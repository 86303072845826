import React, { useState, useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function TOSDialog({
  open,
  handleConfirm,
  handleCancel,
  title,
  description = "",
  negative = "No",
  positive = "Yes",
}) {
  return (
    <div>
      <Dialog
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
        scroll="paper"
        onClose={handleCancel}
      >
        <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <p>
              <span>Welcome to `Celebbook`!</span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <p>
              <span>
                Celebbook is a social media application with casting features.{" "}
              </span>
            </p>

            <p>
              <span>
                These Terms and Conditions administer your use of Celebbook
                services, features, attributes, products, and applications
                provided by us.
              </span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <p>
              <span>
                We charge you a basic amount to use Celebbook and all services
                that come under these terms and services. By using us, you agree
                to be held responsible for the charges we apply. How we
                use/share your data is explained in our <b>Data Policy</b>. You
                can also review these T&amp;C anytime to check the privacy
                choices you have as to how we utilize your data.{" "}
              </span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <h5>
              <span>Services Provided by Us.</span>
            </h5>

            <p>
              <span>
                We at Celebbook aims to tighten the gap between employers and
                jobseekers. To support this cause we provide you with the
                following services.{" "}
              </span>
            </p>

            <h5>
              <span>
                -<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <span>An application with a personalised experience</span>
              <span> :</span>
              <span> </span>
            </h5>

            <p>
              <span>
                Your experience on Celebbook is entirely different from other
                applications and unique only to you with preferences of your
                choice for all features.
              </span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <h5>
              <span>
                -<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <span>Assist you in finding your dream job</span>
              <span> : </span>
            </h5>

            <p>
              <span>
                We use your preferences to curate in present to you the best
                jobs that fit your interests. This can include links/adds to
                third-party sites.
              </span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <h5>
              <span>
                -<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <span>SafeGuard our community</span>
              <span> : </span>
            </h5>

            <p>
              <span>
                We always are on the lookout for any kind of misuse of our
                service, harmful behaviour towards other users and any situation
                where we can provide aid. If we do come across such, appropriate
                actions will be taken; this includes blocking accounts, removing
                content, warnings and contacting law enforcements.
              </span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <h5>
              <span>
                -<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <span>Consistent Updates and services : </span>
            </h5>

            <p>
              <span>
                We research all the time to give you the best experience with up
                to date technical support and the best of facilities.
              </span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <h5>
              <span>Data Policy </span>
            </h5>

            <p>
              <span>
                This policy explains what kind of data we collect and use from
                our consumers.
              </span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <h5>
              <span>
                -<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <span>Information Given By Users</span>
            </h5>

            <p>
              <span>
                For the best experience while using Celebbook, we collect the
                information provided to us by you when you; register to create
                an account, make content and share it with others and
                communicate with others.
              </span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <h5>
              <span>
                -<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <span>Device Information</span>
            </h5>

            <p>
              <span>
                We collect information from and about the devices you use that
                merge with our products; these can be your television, PC,
                mobile and other web connected devices you use. For example: We
                collect information when you use our product on your mobile to
                provide you with better personalised content (includes ads) or
                to check if you reacted differently to an advertisement we
                showed you on your mobile on another device. We collect device
                information such as hardware and software, IP address, time
                zone, ISP and information that you allow us to use such as your
                GPS location.
              </span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <h5>
              <span>
                -<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <span>Cookie Data </span>
            </h5>

            <p>
              <span>
                Celebbook uses cookie data on your device to collect
                information such as your preferences and history in order to
                enhance our services. However, you always have the option to
                decline cookies to your devices which may restrict your access
                to some of our content as they depend on these kinds of
                information.
              </span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <h5>
              <span>
                -<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <span>Government</span>
            </h5>

            <p>
              <span>
                We share any kind of information with the Government of India that
                they may require. If any one posts child porn, rape, violence, hate messages, racial abuse related information
                will be informed to Cyber Crime Department and your account will be blocked permanently and subscription 
                amount will not be refunded
              </span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <h5>
              <span>
                -<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <span>Third-Party</span>
              <span> </span>
            </h5>

            <p>
              <span>
                Celebbook may employ third-party companies to facilitate our
                services, provide services on our behalf and assist us in
                analyzing and improving our services. We allow access to your
                Personal Information to these parties for a better experience
                and in order for them to execute tasks on our behalf. They are
                however, not permitted to disclose/sell or use your information
                for any other purposes. We also have links to other third-party
                companies which are not operated by us. Hence, we strongly
                recommend users to check their T&amp;C before using them.
                Celebbook is not responsible for any misuse of data these
                companies may indulge in.{" "}
              </span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <h5>
              <span>
                -<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <span>Security</span>
              <span> </span>
            </h5>

            <p>
              <span>
                We are always striving to keep your information encrypted and
                safe from any malicious sources. However, we cannot guarantee
                absolute security since no method of communication over the
                internet is entirely reliable.
              </span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <h5>
              <span>Age Privacy </span>
            </h5>

            <p>
              <span>
                There are certain restrictions of the type of content and age
                group that we allow on our website.
              </span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <h5>
              <span>
                -<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <span>Minimum Age of 18 </span>
            </h5>

            <p>
              <span>
                We only allow users who are 18 years or older and take no
                information from anyone below them. If we do encounter a minor
                who has provided us with sensitive information, we immediately
                delete the account and take appropriate measures, subscription
                amount will not be refunded. If you're a guardian who is aware
                that a minor related to you has signed up with us falsely,
                please inform us immediately for further actions.{" "}
              </span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <h5>
              <span>
                -<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <span>Inappropriate Content Posted</span>
            </h5>

            <p>
              <span>
                Any kind of indecent/adult/immoral/unethical/racial/domestic
                violence content posted on our platform will result in an
                immediate warning. If anyone does proceed to share these
                undesirable types of content even after a warning will have
                their account banned permanently and will not receive any
                refund. We will give any information to the higher authorities
                if needed as well. We believe everyone has the right to be on
                our platform and will not tolerate any violent content.{" "}
              </span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <h5>
              <span>Refund and Cancellation</span>
            </h5>

            <p>
              <span>
                Celebbook does not offer any kind of refund if a user decides
                to sign up for our services and leave halfway. Celebbook
                provides a free 7 day trial for consumers to understand our
                product. If any user does register incorrectly i.e jobseeker
                registered as employer and vice versa, we provide them help to
                register as per their interests.
              </span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <h5>
              <span>Changes to our Policy</span>
            </h5>

            <p>
              <span>
                We update our privacy policy from time to time for the best
                services we can provide to our users and hence we suggest users
                to check this page to be updated with any new changes. Changes
                will be in immediate effect when the new policy is posted.{" "}
              </span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <h5>
              <span>
                Your liability as a user of Celebbook and responsibilities
                within the social circle{" "}
              </span>
            </h5>

            <p>
              <span>&nbsp;</span>
            </p>

            <p>
              <span>
                Celebbook gives you this platform and others this medium of
                communication and in return we ask you to follow certain
                guidelines and make the listed commitments:
              </span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <p>
              <span>1. Who can use the </span>
              <span>Celebbook services </span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <p>
              <span>
                As a community it is our responsibility to provide authentic
                information about yourself which is true and without any
                fabrications whatsoever.This is extremely important to maintain
                a sense of security and safety within the user community.Hence,
                you are supposed to follow these rules:
              </span>
            </p>

            <p>
              <span>
                -<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <span>
                You have to give your correct name by which others recognise
                you.Also give as much as possible accurate information about
                yourself in your account.
              </span>
            </p>

            <p>
              <span>
                -<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <span>
                You are supposed to make only one account and use it according
                to your schedule of time.Also while making the account you have
                to keep your password confidential and{" "}
                <b>do not share your password with anyone else.</b>
              </span>
            </p>

            <p>
              <span>
                -<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <span>
                You cannot give access to your personal account for any purpose
                whatsoever to another person for usage without Celebbook
                authorisation.
              </span>
            </p>

            <p>
              <span>
                Although Celebbook is a communication platform and would like
                to provide its services to everyone you{" "}
                <b>cannot henceforth make an account on Celebbook if: </b>
              </span>
            </p>

            <p>
              <span>
                ●<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <b>
                <span> </span>
              </b>
              <span>
                If you are of less than 18 years of age or below the legal age
                to have access to Celebbook.
              </span>
            </p>

            <p>
              <span>
                ●<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <span>If you are a sentenced sex offender/criminal</span>
            </p>

            <p>
              <span>
                ●<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <span>
                Our authorities have removed or temporary disabled your account
                after breaking our community guidelines of Terms and services.
              </span>
            </p>

            <p>
              <span>
                ●<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <span>
                If you are not allowed to have access to our service or
                forbidden under legal laws to not use our services.
              </span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <p>
              <span>2. What you can share and do on </span>
              <span>Celebbook</span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <p>
              <span>
                Celebbook wants you to use the platform freely but also
                wisely.As a user you should use the account to share content
                that you like but keeping in mind the respect and ethics of the
                community.Hence by signing up you agree that you will not
                indulge yourself or encourage others in the misconducts
                mentioned below:
              </span>
            </p>

            <p>
              <b>
                <span>
                  You shall not use Celebbook platform or share anything on the
                  platform if
                </span>
              </b>
              <span>:</span>
            </p>

            <p>
              <span>
                ●<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <span>
                If you break any Terms or Policies laid down by Celebbook or if
                it disrupts the degree of the community or any other terms
                mentioned.
              </span>
            </p>

            <p>
              <span>
                ●<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <span>
                If the content you share is illegal,offensive, deceiving or
                fraudulent.
              </span>
            </p>

            <p>
              <span>
                ●<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <span>
                If it diminishes or breaches someone’s legal rights(including
                Intellectual property rights){" "}
              </span>
            </p>

            <p>
              <span>
                ●<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <span>
                If you upload any kind of malicious codes, viruses that could
                disrupt the website or damage the website to not work or slow
                down the website or cause any malevolent changes to the website.
              </span>
            </p>

            <p>
              <span>
                ●<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <span>
                If you get illegal access to data or gather information from our
                website by any means without authorisation or if you tried to
                access information or data you do not have permission of.
              </span>
            </p>

            <p>
              <span>
                ●<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <span>
                We can regulate or limit your access to the content of your post
                and we can remove any content that breaches the following
                conditions:{" "}
              </span>
            </p>

            <p>
              <span>
                ●<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <span>
                if we have discarded your content in regards to breaking the
                terms and policies of the community.We will hence clarify or
                resolve this issue with you and you will get options to get
                another evaluation of your removed content unless you frequently
                repeat to breach the terms and policies,hurt our local area of
                clients; bargain or meddle with the honesty or activity of any
                of our administrations, frameworks or Products; where we are
                confined because of specialized impediments; or where we are
                denied from doing as such for legitimate reasons.{" "}
              </span>
            </p>

            <p>
              <span>
                ●<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </span>
              <span>
                To help uphold our community ,we urge you to report content that
                you believe abuses your rights (including intellectual property
                rights) or our terms and approaches.We additionally can
                eliminate or limit admittance to your content,administrations or
                data in the event that we confirm that doing so is sensibly
                important to evade or alleviate unfavorable lawful or
                administrative effects to Celebbook.
              </span>
            </p>

            <p>
              <span>&nbsp;</span>
            </p>

            <p>
              <span>3. The consents you give us </span>
            </p>

            <p>
              <span>
                We need certain consents from you to offer our types of
                assistance at Celebbook:{" "}
              </span>
            </p>
            <p>
              <span>
                Authorization to utilize content that you make and offer: Some
                substance that you share or transfer, for example, photographs
                or recordings, might be secured by protected innovation laws.
                You own the licensed innovation rights (things, for example,
                copyright or brand names) in any such substance that you make
                and offer on Celebbook. Nothing in these Terms removes the
                rights you have to your own substance. You are allowed to impart
                your substance to any other individual, any place you need. In
                any case, to offer our types of assistance, we need you to give
                us some legitimate authorizations (known as a 'permit') to
                utilize this substance. This is exclusively for the reasons for
                giving and improving our Products and administrations.
              </span>
            </p>

            <p>
              <span>
                In particular, when you offer, post or transfer content that is
                covered by protected innovation rights on or regarding our
                Products, you award us a non-selective, adaptable,
                sub-licensable, sovereignty free and overall permit to have,
                use, circulate, change, run, duplicate, openly perform or show,
                decipher and make subsidiary works of your substance (reliable
                with your security and application settings). This implies, for
                instance, that in the event that you share a photograph on
                Celebbook, you allow us to store, duplicate and offer it with
                others (once more, reliable with your settings.This permit will
                end when your substance is erased from our frameworks.{" "}
              </span>
            </p>

            <p>
              <span>
                Authorization to utilize your name, profile picture and data
                about your activities with promotions and supported substance:
                You allow us to utilize your name and profile picture and data
                about moves that you have made on Celebbook close to or
                regarding advertisements, offers and other supported substance
                that we show across our Products, with no remuneration to you.
                For instance, we may show your companions that you are keen on a
                publicized occasion or have enjoyed a Page made by a brand that
                has paid us to show its promotions on Celebbook. Advertisements
                like this can be seen exclusively by individuals who have your
                consent to see the moves that you've made on Celebbook. You can
                become familiar with your advertisement settings and
                inclinations.{" "}
              </span>
            </p>
            <p>
              <span>
                Consent to refresh programming that you utilize or download: If
                you download or utilize our product, you allow us to download
                and introduce updates to the product where accessible.{" "}
              </span>
            </p>

            <p>
              <span>
                4. Cutoff points on utilizing our licensed innovation{" "}
              </span>
            </p>
            <p>
              <span>
                In the event that you utilize content covered by licensed
                innovation rights that we have and make accessible in our
                Products (for instance, pictures, plans, recordings or sounds
                that we give, which you add to content that you make or offer on
                Celebbook), we hold all rights to that content (yet not yours).
                You can just utilize our copyrights or reserve (or any
                comparative imprints) as explicitly allowed by our Brand Usage
                Guidelines or with our earlier composed authorization. You
                should acquire our composed consent (or authorization under an
                open-source permit) to change, make subordinate works of,
                decompile or in any case endeavor to extricate source code from
                us.
              </span>
            </p>
            <h5>
              <span>Contact Us</span>
            </h5>

            <p>
              <span>
                For any queries/doubts or additional information, feel free to
                contact us at :{" "}
                <a href="mailto:support@Celebbook.com">
                  <span style={{ color: "#1155CC" }}>
                    support@Celebbook.com
                  </span>
                </a>
              </span>
            </p>
            <p>Date of last revision: 27 March 2021</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancel}
            style={{ textTransform: "none" }}
            color="secondary"
          >
            {negative}
          </Button>
          <Button
            onClick={() => handleConfirm()}
            color="primary"
            style={{ textTransform: "none", float: "right" }}
          >
            {positive}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
