import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import ReactPlayer from "react-player";
import Draggable from "react-draggable";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import CustomDialog from "../CustomDialog";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
export default function VideoDialog({
  open,
  src,
  title,
  handleClose,
  handleNextClick,
  handlePrevClick,
  classes,
  links,
}) {
  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      isShowHeader
      headerTitle={title}
      PaperComponent={PaperComponent}
      isFullDialog
    >
      {links.length > 1 && (
        <IconButton
          aria-label="Previous"
          onClick={() => handlePrevClick()}
          className={classes.navIcons}
          style={{
            // display: currentIndex <= 0 ? "none" : "block",
            left: "calc(50% - 600px)",
          }}
        >
          <NavigateBeforeIcon fontSize="large" />
        </IconButton>
      )}
      {/* <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {title}
        </DialogTitle> */}
      <DialogContent style={{ padding: 0, height: 480 }} dividers>
        <ReactPlayer url={src} controls width="100%" height={464} pip />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          style={{ textTransform: "none" }}
        >
          Close
        </Button>
      </DialogActions>
      {links.length > 1 && (
        <IconButton
          aria-label="Next"
          onClick={() => handleNextClick()}
          className={classes.navIcons}
          style={{
            // display: currentIndex >= galleries.length - 1 ? "none" : "block",
            right: "calc(50% - 600px)",
          }}
        >
          <NavigateNextIcon fontSize="large" />
        </IconButton>
      )}
    </CustomDialog>
  );
}
