import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Typography } from "@material-ui/core";

export default function AlertDialog({
  handleClose,
  open,
  text,
  description = "",
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <InfoOutlinedIcon
            fontSize="large"
            color="primary"
            style={{ marginRight: 12, verticalAlign: "middle" }}
          />
          <Typography display="inline">{text}</Typography>
        </DialogTitle>
        {description !== "" && (
          <DialogContent dividers>
            <DialogContentText id="alert-dialog-description">
              <Typography style={{ whiteSpace: "pre-line" }} paragraph>
                {description}
              </Typography>
            </DialogContentText>
          </DialogContent>
        )}

        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={handleClose} color="primary" variant="contained">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
