import React, { useState, useEffect } from "react";
import Pagination from "@material-ui/lab/Pagination";

function PositionDataTablePagination(props) {
  function handleChangePageNumber(e, page) {
    props.handleChangePageNumber(page);
  }
  return (
    <Pagination
      count={props.count}
      page={props.page}
      variant="outlined"
      shape="rounded"
      onChange={handleChangePageNumber}
    />
  );
}

export default PositionDataTablePagination;
