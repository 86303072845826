import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import IconButton from "@material-ui/core/IconButton";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import { openCheckout } from "../PaymentDetails/Checkout";
import Slide from "@material-ui/core/Slide";
import {
  getGiftsList,
  sendGift,
  getCurrentPointsAndGifts,
  getRazorPaySuccessCapture,
  addPointsToBuy,
} from "../../actions/pointsActions";
import { Typography, Grid } from "@material-ui/core";
import { constants } from "../../helpers/constants";
import PurchaseDetails from "../PointsComponent/PurchaseDetails";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GiftDialog = ({ open, handleClose, url, dispatch, gallery }) => {
  const [gifts, setGifts] = useState([]);
  const [currentPoints, setCurrentPoints] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [chosenPoints, setChosenPoints] = useState(100);
  const [purchaseOpen, setPurchaseOpen] = useState(false);
  const userDetail = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.userDetail
  );

  const addPointsToBuyLoading = useSelector(
    ({ pointsReducer }) => pointsReducer.addSubscriptionPlanLoading
  );
  useEffect(() => {
    getGiftsList().then((res) => setGifts(res.data));
    getCurrentPointsAndGifts().then((res) => {
      if (!res.data) setCurrentPoints(0);
      else setCurrentPoints(res.data.pointsAllocated);
    });
  }, [refresh]);

  const handleGift = (id) => {
    gallery &&
      dispatch(
        sendGift({ userId: gallery.userId._id, giftId: id, noOfGifts: 1 })
      ).then((res) => setRefresh(!refresh));
  };

  const handleCallbackRazorPayCapture = () => {
    getCurrentPointsAndGifts().then((res) => {
      if (!res.data) setCurrentPoints(0);
      else {
        setCurrentPoints(res.data.pointsAllocated);
      }
    });
  };

  const handleCallbackRazorPaySuccess = (res, purchaseId) => {
    setPurchaseOpen(false);
    const body = {
      razorpay_order_id: res.razorpay_order_id,
      razorpay_payment_id: res.razorpay_payment_id,
      razorpay_signature: res.razorpay_signature,
      purchaseId: purchaseId,
    };
    dispatch(getRazorPaySuccessCapture(body, handleCallbackRazorPayCapture));
  };

  const handleCallRazorPayMethod = (razorPayData) => {
    setPurchaseOpen(false);
    openCheckout(
      userDetail,
      razorPayData.totalPriceAfterTax,
      razorPayData.razorpay_order_id,
      razorPayData._id,
      handleCallbackRazorPaySuccess
    );
  };

  const handleMakePayment = () => {
    setPurchaseOpen(false);
    dispatch(addPointsToBuy(chosenPoints, handleCallRazorPayMethod));
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">Choose Gift</DialogTitle>
      <Divider variant="fullWidth" />

      <Typography
        variant="h6"
        align="center"
        color="primary"
        style={{ margin: "6px" }}
      >
        My Points : {currentPoints && currentPoints}
      </Typography>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-slide-description">
          Select a gift to send
        </DialogContentText>

        <Grid
          container
          justify="center"
          alignContent="center"
          alignItems="center"
          spacing={2}
          style={{ marginBottom: "12px" }}
        >
          {gifts.length &&
            gifts.map((gift) => (
              <Grid
                item
                style={{
                  textAlign: "center",
                }}
                spacing={2}
              >
                <Grid item>
                  <IconButton onClick={() => handleGift(gift._id)}>
                    <CardGiftcardIcon color="primary" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography display="block">{gift.giftName}</Typography>
                </Grid>
                <Grid item>
                  <Typography display="block">{gift.pointWeight}</Typography>
                </Grid>
              </Grid>
            ))}
        </Grid>

        <Divider variant="centered" light />

        <div
          style={{
            marginTop: "12px",
          }}
        >
          {/* <Typography
            component="h1"
            variant="h4"
            align="center"
            color="textPrimary"
          >
            Buy Points
          </Typography> */}

          {addPointsToBuyLoading ? (
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white", opacity: "0.6", textTransform: "none" }}
            >
              Making payment...
            </Button>
          ) : (
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  align="center"
                  variant="body1"
                  style={{ margin: "6px" }}
                >
                  Do you want to buy more points?*
                </Typography>
              </Grid>
              <Grid item xs={8} align="center">
                <TextField
                  value={chosenPoints}
                  size="large"
                  onChange={(e) => {
                    (Number(e.target.value) || e.target.value == "") &&
                      setChosenPoints(e.target.value);
                  }}
                  // fullWidth
                  required
                />
              </Grid>
              <Grid item xs={4} align="center">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{
                    color: "white",
                    textTransform: "none",
                  }}
                  onClick={() => chosenPoints > 0 && setPurchaseOpen(true)}
                >
                  Make payment
                </Button>
                {purchaseOpen && (
                  <PurchaseDetails
                    open={purchaseOpen}
                    handleClose={() => setPurchaseOpen(false)}
                    handleMakePayment={handleMakePayment}
                    noOfPoints={chosenPoints}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          style={{ textTransform: "none" }}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect()(withRouter(GiftDialog));
