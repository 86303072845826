import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import bindActionCreators from "redux/es/bindActionCreators";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { sendHTTPRequest, send } from "../helpers/requestEngine";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import { Link as ReactDomLink } from "react-router-dom";
import {
  isValidParam,
  getStringParam,
  getObjectParam,
  getArrayParam,
  getIntParam,
  getBooleanParam,
} from "../helpers/paramVerifier";
import getUrl from "../helpers/apis";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import Paper from "@material-ui/core/Paper";
import FieldLabelValueContent from "../components/FieldLabelValueContent";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import { constants } from "../helpers/constants";
import {
  getFollowing,
  getFollowers,
  getDeptGallery,
  getProfileLikes,
} from "../actions/applicantsDetailsActions";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import FollowersIcon from "@material-ui/icons/ArrowDownward";
import PhotoOutlinedIcon from "@material-ui/icons/PhotoOutlined";
import LikeHiddenIcon from "@material-ui/icons/Favorite";
import LikeOpenIcon from "@material-ui/icons/FavoriteBorderOutlined";
import LikeIcon from "@material-ui/icons/ThumbUpAltOutlined";
import FollowingIcon from "@material-ui/icons/ArrowUpward";
import { openAvatarModal } from "../actions/appActions";

import ImageViewer from "../components/ImageViewer";
import CustomDialog from "../components/CustomDialog";
import ActionsViewComponent from "../components/ActionsViewComponent";
import { showSnackBar } from "../helpers/globalFunctions";

// import "react-image-gallery/styles/css/image-gallery.css";
//require("react-image-gallery/styles/css/image-gallery.css");
// import "react-image-gallery/styles/css/image-gallery-no-icon.css";
//require('react-image-gallery/styles/css/image-gallery-no-icon.css');
import ImageGallery from "react-image-gallery";
import getURL from "../helpers/apis";

const styles = (theme) => ({
  root: {},
  row: {
    marginLeft: 20,
    marginReft: 20,
  },
  paper: {
    fontSize: 14,
    minWidth: 275,
    margin: 20,
    maxHeight: 400,
    maxWidth: 1200,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  breadCumLink: {
    fontSize: 14,
  },
  pos: {
    fontSize: 14,
    marginBottom: 12,
  },
  root: {
    flexGrow: 1,
    padding: 10,
  },
  jobBodyRow: {
    fontSize: 14,
    marginTop: 10,
    marginBottom: 10,
  },
  jobBodyCol1: {
    width: "10%",
    fontSize: 14,
    fontWeight: 600,
    color: "#00000078",
  },
  jobBodyCol2: {
    width: "90%",
    fontSize: 14,
    fontWeight: 400,
    color: "#000000bd",
  },
  button: {
    fontSize: 14,
    marginLeft: 5,
    marginRigh: 5,
    textTransform: "capitalize",
  },
  media: {
    height: 210,
    width: 210,
  },
  grid1: {
    marginLeft: 15,
    fontWeight: 600,
    fontSize: 20,
    lineHeight: 1.2,
    textTransform: "capitalize",
  },
  badgeMargin: {
    margin: 5,
  },
  badge: {
    //backgroundColor: '#00465b',
    fontSize: 12,
  },
  badgePadding: {
    //padding: `0 14px`,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 14,
    fontWeight: 600,
    textTransform: "capitalize",
    color: "#3f51b5b8",
  },
  countElement: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: "capitalize",
    //color: '#3f51b5b8',
    textAlign: "center",
  },
  actionCount: {
    fontSize: 16,
    marginLeft: 25,
    marginRight: 55,
  },
  icons: {
    cursor: "pointer",
    height: "auto",
    fontSize: 30,
    marginLeft: 15,
    marginRight: 15,
  },
});

class ApplicantDetails extends React.Component {
  constructor(props) {
    super(props);
    this.fields = [
      { name: "name", label: "Name" },
      { name: "region", label: "Region" },
      { name: "country", label: "Country" },
      { name: "mainrole", label: "Main Role" },
      { name: "otherroles", label: "Other Roles" },
    ];
  }

  componentDidMount() {}

  changeStatus(status) {
    try {
      let promise = Promise.resolve(
        sendHTTPRequest(
          "post",
          getUrl("CHANGE_APPLICANT_STATUS") + "/" + this.props.applicant._id,
          true,
          { status: status }
        )
      );
      promise.then((response) => {
        this.props.getPosApplicants(this.props.applicant.positionId);
        showSnackBar("Applicants successfully " + status + ".", {
          variant: "success",
        });
      });
    } catch (error) {}
  }

  getCardActions() {
    const { classes } = this.props;
    let element = null;
    try {
      if (!getBooleanParam(this.props.isDetailView)) {
        element = (
          <Grid item xs={12}>
            <Typography component="div" style={{ float: "left" }} />
            <Typography component="div" style={{ float: "right" }}>
              {this.props.status != "ShortListed" && (
                <Button
                  color="primary"
                  className={classes.button}
                  style={{ color: "#3f51b5" }}
                  onClick={() => this.changeStatus("ShortListed")}
                >
                  {" "}
                  Short List{" "}
                </Button>
              )}
              {this.props.status != "Rejected" && (
                <Button
                  color="primary"
                  className={classes.button}
                  style={{ color: "##3f51b5" }}
                  onClick={() => this.changeStatus("Rejected")}
                >
                  {" "}
                  Reject{" "}
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                style={{ backgroundColor: "#3f51b5" }}
              >
                {" "}
                Start Chat{" "}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                style={{ backgroundColor: "#3f51b5" }}
              >
                <ReactDomLink
                  to={
                    "/jobseeker/" +
                    this.props.applicant.userInfo._id +
                    "/" +
                    this.props.status +
                    "/" +
                    this.props.index
                  }
                  style={{ color: "#ffffff", textDecoration: "none" }}
                >
                  {" "}
                  View Full Application
                </ReactDomLink>{" "}
              </Button>
            </Typography>
          </Grid>
        );
      }
    } catch (error) {}
    return element;
  }

  getAppliedDateDiff() {
    let diff = 0;
    try {
      let createdAt = getStringParam(this.props.posDetails.createdAt);
      createdAt = new Date(createdAt);

      let appliedOn = getStringParam(this.props.applicant.createdAt);
      appliedOn = new Date(appliedOn);
      diff = parseInt((new Date() - appliedOn) / (24 * 3600 * 1000));
    } catch (error) {}
    return diff;
  }

  render() {
    const { classes } = this.props;
    let actionElement = this.getCardActions();
    let style = getBooleanParam(this.props.isDetailView) ? { margin: 0 } : {};
    return (
      <Paper className={classes.paper} key={"applicant-details"} style={style}>
        <CardContent>
          <Grid container className={classes.root}>
            <Grid
              container
              justify="left"
              spacing={Number(5)}
              style={{ width: "93%" }}
            >
              <Grid key={2} item>
                <Avatar
                  alt={this.props.applicant.userInfo.name}
                  className={classes.media}
                  src={
                    "http://68.183.93.199/uploads/" +
                    this.props.applicant.userInfo.profilePic
                  }
                  onClick={(e) =>
                    this.props.dispatch(
                      openAvatarModal(
                        "http://68.183.93.199/uploads/" +
                          this.props.applicant.userInfo.profilePic
                      )
                    )
                  }
                />
              </Grid>
              <Grid item justify="centre" xs={9}>
                <ApplicatsBadges {...this.props} />
                <FieldLabelValueContent
                  fields={this.fields}
                  data={this.props.applicant.userInfo}
                  isNotUseContainer={true}
                />
              </Grid>
            </Grid>
            <Grid style={{ width: "7%" }}>
              <Typography
                component="div"
                style={{ fontWeight: 500, fontSize: 12 }}
                color="textSecondary"
              >
                Applied
              </Typography>
              <Typography
                component="div"
                style={{ fontWeight: 500, fontSize: 12 }}
                color="textSecondary"
              >
                {this.getAppliedDateDiff()} Days Ago
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        {actionElement && <CardActions>{actionElement}</CardActions>}
      </Paper>
    );
  }
}
export default withStyles(styles)(connect(null, null)(ApplicantDetails));

class ApplicatsBadges extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenDialog: false,
      isFullDialog: false,
      dialogType: null,
      follwings: [],
      followers: [],
      publicPhotos: [],
      privatePhotos: [],
      departmentPhotos: [],
      likes: [],
    };
    this.getDialogContent.bind(this);
  }

  componentWillMount() {
    try {
      let { applicant } = this.props;
      let id =
        isValidParam(applicant) && isValidParam(applicant.userInfo)
          ? getStringParam(applicant.userInfo._id)
          : "";
      if (id != "") {
        /*******    Get following by user id **************************************** */
        let promiseFollowings = getFollowing(id);
        promiseFollowings.then((response) => {
          if (
            isValidParam(response) &&
            !getBooleanParam(response.error) &&
            response.status == 200
          ) {
            let follwings = getArrayParam(response.data);
            follwings = follwings.filter((f) => {
              return f.followedBy == id;
            });
            this.setState({ follwings: follwings });
          }
        });

        /*******    Get Followers by User id **************************************** */
        let promiseFollowers = getFollowers(id);
        promiseFollowers.then((response) => {
          if (
            isValidParam(response) &&
            !getBooleanParam(response.error) &&
            response.status == 200
          ) {
            let followers = getArrayParam(response.data);
            followers = followers.filter((f) => {
              return f.followedTo == id;
            });
            this.setState({ followers: followers });
          }
        });

        /*******     **************************************** */
        let publicPhotosPromise = getDeptGallery(
          constants.PUBLIC,
          this.props.applicant.userInfo._id
        );
        publicPhotosPromise.then((response) => {
          if (
            isValidParam(response) &&
            !getBooleanParam(response.error) &&
            response.status == 200
          ) {
            let publicPhotos = getArrayParam(response.result);
            this.setState({ publicPhotos: publicPhotos });
          }
        });

        let privatePhotosPromise = getDeptGallery(
          constants.PRIVATE,
          this.props.applicant.userInfo._id
        );
        privatePhotosPromise.then((response) => {
          if (
            isValidParam(response) &&
            !getBooleanParam(response.error) &&
            response.status == 200
          ) {
            let privatePhotos = getArrayParam(response.result);
            this.setState({ privatePhotos: privatePhotos });
          }
        });

        let departmentPhotosPromise = getDeptGallery(
          constants.DEPARTMENT,
          this.props.applicant.userInfo._id
        );
        departmentPhotosPromise.then((response) => {
          if (
            isValidParam(response) &&
            !getBooleanParam(response.error) &&
            response.status == 200
          ) {
            let departmentPhotos = getArrayParam(response.result);
            this.setState({ departmentPhotos: departmentPhotos });
          }
        });

        /*******    Get profile likes by user id **************************************** */
        let likesPromise = getProfileLikes(this.props.applicant.userInfo._id);
        likesPromise.then((response) => {
          if (
            isValidParam(response) &&
            !getBooleanParam(response.error) &&
            response.status == 200
          ) {
            let likes = getArrayParam(response.data);
            this.setState({ likes: likes });
          }
        });

        // let allPromise = Promise.all([promiseFollowings, promiseFollowers, publicPhotosPromise, privatePhotosPromise, departmentPhotosPromise]).then((responses) => {
        //     if(isValidParam(responses) && Array.isArray(responses) && responses.length == 5){

        //     }
        // });
      }
    } catch (error) {}
  }

  handleClose() {
    this.setState({ isOpenDialog: false, dialogType: null });
  }

  handleOpen(dialogType) {
    this.setState({
      isOpenDialog: true,
      dialogType: dialogType,
      isFullDialog: false,
    });
  }

  getPhotoArray(publicPhotos) {
    let rootUrl = getURL("UPLOADS");
    let arr = getArrayParam(publicPhotos);
    arr = arr.map((f) => {
      return {
        srcSet: f.name,
        thumbnail: f.name,
        originalAlt: "original-alt",
        thumbnailAlt: "thumbnail-alt",
      };
    });
    return arr;
  }

  getDialogContent() {
    let element = null;
    try {
      let dialogType = getStringParam(this.state.dialogType);
      if (
        dialogType == constants.PUBLIC &&
        this.state.publicPhotos.length > 0
      ) {
        let arr = this.getPhotoArray(this.state.publicPhotos);
        element = new Object();
        element.header = (
          <Typography variant="h5" style={{ fontWeight: 600 }}>
            Public({this.state.publicPhotos.length})
          </Typography>
        );
        //element.body = <ImageViewer key={'view-gallery-public'} data={this.state.publicPhotos} openFullDialog={this.openFullDialog.bind(this)}/>;
        element.body = (
          <ImageGallery
            key={"photos-" + constants.PUBLIC}
            showPlayButton={true}
            showBullets={true}
            showIndex={true}
            autoPlay={false}
            items={arr}
          />
        );
      } else if (
        dialogType == constants.DEPARTMENT &&
        this.state.departmentPhotos.length > 0
      ) {
        element = new Object();
        element.header = (
          <Typography variant="h5" style={{ fontWeight: 600 }}>
            Department({this.state.departmentPhotos.length})
          </Typography>
        );
        element.body = (
          <ImageViewer
            key={"view-gallery-department"}
            data={this.state.departmentPhotos}
            openFullDialog={this.openFullDialog.bind(this)}
          />
        );
      } else if (
        dialogType == constants.PRIVATE &&
        this.state.privatePhotos.length > 0
      ) {
        element = new Object();
        element.header = (
          <Typography variant="h5" style={{ fontWeight: 600 }}>
            Private({this.state.privatePhotos.length})
          </Typography>
        );
        element.body = (
          <ImageViewer
            key={"view-gallery-private"}
            data={this.state.privatePhotos}
            openFullDialog={this.openFullDialog.bind(this)}
          />
        );
      } else if (
        dialogType == constants.FOLLWERS &&
        this.state.followers.length > 0
      ) {
        element = new Object();
        element.header = (
          <Typography variant="h5" style={{ fontWeight: 600 }}>
            Followers({this.state.followers.length})
          </Typography>
        );
        element.body = (
          <ActionsViewComponent
            key={"view-fowowers"}
            fields={["followedByName", "followedByUsername"]}
            data={this.state.followers}
          />
        );
      } else if (
        dialogType == constants.FOLLWING &&
        this.state.follwings.length > 0
      ) {
        element = new Object();
        element.header = (
          <Typography variant="h5" style={{ fontWeight: 600 }}>
            Following({this.state.follwings.length})
          </Typography>
        );
        element.body = (
          <ActionsViewComponent
            key={"view-follwings"}
            fields={["followedToName", "followedToUsername"]}
            data={this.state.follwings}
          />
        );
      } else if (dialogType == constants.LIKES && this.state.likes.length > 0) {
        element = new Object();
        element.header = (
          <Typography variant="h5" style={{ fontWeight: 600 }}>
            Like({this.state.likes.length})
          </Typography>
        );
        element.body = (
          <ActionsViewComponent
            key={"view-follwings"}
            fields={["likedBy"]}
            data={this.state.likes}
          />
        );
      }
    } catch (error) {}
    return element;
  }

  getCustomDialog() {
    let element = null;
    try {
      let isOpenDialog = getBooleanParam(this.state.isOpenDialog);
      let isFullDialog = getBooleanParam(this.state.isFullDialog);
      let dialogElement = this.getDialogContent();
      if (isOpenDialog && isValidParam(dialogElement)) {
        let props = new Object();
        props.key = getStringParam(this.state.dialogType);
        props.open = getBooleanParam(this.state.isOpenDialog);
        props.onClose = this.handleClose.bind(this);
        props.isShowHeader = true;
        props.headerTitle = dialogElement.header;
        if (isFullDialog) {
          props.isFullDialog = isFullDialog;
          props.isShowHeader = false;
        }

        element = <CustomDialog {...props}>{dialogElement.body}</CustomDialog>;
      }
    } catch (error) {}
    return element;
  }

  openFullDialog() {
    try {
      this.setState({ isFullDialog: true });
    } catch (error) {}
  }

  render() {
    const { classes } = this.props;
    let customDialog = this.getCustomDialog();

    return (
      <Grid container>
        {/* <ImageViewer open={this.state.isOpenDialog} onClose={()=>this.handleClose()}/> */}
        {customDialog}
        <Grid style={{ width: "30%" }}>
          <Typography component="h5" className={classes.grid1}>
            {this.props.applicant.userInfo.name}
          </Typography>
          <Typography
            component="h6"
            className={classes.grid1}
            style={{ fontWeight: 400 }}
          >
            {this.props.applicant.userInfo.mainrole}
          </Typography>
        </Grid>
        <Grid style={{ width: "70%" }}>
          <Grid container>
            <Typography component="div">
              <Typography component="div" className={classes.actionCount}>
                {this.state.likes.length}
              </Typography>
              <Typography component="div">
                <LikeIcon className={classes.icons} />
              </Typography>
            </Typography>
            <Typography component="div">
              <Typography component="div" className={classes.actionCount}>
                {this.state.followers.length}
              </Typography>
              <Typography component="div">
                <FollowingIcon
                  className={classes.icons}
                  onClick={this.handleOpen.bind(this, constants.FOLLWING)}
                />
              </Typography>
            </Typography>
            <Typography component="div">
              <Typography component="div" className={classes.actionCount}>
                {this.state.follwings.length}
              </Typography>
              <Typography component="div">
                <FollowersIcon
                  className={classes.icons}
                  onClick={this.handleOpen.bind(this, constants.FOLLWERS)}
                />
              </Typography>
            </Typography>
            <Typography component="div">
              <Typography component="div" className={classes.actionCount}>
                {this.state.publicPhotos.length}
              </Typography>
              <Typography component="div">
                <PhotoOutlinedIcon
                  className={classes.icons}
                  onClick={() => this.handleOpen(constants.PUBLIC)}
                />
              </Typography>
            </Typography>
            {/* <Typography component="div">
                        <Typography variant={"span"} className={classes.countElement}>{this.state.departmentPhotos.length}</Typography>
                        <Button color="primary" className={classes.badgePadding} onClick={() => this.handleOpen(constants.DEPARTMENT)}>Department</Button>
                    </Typography>
                    <Typography component="div">
                        <Typography variant={"span"} className={classes.countElement}>{this.state.privatePhotos.length}</Typography>
                        <Button color="primary" className={classes.badgePadding} onClick={() => this.handleOpen(constants.PRIVATE)}>Private</Button>
                    </Typography>
                    <Typography component="div">
                        <Typography variant={"span"} className={classes.countElement}>{this.state.followers.length}</Typography>
                        <Button color="primary" className={classes.badgePadding} onClick={this.handleOpen.bind(this, constants.FOLLWERS)}>Follwers</Button>
                    </Typography>
                    <Typography component="div">
                        <Typography variant={"span"} className={classes.countElement}>{this.state.follwings.length}</Typography>
                        <Button color="primary" className={classes.badgePadding} onClick={this.handleOpen.bind(this, constants.FOLLWING)}>Follwing</Button>
                    </Typography>
                    <Typography component="div">
                        <Typography variant={"span"} className={classes.countElement}>{this.state.likes.length}</Typography>
                        <Button color="primary" className={classes.badgePadding} onClick={this.handleOpen.bind(this, constants.LIKES)}><ThumbUpIcon/></Button>
                    </Typography>                     */}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
