import request from "../helpers/requestEngine";
import io from "socket.io-client";
import { getToken } from "../helpers/globalFunctions";
// import snackbar from "snackbar";
import { base_url } from "../helpers/apis";

let socket;

export let notifEventTarget = new EventTarget();

// const base_url = "https://vemsys.org";
// const base_url = "http://68.183.93.199:5000";
// const base_url = "https://cineoffer.com";
// const base_url = "http://localhost:3000";

export const establishSocketConnection = () => {
  socket = io(base_url, {
    path: "/chat",
    transports: ["websocket"],
    query: {
      token: getToken(),
    },
  });

  initUser();

  //events
  socket.on("connect", () => {
    let incomingChatEvent = new CustomEvent("connected");
    notifEventTarget.dispatchEvent(incomingChatEvent);
    socket.emit("subscribe_notif");
  });

  socket.on("fetchNotifs", (data) => {
    let incomingChatEvent = new CustomEvent("fetched", {
      detail: data,
    });
    notifEventTarget.dispatchEvent(incomingChatEvent);
  });

  socket.on("unreadCount", (unread) => {
    let incomingChatEvent = new CustomEvent("unreadCount", {
      detail: unread.unreadCount,
    });
    notifEventTarget.dispatchEvent(incomingChatEvent);
  });

  socket.on("notifRead", () => {
    let incomingChatEvent = new CustomEvent("markRead");
    notifEventTarget.dispatchEvent(incomingChatEvent);
  });

  socket.on("notifDeleted", () => {
    let incomingChatEvent = new CustomEvent("deleted");
    notifEventTarget.dispatchEvent(incomingChatEvent);
  });

  socket.on("new_notification", () => {
    let incomingChatEvent = new CustomEvent("new_notification");
    notifEventTarget.dispatchEvent(incomingChatEvent);
  });
};

export function disconnectSocket() {
  socket && socket.close();
}

const initUser = () => {
  const userToken = getToken();
  socket.emit("connection", { token: userToken });
};

export const getAllNotifs = (page, limit) => {
  socket.emit("notification", { type: "fetchNotifs", page, limit });
};

export const getUnreadCount = () => {
  socket.emit("notification", { type: "unreadCount" });
};

export const markRead = () => {
  socket.emit("notification", { type: "markreadNotif" });
};

export const deleteNotif = (id) => {
  socket.emit("notification", { type: "deleteNotif", notifId: id });
};
