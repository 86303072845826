import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Tabs, Tab, AppBar } from "@material-ui/core";
import GalleryComponent from "../GalleryComponent";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getFollowingSortedGallery,
  getNonFollowingSortedGallery,
} from "../../actions/videosActions";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginTop: "20px",
    // borderTop: "1px solid rgba(var(--b38,219,219,219),1)",
    overflowY: "scroll",
    height: "90vh",
  },
  tabIndicator: {
    height: "2.5px",
    backgroundColor: "#3f51b5",
  },
});

const VideosComponent = ({
  followingVideos,
  followingVideosLoading,
  nonFollowingVideos,
  nonFollowingVideosLoading,
  dispatch,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(1);

  useEffect(() => {
    value === 0
      ? dispatch(getFollowingSortedGallery())
      : dispatch(getNonFollowingSortedGallery());
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Paper elevation={0} className={classes.root}>
      <AppBar position="sticky" color="default">
        <Tabs
          TabIndicatorProps={{ className: classes.tabIndicator }}
          value={value}
          onChange={handleChange}
          textColor="primary"
          color="primary"
          centered
          // variant="fullWidth"
        >
          <Tab
            label="Following"
            // style={{
            //   borderBottom:
            //     value !== 0 ? "1px solid rgba(var(--b38,219,219,219),1)" : "none",
            // }}
            disableRipple
            style={{ textTransform: "none" }}
          />
          <Tab
            disableRipple
            label="New"
            // style={{
            //   borderBottom:
            //     value !== 1 ? "1px solid rgba(var(--b38,219,219,219),1)" : "none",
            // }}
            style={{ textTransform: "none" }}
          />
        </Tabs>
      </AppBar>
      {value === 0 ? (
        followingVideos ? (
          <GalleryComponent
            gallery={followingVideos}
            galleryLoading={followingVideosLoading}
            galleryType="videos"
            isFollowingVideos={true}
          />
        ) : null
      ) : nonFollowingVideos ? (
        <GalleryComponent
          gallery={nonFollowingVideos}
          galleryLoading={nonFollowingVideosLoading}
          galleryType="videos"
        />
      ) : null}
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    followingVideos: state.videoReducer.followingVideos,
    followingVideosLoading: state.videoReducer.followingVideosLoading,
    nonFollowingVideos: state.videoReducer.nonFollowingVideos,
    nonFollowingVideosLoading: state.videoReducer.nonFollowingVideosLoading,
  };
};

export default connect(mapStateToProps)(withRouter(VideosComponent));
