import React, { useEffect, useState } from "react";
import getURL from "../../helpers/apis";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter, Redirect } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  //  Breadcrumbs,
  Paper,
  CircularProgress,
  Divider,
  Avatar,
  Modal,
  Grid,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import UserProfileListViewTabelToolbar from "./UserProfileListViewTabelToolbar";
import UserProfileListViewTableHead from "./UserProfileListViewTableHead";
import {
  getJobSeekersDetails,
  updateRecentSearchById,
} from "../../actions/getPosActions";
import {
  requestCountries,
  requestStates,
  requestIndustries,
} from "../../actions/registerActions";
import { getRoles } from "../../actions/userDetailsAction";
// import ImageDialog from "../../components/UserProfile/ImageDialog";
import { constants } from "../../helpers/constants";
import { openAvatarModal } from "../../actions/appActions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: 25,
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const UserProfilesListViewContainer = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [searchText, setSearchText] = React.useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [avatarModal, setAvatarModal] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [country, setCountry] = useState("");

  const jobSeekersDetailsList = useSelector(
    ({ PosTableReducer }) => PosTableReducer.jobSeekersDetailsList
  );

  const rowData = jobSeekersDetailsList && jobSeekersDetailsList.array;
  const jobSeekersDetailsTotalPages =
    jobSeekersDetailsList && jobSeekersDetailsList.totalPages;
  const fetchingJobSeekersDetails = useSelector(
    ({ PosTableReducer }) => PosTableReducer.fetchingJobSeekersDetails
  );

  const countriesList = useSelector(
    ({ registerReducer }) => registerReducer.countries
  );
  const industriesList = useSelector(
    ({ registerReducer }) => registerReducer.industries
  );

  const rolesList = useSelector(({ rolesReducer }) => rolesReducer.roles);
  // const users = useSelector(({ usersReducer }) => usersReducer.users);

  // useEffect(() => {
  //   const filterOptions = {
  //     country: "Any",
  //     mainrole: "Any",
  //     gender: "Any",
  //     industry: "Any",
  //     ageMin: 0,
  //     ageMax: 0,
  //     pageNumber: 0,
  //     limit: 10,
  //     state: "Any"
  //   };

  //   dispatch(getJobSeekersDetails(filterOptions));
  // }, [true]);

  useEffect(() => {
    dispatch(requestCountries());
  }, [countriesList && countriesList.length]);

  useEffect(() => {
    dispatch(getRoles());
  }, [rolesList && rolesList.length]);

  useEffect(() => {
    dispatch(requestIndustries());
  }, [industriesList && industriesList.length]);

  const handleFilterJobSeekersList = async (filterOptions) => {
    await dispatch(updateRecentSearchById(filterOptions));
    setTimeout(() => {
      dispatch(getJobSeekersDetails(filterOptions));
    }, 100);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rowData && rowData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  // const handleFilterJobSeekersListBySearch = (value) => {
  //   setSearchText(value);
  // };
  useEffect(() => {
    let data = rowData && rowData;
    setFilteredData(
      data &&
        data.filter((user) =>
          user.username.toLowerCase().includes(searchText.trim().toLowerCase())
        )
    );
  }, [rowData && rowData]);

  const customPadding = {
    padding: "8px 16px",
  };

  const customCellContainer = {
    display: "inline-block",
    paddingRight: "25px",
  };

  return (
    <Paper className={classes.root}>
      {/* {avatarModal && (
        <ImageDialog
          open={avatarModal}
          handleClose={(e) => {
            setAvatarModal(false);
            setModalImage("");
          }}
          src={modalImage}
        />
      )} */}
      <UserProfileListViewTabelToolbar
        numSelected={selected.length}
        totalPages={jobSeekersDetailsTotalPages}
        rolesList={rolesList}
        countriesList={countriesList}
        industriesList={industriesList}
        handleFilter={handleFilterJobSeekersList}
        sendCountry={(country) => setCountry(country)}
        // handleSearch={handleFilterJobSeekersListBySearch}
      />
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          <UserProfileListViewTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={filteredData && filteredData.length}
            country={country}
          />

          <TableBody>
            {fetchingJobSeekersDetails ? (
              <TableRow style={{ height: "100%" }}>
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : filteredData && filteredData.length > 0 ? (
              filteredData.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      style={customPadding}
                    >
                      <Grid container spacing={1} alignItems="center">
                        <Grid item>
                          <Avatar
                            src={
                              process.env.REACT_APP_BUCKET_NAME + row.profilepic
                            }
                            onClick={(e) =>
                              dispatch(
                                openAvatarModal(
                                  process.env.REACT_APP_BUCKET_NAME +
                                    row.profilepic
                                )
                              )
                            }
                            // onClick={(e) => {
                            //   setAvatarModal(true);
                            //   setModalImage( row.profilepic);
                            // }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{ color: constants.PRIMARY_COLOR }}
                            component={Link}
                            onClick={() =>
                              history.push(`/user_profile/${row._id}`)
                            }
                          >
                            {row.username}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell style={customPadding} align="center">
                      <span style={customCellContainer}>{row.mainrole}</span>
                    </TableCell>
                    <TableCell style={customPadding} align="center">
                      <span style={customCellContainer}>
                        {row.noofprofilelikes}
                      </span>
                    </TableCell>
                    <TableCell style={customPadding} align="center">
                      <span style={customCellContainer}>
                        {`${row.industry ? row.industry + " , " : ""} ${
                          row.country
                        }`}
                      </span>
                    </TableCell>
                    {/* {country === "India" && (
                      <TableCell align="center">{row.industry}</TableCell>
                    )} */}
                  </TableRow>
                );
              })
            ) : (
              <TableRow style={{ height: "100%" }}>
                <TableCell colSpan={6} align="center">
                  No records found.
                </TableCell>
              </TableRow>
            )}
            {/* {!fetchingJobSeekersDetails && rowData && rowData.length && 10 - rowData.length > 0 ? (
                <TableRow style={{ height: 53 * (10 - rowData.length) }}>
                  <TableCell colSpan={6} />
                </TableRow>
              ) : ""} */}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
export default withRouter(UserProfilesListViewContainer);
