import React, { useState, useEffect } from "react";
// import { Button } from "../components/Buttons";
import {
  AddOnEmailInput,
  AddOnPasswordInput,
  AddOnPhoneInput,
} from "../components/AddOnInputs";
import { AuGroup } from "../components/AuFormComponent";
import { otpPhone } from "../actions/loginActions";
import withRouter from "react-router-dom/es/withRouter";
import Link from "react-router-dom/es/Link";
import { TextField, Button, Typography } from "@material-ui/core";
import { connect } from "react-redux";

// const buttonDesignClass = {
//   className: "btn au-btn theme-resetPassword",
//   value: "Send Otp ",
// };

// const formInputsTheme = {
//   className: "theme-resetPassword",
// };

const OtpPhone = (props) => {
  const [username, setUsername] = useState("");

  const onSubmitForm = (e) => {
    // e.preventDefault();
    const formData = {
      username: username,
    };
    this.props.dispatch(otpPhone(formData)).then((response) => {
      if (!response.error) {
        props.history.push("/otp-phone-verify");
      }
    });
  };

  return (
    <div className="au-frame au-text au-reset-frame">
      <div className="text-center">
        <div className="au-banner theme-resetPassword">
          <div className="au-bfg">
            <div>
              <img
                src="/assets/images/icons/login-icon.png"
                width="20px"
                alt=""
              />
            </div>
            <div>
              <span>Phone Number</span>
            </div>
          </div>
        </div>
        <span>
          <i className="fa fa-3x fa-caret-down fa-reset-clr au-bcd" />
        </span>
      </div>
      <div className="au-card au-form">
        <form className="form-horizontal">
          <TextField
            required
            id="username-input"
            label="Username"
            fullWidth={true}
            type="text"
            margin="dense"
            className="inherit-bg-color cb-tf"
            onChange={(e) => setUsername(e.target.value)}
            value={username}
          />
          <div className="au-re text-center">
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => onSubmitForm(e)}
              style={{ textTransform: "none" }}

              // buttonDesign={buttonDesignClass}
            >
              Send OTP
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default connect(null)(withRouter(OtpPhone));
