const initialState = {
  purchaseDetailsLoading: true,
  purchaseDetails: null,

  addPointsToBuyLoading: false,

  razorPaySuccessCaptureLoading: false,
  razorPayData: {},
  giftTransactions: [],
  giftTransactionsLoading: true,

  pointPurchases: [],
  pointPurchasesLoading: true,
};

const userDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    //GET GIFT TRANS INFORMATION
    case "GET_GIFT_TRANSACTIONS":
      return {
        ...state,
        giftTransactionsLoading: true,
      };
    case "GET_GIFT_TRANSACTIONS_SUCCESS":
      return {
        ...state,
        giftTransactionsLoading: false,
        giftTransactions: action.payload,
      };
    case "GET_GIFT_TRANSACTIONS_FAILED":
      return {
        ...state,
        giftTransactionsLoading: false,
      };
    //GET PURCHASES INFORMATION
    case "GET_POINT_PURCHASES":
      return {
        ...state,
        pointPurchasesLoading: true,
      };
    case "GET_POINT_PURCHASES_SUCCESS":
      return {
        ...state,
        pointPurchasesLoading: false,
        pointPurchases: action.payload,
      };
    case "GET_POINT_PURCHASES_FAILED":
      return {
        ...state,
        pointPurchasesLoading: false,
      };
    //GET PURCHASE INFORMATION
    case "GET_POINTS_PURCHASE_INFORMATION":
      return {
        ...state,
        purchaseDetailsLoading: true,
      };
    case "GET_POINTS_PURCHASE_INFORMATION_SUCCESS":
      return {
        ...state,
        purchaseDetailsLoading: false,
        purchaseDetails: action.payload,
      };
    case "GET_POINTS_PURCHASE_INFORMATION_FAILED":
      return {
        ...state,
        purchaseDetailsLoading: false,
      };

    //USER ADD SUBSCRIPTIO PLAN

    case "USER_BUY_POINTS":
      return {
        ...state,
        addPointsToBuyLoading: true,
      };
    case "USER_BUY_POINTS_SUCCESS":
      return {
        ...state,
        addPointsToBuyLoading: false,
        addSubscriptionPlanDetails: action.payload,
      };
    case "USER_BUY_POINTS_FAILED":
      return {
        ...state,
        addPointsToBuyLoading: false,
      };

    //RAZOR PAY CAPTURE CCAVENUE

    case "USER_POINTS_RAZORPAY_PAYMENT_CAPTURE":
      return {
        ...state,
        razorPaySuccessCaptureLoading: true,
      };
    case "USER_POINTS_RAZORPAY_PAYMENT_CAPTURE_SUCCESS":
      return {
        ...state,
        razorPaySuccessCaptureLoading: false,
        razorPayData: action.payload,
      };
    case "USER_POINTS_RAZORPAY_PAYMENT_CAPTURE_FAILED":
      return {
        ...state,
        razorPaySuccessCaptureLoading: false,
      };

    default:
      return state;
  }
};

export default userDetailsReducer;
