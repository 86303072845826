import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";
import FaceIcon from "@material-ui/icons/Face";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { openAvatarModal } from "../../actions/appActions";
import getURL from "../../helpers/apis";
import DialogActions from "@material-ui/core/DialogActions";

import {
  getShareableUserList,
  removePrivePic,
} from "../../actions/applicantsDetailsActions";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function CheckboxListSecondary(props) {
  const { galleryId, shareadUserList } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const [checked, setChecked] = React.useState([]);

  const rootUrl = getURL("UPLOADS");

  const removingPrivatePicLoading = useSelector(
    ({ applicantsReducer }) => applicantsReducer.removingPrivatePicLoading
  );

  const handleChangeShareToUserList = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const handleSuccessRemoveSharePrivatePic = () => {
    setChecked([]);
    dispatch(getShareableUserList(galleryId));
  };

  const handleRemoveShareGalleryToUser = () => {
    const body = {
      userId: checked,
    };
    dispatch(
      removePrivePic(galleryId, body, handleSuccessRemoveSharePrivatePic)
    );
  };

  return (
    <>
      <List
        dense
        className={classes.root}
        style={{
          height: "350px",
          overflow: "auto",
        }}
      >
        {shareadUserList &&
          shareadUserList.map((user) => {
            const labelId = `checkbox-list-secondary-label-${user._id}`;
            return (
              <ListItem key={user._id} button>
                <ListItemAvatar>
                  <Avatar
                    alt={user.username}
                    onClick={(e) => dispatch(openAvatarModal(process.env.REACT_APP_BUCKET_NAME + user.profilepic))}
                    src={process.env.REACT_APP_BUCKET_NAME + user.profilepic}
                  />
                </ListItemAvatar>
                <ListItemText
                  id={labelId}
                  primary={user.sharedWith.user_follow_customname}
                  secondary={
                    user.sharedWith.user_follow_customname.startsWith("@")
                      ? user.sharedWith.username
                      : user.sharedWith.username.replace("@", "")
                  }
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    onChange={handleChangeShareToUserList(user.sharedWith._id)}
                    checked={checked.indexOf(user.sharedWith._id) !== -1}
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
      </List>
      <div style={{ margin: "32px 0px" }}>
        {" "}
        {checked.length > 0 &&
          checked.map((user) => {
            const userDetails = shareadUserList.find(
              (usr) => usr.sharedWith._id == user
            );

            return (
              <Chip
                key={userDetails._id}
                style={{ marginTop: "4px", marginLeft: "4px" }}
                icon={<FaceIcon />}
                label={userDetails.sharedWith.username}
                variant="outlined"
              />
            );
          })}
      </div>
      <DialogActions>
        {" "}
        <Button
          onClick={() => props.close()}
          color="primary"
          margin="normal"
          style={{ textTransform: "none" }}
        >
          Cancel
        </Button>
        {removingPrivatePicLoading ? (
          <Button color="primary" variant="contained" margin="normal">
            Removing...
          </Button>
        ) : (
          <Button
            onClick={() => handleRemoveShareGalleryToUser()}
            color="primary"
            variant="contained"
            margin="normal"
            style={{ textTransform: "none" }}
          >
            Remove Share
          </Button>
        )}
      </DialogActions>
    </>
  );
}
