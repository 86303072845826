const postAnPosReducer = (
  state = {
    submittingForm: false,
    reqExp: "",
    positionName: "",
    newPosCreated: false
  },
  action
) => {
  switch (action.type) {
    case "POST_POS_SUBMITTING":
      return {
        ...state,
        submittingForm: true
      };
    case "POST_POS_SUBMITTING_FAILED":
      return {
        ...state,
        submittingForm: false
      };
    case "POST_POS_SUBMITTING_SUCCESS":
      return {
        ...state,
        submittingForm: false,
        newPosCreated: true
      };

    default:
      return state;
  }
};

export default postAnPosReducer;
