import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import bindActionCreators from "redux/es/bindActionCreators";
import withRouter from "react-router-dom/es/withRouter";
import { withStyles } from "@material-ui/core/styles";
import PostPosTable from "../components/PostPosTable";
import Grid from "@material-ui/core/Grid";
import PositionDetailsContainer from "./PositionDetailsContainer";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import CreatePositionForm from "../components/CreatePositionForm";
import {
  isValidParam,
  getStringParam,
  getObjectParam,
  getArrayParam,
  getIntParam,
  getBooleanParam
} from "../helpers/paramVerifier";
import { getPosDetails, applyForPos } from "../actions/getPosActions";
import { getPosApplicants } from "../actions/getPosActions";

const styles = theme => ({
  root: {},
  appBar: {
    height: 30
    //backgroundColor: '#3f51b5b8',
    //borderBottom: '1px solid #3f51b5b8',
  },
  tabs: {
    minHeight: 30
  },
  tab: {
    minHeight: 30,
    fontSize: 14,
    textTransform: "capitalize"
    //color: '#ffffff'
  },
  tabSelected: {
    borderBottom: "2px solid #3f51b5"
  }
});

class PositionApplicantsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      positionId: null,
      status: null
    };
  }

  componentWillMount() {
    let positionId = this.props.match.params.position_id;
  }

  componentDidMount() {
    //dd
    let positionId = this.props.match.params.position_id;
    let status = getStringParam(this.props.match.params.status);
    this.props.getPosDetails(positionId);
    this.props.getPosApplicants(positionId);
    this.setState({ positionId: positionId, status: status });
  }

  tabChange(event, value) {
    this.setState({ selectedTab: value });
  }

  render() {
    let { classes, posDetails, applicants } = this.props;
    let { selectedTab, positionId } = this.state;

    return (
      <Grid item xs={12} className={classes.root}>
        <Grid item xs={12} className={classes.appBar}>
          {posDetails && (
            <Tabs
              value={selectedTab}
              classes={{ root: classes.tabs, indicator: classes.tabSelected }}
              onChange={(e, value) => this.tabChange(e, value)}
            >
              <Tab
                label={getStringParam(posDetails.positionName)}
                className={classes.tab}
                labelContainer={{ heigh: 40 }}
              />
              <Tab
                label="Edit Position"
                className={classes.tab}
                labelContainer={{ heigh: 40 }}
              />
            </Tabs>
          )}
        </Grid>
        {selectedTab == 0 && posDetails && Array.isArray(applicants) && (
          <PositionDetailsContainer
            positionId={positionId}
            status={this.state.status}
            posDetails={posDetails}
            applicants={applicants}
          />
        )}
        {selectedTab == 1 && isValidParam(positionId) && (
          <CreatePositionForm positionId={positionId} posDetails={posDetails} />
        )}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    posDetails: state.PosTableReducer.posDetails,
    applicants: state.applicantsReducer.applicants
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      getPosDetails,
      getPosApplicants
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(PositionApplicantsContainer));
