import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { getArrayParam } from "../helpers/paramVerifier";
import Typography from "@material-ui/core/Typography";
import { constants } from "../helpers/constants";

const styles = (theme) => ({
  root: {
    width: 350,
    height: 400,
  },
});

class ActionsViewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getActionsComponent() {
    let actionComponents = [];
    try {
      let fields = getArrayParam(this.props.fields);
      let actionInfoArr = getArrayParam(this.props.data);
      actionComponents = actionInfoArr.map((info, index) => {
        return (
          <ActionInfoComponent
            key={"action-" + index}
            data={info}
            fields={fields}
          />
        );
      });
    } catch (error) {}
    return actionComponents;
  }

  render() {
    let { classes } = this.props;
    return <Grid className={classes.root}>{this.getActionsComponent()}</Grid>;
  }
}
export default withStyles(styles)(ActionsViewComponent);

class ActionInfoComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getElement() {
    let arr = new Array();
    let fields = getArrayParam(this.props.fields);
    fields.forEach((field) => {
      arr.push(
        <Typography style={{ fontSize: 14 }}>
          {this.props.data[field]}
        </Typography>
      );
    });
    return arr;
  }

  render() {
    return (
      <Grid
        style={{
          width: 350,
          height: 50,
          padding: 5,
          borderBottom: "1px solid #1f285a2e",
        }}
      >
        {this.getElement()}
      </Grid>
    );
  }
}

ActionInfoComponent = withStyles(styles)(ActionInfoComponent);
