const initialState = {
  roles: null,
  jobtypes: null,
};

const rolesReducer = (state = initialState, action) => {
  const alphaNumericRef = /^[0-9]{2}/;

  let roles;

  if (action.type == "GET_JOBTYPES_SUCCESS" ||  action.type== "GET_ROLES_SUCCESS") {
    roles = action.payload?.data?.data;
    const alphaNeumerics = roles.filter(
      (role) => role["name"] && alphaNumericRef.test(role["name"])
    );
    alphaNeumerics.forEach((alpha) => {
      const foundIndex = roles.findIndex(
        (role) => role["_id"] === alpha["_id"]
      );

      if (foundIndex != -1) {
        roles.splice(foundIndex, 1);
      }
    });

    roles.push(...alphaNeumerics);
  }

  switch (action.type) {
    case "GET_ROLES_SUCCESS":
      return {
        ...state,
        roles: roles,
      };
    case "GET_JOBTYPES_SUCCESS":
      return {
        ...state,
        jobtypes:  roles,
      };
    default:
      return state;
  }
};

export default rolesReducer;
