import React from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import bindActionCreators from "redux/es/bindActionCreators";
import { connect } from "react-redux";
import { openDialog, closeDialog } from "../actions/appActions";
import { Redirect } from "react-router-dom";
import { constants } from "../helpers/constants";
import ConfirmationDialog from "../components/ConfirmationDialog";
import TOSDialog from "./TOSDialog";
import ReCAPTCHA from "react-google-recaptcha";
import {
  onPasswordChanged,
  onEmailChanged,
  onFirstNameChanged,
  onLastNameChanged,
  onUsernameChanged,
  onRegisterFormSubmitted,
  onCheckBoxToggle,
  onUserTypeChanged,
  requestCountries,
  requestStates,
  requestDistricts,
  requestIndustries,
} from "../actions/registerActions";
import { getProductFeaturesList } from "../actions/userDetailsAction";

import {
  Button,
  Popper,
  Grid,
  Link,
  MenuItem,
  TextField,
  Checkbox,
  Typography,
  Radio,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import request1 from "../helpers/request";
import getURL from "../helpers/apis";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
});

const usertypeArray = [
  { value: "Employer", label: "Employer" },
  { value: "JobSeeker", label: "JobSeeker" },
  { value: "SocialMediaUser", label: "SocialMediaUser" },
];

const gendersArray = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];
const referrelsArray = [
  { value: "Self", label: "Self" },
  { value: "ReferredBy", label: "Referred By" },
];
const maxAge = `${new Date().getFullYear() - 18}-${`${
  new Date().getMonth() + 101
}`.slice(1, 3)}-${`${new Date().getDate() + 100}`.slice(1, 3)}`;

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // firstname: "",
      // lastname: "",
      email: "",
      username: "",
      // bidAlias: "",
      password: "",
      usertype: "",
      confirmPassword: "",
      gender: "",
      dob: null,
      phone: "",
      phoneCode: "",
      // houseno: "",
      // street: "",
      // city: "",
      country: {},
      // state: "",
      // district: {},
      // industry: {},
      // pincode: "",
      usertype: "",
      referrelUsers: [],
      referrel: "",
      referInputValue: "",
      referrelUser: "",
      termsOfServicesCheckBox: false,
      errors: {},
      signUpDisabled: true,
      dialog: false,
      title: "",
      des: "",
      n: "",
      p: "",
      subs: [],
      TOSDialog: false,
    };
    const recaptchaRef = React.createRef();

    // this.onFirstNameChanged = this.onFirstNameChanged.bind(this);
    // this.onLastNameChanged = this.onLastNameChanged.bind(this);
    this.onEmailChanged = this.onEmailChanged.bind(this);
    this.onUsernameChanged = this.onUsernameChanged.bind(this);
    // this.onAliasChanged = this.onAliasChanged.bind(this);
    this.onPasswordChanged = this.onPasswordChanged.bind(this);
    this.onConfirmPasswordChanged = this.onConfirmPasswordChanged.bind(this);
    this.onGenderChange = this.onGenderChange.bind(this);
    this.onDobChange = this.onDobChange.bind(this);
    this.onPhoneChange = this.onPhoneChange.bind(this);
    //this.onPhoneCodeSelect = this.onPhoneCodeSelect.bind(this);
    // this.onHouseNoChange = this.onHouseNoChange.bind(this);
    // this.onStreetChange = this.onStreetChange.bind(this);
    // this.onCityChange = this.onCityChange.bind(this);
    // this.onDistrictChange = this.onDistrictChange.bind(this);
    // this.onPincodeChange = this.onPincodeChange.bind(this);
    this.onReferrelUserChange = this.onReferrelUserChange.bind(this);
    this.onUserTypeChanged = this.onUserTypeChanged.bind(this);
    this.onreferrelChanged = this.onreferrelChanged.bind(this);
    this.onCheckBoxToggle = this.onCheckBoxToggle.bind(this);
    this.onFormSubmitted = this.onFormSubmitted.bind(this);
    this.onConfirmPasswordChanged = this.onConfirmPasswordChanged.bind(this);
    this.handleDialog = this.handleDialog.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  onSubmit = () => {
    const recaptchaValue = this.recaptchaRef.current.getValue();
    this.props.onSubmit(recaptchaValue);
  };

  componentDidMount() {
    this.props.requestCountries();
    this.props.getProductFeaturesList();
  }

  // onFirstNameChanged(e) {
  //   const { value: firstname } = e.target;
  //   if (firstname.trim() === "") {
  //     return this.setState((state) => ({
  //       firstname,
  //       errors: { ...state.errors, firstname: "cannot be blank" },
  //     }));
  //   }

  //   if (firstname.length > 15) {
  //     return this.setState((state) => ({
  //       firstname,
  //       errors: { ...state.errors, firstname: "maximum 15 characters allowed" },
  //     }));
  //   }

  //   return this.setState((state) => ({
  //     firstname,
  //     errors: { ...state.errors, firstname: null },
  //   }));
  // }

  // onLastNameChanged(e) {
  //   const { value: lastname } = e.target;
  //   if (lastname.trim() === "") {
  //     return this.setState((state) => ({
  //       lastname,
  //       errors: { ...state.errors, lastname: "cannot be blank" },
  //     }));
  //   }

  //   if (lastname.length > 15) {
  //     return this.setState((state) => ({
  //       lastname,
  //       errors: { ...state.errors, lastname: "maximum 15 characters allowed" },
  //     }));
  //   }

  //   return this.setState((state) => ({
  //     lastname,
  //     errors: { ...state.errors, lastname: null },
  //   }));
  // }

  onreferrelChanged(e) {
    const { value: referrel } = e.target;
    const values = referrelsArray.map((referrel) => referrel.value);

    if (values.find((value) => value === referrel)) {
      return this.setState((state) => ({
        referrel,
        errors: { ...state.errors, referrel: null },
      }));
    }

    return this.setState((state) => ({
      referrel,
      errors: { ...state.errors, referrel: "please select a valid referrel" },
    }));
  }
  onEmailChanged(e) {
    const { value: email } = e.target;

    if (email.trim() === "") {
      return this.setState((state) => ({
        email,
        errors: { ...state.errors, email: "cannot be blank" },
      }));
    }

    return this.setState((state) => ({
      email,
      errors: { ...state.errors, email: null },
    }));
  }

  onUsernameChanged(e) {
    const { value: username } = e.target;
    const usernameRegex = new RegExp("^[a-zA-Z0-9-._]+$");
    const getAllSubstrings = (str, size) => {
      var i,
        j,
        result = [];
      size = size || 0;
      for (i = 0; i < str.length; i++) {
        for (j = str.length; j - i >= size; j--) {
          result.push(str.slice(i, j));
        }
      }
      return result;
    };
    var arr = getAllSubstrings(username, 3);
    this.setState((state) => ({
      subs: arr,
    }));
    if (username.trim() === "") {
      return this.setState((state) => ({
        username,
        errors: { ...state.errors, username: "cannot be blank" },
      }));
    }

    if (username.length < 6 || username.length > 15) {
      return this.setState((state) => ({
        username,
        errors: {
          ...state.errors,
          username: "minimum 6 and maximum 15 characters allowed",
        },
      }));
    }

    if (!usernameRegex.test(username)) {
      return this.setState((state) => ({
        username,
        errors: {
          ...state.errors,
          username: "cannot contain special characters except '-', '_', '.'",
        },
      }));
    }

    return this.setState((state) => ({
      username,
      errors: { ...state.errors, username: null },
    }));
  }

  // onAliasChanged(e) {
  //   const { value: bidAlias } = e.target;
  //   const usernameRegex = new RegExp("^[a-zA-Z0-9-._]+$");
  //   const aliasRegex = new RegExp(this.state.subs.join("|"));
  //   if (bidAlias.trim() === "") {
  //     return this.setState((state) => ({
  //       bidAlias,
  //       errors: { ...state.errors, bidAlias: "cannot be blank" },
  //     }));
  //   }

  //   if (bidAlias.length < 6 || bidAlias.length > 15) {
  //     return this.setState((state) => ({
  //       bidAlias,
  //       errors: {
  //         ...state.errors,
  //         bidAlias: "minimum 6 and maximum 15 characters allowed",
  //       },
  //     }));
  //   }

  //   if (!usernameRegex.test(bidAlias)) {
  //     return this.setState((state) => ({
  //       bidAlias,
  //       errors: {
  //         ...state.errors,
  //         bidAlias: "cannot contain special characters except '-', '_', '.'",
  //       },
  //     }));
  //   }

  //   if (aliasRegex.test(bidAlias)) {
  //     return this.setState((state) => ({
  //       bidAlias,
  //       errors: {
  //         ...state.errors,
  //         bidAlias:
  //           "cannot contain any 3 consecutive characters of the login username.",
  //       },
  //     }));
  //   }

  //   return this.setState((state) => ({
  //     bidAlias,
  //     errors: { ...state.errors, bidAlias: null },
  //   }));
  // }

  onPasswordChanged(e) {
    const { value: password } = e.target;

    if (password.trim() === "") {
      return this.setState((state) => ({
        password,
        errors: { ...state.errors, password: "cannot be blank" },
      }));
    }

    if (password.length < 6 || password.length > 15) {
      return this.setState((state) => ({
        password,
        errors: {
          ...state.errors,
          password: "minimum 6 and maximum 15 characters allowed",
        },
      }));
    }

    return this.setState((state) => ({
      password,
      errors: { ...state.errors, password: null },
    }));
  }

  onConfirmPasswordChanged(e) {
    const { value: confirmPassword } = e.target;

    if (confirmPassword !== this.state.password) {
      return this.setState((state) => ({
        confirmPassword,
        errors: {
          ...state.errors,
          confirmPassword: "confirmation password do not match password",
        },
      }));
    }

    return this.setState((state) => ({
      confirmPassword,
      errors: { ...state.errors, confirmPassword: null },
    }));
  }

  onGenderChange(e) {
    const { value: gender } = e.target;
    const values = gendersArray.map((gender) => gender.value);

    if (values.find((value) => value === gender)) {
      return this.setState((state) => ({
        gender,
        errors: { ...state.errors, gender: null },
      }));
    }

    return this.setState((state) => ({
      gender,
      errors: { ...state.errors, gender: "please select a valid Gender" },
    }));
  }

  onDobChange(e) {
    const { value: dob } = e.target;
    const date = new Date(dob);

    if (isNaN(date.getTime())) {
      return this.setState((state) => ({
        dob,
        errors: { ...state.errors, dob: "Please provide a valid date" },
      }));
    }

    const today = new Date();
    const dateDiff = today - date;
    const newDiffDate = new Date(dateDiff);
    // const yearDiff = newDiffDate.getFullYear() - 1970;

    // if (yearDiff < 18) {
    //   return this.setState((state) => ({
    //     dob,
    //     errors: { ...state.errors, dob: "minimum is 18 years" },
    //   }));
    // }

    return this.setState((state) => ({
      dob,
      errors: { ...state.errors, dob: null },
    }));
  }

  onPhoneChange(e) {
    const { value: phone } = e.target;
    const phoneRegex = new RegExp("^\\d+$");

    if (!phoneRegex.test(phone)) {
      return this.setState((state) => ({
        phone,
        errors: { ...state.errors, phone: "can only contains digits" },
      }));
    }

    if (phone.length !== 10) {
      return this.setState((state) => ({
        phone,
        errors: {
          ...state.errors,
          phone: "Please provide a valid Mobile Number",
        },
      }));
    }

    return this.setState((state) => ({
      phone,
      errors: { ...state.errors, phone: null },
    }));
  }

  onPhoneCodeSelect(e, phoneCode) {
    this.setState({ phoneCode });
  }

  // onHouseNoChange(e) {
  //   const { value: houseno } = e.target;

  //   if (houseno.trim() === "") {
  //     return this.setState((state) => ({
  //       houseno,
  //       errors: { ...state.errors, houseno: "cannot be blank" },
  //     }));
  //   }

  //   if (houseno.length > 15) {
  //     return this.setState((state) => ({
  //       houseno,
  //       errors: { ...state.errors, houseno: "maximum 15 characters allowed" },
  //     }));
  //   }

  //   return this.setState((state) => ({
  //     houseno,
  //     errors: { ...state.errors, houseno: null },
  //   }));
  // }

  // onStreetChange(e) {
  //   const { value: street } = e.target;

  //   if (street.trim() === "") {
  //     return this.setState((state) => ({
  //       street,
  //       errors: { ...state.errors, street: "cannot be blank" },
  //     }));
  //   }

  //   if (street.length > 15) {
  //     return this.setState((state) => ({
  //       street,
  //       errors: { ...state.errors, street: "maximum 15 characters allowed" },
  //     }));
  //   }

  //   return this.setState((state) => ({
  //     street,
  //     errors: { ...state.errors, street: null },
  //   }));
  // }

  // onCityChange(e) {
  //   const { value: city } = e.target;

  //   if (city.trim() === "") {
  //     return this.setState((state) => ({
  //       city,
  //       errors: { ...state.errors, city: "cannot be blank" },
  //     }));
  //   }

  //   if (city.length > 20) {
  //     return this.setState((state) => ({
  //       city,
  //       errors: { ...state.errors, city: "maximum 20 characters allowed" },
  //     }));
  //   }

  //   return this.setState((state) => ({
  //     city,
  //     errors: { ...state.errors, city: null },
  //   }));
  // }

  onCountrySelect(e, country) {
    this.props.requestStates(country.country);
    this.setState({ country });
    this.setState({ state: "" });
  }
  onReferrelUserChange(newInputValue) {
    if (newInputValue.length > 0)
      request1(
        "get",
        `${getURL("GET_USERNAMES")}?key=${newInputValue}&page=0&limit=20`
      ).then((response) => {
        console.log(response.data.data);
        this.setState({ referrelUsers: response.data.data });
      });
    this.setState({ referInputValue: newInputValue });
  }
  // onStateSelect(e, state) {
  //   this.props.requestDistricts(state);
  //   this.props.requestIndustries();
  //   this.setState({ state });
  // }

  // onIndustrySelect(e, industry) {
  //   this.setState({ industry });
  // }

  // onDistrictChange(e, district) {
  //   this.setState({ district });
  // }

  // onPincodeChange(e) {
  //   const { value: pincode } = e.target;
  //   // const pincodeRegex = new RegExp("^\\d+$");

  //   // if (!pincodeRegex.test(pincode)) {
  //   //   return this.setState({
  //   //     pincode,
  //   //     errors: { pincode: "Pincode can only contains numbers" },
  //   //   });
  //   // }

  //   // if (pincode.length > 6) {
  //   //   return this.setState({
  //   //     pincode,
  //   //     errors: { pincode: "Maximum of 6 digits allowed" },
  //   //   });
  //   // }

  //   return this.setState({ pincode, errors: { pincode: null } });
  // }

  handleRenderValue(value) {
    return value;
  }

  // onUserTypeChanged(e) {

  //   const { value: value } = e.target;
  //   const { userType: usertype } = value;

  //    const values = this.props.productAllFeatureList.map(user => user.userType);
  //   if (values.find(value => value === usertype)) {
  //     return this.setState(state => ({
  //       usertype,
  //       errors: { ...state.errors, usertype: null }
  //     }));
  //   }

  //   return this.setState(state => ({
  //     errors: { usertype: "please select a valid user role" }
  //   }));
  // }

  onUserTypeChanged(e) {
    const { value: usertype } = e.target;
    const values = usertypeArray.map((user) => user.value);
    if (values.find((value) => value === usertype)) {
      return this.setState((state) => ({
        usertype,
        errors: { ...state.errors, usertype: null },
      }));
    }

    return this.setState((state) => ({
      errors: { usertype: "please select a valid user role" },
    }));
  }

  onCheckBoxToggle(e, checked) {
    if (!checked) {
      return this.setState((state) => ({
        termsOfServicesCheckBox: checked,
        errors: {
          ...state.errors,
          termsOfServicesCheckBox: "Please agree to the terms of services",
        },
      }));
    }

    return this.setState((state) => ({
      termsOfServicesCheckBox: checked,
      errors: { ...state.errors, termsOfServicesCheckBox: null },
    }));
  }

  onFormSubmitted(e) {
    e.preventDefault();
    const errors = Object.keys(this.state.errors).filter(
      (err) => this.state.errors[err]
    );

    if (errors.length === 0) {
      if(!(this.state.referrel === "Self" || (this.state.referrel === "ReferredBy" && this.state.referrelUser))){
        return this.props.openDialog("Please provide valid referrel in form.");
    }
      return this.handleDialog(
        "Please check the below details properly, as you will not be able to change them in the future.",
        "Gender, DOB, EMail, Mobile Number.",
        "Back",
        "Confirm"
      );
    } else {
      return this.props.openDialog("Please provide valid details in the form.");
    }
    console.log(this.state);
  }
  handleDialog(title, des, n, p) {
    return this.setState((state) => ({
      dialog: true,
      title,
      des,
      n,
      p,
    }));
  }

  onConfirm() {
    this.setState((state) => ({
      dialog: false,
      title: "",
      des: "",
      n: "",
      p: "",
    }));
    return this.props.onRegisterFormSubmitted(this.state, this.props.history);
  }

  render() {
    let { classes } = this.props;
    const PopperMy = function (props) {
      return (
        <Popper {...props} style={{ width: 250 }} placement="bottom-start" />
      );
    };
    if (this.props.responseUserDetails) {
      return (
        <Redirect to={`/email-verify/${this.props.responseUserDetails._id}`} />
      );
    }

    return (
      <Grid
        container
        style={{ marginTop: 10 }}
        justify="center"
        alignItems="center"
      >
        {this.state.TOSDialog && (
          <TOSDialog
            open={this.state.TOSDialog}
            handleCancel={() =>
              this.setState((state) => ({
                TOSDialog: false,
                termsOfServicesCheckBox: false,
              }))
            }
            handleConfirm={() =>
              this.setState((state) => ({
                ...this.state,
                TOSDialog: false,
                termsOfServicesCheckBox: true,
              }))
            }
            title={this.state.title}
            description={this.state.des}
            negative={this.state.n}
            positive={this.state.p}
          />
        )}
        {this.state.dialog && (
          <ConfirmationDialog
            open={this.state.dialog}
            handleCancel={() =>
              this.setState((state) => ({
                dialog: false,
                title: "",
                des: "",
                n: "",
                p: "",
              }))
            }
            handleConfirm={() => this.onConfirm()}
            title={this.state.title}
            description={this.state.des}
            negative={this.state.n}
            positive={this.state.p}
          />
        )}
        <Grid xs={8} justify="center" alignItems="center">
          <Grid
            container
            justify="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid xs={10} justify="center" alignItems="center">
              <Typography display="block" style={{ float: "right" }}>
                Already a member?{" "}
                <Link
                  size="small"
                  variant="outlined"
                  component={RouterLink}
                  to={"/login"}
                >
                  Login here
                </Link>{" "}
              </Typography>
            </Grid>
            <Grid xs={10} justify="center" alignItems="center">
              <Typography
                component="h1"
                variant="h4x"
                display="block"
                style={{
                  margin: 10,
                  textAlign: "center",
                }}
              >
                Register
              </Typography>
            </Grid>
          </Grid>

          <Divider />
          <form onSubmit={this.onFormSubmitted}>
            <Grid
              container
              spacing={2}
              justify="space-evenly"
              alignItems="center"
              style={{ marginTop: 25 }}
            >
              <Grid item xs={4} justify="center" alignItems="center">
                <Grid
                  container
                  spacing={0}
                  justify="center"
                  alignItems="center"
                >
                  {/* <Grid item xs={5}>
                    <Typography
                      style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                    >
                      First Name*
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      // variant="outlined"
                      required
                      id="firstname-input"
                      // label="Firstname"
                      fullWidth
                      // margin="dense"
                      // className={classes.fields}
                      onChange={this.onFirstNameChanged}
                      value={this.state.firstname}
                      helperText={this.state.errors.firstname}
                      error={Boolean(this.state.errors.firstname)}
                    />
                  </Grid> */}

                  {/* <Grid item xs={5}>
                    <Typography
                      style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                    >
                      Last Name*
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      // variant="outlined"
                      required
                      id="lastname-input"
                      // label="Lastname"
                      fullWidth
                      // margin="dense"
                      // className={classes.fields}
                      onChange={this.onLastNameChanged}
                      value={this.state.lastname}
                      helperText={this.state.errors.lastname}
                      error={Boolean(this.state.errors.lastname)}
                    />
                  </Grid> */}

                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                    >
                      Email*
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      // variant="outlined"
                      required
                      type="email"
                      id="email-input"
                      // label="Email"
                      fullWidth
                      // margin="dense"
                      // className={classes.fields}
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      onChange={this.onEmailChanged}
                      value={this.state.email}
                      helperText={this.state.errors.email}
                      error={Boolean(this.state.errors.email)}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                    >
                      User Name*
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      // variant="outlined"
                      required
                      id="username-input"
                      // label="Username"
                      fullWidth
                      // margin="dense"
                      // className={classes.fields}
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      onChange={this.onUsernameChanged}
                      value={this.state.username}
                      helperText={this.state.errors.username}
                      error={Boolean(this.state.errors.username)}
                    />
                  </Grid>
                  {/* <Grid item xs={5}>
                    <Typography
                      style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                    >
                      Bid Alias Name*
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      // variant="outlined"
                      required
                      id="username-input"
                      // label="Username"
                      fullWidth
                      // margin="dense"
                      // className={classes.fields}
                      onChange={this.onAliasChanged}
                      value={this.state.bidAlias}
                      helperText={this.state.errors.bidAlias}
                      error={Boolean(this.state.errors.bidAlias)}
                    />
                  </Grid> */}
                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                    >
                      Password*
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      // variant="outlined"
                      required
                      id="password-input"
                      // label="Password"
                      fullWidth
                      // margin="dense"
                      type="password"
                      // className={classes.fields}
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      onChange={this.onPasswordChanged}
                      value={this.state.password}
                      helperText={this.state.errors.password}
                      error={Boolean(this.state.errors.password)}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                    >
                      Confirm Password*
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      // variant="outlined"
                      required
                      id="confirm-password-input"
                      // label="Confirm Password"
                      fullWidth
                      // margin="dense"
                      type="password"
                      // className={classes.fields}
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      onChange={this.onConfirmPasswordChanged}
                      value={this.state.confirmPassword}
                      helperText={this.state.errors.confirmPassword}
                      error={Boolean(this.state.errors.confirmPassword)}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                    >
                      Referrel*
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      // variant="outlined"
                      required
                      id="referrel-input"
                      // label="I want to register as"
                      fullWidth
                      // margin="dense"
                      SelectProps={{ renderValue: this.handleRenderValue }}
                      select
                      // className={classes.fields}
                      onChange={this.onreferrelChanged}
                      value={this.state.referrel}
                      helperText={this.state.errors.referrel}
                      error={Boolean(this.state.errors.referrel)}
                    >
                      <MenuItem
                        style={{ fontSize: "1em" }}
                        key=""
                        value=""
                        disabled
                      >
                        Select Referrel
                      </MenuItem>
                      {referrelsArray.map((option) => {
                        return (
                          <MenuItem
                            style={{ fontSize: "1em" }}
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  {this.state.referrel === "ReferredBy" ? (
                    <>
                      <Grid item xs={5}>
                        <Typography
                          style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                        >
                          Referred By*
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Autocomplete
                          id="referred-by-input"
                          getOptionSelected={(option, value) =>
                            option.username === value.username
                          }
                          freeSolo
                          fullwidth
                          size="small"
                          // className={classes.fields}
                          getOptionLabel={(option) => option.username}
                          value={this.state.referrelUser}
                          onChange={(event, newValue) => {
                            this.setState({ referrelUser: newValue });
                          }}
                          inputValue={this.state.referInputValue}
                          onInputChange={(event, newInputValue) => {
                            this.onReferrelUserChange(newInputValue);
                          }}
                          options={this.state.referrelUsers}
                          renderOption={(option) => (
                            <MenuItem style={{ padding: 0 }}>
                              <ListItemAvatar>
                                <Avatar
                                  src={
                                    process.env.REACT_APP_BUCKET_NAME +
                                    option.profilepic
                                  }
                                />
                              </ListItemAvatar>
                              <ListItemText
                              primary={option.username}
                                secondary={`${option.firstname} ${option.lastname}`}
                              />
                            </MenuItem>
                          )}
                          renderInput={(params) => (
                            <TextField
                              // variant="outlined"
                              {...params}
                              // label="Select Country"
                              fullWidth
                              required
                            />
                          )}
                        />
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={4} justify="center" alignItems="center">
                <Grid
                  container
                  spacing={0}
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                    >
                      Gender*
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      // variant="outlined"
                      required
                      id="gender-input"
                      // label="Gender"
                      fullWidth
                      // margin="dense"
                      select
                      // className={classes.fields}
                      onChange={this.onGenderChange}
                      value={this.state.gender}
                      helperText={this.state.errors.gender}
                      error={Boolean(this.state.errors.gender)}
                    >
                      <MenuItem
                        style={{ fontSize: "1em" }}
                        key=""
                        value=""
                        disabled
                      >
                        Select Gender
                      </MenuItem>
                      {gendersArray.map((option) => {
                        return (
                          <MenuItem
                            style={{ fontSize: "1em" }}
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                    >
                      DOB*
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      // variant="outlined"
                      required
                      id="dob-input"
                      // label="DOB"
                      fullWidth
                      // margin="dense"
                      type="date"
                      inputProps={{
                        max: maxAge,
                      }}
                      // className={classes.fields}
                      onChange={this.onDobChange}
                      value={this.state.dob}
                      InputLabelProps={{ shrink: true }}
                      helperText={this.state.errors.dob}
                      error={Boolean(this.state.errors.dob)}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                    >
                      Mobile No.*
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={3}>
                    <Autocomplete
                      id="country-input"
                      options={this.props.countriesList}
                      fullwidth
                      size="small"
                      disableClearable
                      // className={classes.fields}
                      getOptionLabel={(option) =>
                        "+" + option.mobileCode + " " + option.country
                      }
                      onChange={(e, object) =>
                        this.onPhoneCodeSelect(e, object.mobileCode)
                      }
                      PopperComponent={PopperMy}
                      renderInput={(params) => (
                        <TextField
                          variant="outlined"
                          {...params}
                          size="small"
                          fullWidth
                          required
                        />
                      )}
                    />
                  </Grid> */}
                  <Grid item xs={7}>
                    <TextField
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      // variant="outlined"
                      required
                      id="phone-input"
                      // label="Mobile No"
                      fullWidth
                      // margin="dense"
                      type="tel"
                      // className={classes.fields}
                      onChange={this.onPhoneChange}
                      value={this.state.phone}
                      helperText={this.state.errors.phone}
                      error={Boolean(this.state.errors.phone)}
                    />
                  </Grid>
                  {/* <Grid item xs={5}>
                    <Typography
                      style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                    >
                      House No.*
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      // variant="outlined"
                      required
                      id="houseno-input"
                      // label="House No"
                      fullWidth
                      // margin="dense"
                      // className={classes.fields}
                      onChange={this.onHouseNoChange}
                      value={this.state.houseno}
                      helperText={this.state.errors.houseno}
                      error={Boolean(this.state.errors.houseno)}
                    />
                  </Grid> */}

                  {/* <Grid item xs={5}>
                    <Typography
                      style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                    >
                      Street*
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      // variant="outlined"
                      required
                      id="street-input"
                      // label="Street No"
                      fullWidth
                      // margin="dense"
                      // className={classes.fields}
                      onChange={this.onStreetChange}
                      value={this.state.street}
                      helperText={this.state.errors.street}
                      error={Boolean(this.state.errors.street)}
                    />
                  </Grid> */}

                  {/* <Grid item xs={5}>
                    <Typography
                      style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                    >
                      City*
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      // variant="outlined"
                      required
                      id="city-input"
                      // label="City"
                      fullWidth
                      // margin="dense"
                      // className={classes.fields}
                      onChange={this.onCityChange}
                      value={this.state.city}
                      helperText={this.state.errors.city}
                      error={Boolean(this.state.errors.city)}
                    />
                  </Grid> */}

                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                    >
                      Country*
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Autocomplete
                      id="country-input"
                      options={this.props.countriesList}
                      fullwidth
                      size="small"
                      // className={classes.fields}
                      getOptionLabel={(option) => option.country}
                      onChange={(e, object) => this.onCountrySelect(e, object)}
                      renderInput={(params) => (
                        <TextField
                          inputProps={{
                            autocomplete: "new-password",
                            form: {
                              autocomplete: "off",
                            },
                          }}
                          // variant="outlined"
                          {...params}
                          // label="Select Country"
                          fullWidth
                          required
                        />
                      )}
                    />
                  </Grid>

                  {/* <Grid item xs={5}>
                    <Typography
                      style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                    >
                      State*
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Autocomplete
                      id="state-input"
                      options={this.props.statesList}
                      fullwidth
                      size="small"
                      getOptionLabel={(option) => option}
                      // className={classes.fields}
                      onChange={(e, object) => this.onStateSelect(e, object)}
                      disabled={!Boolean(this.state.country.country)}
                      renderInput={(params) => (
                        <TextField
                          inputProps={{
                            autocomplete: "new-password",
                            form: {
                              autocomplete: "off",
                            },
                          }}
                          // variant="outlined"
                          {...params}
                          // label="Select State"
                          fullWidth
                          required
                        />
                      )}
                    />
                  </Grid> */}

                  {/* {this.state.country.country === "India" && (
                    <>
                      <Grid item xs={5}>
                        <Typography
                          style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                        >
                          District*
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Autocomplete
                          id="district-input"
                          options={this.props.districtsList}
                          getOptionLabel={(option) => option.district}
                          // className={classes.fields}
                          fullwidth
                          size="small"
                          onChange={(e, object) =>
                            this.onDistrictChange(e, object)
                          }
                          disabled={!Boolean(this.state.state)}
                          renderInput={(params) => (
                            <TextField
                              inputProps={{
                                autocomplete: "new-password",
                                form: {
                                  autocomplete: "off",
                                },
                              }}
                              // variant="outlined"
                              {...params}
                              // label="Select District"
                              fullWidth
                              required
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )} */}

                  {/* {this.state.country.country === "India" && (
                    <>
                      <Grid item xs={5}>
                        <Typography
                          style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                        >
                          Industry*
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Autocomplete
                          id="industry-input"
                          options={this.props.industriesList}
                          getOptionLabel={(option) => option.industry}
                          // className={classes.fields}
                          fullwidth
                          size="small"
                          onChange={(e, object) =>
                            this.onIndustrySelect(e, object)
                          }
                          disabled={!Boolean(this.state.state)}
                          renderInput={(params) => (
                            <TextField
                              inputProps={{
                                autocomplete: "new-password",
                                form: {
                                  autocomplete: "off",
                                },
                              }}
                              // variant="outlined"
                              {...params}
                              // label="Select Industry"
                              fullWidth
                              required
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )} */}

                  {/* <Grid item xs={5}>
                    <Typography
                      style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                    >
                      Pincode*
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      // variant="outlined"
                      required
                      id="pincode-input"
                      // label="Pin Code"
                      fullWidth
                      // margin="dense"
                      // className={classes.fields}
                      onChange={this.onPincodeChange}
                      value={this.state.pincode}
                      helperText={this.state.errors.pincode}
                      error={Boolean(this.state.errors.pincode)}
                    />
                  </Grid> */}

                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                    >
                      UserType*
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    {!!this.props.productAllFeatureList.length && (
                      <TextField
                        // variant="outlined"
                        required
                        id="user-type-input"
                        // label="I want to register as"
                        fullWidth
                        // margin="dense"
                        SelectProps={{ renderValue: this.handleRenderValue }}
                        select
                        // className={classes.fields}
                        onChange={this.onUserTypeChanged}
                        value={this.state.usertype}
                        helperText={this.state.errors.usertype}
                        error={Boolean(this.state.errors.usertype)}
                      >
                        <MenuItem
                          style={{ fontSize: "1em" }}
                          key=""
                          value=""
                          disabled
                        >
                          Select User Type
                        </MenuItem>
                        {this.props.productAllFeatureList.map((option) => {
                          return (
                            <MenuItem
                              key={option.userType}
                              value={option.userType}
                              style={{ fontSize: "1em", display: "flow-root" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <Radio
                                    checked={
                                      this.state.usertype === option.userType
                                    }
                                    name="radio-button-demo"
                                    inputProps={{ "aria-label": "A" }}
                                  />
                                  <span>
                                    {/* {option.userType} */}
                                    {option.userType === "Employer"
                                      ? "Production House/Employer"
                                      : option.userType === "JobSeeker"
                                      ? "Opportunity Seeker/JobSeeker"
                                      : option.userType === "SocialMediaUser"
                                      ? "Social Media User"
                                      : ""}
                                  </span>
                                </div>

                                <span>{option.price}</span>
                              </div>

                              <ul>
                                {option.features.map((feature) => {
                                  return <li>{feature}</li>;
                                })}
                              </ul>
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} align="center">
                <form onSubmit={this.onSubmit}>
                  <ReCAPTCHA
                    ref={this.recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                    onChange={() => this.setState({ signUpDisabled: false })}
                  />
                </form>
              </Grid>
              {/* <Grid item xs={12} align="center">
                <Link
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      TOSDialog: true,
                      title: "Terms of Service",
                      des: "Text comes here",
                      n: "I Disagree.",
                      p: "I Agree.",
                    })
                  }
                  style={{ fontSize: "1.2em" }}
                >
                  Terms of Service
                </Link>
              </Grid> */}

              {/* <Grid item xs={12} align="center">
                <Typography
                  // margin="dense"
                  component="label"
                  style={{ fontSize: "1.2em" }}
                >
                  <Checkbox
                    onChange={this.onCheckBoxToggle}
                    checked={this.state.termsOfServicesCheckBox}
                    value={this.state.termsOfServicesCheckBox ? "checked" : ""}
                    required
                    disabled={!this.state.termsOfServicesCheckBox}
                  />
                  I have read and agree to the Terms of Service
                </Typography>

                {this.state.errors.termsOfServicesCheckBox && (
                  <Typography
                    // margin="dense"
                    component="h6"
                    variant="h6"
                    fontSize="1em"
                    color="error"
                  >
                    {this.state.errors.termsOfServicesCheckBox}
                  </Typography>
                )}
              </Grid> */}

              <Grid item xs={12} align="center">
                <Typography
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      TOSDialog: true,
                      title: "Terms of Service",
                      des: "Text comes here",
                      n: "I Disagree.",
                      p: "I agree.",
                    })
                  }
                  // margin="dense"
                  component="label"
                  style={{ fontSize: "1.2em" }}
                >
                  <Checkbox
                    onChange={this.onCheckBoxToggle}
                    checked={this.state.termsOfServicesCheckBox}
                    value={this.state.termsOfServicesCheckBox ? "checked" : ""}
                    required
                    disabled={!this.state.termsOfServicesCheckBox}
                  />
                  I have read and agree to the Terms of Service
                </Typography>
              </Grid>

              <Grid item xs={12} align="center">
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={
                    this.state.error ||
                    this.state.signUpDisabled ||
                    this.props.registering ||
                    !this.state.termsOfServicesCheckBox
                  }
                  style={{ textTransform: "none" }}
                >
                  Sign up
                </Button>
              </Grid>
              <Grid item xs={12} align="center">
                <Typography>
                  Already a member?{" "}
                  <Link component={RouterLink} to={"/login"}>
                    Login here
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    countriesList: state.registerReducer.countries,
    statesList: state.registerReducer.states,
    districtsList: state.registerReducer.districts,
    industriesList: state.registerReducer.industries,
    responseUserDetails: state.registerReducer.userDetail,
    registering: state.registerReducer.registering,
    productAllFeatureList: state.userDetailsReducer.productAllFeatureList,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      // onFirstNameChanged,
      // onLastNameChanged,
      onEmailChanged,
      onUsernameChanged,
      onCheckBoxToggle,
      onUserTypeChanged,
      onRegisterFormSubmitted,
      onPasswordChanged,
      requestCountries,
      requestStates,
      requestDistricts,
      requestIndustries,
      getProductFeaturesList,
      openDialog,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RegisterForm))
);
