import React from "react";
import { forgotPassword } from "../actions/loginActions";
import { Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";

import { TextField, Button, Link, Grid, Typography } from "@material-ui/core";

class ForgetPasswordComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: "" };
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
  }

  onSubmitForm(e) {
    e.preventDefault();
    this.props.dispatch(forgotPassword(this.state.email)).then((response) => {
      if (!response.error) {
        this.setState({
          email: "",
        });
      }
    });
  }
  onEmailChange(e) {
    e.preventDefault();
    this.setState({
      email: e.target.value,
    });
  }
  render() {
    return (
      <Grid container className="mui-center-grid">
        <Grid item xs={4} className="mui-inner-grid">
          <Typography component="h3" variant="h3" className="heading-center">
            Reset Password
          </Typography>
          <form onSubmit={this.onSubmitForm}>
            <TextField
              required
              id="email-input"
              label="Email"
              fullWidth={true}
              margin="dense"
              className="inherit-bg-color cb-tf"
              onChange={this.onEmailChange}
              value={this.state.email}
            />
            <Typography className="text-center mtop15">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ textTransform: "none" }}
              >
                Send Email
              </Button>
            </Typography>
          </form>
          <Typography
            component="h5"
            variant="h5"
            className="text-center mtop20"
          >
            I remember it now, take me back to&nbsp;{" "}
            <Link component={RouterLink} to={"/login"}>
              Login
            </Link>
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default connect(null)(ForgetPasswordComponent);
