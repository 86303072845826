import React from "react";
import { Route, Switch } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import ProfileSubscriptions from "./ProfileSubscriptions";

export default class SubscriptionContainer extends React.Component {
  render() {
    const match = this.props.match;

    return (
      <Grid container>
        <Switch>
          <Route
            path={`${match.url}/`}
            exact
            component={ProfileSubscriptions}
          />
        </Switch>
      </Grid>
    );
  }
}
