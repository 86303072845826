import request from "../helpers/requestEngine";
import request1 from "../helpers/request";
import { sendHTTPRequest } from "../helpers/requestEngine";
// // import snackbar from "snackbar";
import getURL from "../helpers/apis";
import { isValidParam, getStringParam } from "../helpers/paramVerifier";
import { openDialog, closeDialog } from "./appActions";
import { getAppliedADs } from "./getADsActions";

export const flagClosed = (data) => {
  return {
    type: "CONFIRM_ROLE",
    payload: data && data.data,
  };
};

export const confirmRole = (_id, path) => {
  return (dispatch) => {
    dispatch(confirmingRole());
    return dispatch(requestForRoleConfirm(_id, path));
  };
};

function confirmingRole() {
  return {
    type: "CONFIRMING_ROLE",
  };
}

function requestForRoleConfirm(_id, path) {
  return (dispatch) => {
    request("post", "CONFIRM_ROLE", true, {
      positionId: _id,
    }).then(
      (response) => {
        // snackbar.show(response.data.usrMsg);
        dispatch(openDialog(response.data.usrMsg || "Done!"));

        if (response.data.error) {
          dispatch(errorConfirmingRole());
        } else {
          dispatch(flagClosed(response.data && response.data));
          // path === "/applied-jobs"
          // ? dispatch(getAppliedADs("ALL"))
          // : dispatch(getPositionDetailsById(_id));
        }
      },
      (err) => {
        if (err.response.data.error && err.response.data.status == 200) {
          // snackbar.show(err.response.data.data);
          dispatch(
            openDialog(
              err.response.data.data ||
                "Something went wrong while confirming role."
            )
          );
          dispatch(openDialog("Done!"));

          dispatch(flagClosed());
          // path === "/applied-jobs"
          // ? dispatch(getAppliedADs("ALL"))
          // : dispatch(getPositionDetailsById(_id));
        } else {
          // snackbar.show("Something went wrong while applying for Pos.");
          dispatch(openDialog("Something went wrong while confirming role."));

          dispatch(errorConfirmingRole());
        }
      }
    );
  };
}

function errorConfirmingRole() {
  return {
    type: "ERROR_CONFIRM_ROLE",
  };
}

export const flagCancelled = (data) => {
  return {
    type: "CANCELLED_APPLICATION",
    payload: data && data.data,
  };
};

export const cancelPosApplication = (_id, path) => {
  return (dispatch) => {
    dispatch(cancellingApp());
    return dispatch(requestForAppCancel(_id, path));
  };
};

function cancellingApp() {
  return {
    type: "CANCELLING_POSITION_APPLICATION",
  };
}

function requestForAppCancel(_id, path) {
  return (dispatch) => {
    request("put", "CANCEL_POSITION_APPLICATION", true, {
      positionId: _id,
    }).then(
      (response) => {
        // snackbar.show(response.data.usrMsg);
        dispatch(openDialog(response.data.usrMsg));

        if (response.data.error) {
          dispatch(errorCancellingPosApp());
        } else {
          dispatch(flagCancelled(response.data && response.data));
          path === "/applied-jobs"
            ? dispatch(getAppliedADs("ALL"))
            : dispatch(getPositionDetailsById(_id));
        }
      },
      (err) => {
        if (err.response.data.error && err.response.data.status == 200) {
          // snackbar.show(err.response.data.data);
          dispatch(openDialog(err.response.data.data));

          dispatch(flagCancelled());
          path === "/applied-jobs"
            ? dispatch(getAppliedADs("ALL"))
            : dispatch(getPositionDetailsById(_id));
        } else {
          // snackbar.show("Something went wrong while applying for Pos.");
          dispatch(
            openDialog("Something went wrong while cancelling application.")
          );

          dispatch(errorCancellingPosApp());
        }
      }
    );
  };
}

function errorCancellingPosApp() {
  return {
    type: "ERROR_CANCEL_POSITION_APPLICATION",
  };
}

export const flagApplied = (data) => {
  return {
    type: "APPLIED_FOR_POS",
    payload: data && data.data,
  };
};

export const applyForPos = (_id) => {
  return (dispatch) => {
    dispatch(applyingForPos());
    return dispatch(requestForPosApply(_id));
  };
};

function applyingForPos() {
  return {
    type: "APPLYING_FOR_POS",
  };
}

function requestForPosApply(_id) {
  return (dispatch) => {
    request("put", "APPLY_FOR_POS", true, {
      positionId: _id,
    }).then(
      (response) => {
        // snackbar.show(response.data.usrMsg);
        dispatch(openDialog(response.data.usrMsg));

        if (response.data.error) {
          dispatch(errorApplyingPos());
        } else {
          dispatch(flagApplied(response.data && response.data));
          dispatch(getPositionDetailsById(_id));
        }
      },
      (err) => {
        if (err.response.data.error && err.response.data.status == 200) {
          // snackbar.show(err.response.data.data);
          dispatch(openDialog(err.response.data.data));

          dispatch(flagApplied());
          dispatch(getPositionDetailsById(_id));
        } else {
          // snackbar.show("Something went wrong while applying for Pos.");
          dispatch(openDialog("Something went wrong while applying."));

          dispatch(errorApplyingPos());
        }
      }
    );
  };
}

function errorApplyingPos() {
  return {
    type: "ERROR_APPLY_Pos",
  };
}

export const changeCurrentPos = (currentPos, applied) => {
  return {
    type: "CURRENT_POS",
    currentPos: currentPos,
    applied: applied,
  };
};

export const getPosByUser = (dept, job_id) => {
  return (dispatch) => {
    dispatch(fetchingPos());

    return dispatch(fetchPos(dept, job_id));
  };
};

function fetchingPos() {
  return {
    type: "FETCHING_POS",
  };
}

function fetchPos(dept, job_id) {
  return (dispatch) => {
    request1(
      "get",
      getURL("GET_POSITIONS_BY_USER") + `/${job_id}`,
      true,
      {},
      { dept, job_id }
    ).then(
      (response) => dispatch(fetchPosSuccess(response.data)),
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));

        dispatch(fetchPosFailed(err));
      }
    );
  };
}

function fetchPosSuccess(data) {
  return {
    type: "POS_FETCHED_SUCCESS",
    payload: data.data,
  };
}

function fetchPosFailed(err) {
  return {
    type: "POS_FETCHED_FAILED",
  };
}

/////////////////////////////////

export const getPosByStatus = (status) => {
  return (dispatch) => {
    dispatch(fetchingPos());

    return dispatch(fetchPosByStatus(status));
  };
};

// function fetchingPosByStatus() {
//   return {
//     type: "FETCHING_POS_BY_STATUS"
//   };
// }

function fetchPosByStatus(status) {
  return (dispatch) => {
    request1(
      "get",
      getURL("GET_POSITIONS_BY_STATUS") + `positionStatus=${status}`,
      true,
      {},
      {}
    ).then(
      (response) => {
        dispatch(fetchPosSuccess(response.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));

        dispatch(fetchPosFailed(err));
      }
    );
  };
}

// function fetchPosByStatusSuccess(data) {
//   return {
//     type: "POS_FETCHED_BY_STATUS_SUCCESS",
//     payload: data.data
//   };
// }

// function fetchPosByStatusFailed(err) {
//   return {
//     type: "POS_FETCHED_BY_STATUS_FAILED"
//   };
// }

//////////////////////////

export const getPosApplicants = (pos_id) => {
  return (dispatch) => {
    return dispatch(fetchPosApplicants(pos_id));
  };
};

function fetchPosApplicants(pos_id) {
  return (dispatch) => {
    request1("get", getURL("GET_APPLICANT") + `${pos_id}`, true, null, {}).then(
      (response) => {
        dispatch(applicantsFetchedSuccess(response.data.data));
      },
      (err) => {
        // dispatch()
      }
    );
  };
}

function applicantsFetchedSuccess(applicants) {
  return {
    applicants: applicants,
    type: "APPLICANTS_FETCHED_SUCCESS",
  };
}

export const getPosDetails = (pos_id) => {
  return (dispatch) => {
    dispatch(fetchingPosDetails());

    return dispatch(fetchPosDetails(pos_id));
  };
};

function fetchingPosDetails() {
  return {
    type: "FETCHING_POS_DETAILS",
  };
}

function fetchPosDetails(pos_id) {
  return (dispatch) => {
    request1(
      "get",
      getURL("GET_POS_DETAILS") + `/${pos_id}`,
      true,
      {},
      {}
    ).then(
      (response) => dispatch(fetchPosDetailsSuccess(response.data)),
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));

        dispatch(fetchPosDetailsFailed(err));
      }
    );
  };
}

function fetchPosDetailsSuccess(data) {
  return {
    type: "POS_FETCHED_DETAILS_SUCCESS",
    payload: data,
  };
}

function fetchPosDetailsFailed(err) {
  return {
    type: "POS_FETCHED_DETAILS_FAILED",
  };
}

export const getEmpAllPos = () => {
  return (dispatch) => {
    dispatch(fetchingPos());

    return dispatch(fetchAllEmpPos());
  };
};

function fetchAllEmpPos() {
  return (dispatch) => {
    request1("get", getURL("GET_ALL_EMP_POSITIONS"), true, {}, {}).then(
      (response) => dispatch(fetchPosSuccess(response.data)),
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));

        dispatch(fetchPosFailed(err));
      }
    );
  };
}

export const getIsUserAppliedToPosition = (positionId) => {
  let url = getURL("GET_IS_USER_APPLIED_TO_POSITION") + positionId;
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}));
  return promise;
};

// GET_JOB_SEEKERS_PROFILE: '/api/user/getusers'

export const getJobSeekersDetails = (filterOptions) => {
  return (dispatch) => {
    dispatch(fetchingJobSeekersDetails());

    return dispatch(fetchJobSeekersDetails(filterOptions));
  };
};

function fetchingJobSeekersDetails() {
  return {
    type: "FETCH_JOB_SEEKERS_PROFILE",
  };
}

function fetchJobSeekersDetails(filterOptions) {
  const { pageNumber, limit, key } = filterOptions;

  let getUrl =
    getURL("GET_JOB_SEEKERS_PROFILE") +
    `?page=${pageNumber}&limit=${limit}&key=${key}`;
  return (dispatch) => {
    request1("get", getUrl, true, {}, {}).then(
      (response) => {
        dispatch(fetchJobSeekersDetailsSuccess(response.data.data));
      },

      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));

        dispatch(fetchJobSeekersDetailsFailed(err));
      }
    );
  };
}

function fetchJobSeekersDetailsSuccess(data) {
  return {
    type: "FETCH_JOB_SEEKERS_PROFILE_SUCCESS",
    payload: data,
  };
}

function fetchJobSeekersDetailsFailed(err) {
  return {
    type: "FETCH_JOB_SEEKERS_PROFILE_FAILED",
  };
}

// UPDATE_RECENT_SEARCH: '/api/search/updatRecentSearch'

export const updateRecentSearchById = (filterOptions) => {
  return (dispatch) => {
    dispatch(updatingRecentSearch());

    return dispatch(updateRecentSearch(filterOptions));
  };
};

function updatingRecentSearch() {
  return {
    type: "UPDATE_RECENT_SEARCH",
  };
}

function updateRecentSearch(filterOptions) {
  const {
    country,
    gender,
    mainrole,
    industry,
    ageMin,
    ageMax,
    state,
    compensation,
    eligibility,
    status,
    posted,
    positionType,
  } = filterOptions;
  var body = {};
  body.searchAgeMax = ageMax;
  body.searchAgeMin = ageMin;
  body.searchGender = gender;
  body.searchRole = mainrole;
  body.searchIndustry = industry;
  body.searchType = positionType || "";
  body.searchCompensation = compensation || "";
  body.searchEligibility = eligibility;
  body.searchStatus = status;
  body.searchPosted = posted;
  body.searchCountry = country;
  body.searchState = state;
  body.searchName = "Recent";

  // let getUrl =
  //   getURL("UPDATE_RECENT_SEARCH") +
  //   `?searchAgeMax=${ageMax}&searchAgeMin=${ageMin}&searchGender=${gender}&searchRole=${mainrole}&searchIndustry=${industry}&searchCountry=${country}&searchState=${states}&searchName=Recent`;
  return (dispatch) => {
    request1("post", getURL("UPDATE_RECENT_SEARCH"), true, body, {}).then(
      (response) => {
        dispatch(updateRecentSearchSuccess(response.data));
      },

      (err) => {
        // snackbar.show("Something went wrong.");

        dispatch(openDialog("Something went wrong."));

        dispatch(updateRecentSearchFailed(err));
      }
    );
  };
}

function updateRecentSearchSuccess(data) {
  return {
    type: "UPDATE_RECENT_SEARCH_SUCCESS",
    payload: data,
  };
}

function updateRecentSearchFailed(err) {
  return {
    type: "UPDATE_RECENT_SEARCH_FAILED",
  };
}

//  UPDATE STATUS OF APPLICANT FOR POSITION
export const editApplicantStatus = (applicantId, body) => {
  return (dispatch) => {
    dispatch(updatingApplicantStatus());

    return dispatch(updateApplicantStatus(applicantId, body));
  };
};

function updatingApplicantStatus() {
  return {
    type: "UPDATE_APPLICANT_STATUS",
  };
}

function updateApplicantStatus(applicantId, body) {
  return (dispatch) => {
    request1(
      "post",
      `${getURL("GET_POS_DETAILS")}/${applicantId}`,
      true,
      body,
      {}
    ).then(
      (response) => {
        dispatch(updateApplicantStatusSuccess(response.data.data));
      },

      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));

        dispatch(updateApplicantStatusFailed(err));
      }
    );
  };
}

function updateApplicantStatusSuccess(data) {
  return {
    type: "UPDATE_APPLICANT_STATUS_SUCCESS",
    payload: data,
  };
}

function updateApplicantStatusFailed(err) {
  return {
    type: "UPDATE_APPLICANT_STATUS_FAILED",
  };
}

//  UPDATE STATUS OF POSITION
export const closePositionStatus = (positionId, body) => {
  return (dispatch) => {
    dispatch(updatingPositionStatus());

    return dispatch(updatePositionStatus(positionId, body));
  };
};

function updatingPositionStatus() {
  return {
    type: "UPDATE_POSITION_STATUS",
  };
}

function updatePositionStatus(positionId, body) {
  return (dispatch) => {
    request1(
      "post",
      `${getURL("CHANGE_POS_STATUS")}/${positionId}`,
      true,
      body,
      {}
    ).then(
      (response) => {
        dispatch(updatePositionStatusSuccess(response.data.data));

        if (response.data.error) {
          // snackbar.show(
          //   response.data.msg || "Something went wrong. Please try again."
          // );
          dispatch(
            openDialog(
              response.data.msg || "Something went wrong. Please try again."
            )
          );
        } else {
          // snackbar.show("Position status has been updated.");
          dispatch(openDialog("Closed successfully."));
        }
      },

      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));

        dispatch(updatePositionStatusFailed(err));
      }
    );
  };
}

function updatePositionStatusSuccess(data) {
  return {
    type: "UPDATE_POSITION_STATUS_SUCCESS",
    payload: data,
  };
}

function updatePositionStatusFailed(err) {
  return {
    type: "UPDATE_POSITION_STATUS_FAILED",
  };
}

// DELETE POSITION
export const deletePositionById = (positionId) => {
  return (dispatch) => {
    dispatch(deletingPosition());

    return dispatch(deletePosition(positionId));
  };
};

function deletingPosition() {
  return {
    type: "DELETE_POSITION",
  };
}

function deletePosition(positionId) {
  return (dispatch) => {
    request1(
      "delete",
      `${getURL("DELETE_POS")}${positionId}`,
      true,
      null,
      {}
    ).then(
      (response) => {
        dispatch(deletePositionSuccess(response.data.data));

        if (response.data.error) {
          // snackbar.show(
          //   response.data.msg || "Something went wrong. Please try again."
          // );
        } else {
          dispatch(getPosByStatus("All"));
          // snackbar.show("Position deleted.");
          dispatch(openDialog("Position deleted."));
        }
      },

      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));

        dispatch(deletePositionFailed(err));
      }
    );
  };
}

function deletePositionSuccess(data) {
  return {
    type: "DELETE_POSITION_SUCCESS",
    payload: data,
  };
}

function deletePositionFailed(err) {
  return {
    type: "DELETE_POSITION_FAILED",
  };
}

// UPDATE POSITION DETAILS
export const editPositionDetails = (body, successCallback) => {
  return (dispatch) => {
    dispatch(updatingPositionDetails());

    return dispatch(updatePositionDetails(body, successCallback));
  };
};

function updatingPositionDetails() {
  return {
    type: "UPDATE_POSITION_DETAILS",
  };
}

function updatePositionDetails(body, successCallback) {
  return (dispatch) => {
    request1("post", `${getURL("UPDATE_POS_DETAILS")}`, true, body, {}).then(
      (response) => {
        if (!response.data.error) {
          dispatch(
            updatePositionDetailsSuccess({
              response: response.data.positionDetail,
              _id: body.positionId,
            })
          );
          successCallback && successCallback();
        }

        if (response.data.error) {
          dispatch(updatePositionDetailsFailed(response.data.errors));
          // snackbar.show(
          //   response.data.errors.msg ||
          //     "Something went wrong. Please try again."
          // );
          dispatch(
            openDialog(
              response.data.errors.msg ||
                "Something went wrong. Please try again."
            )
          );
        } else {
          // snackbar.show("Position Details have been updated.");
          dispatch(openDialog("Role details have been updated."));
        }
      },

      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));

        dispatch(updatePositionDetailsFailed(err));
      }
    );
  };
}

function updatePositionDetailsSuccess(data) {
  return {
    type: "UPDATE_POSITION_DETAILS_SUCCESS",
    payload: data,
  };
}

function updatePositionDetailsFailed(err) {
  return {
    type: "UPDATE_POSITION_DETAILS_FAILED",
    payload: err,
  };
}

// GET POSITION DETAILS BY ID

export const getPositionDetailsById = (pos_id) => {
  return (dispatch) => {
    dispatch(fetchingPositionDetailsById());

    return dispatch(fetchPositionDetailsById(pos_id));
  };
};

function fetchingPositionDetailsById() {
  return {
    type: "FETCHING_POSITION_BY_ID",
  };
}

function fetchPositionDetailsById(pos_id) {
  return (dispatch) => {
    request1(
      "get",
      getURL("GET_POSITIONS_BY_ID") + `${pos_id}`,
      true,
      {},
      {}
    ).then(
      (response) => {
        dispatch(fetchPositionDetailsByIdSuccess(response.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));

        dispatch(fetchPositionDetailsByIdFailed(err));
      }
    );
  };
}

function fetchPositionDetailsByIdSuccess(data) {
  return {
    type: "FETCHING_POSITION_BY_ID_SUCCESS",
    payload: data.data,
  };
}

function fetchPositionDetailsByIdFailed(err) {
  return {
    type: "FETCHING_POSITION_BY_ID_FAILED",
  };
}

// CLONE POSITION
export const clonePosition = (body) => {
  return (dispatch) => {
    dispatch(clonePositionId());
    return dispatch(clonePositionDetailsById(body));
  };
};
function clonePositionId() {
  return {
    type: "CLONE_POSITION_BY_ID",
  };
}
function clonePositionDetailsByIdSuccess(data) {
  return {
    type: "CLONE_POSITION_SUCCESS_BY_ID",
    payload: data.data,
  };
}
function clonePositionDetailsByIdFailed(err) {
  return {
    type: "CLONE_POSITION_FAILED_BY_ID",
  };
}
function clonePositionDetailsById(body) {
  const token = localStorage.getItem("jwt");

  return (dispatch) => {
    request1("post", getURL("CLONE_POSITIONS"), true, body, {}).then(
      (response) => {
        dispatch(clonePositionDetailsByIdSuccess(response.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));

        dispatch(clonePositionDetailsByIdFailed(err));
      }
    );
  };
}

//GET POSITIONS BY USER

export const getFilteredPositionListByUser = (user_id, page, limit) => {
  return (dispatch) => {
    dispatch(fetchFilteredPositionListByUser());

    return dispatch(fetchingPositinListByUser(user_id, page, limit));
  };
};

function fetchFilteredPositionListByUser() {
  return {
    type: "FETCH_FILTERED_POSITION_LIST_BY_USER",
  };
}

function fetchingPositinListByUser(user_id, page, limit) {
  return (dispatch) => {
    request1(
      "get",
      getURL("FILTER_POSITIONS") + `/${user_id}?page=${page}&limit=${limit}`,
      true,
      {},
      {}
    ).then(
      (response) => {
        dispatch(fetchFilteredPositionListByUserSuccess(response.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));

        dispatch(fetchFilteredPositionListByUserFailed(err));
      }
    );
  };
}

function fetchFilteredPositionListByUserSuccess(data) {
  return {
    type: "FETCH_FILTERED_POSITION_LIST_BY_USER_SUCCESS",
    payload: data,
  };
}

function fetchFilteredPositionListByUserFailed(err) {
  return {
    type: "FETCH_FILTERED_POSITION_LIST_BY_USER_FAILED",
  };
}
