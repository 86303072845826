import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  TextField,
  MenuItem,
  Button,
  Switch,
  Typography,
  Select,
  Divider,
  IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
import { useHistory, Redirect, Link } from "react-router-dom";
import { Loader } from "../UtilityComponents";
import {
  getUserSettings,
  changeSetting,
} from "../../actions/userDetailsAction";
import ExceptionListPopup from "./ExceptionListPopup";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import PasswordChange from "./PasswordChange";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30,
    width: "100%",
    textAlign: "center",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
    width: "inherit",
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
    justifyContent: "space-between",
    width: "95%",
  },
}));

const BlueSwitch = withStyles({
  switchBase: {
    // color: purple[300],
    "&$checked": {
      color: "#0A3C9D",
    },
    "&$checked + $track": {
      backgroundColor: "#0A3C9D",
    },
  },
  checked: {},
  track: {},
})(Switch);

const Settings = ({ userSettings, dispatch }) => {
  const classes = useStyles();
  var history = useHistory();
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [showBirthDate_Month, setShowBirthDate_Month] = useState(null);
  const [showBirthYear, setShowBirthYear] = useState(null);
  const [showMobileNumber, setShowMobileNumber] = useState(null);
  const [showHiddenFriendsOption, setShowHiddenFriendsOption] = useState(null);
  const [showAddress, setShowAddress] = useState(null);
  const [hide_HiddenFriends_Count, setHide_HiddenFriends_Count] = useState(
    null
  );
  const [
    stop_Sending_Profile_Viewed_Notification,
    setStop_Sending_Profile_Viewed_Notification,
  ] = useState(null);
  const [auto_Rotation_Of_Videos, setAuto_Rotation_Of_Videos] = useState(null);
  const [whoCanChat, setWhoCanChat] = useState(null);
  const [whoseCommentsByDefault, setWhoseCommentsByDefault] = useState(null);
  const [whoCanSharePrivateGallery, setWhoCanSharePrivateGallery] = useState(
    null
  );
  const [exceptionListOpen, setExceptionListOpen] = useState(false);
  const [exceptionListSetting, setExceptionListSetting] = useState(null);

  useEffect(() => {
    dispatch(getUserSettings());
    return () => {
      !openChangePassword && dispatch(getUserSettings());
    };
  }, []);

  useEffect(() => {
    if (userSettings) {
      setShowBirthDate_Month(userSettings && userSettings.showBirthDate_Month);
      setShowBirthYear(userSettings && userSettings.showBirthYear);
      setShowMobileNumber(userSettings && userSettings.showMobileNumber);
      setShowHiddenFriendsOption(
        userSettings && userSettings.showHiddenFriendsOption
      );
      setShowAddress(userSettings && userSettings.showAddress);
      setHide_HiddenFriends_Count(
        userSettings && userSettings.hide_HiddenFriends_Count
      );
      setStop_Sending_Profile_Viewed_Notification(
        userSettings && userSettings.stop_Sending_Profile_Viewed_Notification
      );
      setAuto_Rotation_Of_Videos(
        userSettings && userSettings.auto_Rotation_Of_Videos
      );
      setWhoCanChat(userSettings && userSettings.whoCanChat);
      setWhoseCommentsByDefault(
        userSettings && userSettings.whoseCommentsByDefault
      );
      setWhoCanSharePrivateGallery(
        userSettings && userSettings.whoCanSharePrivateGallery
      );
    }
  }, [userSettings]);

  const handleToggleSetting = (setting) => {
    changeSetting(setting).then((res) => {
      // res.showBirthDate_Month !== undefined &&
      //   setShowBirthDate_Month(res.showBirthDate_Month);
      // res.showBirthYear !== undefined && setShowBirthYear(res.showBirthYear);
      // res.data &&
      //   res.data.showMobileNumber !== undefined &&
      //   setShowMobileNumber(res.data.showMobileNumber);
      //   res.data.showHiddenFriendsOption !== undefined &&
      //   setshowHiddenFriendsOption(res.data.showHiddenFriendsOption);
      // res.data &&
      //   res.data.showAddress !== undefined &&
      //   setShowAddress(res.data.showAddress);
      // res.data &&
      //   res.data.hide_HiddenFriends_Count !== undefined &&
      //   setHide_HiddenFriends_Count(res.data.hide_HiddenFriends_Count);
      // res.data &&
      //   res.data.stop_Sending_Profile_Viewed_Notification !== undefined &&
      //   setStop_Sending_Profile_Viewed_Notification(
      //     res.data.stop_Sending_Profile_Viewed_Notification
      //   );
      // res.data &&
      //   res.data.auto_Rotation_Of_Videos !== undefined &&
      //   setAuto_Rotation_Of_Videos(res.data.auto_Rotation_Of_Videos);
    });
    "showBirthYear" === setting && setShowBirthYear(!showBirthYear);
    "showBirthDate_Month" === setting &&
      !showBirthDate_Month &&
      setShowBirthYear(false);
    "showBirthDate_Month" === setting &&
      setShowBirthDate_Month(!showBirthDate_Month);
    "showMobileNumber" === setting && setShowMobileNumber(!showMobileNumber);
    "showHiddenFriendsOption" === setting &&
      setShowHiddenFriendsOption(!showHiddenFriendsOption);
    "showAddress" === setting && setShowAddress(!showAddress);
    "hide_HiddenFriends_Count" === setting &&
      setHide_HiddenFriends_Count(!hide_HiddenFriends_Count);
    "stop_Sending_Profile_Viewed_Notification" === setting &&
      setStop_Sending_Profile_Viewed_Notification(
        !stop_Sending_Profile_Viewed_Notification
      );
    "auto_Rotation_Of_Videos" === setting &&
      setAuto_Rotation_Of_Videos(!auto_Rotation_Of_Videos);
  };

  const handleChangeSetting = (value, setting) => {
    changeSetting(setting, { [setting]: value });
    setWhoseCommentsByDefault("All");
    if (setting === "whoCanChat") {
      setWhoCanChat(value);
    }else if (setting === "whoCanSharePrivateGallery") {
      setWhoCanSharePrivateGallery(value);
    }
    
  };

  const handleOpenExceptionList = (setting) => {
    if (setting === "whoCanChat") {
      handleViewExceptionList("whoCanChat");
    } else if (setting === "whoCanSharePrivateGallery") {
      handleViewExceptionList("whoCanSharePrivateGallery");
    }
  };

  const handleViewExceptionList = (setting) => {
    setExceptionListSetting(setting);
    setExceptionListOpen(true);
  };

  return (
    <Paper className={classes.root}>
      {exceptionListOpen && (
        <ExceptionListPopup
          open={exceptionListOpen}
          // close={handleCloseExceptionList}
          close={() => setExceptionListOpen(false)}
          setting={exceptionListSetting}
        />
      )}
      {openChangePassword && (
        <PasswordChange
          open={openChangePassword}
          handleClose={() => setOpenChangePassword(false)}
          history={history}
        />
      )}
      <Button
        color="secondary"
        variant="contained"
        size="small"
        onClick={(e) => history.push("/profile-me")}
        style={{ float: "right", margin: "16px", textTransform: "none" }}
      >
        Back
      </Button>
      <Typography variant="h4">Settings</Typography>
      {userSettings ? (
        <>
          <List subheader={<ListSubheader>Personal</ListSubheader>}>
            <Divider />
            <ListItem divider>
              <ListItemText
                id="switch-list-label-7"
                primary="Auto Rotation of Videos"
              />
              <ListItemSecondaryAction>
                <BlueSwitch
                  className={classes.checked}
                  edge="end"
                  onChange={() =>
                    handleToggleSetting("auto_Rotation_Of_Videos")
                  }
                  checked={auto_Rotation_Of_Videos && auto_Rotation_Of_Videos}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText
                id="switch-list-label-5"
                primary="Hide Hidden Friends count"
              />
              <ListItemSecondaryAction>
                <BlueSwitch
                  className={classes.checked}
                  edge="end"
                  onChange={() =>
                    handleToggleSetting("hide_HiddenFriends_Count")
                  }
                  checked={hide_HiddenFriends_Count && hide_HiddenFriends_Count}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText id="switch-list-label-4" primary="Show Address" />
              <ListItemSecondaryAction>
                <BlueSwitch
                  className={classes.checked}
                  edge="end"
                  onChange={() => handleToggleSetting("showAddress")}
                  checked={showAddress && showAddress}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText
                id="switch-list-label-1"
                primary="Show birth date, month"
              />
              <ListItemSecondaryAction>
                <BlueSwitch
                  className={classes.checked}
                  edge="end"
                  onChange={() => handleToggleSetting("showBirthDate_Month")}
                  checked={showBirthDate_Month && showBirthDate_Month}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText
                id="switch-list-label-2"
                primary="Show birth year"
              />
              <ListItemSecondaryAction>
                <BlueSwitch
                  className={classes.checked}
                  edge="end"
                  disabled={!showBirthDate_Month}
                  onChange={() => handleToggleSetting("showBirthYear")}
                  checked={
                    !showBirthDate_Month
                      ? false
                      : showBirthYear && showBirthYear
                  }
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText
                id="switch-list-label-3"
                primary="Show Mobile Number"
              />
              <ListItemSecondaryAction>
                <BlueSwitch
                  className={classes.checked}
                  edge="end"
                  onChange={() => handleToggleSetting("showMobileNumber")}
                  checked={showMobileNumber && showMobileNumber}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText
                id="switch-list-label-3"
                primary="Show 'Hidden Friends' Option in Open Comment/Post Settings"
              />
              <ListItemSecondaryAction>
                <BlueSwitch
                  className={classes.checked}
                  edge="end"
                  onChange={() =>
                    handleToggleSetting("showHiddenFriendsOption")
                  }
                  checked={showHiddenFriendsOption && showHiddenFriendsOption}
                />
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem divider>
              <ListItemText
                id="switch-list-label-6"
                primary="Stop Sending Profile View Notifications"
              />
              <ListItemSecondaryAction>
                <BlueSwitch
                  className={classes.checked}
                  edge="end"
                  onChange={() =>
                    handleToggleSetting(
                      "stop_Sending_Profile_Viewed_Notification"
                    )
                  }
                  checked={
                    stop_Sending_Profile_Viewed_Notification &&
                    stop_Sending_Profile_Viewed_Notification
                  }
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText id="switch-list-label-10" primary="Who Can Chat" />
              <ListItemSecondaryAction>
                <Select
                  value={whoCanChat && whoCanChat}
                  autoWidth
                  onChange={(e) =>
                    handleChangeSetting(e.target.value, "whoCanChat")
                  }
                  // onClose={(e) =>
                  //   handleOpenExceptionList(e.target.value, "whoCanChat")
                  // }
                >
                  <MenuItem value={"NoOne"}>No One</MenuItem>
                  <MenuItem value={"AnyOne"}>Any One</MenuItem>
                  <MenuItem value={"OpenFriends"}>Open Friends</MenuItem>
                  <MenuItem value={"HiddenFriends"}>Hidden Friends</MenuItem>
                  <MenuItem value={"HiddenOpenFriends"}>
                    Hidden, Open Friends
                  </MenuItem>
                  <MenuItem
                    value={"ExceptionUsers"}
                    // onClick={() => handleOpenExceptionList("whoCanChat")}
                  >
                    Exception Users
                  </MenuItem>
                </Select>
                {whoCanChat === "ExceptionUsers" && (
                  <IconButton
                    onClick={() => handleOpenExceptionList("whoCanChat")}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText
                id="switch-list-label-9"
                primary="Who Can Share Private Gallery"
              />
              <ListItemSecondaryAction>
                <Select
                  value={whoCanSharePrivateGallery && whoCanSharePrivateGallery}
                  autoWidth
                  onChange={(e) =>
                    handleChangeSetting(
                      e.target.value,
                      "whoCanSharePrivateGallery"
                    )
                  }
                  // onClose={(e) =>
                  //   handleOpenExceptionList(
                  //     e.target.value,
                  //     "whoCanSharePrivateGallery"
                  //   )
                  // }
                >
                  <MenuItem value={"NoOne"}>No One</MenuItem>
                  <MenuItem value={"AnyOne"}>Any One</MenuItem>
                  <MenuItem value={"OpenFriends"}>Open Friends</MenuItem>
                  <MenuItem value={"HiddenFriends"}>Hidden Friends</MenuItem>
                  <MenuItem value={"HiddenOpenFriends"}>
                    Hidden, Open Friends
                  </MenuItem>
                  <MenuItem
                    value={"ExceptionUsers"}
                    // onClick={() =>
                    //   handleOpenExceptionList("whoCanSharePrivateGallery")
                    // }
                  >
                    Exception Users
                  </MenuItem>
                </Select>
                {whoCanSharePrivateGallery === "ExceptionUsers" && (
                  <IconButton
                    onClick={() =>
                      handleOpenExceptionList("whoCanSharePrivateGallery")
                    }
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>

            {/* <ListItem divider>
              <ListItemText
                id="switch-list-label-8"
                primary="Whose Comments By Default"
              />
              <ListItemSecondaryAction>
                <Select
                  value={whoseCommentsByDefault && whoseCommentsByDefault}
                  autoWidth
                  onChange={(e) =>
                    handleChangeSetting(
                      e.target.value,
                      "whoseCommentsByDefault"
                    )
                  }
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={"Male"}>Male</MenuItem>
                  <MenuItem value={"Female"}>Female</MenuItem>
                </Select>
              </ListItemSecondaryAction>
            </ListItem> */}
          </List>
          <List subheader={<ListSubheader>Security</ListSubheader>}>
            <Divider />
            <ListItem
              divider
              button
              onClick={() => setOpenChangePassword(true)}
            >
              <ListItemText primary="Change Password" />
              <ListItemSecondaryAction>
                <IconButton onClick={() => setOpenChangePassword(true)}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </>
      ) : (
        <Loader />
      )}
    </Paper>
  );
};
const mapStateToProps = (state) => {
  return {
    userSettings: state.userDetailsReducer.userSettings,
  };
};
export default connect(mapStateToProps)(Settings);
