import { sendHTTPRequest } from "../helpers/requestEngine";
import getUrl from "../helpers/apis";
import { getStringParam } from "../helpers/paramVerifier";
import request1 from "../helpers/request";
import getURL from "../helpers/apis";
// // import snackbar from "snackbar";
import { openDialog, closeDialog } from "./appActions";

export const getUserAddress = (params) => {
  let url = getUrl("GET_USER_ADDRESS");
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}, params));
  return promise;
};

export const getReportReasons = (params) => {
  let url = getUrl("GET_REPORT_REASONS");
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}, params));
  return promise;
};

export const getAllRepliesToMyComment = (params) => {
  let url = getUrl("GET_REPLIES_TO_MY_COMMENT");
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}, params));
  return promise;
};

export const getAllMyComments = (params) => {
  let url = getUrl("GET_ALL_MY_COMMENTS");
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}, params));
  return promise;
};

export const deleteGallery = (id) => {
  let url = getUrl("DELETE_GALLERY");
  let promise = Promise.resolve(
    sendHTTPRequest("delete", url, true, { gallery_id: id })
  );
  return promise;
};

export const getFollowing = (params) => {
  let url = "";
  if (params.userId) {
    url = getUrl("GET_FOLLOWING_BY_USER");
  } else {
    url = getUrl("GET_USER_FOLLOWING");
  }
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}, params));
  return promise;
};

export const getFollowers = (params) => {
  let url = "";
  if (params.userId) {
    url = getUrl("GET_FOLLOWERS_BY_USER");
  } else {
    url = getUrl("GET_USER_FOLLOWERS");
  }
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}, params));
  return promise;
};

export const getBlockedUsers = (params) => {
  let url = getUrl("GET_BLOCKED_USERS");
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}, params));
  return promise;
};

export const getPublicGallery = (userId) => {
  let url = getUrl("GET_PUBLIC") + "/" + userId;
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}));
  return promise;
};

export const getPrivateGallery = (id) => {
  let url = getUrl("GET_PRIVATE") + "/" + id;
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}));
  return promise;
};

export const getDeptGallery = (id) => {
  let url = getUrl("GET_DEPARTMENT") + "/" + id;
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}));
  return promise;
};

export const getProfileLikes = (userId) => {
  let url = getUrl("GET_PROFILE_LIKES") + "?user_id=" + userId;
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}));
  return promise;
};

export const getGalleriesUserLiked = (params) => {
  let url = getUrl("GET_ALL_GALLERIES_USER_LIKED");
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}, params));
  return promise;
};

export const getGalleriesUserFavourited = (params) => {
  let url = getUrl("GET_ALL_GALLERIES_USER_FAVED");
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}, params));
  return promise;
};

export const getGalleryLikesByUserId = (params) => {
  let url = getUrl("GET_GALLERY_LIKES_BY_USER_ID");
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}, params));
  return promise;
};

export const getPostLikesByUserId = (params) => {
  let url = getUrl("GET_POST_LIKES_BY_USER_ID");
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}, params));
  return promise;
};

export const getGalleryFavouritesByUserId = (params) => {
  let url = getUrl("GET_GALLERY_FAV_BY_USER_ID");
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}, params));
  return promise;
};

export const getUserGalleryLikesByType = (params) => {
  let url = getUrl("GET_USER_GALLERY_LIKES_BY_TYPE");
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}, params));
  return promise;
};
export const getUserPostLikesByType = (params) => {
  let url = getUrl("GET_USER_POST_LIKES_BY_TYPE");
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}, params));
  return promise;
};

export const getUserGalleryFavouritesByType = (params) => {
  let url = getUrl("GET_USER_GALLERY_FAVES_BY_TYPE");
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}, params));
  return promise;
};

export const shareGallery = (body) => {
  let url = getUrl("SHARE_PUBLIC_GALLERY");
  let promise = Promise.resolve(sendHTTPRequest("post", url, true, body));
  return promise;
};

export const editCustomName = (body) => {
  let url = getUrl("EDIT_CUSTOM_NAME");
  let promise = Promise.resolve(sendHTTPRequest("post", url, true, body));
  return promise;
};

export const reportEntity = (body) => {
  let url = getUrl("REPORT_ENTITY");
  let promise = Promise.resolve(sendHTTPRequest("post", url, true, body));
  return promise;
};


export const reportPostEntity = (body) => {
  let url = getUrl("REPORT_POST_ENTITY");
  let promise = Promise.resolve(sendHTTPRequest("post", url, true, body));
  return promise;
};



export const follow = (body) => {
  let url = getUrl("FOLLOW");
  let promise = Promise.resolve(sendHTTPRequest("post", url, true, body));
  return promise;
};

export const unfollow = (body) => {
  let url = getUrl("UNFOLLOW");
  let promise = Promise.resolve(sendHTTPRequest("post", url, true, body));
  return promise;
};

export const block = (body) => {
  let url = getUrl("BLOCK");
  let promise = Promise.resolve(sendHTTPRequest("post", url, true, body));
  return promise;
};

export const unlikeProfile = (body) => {
  let url = getUrl("UNLIKE_PROFILE");
  let promise = Promise.resolve(sendHTTPRequest("post", url, true, body));
  return promise;
};

export const likeProfile = (body) => {
  let url = getUrl("LIKE_PROFILE");
  let promise = Promise.resolve(sendHTTPRequest("post", url, true, body));
  return promise;
};

export const unblock = (body) => {
  let url = getUrl("UNBLOCK");
  let promise = Promise.resolve(sendHTTPRequest("post", url, true, body));
  return promise;
};

export const likeGallery = (body) => {
  let url = getUrl("LIKE_GALLERY");
  let promise = Promise.resolve(sendHTTPRequest("post", url, true, body));
  return promise;
};

export const unlikeGallery = (body) => {
  let url = getUrl("UNLIKE_GALLERY");
  let promise = Promise.resolve(sendHTTPRequest("post", url, true, body));
  return promise;
};

export const favGallery = (body) => {
  let url = getUrl("FAVOURITE_GALLERY");
  let promise = Promise.resolve(sendHTTPRequest("post", url, true, body));
  return promise;
};

export const unfavGallery = (body) => {
  let url = getUrl("UNFAVOURITE_GALLERY");
  let promise = Promise.resolve(sendHTTPRequest("delete", url, true, body));
  return promise;
};

export const getGalleryDetailsById = (id) => {
  let url = getUrl("GET_GALLERY_DETAILS_BY_ID") + id;
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}));
  return promise;
};

export const getUserWarningMessages = ()=>{
  let url = getUrl("GET_USER_WARNING_MESSAGES");
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}));
  return promise;
}


export const getSharedToMe = (params) => {
  let url = "";
  if (params.userId) {
    url = getUrl("GET_SHARED_TO_ME_BY_USER_ID");
  } else {
    url = getUrl("GET_SHARED_TO_ME");
  }
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}, params));
  return promise;
};

export const getExceptionUserList = (params) => {
  let url = getUrl("EXCEPTION_LIST");

  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}, params));
  return promise;
};

export const getAddableUsersByKeyword = (params) => {
  let url = getUrl("GET_ADDABLE_USERS");

  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}, params));
  return promise;
};

export const addUserToExceptionList = (body) => {
  let url = getUrl("EXCEPTION_LIST");

  let promise = Promise.resolve(sendHTTPRequest("post", url, true, body));
  return promise;
};

export const removeUserFromExceptionList = (body) => {
  let url = getUrl("EXCEPTION_LIST");

  let promise = Promise.resolve(sendHTTPRequest("delete", url, true, body));
  return promise;
};

// export const getSharedToMeByUserId = (id) => {
//   let url = getUrl("GET_SHARED_TO_ME_BY_USER_ID") + "?userId=" + id;
//   let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}));
//   return promise;
// };

// export const getProfileLikes = (userId) => {
//     let url = getUrl('GET_PROFILE_LIKES') + '?user_id=' + userId;
//     let promise = Promise.resolve(sendHTTPRequest('get', url, true, {}));
//     return promise;
// }

// export const getProfileLikes = (userId) => {
//     let url = getUrl('GET_PROFILE_LIKES') + '?user_id=' + userId;
//     let promise = Promise.resolve(sendHTTPRequest('get', url, true, {}));
//     return promise;
// }

// export const getProfileLikes = (userId) => {
//     let url = getUrl('GET_PROFILE_LIKES') + '?user_id=' + userId;
//     let promise = Promise.resolve(sendHTTPRequest('get', url, true, {}));
//     return promise;
// }

// let promise = Promise.resolve(sendHTTPRequest('post', getUrl('CHANGE_APPLICANT_STATUS')+'/'+this.props.applicant._id, true, {status: status}));
//             promise.then(response=>{
//                 this.props.getPosApplicants(this.props.applicant.positionId);
//             });

// CLEAR SHAREBLE  USER FOR GALLARY

export const clearComments = () => {
  return (dispatch) => {
    dispatch(clearingComments());
  };
};

function clearingComments() {
  return {
    type: "CLEAR_COMMENTS",
  };
}
export const clearReplies = () => {
  return (dispatch) => {
    dispatch(clearingReplies());
  };
};

function clearingReplies() {
  return {
    type: "CLEAR_REPLIES",
  };
}

export const clearShareableUserList = () => {
  return (dispatch) => {
    dispatch(clearingShareableUserList());
  };
};

function clearingShareableUserList() {
  return {
    type: "CLEAR_SHAREABLE_USER_LIST_SUCCESS",
  };
}
export const clearSharedUserList = () => {
  return (dispatch) => {
    dispatch(clearingSharedUserList());
  };
};

function clearingSharedUserList() {
  return {
    type: "CLEAR_SHARED_USER_LIST_SUCCESS",
  };
}

// GET SHAREBLE  USER FOR GALLARY

export const getShareableUserList = (params) => {
  return (dispatch) => {
    dispatch(fetchingShareableUserList());

    return dispatch(fetchShareableUserList(params));
  };
};

function fetchingShareableUserList() {
  return {
    type: "FETCH_SHAREABLE_USER_LIST",
  };
}

function fetchShareableUserList(params) {
  return (dispatch) => {
    request1("get", getURL("GET_SHAREABLE_USER"), true, {}, params).then(
      (response) => {
        dispatch(fetchingShareableUserListSuccess(response.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));
        dispatch(fetchingShareableUserListFailed(err));
      }
    );
  };
}

function fetchingShareableUserListSuccess(data) {
  return {
    type: "FETCH_SHAREABLE_USER_LIST_SUCCESS",
    payload: data.data,
  };
}

function fetchingShareableUserListFailed(err) {
  return {
    type: "FETCH_SHAREABLE_USER_LIST_FAILED",
  };
}

// GET SHARED USER FOR GALLARY

export const getSharedUserList = (gallery_id) => {
  return (dispatch) => {
    dispatch(fetchingSharedUserList());

    return dispatch(fetchSharedUserList(gallery_id));
  };
};

function fetchingSharedUserList() {
  return {
    type: "FETCH_SHARED_USER_LIST",
  };
}

function fetchSharedUserList(gallery_id) {
  return (dispatch) => {
    request1(
      "get",
      getURL("GET_SHARED_USER"),
      true,
      {},
      { gallery_id: gallery_id }
    ).then(
      (response) => {
        dispatch(fetchingSharedUserListSuccess(response.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));

        dispatch(fetchingSharedUserListFailed(err));
      }
    );
  };
}

function fetchingSharedUserListSuccess(data) {
  return {
    type: "FETCH_SHARED_USER_LIST_SUCCESS",
    payload: data.data,
  };
}

function fetchingSharedUserListFailed(err) {
  return {
    type: "FETCH_SHARED_USER_LIST_FAILED",
  };
}

// sharePrivatePic

export const sharePrivatePic = (
  galleryId,
  body,
  handleSuccessSharePrivatePic
) => {
  return (dispatch) => {
    dispatch(sharingPrivatePic());

    return dispatch(
      sharingPrivateUserPic(galleryId, body, handleSuccessSharePrivatePic)
    );
  };
};

function sharingPrivatePic() {
  return {
    type: "SHARING_PRIVATE_PIC",
  };
}

function sharingPrivateUserPic(galleryId, body, handleSuccessSharePrivatePic) {
  return (dispatch) => {
    request1(
      "put",
      getURL("SHARE_PRIVATE_SHARE") + `${galleryId}`,
      true,
      body,
      {}
    ).then(
      (response) => {
        handleSuccessSharePrivatePic && handleSuccessSharePrivatePic();
        dispatch(sharingPrivatePicSuccess(body));
        // snackbar.show("Successfully shared");
        dispatch(openDialog("Successfully shared"));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));

        dispatch(sharingPrivatePicFailed(err));
      }
    );
  };
}

function sharingPrivatePicSuccess(data) {
  return {
    type: "SHARING_PRIVATE_PIC_SUCCESS",
    payload: data,
  };
}

function sharingPrivatePicFailed(err) {
  return {
    type: "SHARING_PRIVATE_PIC_FAILED",
  };
}

///////////////////////////////////////////////////////////////////////////////

// removePrivePic

export const removePrivePic = (
  galleryId,
  body,
  handleSuccessRemoveSharePrivatePic
) => {
  return (dispatch) => {
    dispatch(removingPrivatePic());

    return dispatch(
      removingPrivateUserPic(
        galleryId,
        body,
        handleSuccessRemoveSharePrivatePic
      )
    );
  };
};

function removingPrivatePic() {
  return {
    type: "REMOVING_PRIVATE_PIC",
  };
}

function removingPrivateUserPic(
  galleryId,
  body,
  handleSuccessRemoveSharePrivatePic
) {
  return (dispatch) => {
    request1(
      "delete",
      getURL("REMOVE_PRIVATE_SHARE") + `${galleryId}`,
      true,
      body,
      {}
    ).then(
      (response) => {
        handleSuccessRemoveSharePrivatePic &&
          handleSuccessRemoveSharePrivatePic();
        dispatch(removingPrivatePicSuccess(body));
        // snackbar.show("Successfully removed");
        dispatch(openDialog("Successfully removed"));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));

        dispatch(removingPrivatePicFailed(err));
      }
    );
  };
}

function removingPrivatePicSuccess(data) {
  return {
    type: "REMOVING_PRIVATE_PIC_SUCCESS",
    payload: data,
  };
}

function removingPrivatePicFailed(err) {
  return {
    type: "REMOVING_PRIVATE_PIC_FAILED",
  };
}
