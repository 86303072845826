import React, { useState, useEffect, useRef } from "react";
import { Typography, Avatar } from "@material-ui/core";
import getURL from "../../../helpers/apis";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { red } from "@material-ui/core/colors";
import PersonIcon from "@material-ui/icons/Person";
import { openAvatarModal } from "../../../actions/appActions";
import PostMediaCarousel from "./PostMediaCarousel";
import { Link } from "react-router-dom";
import bindActionCreators from "redux/es/bindActionCreators";
import { useHistory } from "react-router-dom";

const reactStringReplace = require("react-string-replace");

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    // maxHeight: "600px",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  // root: {
  //   position: "relative",
  //   overflow: "visible",
  //   display: "flex",
  //   width: "fit-content",
  //   height: "fit-content",
  //   maxWidth: "inherit",
  //   maxHeight: "inherit",
  // },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
    height: "auto",
    minWidth: "335px",
    minHeight: "auto",
  },
  content: {
    width: "fit-content",
    height: "100%",
  },
  navIcons: {
    display: "block",
    marginTop: "-20px",
    overflow: "hidden",
    pointerEvents: "auto",
    position: "fixed",
    textIndent: "-9999em",
    top: "50%",
    cursor: "pointer",
    color: "white",
  },
  video: {
    width: "fit-content",
    height: "fit-content",
    maxWidth: "600px",
    maxHeight: "inherit",
    minWidth: "335px",
    minHeight: "auto",
  },
  image: {
    height: "600px",
    width: "100%",
    display: "inline-block",
    maxWidth: "600px",
    maxHeight: "inherit",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  commentsPaper: {
    width: "100%",
    maxWidth: "100%",
    height: "inherit",
    // overflowY: "scroll",
    display: "block",
    overflow: "hidden",
    margin: 0,
    // margin: theme.spacing(1),
    // marginTop: 0,
    // marginBottom: 0,
  },
  commentBody: {
    width: "88%",
    maxWidth: "88%",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(0),
  },
  section1: {
    width: "-webkit-fill-available",
    margin: theme.spacing(2),
    marginBottom: 0,
  },
  // section2: {
  //   width: "-webkit-fill-available",
  //   margin: theme.spacing(1),
  //   marginTop: 0,
  //   marginBottom: 0,
  //   height: "40px",
  //   overflowY: "scroll",
  //   overflowX: "hidden",
  // },
  section2: {
    width: "-webkit-fill-available",
    // margin: theme.spacing(2),
    marginTop: 0,
    height: "fit-content",
    overflow: "hidden",
    marginBottom: 0,
  },
  section3: {
    width: "-webkit-fill-available",
    // margin: theme.spacing(2),
    marginTop: 0,
    marginBottom: 0,
  },
  filter: {
    flexDirection: "row",
    fontSize: "12px",
    display: "inline-block",
  },
  btns: {
    fontSize: "10px",
    display: "inline-block",
    minHeight: 0,
    minWidth: 0,
    textTransform: "none",
  },
  icons: {
    cursor: "pointer",
    height: "auto",
    paddingTop: 3,
    paddingBottom: 3,
    // fontSize: 16,
    // marginLeft: 15,
    // marginRight: 15,
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "8px",
  },
}));

const AdItem = ({
  ad,
  userDetail,
  openAvatarModal,
  hovered = false,
  match,
  location,
}) => {
  var history = useHistory();
  const classes = useStyles();
  const rootUrl = getURL("UPLOADS");
  const [owner, setOwner] = useState(false);

  const loggedInUser = userDetail._id;

  useEffect(() => {
    if (ad) {
      setOwner(loggedInUser === ad.companyId);
    }
  }, [ad, loggedInUser]);

  return (
    <Card
      className={classes.root}
      style={{ backgroundColor: hovered ? "rgba(0, 0, 0, 0.05)" : "white" }}
    >
      <CardHeader
        style={{ paddingTop: 0, paddingBottom: 0 }}
        avatar={
          ad.profilepic ? (
            <Avatar
              alt="owner"
              src={process.env.REACT_APP_BUCKET_NAME + ad.profilepic}
              onClick={(e) => openAvatarModal(process.env.REACT_APP_BUCKET_NAME + ad.profilepic)}
            />
          ) : (
            <Avatar
              alt="owner"
              className={classes.avatar}
              onClick={(e) => openAvatarModal()}
            >
              <PersonIcon />
            </Avatar>
          )
        }
        title={
          <Link
            to={`/user_profile/${ad.companyId}`}
            onClick={(e) => e.stopPropagation()}
          >
            <Typography variant="h5">{ad.bnr}</Typography>
          </Link>
        }
        subheader={"Sponsored"}
        // subheader={new Date(ad.createdAt).toDateString()}
      />
      <CardContent
        style={{ paddingTop: 6, paddingBottom: 6 }}
        // onClick={() => history.push(`/ad/${ad._id}`)}
      >
        {/* <Typography variant="subtitle1" color="textPrimary" component="p">
          {ad.text}
        </Typography> */}
      </CardContent>

      <PostMediaCarousel galleries={ad.media} postId={ad._id} />
    </Card>
  );
};

function mapStateToProps(state) {
  return {
    userDetail: state.userDetailsReducer.userDetail,
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      openAvatarModal,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapActionsToProps)(withRouter(AdItem));
