const profilePhotoReducer = (
  state = {
    fetching: false,
    photos: [],
    dataSetChanged: false,
    uploading: false
  },
  action
) => {
  switch (action.type) {
    case "PHOTOS_FETCHING":
      return {
        ...state,
        fetching: true
      };

    case "PHOTOS_FETCH_SUCCESS":
      return {
        ...state,
        fetching: false,
        photos: action.payload,
        dataSetChanged: false
      };

    case "PHOTOS_FETCH_FAILED":
      return {
        ...state,
        fetching: false,
        dataSetChanged: false
      };

    case "PHOTO_UPLOADING":
      return {
        ...state,
        uploading: true
      };

    case "PHOTO_UPLOAD_SUCCESS":
      return {
        ...state,
        dataSetChanged: true,
        uploading: false
      };

    case "PHOTO_UPLOAD_FAILED":
      return {
        ...state,
        uploading: false
      };

    default:
      return state;
  }
};

export default profilePhotoReducer;
