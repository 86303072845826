import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Button,
  ButtonGroup,
  Paper,
  Grid,
  MenuItem,
  TextField,
  FormGroup,
  Avatar,
  IconButton,
  Popover,
  List,
  ListItem,
  Icon,
  ListItemText,
  Badge,
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import getURL from "../../../helpers/apis";
import { withRouter } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CommentIcon from "@material-ui/icons/Comment";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import DescriptionSharpIcon from "@material-ui/icons/DescriptionSharp";
// import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PersonIcon from "@material-ui/icons/Person";
import { openAvatarModal, openDialog } from "../../../actions/appActions";
import PostMediaCarousel from "./PostMediaCarousel";
import { Link } from "react-router-dom";
import bindActionCreators from "redux/es/bindActionCreators";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Menu from "@material-ui/core/Menu";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { useHistory } from "react-router-dom";
import {
  getCommentsByPostId,
  getRepliesByCommentId,
  clearComments,
  clearReplies,
  postComment,
  deleteComment,
  deletePost,
  likePost,
  unlikePost,
} from "../../../actions/postActions";
import { Loader } from "../../UtilityComponents";
import moment from "moment";
import { decryptText, encryptText } from "../../../helpers/globalFunctions";
import ConfirmationDialog from "../../ConfirmationDialog";
import UserList from "./UserList";
import PublicShare from "./PublicShare";
import BlockedUserIcon from "../../../assets/svg/Blocked Users Icon.svg";
import LikesIcon from "../../../assets/svg/Likes icon (OUTLINE).svg";
import FavouritesIcon from "../../../assets/svg/Favourites icon (OUTLINE).svg";
import ORIcon from "../../../assets/svg/O R ICON.svg";
import HRIcon from "../../../assets/svg/H R ICON.svg";
import OpenLikeIcon from "../../../assets/svg/Likes icon (OPEN) copy 3.svg";
import ShareIcon from "../../../assets/svg/SHARE ICON (blue).svg";
import HiddenLikeIcon from "../../../assets/svg/Likes icon (HIDDEN) copy 3.svg";
import OpenFavoriteIcon from "../../../assets/svg/Favourites icon (OPEN).svg";
import HiddenFavoriteIcon from "../../../assets/svg/Favourites icon (HIDDEN).svg";
import HiddenFriendsIcon from "../../../assets/svg/Hidden Friends icon.svg";
import ProfileLikeIcon from "../../../assets/svg/Profile Like icon.svg";
import ShareToMeIcon from "../../../assets/svg/Sharedtome icon.svg";
import MyCommentsIcon from "../../../assets/svg/My Comments Icon 2.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import ReportDialog from "../../GalleryComponent/ReportDialog";
import ReportIcon from "../../../assets/svg/Report Icon (black).svg";


const reactStringReplace = require("react-string-replace");

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    // maxHeight: "600px",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  // root: {
  //   position: "relative",
  //   overflow: "visible",
  //   display: "flex",
  //   width: "fit-content",
  //   height: "fit-content",
  //   maxWidth: "inherit",
  //   maxHeight: "inherit",
  // },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
    height: "auto",
    minWidth: "335px",
    minHeight: "auto",
  },
  content: {
    width: "fit-content",
    height: "100%",
  },
  navIcons: {
    display: "block",
    marginTop: "-20px",
    overflow: "hidden",
    pointerEvents: "auto",
    position: "fixed",
    textIndent: "-9999em",
    top: "50%",
    cursor: "pointer",
    color: "white",
  },
  video: {
    width: "fit-content",
    height: "fit-content",
    maxWidth: "600px",
    maxHeight: "inherit",
    minWidth: "335px",
    minHeight: "auto",
  },
  image: {
    height: "600px",
    width: "100%",
    display: "inline-block",
    maxWidth: "600px",
    maxHeight: "inherit",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  commentsPaper: {
    width: "100%",
    maxWidth: "100%",
    height: "inherit",
    // overflowY: "scroll",
    display: "block",
    overflow: "hidden",
    margin: 0,
    // margin: theme.spacing(1),
    // marginTop: 0,
    // marginBottom: 0,
  },
  commentBody: {
    width: "100%",
    maxWidth: "100%",
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(2),
  },
  section1: {
    width: "-webkit-fill-available",
    margin: theme.spacing(2),
    marginBottom: 0,
  },
  // section2: {
  //   width: "-webkit-fill-available",
  //   margin: theme.spacing(1),
  //   marginTop: 0,
  //   marginBottom: 0,
  //   height: "40px",
  //   overflowY: "scroll",
  //   overflowX: "hidden",
  // },
  section2: {
    width: "-webkit-fill-available",
    // margin: theme.spacing(2),
    marginTop: 0,
    height: "fit-content",
    overflow: "hidden",
    marginBottom: 0,
  },
  section3: {
    width: "-webkit-fill-available",
    // margin: theme.spacing(2),
    marginTop: 0,
    marginBottom: 0,
  },
  filter: {
    flexDirection: "row",
    fontSize: "12px",
    display: "inline-block",
  },
  btns: {
    fontSize: "10px",
    display: "inline-block",
    minHeight: 0,
    minWidth: 0,
    textTransform: "none",
  },
  icons: {
    cursor: "pointer",
    height: "auto",
    paddingTop: 3,
    paddingBottom: 3,
    display: "flex",
    // fontSize: 16,
    // marginLeft: 15,
    // marginRight: 15,
  },
 
  imgIcon: {
    verticalAlign: "bottom",
    // fontSize: 16,
    // marginLeft: 15,
    // marginRight: 15,
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "8px",
  },
  collapse: {
    display: "flex",
    flexGrow: 1,
    // transition: theme.transitions.create(["margin"], transition),
    margin: 0,
    "&$expanded": {
      margin: 0,
    },
  },
  expanded: {
    margin: 0,
    padding: 0,
  },
  before: {
    "&:before": { opacity: 0.69 },
  },
  overlay: {
    position: "absolute",
    bottom: 0,
    left: 0,
    // width: "100%",
    // height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "white",
    minHeight: "auto",
    maxHeight: "inherit",
  },
  wrapper: {
    position: "relative",
  },
}));

const PostItem = ({
  post,
  userSettings,
  postComment,
  deleteComment,
  deletePost,
  getCommentsByPostId,
  getRepliesByCommentId,
  clearComments,
  clearReplies,
  commentsObj,
  repliesObj,
  userDetail,
  handlePostsRefresh,
  openAvatarModal,
  exp = false,
  hovered = false,
  match,
  location,
  openDialog,
}) => {
  const [expanded, setExpanded] = useState(exp);
  var history = useHistory();
  const classes = useStyles();
  const rootUrl = getURL("UPLOADS");
  const [type, setType] = useState(null);
  const [gender, setGender] = useState(null);
  const [owner, setOwner] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [replyParent, setReplyParent] = useState({});
  const [openSettings, setOpenSettings] = useState(false);
  const [err, setErr] = useState(false);
  const [like, setLike] = useState(false);
  const [fav, setFav] = useState(false);
  const [replyKey, setReplyKey] = useState({});
  const [isPostLiked, setPostLiked] = useState(post && post.isPostLiked);
  const [isPostLikedType, setPostLikedType] = useState(
    post && post.isPostLikedType
  );
  const [noOfLikes, setNoOfLikes] = useState(post && post.nooflikes);
  const [noOfComments, setnoOfComments] = useState(post && post.noofcomments);
  const [noOfShare, setNoOfShare] = useState(post && post.noofshare);
  const [openUserList, setOpenUserList] = useState(false);
  const [actionType, setActionType] = useState("Open");
  const [actionName, setActionName] = useState("Like");
  const [openFavourite, setOpenFavourite] = useState(false);
  const [key, setKey] = useState("");
  const [page, setPage] = useState(1);
  const [replyPage, setReplyPage] = useState({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openCustom, setOpenCustom] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [commentsRefresh, setCommentsRefresh] = useState(false);
  const [followType, setFollowType] = useState(null);
  const [whoseCommentsByDefault, setWhoseCommentsByDefault] = useState(null);
  const [auto_Rotation_Of_Videos, setAuto_Rotation_Of_Videos] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [openPublicShare, setOpenPublicShare] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [commentsList, setCommentsList] = useState(null);
  const [repliesList, setRepliesList] = useState({});
  const [openReport, setOpenReport] = useState(false);
  const [commentMenu, setCommentMenu] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hover, setHover] = useState(null);
  const [pop, setPop] = useState(false);
  const [anchorElPop, setAnchorElPop] = useState(null);
  const [currentComment, setCurrentComment] = useState(null);
  const [replyExpanded, setReplyExpanded] = useState([]);
  const [hiddenReplying, setHiddenReplying] = useState(false);

  const replyRef = useRef(null);

  const limit = 10;
  const replyLimit = 3;
  const loggedInUser = userDetail._id;

  useEffect(() => {
    if (post) {
      setOwner(
        loggedInUser === (post.userId || (post.userId && post.userId._id))
      );
    }
  }, [post, loggedInUser]);

  useEffect(() => {
    userSettings &&
      setAuto_Rotation_Of_Videos(userSettings.auto_Rotation_Of_Videos);
    userSettings &&
      setWhoseCommentsByDefault(userSettings.whoseCommentsByDefault);
  }, [userSettings]);

  useEffect(() => {
    if (post) {
      setGender(whoseCommentsByDefault || "All");
      post.allowOpenComments && post.allowHiddenComments && setType("Open");
      !post.allowOpenComments && post.allowHiddenComments && setType("Hidden");
      post.allowOpenComments && !post.allowHiddenComments && setType("Open");
      !post.allowOpenComments && !post.allowHiddenComments && setType("Open");
    }
  }, [whoseCommentsByDefault]);

  useEffect(() => {
    if (expanded && gender && type) {
      setPage(1);
      // setReplyPage({});
      // setCommentsList(null);
      let params = {
        post_id: post._id,
        commenttype: type,
        gender: "All",
        page: 0,
        limit: limit,
        key: key === "" ? "" : encryptText(key),
      };
      getCommentsByPostId(params);
    }
  }, [expanded, gender, type, key, refresh, commentsRefresh]);

  useEffect(() => {
    if (expanded && gender && type && page !== 1) {
      let params = {
        post_id: post._id,
        commenttype: type,
        gender: "All",
        page: page - 1,
        limit: limit,
        key: key === "" ? "" : encryptText(key),
      };
      getCommentsByPostId(params);
      // if (replyExpanded) {
      //   params.comment_id = replyExpanded;
      //   getRepliesByCommentId(params);
      // }
    }
  }, [page]);

  useEffect(() => {
    if (commentsObj && commentsObj.data) {
      if (commentsObj.post_id == post._id) {
        if (
          commentsList &&
          commentsObj.data &&
          !(commentsObj.data === commentsList) &&
          page !== 1
        ) {
          var arr = commentsObj.data.filter(
            (obj) => !commentsList.includes(obj)
          );
          setCommentsList([...commentsList, ...arr]);
          setTotalPages(commentsObj.totalPages);
        } else {
          setCommentsList(commentsObj.data);
          setTotalPages(commentsObj.totalPages);
        }
      } else {
        setExpanded(false);
      }
    }
  }, [commentsObj, commentsObj && commentsObj.data]);

  useEffect(() => {
    if (currentComment && repliesObj && Object.keys(repliesObj).length > 0) {
      if (repliesObj[currentComment].post_id == post._id) {
        if (
          repliesList &&
          repliesList[currentComment] &&
          repliesObj[currentComment].data &&
          !(repliesObj[currentComment].data === repliesList[currentComment]) &&
          replyPage &&
          replyPage[currentComment] !== 1
        ) {
          var arr = repliesObj[currentComment].data.filter(
            (obj) => !repliesList[currentComment].includes(obj)
          );
          setRepliesList({
            ...repliesList,
            [currentComment]: [...repliesList[currentComment], ...arr],
          });
        } else {
          setRepliesList({
            ...repliesList,
            [currentComment]: repliesObj[currentComment].data,
          });
        }
      } else {
        setExpanded(false);
      }
    }
  }, [repliesObj]);

  useEffect(() => {
    return () => {
      clearComments();
      clearReplies();
      setCommentsList(null);
      setRepliesList({});
    };
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const isCommentAllowed = () => {
    let friends =
      post.friendStatus &&
      post.friendStatus.areYouFollowing &&
      post.friendStatus.isHeFollowing
        ? post.friendStatus.isHeFollowingType === "Open" &&
          post.friendStatus.areYouFollowingType === "Open"
          ? "OpenFriends"
          : post.friendStatus.isHeFollowingType === "Hidden" &&
            post.friendStatus.areYouFollowingType === "Hidden"
          ? "HiddenFriends"
          : null
        : null;
    var isAllowed = false;
    if (post.allowOpenComments || post.allowHiddenComments) {
      isAllowed =
        owner ||
        (post.allowOpenComments && post.whoCanOpenComment === "AnyOne") ||
        (post.allowHiddenComments && post.whoCanHiddenComment === "AnyOne") ||
        ((post.whoCanOpenComment === "HiddenOpenFriends" ||
          post.whoCanHiddenComment === "HiddenOpenFriends") &&
          friends !== null) ||
        (post.allowOpenComments &&
          post.whoCanOpenComment === "OpenFriends" &&
          friends === "OpenFriends") ||
        (post.allowHiddenComments &&
          post.whoCanHiddenComment === "OpenFriends" &&
          friends === "OpenFriends") ||
        (post.allowOpenComments &&
          post.whoCanOpenComment === "HiddenFriends" &&
          friends === "HiddenFriends") ||
        (post.allowHiddenComments &&
          post.whoCanHiddenComment === "HiddenFriends" &&
          friends === "HiddenFriends");
    }
    return isAllowed;
    // switch (true) {
    //   case owner:
    //     return true;
    //   case post.whoCanOpenComment === "AnyOne" ||
    //     post.whoCanHiddenComment === "AnyOne":
    //     return true;
    //   case post.whoCanOpenComment === "HiddenOpenFriends" ||
    //     post.whoCanHiddenComment === "HiddenOpenFriends":
    //     return friends !== null;
    //   case post.whoCanOpenComment === "OpenFriends":
    //     return friends === "OpenFriends";
    //   case post.whoCanHiddenComment === "OpenFriends":
    //     return friends === "OpenFriends";
    //   case post.whoCanOpenComment === "HiddenFriends":
    //     return friends === "HiddenFriends";
    //   case post.whoCanHiddenComment === "HiddenFriends":
    //     return friends === "HiddenFriends";
    //   default:
    //     return false;
    // }
  };

  const openCommentAllowed = () => {
    let friends =
      post.friendStatus &&
      post.friendStatus.areYouFollowing &&
      post.friendStatus.isHeFollowing
        ? post.friendStatus.isHeFollowingType === "Open" &&
          post.friendStatus.areYouFollowingType === "Open"
          ? "OpenFriends"
          : post.friendStatus.isHeFollowingType === "Hidden" &&
            post.friendStatus.areYouFollowingType === "Hidden"
          ? "HiddenFriends"
          : null
        : null;
    var isAllowed = false;
    if (post.allowOpenComments) {
      isAllowed =
        owner ||
        post.whoCanOpenComment === "AnyOne" ||
        (post.whoCanOpenComment === "HiddenOpenFriends" && friends !== null) ||
        (post.whoCanOpenComment === "OpenFriends" &&
          friends === "OpenFriends") ||
        (post.whoCanOpenComment === "HiddenFriends" &&
          friends === "HiddenFriends");
    }
    return isAllowed;
    // switch (true) {
    //   case owner:
    //     return true;
    //   case post.whoCanOpenComment === "AnyOne":
    //     return true;
    //   case post.whoCanOpenComment === "HiddenOpenFriends":
    //     return !friends;
    //   case post.whoCanOpenComment === "OpenFriends":
    //     return friends === "OpenFriends";
    //   case post.whoCanOpenComment === "HiddenFriends":
    //     return friends === "HiddenFriends";
    //   default:
    //     return false;
    // }
  };

  const hiddenCommentAllowed = () => {
    let friends =
      post.friendStatus &&
      post.friendStatus.areYouFollowing &&
      post.friendStatus.isHeFollowing
        ? post.friendStatus.isHeFollowingType === "Open" &&
          post.friendStatus.areYouFollowingType === "Open"
          ? "OpenFriends"
          : post.friendStatus.isHeFollowingType === "Hidden" &&
            post.friendStatus.areYouFollowingType === "Hidden"
          ? "HiddenFriends"
          : null
        : null;
    var isAllowed = false;
    if (post.allowHiddenComments) {
      isAllowed =
        owner ||
        post.whoCanHiddenComment === "AnyOne" ||
        (post.whoCanHiddenComment === "HiddenOpenFriends" &&
          friends !== null) ||
        (post.whoCanHiddenComment === "OpenFriends" &&
          friends === "OpenFriends") ||
        (post.whoCanHiddenComment === "HiddenFriends" &&
          friends === "HiddenFriends");
    }
    return isAllowed;
    // switch (true) {
    //   case owner:
    //     return true;
    //   case post.whoCanHiddenComment === "AnyOne":
    //     return true;
    //   case post.whoCanHiddenComment === "HiddenOpenFriends":
    //     return !friends;
    //   case post.whoCanHiddenComment === "OpenFriends":
    //     return friends === "OpenFriends";
    //   case post.whoCanHiddenComment === "HiddenFriends":
    //     return friends === "HiddenFriends";
    //   default:
    //     return false;
    // }
  };

  const RenderLikeCountBtn = () => (
    <Typography
      variant="subtitle2"
      color="textPrimary"
      style={{
        marginLeft: 4,
        marginRight: 4,
        paddingLeft: 8,
        paddingRight: 8,
        cursor: "pointer",
        textDecoration: "underline",
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleOpenUserList("Open", "Like");
      }}
    >
      {noOfLikes}
    </Typography>
  );

  const renderFilterBtns = (allowed) => {
    const typeLabel = (type) => (
      <Button
        style={{ textTransform: "none" }}
        onClick={(e) => handleChangeType(type)}
      >
        {type}
      </Button>
    );
    const typeBtns = () => (
      <ButtonGroup
        size="small"
        color="primary"
        aria-label="type"
        name="type"
        style={{
          margin: "12px",
          height: "32px",
        }}
      >
        <Button
          style={{ padding: "4px", textTransform: "none" }}
          onClick={(e) => handleChangeType("Open")}
          variant={type === "Open" ? "contained" : "outlined"}
        >
          Open
          {type === "Hidden"
            ? ` - ${commentsObj.opencount || 0}`
            : type === "Open" && ` - ${commentsObj.allcount || 0}`}
        </Button>
        <Button
          style={{ padding: "4px", textTransform: "none" }}
          onClick={(e) => handleChangeType("Hidden")}
          variant={type === "Hidden" ? "contained" : "outlined"}
        >
          Hidden
          {type === "Open"
            ? ` - ${commentsObj.hiddencount || 0}`
            : type === "Hidden" && ` - ${commentsObj.allcount || 0}`}
        </Button>
      </ButtonGroup>
    );

    // const genderBtns = () => (
    //   <ButtonGroup
    //     size="small"
    //     color="primary"
    //     aria-label="gender"
    //     name="gender"
    //     style={{
    //       margin: "12px",
    //       height: "32px",
    //     }}
    //   >
    //     <Button
    //       style={{ padding: "4px", textTransform: "none" }}
    //       onClick={(e) => handleChangeGender("All")}
    //       variant={gender === "All" ? "contained" : "outlined"}
    //     >
    //       All
    //       {` - ${commentsObj.allcount || 0}`}
    //     </Button>
    //     <Button
    //       style={{ padding: "4px", textTransform: "none" }}
    //       onClick={(e) => handleChangeGender("Male")}
    //       variant={gender === "Male" ? "contained" : "outlined"}
    //     >
    //       M{` - ${commentsObj.malecount || 0}`}
    //     </Button>
    //     <Button
    //       style={{ padding: "4px", textTransform: "none" }}
    //       onClick={(e) => handleChangeGender("Female")}
    //       variant={gender === "Female" ? "contained" : "outlined"}
    //     >
    //       F{` - ${commentsObj.femalecount || 0}`}
    //     </Button>
    //   </ButtonGroup>
    // );

    if (owner) {
      switch (true) {
        case allowed === "Both":
          return (
            <FormGroup row={true} size="small" className={classes.filter}>
              {typeBtns()}
              {/* {genderBtns()} */}
            </FormGroup>
          );
        case allowed === "Open":
          return (
            <FormGroup row={true} size="small" className={classes.filter}>
              {typeLabel("Open")}
              {/* {typeBtns()} */}
              {/* {genderBtns()} */}
            </FormGroup>
          );
        case allowed === "Hidden":
          return (
            <FormGroup row={true} size="small" className={classes.filter}>
              {typeLabel("Hidden")}
              {/* {typeBtns()} */}
              {/* {genderBtns()} */}
            </FormGroup>
          );
      }
    } else {
      // let openAllowed = post && openCommentAllowed();
      // let hiddenAllowed = post && hiddenCommentAllowed();
      switch (true) {
        // case openAllowed || hiddenAllowed:
        //   return (
        //     <FormGroup row={true} size="small" className={classes.filter}>
        //       {typeBtns()}
        //       {type === "Open" && genderBtns()}
        //     </FormGroup>
        //   );
        case allowed === "Both":
          // case allowed === "Both" && (openAllowed || hiddenAllowed):
          return (
            <FormGroup row={true} size="small" className={classes.filter}>
              {typeBtns()}
              {type === "Open"}
              {/* {type === "Open" && genderBtns()} */}
            </FormGroup>
          );
        case allowed === "Open":
          // case (allowed === "Both" && openAllowed) ||
          //   (allowed === "Open" && openAllowed):
          return (
            <FormGroup row={true} size="small" className={classes.filter}>
              {typeLabel("Open")}
              {/* {genderBtns()} */}
            </FormGroup>
          );
        case allowed === "Hidden":
          // case (allowed === "Both" && hiddenAllowed) ||
          //   (allowed === "Hidden" && hiddenAllowed):
          return (
            <FormGroup row={true} size="small" className={classes.filter}>
              {/* {typeBtns()} */}
              {typeLabel("Hidden")}
            </FormGroup>
          );
      }
    }
  };

  const renderOtherUserControls = (isLiked, isFavourited) => {
    // const followBtn = () => {
    //   {
    //     switch (true) {
    //       case gallery.friendStatus &&
    //         gallery.friendStatus.areYouFollowing &&
    //         gallery.friendStatus.isHeFollowing:
    //         return gallery.friendStatus.areYouFollowingType === "Open" ? (
    //           <IconButton
    //             fontSize="small"
    //             color="primary"
    //             onClick={(e) => handleUnfollow("Open")}
    //           >
    //             <Badge
    //               anchorOrigin={{
    //                 vertical: "bottom",
    //                 horizontal: "right",
    //               }}
    //               badgeContent={"O"}
    //               style={{ transform: "none" }}
    //             >
    //               <DoneSharpIcon className={classes.icons} />
    //             </Badge>
    //           </IconButton>
    //         ) : (
    //           <IconButton
    //             fontSize="small"
    //             color="primary"
    //             onClick={(e) => handleUnfollow("Hidden")}
    //           >
    //             <Badge
    //               anchorOrigin={{
    //                 vertical: "bottom",
    //                 horizontal: "right",
    //               }}
    //               badgeContent={"H"}
    //               style={{ transform: "none" }}
    //             >
    //               <DoneSharpIcon className={classes.icons} />
    //             </Badge>
    //           </IconButton>
    //         );
    //       case gallery.friendStatus &&
    //         !gallery.friendStatus.areYouFollowing &&
    //         gallery.friendStatus.isHeFollowing:
    //         return gallery.friendStatus.isHeFollowingType === "Open" ? (
    //           <IconButton
    //             fontSize="small"
    //             color="primary"
    //             onClick={(e) => handleFollow("Open")}
    //           >
    //             <Badge
    //               anchorOrigin={{
    //                 vertical: "bottom",
    //                 horizontal: "right",
    //               }}
    //               badgeContent={"O"}
    //               style={{ transform: "none" }}
    //             >
    //               <ArrowBackSharpIcon className={classes.icons} />
    //             </Badge>
    //           </IconButton>
    //         ) : (
    //           <IconButton
    //             fontSize="small"
    //             color="primary"
    //             onClick={(e) => handleFollow("Hidden")}
    //           >
    //             <Badge
    //               anchorOrigin={{
    //                 vertical: "bottom",
    //                 horizontal: "right",
    //               }}
    //               badgeContent={"H"}
    //             >
    //               <ArrowBackSharpIcon className={classes.icons} />
    //             </Badge>
    //           </IconButton>
    //         );
    //       case gallery.friendStatus &&
    //         gallery.friendStatus.areYouFollowing &&
    //         !gallery.friendStatus.isHeFollowing:
    //         return gallery.friendStatus.areYouFollowingType === "Open" ? (
    //           <IconButton
    //             fontSize="small"
    //             color="primary"
    //             onClick={(e) => handleUnfollow("Open")}
    //           >
    //             <Badge
    //               anchorOrigin={{
    //                 vertical: "bottom",
    //                 horizontal: "right",
    //               }}
    //               badgeContent={"F"}
    //             >
    //               <RadioButtonUncheckedIcon className={classes.icons} />
    //             </Badge>
    //           </IconButton>
    //         ) : (
    //           <IconButton
    //             fontSize="small"
    //             color="primary"
    //             onClick={(e) => handleUnfollow("Hidden")}
    //           >
    //             <Badge
    //               anchorOrigin={{
    //                 vertical: "bottom",
    //                 horizontal: "right",
    //               }}
    //               badgeContent={"F"}
    //             >
    //               <Icon className={classes.icons}>H</Icon>
    //             </Badge>
    //           </IconButton>
    //         );
    //       case !gallery.friendStatus:
    //         return (
    //           <IconButton
    //             fontSize="small"
    //             color="primary"
    //             onClick={(e) => handleFollow()}
    //           >
    //             <AddSharpIcon className={classes.icons} color="inherit" />
    //           </IconButton>
    //         );
    //       default:
    //         return null;
    //     }
    //   }
    // };

    const likeBtn = () =>
      isLiked ? (
        isPostLikedType === "Open" ? (
          <IconButton
            className={classes.icons}
            aria-label="open-like"
            color="secondary"
            onClick={() => handleAction("Open", "Like")}
          >
            {/* <Badge
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              badgeContent={"O"}
            >
              <FavoriteIcon className={classes.icons} />
            </Badge> */}
            <Icon className={classes.icons}>
              <img src={OpenLikeIcon} />
            </Icon>
          </IconButton>
        ) : (
          <IconButton
            className={classes.icons}
            aria-label="hidden-like"
            color="secondary"
            onClick={() => handleAction("Hidden", "Like")}
          >
            {/* <Badge
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              badgeContent={"H"}
            >
              <FavoriteIcon className={classes.icons} />
            </Badge> */}
            <Icon className={classes.icons}>
              <img src={HiddenLikeIcon} />
            </Icon>
          </IconButton>
        )
      ) : (
        <IconButton
          className={classes.icons}
          size="small"
          disableFocusRipple
          disableRipple
          onMouseLeave={() => setLike(false)}
        >
          {like && (
            <img
              style={{ width: "25px" }}
              src={OpenLikeIcon}
              alt="open-like"
              className="floatingBtn-left"
              onClick={() => handleAction("Open", "Like")}
            />
          )}

          <IconButton
            className={classes.icons}
            aria-label="like"
            onMouseEnter={() => setLike(true)}
            disabled={like}
          >
            <FavoriteBorderIcon
              className={classes.icons}
              style={{
                fontSize: like ? "12px" : "24px",
              }}
            />
          </IconButton>

          {like && (
            <img
              style={{ width: "25px" }}
              src={HiddenLikeIcon}
              className="floatingBtn-right"
              alt="hidden-like"
              onClick={() => handleAction("Hidden", "Like")}
            />
          )}
        </IconButton>
      );

    // const favBtn = () =>
    //   isFavourited ? (
    //     gallery.isGalleryFavouritedType === "Open" ? (
    //       <IconButton
    //         aria-label="open-fav"
    //         color="secondary"
    //         onClick={() => handleAction("Open", "Favourite")}
    //       >
    //         <Badge
    //           anchorOrigin={{
    //             vertical: "bottom",
    //             horizontal: "right",
    //           }}
    //           badgeContent={"O"}
    //         >
    //           <BookmarkIcon className={classes.icons} />
    //         </Badge>
    //       </IconButton>
    //     ) : (
    //       <IconButton
    //         aria-label="hidden-fav"
    //         color="secondary"
    //         onClick={() => handleAction("Hidden", "Favourite")}
    //       >
    //         <Badge
    //           anchorOrigin={{
    //             vertical: "bottom",
    //             horizontal: "right",
    //           }}
    //           badgeContent={"H"}
    //         >
    //           <BookmarkIcon className={classes.icons} />
    //         </Badge>
    //       </IconButton>
    //     )
    //   ) : (
    //     <IconButton
    //       size="small"
    //       disableFocusRipple
    //       disableRipple
    //       onMouseLeave={() => setFav(false)}
    //     >
    //       <IconButton
    //         aria-label="open-fav"
    //         style={{ display: fav ? "block" : "none" }}
    //         onClick={() => handleAction("Open", "Favourite")}
    //       >
    //         <Badge
    //           anchorOrigin={{
    //             vertical: "bottom",
    //             horizontal: "right",
    //           }}
    //           badgeContent={"O"}
    //         >
    //           <BookmarkBorderIcon className={classes.icons} />
    //         </Badge>
    //       </IconButton>
    //       <IconButton
    //         aria-label="fav"
    //         onMouseEnter={() => setFav(true)}
    //         disabled={fav}
    //       >
    //         <BookmarkBorderIcon
    //           className={classes.icons}
    //           style={{
    //             fontSize: fav ? "12px" : "24px",
    //           }}
    //         />
    //       </IconButton>
    //       <IconButton
    //         aria-label="hidden-fav"
    //         style={{ display: fav ? "block" : "none" }}
    //         onClick={() => handleAction("Hidden", "Favourite")}
    //       >
    //         <Badge
    //           anchorOrigin={{
    //             vertical: "bottom",
    //             horizontal: "right",
    //           }}
    //           badgeContent={"H"}
    //         >
    //           <BookmarkBorderIcon className={classes.icons} />
    //         </Badge>
    //       </IconButton>
    //     </IconButton>
    //   );

    const commentbtn = () => (
      <IconButton
        className={classes.icons}
        aria-label="add to favorites"
        onClick={handleExpandClick}
      >
        <CommentIcon color={expanded ? "primary" : "default"} />
        <Typography variant="body2" style={{ margin: 12 }}>
          {noOfComments}
        </Typography>
      </IconButton>
    );

    const shareBtn = () => (
      <IconButton
        className={classes.icons}
        aria-label="share"
        color="inherit"
        onClick={() => handleShareGallery()}
      >
        <Icon className={classes.icons}>
          <img src={ShareIcon} />
        </Icon>
        <Typography variant="body2" style={{ margin: 12 }}>
          {noOfShare}
        </Typography>
      </IconButton>
    );

    const reportBtn = () => (
      <IconButton
        aria-label="report"
        color="inherit"
        onClick={() => setOpenReport(true)}
      >
        <Icon className={classes.icons}>
          <img className={classes.imgIcon} src={ReportIcon} />
        </Icon>
      </IconButton>
    );

    return (
      <>
        {/* {followBtn()} */}
        {commentbtn()}
        {likeBtn()}
        <RenderLikeCountBtn />
        {/* {favBtn()} */}
        {shareBtn()}
        {reportBtn()}
      </>
    );
  };

  const renderOwnerControls = () => (
    <>
    
      {/* <IconButton
          aria-label="fav"
          color="primary"
          onClick={() => handleOpenUserList("Open", "Favourite")}
        >
          <BookmarkBorderIcon className={classes.icons} />
        </IconButton> */}
      <IconButton
        className={classes.icons}
        aria-label="add to favorites"
        onClick={handleExpandClick}
      >
        <CommentIcon color={expanded ? "primary" : "default"} />
        <Typography variant="body2" style={{ margin: 12 }}>
          {noOfComments}
        </Typography>
      </IconButton>

      <IconButton
        className={classes.icons}
        aria-label="like"
        color="primary"
        onClick={() => handleOpenUserList("Open", "Like")}
      >
        <Icon className={classes.icons}>
          <img src={LikesIcon} />
        </Icon>
        {/* <FavoriteBorderIcon className={classes.icons} /> */}
        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ margin: 6 }}
        >
          {noOfLikes}
        </Typography>
      </IconButton>

      <IconButton
        className={classes.icons}
        aria-label="share"
        onClick={() => handleShareGallery()}
      >
        <Icon className={classes.icons}>
          <img src={ShareIcon} />
        </Icon>
        <Typography variant="body2" style={{ margin: 12 }}>
          {noOfShare}
        </Typography>
      </IconButton>
    </>
  );

  useEffect(() => {
    // if (!replyExpanded) setReplyPage({});
    if (expanded && currentComment && gender && type) {
      let params = {
        post_id: post._id,
        commenttype: type,
        gender: "All",
        page: (replyPage[currentComment] || 1) - 1,
        limit: replyLimit,
        key: replyKey[currentComment]
          ? replyKey[currentComment] === ""
            ? ""
            : encryptText(replyKey[currentComment])
          : "",
        comment_id: currentComment,
      };
      getRepliesByCommentId(params);
    }
  }, [currentComment, replyPage, replyKey]);

  const renderReplies = (commentId, length) => (
    <Accordion
      square
      elevation={0}
      style={{
        marginLeft: 32,
        marginTop: 0,
      }}
      expanded={replyExpanded.includes(commentId)}
      TransitionProps={{ collapsedHeight: 0 }}
      classes={{ root: classes.before }}
      onChange={(e, thisExpanded) => {
        thisExpanded
          ? setReplyExpanded([commentId, ...replyExpanded])
          : setReplyExpanded((replyExpanded) => {
              var arr = replyExpanded.filter((i) => i != commentId);
              return arr;
            });
        thisExpanded
          ? setCurrentComment(commentId)
          : setRepliesList((repliesList) => {
              delete repliesList[commentId];
              return repliesList;
            });
        thisExpanded
          ? setReplyPage({ ...replyPage, [commentId]: 1 })
          : setReplyPage((replyPage) => {
              delete replyPage[commentId];
              return replyPage;
            });
      }}
    >
      <AccordionSummary
        expandIcon={<></>}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{
          minHeight: 0,
          padding: 0,
          margin: 0,
        }}
        IconButtonProps={{ style: { margin: 0, padding: 0 } }}
        classes={{
          content: classes.collapse,
          expanded: classes.expanded,
        }}
      >
        {!replyExpanded.includes(commentId) ? (
          <Typography
            variant="caption"
            align="right"
            style={{ marginLeft: 12, textAlign: "right" }}
          >
            —— View {length > 1 ? "replies" : "reply"} {"("}
            {length}
            {") "}
          </Typography>
        ) : replyPage[commentId] < length / replyLimit ? (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Typography
              variant="caption"
              align="right"
              style={{ marginLeft: 6, textAlign: "right" }}
              onClick={(e) => {
                e.stopPropagation();
                setCurrentComment(commentId);
                setReplyPage({
                  ...replyPage,
                  [commentId]: replyPage[commentId] + 1,
                });
              }}
            >
              —— View replies ({length - replyLimit * replyPage[commentId]})
            </Typography>
            <TextField
              // fullWidth
              placeholder="Search replies"
              onFocus={() => setReplyPage({ ...replyPage, [commentId]: 1 })}
              value={replyKey[commentId]}
              onChange={(e) =>
                setReplyKey({ ...replyKey, [commentId]: e.target.value })
              }
              variant="outlined"
              size="small"
              style={{ marginLeft: 12, width: 150 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{ marginRight: 0 }}>
                    <SearchIcon fontSize="small" style={{ fontSize: 18 }} />
                  </InputAdornment>
                ),
                style: { paddingLeft: 3 },
              }}
              inputProps={{
                style: { padding: 4 },
              }}
            />
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="caption"
              align="right"
              style={{ marginLeft: 6, textAlign: "right" }}
            >
              —— Hide {length > 1 ? "replies" : "reply"}
            </Typography>
            <TextField
              // fullWidth
              onClick={(e) => {
                e.stopPropagation();
              }}
              onFocus={() => setReplyPage({ ...replyPage, [commentId]: 1 })}
              placeholder="Search replies"
              value={replyKey[commentId]}
              onChange={(e) =>
                setReplyKey({ ...replyKey, [commentId]: e.target.value })
              }
              variant="outlined"
              size="small"
              style={{ marginLeft: 12, width: 150 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{ marginRight: 0 }}>
                    <SearchIcon fontSize="small" style={{ fontSize: 18 }} />
                  </InputAdornment>
                ),
                style: { paddingLeft: 4 },
              }}
              inputProps={{
                style: { padding: 4 },
              }}
            />
          </div>
        )}
      </AccordionSummary>
      <AccordionDetails
        style={{
          display: "block",
          padding: 0,
          margin: 0,
          marginBottom: 12,
        }}
      >
        {repliesList &&
          Object.keys(repliesList).length > 0 &&
          repliesList[commentId] &&
          repliesList[commentId].length &&
          repliesList[commentId].map((reply) => {
            return (
              <Grid>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={reply._id === commentMenu}
                  onClose={handleMenuClose}
                >
                  {owner &&
                    loggedInUser !== reply.user_id._id &&
                    reply.commentType === "Open" && (
                      <MenuItem
                        onClick={() => handleReply(reply, "hiddenReply")}
                      >
                        Reply in Hidden
                      </MenuItem>
                    )}
                  {(owner || loggedInUser === reply.user_id._id) && (
                    <MenuItem onClick={() => handleDeleteComment(reply._id)}>
                      Delete
                    </MenuItem>
                  )}
                </Menu>
                <Grid
                  container
                  wrap="nowrap"
                  spacing={1}
                  style={
                    {
                      // marginLeft: "32px",
                      // marginLeft: `${margin}px`,
                    }
                  }
                  onMouseEnter={() => setHover(reply._id)}
                  onMouseLeave={() => setHover(null)}
                >
                  <Grid item>
                    {reply.user_id.profilepic ? (
                      <Avatar
                        style={{
                          width: 32,
                          height: 32,
                        }}
                        alt="owner"
                        src={
                          process.env.REACT_APP_BUCKET_NAME +
                          reply.user_id.profilepic
                        }
                        onClick={(e) =>
                          openAvatarModal(
                            process.env.REACT_APP_BUCKET_NAME +
                              reply.user_id.profilepic
                          )
                        }
                      />
                    ) : (
                      <Avatar
                        style={{
                          width: 32,
                          height: 32,
                        }}
                        alt="owner"
                        onClick={(e) => openAvatarModal()}
                      >
                        <PersonIcon />
                      </Avatar>
                    )}
                  </Grid>
                  <Grid
                    justifyContent="left"
                    item
                    className={classes.commentBody}
                  >
                    <Typography
                      variant="subtitle2"
                      style={{
                        marginRight: "6px",
                        display: "inline-block",
                      }}
                    >
                      <Link to={`/user_profile/${reply.user_id._id}`}>
                        {reply.user}
                      </Link>
                    </Typography>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      style={{
                        display: "inline-block",
                        float: "right",
                        marginRight:
                          owner || loggedInUser === reply.user_id._id
                            ? "0px"
                            : "48px",
                      }}
                    >
                      {moment(reply.createdAt).format("DD-MM-YYYY")}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ overflowWrap: "anywhere" }}
                    >
                      {handleGetText(reply.comment)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    justifyContent="right"
                    style={{ alignSelf: "center", minWidth: 24 }}
                    onClick={(e) =>
                      hover === reply._id &&
                      (owner || loggedInUser === reply.user_id._id) &&
                      handleMenuClick(e, reply._id)
                    }
                  >
                    {hover === reply._id &&
                      (owner || loggedInUser === reply.user_id._id) && (
                        <IconButton
                          variant="text"
                          color="inherit"
                          disableFocusRipple
                          disableRipple
                          style={{
                            padding: 0,
                            // marginRight: "72px",
                          }}
                        >
                          <MoreVertIcon fontSize="small" />
                        </IconButton>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
      </AccordionDetails>
    </Accordion>
  );

  const handleChangeType = (type) => {
    setGender("All");
    setType(type);
    setReplyExpanded([]);
    setCommentsList(null);
    setRepliesList({});
    setCurrentComment(null);
    setPage(1);
    setReplyPage({});
    setKey("");
    setReplyKey({});
  };

  const handleChangeGender = (gender) => {
    setGender(gender);
    setReplyExpanded([]);
    setCommentsList(null);
    setRepliesList({});
    setCurrentComment(null);
    setPage(1);
    setReplyPage({});
    setKey("");
    setReplyKey({});
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCommentMenu(null);
  };

  const handleDeleteComment = (commentId) => {
    handleMenuClose();
    deleteComment(commentId).then((r) => {
      setCommentsRefresh(!commentsRefresh);
    });
    // setPage(1);
    // setKey("");
    // setReplyKey({});
    setnoOfComments(noOfComments - 1);
  };

  const handleShareGallery = () => {
    setOpenPublicShare(true);
  };

  const handleDeletePost = () => {
    setTitle(`Are you sure you want to delete this post?`);
    setDescription(`This cannot be undone.`);
    setDeleting(true);
    setOpenConfirm(true);
  };

  const handleUpdatePost = () => {
    history.push(`/posts/edit/${post._id}`);
  };

  const handleGetText = (text) => {
    text = decryptText(text);
    // if (post && post.galleryType === "videos") {
    //   let expression = /&.*?[^&]&/g;
    //   var regex = new RegExp(expression, "g");
    //   var allMatches = text.match(regex);
    //   var finalText = text;
    // if (allMatches && allMatches.length > 0) {
    //   for (var i = 0; i < allMatches.length; i++) {
    //     if (typeof finalText == "string") {
    //       finalText = (
    //         <Typography variant="body2" display="inline"
    // style={{ overflowWrap: "anywhere" }}
    // >
    //           {reactStringReplace(
    //             finalText,
    //             allMatches[i],
    //             (match, index) => {
    //               var time = match.replace(/&/g, "").split(":");
    //               time.map((a) => Number(a));
    //               if (time[0] && time[1]) {
    //                 return (
    //                   <a key={i} onClick={() => handleSeek(time)}>
    //                     {match.replace(/&/g, "")}
    //                   </a>
    //                 );
    //               } else return allMatches[i];
    //             }
    //           )}
    //         </Typography>
    //       );
    //     } else {
    //       finalText.props.children[finalText.props.children.length - 1] =
    //         // <Typography variant="body2" display="inline"
    // style={{ overflowWrap: "anywhere" }}
    // >
    //         reactStringReplace(
    //           finalText.props.children[finalText.props.children.length - 1],
    //           allMatches[i],
    //           (match, index) => {
    //             var time = match.replace(/&/g, "").split(":");
    //             time.map((a) => Number(a));
    //             if (time[0] && time[1]) {
    //               return (
    //                 <a key={i} onClick={() => handleSeek(time)}>
    //                   {match.replace(/&/g, "")}
    //                 </a>
    //               );
    //             } else return allMatches[i];
    //           }
    //         );
    //       // </Typography>
    //     }
    //   }
    // }
    //   return finalText;
    // } else
    return text;
  };

  const handleMenuClick = (e, id) => {
    setAnchorEl(e.currentTarget);
    setCommentMenu(id);
  };

  const handlePopover = (e) => {
    setPop(true);
    setAnchorElPop(e.currentTarget);
  };

  const fetchMoreData = () => {
    setPage(page + 1);
  };

  const handleReply = (parent, type) => {
    handleMenuClose();
    if (type === "hiddenReply") setHiddenReplying(true);
    setReplyParent(parent);
    replyRef.current.focus();
  };

  const handleOpenUserList = (actionType, actionName) => {
    setActionType(actionType);
    setActionName(actionName);
    setOpenUserList(true);
  };

  const handleAction = (actionType, actionName) => {
    
    if (actionName === "Like") {
      if (post && isPostLiked) {
        let body = {
          post_id: post._id,
        };
        unlikePost(body).then((res) => {
          setLike(false);
          setPostLiked(false);
          setPostLikedType(actionType);
          setNoOfLikes(res.post.nooflikes);
        });
      } else {
        let body = {
          post_id: post._id,
          likeType: actionType,
        };
        likePost(body).then((res) => {
          setLike(false);
          setPostLiked(true);
          setPostLikedType(actionType);
          setNoOfLikes(res.post.nooflikes);
        });
      }
    }
    // else if (actionName === "Favourite") {
    //   if (gallery && gallery.isGalleryFavourited) {
    //     let body = {
    //       galleryId: gallery._id,
    //     };
    //     unfavGallery(body).then((res) => {
    //       setFav(false);
    //       getGalleryDetailsById(galleryId).then((res) => setGallery(res.data));
    //     });
    //   } else setOpenFavourite(actionType);
    // }
  };

  const handleConfirm = () => {
    if (deleting) {
      setOpenConfirm(false);
      setDeleting(false);
      if (owner && owner) {
        deletePost(post._id).then((res) => handlePostsRefresh());
      }
    } else {
      setOpenConfirm(false);
      // let user_id = gallery.userId && gallery.userId._id;
      // unfollow({ type: followType, user_id }).then((res) => {
      //   setFollowType(null);
      //   setRefresh(!refresh);
      // });
    }
  };

  const handlePost = () => {
    if (isCommentAllowed()) {
      if (newComment === "" || newComment.length > 280) {
        setErr(true);
        return;
      }
      setErr(false);
      let body = {
        commentType:
          owner && hiddenReplying
            ? "Hidden"
            : openCommentAllowed() && hiddenCommentAllowed()
            ? type
            : hiddenCommentAllowed()
            ? "Hidden"
            : openCommentAllowed()
            ? "Open"
            : null,
        post_id: post._id,
        comment: encryptText(newComment),
        hiddenReplying: owner && hiddenReplying,
      };
      if (replyParent._id) body.parent_id = replyParent._id;
      postComment(body).then((res) => {
        if (!res.error) {
          setNewComment("");
          // if (replyParent._id) {
          //   setRepliesList((repliesList) => {
          //     delete repliesList[replyParent._id];
          //     return repliesList;
          //   });
          //   setCurrentComment(replyParent._id);
          //   setReplyPage((replyPage) => {
          //     delete replyPage[replyParent._id];
          //     return replyPage;
          //   });
          //   !replyExpanded.includes(replyParent._id) &&
          //     setReplyExpanded([replyParent._id, ...replyExpanded]);
          // } else {
          // }

          setCommentsRefresh(!commentsRefresh);

          setReplyParent({});
          if (hiddenReplying) {
            setType("Hidden");
          }
          setHiddenReplying(false);
          setKey("");
          setReplyKey({});
          setnoOfComments(noOfComments + 1);
          // setPage(1);
        }
      });
    } else {
      openDialog("Not Allowed");
    }
  };

  return (
    <Card
      className={classes.root}
      style={{ backgroundColor: hovered ? "rgba(0, 0, 0, 0.05)" : "white" }}
    >
      {openConfirm && (
        <ConfirmationDialog
          open={openConfirm}
          handleCancel={() => setOpenConfirm(false)}
          handleConfirm={() => handleConfirm()}
          title={title}
          description={description}
        />
      )}
      {openUserList && (
        <UserList
          open={openUserList}
          handleClose={() => setOpenUserList(false)}
          actionType={actionType}
          actionName={actionName}
          isOwner={owner}
          post_id={post && post._id}
        />
      )}
      {openReport && (
        <ReportDialog
          open={openReport}
          handleClose={() => setOpenReport(false)}
          postId={post && post._id}
        />
      )}
      {openPublicShare && (
        <PublicShare
          open={openPublicShare}
          handleClose={() => setOpenPublicShare(false)}
          url={"https://celebbook.in/post/" + post._id}
          post_id={post._id}
          handleShareInc={() => setNoOfShare(noOfShare + 1)}
        />
      )}
      <CardHeader
        style={{ paddingTop: 0, paddingBottom: 0 }}
        avatar={
          post.profilepic || (post.userId && post.userId.profilepic) ? (
            <Avatar
              alt="owner"
              src={
                process.env.REACT_APP_BUCKET_NAME +
                (post.profilepic || (post.userId && post.userId.profilepic))
              }
              onClick={(e) =>
                openAvatarModal(
                  process.env.REACT_APP_BUCKET_NAME +
                    (post.profilepic || (post.userId && post.userId.profilepic))
                )
              }
            />
          ) : (
            <Avatar
              alt="owner"
              className={classes.avatar}
              onClick={(e) => openAvatarModal()}
            >
              <PersonIcon />
            </Avatar>
          )
        }
        action={
          owner && (
            <>
              <IconButton
                aria-label="settings"
                onClick={(e) => handlePopover(e)}
              >
                <MoreVertIcon />
              </IconButton>
              <Popover
                id="simple-popper"
                open={pop}
                anchorEl={anchorElPop}
                onClose={(e) => {
                  setPop(false);
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <List component="nav" className={classes.root}>
                  {owner && (
                    <ListItem
                      button
                      className={classes.listItem}
                      onClick={(e) => {
                        handleUpdatePost();
                      }}
                    >
                      <ListItemText
                        primary="Edit Post"
                        classes={{ primary: classes.listItemText }}
                      />
                    </ListItem>
                  )}
                  {owner && (
                    <ListItem
                      button
                      className={classes.listItem}
                      onClick={(e) => {
                        handleDeletePost();
                      }}
                    >
                      <ListItemText
                        primary="Delete Post"
                        style={{ color: "red" }}
                        classes={{ primary: classes.listItemText }}
                      />
                    </ListItem>
                  )}
                  {/* {currentUser.blockStatus.toLowerCase().startsWith("you") ? (
                  <ListItem
                    button
                    className={classes.listItem}
                    onClick={() => handleUnblock()}
                  >
                    <ListItemText
                      primary={"Unblock User"}
                      classes={{ primary: classes.listItemText }}
                    />
                  </ListItem>
                ) : (
                  <ListItem
                    button
                    className={classes.listItem}
                    onClick={() => handleBlock()}
                  >
                    <ListItemText
                      primary={"Block User"}
                      classes={{ primary: classes.listItemText }}
                    />
                  </ListItem>
                )} */}
                </List>
              </Popover>
            </>
          )
        }
        title={
          <>
            <Link
              to={`/user_profile/${
                post.userId || (post.userId && post.userId._id)
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              <Typography variant="h6" display="inline">
                {post.username || (post.userId && post.userId.username)}{" "}
              </Typography>
            </Link>
            <Typography variant="body2" display="inline">
              • {new Date(post.createdAt).toDateString()}
            </Typography>
          </>
        }
        subheader={
          <Typography variant="subtitle1" color="textPrimary" component="p">
            {post.text}
          </Typography>
        }
        // subheader={new Date(post.createdAt).toDateString()}
      />
      {/* <CardContent
        style={{ paddingTop: 6, paddingBottom: 6 }}
        onClick={() => history.push(`/post/${post._id}`)}
      >
        <Typography variant="subtitle1" color="textPrimary" component="p">
          {post.text}
        </Typography>
      </CardContent> */}

      <PostMediaCarousel galleries={post.media} postId={post._id} />

      <CardActions disableSpacing style={{ padding: 0, paddingLeft: 16 }}>
        {!owner
          ? renderOtherUserControls(isPostLiked, post.isPostFavourited)
          : renderOwnerControls()}
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      {/* comment section */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent style={{ padding: 0 }}>
          {type && gender && commentsObj ? (
            <Grid container>
              <Grid item xs={12} flexGrow={1} className={classes.section3}>
                {(!post.allowOpenComments && !post.allowHiddenComments) ||
                (!owner && !isCommentAllowed())
                  ? null
                  : ((type === "Open" && openCommentAllowed()) ||
                      (type === "Hidden" && hiddenCommentAllowed())) && (
                      <>
                        {replyParent && replyParent._id && (
                          <Paper
                            elevation={6}
                            style={{
                              backgroundColor: "rgb(0, 0, 0, 0.1)",
                              padding: 6,
                            }}
                            square
                          >
                            <Grid container wrap="nowrap" spacing={1}>
                              <Grid item>
                                {replyParent.user_id.profilepic ? (
                                  <Avatar
                                    style={{
                                      width: 32,
                                      height: 32,
                                    }}
                                    alt="owner"
                                    src={
                                      process.env.REACT_APP_BUCKET_NAME +
                                      replyParent.user_id.profilepic
                                    }
                                    onClick={(e) =>
                                      openAvatarModal(
                                        process.env.REACT_APP_BUCKET_NAME +
                                          replyParent.user_id.profilepic
                                      )
                                    }
                                  />
                                ) : (
                                  <Avatar
                                    style={{
                                      width: 32,
                                      height: 32,
                                    }}
                                    alt="owner"
                                    onClick={(e) => openAvatarModal()}
                                  >
                                    <PersonIcon />
                                  </Avatar>
                                )}
                              </Grid>
                              <Grid
                                justifyContent="left"
                                item
                                className={classes.commentBody}
                              >
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    marginRight: "12px",
                                    display: "inline-block",
                                  }}
                                >
                                  <Link
                                    to={`/user_profile/${replyParent.user_id._id}`}
                                  >
                                    {replyParent.user_id.username}
                                  </Link>
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                  style={{
                                    display: "inline-block",
                                    float: "right",
                                  }}
                                >
                                  {moment(replyParent.createdAt).format(
                                    "DD-MM-YYYY"
                                  )}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  style={{ overflowWrap: "anywhere" }}
                                >
                                  {handleGetText(replyParent.comment)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Paper>
                        )}

                        <Paper
                          component="form"
                          square
                          style={{ display: "flex", padding: 6 }}
                        >
                          <TextField
                            error={err}
                            size="small"
                            fullWidth
                            autoFocus
                            inputRef={replyRef}
                            onChange={(e) => {
                              setNewComment(e.target.value);
                              if (e.target.value.length > 280) setErr(true);
                              else setErr(false);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                handlePost();
                              }
                            }}
                            helperText={
                              err
                                ? newComment === ""
                                  ? "Can not be empty"
                                  : "Comments can only be between 1 to 280 charachters."
                                : ""
                            }
                            multiline
                            rows={1}
                            id="standard-basic"
                            variant={
                              err || replyParent._id !== ""
                                ? "outlined"
                                : "standard"
                            }
                            placeholder={
                              replyParent._id
                                ? hiddenReplying
                                  ? `Replying in Hidden to ${replyParent.user_id.username}`
                                  : `Replying to ${replyParent.user_id.username}`
                                : "Add Comment"
                            }
                            inputProps={{ style: { padding: 0 } }}
                            style={{ flex: 1, marginRight: 6 }}
                            defaultValue={newComment}
                            value={newComment}
                          />
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={handlePost}
                            style={{ textTransform: "none" }}
                          >
                            {replyParent._id ? "Post Reply" : "Post Comment"}
                          </Button>
                          {replyParent._id && (
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={() => {
                                setReplyParent({});
                                setErr(false);
                              }}
                              style={{ textTransform: "none" }}
                            >
                              Cancel
                            </Button>
                          )}
                        </Paper>
                      </>
                    )}
              </Grid>

              <Grid item xs={12} className={classes.section2}>
                <Paper elevation={0} className={classes.commentsPaper} square>
                  {/* FILTER */}
                  {post.allowOpenComments && post.allowHiddenComments
                    ? renderFilterBtns("Both")
                    : post.allowOpenComments && !post.allowHiddenComments
                    ? renderFilterBtns("Open")
                    : !post.allowOpenComments && post.allowHiddenComments
                    ? renderFilterBtns("Hidden")
                    : null}

                  {(!post.allowOpenComments && !post.allowHiddenComments) ||
                  (!owner && !isCommentAllowed()) ? (
                    <div>
                      <i>Comments Disabled by owner</i>
                    </div>
                  ) : (
                    commentsObj &&
                    commentsList &&
                    commentsList.length > 0 && (
                      <>
                        <TextField
                          label="Search"
                          value={key}
                          onChange={(e) => setKey(e.target.value)}
                          variant="outlined"
                          size="small"
                          style={{ margin: "6px" }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <InfiniteScroll
                          dataLength={commentsList && commentsList.length}
                          next={fetchMoreData}
                          // scrollableTarget="scrollableDiv"
                          hasMore={
                            Math.ceil(
                              (commentsList ? commentsList.length : 0) / limit
                            ) < totalPages
                          }
                          loader={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                minHeight: "100px",
                              }}
                            >
                              <CircularProgress />
                            </div>
                          }
                          height={284}
                          style={{
                            overflowX: "hidden",
                            paddingLeft: 6,
                          }}
                        >
                          {commentsList.map((comment) => {
                            return (
                              <>
                                <Grid>
                                  <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={comment._id === commentMenu}
                                    onClose={handleMenuClose}
                                  >
                                    {loggedInUser !== comment.user_id._id && (
                                      <MenuItem
                                        onClick={() => handleReply(comment)}
                                      >
                                        Reply
                                      </MenuItem>
                                    )}
                                    {owner &&
                                      loggedInUser !== comment.user_id._id &&
                                      comment.commentType === "Open" && (
                                        <MenuItem
                                          onClick={() =>
                                            handleReply(comment, "hiddenReply")
                                          }
                                        >
                                          Reply in Hidden
                                        </MenuItem>
                                      )}
                                    {(owner ||
                                      loggedInUser === comment.user_id._id) && (
                                      <MenuItem
                                        onClick={() =>
                                          handleDeleteComment(comment._id)
                                        }
                                      >
                                        Delete
                                      </MenuItem>
                                    )}
                                  </Menu>
                                  <Grid
                                    container
                                    wrap="nowrap"
                                    spacing={1}
                                    onMouseEnter={() => setHover(comment._id)}
                                    onMouseLeave={() => setHover(null)}
                                  >
                                    <Grid item>
                                      {comment.user_id.profilepic ? (
                                        <Avatar
                                          style={{
                                            width: 32,
                                            height: 32,
                                          }}
                                          alt="owner"
                                          src={
                                            process.env.REACT_APP_BUCKET_NAME +
                                            comment.user_id.profilepic
                                          }
                                          onClick={(e) =>
                                            openAvatarModal(
                                              process.env
                                                .REACT_APP_BUCKET_NAME +
                                                comment.user_id.profilepic
                                            )
                                          }
                                        />
                                      ) : (
                                        <Avatar
                                          style={{
                                            width: 32,
                                            height: 32,
                                          }}
                                          alt="owner"
                                          onClick={(e) => openAvatarModal()}
                                        >
                                          <PersonIcon />
                                        </Avatar>
                                      )}
                                    </Grid>
                                    <Grid
                                      justifyContent="left"
                                      item
                                      className={classes.commentBody}
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        style={{
                                          marginRight: "6px",
                                          display: "inline-block",
                                        }}
                                      >
                                        <Link
                                          to={`/user_profile/${comment.user_id._id}`}
                                        >
                                          {comment.user}
                                        </Link>
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        color="textSecondary"
                                        style={{
                                          display: "inline-block",
                                          float: "right",
                                        }}
                                      >
                                        {moment(comment.createdAt).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        style={{ overflowWrap: "anywhere" }}
                                      >
                                        {handleGetText(comment.comment)}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      justifyContent="right"
                                      style={{
                                        alignSelf: "center",
                                        minWidth: 24,
                                      }}
                                      onClick={(e) =>
                                        hover === comment._id &&
                                        handleMenuClick(e, comment._id)
                                      }
                                    >
                                      {hover === comment._id && (
                                        // ((type === "Open" &&
                                        //   openCommentAllowed()) ||
                                        //   (type === "Hidden" &&
                                        //     hiddenCommentAllowed())) &&
                                        <IconButton
                                          variant="text"
                                          color="inherit"
                                          disableFocusRipple
                                          disableRipple
                                          style={{
                                            padding: 0,
                                          }}
                                        >
                                          <MoreVertIcon fontSize="small" />
                                        </IconButton>
                                      )}
                                    </Grid>
                                  </Grid>
                                  {comment.noOfReplies > 0 &&
                                    renderReplies(
                                      comment._id,
                                      comment.noOfReplies
                                    )}
                                </Grid>
                              </>
                            );
                          })}
                        </InfiniteScroll>
                      </>
                    )
                  )}
                </Paper>
              </Grid>
            </Grid>
          ) : (
            <div>
              <Loader />
            </div>
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
};

function mapStateToProps(state) {
  return {
    commentsObj: state.postsReducer.commentsObj,
    repliesObj: state.postsReducer.repliesObj,
    userDetail: state.userDetailsReducer.userDetail,
    userSettings: state.userDetailsReducer.userSettings,
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      postComment,
      getCommentsByPostId,
      getRepliesByCommentId,
      clearComments,
      clearReplies,
      deleteComment,
      deletePost,
      // allowOpenComments,
      // allowHiddenComments,
      // changeWhoCanOpenComment,
      // changeWhoCanHiddenComment,
      openAvatarModal,
      openDialog,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(PostItem));
