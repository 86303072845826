import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import ShareableUserList from "./ShareableUserList";
import SharedUserList from "./SharedUserList";
import Paper from "@material-ui/core/Paper";
import Input from "@material-ui/core/Input";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch, useSelector } from "react-redux";
import {
  getSharedUserList,
  getShareableUserList,
  clearShareableUserList,
  clearSharedUserList,
} from "../../actions/applicantsDetailsActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { minHeight } from "@material-ui/system";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SharePopup(props) {
  const { currentIndex: galleryId } = props;

  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();

  const classes = useStyles();
  // const [open, setOpen] = React.useState(false);
  // const [fullWidth, setFullWidth] = React.useState(true);
  const [searchHeader, setSearchHeader] = React.useState("");
  // const descriptionElementRef = React.useRef(null);

  const shareableUserListLoading = useSelector(
    ({ applicantsReducer }) => applicantsReducer.shareableUserListLoading
  );

  const shareableUserList = useSelector(
    ({ applicantsReducer }) => applicantsReducer.shareableUserList
  );

  const sharedUserListLoading = useSelector(
    ({ applicantsReducer }) => applicantsReducer.sharedUserListLoading
  );

  const shareadUserList = useSelector(
    ({ applicantsReducer }) => applicantsReducer.shareadUserList
  );

  // useEffect(() => {
  //   if (open) {
  //     const { current: descriptionElement } = descriptionElementRef;
  //     if (descriptionElement !== null) {
  //       descriptionElement.focus();
  //     }
  //   }
  // }, [open]);

  // useEffect(() => {
  //   let params = {
  //     gallery_id: galleryId,
  //     page: 0,
  //     limit: 20,
  //     key: searchHeader,
  //   };
  //   dispatch(getShareableUserList(params));
  // }, []);

  useEffect(() => {
    if (searchHeader.length >= 3) {
      let params = {
        gallery_id: galleryId,
        page: 0,
        limit: 20,
        key: searchHeader,
      };
      dispatch(getShareableUserList(params));
    }
  }, [searchHeader]);

  useEffect(() => {
    !shareadUserList.length && dispatch(getSharedUserList(galleryId));
  }, [shareadUserList && shareadUserList.length]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function handleSearchHeader(e) {
    setSearchHeader(e.target.value);
  }

  //shareable list
  // let filteredShareableUserList = shareableUserList;
  // filteredShareableUserList = filteredShareableUserList.filter(
  //   user => user.whoCanSharePrivateGallery === "AnyOne"
  // );
  // if (searchHeader && value == 0 && shareableUserList.length > 0) {
  //   filteredShareableUserList = filteredShareableUserList.filter(user => {
  //     return user.username.toLowerCase().startsWith(searchHeader.trim().toLowerCase());
  //   });
  // }

  //shared list
  let filteredSharedUserList = shareadUserList;
  if (searchHeader && value == 1 && shareableUserList.length > 0) {
    filteredSharedUserList = shareadUserList.filter((user) => {
      return user.sharedWith.username
        .toLowerCase()
        .startsWith(searchHeader.trim().toLowerCase());
    });
  }
  const handleClose = () => {
    setSearchHeader("");
    dispatch(clearShareableUserList());
    dispatch(clearSharedUserList());
    props.close();
  };

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={() => handleClose()}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title">
          Private Gallery Share
        </DialogTitle>
        <div style={{ paddingLeft: 12, paddingRight: 12 }}>
          <Paper
            style={{
              display: "flex",
              padding: "4px",
              alignItems: "center",
              width: "100%",
            }}
            elevation={1}
          >
            <SearchIcon color="action" />
            <Input
              placeholder="Search"
              disableUnderline
              fullWidth
              value={searchHeader}
              inputProps={{
                "aria-label": "Search",
              }}
              onChange={handleSearchHeader}
            />
          </Paper>
        </div>

        <div style={{ paddingLeft: 12, paddingRight: 12 }}>
          <Tabs
            value={value}
            centered
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab
              label="Share"
              {...a11yProps(0)}
              style={{ textTransform: "none" }}
            />
            <Tab
              label={`Remove Share(${
                filteredSharedUserList && filteredSharedUserList.length
              })`}
              {...a11yProps(1)}
              style={{ textTransform: "none" }}
            />
          </Tabs>

          <TabPanel value={value} index={0}>
            {shareableUserListLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "350px",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <ShareableUserList
                close={() => handleClose()}
                galleryId={galleryId}
                searchText={searchHeader}
                shareableUserList={shareableUserList}
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {sharedUserListLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "350px",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <SharedUserList
                close={() => handleClose()}
                galleryId={galleryId}
                searchText={searchHeader}
                shareadUserList={filteredSharedUserList}
              />
            )}
          </TabPanel>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
