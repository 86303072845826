import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Paper,
  DialogContent,
  Grid,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Divider,
  Switch,
} from "@material-ui/core";
import { connect } from "react-redux";
import PlayCircleOutlineSharpIcon from "@material-ui/icons/PlayCircleOutlineSharp";
import { withRouter, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useStyles from "./styles";
import { constants } from "../../../helpers/constants";
import getURL from "../../../helpers/apis";
import FineUploaderTraditional from "fine-uploader-wrappers";
import Gallery from "react-fine-uploader";
import "react-fine-uploader/gallery/gallery.css";
import { openDialog } from "../../../actions/appActions";
import { getPost, updatePost } from "../../../actions/postActions";
import { getUserSettings } from "../../../actions/userDetailsAction";

import moment from "moment";

const PostForm = ({
  history,
  dispatch,
  userDetail,
  post,
  fetchingPost,
  userSettings,
  match,
}) => {
  const classes = useStyles();
  const [text, setText] = useState(post ? post.text : "");
  const [allowOpen, setAllowOpen] = useState(
    post ? post.allowOpenComments : null
  );
  const [allowHidden, setAllowHidden] = useState(
    post ? post.allowHiddenComments : null
  );
  const [whoOpen, setWhoOpen] = useState(post ? post.whoCanOpenComment : "");


  const [whoHidden, setWhoHidden] = useState(
    post ? post.whoCanHiddenComment : ""
  );
  const [freeDays, setFreeDays] = useState(
    moment().diff(moment(userDetail && userDetail.createdAt), "days")
  );
  const [authToken, setAuthToken] = useState(localStorage.getItem("jwt"));
  const [uploader, setUploader] = useState(
    new FineUploaderTraditional({
      options: {
        maxConnections: freeDays > 7 ? 2 : 6,
        validation: {
          allowedExtensions: [
            "jpeg",
            "jpg",
            "png",
            "mp4",
            "mpeg",
            "webm",
            "mkv",
            "flv",
            "ogg",
            "ogv",
            "m4v",
            "m4p",
            "3gp",
          ],
          itemLimit: freeDays > 7 ? 2 : 6,
          sizeLimit: 10485760,
          stopOnFirstInvalidFile: false,
        },
        // debug: true,
        request: {
          customHeaders: {
            Authorization: authToken,
          },
          inputName: "media",
        },
        session: {
          customHeaders: {
            Authorization: authToken,
          },
          endpoint: getURL("GET_POST_GALLERIES_FOR_UPDATE"),
          params: { post_id: match.params.post_id },
          refreshOnReset: false,
        },
        deleteFile: {
          enabled: true,
          customHeaders: {
            Authorization: authToken,
          },
          endpoint: getURL("GALLERY_REMOVE"),
        },
        // retry: {
        //   enableAuto: true,
        // },
      },
    })
  );
  const rootUrl = getURL("UPLOADS");

  useEffect(() => {
    dispatch(getUserSettings());
  }, []);

  useEffect(() => {
    dispatch(getPost(match.params.post_id));
  }, [match.params.post_id]);

  useEffect(() => {
    if (userDetail) {
      setFreeDays(moment().diff(moment(userDetail.createdAt), "days"));
    }
  }, [userDetail]);

  useEffect(() => {
    if (post) {
      if (post.userId !== userDetail._id) {
        return <Redirect to={"/posts"} />;
      } else {
        uploader.on("submit", (id, name) => {
          let file = uploader.methods.getFile(id);
          if (file.type.substr(0, 5) === "image") {
            uploader.methods.setEndpoint(
              getURL("UPDATE_POST_WITH_GALLERIES") +
                "/" +
                post._id +
                "/" +
                "photos",
              id
            );
          } else if (file.type.substr(0, 5) === "video") {
            uploader.methods.setEndpoint(
              getURL("UPDATE_POST_WITH_GALLERIES") +
                "/" +
                post._id +
                "/" +
                "videos",
              id
            );
          }
        });
        uploader.on("validate", ({ name, size }) => {
          if (
            ![
              ".jpeg",
              ".jpg",
              ".png",
              ".mp4",
              ".mpeg",
              ".webm",
              ".mkv",
              ".flv",
              ".ogg",
              ".ogv",
              ".m4v",
              ".m4p",
              ".3gp",
            ].includes(name.substr(name.lastIndexOf(".")).toLowerCase()) ||
            size >= 10485760
          ) {
            dispatch(
              openDialog(
                `Some files were not uploaded. Please upload images or videos under 10MB in size. File formats supported are -
        .jpeg, .jpg, .png, .mp4, .mpeg, .webm,  .mkv, .flv, .ogg, .ogv, .m4v, .m4p, .3gp`
              )
            );
          }
        });
        uploader.on("validateBatch", (array) => {
          if (freeDays > 7 && array.length > 2) {
            dispatch(
              openDialog(`Please subscribe to upload more than 2 files.`)
            );
          }
          if (!(freeDays > 7) && array.length > 6) {
            dispatch(openDialog(`Upload limit is 6.`));
          }
        });
        uploader.on("cancel", (id) => {
          uploader.methods.deleteFile(id);
        });
        uploader.on("complete", (id, name, res) => {
          if (res.error) {
            dispatch(openDialog(res.data || "Something went wrong."));
          }
        });
        setText(post.text);
        setAllowOpen(post.allowOpenComments);
        setAllowHidden(post.allowHiddenComments);
        setWhoOpen(post.whoCanOpenComment);
        setWhoHidden(post.whoCanHiddenComment);
      }
    }
  }, [post]);

  useEffect(() => {
    setAuthToken(localStorage.getItem("jwt"));
  }, []);

  const handleSave = async () => {
    const params = {
      postId: post._id,
      text,
      allowOpenComments: allowOpen,
      allowHiddenComments: allowHidden,
      whoCanOpenComment: allowOpen ? whoOpen : "NoOne",
      whoCanHiddenComment: allowHidden ? whoHidden : "NoOne",
    };
    await dispatch(updatePost(params, history));
  };

  useEffect(() => {
    !allowOpen ? setWhoOpen("NoOne") : setWhoOpen(post?.whoCanOpenComment ?? "AnyOne");
  }, [allowOpen]);
  useEffect(() => {
    !allowHidden ? setWhoHidden("NoOne") : setWhoHidden(post?.whoCanHiddenComment ?? "AnyOne");
  }, [allowHidden]);

  return (
    <Paper style={{ padding: 10, width: "100%", marginTop: 20 }} elevation={1}>
      <Grid container justify="space-between">
        <Grid itemalign="left">
          <Typography variant="h5">Edit Post</Typography>
        </Grid>
        <Grid item>
          <Grid container justify="space-evenly" spacing={0}>
            <Grid item align="right">
              <Button
                variant="contained"
                className={classes.submit}
                onClick={() => handleSave()}
                disabled={text.length < 1}
                style={{ textTransform: "none" }}
                color="primary"
                size="small"
              >
                Save
              </Button>
            </Grid>
            <Grid item align="right">
              <Button
                color="secondary"
                variant="contained"
                size="small"
                onClick={(e) => {
                  history.goBack();
                }}
                style={{ margin: "8px", textTransform: "none" }}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 6 }}>
        <Divider variant="fullWidth" />
      </Grid>

      <Grid container justify="space-evenly" spacing={0}>
        <form
          className={classes.form}
          style={{ width: "100%" }}
          className="inherit-bg-color"
        >
          <Grid container justify="space-around" spacing={1}>
            <Grid item xs={6}>
              <Grid container justify="space-around" spacing={0}>
                <Grid item xs={11} align="center">
                  <Typography variant="h6">Post Caption</Typography>
                </Grid>
                <Grid item xs={11}>
                  <TextField
                    variant="outlined"
                    required
                    autoFocus
                    multiline
                    rows={8}
                    fullWidth
                    placeholder="Create a post"
                    value={text}
                    onChange={(e) =>
                      e.target.value.length <= 280 && setText(e.target.value)
                    }
                  />
                </Grid>
              </Grid>
              </Grid>
              {/* <Grid item xs={12} style={{ marginTop: 12 }}>
                <Divider variant="fullWidth" />
              </Grid> */}
              <Grid item xs={6}>
                <Grid
                  container
                  justify="space-around"
                  spacing={0}
                  style={{ marginTop: 12 }}
                >
                  <Grid item xs={11}>
                    <Grid item xs={12} align="center">
                      <Typography variant="h6">
                        Choose Comments Privacy Settings
                      </Typography>
                    </Grid>
                    <DialogContent>
                      <form className={classes.form} noValidate>
                        <FormControlLabel
                          className={classes.formControlLabel}
                          control={
                            <Switch
                              checked={allowOpen}
                              size="large"
                              onChange={(e) => {
                                setAllowOpen(!allowOpen);
                              }}
                            />
                          }
                          labelPlacement="start"
                          label="Open Comments"
                        />

                        {allowOpen && (
                          <FormControlLabel
                            className={classes.formControlLabel}
                            control={
                              <Select
                                value={whoOpen}
                                autoWidth
                                onChange={(e) => setWhoOpen(e.target.value)}
                                inputProps={{
                                  name: "who-open",
                                  id: "who-open",
                                }}
                              >
                                <MenuItem value={"NoOne"}>No One</MenuItem>
                                <MenuItem value={"AnyOne"}>Any One</MenuItem>
                                <MenuItem value={"OpenFriends"}>
                                  Open Friends
                                </MenuItem>
                                {[
                                  {
                                    value: "HiddenFriends",
                                    label: "Hidden Friends",
                                  },
                                  {
                                    label: "Hidden, Open Friends",
                                    value: "HiddenOpenFriends",
                                  },
                                ].map((opt) => (
                                  <MenuItem
                                    hidden={
                                      !(
                                        userSettings &&
                                        userSettings.showHiddenFriendsOption
                                      )
                                    }
                                    key={opt.value}
                                    value={opt.value}
                                  >
                                    {opt.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            }
                            labelPlacement="start"
                            label="Who Can Open Comment"
                          />
                        )}
                        <FormControlLabel
                          className={classes.formControlLabel}
                          control={
                            <Switch
                              checked={allowHidden}
                              size="large"
                              onChange={(e) => {
                                setAllowHidden(!allowHidden);
                              }}
                            />
                          }
                          labelPlacement="start"
                          label="Hidden Comments"
                        />
                        {allowHidden && (
                          <FormControlLabel
                            className={classes.formControlLabel}
                            control={
                              <Select
                                value={whoHidden}
                                autoWidth
                                onChange={(e) => setWhoHidden(e.target.value)}
                                inputProps={{
                                  name: "who-hidden",
                                  id: "who-hidden",
                                }}
                              >
                                <MenuItem value={"NoOne"}>No One</MenuItem>
                                <MenuItem value={"AnyOne"}>Any One</MenuItem>
                                <MenuItem value={"OpenFriends"}>
                                  Open Friends
                                </MenuItem>
                                <MenuItem value={"HiddenFriends"}>
                                  Hidden Friends
                                </MenuItem>
                                <MenuItem value={"HiddenOpenFriends"}>
                                  Hidden, Open Friends
                                </MenuItem>
                              </Select>
                            }
                            labelPlacement="start"
                            label="Who Can Hidden Comment"
                          />
                        )}
                      </form>
                    </DialogContent>
                  </Grid>
                </Grid>
                </Grid>
              <Grid item xs={12} style={{ marginTop: 12 }}>
                <Divider variant="fullWidth" />
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12} align="center">
                  <Typography variant="h6">Post Media</Typography>
                </Grid>
                <DialogContent>
                  <Gallery style={{ width: "100%" }} uploader={uploader} />
                </DialogContent>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 12 }}>
                <Divider variant="fullWidth" />
              </Grid>

              {/* <Grid container justify="space-evenly" spacing={0}>
                <Grid item xs={12} align="right">
                  <Button
                    variant="contained"
                    className={classes.submit}
                    onClick={() => handleSave()}
                    disabled={text.length < 1}
                    style={{ textTransform: "none" }}
                    color="primary"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid> */}
            </Grid>
        </form>
      </Grid>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetail: state.userDetailsReducer.userDetail,
    post: state.postsReducer.post,
    fetchingPost: state.postsReducer.fetchingPost,
    userSettings: state.userDetailsReducer.userSettings,
  };
};

export default connect(mapStateToProps)(withRouter(PostForm));
