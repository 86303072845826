import React, { Fragment, useState, useEffect } from "react";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  DialogActions,
  DialogContentText,
  Button,
  Switch,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ReactPlayer from "react-player";
import withRouter from "react-router-dom/es/withRouter";
import { Paper, Typography, Divider, Tabs, Tab } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import { useDispatch, useSelector } from "react-redux";
import "react-fine-uploader/gallery/gallery.css";
import { connect } from "react-redux";
import { openDialog } from "../../actions/appActions";
import { constants } from "../../helpers/constants";

import FineUploaderTraditional from "fine-uploader-wrappers";
import Gallery from "react-fine-uploader";

import "react-fine-uploader/gallery/gallery.css";
import getURL from "../../helpers/apis";

const UploadComponent = ({
  // open,
  // handleClose,
  companyId,
}) => {
  const dispatch = useDispatch();

  const [authToken, setAuthToken] = useState(localStorage.getItem("jwt"));
  const [uploader, setUploader] = useState(
    new FineUploaderTraditional({
      options: {
        maxConnections: 1,
        validation: {
          allowedExtensions: [
            "jpeg",
            "jpg",
            "png",
            // "mp4",
            // "mpeg",
            // "webm",
            // "mkv",
            // "flv",
            // "ogg",
            // "ogv",
            // "m4v",
            // "m4p",
            // "3gp",
          ],
          itemLimit: 1,
          sizeLimit: 10485760,
          stopOnFirstInvalidFile: false,
        },
        debug: true,
        request: {
          customHeaders: {
            Authorization: authToken,
          },
          inputName: "media",
          params: { free: true },
        },
        session: {
          customHeaders: {
            Authorization: authToken,
          },
          endpoint: getURL("GET_AD_GALLERIES_FOR_UPDATE"),
          params: { companyId, free: true },
          refreshOnReset: false,
        },
        deleteFile: {
          enabled: true,
          forceConfirm: true,
          customHeaders: {
            Authorization: authToken,
          },
          endpoint: getURL("ADGALLERY_REMOVE"),
          params: { free: true },
        },
        retry: {
          enableAuto: true,
        },
      },
    })
  );

  // useEffect(() => {
  //   setAuthToken(localStorage.getItem("jwt"));
  // }, []);

  useEffect(() => {
    if (companyId) {
      uploader.on("submit", (id, name) => {
        let file = uploader.methods.getFile(id);
        if (file.type.substr(0, 5) === "image") {
          uploader.methods.setEndpoint(
            getURL("POST_ADGALLERIES") + "photos",
            id
          );
        } else if (file.type.substr(0, 5) === "video") {
          uploader.methods.setEndpoint(
            getURL("POST_ADGALLERIES") + "videos",
            id
          );
        }
      });
      uploader.on("validate", ({ name, size }) => {
        if (
          ![
            ".jpeg",
            ".jpg",
            ".png",
            // ".mp4",
            // ".mpeg",
            // ".webm",
            // ".mkv",
            // ".flv",
            // ".ogg",
            // ".ogv",
            // ".m4v",
            // ".m4p",
            // ".3gp",
          ].includes(name.substr(name.lastIndexOf(".")).toLowerCase()) ||
          size >= 10485760
        ) {
          dispatch(
            openDialog(
              `Some files were not uploaded. Please upload images or videos under 10MB in size. File formats supported are -
        .jpeg, .jpg, .png`
            )
          );
        }
      });
      uploader.on("validateBatch", (array) => {
        if (array.length > 1) {
          dispatch(openDialog(`Upload limit is 1.`));
        }
      });
      uploader.on("cancel", (id) => {
        uploader.methods.deleteFile(id);
      });
      uploader.on("allComplete", (successArr, failedArr) => {
        if (successArr.length > 0) {
          dispatch(
            openDialog(
              "Ad request submitted. Keep checking here for status approval."
            )
          );
        }
      });
    }
  }, [uploader, companyId]);

  return (
    <Fragment style={{ width: "100%" }}>
      {/* <Dialog fullWidth maxWidth="sm" open={open} onClose={() => handleClose()}> */}
      <DialogTitle id="comment-settings">Upload the thumbnail image to be displayed. If the platform is Youtube,  thumbnail image not required to upload, because Youtube videos already have thumbnail image, the same thumbnail image will be displayed here.</DialogTitle>
      <Gallery uploader={uploader} />
      {/* <DialogActions>
          <Button
            onClick={() => handleClose()}
            color="primary"
            style={{ textTransform: "none" }}
          >
            Done
          </Button>
        </DialogActions> */}
      {/* </Dialog> */}
    </Fragment>
  );
};

export default withRouter(UploadComponent);
