import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import UsersList from "./UsersList";
import AddedUserList from "./AddedUserList";
import SearchField from "./SearchField";
import Paper from "@material-ui/core/Paper";
import Input from "@material-ui/core/Input";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch, useSelector } from "react-redux";
import {
  getExceptionUserList,
  getAddableUsersByKeyword,
} from "../../actions/applicantsDetailsActions";
import { getUsersByKeyword } from "../../actions/userDetailsAction";
import CircularProgress from "@material-ui/core/CircularProgress";
import { minHeight } from "@material-ui/system";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ExceptionListPopup = (props) => {
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();

  const classes = useStyles();
  const [searchHeader, setSearchHeader] = useState("");
  const [addedUserList, setAddedUserList] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (value === 0 && searchHeader.length > 0) {
      let listType = props.setting === "whoCanChat" ? "chat" : "privateShare";
      const params = {
        key: searchHeader,
        page: 0,
        limit: 10,
        listType: listType,
      };
      getAddableUsersByKeyword(params).then((res) => {
        if (res) {
          setUsersList(res.data);
        }
      });
    } else if (value === 1) {
      let listType = props.setting === "whoCanChat" ? "chat" : "privateShare";
      getExceptionUserList({ listType }).then((res) => {
        if (res) setAddedUserList(res.data);
        else setAddedUserList([]);
      });
    }
  }, [value, refresh]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleGetList = (keyword) => {
    if (keyword.length > 0) {
      setSearchHeader(keyword);
      let listType = props.setting === "whoCanChat" ? "chat" : "privateShare";
      const params = { key: keyword, page: 0, limit: 10, listType: listType };
      getAddableUsersByKeyword(params).then((res) => {
        if (res) {
          setUsersList(res.data);
        }
      });
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={() => props.close()}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title">
          {props.setting === "whoCanChat"
            ? "Who Can Chat"
            : "Who Can Share Private Gallery"}
        </DialogTitle>
        <div style={{ paddingLeft: 12, paddingRight: 12 }}>
          <Paper
            style={{
              display: "flex",
              padding: "4px",
              alignItems: "center",
              width: "100%",
            }}
            elevation={1}
          >
            <SearchField
              handleGetKeyword={(keyword) => handleGetList(keyword)}
            />
          </Paper>
        </div>
        <div style={{ paddingLeft: 12, paddingRight: 12 }}>
          <Tabs
            value={value}
            centered
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Add User" {...a11yProps(0)} />
            <Tab
              label={`Remove User(${
                addedUserList ? addedUserList.length : ""
              })`}
              {...a11yProps(1)}
            />
          </Tabs>

          <TabPanel value={value} index={0}>
            <UsersList
              close={() => props.close()}
              usersList={usersList && usersList}
              setting={props.setting}
              handleRefresh={() => setRefresh(!refresh)}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            {!addedUserList ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "350px",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <AddedUserList
                close={() => props.close()}
                addedUserList={addedUserList && addedUserList}
                setting={props.setting}
                handleRefresh={() => setRefresh(!refresh)}
              />
            )}
          </TabPanel>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default ExceptionListPopup;
