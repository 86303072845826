import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import LocatinIcon from "@material-ui/icons/LocationOn";
import Pagination from "@material-ui/lab/Pagination";
import {
  getPosByUser,
  getPositionDetailsById,
} from "../../../src/actions/getPosActions";
import { Link, withRouter, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { func } from "prop-types";
import { formatDate } from "../../helpers/globalFunctions";
import { constants } from "../../helpers/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    marginTop: "15px",
    backgroundColor: theme.palette.background.paper,
  },
  positionTabHead: {
    display: "flex",
    textDecoration: "none",
  },
  appBar: {
    boxShadow: "none",
    margin: "16px 0px 0px",
  },
  tabs: {
    textColor: "white",
  },
  flexJustifyCenter: {
    display: "flex",
    justifyContent: "center",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  roleTitle: {
    margin: "8px 0px 0px",
  },
  roleTitleTypography: {
    fontWeight: "400",
  },
  applicantHead: {
    padding: "16px 16px 0px",
  },
  roleTypography: {
    margin: "4px 4px 0px",
  },
  breadcrumbSeparater: {
    margin: "0px 6px",
  },
  textFieldTypography: {
    margin: "8px 32px 4px 0px",
    fontWeight: 400,
  },
  arrowBackButton: {
    marginRight: "8px",
  },
  separator: {
    margin: "0px 24px",
  },
  descriptionTypography: {
    border: "1px solid #ccc",
    borderRadius: "4px",
    padding: "8px 4px",
  },
  addressParent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "4px",
  },
  address: {
    border: "1px solid #ccc",
    borderRadius: "4px",
    padding: "8px 8px",
  },
  divider: {
    marginTop: "12px",
  },
  allDetailsFlexCol: {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
  },
  rowPositionDetails: {
    display: "flex",
  },
  labelParent: {
    minWidth: "10%",
  },
  addressMargin: {
    margin: "0px 4px ",
  },
  checkBoxTypography: {
    marginTop: "8px",
  },
  pagination: {
    margin: "8px 0px",
    display: "flex",
    justifyContent: "center",
  },
  editButton: {
    margin: "16px 0px",
  },
}));

function ViewPositionDetails(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isRedirect, setIsRedirect] = useState(false);
  const [redirectPath, setRedirectPath] = useState("");
  const [location, setLocation] = useState(props.history.location.pathname);

  const positionList = useSelector(
    ({ PosTableReducer }) => PosTableReducer.Pos
  );
  const userDetail = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.userDetail
  );

  const queryParamArrayLength = props.history.location.pathname.split("/")
    .length;
  const positionId = props.history.location.pathname.split("/")[
    queryParamArrayLength - 1
  ];

  const currentPosition = useSelector(
    ({ PosTableReducer }) => PosTableReducer.positionDetailsById
  );

  const positionIdArray = positionList.map((pos) => pos._id);

  const currentPositionIndex = positionIdArray.indexOf(positionId);

  const [page, setPage] = React.useState(1);

  useEffect(() => {
    setPage(currentPositionIndex + 1);
  }, [currentPositionIndex]);

  const handleChangePage = (event, value) => {
    setPage(value);
    props.history.push(`/positions/view/${positionIdArray[value - 1]}`);
  };

  useEffect(() => {
    positionList && dispatch(getPosByUser(null, userDetail._id));
  }, [positionList.length]);

  useEffect(() => {
    dispatch(getPositionDetailsById(positionId));
  }, [positionId]);

  useEffect(() => {
    setLocation(props.history.location.pathname);
  }, [redirectPath]);

  function redirect(path) {
    setIsRedirect(true);
    setRedirectPath(path);
  }
  function handleRedirectToAllPositions() {
    props.history.push(`/all-positions`);
  }
  function handleRedirectToEditPositions() {
    props.history.push(`/positions/edit/${positionId}`);
  }

  if (isRedirect) {
    setIsRedirect(false);
    return props.history.push(redirectPath);
    // return <Redirect to={redirectPath} />;
  }
  return (
    <div className={classes.root}>
      <div className={classes.applicantHead}>
        <div className={classes.flexCenter}>
          <Typography
            onClick={handleRedirectToAllPositions}
            role="button"
            to="/all-positions/"
            className={classes.positionTabHead}
            style={{
              display: "flex",
              textDecoration: "none",
              color: constants.PRIMARY_COLOR,
            }}
          >
            All Positions
          </Typography>
          <Typography
            style={{ color: constants.PRIMARY_COLOR }}
            variant="subtitle1"
            className={classes.breadcrumbSeparater}
          >
            /
          </Typography>

          <Typography
            style={{ color: constants.PRIMARY_COLOR }}
            variant="subtitle1"
          >
            {currentPosition && currentPosition.role}
          </Typography>
        </div>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          style={{ float: "right", textTransform: "none" }}
          onClick={() => history.push("/all-positions")}
        >
          Back
        </Button>
        <div className={classes.roleTitle}>
          <Typography
            color="inherit"
            variant="h5"
            className={classes.roleTitleTypography}
          >
            {currentPosition && currentPosition.role}
          </Typography>
        </div>
        <Divider className={classes.divider} />
      </div>
      <div style={{ margin: 24 }}>
        <Grid container spacing={6} justify="space-evenly">
          <Grid item xs={6} spacing={1} style={{ paddingBottom: 0 }}>
            <Grid container spacing={1}>
            <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Type
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {currentPosition && currentPosition.positionType}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Title
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {(currentPosition && currentPosition.title) || ""}
                </Typography>
              </Grid>
             
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Role
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {currentPosition && currentPosition.role}
                </Typography>
              </Grid>
              {currentPosition &&
                currentPosition.role &&
                currentPosition.role.toLowerCase() === "actor" && (
                  <>
                    <Grid item xs={5}>
                      <Typography
                        style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                      >
                        Role Name
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography style={{ margin: 10 }}>
                        {currentPosition && currentPosition.otherrolename}
                      </Typography>
                    </Grid>
                  </>
                )}
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Gender
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {currentPosition && currentPosition.gender}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Compensation
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {currentPosition && currentPosition.compensation}
                </Typography>
              </Grid>
              {currentPosition &&
                currentPosition.compensation &&
                currentPosition.compensation.toLowerCase() === "paid" && (
                  <>
                    <Grid item xs={5}>
                      <Typography
                        style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                      >
                        Amount
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography style={{ margin: 10 }}>
                        {currentPosition && currentPosition.amount}
                      </Typography>
                    </Grid>
                  </>
                )}
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Age
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {currentPosition && currentPosition.ageMin} to{" "}
                  {currentPosition && currentPosition.ageMax}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  How Many?
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {currentPosition && currentPosition.noOfPositions}
                </Typography>
              </Grid>
             
            </Grid>
          </Grid>
          <Grid item xs={6} spacing={1} style={{ paddingBottom: 0 }}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  House no
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {(currentPosition && currentPosition.houseno) || ""}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Street
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {(currentPosition && currentPosition.street) || ""}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  City
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {(currentPosition && currentPosition.city) || ""}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  State
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {(currentPosition && currentPosition.state) || ""}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Country
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {(currentPosition && currentPosition.country) || ""}
                </Typography>
              </Grid>
              {currentPosition &&
                currentPosition.country &&
                currentPosition.country.toLowerCase() === "india" && (
                  <>
                    <Grid item xs={5}>
                      <Typography
                        style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                      >
                        Industry
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography style={{ margin: 10 }}>
                        {(currentPosition && currentPosition.industry) || ""}
                      </Typography>
                    </Grid>
                  </>
                )}
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Pincode
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {(currentPosition && currentPosition.pincode) || ""}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Posted Date
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {currentPosition &&
                    new Date(currentPosition.createdAt).toDateString()}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Last Date
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {currentPosition &&
                    new Date(currentPosition.lastDate).toDateString()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {currentPosition &&
            !(
              !currentPosition.role ||
              currentPosition.role.toLowerCase() === "" ||
              currentPosition.role.toLowerCase() === "actor" ||
              currentPosition.role.toLowerCase() === "model" ||
              currentPosition.role.toLowerCase() === "audience" || 
              currentPosition.role.toLowerCase() === "people" 
            ) && (
              <>
                <Grid item xs={6}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Main role in user other roles can also apply?
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    className={classes.checkBoxTypography}
                    control={
                      <Checkbox
                        checked={
                          currentPosition && currentPosition.otherRolesCanApply
                        }
                        value="otherRolesCanApply"
                        color="secondary"
                        disabled={
                          !currentPosition.role ||
                          currentPosition.role.toLowerCase() === "" ||
                          currentPosition.role.toLowerCase() === "actor" ||
                          currentPosition.role.toLowerCase() === "model"
                        }
                      />
                    }
                    // label="Main role in user other roles can also apply?"
                  />
                </Grid>
              </>
            )}
          <Grid item xs={2} style={{ paddingTop: 0 }}>
            <Typography style={{ margin: 10, color: constants.PRIMARY_COLOR }}>
              Description
            </Typography>
          </Grid>
          <Grid item xs={10} style={{ paddingTop: 0 }}>
            <TextField
              id="description"
              name="description"
              margin="dense"
              type="text"
              multiline
              fullWidth
              rows={6}
              variant="outlined"
              disabled
              value={currentPosition && currentPosition.description}
            />
          </Grid>
        </Grid>
        <div className={classes.flexJustifyCenter}>
          <Button
            variant="contained"
            className={classes.editButton}
            onClick={handleRedirectToEditPositions}
            style={{
              textTransform: "none",
              color: constants.PRIMARY_COLOR,
            }}
          >
            Update
          </Button>
        </div>
        <div className={classes.pagination}>
          <Pagination
            count={positionIdArray.length}
            page={page}
            onChange={handleChangePage}
            color="secondary"
          />
        </div>
      </div>
    </div>
  );
}

export default withRouter(ViewPositionDetails);

//
// import React, { useEffect, useState } from "react";

// import { makeStyles } from "@material-ui/core/styles";
// import Typography from "@material-ui/core/Typography";
// import Button from "@material-ui/core/Button";
// import Divider from "@material-ui/core/Divider";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import LocatinIcon from "@material-ui/icons/LocationOn";
// import Pagination from "@material-ui/lab/Pagination";
// import {
//   getPosByUser,
//   getPositionDetailsById
// } from "../../../src/actions/getPosActions";
// import { Link, withRouter, Redirect } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";

// const useStyles = makeStyles(theme => ({
//   root: {
//     flexGrow: 1,
//     width: "100%",
//     marginTop: "35px",
//     backgroundColor: theme.palette.background.paper
//   },
//   positionTabHead: {
//     display: "flex",
//     textDecoration: "none"
//   },
//   appBar: {
//     boxShadow: "none",
//     margin: "16px 0px 0px"
//   },
//   tabs: {
//     textColor: "white"
//   },
//   flexJustifyCenter: {
//     display: "flex",
//     justifyContent: "center"
//   },
//   flexCenter: {
//     display: "flex",
//     alignItems: "center"
//   },
//   roleTitle: {
//     margin: "16px 0px 0px"
//   },
//   roleTitleTypography: {
//     fontWeight: "400"
//   },
//   applicantHead: {
//     padding: "16px 16px 0px"
//   },
//   roleTypography: {
//     margin: "16px 4px 0px"
//   },
//   breadcrumbSeparater: {
//     margin: "0px 6px"
//   },
//   textFieldTypography: {
//     margin: "16px 4px 0px",
//     fontWeight: 400
//   },
//   arrowBackButton: {
//     marginRight: "8px"
//   },
//   separator: {
//     margin: "0px 24px"
//   },
//   descriptionTypography: {
//     border: "1px solid #ccc",
//     borderRadius: "4px",
//     padding: "16px 4px"
//   },
//   addressParent: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-between"
//   },
//   address: {
//     border: "1px solid #ccc",
//     borderRadius: "4px",
//     padding: "8px 0px"
//   },
//   divider: {
//     marginTop: "12px"
//   },
//   allDetailsFlexCol: {
//     display: "flex",
//     flexDirection: "column",
//     padding: "16px"
//   },
//   rowPositionDetails: {
//     display: "flex"
//   },
//   labelParent: {
//     minWidth: "15%"
//   },
//   addressMargin: {
//     margin: "0px 4px "
//   },
//   checkBoxTypography: {
//     marginTop: "8px"
//   },
//   pagination: {
//     margin: "8px 0px",
//     display: "flex",
//     justifyContent: "center"
//   },
//   editButton: {
//     margin: "16px 0px"
//   }
// }));

// function ViewPositionDetails(props) {
//   const classes = useStyles();
//   const dispatch = useDispatch();

//   const [isRedirect, setIsRedirect] = useState(false);
//   const [redirectPath, setRedirectPath] = useState(false);

//   const positionList = useSelector(
//     ({ PosTableReducer }) => PosTableReducer.Pos
//   );
//   const userDetail = useSelector(
//     ({ userDetailsReducer }) => userDetailsReducer.userDetail
//   );

//   const queryParamArrayLength = props.history.location.pathname.split("/").length;
//   const positionId = props.history.location.pathname.split("/")[queryParamArrayLength - 1];

//   const currentPosition = useSelector(
//     ({ PosTableReducer }) => PosTableReducer.positionDetailsById
//   );

//   const positionIdArray = positionList.map(pos => pos._id);

//   const currentPositionIndex = positionIdArray.indexOf(positionId);

//   const [page, setPage] = React.useState(1);

//   useEffect(() => {
//     setPage(currentPositionIndex + 1);
//   }, [currentPositionIndex]);

//   const handleChangePage = (event, value) => {
//     setPage(value);
//     props.history.push(`/positions/view/${positionIdArray[value - 1]}`);
//   };

//   useEffect(() => {
//     positionList && dispatch(getPosByUser(null, userDetail._id));
//   }, [positionList.length]);

//   useEffect(() => {
//     dispatch(getPositionDetailsById(positionId));
//   }, [positionId]);

//   function handleRedirectToEditPositions() {
//     props.history.push(`/positions/edit/${positionId}`);
//   }

//   function handleRedirectToAllPositions(path) {
//     setIsRedirect(true);
//     setRedirectPath(path);
//   }

//   useEffect(() => {
//     setRedirectPath(props.history.location.pathname);
//   }, [props.history.location.pathname]);
//   // componentDidUpdate(prevProps, prevState) {
//   //   if (prevState.location !== this.props.history.location.pathname) {
//   //     this.setState({ location: this.props.history.location.pathname });
//   //   }
//   // }

//   if (isRedirect) {
//     return <Redirect to={redirectPath} />;
//   }
//   return (
//     <div className={classes.root}>
//       <div className={classes.applicantHead}>
//         <div className={classes.flexCenter}>
//           <Typography
//             onClick={() => handleRedirectToAllPositions("/all-positions")}
//             // component={Link}
//             // role="button"
//             // to="/all-positions/"
//             style={{ color: constants.PRIMARY_COLOR }}
//           >
//             All Positions
//           </Typography>
//           <Typography
//             style={{ color: constants.PRIMARY_COLOR }}
//             variant="subtitle1"
//             className={classes.breadcrumbSeparater}
//           >
//             /
//           </Typography>

//           <Typography style={{ color: constants.PRIMARY_COLOR }} variant="subtitle1">
//             {currentPosition && currentPosition.role}
//           </Typography>
//         </div>

//         <div className={classes.roleTitle}>
//           <Typography
//             style={{ color: constants.PRIMARY_COLOR }}
//             variant="h5"
//             className={classes.roleTitleTypography}
//           >
//             {currentPosition && currentPosition.role}
//           </Typography>
//         </div>
//         <Divider className={classes.divider} />
//       </div>

//       <div className={classes.allDetailsFlexCol}>
//         <div className={classes.rowPositionDetails}>
//           <div className={classes.labelParent}>
//             <Typography
//               style={{ color: constants.PRIMARY_COLOR }}
//               variant="subtitle2"
//               className={classes.roleTypography}
//             >
//               type
//             </Typography>
//             <Typography
//               style={{ color: constants.PRIMARY_COLOR }}
//               variant="subtitle2"
//               className={classes.roleTypography}
//             >
//               Role
//             </Typography>
//             <Typography
//               style={{ color: constants.PRIMARY_COLOR }}
//               variant="subtitle2"
//               className={classes.roleTypography}
//             >
//               Role Name
//             </Typography>
//             <Typography
//               style={{ color: constants.PRIMARY_COLOR }}
//               variant="subtitle2"
//               className={classes.roleTypography}
//             >
//               Gender
//             </Typography>
//             <Typography
//               style={{ color: constants.PRIMARY_COLOR }}
//               variant="subtitle2"
//               className={classes.roleTypography}
//             >
//               Age
//             </Typography>
//             <Typography
//               style={{ color: constants.PRIMARY_COLOR }}
//               variant="subtitle2"
//               className={classes.roleTypography}
//             >
//               Compensation
//             </Typography>
//             <Typography
//               style={{ color: constants.PRIMARY_COLOR }}
//               variant="subtitle2"
//               className={classes.roleTypography}
//             >
//               Amount
//             </Typography>
//           </div>
//           <div>
//             <Typography
//               variant="subtitle2"
//               className={classes.textFieldTypography}
//             >
//               <span className={classes.separator}>:</span>{" "}
//               {currentPosition && currentPosition.positionType}
//             </Typography>
//             <Typography
//               variant="subtitle2"
//               className={classes.textFieldTypography}
//             >
//               <span className={classes.separator}>:</span>{" "}
//               {currentPosition && currentPosition.role}
//             </Typography>
//             <Typography
//               variant="subtitle2"
//               className={classes.textFieldTypography}
//             >
//               <span className={classes.separator}>:</span>{" "}
//               {currentPosition && currentPosition.otherrolename}
//             </Typography>
//             <Typography
//               variant="subtitle2"
//               className={classes.textFieldTypography}
//             >
//               <span className={classes.separator}>:</span>{" "}
//               {currentPosition && currentPosition.gender}
//             </Typography>
//             <Typography
//               variant="subtitle2"
//               className={classes.textFieldTypography}
//             >
//               <span className={classes.separator}>:</span>{" "}
//               {currentPosition && currentPosition.ageMin} to{" "}
//               {currentPosition && currentPosition.ageMax}
//             </Typography>
//             <Typography
//               variant="subtitle2"
//               className={classes.textFieldTypography}
//             >
//               <span className={classes.separator}>:</span>{" "}
//               {currentPosition && currentPosition.compensation}
//             </Typography>
//             <Typography
//               variant="subtitle2"
//               className={classes.textFieldTypography}
//             >
//               <span className={classes.separator}>:</span>{" "}
//               {currentPosition && currentPosition.amount}
//             </Typography>
//           </div>
//         </div>
//         <div className={classes.addressParent}>
//           <Typography
//             style={{ color: constants.PRIMARY_COLOR }}
//             variant="subtitle2"
//             className={classes.roleTypography}
//           >
//             Address
//           </Typography>
//           <LocatinIcon />
//         </div>

//         <div className={classes.address}>
//           <div className={classes.rowPositionDetails}>
//             <div className={classes.labelParent}>
//               <Typography
//                 style={{ color: constants.PRIMARY_COLOR }}
//                 variant="subtitle2"
//                 className={classes.addressMargin}
//               >
//                 Building no
//               </Typography>
//               <Typography
//                 style={{ color: constants.PRIMARY_COLOR }}
//                 variant="subtitle2"
//                 className={classes.roleTypography}
//               >
//                 Street
//               </Typography>
//               <Typography
//                 style={{ color: constants.PRIMARY_COLOR }}
//                 variant="subtitle2"
//                 className={classes.roleTypography}
//               >
//                 City
//               </Typography>
//               <Typography
//                 style={{ color: constants.PRIMARY_COLOR }}
//                 variant="subtitle2"
//                 className={classes.roleTypography}
//               >
//                 State
//               </Typography>
//               <Typography
//                 style={{ color: constants.PRIMARY_COLOR }}
//                 variant="subtitle2"
//                 className={classes.roleTypography}
//               >
//                 Country
//               </Typography>
//               <Typography
//                 style={{ color: constants.PRIMARY_COLOR }}
//                 variant="subtitle2"
//                 className={classes.roleTypography}
//               >
//                 Industry
//               </Typography>
//             </div>
//             <div>
//               <Typography variant="subtitle2" className={classes.addressMargin}>
//                 <span className={classes.separator}>:</span>{" "}
//                 {currentPosition && currentPosition.houseno}
//               </Typography>
//               <Typography
//                 variant="subtitle2"
//                 className={classes.textFieldTypography}
//               >
//                 <span className={classes.separator}>:</span>
//                 {currentPosition && currentPosition.street}
//               </Typography>
//               <Typography
//                 variant="subtitle2"
//                 className={classes.textFieldTypography}
//               >
//                 <span className={classes.separator}>:</span>{" "}
//                 {currentPosition && currentPosition.city}
//               </Typography>
//               <Typography
//                 variant="subtitle2"
//                 className={classes.textFieldTypography}
//               >
//                 <span className={classes.separator}>:</span>{" "}
//                 {currentPosition && currentPosition.state}
//               </Typography>
//               <Typography
//                 variant="subtitle2"
//                 className={classes.textFieldTypography}
//               >
//                 <span className={classes.separator}>:</span>{" "}
//                 {currentPosition && currentPosition.country}
//               </Typography>
//               <Typography
//                 variant="subtitle2"
//                 className={classes.textFieldTypography}
//               >
//                 <span className={classes.separator}>:</span>{" "}
//                 {currentPosition && currentPosition.industry}
//               </Typography>
//             </div>
//           </div>
//         </div>
//         <div>
//           <Typography variant="subtitle2" className={classes.roleTypography}>
//             Description
//           </Typography>
//           <div className={classes.descriptionTypography}>
//             <Typography variant="subtitle2">
//               {currentPosition && currentPosition.description}
//             </Typography>
//           </div>
//         </div>
//         <FormControlLabel
//           className={classes.checkBoxTypography}
//           control={
//             <Checkbox
//               checked={currentPosition && currentPosition.otherRolesCanApply}
//               value="otherRolesCanApply"
//               color="secondary"
//             />
//           }
//           label="otherRolesCanApply"
//         />

//         <div className={classes.flexJustifyCenter}>
//           <Button
//             variant="contained"
//             style={{ color: constants.PRIMARY_COLOR }}
//             className={classes.editButton}
//             onClick={handleRedirectToEditPositions}
//           >
//             Edit
//           </Button>
//         </div>
//         <div className={classes.pagination}>
//           <Pagination
//             count={positionIdArray.length}
//             page={page}
//             onChange={handleChangePage}
//             color="secondary"
//           />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default withRouter(ViewPositionDetails);
