import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";
import clsx from "clsx";

import CreateSearchForm from "./CreateSearchForm";
import InputBase from "@material-ui/core/InputBase";
import Pagination from "@material-ui/lab/Pagination";
import { lighten, makeStyles, fade } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { openDialog, closeDialog } from "../../actions/appActions";

import { requestStates } from "../../actions/registerActions";
import {
  filterParams,
  updateSearchParams,
  searchNamesByUserId,
  getRecentSearch,
  createFilterSearch,
  deleteFilterSearch,
} from "../../actions/filterAction";

import { genderOptionsList } from "../../helpers/constants";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontSize: "16px",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  DialogTitleHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  formControl: {
    width: "33%",
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },

  tablepagination: {
    display: "flex",
    flexDirection: "column",

    width: "100%",
  },
  closeIconButton: {
    padding: "16px 24px",
  },
  margin: {
    margin: theme.spacing(1),
  },
  flexRow: {
    display: "flex",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "8px",
  },
  search: {
    border: "1px solid #c4c4c4;",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  buttonGroup: {
    marginTop: "16px",
  },
}));

const UserProfileListViewTableToolbar = (props) => {
  const dispatch = useDispatch();
  const classes = useToolbarStyles();
  const [formOpen, setFormOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const {
    numSelected,
    totalPages,
    countriesList,
    rolesList,
    industriesList,
  } = props;

  const statesList = useSelector(
    ({ registerReducer }) => registerReducer.states
  );

  const user = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.userDetail
  );
  const [ageDuration, setAgeDuration] = useState([0, 0]);
  const [pageNumber, setPageNumber] = useState(1);
  const [state, setState] = useState({
    country: { _id: "1_country", country: "Any" },
    states: { id: "abc", state: "Any" },
    gender: { id: 0, gender: "Any" },
    mainrole: { _id: "1_role", role: "Any", __v: 0 },
    industry: { _id: "1_industry", industry: "Any", __v: 0 },
    ageMin: 0,
    ageMax: 0,
    limit: 10,
    searchNames: [],
    currentSearchNameIndex: 0,
    key: "",
  });
  const [country, setCountry] = useState("Any");

  useEffect(() => {
    getRecentSearch().then((response) => {
      const record = response.data.data[0];

      if (record) {
        const {
          searchAgeMax,
          searchAgeMin,
          searchIndustry,
          searchGender,
          searchCountry,
          searchRole,
          searchState,
        } = record;
        const params = {
          ageMax: searchAgeMax,
          ageMin: searchAgeMin,
          industry: searchIndustry,
          gender: searchGender,
          country: searchCountry,
          mainrole: searchRole,
          state: searchState,
          page: 0,
          limit: 10,
          key: state.key,
        };
        props.handleFilter(params).then((res) => {
          searchNamesByUserId({ userId: user._id }).then((response) => {
            if (response.data.data) {
              setState({
                ...state,
                ageMax: searchAgeMax,
                ageMin: searchAgeMin,
                industry: { ...state.industry, industry: searchIndustry },
                gender: { ...state.gender, gender: searchGender },
                country: { ...state.country, country: searchCountry },
                mainrole: { ...state.mainrole, role: searchRole },
                states: { ...state.states, state: searchState },
                searchNames: response.data.data,
              });
            }
          });
        });
      }
    });
  }, []);

  useEffect(() => {
    !!state.country && dispatch(requestStates(state.country.country));
    props.sendCountry(state.country.country);
  }, [state.country]);
  useEffect(() => {
    props.sendCountry(state.country.country);
  }, []);

  const handleResetFilterOptionsPopover = () => {
    setPageNumber(1);
    setState({
      ...state,
      country: { _id: "1_country", country: "Any" },
      states: { id: "abc", state: "Any" },
      gender: { id: 0, gender: "Any" },
      mainrole: { _id: "1_role", role: "Any", __v: 0 },
      industry: { _id: "1_industry", industry: "Any", __v: 0 },
      ageMin: 0,
      ageMax: 0,
      limit: 10,
      key: "",
    });
    setAgeDuration([0, 0]);
    const filterOptions = {
      country: "Any",
      mainrole: "Any",
      gender: "Any",
      state: "Any",
      industry: "Any",
      ageMin: 0,
      ageMax: 0,
      pageNumber: 0,
      limit: 10,
      key: "",
    };
    props.handleFilter(filterOptions);
  };

  const handleChange = (name, value) => {
    if (name === "country") {
      if (value.country !== "India") {
        return setState({
          ...state,
          [name]: value,
          industry: { _id: "1_industry", industry: "Any", __v: 0 },
        });
      }
      setState({
        ...state,
        [name]: value,
      });
    }
    setState({
      ...state,
      [name]: value,
    });
  };
  useEffect(() => {
    setCountry(state.country.country);
  }, [state.country.country]);

  const handleChangePageNumber = (event, value) => {
    setPageNumber(value);
    const updatedFilterOptions = Object.assign(
      {
        country: state.country.country,
        state: state.states.state,
        gender: state.gender.gender,
        mainrole: state.mainrole.role,
        industry: state.industry.industry,
        ageMin: state.ageMin,
        ageMax: state.ageMax,
        limit: 10,
        key: state.key,
      },
      { ageDuration: ageDuration },
      { pageNumber: 0 }
    );

    const filterOptions = Object.assign(updatedFilterOptions, {
      pageNumber: value - 1,
    });

    props.handleFilter(filterOptions);
  };

  const handleChangeAge = (name) => (event) => {
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  const handleChangeInputTextSearch = (e) => {
    // props.handleSearch(e.target.value);
    setState({ ...state, key: e.target.value });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleNameClick = (index) => {
    setOpen(false);

    const { searchNames } = state;
    const {
      searchAgeMax,
      searchAgeMin,
      searchIndustry,
      searchGender,
      searchCountry,
      searchName,
      searchRole,
      searchState,
    } = searchNames[index];
    const params = {
      ageMax: searchAgeMax,
      ageMin: searchAgeMin,
      industry: searchIndustry,
      gender: searchGender,
      country: searchCountry,
      mainrole: searchRole,
      state: searchState,
      page: 0,
      limit: 10,
      key: state.key,
    };
    setState({
      ...state,
      ageMax: searchAgeMax,
      ageMin: searchAgeMin,
      industry: { ...state.industry, industry: searchIndustry },
      gender: { ...state.gender, gender: searchGender },
      country: { ...state.country, country: searchCountry },
      mainrole: { ...state.mainrole, role: searchRole },
      states: { ...state.states, state: searchState },
      currentSearchNameIndex: index,
      key: state.key,
    });
    props.handleFilter(params);
  };

  const handleDelete = (event, searchId) => {
    event.stopPropagation();

    deleteFilterSearch({ searchId }).then((response) => {
      if (!response.data.error) {
        setState({
          ...state,
          searchNames: state.searchNames.filter((f) => f._id !== searchId),
        });
        // snackbar.show(response.data.data);
      }

      return response;
    });
  };

  const handleApplyFilter = () => {
    const filterOptions = Object.assign(
      {
        country: state.country.country,
        state: state.states.state,
        gender: state.gender.gender,
        mainrole: state.mainrole.role,
        industry: state.industry.industry,
        ageMin: state.ageMin,
        ageMax: state.ageMax,
        limit: 10,
        key: state.key,
      },
      { ageDuration: ageDuration },
      { pageNumber: 0 }
    );

    props.handleFilter(filterOptions);
  };

  const handleCreate = (name) => {
    const params = {
      searchAgeMax: state.ageMax,
      searchAgeMin: state.ageMin,
      searchIndustry: state.industry.industry,
      searchGender: state.gender.gender,
      searchCountry: state.country.country,
      searchRole: state.mainrole.role,
      searchState: state.states.state,
      searchName: name,
    };

    createFilterSearch(params).then((response) => {
      if (!response.error) {
        setFormOpen(false);
      }
      if (response.data.data) {
        setState({
          ...state,
          searchNames: [...state.searchNames, response.data.data],
        });
        return response;
      }

      // snackbar.show(response.data.data);
      dispatch(openDialog(response.data.data));
      return response;
    });

    // onCreateSearch(params).then(response => {
    //   if (!response.error) {
    //     setFormOpen(false);
    //   }
    // });
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {/* {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h5" id="tableTitle">
            User Profile
          </Typography>
        )} */}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <div className={classes.tablepagination}>
          <div className={classes.flexCol}>
            <form className={classes.container}>
              <div className={classes.flexCol} style={{ width: "69%" }}>
                <div className={classes.flexRow}>
                  <Grid item className={classes.formControl}>
                    <Autocomplete
                      disableClearable={true}
                      size="small"
                      options={
                        countriesList
                          ? [
                              { _id: "1_country", country: "Any" },
                              ...countriesList,
                            ]
                          : []
                      }
                      getOptionLabel={(option) => option.country}
                      onChange={(e, object) => handleChange("country", object)}
                      value={state.country}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          label="Country"
                          value={state.country}
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item className={classes.formControl}>
                    <Autocomplete
                      disableClearable={true}
                      size="small"
                      options={
                        statesList
                          ? [
                              { id: "abc", state: "Any" },
                              ...statesList.map((state, i) => {
                                return { id: i, state: state };
                              }),
                            ]
                          : []
                      }
                      getOptionLabel={(option) => option.state}
                      onChange={(e, object) => handleChange("states", object)}
                      value={state.states}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          label="State"
                          value={state.states}
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item className={classes.formControl}>
                    <Autocomplete
                      disableClearable={true}
                      size="small"
                      options={
                        industriesList
                          ? [
                              { id: "cde", industry: "Any" },
                              ...industriesList.map((industry, i) => {
                                return { id: i, industry: industry.industry };
                              }),
                            ]
                          : []
                      }
                      getOptionLabel={(option) => option.industry}
                      onChange={(e, object) => handleChange("industry", object)}
                      value={state.industry}
                      disabled={country !== "India"}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          label="Industry"
                          disabled={country !== "India"}
                          value={state.industry}
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                </div>
                <div className={classes.flexRow}>
                  <Grid item className={classes.formControl}>
                    <Autocomplete
                      disableClearable={true}
                      size="small"
                      options={
                        genderOptionsList
                          ? [
                              ...genderOptionsList.map((gender, i) => {
                                return { id: i, gender: gender };
                              }),
                            ]
                          : []
                      }
                      getOptionLabel={(option) => option.gender}
                      onChange={(e, object) => handleChange("gender", object)}
                      //disabled={!Boolean(country)}
                      value={state.gender}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          label="Gender"
                          value={state.gender}
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item className={classes.formControl}>
                    <Autocomplete
                      size="small"
                      disableClearable={true}
                      options={
                        rolesList
                          ? [
                              { id: "1_role", role: "Any", _v: 0 },
                              ...rolesList.map((role, i) => {
                                return { id: i, role: role.role };
                              }),
                            ]
                          : []
                      }
                      // onInputChange={(e) =>
                      //   handleChangeInput(e, "mainrole", {
                      //     id: "1_role",
                      //     role: "Any",
                      //     _v: 0,
                      //   })
                      // }
                      getOptionLabel={(option) => option.role}
                      onChange={(e, object) => handleChange("mainrole", object)}
                      value={state.mainrole}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          label="Role"
                          value={state.mainrole}
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <FormControl
                    className={classes.formControl}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <TextField
                      size="small"
                      id="outlined-basic"
                      label="Age"
                      type="number"
                      name="ageMin"
                      variant="outlined"
                      value={state.ageMin}
                      onChange={handleChangeAge("ageMin")}
                    />
                    <Typography variant="h4" style={{ margin: "8px" }}>
                      -
                    </Typography>
                    <TextField
                      size="small"
                      id="outlined-basic"
                      type="number"
                      variant="outlined"
                      name="ageMax"
                      value={state.ageMax}
                      onChange={handleChangeAge("ageMax")}
                    />
                  </FormControl>
                </div>
              </div>
              <div
                style={{
                  // alignItems: "center",
                  // justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  width: "29%",
                  margin: "8px",
                }}
              >
                <div>
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>
                    <InputBase
                      onChange={handleChangeInputTextSearch}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleApplyFilter();
                        }
                      }}
                      value={state.key}
                      placeholder="Press Enter to Search"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ "aria-label": "search" }}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: "22px" }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleApplyFilter}
                    style={{ marginRight: "5px", textTransform: "none" }}
                  >
                    Search
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: "5px", textTransform: "none" }}
                    onClick={handleClick}
                  >
                    List
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                  >
                    {state.searchNames.map((name, index) => (
                      <MenuItem onClick={() => handleNameClick(index)}>
                        <ListItemIcon>
                          <DeleteIcon
                            style={{
                              color: "black",
                              border: "1px solid #d3d3d3",
                            }}
                            fontSize="small"
                            onClick={(e) => handleDelete(e, name._id)}
                          />
                        </ListItemIcon>
                        <ListItemText primary={name.searchName} />
                      </MenuItem>
                    ))}
                  </Menu>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setFormOpen(true)}
                    style={{ marginRight: "5px", textTransform: "none" }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleResetFilterOptionsPopover}
                    style={{ marginRight: "5px", textTransform: "none" }}
                  >
                    Clear
                  </Button>
                </div>
              </div>
            </form>

            {formOpen && (
              <CreateSearchForm
                open={formOpen}
                handleClose={() => setFormOpen(false)}
                handleCreate={handleCreate}
              />
            )}
          </div>
          <div className={classes.pagination}>
            <Pagination
              count={totalPages}
              page={pageNumber}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePageNumber}
            />
          </div>
        </div>
      )}
    </Toolbar>
  );
};

UserProfileListViewTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default UserProfileListViewTableToolbar;

// import React, { useEffect, useState } from "react";

// import { useDispatch, useSelector } from "react-redux";

// import PropTypes from "prop-types";
// import clsx from "clsx";

// import Pagination from "@material-ui/lab/Pagination";
// import { lighten, makeStyles } from "@material-ui/core/styles";
// import TextField from "@material-ui/core/TextField";
// import Toolbar from "@material-ui/core/Toolbar";
// import IconButton from "@material-ui/core/IconButton";
// import Tooltip from "@material-ui/core/Tooltip";
// import DeleteIcon from "@material-ui/icons/Delete";
// import Typography from "@material-ui/core/Typography";
// import InputLabel from "@material-ui/core/InputLabel";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";

// import Button from "@material-ui/core/Button";

// import { requestStates } from "../../actions/registerActions";

// import { genderOptionsList } from "../../helpers/constants";

// const useToolbarStyles = makeStyles(theme => ({
//   root: {
//     paddingLeft: theme.spacing(2),
//     paddingRight: theme.spacing(1),
//     fontSize: "16px"
//   },
//   highlight:
//     theme.palette.type === "light"
//       ? {
//           color: theme.palette.secondary.main,
//           backgroundColor: lighten(theme.palette.secondary.light, 0.85)
//         }
//       : {
//           color: theme.palette.text.primary,
//           backgroundColor: theme.palette.secondary.dark
//         },
//   title: {
//     flex: "1 1 100%"
//   },
//   DialogTitleHead: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center"
//   },
//   formControl: {
//     width: "33%",
//     margin: theme.spacing(1),
//     minWidth: 120
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2)
//   },
//   container: {
//     display: "flex"
//   },

//   tablepagination: {
//     display: "flex",
//     flexDirection: "column",

//     width: "100%"
//   },
//   closeIconButton: {
//     padding: "16px 24px"
//   },
//   margin: {
//     margin: theme.spacing(1)
//   },
//   flexRow: {
//     display: "flex"
//   },
//   flexCol: {
//     display: "flex",
//     flexDirection: "column"
//   },
//   pagination: {
//     display: "flex",
//     justifyContent: "flex-end",
//     margin: "8px"
//   }
// }));

// const UserProfileListViewTableToolbar = props => {
//   const dispatch = useDispatch();
//   const classes = useToolbarStyles();

//   const {
//     numSelected,
//     totalPages,
//     countriesList,
//     rolesList,
//     industriesList
//   } = props;

//   const inputLabel = useRef(null);

//   const [labelWidth, setLabelWidth] = useState(0);

//   useEffect(() => {
//     setLabelWidth(inputLabel.current.offsetWidth);
//   }, []);
//   const statesList = useSelector(
//     ({ registerReducer }) => registerReducer.states
//   );
//   const [ageDuration, setAgeDuration] = useState([0, 0]);
//   const [pageNumber, setPageNumber] = useState(1);
//   const [state, setState] = useState({
//     country: "Any",
//     states: "Any",
//     gender: "Any",
//     mainrole: "Any",
//     industry: "Any",
//     ageMin: 0,
//     ageMax: 0,
//     limit: 10
//   });
//   useEffect(() => {
//     dispatch(requestStates(state.country));
//   }, [state.country]);

//   const handleResetFilterOptionsPopover = () => {
//     setState({
//       ...state,
//       country: "Any",
//       states: "Any",
//       gender: "Any",
//       mainrole: "Any",
//       industry: "Any",
//       ageMin: 0,
//       ageMax: 0,
//       limit: 10
//     });
//     setAgeDuration([0, 0]);
//     const filterOptions = {
//       country: "Any",
//       mainrole: "Any",
//       gender: "Any",
//       states: "Any",
//       industry: "Any",
//       ageMin: 0,
//       ageMax: 0,
//       pageNumber: 0,
//       limit: 10
//     };
//     props.handleFilter(filterOptions);
//   };

//   const handleChange = name => event => {
//     setState({
//       ...state,
//       [name]: event.target.value
//     });
//   };

//   const handleChangePageNumber = (event, value) => {
//     setPageNumber(value);
//     const filterOptions = Object.assign(state, { pageNumber: value - 1 });

//     props.handleFilter(filterOptions);
//   };

//   const handleApplyFilter = () => {
//     const filterOptions = Object.assign(
//       state,
//       { ageDuration: ageDuration },
//       { pageNumber: 0 }
//     );

//     props.handleFilter(filterOptions);
//   };

//   return (
//     <Toolbar
//       className={clsx(classes.root, {
//         [classes.highlight]: numSelected > 0
//       })}
//     >
//       {numSelected > 0 ? (
//         <Tooltip title="Delete">
//           <IconButton aria-label="delete">
//             <DeleteIcon />
//           </IconButton>
//         </Tooltip>
//       ) : (
//         <div className={classes.tablepagination}>
//           <div className={classes.flexCol}>
//             <form className={classes.container}>
//               <div className={classes.flexCol} style={{ width: "80%" }}>
//                 <div className={classes.flexRow}>
//                   <FormControl
//                     className={classes.formControl}
//                     variant="outlined"
//                   >
//                     <InputLabel
//                       ref={inputLabel}
//                       htmlFor="outlined-country-native-simple"
//                     >
//                       Country
//                     </InputLabel>
//                     <Select
//                       native
//                       labelWidth={labelWidth}
//                       value={state.country}
//                       onChange={handleChange("country")}
//                       inputProps={{
//                         name: "country",
//                         id: "outlined-country-native-simple"
//                       }}
//                     >
//                       {countriesList &&
//                         [
//                           { _id: "1_country", country: "Any" },
//                           ...countriesList
//                         ].map(option => {
//                           return (
//                             <option key={option._id} value={option.country}>
//                               {option.country}
//                             </option>
//                           );
//                         })}
//                     </Select>
//                   </FormControl>
//                   <FormControl
//                     className={classes.formControl}
//                     variant="outlined"
//                   >
//                     <InputLabel ref={inputLabel} htmlFor="state-native-helper">
//                       State
//                     </InputLabel>
//                     <Select
//                       native
//                       labelWidth={labelWidth}
//                       value={state.states}
//                       onChange={handleChange("states")}
//                       inputProps={{
//                         name: "state",
//                         id: "state-native-helper"
//                       }}
//                     >
//                       {statesList &&
//                         ["Any", ...statesList].map(option => {
//                           return (
//                             <option key={option} value={option}>
//                               {option}
//                             </option>
//                           );
//                         })}
//                     </Select>
//                   </FormControl>
//                   <FormControl
//                     className={classes.formControl}
//                     variant="outlined"
//                   >
//                     <InputLabel
//                       ref={inputLabel}
//                       htmlFor="state-native-helper"
//                       shrink={true}
//                     >
//                       Roles
//                     </InputLabel>
//                     <Select
//                       native
//                       labelWidth={labelWidth}
//                       value={state.mainrole}
//                       onChange={handleChange("mainrole")}
//                       inputProps={{
//                         name: "state",
//                         id: "state-native-helper"
//                       }}
//                     >
//                       {rolesList &&
//                         [{ _id: "1_role", role: "Any" }, ...rolesList].map(
//                           option => {
//                             return (
//                               <option key={option._id} value={option.role}>
//                                 {option.role}
//                               </option>
//                             );
//                           }
//                         )}
//                     </Select>
//                   </FormControl>
//                 </div>
//                 <div className={classes.flexRow}>
//                   <FormControl
//                     className={classes.formControl}
//                     variant="outlined"
//                   >
//                     <InputLabel ref={inputLabel} htmlFor="gender-native-helper">
//                       Gender
//                     </InputLabel>
//                     <Select
//                       native
//                       labelWidth={labelWidth}
//                       value={state.gender}
//                       onChange={handleChange("gender")}
//                       inputProps={{
//                         name: "gender",
//                         id: "gender-native-helper"
//                       }}
//                     >
//                       {genderOptionsList.map(option => {
//                         return <option value={option}>{option}</option>;
//                       })}
//                     </Select>
//                   </FormControl>
//                   <FormControl
//                     className={classes.formControl}
//                     variant="outlined"
//                   >
//                     <InputLabel htmlFor="gender-native-helper">
//                       Industry
//                     </InputLabel>
//                     <Select
//                       native
//                       labelWidth={labelWidth}
//                       value={state.industry}
//                       onChange={handleChange("industry")}
//                       inputProps={{
//                         name: "industry",
//                         id: "industry-native-helper"
//                       }}
//                     >
//                       {industriesList &&
//                         [
//                           { _id: "1_industry", industry: "Any" },
//                           ...industriesList
//                         ].map(option => {
//                           return (
//                             <option id={option._id} value={option.industry}>
//                               {option.industry}
//                             </option>
//                           );
//                         })}
//                     </Select>
//                   </FormControl>
//                   <FormControl
//                     className={classes.formControl}
//                     style={{
//                       display: "flex",
//                       flexDirection: "row"
//                     }}
//                   >
//                     <TextField
//                       id="outlined-basic"
//                       label="Age"
//                       type="number"
//                       name="ageMin"
//                       variant="outlined"
//                       value={state.ageMin}
//                       onChange={handleChange("ageMin")}
//                     />
//                     <Typography variant="h4" style={{ margin: "8px" }}>
//                       -
//                     </Typography>
//                     <TextField
//                       id="outlined-basic"
//                       type="number"
//                       variant="outlined"
//                       name="ageMax"
//                       value={state.ageMax}
//                       onChange={handleChange("ageMax")}
//                     />
//                   </FormControl>
//                 </div>
//               </div>
//               <div
//                 style={{
//                   alignItems: "center",
//                   justifyContent: "center",
//                   display: "flex",
//                   flexDirection: "column",
//                   width: "20%"
//                 }}
//               >
//                 <Button
//                   onClick={handleResetFilterOptionsPopover}
//                   color="primary"
//                   style={{ marginBottom: "8px" }}
//                 >
//                   Reset
//                 </Button>
//                 <Button
//                   style={{ marginTop: "8px" }}
//                   onClick={handleApplyFilter}
//                   color="primary"
//                   variant="contained"
//                 >
//                   Apply
//                 </Button>
//               </div>
//             </form>
//           </div>
//           <div className={classes.pagination}>
//             <Pagination
//               count={totalPages}
//               page={pageNumber}
//               variant="outlined"
//               shape="rounded"
//               onChange={handleChangePageNumber}
//             />
//           </div>
//         </div>
//       )}
//     </Toolbar>
//   );
// };

// UserProfileListViewTableToolbar.propTypes = {
//   numSelected: PropTypes.number.isRequired
// };

// export default UserProfileListViewTableToolbar;
