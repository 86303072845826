import React from "react";
import getURL from "../helpers/apis";
import { Link } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import withRouter from "react-router-dom/es/withRouter";
import { Tabs, Tab } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import {
  updateProfile,
  handleUpload,
  openAvatarModal,
} from "../actions/appActions";
import { getUserDetails } from "../actions/userDetailsAction";

import {
  isValidParam,
  getStringParam,
  getBooleanParam,
  getArrayParam,
} from "../helpers/paramVerifier";
import Typography from "@material-ui/core/Typography";
import { Loader } from "./UtilityComponents";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import PhotoSharpIcon from "@material-ui/icons/PhotoSharp";
import PersonSharpIcon from "@material-ui/icons/PersonSharp";
import EventNoteSharpIcon from "@material-ui/icons/EventNoteSharp";
import DynamicFeedSharpIcon from "@material-ui/icons/DynamicFeedSharp";
// import FollowingIcon from "@material-ui/icons/ArrowUpward";
// import ProfileLikeIcon from "@material-ui/icons/ThumbUpAltSharp";
// import FollowersIcon from "@material-ui/icons/ArrowDownward";
import LikeHiddenIcon from "@material-ui/icons/Favorite";
import CommentIcon from "@material-ui/icons/Comment";
import LikeOpenIcon from "@material-ui/icons/FavoriteBorderSharp";
import FavouriteIcon from "@material-ui/icons/BookmarkBorderSharp";
import EventNoteIcon from "@material-ui/icons/EventNote";
import PhotoOutlinedIcon from "@material-ui/icons/PhotoOutlined";
import PhotoCameraOutlinedIcon from "@material-ui/icons/PhotoCameraOutlined";
import VisibilityOffSharpIcon from "@material-ui/icons/VisibilityOffSharp";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
// import ShareToMeIcon from "@material-ui/icons/ReplySharp";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabledSharp";
import BlockedUserIcon from "../assets/svg/Blocked Users Icon.svg";
import LikesIcon from "../assets/svg/Likes icon (OUTLINE).svg";
import FavouritesIcon from "../assets/svg/Favourites icon (OUTLINE).svg";
import HiddenFriendsIcon from "../assets/svg/Hidden Friends icon.svg";
import ProfileLikeIcon from "../assets/svg/Profile Like icon.svg";
import ShareToMeIcon from "../assets/svg/Sharedtome icon.svg";
import MyCommentsIcon from "../assets/svg/My Comments Icon 2.svg";
import CalendarIcon from "../assets/svg/Calendar icon.svg";
import CalendarIconBlue from "../assets/svg/Calendar icon (blue).svg";
import GalleryIcon from "../assets/svg/Gallery icon.svg";
import GalleryIconBlue from "../assets/svg/Gallery icon (blue).svg";
import PersonIcon from "../assets/svg/User profile icon.svg";
import PersonIconBlue from "../assets/svg/User profile icon (blue) copy.svg";
import FollowingIcon from "../assets/svg/Following (black).svg";
import FollowersIcon from "../assets/svg/Followers (black).svg";
import WarningIcon from "../assets/images/icons/warning.png";
import { Icon } from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";
import EditProfile from "./EditProfile";
import EditProfileForm from "./EditProfile/EditProfileForm";
import Modal from "@material-ui/core/Modal";
import UserGalleryComponent from "./UserGalleryComponent";
import ProfileInfo from "./ProfileInfo";
import CalendarComponent from "./CalendarComponent";
import PostFeed from "./PostsComponent/PostFeed";

// import "react-image-gallery/styles/css/image-gallery.css";
// import "react-image-gallery/styles/css/image-gallery-no-icon.css";
// import ImageGallery from "react-image-gallery";
import UploadDialog from "./FineUploader/UploadDialog";
import { CheckCircle } from "@material-ui/icons";
// import ImageDialog from "./UserProfile/ImageDialog";

const rootUrl = getURL("UPLOADS");

const styles = (theme) => ({
  mainContent: {
    marginTop: 20,
  },
  paper: {
    fontSize: 14,
    width: "100%",
    minWidth: 275,
    // margin: 10,
    maxWidth: 1200,
  },
  avatar: {
    fontSize: 100,
    margin: "auto",
    // marginLeft: "8px",
    width: 120,
    height: 120,
  },
  columnContainer: {
    padding: 5,
  },
  fieldGridLabel: {
    // width: "30%",
    fontSize: 18,
    margin: 5,
  },
  fieldGridValue: {
    // width: "68%",
    fontSize: 18,
    margin: 5,
  },
  fieldLabel: {
    fontSize: 14,
    // fontWeight: 600,
  },
  fieldValue: {
    fontSize: 14,
  },
  actionsRow: {},
  actionContainer: {},
  actionElement: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
    cursor: "pointer",
  },
  actionButton: {
    marginLeft: 10,
    marginTop: 10,
    paddingLeft: 30,
    paddingRight: 30,
    textTransform: "capitalize",
    fontSize: 14,
    height: 30,
    paddingTop: 4,
  },
  profilePicChange: {
    top: 128,
    width: 120,
    height: 56,
    zIndex: 1000,
    marginLeft: "4%",
    position: "absolute",
    backgroundColor: "#00000070",
    borderBottomLeftRadius: 100,
    borderBottomRightRadius: 100,
    textAlign: "center",
    cursor: "pointer",
  },
  profileCamera: {
    position: "relative",
    marginTop: 6,
    fontSize: 24,
    color: "#ffffff",
  },
  icons: {
    cursor: "pointer",
    // fontSize: 24,
    height: "auto",
    // marginLeft: 8,
    // marginRight: 8,
  },
  actionCount: {
    fontSize: 16,
    marginLeft: 25,
    marginRight: 55,
  },
  profileTitle: {
    textAlign: "center",
    fontSize: 24,
    fontWeight: 500,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  tabPaper: {
    flexGrow: 1,
    borderTop: 0,
    // overflowY: "scroll",
    // height: "100vh",
  },
  tabIndicator: {
    top: "0px",
    height: "2px",
    backgroundColor: "#3f51b5",
  },
  navIcons: {
    display: "block",
    marginTop: "-20px",
    overflow: "hidden",
    pointerEvents: "auto",
    position: "fixed",
    textIndent: "-9999em",
    top: "50%",
    cursor: "pointer",
    color: "white",
  },
});

class ProfileAboutComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: props.userDetails,
      isHoverProfilePic: false,
      open: null,
      avatarModal: false,
      editProfile: false,
      value: 0,
      openUploader: false,
      refresh: false,
      type: "public",
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOpenEdit = this.handleOpenEdit.bind(this);
  }

  componentDidMount() {
    if (!this.props.userDetails) {
      const id = localStorage.getItem("userId");
      const jwt = localStorage.getItem("jwt");
      this.props.dispatch(getUserDetails(jwt, id));
    }
    this.props.match.path === "/profile-me/public/" &&
      this.setState({ ...this.state, value: 1, type: "public" });
    this.props.match.path === "/profile-me/department/" &&
      this.setState({ ...this.state, value: 1, type: "department" });
    this.props.match.path === "/profile-me/private/" &&
      this.setState({ ...this.state, value: 1, type: "private" });
    this.props.match.path === "/profile-me/my-calendar/" &&
      this.setState({ ...this.state, value: 2 });
  }
  // componentDidUpdate() {
  //   const id = localStorage.getItem("userId");
  //   const jwt = localStorage.getItem("jwt");
  //   this.props.dispatch(getUserDetails(jwt, id));
  // }

  refresh() {
    const id = localStorage.getItem("userId");
    const jwt = localStorage.getItem("jwt");
    this.props.dispatch(getUserDetails(jwt, id));
  }

  handleEdit(params) {
    return this.props.dispatch(updateProfile(params));
  }

  handleOpenEdit(e) {
    this.props.history.push("/editprofile");
    // this.setState({ editProfile: true });
  }

  handleChange(e, newValue) {
    this.setState({ value: newValue });
  }

  onMouseEnter() {
    this.setState({ isHoverProfilePic: true });
  }

  onMouseLeave() {
    this.setState({ isHoverProfilePic: false });
  }

  onAvatarClick(profilePhotoUrl) {
    // this.setState({ avatarModal: true });
    return this.props.dispatch(openAvatarModal(profilePhotoUrl));
  }

  handleClose() {
    this.setState({ openUploader: false });
  }

  render() {
    const { classes, userDetail: userDetails } = this.props;
    let isHoverProfilePic = getBooleanParam(this.state.isHoverProfilePic);
    if (!userDetails) {
      return <Loader />;
    }
    let profilePhotoUrl = null;
    if (userDetails.profilepic) profilePhotoUrl = userDetails.profilepic;
    // let profileName =
    //   getStringParam(userDetails.firstname) +
    //   " " +
    //   getStringParam(userDetails.lastname);

    let profileName = userDetails.username;
    let userBio = userDetails.userBio;
    return (
      <Paper className={classes.paper} elevation={0}>
        {this.state.openUploader && (
          <UploadDialog
            open={this.state.openUploader}
            handleClose={() => {
              this.handleClose({ openUploader: false });
              this.refresh();
            }}
          />
        )}
        <Grid
          container
          spacing={0}
          justify="space-between"
          className={classes.mainContent}
        >
          <Grid key={2} item xs={2}>
            {profilePhotoUrl ? (
              <Avatar
                alt={this.props.userDetail.name}
                className={classes.avatar}
                src={process.env.REACT_APP_BUCKET_NAME + profilePhotoUrl}
                onMouseEnter={this.onMouseEnter.bind(this)}
                onMouseLeave={this.onMouseLeave.bind(this)}
                onClick={() =>
                  this.onAvatarClick(
                    process.env.REACT_APP_BUCKET_NAME + profilePhotoUrl
                  )
                }
              />
            ) : (
              <Avatar
                className={classes.avatar}
                alt="owner"
                onMouseEnter={this.onMouseEnter.bind(this)}
                onMouseLeave={this.onMouseLeave.bind(this)}
                onClick={() => this.onAvatarClick()}
              />
            )}
            {isHoverProfilePic && (
              <Grid
                justify="space-evenly"
                className={classes.profilePicChange}
                onMouseEnter={this.onMouseEnter.bind(this)}
                onMouseLeave={this.onMouseLeave.bind(this)}
              >
                <div onClick={() => this.setState({ openUploader: true })}>
                  <i
                    className={classNames(
                      "material-icons",
                      classes.profileCamera
                    )}
                  >
                    local_see
                  </i>

                  <Typography style={{ fontSize: 14, color: "#ffffff" }}>
                    Update
                  </Typography>
                </div>
              </Grid>
            )}
          </Grid>

          <Grid key={3} item xs={3}>
          <Typography style={{padding:10}}>
          {userBio}
        </Typography>
          </Grid>

          <Grid key={7} item xs={7}>
            <Grid container>
              <Grid item xs={9}>
                <Typography component="div" className={classes.profileTitle} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                  {profileName}
                  {userDetails.verifiedProfile ? <Icon color="primary" style={{marginLeft : 5}}><CheckCircle/></Icon> :<></>}
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={(e) => this.handleOpenEdit(e)}
                  style={{ textTransform: "none" }}
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
            {/* <Divider variant="middle" /> */}

            <Grid container justify="center">
              <Grid item>
                <Typography component="div" className={classes.actionContainer}>
                  <IconButton fontSize="small" disabled>
                    <Link style={{ color: "black" }}>
                      <Typography>
                        {this.props.userDetail.noofprofilelikes}
                      </Typography>
                      <Icon className={classes.icons}>
                        <img src={ProfileLikeIcon} />
                      </Icon>

                      {/* <ProfileLikeIcon
                        className={classes.icons}
                        color="inherit"
                      /> */}
                    </Link>
                  </IconButton>
                  <IconButton fontSize="small">
                    <Link
                      to={`/profile-me/following/open`}
                      style={{ color: "black" }}
                    >
                      {" "}
                      <Typography>
                        {this.props.userDetail.nooffollowing}
                      </Typography>
                      <Icon className={classes.icons}>
                        <img src={FollowingIcon} />
                      </Icon>
                      {/* <FollowingIcon
                        className={classes.icons}
                        color="inherit"
                      /> */}
                    </Link>
                  </IconButton>
                  <IconButton fontSize="small">
                    <Link
                      to={`/profile-me/followers/open`}
                      style={{ color: "black" }}
                    >
                      <Typography>
                        {this.props.userDetail.nooffollowers}
                      </Typography>
                      <Icon className={classes.icons}>
                        <img src={FollowersIcon} />
                      </Icon>
                      {/* <FollowersIcon
                        className={classes.icons}
                        color="inherit"
                      /> */}
                    </Link>
                  </IconButton>
                  {this.props.userDetail.userType !== "FreeUser" && (
                    <IconButton fontSize="small">
                      <Link
                        to={`/profile-me/followers/hidden`}
                        style={{ color: "black" }}
                      >
                        <Typography style={{ whiteSpace: "pre" }}>
                          {!this.props.userDetail.hide_HiddenFriends_Count
                            ? this.props.userDetail.hidden_friends_count
                            : 0}
                        </Typography>
                        <Icon className={classes.icons}>
                          <img src={HiddenFriendsIcon} />
                        </Icon>

                        {/* <VisibilityOffSharpIcon
                          className={classes.icons}
                          color="inherit"
                        /> */}
                      </Link>
                    </IconButton>
                  )}
                </Typography>
              </Grid>
              <Grid item style={{ display: "flex", marginTop: 24 }}>
                {/* <Divider orientation="vertical" variant="fullWidth" /> */}
                <Typography component="div" className={classes.actionContainer}>
                  <IconButton fontSize="small">
                    <Link to={`/profile-me/likes`} style={{ color: "black" }}>
                      <Icon className={classes.icons}>
                        <img src={LikesIcon} />
                      </Icon>
                      {/* <LikeOpenIcon className={classes.icons} color="inherit" /> */}
                    </Link>
                  </IconButton>
                  <IconButton fontSize="small">
                    <Link
                      to={`/profile-me/favourites`}
                      style={{ color: "black" }}
                    >
                      <Icon className={classes.icons}>
                        <img src={FavouritesIcon} />
                      </Icon>
                      {/* <FavouriteIcon className={classes.icons} color="inherit" /> */}
                    </Link>
                  </IconButton>
                  <IconButton fontSize="small">
                    <Link
                      to={`/profile-me/blocked/all`}
                      style={{ color: "black" }}
                    >
                      <Icon className={classes.icons}>
                        <img src={BlockedUserIcon} />
                      </Icon>
                      {/* <PersonAddDisabledIcon
                        className={classes.icons}
                        color="inherit"
                      /> */}
                    </Link>
                  </IconButton>
                  <IconButton fontSize="small">
                    <Link
                      to="/profile-me/mycomments/open"
                      style={{ color: "black" }}
                    >
                      <Icon className={classes.icons}>
                        <img src={MyCommentsIcon} />
                      </Icon>
                      {/* <CommentIcon className={classes.icons} color="inherit" /> */}
                    </Link>
                  </IconButton>
                  {this.props.userDetail.userType !== "FreeUser" && (
                    <IconButton fontSize="small">
                      <Link
                        to={`/profile-me/sharedtome`}
                        style={{ color: "black" }}
                      >
                        <Icon className={classes.icons}>
                          <img src={ShareToMeIcon} />
                        </Icon>
                        {/* <ShareToMeIcon
                          className={classes.icons}
                          color="inherit"
                          style={{
                            transform: "rotate(270.5deg)",
                          }}
                        /> */}
                      </Link>
                    </IconButton>
                  )}

                  {this.props.userDetail.isWarningGiven &&
                    this.props.userDetail.isWarningGiven !== "false" && (
                      <IconButton fontSize="small">
                        <Link
                          to={`/profile-me/warnings`}
                          style={{ color: "black" }}
                        >
                          <Icon className={classes.icons}>
                            <img height={20} src={WarningIcon} />
                          </Icon>
                          {/* <ShareToMeIcon
                          className={classes.icons}
                          color="inherit"
                          style={{
                            transform: "rotate(270.5deg)",
                          }}
                        /> */}
                        </Link>
                      </IconButton>
                    )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Paper elevation={0} className={classes.tabPaper}>
            <Tabs
              TabIndicatorProps={{ className: classes.tabIndicator }}
              value={this.state.value}
              onChange={(e, newValue) => this.handleChange(e, newValue)}
              textColor="inherit"
              color="primary"
              variant="fullWidth"
              style={{ minHeight: 3 }}
            >
              <Tab
                style={{
                  border:
                    this.state.value !== 0
                      ? "1px solid rgba(var(--b38,219,219,219),1)"
                      : "none",
                  textTransform: "none",
                  padding: 0,
                  minHeight: 3,
                }}
                disableRipple
                icon={
                  // <PersonSharpIcon
                  //   color={this.state.value === 0 ? "primary" : "inherit"}
                  // />
                  this.state.value === 0 ? (
                    <Icon className={classes.icons}>
                      <img
                        style={{ verticalAlign: "baseline" }}
                        src={PersonIconBlue}
                      />
                    </Icon>
                  ) : (
                    <Icon className={classes.icons}>
                      <img
                        style={{ verticalAlign: "baseline" }}
                        src={PersonIcon}
                      />
                    </Icon>
                  )
                }
                aria-label="Profile"
              />
              <Tab
                style={{
                  border:
                    this.state.value !== 1
                      ? "1px solid rgba(var(--b38,219,219,219),1)"
                      : "none",
                  textTransform: "none",
                  padding: 0,
                  minHeight: 3,
                }}
                disableRipple
                icon={
                  // <PhotoSharpIcon
                  //   color={this.state.value === 1 ? "primary" : "inherit"}
                  // />
                  this.state.value === 1 ? (
                    <Icon className={classes.icons}>
                      <img
                        style={{ verticalAlign: "baseline" }}
                        src={GalleryIconBlue}
                      />
                    </Icon>
                  ) : (
                    <Icon className={classes.icons}>
                      <img
                        style={{ verticalAlign: "baseline" }}
                        src={GalleryIcon}
                      />
                    </Icon>
                  )
                }
                aria-label="Gallery"
              />
              {userDetails.userType !== "SocialMediaUser" &&
                userDetails.userType !== "FreeUser" && (
                  <Tab
                    style={{
                      border:
                        this.state.value !== 2
                          ? "1px solid rgba(var(--b38,219,219,219),1)"
                          : "none",
                      textTransform: "none",
                      padding: 0,
                      minHeight: 3,
                    }}
                    disableRipple
                    icon={
                      this.state.value === 2 ? (
                        <Icon className={classes.icons}>
                          <img
                            style={{ verticalAlign: "baseline" }}
                            src={CalendarIconBlue}
                          />
                        </Icon>
                      ) : (
                        <Icon className={classes.icons}>
                          <img
                            style={{ verticalAlign: "baseline" }}
                            src={CalendarIcon}
                          />
                        </Icon>
                      )
                      // <EventNoteSharpIcon
                      //   color={this.state.value === 2 ? "primary" : "inherit"}
                      // />
                    }
                    aria-label="Calendar"
                  />
                )}
            </Tabs>
            {this.state.value === 0 && (
              <ProfileInfo
                userDetails={userDetails}
                classes={classes}
                auth={true}
              />
            )}
            {this.state.value === 1 && (
              <UserGalleryComponent isLoggedIn={true} type={this.state.type} />
            )}
            {this.state.value === 2 &&
              userDetails &&
              userDetails.userType !== "SocialMediaUser" &&
              userDetails.userType !== "FreeUser" && (
                <CalendarComponent user_id={userDetails._id} />
              )}
          </Paper>
        </Grid>
      </Paper>
    );
  }
}

function mapStateToProps(state) {
  return {
    userDetail: state.userDetails ?? state.userDetailsReducer.userDetail,
  };
}

ProfileAboutComponent = withStyles(styles)(ProfileAboutComponent);
export default connect(mapStateToProps)(withRouter(ProfileAboutComponent));
