import React, { useState, useEffect } from "react";
import {
  getShareBids,
  postShareBidAfterPayment,
  postShareBid,
  getRazorPaySuccessCapture,
} from "../../actions/shareBidActions";
import {
  Typography,
  Button,
  Popper,
  Grid,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Card,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import { getUserAddress } from "../../../src/actions/applicantsDetailsActions";
import { useSelector, useDispatch } from "react-redux";
import useStyles from "./styles";
import { constants } from "../../helpers/constants";
import PurchaseDetails from "./PurchaseDetails";
import { openCheckout } from "../PaymentDetails/Checkout";
import { openDialog, closeDialog } from "../../actions/appActions";
import getURL from "../../helpers/apis";
import { getUserDetails } from "../../actions/userDetailsAction";
import InfoIcon from "@material-ui/icons/Info";
import Popover from "@material-ui/core/Popover";

const BidForm = ({ userDetail, history, dispatch }) => {
  const classes = useStyles();
  const [purchaseOpen, setPurchaseOpen] = useState(false);

  const [subs, setSubs] = useState([]);

  const [formData, setformData] = useState({
    user_id: userDetail._id,
    company:
      userDetail && userDetail.bidAlias && userDetail.bidAlias !== ""
        ? userDetail.bidAlias
        : "",
    username: userDetail.username,
    bidamt: "",
    type: "Select Type",
  });

  const handleChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.company.length < 3) {
      return dispatch(
        openDialog(
          "Bid username is mandatory and mininum bid username length is 3"
        )
      );
    }
    if (new RegExp(subs.join("|")).test(formData.company)) {
      return dispatch(
        openDialog(
          "Bid username can’t contain any 3 consecutive characters of the login user name."
        )
      );
    }

    userDetail.hasPaidToBuyShare
      ? dispatch(postShareBidAfterPayment(formData, history))
      : setPurchaseOpen(true);
  };

  const getAllSubstrings = (str, size) => {
    var i,
      j,
      result = [];
    size = size || 0;
    for (i = 0; i < str.length; i++) {
      for (j = str.length; j - i >= size; j--) {
        result.push(str.slice(i, j));
      }
    }
    return result;
  };

  useEffect(() => {
    var arr = getAllSubstrings(userDetail && userDetail.username, 3);
    setSubs(arr);
  }, [userDetail]);

  const handleCallbackRazorPayCapture = () => {
    const jwt = localStorage.getItem("jwt");
    dispatch(getUserDetails(jwt, userDetail._id));
    dispatch(openDialog("Bid Posted!"));
    dispatch(getShareBids());
  };

  const handleCallbackRazorPaySuccess = (res) => {
    setPurchaseOpen(false);
    const body = {
      ...res,
    };
    dispatch(getRazorPaySuccessCapture(body, handleCallbackRazorPayCapture));
  };

  const handleCallRazorPayMethod = (data) => {
    setPurchaseOpen(false);
    var pd = {
      key: process.env.REACT_APP_PAYU_KEY,
      txnid: data._id,
      amount: data.totalPriceAfterTax,
      firstname: userDetail.username,
      email: userDetail.email,
      phone: userDetail.phone,
      productinfo: data.bidId,
      surl: getURL("BASE_URL") + "buyShare",
      furl: getURL("BASE_URL") + "buyShare",
      hash: data.hash,
      service_provider: "payu_paisa",
    };
    redirectToPayU(pd);
  };

  const redirectToPayU = (pd) => {
    window.bolt.launch(pd, {
      responseHandler: (response) => {
        response.response.txnStatus !== "CANCEL" &&
          handleCallbackRazorPaySuccess(response);
      },
      catchException: (response) => {
        response.response.txnStatus !== "CANCEL" &&
          handleCallbackRazorPaySuccess(response);
      },
    });
  };

  const handleMakePayment = () => {
    setPurchaseOpen(false);
    dispatch(postShareBid(formData, history, handleCallRazorPayMethod));
  };

  return (
    <Grid
      container
      className={classes.paper}
      spacing={4}
      justify="space-evenly"
    >
      <Grid item xs={5}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Grid container>
            <Grid item xs={5}>
              <Typography
                style={{ margin: 10, color: constants.PRIMARY_COLOR }}
              >
                Bid Alias Name*
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                name="company"
                value={formData.company}
                defaultValue={
                  userDetail &&
                  userDetail.bidAlias &&
                  userDetail.bidAlias !== ""
                    ? userDetail.bidAlias
                    : ""
                }
                onChange={(e) => handleChange(e)}
                disabled={
                  userDetail &&
                  userDetail.bidAlias &&
                  userDetail.bidAlias !== ""
                }
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ margin: 10, color: constants.PRIMARY_COLOR }}
              >
                Bid Type*
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Select
                name="type"
                fullWidth
                value={formData.type}
                onChange={(e) => handleChange(e)}
              >
                <MenuItem disabled value="">
                  Select Type
                </MenuItem>
                <MenuItem value={"Open"}>Open</MenuItem>
                <MenuItem value={"Hidden"}>Hidden</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ margin: 10, color: constants.PRIMARY_COLOR }}
              >
                Bid Amount*
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                value={formData.bidamt}
                onChange={(e) =>
                  (Number(e.target.value) > 0 || e.target.value === "") &&
                  setformData({ ...formData, bidamt: e.target.value })
                }
                fullWidth
                required
                name="bidamt"
              />
            </Grid>

            <Grid item xs={12} align="center">
              <Button
                variant="contained"
                type="submit"
                size="large"
                className={classes.submit}
                style={{ textTransform: "none" }}
                color="primary"
              >
                Post Bid!
              </Button>
              {purchaseOpen && (
                <PurchaseDetails
                  open={purchaseOpen}
                  handleClose={() => setPurchaseOpen(false)}
                  handleMakePayment={handleMakePayment}
                  bidamt={formData.bidamt}
                />
              )}
            </Grid>
          </Grid>
        </form>
      </Grid>

      <Grid item xs={5}>
        <InfoIcon color="primary" fontSize="small" />

        <Card className={classes.popoverpaper}>
          <Typography
            variant="body1"
            // display="inline"
            style={{
              border: "solid",
              borderWidth: 0.125,
              borderColor: "gray",
              padding: 3,
            }}
          >
            Celebbook management wants to sell 10% of their share. Interested
            people can bid here. To avoid time passers we are charging Rs.5000
            as the participation fee, which is NOT REFUNDABLE. Open bid amount
            displays to everyone, hidden bid amount will not be visible to other
            users except the user logged in. Open, hidden bid amounts of all
            bidders displays to Celebbook admin. Highest amount bidder will
            become a partner of Celebbook.com
          </Typography>
        </Card>
      </Grid>
    </Grid>
  );
};

export default connect()(withRouter(BidForm));
