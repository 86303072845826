import request from "../helpers/requestEngine";
import snackbar from "snackbar";
import { openDialog, closeDialog } from "./appActions";

export const getFollowingSortedGallery = () => {
  return (dispatch) => {
    dispatch(fetchingFollowingSortedGallery());
    return dispatch(fetchFollowingSortedGallery());
  };
};

function fetchingFollowingSortedGallery() {
  return {
    type: "GET_FOLLOWING_VIDEOS",
  };
}

function fetchFollowingSortedGallery() {
  return (dispatch) => {
    request("get", "GET_FOLLOWING_GALLERY", true).then(
      (response) => {
        dispatch(fetchFollowingSortedGallerySuccess(response.data));
      },
      (err) => {
        // snackbar.show("Something went wrong. Please try again.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(fetchFollowingSortedGalleryFailed());
      }
    );
  };
}

function fetchFollowingSortedGallerySuccess(response) {
  return {
    type: "GET_FOLLOWING_VIDEOS_SUCCESS",
    payload: response.data,
  };
}

function fetchFollowingSortedGalleryFailed() {
  return {
    type: "GET_FOLLOWING_VIDEOS_FAILED",
  };
}

export const getNonFollowingSortedGallery = () => {
  return (dispatch) => {
    dispatch(fetchingNonFollowingSortedGallery());
    return dispatch(fetchNonFollowingSortedGallery());
  };
};

function fetchingNonFollowingSortedGallery() {
  return {
    type: "GET_NONFOLLOWING_VIDEOS",
  };
}

function fetchNonFollowingSortedGallery() {
  return (dispatch) => {
    request("get", "GET_NONFOLLOWING_GALLERY", true).then(
      (response) => {
        dispatch(fetchNonFollowingSortedGallerySuccess(response.data));
      },
      (err) => {
        // snackbar.show("Something went wrong. Please try again.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(fetchNonFollowingSortedGalleryFailed());
      }
    );
  };
}

function fetchNonFollowingSortedGallerySuccess(response) {
  return {
    type: "GET_NONFOLLOWING_VIDEOS_SUCCESS",
    payload: response.data,
  };
}

function fetchNonFollowingSortedGalleryFailed() {
  return {
    type: "GET_NONFOLLOWING_VIDEOS_FAILED",
  };
}

export const onFormSubmit = (e) => {
  e.preventDefault();
  return (dispatch) => {
    dispatch(formSubmitting());
    return dispatch(submitForm(e));
  };
};

function formSubmitting() {
  return {
    type: "VIDEO_SUBMITTING",
  };
}

function submitForm(e) {
  const eventTraget = e.target;
  const formData = {
    bnr: e.target.bnr.value,
    pr: e.target.pr.value,
    dr: e.target.dr.value,
    youtubeLink: e.target.youtubeLink.value,
  };
  return (dispatch) => {
    request("post", "VIDEOS_LIST", true, formData).then(
      (response) => {
        eventTraget.reset();
        // snackbar.show("Video link added");
        dispatch(onSubmitSuccess(response.data));
      },
      (err) => {
        // snackbar.show("Something went wrong. Please try again.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(onSubmitFailed());
      }
    );
  };
}

function onSubmitSuccess(response) {
  return {
    type: "VIDEOS_POST_SUCCESS",
    payload: response.data,
  };
}

function onSubmitFailed() {
  return {
    type: "VIDEOS_POST_FAILED",
  };
}

function fetchVideos() {
  return (dispatch) => {
    request("get", "VIDEOS_LIST", true).then(
      (response) => dispatch(videosFetchSuccess(response.data.data)),
      (err) => dispatch(videosFetchFailed())
    );
  };
}

function videosFetchSuccess(videos) {
  return {
    type: "VIDEOS_LIST_FETCH_SUCCESS",
    payload: videos,
  };
}

function videosFetchFailed() {
  return {
    type: "VIDEOS_LIST_FETCH_FAILED",
  };
}
