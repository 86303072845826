import React from "react";

const style = {
  border: "1px solid #ccc",
  borderRadius: "4px",
  boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
};

export class TextInput extends React.Component {
  render() {
    return <input style={style} type="text" {...this.props} />;
  }
}

export class PasswordInput extends React.Component {
  render() {
    return <input style={style} type="password" {...this.props} />;
  }
}

export class TextAreaInput extends React.Component {
  render() {
    return <textarea {...this.props} />;
  }
}

export class EmailInput extends React.Component {
  render() {
    return <input style={style} type="email" {...this.props} />;
  }
}

export class CheckBoxInput extends React.Component {
  render() {
    return <input style={style} type="checkbox" {...this.props} />;
  }
}

export class NumberInput extends React.Component {
  render() {
    return <input style={style} type="number" {...this.props} />;
  }
}

export class DateInput extends React.Component {
  render() {
    return <input style={style} type="date" {...this.props} />;
  }
}
