import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import ReactPlayer from "react-player";
import withRouter from "react-router-dom/es/withRouter";
import { Paper, Typography, Divider, Tabs, Tab } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Gallery from "react-fine-uploader";
import FineUploaderTraditional from "fine-uploader-wrappers";
import getURL from "../../helpers/apis";
import "react-fine-uploader/gallery/gallery.css";
import UploadForm from "./UploadForm";
import AlertDialog from "../AlertDialog";
import { constants } from "../../helpers/constants";
import { openDialog } from "../../actions/appActions";
import { useDispatch, useSelector } from "react-redux";
import { getUserSettings } from "../../actions/userDetailsAction";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "20px",
    width: "100%",
    padding: "10px",
    textAlign: "center",
  },
  uploadSection: {
    margin: theme.spacing(2),
    height: "96px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(2),
    width: "-webkit-fill-available",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
    width: "inherit",
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
    justifyContent: "space-between",
    width: "95%",
  },
  preview: {
    marginTop: theme.spacing(1),
    display: "table",
    margin: "0 auto",
    height: "300px",
  },
}));

const GalleryUpload = ({ match, userSettings }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  var history = useHistory();
  const [type, setType] = useState("");
  const [currentId, setCurrentId] = useState(0);
  const [galleries, setGalleries] = useState([]);
  const [previews, setPreviews] = useState({});
  const [formData, setFormData] = useState({});
  const [progress, setProgress] = useState({});
  const [galleryType, setGalleryType] = useState({});
  const [alert, setAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [authToken, setAuthToken] = useState(localStorage.getItem("jwt"));
  const formDataRef = useRef();
  const galleriesRef = useRef();
  const previewsRef = useRef();
  const progressRef = useRef();
  const galleryTypeRef = useRef();
  const canvasRef = useRef();
  const videoRef = useRef();

  formDataRef.current = formData;
  galleriesRef.current = galleries;
  previewsRef.current = previews;
  progressRef.current = progress;
  galleryTypeRef.current = galleryType;
  videoRef.current = {};
  canvasRef.current = {};

  useEffect(() => {
    setType(match.params.type);
    dispatch(getUserSettings());
  }, []);

  const onSubmit = (id, name) => {
    let uuid = uploader.methods.getUuid(id);

    setGalleries([...galleriesRef.current, uuid]);
    let file = uploader.methods.getFile(id);
    if (file.type.substr(0, 5) === "image") {
      setGalleryType({ ...galleryTypeRef.current, [uuid]: "photos" });
    } else if (file.type.substr(0, 5) === "video") {
      setGalleryType({ ...galleryTypeRef.current, [uuid]: "videos" });
    }
    setPreviews({
      ...previewsRef.current,
      [uuid]: URL.createObjectURL(file),
    });
  };

  const onValidate = ({ name, size }) => {
    if (
      ![
        ".jpeg",
        ".jpg",
        ".png",
        ".mp4",
        ".mpeg",
        ".webm",
        ".mkv",
        ".flv",
        ".ogg",
        ".ogv",
        ".m4v",
        ".m4p",
        ".3gp",
      ].includes(name.substr(name.lastIndexOf(".")).toLowerCase()) ||
      size >= 10485760
    ) {
      dispatch(
        openDialog(
          `Some files were not uploaded. Please upload images or videos under 10MB in size. File formats supported are -
        .jpeg, .jpg, .png, .mp4, .mpeg, .webm,  .mkv, .flv, .ogg, .ogv, .m4v, .m4p, .3gp`
        )
      );
      // setAlert(true);
    }
  };

  const onUpload = (id, name) => {
    let uuid = uploader.methods.getUuid(id);
    let params = formDataRef.current[uuid];
    uploader.methods.setEndpoint(
      getURL("GALLERY_UPLOAD") +
        "/" +
        galleryTypeRef.current[uuid] +
        "/" +
        type,
      id
    );
    uploader.methods.setParams(params, id);
  };

  const onProgress = (id, name, uploaded, total) => {
    let uuid = uploader.methods.getUuid(id);
    if (!(progressRef.current === "Cancelled")) {
      setProgress({
        ...progressRef.current,
        [uuid]: { percent: (uploaded / total) * 100, msg: "Uploading..." },
      });
    } else {
      uploader.methods.clearStoredFiles();
      uploader.methods.cancelAll();
    }
  };

  const onTotalProgress = (uploaded, total) => {
    if (progressRef.current === "Cancelled") {
      uploader.methods.clearStoredFiles();
      uploader.methods.cancelAll();
    }
  };

  const onComplete = (id, name, res) => {
    let uuid = uploader.methods.getUuid(id);
    if (res.error) {
      setProgress({
        ...progressRef.current,
        [uuid]: { percent: 0.0001, msg: res.errors.msg || "Failed" },
      });
      dispatch(openDialog(res.errors.msg || "Failed"));
      // setAlert(true);
    } else {
      setProgress({
        ...progressRef.current,
        [uuid]: { percent: 100, msg: "Uploaded." },
      });
    }
  };

  const onAllComplete = (succeded, failed) => {
    setProgress({ ...progressRef.current, completed: true });
    history.push("/profile-me/" + type);
  };

  useEffect(() => {
    let uuid = galleries[galleries.length - 1];
    uuid &&
      setFormData({
        ...formData,
        [uuid]: {
          galleryQuotation: "",
          allowOpenComments: false,
          allowHiddenComments: false,
          whoCanOpenComment: "AnyOne",
          whoCanHiddenComment: "AnyOne",
        },
      });
  }, [galleries]);

  useEffect(() => {
    setAuthToken(localStorage.getItem("jwt"));
  }, []);

  const handleChange = (event, newValue) => {
    setCurrentId(newValue);
  };

  const handleCancelAll = (e) => {
    setProgress("Cancelled");
  };

  const videoItem = (src, uuid) => {
    return (
      <ReactPlayer
        ref={(rp) => (videoRef.current[uuid] = rp)}
        url={src}
        playing={false}
        controls
        width="100%"
        height="300px"
        style={{
          height: "300px",
          minHeight: "auto",
          maxHeight: "300px",
        }}
        config={{ attributes: { id: uuid } }}
      />
    );
  };

  const uploader = new FineUploaderTraditional({
    options: {
      form: {
        element: document.getElementById("upload"),
        autoUpload: false,
      },
      // debug: true,
      autoUpload: false,
      requireSuccessJson: false,
      maxConnections: 5,
      validation: {
        allowedExtensions: [
          "jpeg",
          "jpg",
          "png",
          "mp4",
          "mpeg",
          "webm",
          "mkv",
          "flv",
          "ogg",
          "ogv",
          "m4v",
          "m4p",
          "3gp",
        ],
        sizeLimit: 10485760,
        stopOnFirstInvalidFile: false,
      },
      request: {
        customHeaders: {
          Authorization: authToken,
        },
        inputName: "media",
      },
      callbacks: {
        onSubmit,
        onValidate,
        onUpload,
        onProgress,
        onComplete,
        onAllComplete,
        onTotalProgress,
      },
    },
  });

  const handleSetSame = (i, checked) => {
    if (!checked) {
      let formdata = formDataRef.current;
      Object.keys(formdata).map((item, index) => {
        if (item !== galleriesRef.current[i]) {
          formData[item].allowOpenComments = false;
          formData[item].allowHiddenComments = false;
          formData[item].whoCanOpenComment = "AnyOne";
          formData[item].whoCanHiddenComment = "AnyOne";
        }
      });
      setFormData(formData);
    } else {
      let newdata = formDataRef.current[galleriesRef.current[i]];
      let formdata = formDataRef.current;
      Object.keys(formdata).map((item) => {
        formData[item].allowOpenComments = newdata.allowOpenComments;
        formData[item].allowHiddenComments = newdata.allowHiddenComments;
        formData[item].whoCanOpenComment = newdata.whoCanOpenComment;
        formData[item].whoCanHiddenComment = newdata.whoCanHiddenComment;
      });
      setFormData(formData);
    }
  };

  return (
    <Paper elevation={0} className={classes.root}>
      {/* {alert && (
        <AlertDialog
          open={alert}
          handleClose={() => {
            setAlert(false);
            // handleClose();
          }}
          text={alertText}
        />
      )} */}
      {(galleries.length === 0 ||
        progress === "Cancelled" ||
        progressRef.current.completed ||
        Object.keys(progress).length === 0) && (
        <>
          <Button
            onClick={() => history.push("/profile-me/" + type)}
            variant="contained"
            color="secondary"
            size="small"
            style={{ float: "Right", margin: "8px", textTransform: "none" }}
          >
            Back
          </Button>
        </>
      )}
      <Typography
        variant="h4"
        color="textPrimary"
        gutterBottom
        style={{ marginRight: "10%", textTransform: "capitalize" }}
      >
        Upload <b>{type}</b> gallery
      </Typography>

      <Divider variant="fullWidth" />
      <form id="upload">
        <div
          className={classes.uploadSection}
          style={{ display: galleries.length === 0 ? "block" : "none" }}
        >
          <Gallery
            uploader={uploader}
            dropzone-style={{ height: "90px", minHeight: "0" }}
          />
          <Typography variant="body1" color="textSecondary">
            Only 5 galleries must be uploaded concurrently.
          </Typography>
        </div>
        {(progressRef.current.completed ||
          progressRef.current === "Cancelled") && (
          <Typography variant="h5" style={{ color: constants.PRIMARY_COLOR }}>
            Done.
          </Typography>
        )}
        <Divider variant="fullWidth" />
        <Tabs
          TabIndicatorProps={{ className: classes.tabIndicator }}
          value={currentId}
          onChange={handleChange}
          textColor="inherit"
          variant="scrollable"
          scrollButtons="auto"
        >
          {galleries.length > 0 &&
            galleries.map((item, index) => (
              <Tab disableRipple label={index + 1} />
            ))}
        </Tabs>
        {galleries.length > 0 && progress[galleries[currentId]] && (
          <>
            <Box display="flex" alignItems="center">
              <Box width="100%" mr={1}>
                <CircularProgress
                  variant="determinate"
                  color="primary"
                  value={progress[galleries[currentId]].percent}
                />
                <Box minWidth={35}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  >{`${Math.round(
                    progress[galleries[currentId]].percent
                  )}%`}</Typography>
                </Box>
              </Box>
            </Box>
            <Typography variant="h6" color="secondary">
              {progress[galleries[currentId]].msg || "Failed"}
            </Typography>
          </>
        )}
        {galleries.length > 0 &&
          galleriesRef.current.map((item) =>
            galleryTypeRef.current[item] === "photos" ? (
              <div
                className={classes.preview}
                style={{
                  display: galleries[currentId] === item ? "block" : "none",
                }}
              >
                <img
                  id="preview"
                  src={previewsRef.current[item]}
                  style={{ height: "inherit" }}
                />
              </div>
            ) : (
              <div
                className={classes.preview}
                style={{
                  display: galleries[currentId] === item ? "block" : "none",
                }}
              >
                {videoItem(previewsRef.current[item], item)}
                <canvas
                  ref={(c) => (canvasRef.current[item] = c)}
                  style={{ display: "none" }}
                ></canvas>
              </div>
            )
          )}

        {galleries.length > 0 &&
          formData[galleries[currentId]] &&
          progressRef.current !== "Cancelled" &&
          !progressRef.current.completed &&
          galleries.map((item, index) => (
            <UploadForm
              index={index}
              currentId={currentId}
              classes={classes}
              userSettings={userSettings}
              quotation={formData[item] && formData[item].galleryQuotation}
              allowOpen={formData[item] && formData[item].allowOpenComments}
              allowHidden={formData[item] && formData[item].allowHiddenComments}
              whoOpen={formData[item] && formData[item].whoCanOpenComment}
              whoHidden={formData[item] && formData[item].whoCanHiddenComment}
              setQuotation={(value) =>
                setFormData({
                  ...formData,
                  [item]: { ...formData[item], galleryQuotation: value },
                })
              }
              setAllowOpen={(value) =>
                setFormData({
                  ...formData,
                  [item]: { ...formData[item], allowOpenComments: value },
                })
              }
              setAllowHidden={(value) =>
                setFormData({
                  ...formData,
                  [item]: { ...formData[item], allowHiddenComments: value },
                })
              }
              setWhoOpen={(value) =>
                setFormData({
                  ...formData,
                  [item]: { ...formData[item], whoCanOpenComment: value },
                })
              }
              setWhoHidden={(value) =>
                setFormData({
                  ...formData,
                  [item]: { ...formData[item], whoCanHiddenComment: value },
                })
              }
              length={galleries.length}
              type={type}
              setSame={(i, checked) => handleSetSame(i, checked)}
            />
          ))}

        {galleries.length > 0 &&
        progressRef.current &&
        progressRef.current !== "Cancelled" &&
        !progressRef.current.completed ? (
          galleriesRef.current.length ===
          Object.keys(progressRef.current).length ? (
            <>
              <Divider variant="fullWidth" />
              <Button
                onClick={(e) => handleCancelAll(e)}
                variant="contained"
                color="secondary"
                style={{ margin: "12px", textTransform: "none" }}
              >
                Cancel Ongoing Uploads
              </Button>
            </>
          ) : (
            <>
              <Divider variant="fullWidth" />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ margin: "12px", textTransform: "none" }}
              >
                Upload
              </Button>
            </>
          )
        ) : null}
      </form>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    userSettings: state.userDetailsReducer.userSettings,
  };
};

export default withRouter(connect(mapStateToProps)(GalleryUpload));
