import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { TextField, withStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Redirect } from "react-router-dom";
import withRouter from "react-router-dom/es/withRouter";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

import { getAddableUsersByKeyword } from "../../actions/applicantsDetailsActions";

const SearchField = ({ handleGetKeyword }) => {
  const [keyword, setKeyword] = useState("");
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    handleGetKeyword(keyword);
    // if (keyword.length > 0) {
    //   let listType = setting === "whoCanChat" ? "chat" : "privateShare";
    //   const params = { key: keyword, page: 0, limit: 10, listType: listType };
    //   getAddableUsersByKeyword(params).then((res) => {
    //     if (res) {
    //       setUsersList(res.data);
    //       handleSetList();
    //     }
    //   });
    // }
  }, [keyword.length]);

  const handleOnType = (e) => {
    setKeyword(e.target.value);
  };

  return (
    <React.Fragment>
      <TextField
        style={{ color: "white" }}
        margin="normal"
        variant="outlined"
        autoFocus
        fullWidth
        inputValue={keyword}
        onChange={(e) => handleOnType(e)}
        placeholder="Search Users"
        InputProps={{
          type: "search",
          // className: classes.autocompleteField,
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          ),
        }}
      />
    </React.Fragment>
  );
};

const styles = {
  autocomplete: {
    width: "40%",
  },
  autocompleteField: {
    background: "white",
  },
};

export default withRouter(withStyles(styles)(SearchField));
