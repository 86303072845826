import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Typography,
  FormLabel,
  Divider,
  Popper,
  FormHelperText,
} from "@material-ui/core";
import {
  requestCountries,
  requestStates,
  requestDistricts,
  requestIndustries,
} from "../../actions/registerActions";
import { openDialog } from "../../actions/appActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { connect } from "react-redux";
import withRouter from "react-router-dom/es/withRouter";
import { updateProfile } from "../../actions/appActions";
import { useHistory } from "react-router-dom";
import { constants } from "../../helpers/constants";
import { getRoles } from "../../actions/userDetailsAction";
import SimplePopover from "../SimplePopover";

import { makeStyles } from "@material-ui/core/styles";
import { TextAreaInput } from "../InputFields";

const useStyles = makeStyles((theme) => ({
  infoIcon: {
    position: "absolute",
    right: "-25px",
    top: "5px",
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const EditProfileForm = ({
  userDetail = {},
  roles,
  countriesList,
  statesList,
  districtsList,
  industriesList,
  dispatch,
}) => {
  const classes = useStyles();

  const [username, setUsername] = useState(userDetail.username || "");
  const [firstname, setFirstname] = useState(userDetail.firstname || "");
  const [lastname, setLastname] = useState(userDetail.lastname || "");
  const [userBio, setUserBio] = useState(userDetail.userBio || "");
  const [houseno, setHouseno] = useState(userDetail.houseno || "");
  const [street, setStreet] = useState(userDetail.street || "");
  const [city, setCity] = useState(userDetail.city || "");
  const [pincode, setPincode] = useState(userDetail.pincode || "");
  const [state, setState] = useState(userDetail.state || "");
  const [country, setCountry] = useState(userDetail.country || "");
  const [industry, setIndustry] = useState(userDetail.industry || "");
  const [district, setDistrict] = useState(userDetail.district || "");
  // const [bidAlias, setBidAlias] = useState(
  //   (userDetail && userDetail.bidAlias) || ""
  // );

  const [managerName, setManagerName] = useState(userDetail.managerName || "");
  const [managerMobile, setManagerMobile] = useState(
    userDetail.managerMobile || ""
  );
  const [subs, setSubs] = useState([]);

  const [mainRole, setMailRole] = useState(userDetail.mainrole || "");
  const [weight, setWeight] = useState(userDetail.weight || "");
  const [feet, setFeet] = useState(userDetail.heightfoot || "");
  const [passport, setPassport] = useState(
    userDetail.passport === "true" || false
  );
  const [inches, setInches] = useState(userDetail.heightinches || "");
  const [education, setEducation] = useState(userDetail.education || "");
  const [link1Title, setLink1Title] = useState(userDetail.link1_title || "");
  const [link1Url, setLink1Url] = useState(userDetail.link1_url || "");
  const [link2Title, setLink2Title] = useState(userDetail.link2_title || "");
  const [link2Url, setLink2Url] = useState(userDetail.link2_url || "");
  const [link3Title, setLink3Title] = useState(userDetail.link3_title || "");
  const [link3Url, setLink3Url] = useState(userDetail.link3_url || "");
  const [link4Title, setLink4Title] = useState(userDetail.link4_title || "");
  const [link4Url, setLink4Url] = useState(userDetail.link4_url || "");
  const [companyName, setCompanyName] = useState(userDetail.bnr || "");
  const [otherRoles, setOtherRole] = useState(userDetail.otherroles || []);
  const [waist, setWaist] = useState(userDetail.waist || "");
  const [hips, setHips] = useState(userDetail.hips || "");
  const [chest, setChest] = useState(userDetail.chest || "");

  var history = useHistory();

  useEffect(() => {
    dispatch(getRoles());
    dispatch(requestCountries());
    dispatch(requestStates(country));
    dispatch(requestIndustries());
    dispatch(requestDistricts(state));
  }, [dispatch, country, state]);

  const getAllSubstrings = (str, size) => {
    var i,
      j,
      result = [];
    size = size || 0;
    for (i = 0; i < str.length; i++) {
      for (j = str.length; j - i >= size; j--) {
        result.push(str.slice(i, j));
      }
    }
    return result;
  };

  useEffect(() => {
    var arr = getAllSubstrings(userDetail && userDetail.username, 3);
    setSubs(arr);
  }, [userDetail, username]);

  // const handleEdit = (params) => {
  //   return dispatch(updateProfile(params));
  // };

  const isJobSeeker =
    userDetail &&
    userDetail.baseUserType &&
    userDetail.baseUserType === "JobSeeker";
  const isEmployer =
    userDetail &&
    userDetail.baseUserType &&
    userDetail.baseUserType === "Employer";
  const isSocial =
    userDetail &&
    userDetail.baseUserType &&
    userDetail.baseUserType === "SocialMediaUser";
  const isFemale =
    userDetail && userDetail.gender && userDetail.gender === "Female";

  const handleOnSubmit = () => {
    if (isEmployer && companyName === "") {
      return dispatch(openDialog("Production house can't be empty."));
    }

    if (isJobSeeker || isEmployer) {
      if (firstname === "" || lastname === "") {
        return dispatch(openDialog("Firstname, lastname can't be empty."));
      }
    }

    if (isJobSeeker || isEmployer) {
      if (country === "India" && industry === "") {
        return dispatch(openDialog("Industry can't be empty for Indians"));
      }
    }

    if (isJobSeeker && mainRole === "") {
      return dispatch(openDialog("Main role can't be empty"));
    }

    if (isJobSeeker && otherRoles.length === 0) {
      return dispatch(openDialog("Other roles can't be empty"));
    }

    // if (isJobSeeker || isEmployer) {
    if (country === "India") {
      if (
        houseno === "" ||
        street === "" ||
        city === "" ||
        district === "" ||
        pincode === "" ||
        state === ""
      ) {
        return dispatch(
          openDialog(
            "Address fields Houseno, street, city, district, state, pincode can't be empty"
          )
        );
      }
    } else {
      if (
        houseno === "" ||
        street === "" ||
        city === "" ||
        pincode === "" ||
        state === ""
      ) {
        return dispatch(
          openDialog(
            "Address fields Houseno, street, city, state, pincode can't be empty"
          )
        );
      }
      // }
    }

    if (Number(inches) > 12) {
      return dispatch(openDialog("height in inches cannot be greater than 12"));
    }
    if (Number(feet) > 9) {
      return dispatch(openDialog("height in feet cannot be greater than 12"));
    }
    // if (bidAlias.length < 3) {
    //   return dispatch(
    //     openDialog(
    //       "Bid username is mandatory and mininum bid username length is 3"
    //     )
    //   );
    // }
    // if (new RegExp(subs.join("|")).test(bidAlias)) {
    //   return dispatch(
    //     openDialog(
    //       "Bid username can’t contain any 3 consecutive characters of the login user name."
    //     )
    //   );
    // }

    const params = {
      education,
      mainrole: mainRole,
      link1_title: link1Title,
      link1_url: link1Url,
      link2_title: link2Title,
      link2_url: link2Url,
      link3_title: link3Title,
      link3_url: link3Url,
      link4_title: link4Title,
      link4_url: link4Url,
      heightfoot: feet,
      heightinches: inches,
      weight,
      managerMobile,
      managerName,
      bnr: companyName,
      otherroles: otherRoles.filter((or) => or !== mainRole),
      waist,
      hips,
      chest,
      passport,
      username,
      firstname,
      // bidAlias,
      lastname,
      userBio,
      houseno,
      street,
      city,
      pincode,
      country,
      state,
      industry,
      district,
    };
    dispatch(updateProfile(params)).then((res) => {
      !res.error && history.push("/profile-me");
    });
  };

  const PopperMy = function (props) {
    return (
      <Popper {...props} style={{ width: 264 }} placement="bottom-start" />
    );
  };

  const getFilteredRoles_ForOtherRoles = () => {
    if (isJobSeeker)
      return roles.filter(
        (role) =>
          role &&
          role.toLowerCase() !== "model" &&
          role.toLowerCase() !== "actor" &&
          role.toLowerCase() !== "audience" &&
          role.toLowerCase() !== "people"
      );
    return roles;
  };

  const getFilteredRoles_ForMainRole = () => {
    if (isJobSeeker)
      return roles.filter(
        (role) =>
          role &&
          role.toLowerCase() !== "audience" &&
          role.toLowerCase() !== "people"
      );

    return roles;
  };

  return (
    <div
      style={{
        height: "95vh",
        overflow: "scroll",
      }}
    >
      <Grid
        container
        style={{ marginTop: 10 }}
        justify="center"
        alignItems="center"
      >
        <Grid container xs={12} justify="space-evenly" alignItems="center">
          <Grid
            container
            style={{ marginTop: 20 }}
            xs={12}
            justiy="space-between"
            align="center"
          >
            <Grid item xs={11}>
              <Typography
                component="h1"
                variant="h5"
                display="block"
                style={{
                  alignItems: "center",
                  textTransform: "none",
                }}
              >
                Edit Profile
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Button
                size="small"
                variant="contained"
                style={{
                  textTransform: "none",
                }}
                onClick={(e) => history.goBack()}
                color="secondary"
              >
                Back
              </Button>
            </Grid>
          </Grid>
          <Divider light variant="middle" />

          <Grid container justify="space-evenly" alignItems="center" xs={10} spacing={2}>
            <Grid container spacing={2} xs={5}>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                >
                  User Name*
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  required
                  fullWidth
                  // label="User Name"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Grid>

              <Grid item xs={5}>
                <Typography
                  style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                >
                  First Name*
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  required
                  fullWidth
                  // label="First Name"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                />
              </Grid>

              <Grid item xs={5}>
                <Typography
                  style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                >
                  Last Name*
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  required
                  fullWidth
                  // label="Last Name"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
              >
                Bio
              </Typography>
              <TextAreaInput
                rows={5}
                cols={20}
                maxlength={150}
                className={"form-control"}
                style={{ resize: "none" }}
                onChange={(e) => setUserBio(e.target.value)}
                value={userBio}
              />
              <Typography
                variant="caption"
                style={{ margin: "2px", color: constants.PRIMARY_COLOR }}
              >
                ({userBio.length}/150)
              </Typography>
            </Grid>
            {/* <Grid item xs={5}>
              <Typography
                style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
              >
                Bid Alias Name*
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                required
                fullWidth
                // label="Company Name"
                value={bidAlias}
                onChange={(e) => setBidAlias(e.target.value)}
              />
              </Grid>*/}
            <Grid container xs={5}>
              {isEmployer && (
                <>
                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                    >
                      Production House*
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      fullWidth
                      // label="Production House"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container xs={5}>
              {(isJobSeeker || isEmployer) && (
                <>
                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                    >
                      Manager Name
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      fullWidth
                      // label="Manager Name"
                      name="managerName"
                      value={managerName}
                      onChange={(e) => setManagerName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                    >
                      Manager Mb No
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      fullWidth
                      // label="Manager Mb No"
                      name="managerMobile"
                      value={managerMobile}
                      onChange={(e) => setManagerMobile(e.target.value)}
                    />
                  </Grid>

                  {isJobSeeker && (
                    <>
                      <Grid item xs={5}>
                        <Typography
                          style={{
                            color: constants.PRIMARY_COLOR,
                            margin: "8px",
                          }}
                        >
                          Main Role*
                        </Typography>
                      </Grid>
                      <Grid item style={{ position: "relative" }} xs={7}>
                        <Autocomplete
                          options={getFilteredRoles_ForMainRole()}
                          getOptionLabel={(option) => option}
                          onChange={(e, object) => setMailRole(object)}
                          value={mainRole}
                          PopperComponent={PopperMy}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // label="Main Role"
                              fullWidth
                              required
                            />
                          )}
                        />
                        <SimplePopover rootClass={classes.infoIcon}>
                          <ul>
                            <li>
                              <Typography>
                                Every opportunity seeker has to select one main
                                role, and three other roles. Celebbook wants to
                                separate actors and models. Generally models are
                                good looking with perfect vitals. A good looking
                                male/female has to choose their main role as
                                model. Those who choose model as their main
                                role, they can't select actor as other role.
                                Also vice versa.
                              </Typography>
                            </li>
                            <li>
                              <Typography>
                                Director selects models for heroine,actors for
                                mother, aunt characters.
                              </Typography>
                            </li>
                          </ul>
                        </SimplePopover>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          style={{
                            color: constants.PRIMARY_COLOR,
                            margin: "8px",
                          }}
                        >
                          Other Roles*
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Autocomplete
                          multiple
                          disabled={mainRole === ""}
                          options={getFilteredRoles_ForOtherRoles()}
                          disableCloseOnSelect={otherRoles.length < 2}
                          getOptionLabel={(option) => option}
                          onChange={(e, object) =>
                            setOtherRole(object.slice(-3))
                          }
                          value={
                            mainRole
                              ? otherRoles.filter((or) => or !== mainRole)
                              : otherRoles
                          }
                          PopperComponent={PopperMy}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </React.Fragment>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // label="Other Roles"
                              placeholder="Other Roles"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
            <Grid container xs={5}>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                >
                  Education
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <FormControl fullWidth required>
                  {/* <InputLabel>Education</InputLabel> */}
                  <Select
                    value={education}
                    onChange={(e) => setEducation(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"Graduation"}>Graduation</MenuItem>
                    <MenuItem value={"Post Graduation"}>
                      Post Graduation
                    </MenuItem>
                    <MenuItem value={"10th Grade"}>10th Grade</MenuItem>
                    <MenuItem value={"Below 10th Grade"}>
                      Below 10th Grade
                    </MenuItem>
                    <MenuItem value={"10+2"}>10+2</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container xs={5}>
              {(isJobSeeker || isEmployer) && (
                <>
                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                    >
                      Weight
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      fullWidth
                      // label="Weight"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">Kg</InputAdornment>
                        ),
                      }}
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                    >
                      Height
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <TextField
                          required
                          fullWidth
                          // label="Height"
                          // InputProps={{
                          //   startAdornment: (
                          //     <InputAdornment position="start">Feet</InputAdornment>
                          //   ),
                          // }}
                          error={Number(feet) > 9}
                          helperText={
                            Number(feet) > 9
                              ? "feet cannot be greater than 9"
                              : ""
                          }
                          value={feet}
                          onChange={(e) => setFeet(e.target.value)}
                        />
                        <FormHelperText id="standard-weight-helper-text">
                          Feet
                        </FormHelperText>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          fullWidth
                          // label="Height"
                          // InputProps={{
                          //   startAdornment: (
                          //     <InputAdornment position="start">Inches</InputAdornment>
                          //   ),
                          // }}
                          error={Number(inches) > 12}
                          helperText={
                            Number(inches) > 12
                              ? "inches cannot be greater than 12"
                              : ""
                          }
                          value={inches}
                          onChange={(e) => setInches(e.target.value)}
                        />
                        <FormHelperText id="standard-weight-helper-text">
                          Inches
                        </FormHelperText>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container xs={5}>
              {isFemale && (isJobSeeker || isEmployer) && (
                <>
                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                    >
                      Chest
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      fullWidth
                      // label="Chest"
                      value={chest}
                      onChange={(e) =>
                        Number(e.target.value) < 100 && setChest(e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                    >
                      Waist
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      fullWidth
                      // label="Waist"
                      value={waist}
                      onChange={(e) =>
                        Number(e.target.value) < 100 && setWaist(e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                    >
                      Hip
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      fullWidth
                      // label="Hips"
                      value={hips}
                      onChange={(e) =>
                        Number(e.target.value) < 100 && setHips(e.target.value)
                      }
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container xs={5}>
              {isJobSeeker && (
                <>
                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                    >
                      Passport
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={passport}
                          onChange={(e) => setPassport(!passport)}
                        />
                      }
                      // label="Passport"
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container xs={5}>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                >
                  House Number*
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  required
                  fullWidth
                  // label="House Number"
                  value={houseno}
                  onChange={(e) => setHouseno(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container xs={5}>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                >
                  Street*
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  required
                  fullWidth
                  // label="Street"
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container xs={5}>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                >
                  City*
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  required
                  fullWidth
                  // label="City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container xs={5}>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                >
                  Country*
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Autocomplete
                  options={countriesList}
                  getOptionLabel={(option) => option}
                  onChange={(e, object) => {
                    setCountry(object);
                    setState("");
                  }}
                  value={country}
                  PopperComponent={PopperMy}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={country}
                      // label="Select Country"
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container xs={5}>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                >
                  Pincode*
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  required
                  fullWidth
                  // label="City"
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container xs={5}>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                >
                  State*
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Autocomplete
                  options={statesList}
                  getOptionLabel={(option) => option}
                  onChange={(e, object) => setState(object)}
                  value={state}
                  disabled={!Boolean(country || country !== "")}
                  PopperComponent={PopperMy}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={state}
                      // label="Select Country"
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
            </Grid>
            
              {country === "India" && (
                <Grid container xs={5}>
                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                    >
                      District*
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Autocomplete
                      options={districtsList}
                      getOptionLabel={(option) => option}
                      onChange={(e, object) => setDistrict(object)}
                      disabled={country !== "India"}
                      value={district}
                      PopperComponent={PopperMy}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={district}
                          disabled={country !== "India"}
                          // label="Select Country"
                          fullWidth
                          required
                        />
                      )}
                    />
                  </Grid>
                    </Grid>)}
             
              {country === "India" && (
                    <Grid container xs={5}>
                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                    >
                      Industry*
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Autocomplete
                      options={industriesList}
                      getOptionLabel={(option) => option}
                      onChange={(e, object) => setIndustry(object)}
                      disabled={country !== "India"}
                      value={industry}
                      PopperComponent={PopperMy}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={industry}
                          disabled={country !== "India"}
                          // label="Select Country"
                          fullWidth
                          required
                        />
                      )}
                    />
                  </Grid>
                  </Grid>   )}
            

            {/* {(isJobSeeker || isEmployer) && (
              <> */}
            <Grid container xs={5}>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                >
                  Link 1 Title
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  name="link1Title"
                  // label="Link 1 Title"
                  value={link1Title}
                  onChange={(e) => setLink1Title(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container xs={5}>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                >
                  Link 1 URL
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  name="link1Url"
                  // label="Link 1 URL"
                  value={link1Url}
                  onChange={(e) => setLink1Url(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container xs={5}>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                >
                  Link 2 Title
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  name="link2Title"
                  // label="Link 2 Title"
                  value={link2Title}
                  onChange={(e) => setLink2Title(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container xs={5}>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                >
                  Link 2 URL
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  name="link2Url"
                  // label="Link 2 URL"
                  value={link2Url}
                  onChange={(e) => setLink2Url(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container xs={5}>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                >
                  Link 3 Title
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  name="link3Title"
                  // label="Link 3 Title"
                  value={link3Title}
                  onChange={(e) => setLink3Title(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container xs={5}>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                >
                  Link 3 URL
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  name="link3Url"
                  // label="Link3 URL"
                  value={link3Url}
                  onChange={(e) => setLink3Url(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container xs={5}>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                >
                  Link 4 Title
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  name="link4Title"
                  // label="Link4 Title"
                  value={link4Title}
                  onChange={(e) => setLink4Title(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container xs={5}>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                >
                  Link 4 URL
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  name="link4Url"
                  // label="Link 4 URL"
                  value={link4Url}
                  onChange={(e) => setLink4Url(e.target.value)}
                />
              </Grid>
            </Grid>
            {/* </> 
            )} */}
          </Grid>
          <Grid item xs={12} align="center">
            <Divider light variant="middle" />

            <Button
              variant="contained"
              type="submit"
              size="large"
              style={{ margin: "16px", textTransform: "none" }}
              onClick={handleOnSubmit}
              color="primary"
            >
              Update
            </Button>
            <Button
              variant="contained"
              type="submit"
              size="large"
              style={{ margin: "16px", textTransform: "none" }}
              onClick={(e) => history.goBack()}
              color="secondary"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = ({
  rolesReducer,
  userDetailsReducer,
  registerReducer,
}) => ({
  roles:
    (rolesReducer &&
      rolesReducer.roles &&
      rolesReducer.roles.map((obj) => obj.role)) ||
    [],
  userDetail: userDetailsReducer.userDetail,
  countriesList:
    (registerReducer &&
      registerReducer.countries &&
      registerReducer.countries.map((obj) => obj.country)) ||
    [],
  statesList: registerReducer.states,
  districtsList:
    (registerReducer &&
      registerReducer.districts &&
      registerReducer.districts.map((obj) => obj.district)) ||
    [],
  industriesList:
    (registerReducer &&
      registerReducer.industries &&
      registerReducer.industries.map((obj) => obj.industry)) ||
    [],
});

export default connect(mapStateToProps)(withRouter(EditProfileForm));
