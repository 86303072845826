import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(0),
    display: "flex",
    alignItems: "center",
  },
  paper: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1),
    alignItems: "center",
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverpaper: {
    padding: theme.spacing(1),
    width:350

  },
}));

export default useStyles;
