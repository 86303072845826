import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { connect } from "react-redux";
import { changePassword } from "../../actions/loginActions";

import AlertDialog from "../AlertDialog";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    width: "50%",
    flex: "1 1 auto",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PassWordChange = ({ open, handleClose, dispatch, history }) => {
  const classes = useStyles();
  const [currentPass, setCurrentPass] = useState(null);
  const [newPass, setNewPass] = useState(null);
  const [confirmPass, setConfirmPass] = useState(null);
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState(false);
  const [alertText, setAlertText] = useState("");

  const handleChangeCurrent = (e) => {
    setErrors({ ...errors, currentPass: null });
    setCurrentPass(e.target.value);
  };
  const handleChangeNew = (e) => {
    setErrors({ ...errors, newPass: null });
    setNewPass(e.target.value);
  };
  const handleChangeConfirm = (e) => {
    setErrors({ ...errors, confirmPass: null });
    setConfirmPass(e.target.value);
  };

  useEffect(() => {
    if (currentPass) {
      if (currentPass === "") {
        setErrors({ ...errors, currentPass: "Cannot be empty" });
      } else if (currentPass.length < 6) {
        setErrors({
          ...errors,
          currentPass: "Password has to be longer than 5 characters",
        });
      }
    }
    if (newPass) {
      if (newPass === "") {
        setErrors({ ...errors, newPass: "Cannot be empty" });
      } else if (newPass.length < 6) {
        setErrors({
          ...errors,
          newPass: "Password has to be longer than 5 characters",
        });
      }
    }
    if (confirmPass) {
      if (confirmPass === "") {
        setErrors({ ...errors, confirmPass: "Cannot be empty" });
      } else if (confirmPass.length < 6) {
        setErrors({
          ...errors,
          confirmPass: "Password has to be longer than 5 characters",
        });
      } else if (newPass !== confirmPass) {
        setErrors({ ...errors, confirmPass: "Passwords do not match" });
      }
    }
  }, [confirmPass, newPass, currentPass]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (currentPass !== "" && newPass !== "" && confirmPass !== "") {
      dispatch(
        changePassword(
          {
            oldPassword: currentPass,
            password: newPass,
            rePassword: confirmPass,
          },
          history
        )
      );
      // .then((result) => {
      //   setConfirmPass("");
      //   setNewPass("");
      //   setCurrentPass("");
      //   if (result.error) {
      //     setAlertText(result.msg);
      //     setAlert(true);
      //   } else {
      //     setAlertText(result.msg);
      //     setAlert(true);
      //     logout();
      //   }
      // });
    }
  };

  return (
    <div>
      {alert && (
        <AlertDialog
          open={alert}
          handleClose={() => {
            setAlert(false);
            handleClose();
          }}
          text={alertText}
        />
      )}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Change Password
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={handleClose}
              style={{ textTransform: "none" }}
            >
              Done
            </Button>
          </Toolbar>
        </AppBar>
        <Paper
          style={{ margin: "32px" }}
          className={classes.form}
          elevation={10}
        >
          <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
            <FormControl fullWidth margin="dense">
              <InputLabel
                htmlFor="password-current"
                error={Boolean(errors.currentPass)}
              >
                {"Current Password"}
              </InputLabel>
              <Input
                id="password-current"
                name="currentPass"
                type="password"
                value={currentPass}
                onChange={handleChangeCurrent}
                error={Boolean(errors.currentPass)}
              />
              <FormHelperText error={Boolean(errors.currentPass)}>
                {errors.currentPass ? errors.currentPass : ""}
              </FormHelperText>
            </FormControl>
            <FormControl
              fullWidth
              margin="dense"
              error={Boolean(errors.newPass)}
            >
              <InputLabel
                htmlFor="password-new"
                error={Boolean(errors.newPass)}
              >
                {"New Password"}
              </InputLabel>
              <Input
                id="password-new"
                name="newPass"
                type="password"
                value={newPass}
                onChange={handleChangeNew}
                error={Boolean(errors.newPass)}
              />
              <FormHelperText error={Boolean(errors.newPass)}>
                {errors.newPass ? errors.newPass : ""}
              </FormHelperText>
            </FormControl>
            <FormControl
              fullWidth
              margin="dense"
              error={Boolean(errors.confirmPass)}
            >
              <InputLabel
                htmlFor="password-confirm"
                error={Boolean(errors.confirmPass)}
              >
                {"Confirm Password"}
              </InputLabel>
              <Input
                id="password-confirm"
                name="confirmPass"
                type="password"
                value={confirmPass}
                onChange={handleChangeConfirm}
                error={Boolean(errors.confirmPass)}
              />
              <FormHelperText error={Boolean(errors.confirmPass)}>
                {errors.confirmPass ? errors.confirmPass : ""}
              </FormHelperText>
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              //   disabled={Boolean(!isValid || isSubmitting)}
              style={{ margin: "16px", textTransform: "none" }}
            >
              {"Reset Password"}
            </Button>
            <Button
              autoFocus
              color="secondary"
              onClick={handleClose}
              style={{ textTransform: "none" }}
            >
              Cancel
            </Button>
          </form>
        </Paper>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(PassWordChange);
