import React from "react";
import { Route, Switch } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import ProfileSideNavCard from "./ProfileSideNavCard";
import ProfileAboutComponent from "./ProfileAboutComponent";
import ProfilePhotosComponent from "./ProfilePhotosComponent";
import ProfileVideosComponent from "./ProfileVideosComponent";
import ProfileDetails from "./ProfileDetails";

export default class ProfileContainer extends React.Component {
  render() {
    const match = this.props.match;

    return (
      <Grid container>
        <Switch>
          <Route path={`${match.url}/`} exact component={ProfileDetails} />
          <Route
            path={`${match.url}/photos`}
            exact
            component={ProfilePhotosComponent}
          />
          <Route
            path={`${match.url}/videos`}
            exact
            component={ProfileVideosComponent}
          />
        </Switch>
      </Grid>
    );
  }
}
