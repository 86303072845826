const initialState = {
  userDetail: null,
  productFeaturesListLoading: false,
  userSubscriptionListLoading: false,
  userSubscriptionList: [],
  upgradeDetailsLoading: true,
  upgradeDetails: null,
  purchaseDetailsLoading: true,
  purchaseDetails: null,
  chooseSubscriptionPlanLoading: false,
  chooseSubscriptionPlanList: [],
  addSubscriptionPlanLoading: false,
  addDonationPlanLoading : false,

  productAllFeatureListLoading: false,
  productAllFeatureList: [],

  razorPaySuccessCaptureLoading: false,
  razorPayData: {},
  accessPages: [],
  userSettings: {},
};

const userDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USER_DETAILS_SUCCESS":
      return {
        ...state,
        userDetail: action.payload.data.data,
      };
    case "UPDATE_USER_DETAILS":
      return {
        ...state,
        userDetail: action.response.data.userDetail,
      };
    case "GET_USER_ACCESS_PAGES_SUCCESS":
      return {
        ...state,
        accessPages: action.payload,
      };
    case "GET_USER_SETTINGS_SUCCESS":
      return {
        ...state,
        userSettings: action.payload,
      };
    case "LOGGED_OUT":
      return {
        ...state,
        userDetail: null,
      };

    // GET PRODUCT FEATURES BY USER TYPE
    case "GET_PRODUCT_FEATURES_BY_TYPE":
      return {
        ...state,
        productFeaturesListLoading: true,
      };
    case "GET_PRODUCT_FEATURES_BY_TYPE_SUCCESS":
      return {
        ...state,
        productFeaturesListLoading: false,
        productFeatureList: action.payload,
      };
    case "GET_PRODUCT_FEATURES_BY_TYPE_FAILED":
      return {
        ...state,
        productFeaturesListLoading: false,
      };

    //GET USERT SUBSCRIPTION LIST
    case "GET_USER_SUBSCRIPTION":
      return {
        ...state,
        userSubscriptionListLoading: true,
      };
    case "GET_USER_SUBSCRIPTION_SUCCESS":
      return {
        ...state,
        userSubscriptionListLoading: false,
        userSubscriptionList: action.payload,
      };
    case "GET_USER_SUBSCRIPTION_FAILED":
      return {
        ...state,
        userSubscriptionListLoading: false,
      };

    //GET UPGRADE INFORMATION
    case "GET_UPGRADE_INFORMATION":
      return {
        ...state,
        upgradeDetailsLoading: true,
      };
    case "GET_UPGRADE_INFORMATION_SUCCESS":
      return {
        ...state,
        upgradeDetailsLoading: false,
        upgradeDetails: action.payload,
      };
    case "GET_UPGRADE_INFORMATION_FAILED":
      return {
        ...state,
        upgradeDetailsLoading: false,
      };

    //GET PURCHASE INFORMATION
    case "GET_PURCHASE_INFORMATION":
      return {
        ...state,
        purchaseDetailsLoading: true,
      };
    case "GET_PURCHASE_INFORMATION_SUCCESS":
      return {
        ...state,
        purchaseDetailsLoading: false,
        purchaseDetails: action.payload,
      };
    case "GET_PURCHASE_INFORMATION_FAILED":
      return {
        ...state,
        purchaseDetailsLoading: false,
      };

    //USER CHOOSE SUBSCRIPTIN PLAN
    case "USER_CHOOSE_SUBSCRIPTION_PLAN":
      return {
        ...state,
        chooseSubscriptionPlanLoading: true,
      };
    case "USER_CHOOSE_SUBSCRIPTION_PLAN_SUCCESS":
      return {
        ...state,
        chooseSubscriptionPlanLoading: false,
        chooseSubscriptionPlanList: action.payload,
      };
    case "USER_CHOOSE_SUBSCRIPTION_PLAN_FAILED":
      return {
        ...state,
        chooseSubscriptionPlanLoading: false,
      };

    //USER ADD SUBSCRIPTIO PLAN

    case "USER_ADD_SUBSCRIPTION_TO_CART":
      return {
        ...state,
        addSubscriptionPlanLoading: true,
      };
    case "USER_ADD_SUBSCRIPTION_TO_CART_SUCCESS":
      return {
        ...state,
        addSubscriptionPlanLoading: false,
        addSubscriptionPlanDetails: action.payload,
      };
    case "USER_ADD_SUBSCRIPTION_TO_CART_FAILED":
      return {
        ...state,
        addSubscriptionPlanLoading: false,
      };

    //USER ADD SUBSCRIPTIO PLAN

    case "USER_ADD_DONATION_TO_CART":
      return {
        ...state,
        addDonationPlanLoading: true,
      };
    case "USER_ADD_DONATION_TO_CART_SUCCESS":
      return {
        ...state,
        addDonationPlanLoading: false,
        addDonationPlanDetails: action.payload,
      };
    case "USER_ADD_DONATION_TO_CART_FAILED":
      return {
        ...state,
        addDonationPlanLoading: false,
      };
    //RAZOR PAY CAPTURE CCAVENUE

    case "USER_RAZORPAY_PAYMENT_CAPTURE":
      return {
        ...state,
        razorPaySuccessCaptureLoading: true,
      };
    case "USER_RAZORPAY_PAYMENT_CAPTURE_SUCCESS":
      return {
        ...state,
        razorPaySuccessCaptureLoading: false,
        razorPayData: action.payload,
      };
    case "USER_RAZORPAY_PAYMENT_CAPTURE_FAILED":
      return {
        ...state,
        razorPaySuccessCaptureLoading: false,
      };
    // GET PRODUCT FEATURES BY List
    case "GET_PRODUCT_FEATURES_LIST":
      return {
        ...state,
        productAllFeatureListLoading: true,
      };
    case "GET_PRODUCT_FEATURES_LIST_SUCCESS":
      return {
        ...state,
        productAllFeatureListLoading: false,
        productAllFeatureList: action.payload,
      };
    case "GET_PRODUCT_FEATURES_LIST_FAILED":
      return {
        ...state,
        productAllFeatureListLoading: false,
      };

    default:
      return state;
  }
};

export default userDetailsReducer;
