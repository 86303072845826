export const openCheckout = (user, amount, order_id, cart_id, callback) => {
  let options = {
    key: process.env.REACT_APP_RPAY_TEST_KEY,
    // key: process.env.REACT_APP_RPAY_LIVE_KEY,
    amount: amount * 100,
    name: "CB WebAPP",
    description: `CB WebAPP Subscription Payment`,
    order_id,
    handler: function (response) {
      callback(response, cart_id);
    },
    prefill: {
      name: user.name,
      email: user.email,
      contact: user.phone,
    },
    notes: {
      address: `${user.houseno},${user.street},${user.city},${user.state},${user.country},${user.pincode}.`,
    },
  };

  let rzp = new window.Razorpay(options);
  rzp.open();
};
