import { createAction } from "redux-actions";
import request from "../helpers/requestEngine";
import request1 from "../helpers/request";
import getURL from "../helpers/apis";
import snackbar from "snackbar";
import { openDialog, closeDialog } from "./appActions";
import { authenticateLoginCredentials } from "./loginActions";

const requestCountriesSuccess = createAction("FETCH_ALL_COUNTRIES_SUCCESS");
const requestCountriesFailure = createAction("FETCH_ALL_COUNTRIES_FAILURE");

export function requestCountries() {
  return (dispatch) =>
    request("get", "GET_COUNTRIES").then(
      (response) => dispatch(requestCountriesSuccess(response)),
      (error) => dispatch(requestCountriesFailure(error))
    );
}

const requestStatesSuccess = createAction("FETCH_STATES_SUCCESS");
const requestStatesFailure = createAction("FETCH_STATES_FAILURE");

export function requestStates(countryName) {
  return (dispatch) =>
    request1("get", `${getURL("GET_STATES")}?country=${countryName}`).then(
      (response) => dispatch(requestStatesSuccess(response)),
      (error) => dispatch(requestStatesFailure(error))
    );
}

const requestDistrictsSuccess = createAction("FETCH_DISTRICTS_SUCCESS");
const requestDistrictsFailure = createAction("FETCH_DISTRICTS_FAILURE");

export function requestDistricts(stateName) {
  return (dispatch) =>
    request1("get", `${getURL("GET_DISTRICTS")}?state=${stateName}`).then(
      (response) => dispatch(requestDistrictsSuccess(response)),
      (error) => dispatch(requestDistrictsFailure(error))
    );
}

const requestIndustriesSuccess = createAction("FETCH_INDUSTRIES_SUCCESS");
const requestIndustriesFailure = createAction("FETCH_INDUSTRIES_FAILURE");

export function requestIndustries() {
  return (dispatch) =>
    request("get", "GET_INDUSTRIES").then(
      (response) => dispatch(requestIndustriesSuccess(response)),
      (error) => dispatch(requestIndustriesFailure(error))
    );
}

export const onEmailChanged = (e) => {
  return {
    type: "REGISTER_EMAIL_CHANGED",
    email: e.target.value,
  };
};

export const onPasswordChanged = (e) => {
  return {
    type: "REGISTER_PASSWORD_CHANGED",
    password: e.target.value,
  };
};

export const onUsernameChanged = (e) => {
  return {
    type: "REGISTER_USERNAME_CHANGED",
    username: e.target.value,
  };
};

// export const onFirstNameChanged = (e) => {
//   return {
//     type: "REGISTER_FIRSTNAME_CHANGED",
//     firstname: e.target.value,
//   };
// };
// export const onLastNameChanged = (e) => {
//   return {
//     type: "REGISTER_LASTNAME_CHANGED",
//     lastname: e.target.value,
//   };
// };
export const onUserTypeChanged = (e) => {
  return {
    type: "REGISTER_USERTYPE_CHANGED",
    usertype: e.target.value,
  };
};

export const onCheckBoxToggle = (e) => {
  return {
    type: "REGISTER_CHECKBOX_TOGGLE",
  };
};

export const setInitialRegisterState = () => {
  return {
    type: "SET_INITIAL_REGISTER_STATE",
  };
};

const registering = () => {
  return {
    type: "REGISTERING_USER",
  };
};

export const onRegisterFormSubmitted = (formData, history) => {
  let newFormData = {
    // firstname: formData.firstname,
    // lastname: formData.lastname,
    email: formData.email,
    username: formData.username,
    // bidAlias: formData.bidAlias,
    password: formData.password,
    confirmPassword: formData.confirmPassword,
    gender: formData.gender,
    dob: formData.dob,
    phone: formData.phone,
    referrel:formData.referrel,
    referredByUserName:formData.referrelUser.username,
    // houseno: formData.houseno,
    // street: formData.street,
    // city: formData.city,
    country: formData.country.country,
    // state: formData.state,
    // district: formData.district.district,
    // industry: formData.industry.industry,
    // pincode: formData.pincode,
    baseUserType: formData.usertype,
    userType: formData.usertype,
  };

  if (!formData.termsOfServicesCheckBox) {
    return snackbar.show(
      "Please agree to terms and conditions to move forward."
    );
  }

  return (dispatch) => {
    dispatch(registering());
    request("post", "SIGN_UP", false, newFormData).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong. Please try again.");
          dispatch(openDialog("Something went wrong. Please try again."));
        }
        if (response.data.data) {
          // snackbar.show("Registered Successfully.");
          localStorage.setItem("userId", response.data.existingUser._id);
          localStorage.setItem("validationJWT", response.data.data);
          localStorage.setItem("email", response.data.existingUser.email);
          localStorage.setItem("username", response.data.existingUser.username);
          return dispatch(registeredSuccessful(response.data));
        }
      },
      (err) => {
        if (err.response.data.error) {
          let errors = err.response.data.errors;
          try {
            dispatch(registeredFailed());

            if (errors && errors.constructor.name === "Array") {
              if (errors[0].isUserNameExist) {
                // snackbar.show("Already signed up.");
                dispatch(openDialog("This username already exists."));
              }
              if (errors[0].isEmailExist) {
                // snackbar.show("This email already exists.");
                dispatch(openDialog("This email already exists."));
              }
              if (errors[0].isMobileExist) {
                // snackbar.show("This phone number already exists.");
                dispatch(openDialog("This phone number already exists."));
              }
            } else {
              // snackbar.show("Something went wrong. Please try again.");
              dispatch(openDialog("Something went wrong. Please try again."));
            }
          } catch (e) {
            // snackbar.show("Something went wrong. Please try again.");
            dispatch(registeredFailed());

            dispatch(openDialog("Something went wrong. Please try again."));
          }
        }
      }
    );
  };
};

export const registeredSuccessful = ({ data, existingUser }) => {
  return {
    type: "REGISTRATION_SUCCESS",
    jwt: data,
    userDetail: existingUser,
  };
};

export const registeredFailed = () => {
  return {
    type: "REGISTRATION_FAILED",
  };
};
