import React, { Fragment, useEffect, useState } from "react";
import { Typography, Paper, Grid, Divider } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getFollowingPosts,
  getPostsByUserId,
  getAdsBetweenPosts,
  getTrendingPosts,
} from "../../../actions/postActions";
import PostItem from "./PostItem";
import AdItem from "./AdItem";

const Posts = ({
  dispatch,
  posts,
  ads,
  fetchingPosts,
  fetchingAdsBetweenPosts,
  user_id = null,
  value,
}) => {
  const [hover, setHover] = useState(null);
  // const [mixed, setMixed] = useState(null);
  useEffect(() => {
    if (value !== 1) {
      value === 0
        ? dispatch(getFollowingPosts())
        : dispatch(getTrendingPosts());
    }
    // dispatch(getAdsBetweenPosts())
  }, [value]);
  useEffect(() => {
    value === 1 && user_id && dispatch(getPostsByUserId(user_id));
    // dispatch(getAdsBetweenPosts())
  }, [value, user_id]);

  const handlePostsRefresh = () => {
    value === 1
      ? dispatch(getPostsByUserId(user_id))
      : value === 0
      ? dispatch(getFollowingPosts())
      : dispatch(getTrendingPosts());
    // dispatch(getAdsBetweenPosts())
  };

  // useEffect(() => {
  //   if (
  //     !fetchingAdsBetweenPosts &&
  //     !fetchingPosts &&
  //     ads.length > 0 &&
  //     posts.length > 0
  //   ) {
  //     var result = [posts, ads]
  //       .reduce(
  //         (r, a) => (a.forEach((a, i) => (r[i] = r[i] || []).push(a)), r),
  //         []
  //       )
  //       .reduce((a, b) => a.concat(b));
  //     setMixed(result);
  //   } else if (!fetchingAdsBetweenPosts && !fetchingPosts) {
  //     setMixed(posts);
  //   }
  // }, [posts, ads]);

  return !fetchingPosts && posts.length === 0 ? (
    <Typography variant="h4">No Posts yet.</Typography>
  ) : (
    <Paper style={{ width: "100%" }} elevation={0}>
      {posts &&
        posts.length > 0 &&
        posts.map((post) => (
          <Paper
            style={{
              width: "100%",
            }}
            variant="outlined"
            onMouseEnter={() => setHover(post._id)}
            onMouseLeave={() => setHover(null)}
          >
            {post.companyId ? (
              <AdItem
                key={post._id}
                ad={post}
                hovered={hover === post._id}
                handlePostsRefresh={handlePostsRefresh}
              />
            ) : (
              <PostItem
                key={post._id}
                post={post}
                hovered={hover === post._id}
                handlePostsRefresh={handlePostsRefresh}
              />
            )}
          </Paper>
        ))}
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  // ads: state.postsReducer.adsBetweenPosts,
  // fetchingAdsBetweenPosts: state.postsReducer.fetchingAdsBetweenPosts,
  posts: state.postsReducer.posts,
  fetchingPosts: state.postsReducer.fetchingPosts,
});

export default connect(mapStateToProps)(withRouter(Posts));
