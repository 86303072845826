// import dependencies
import React, { useState, useEffect } from "react";
import { Paper, Grid } from "@material-ui/core";
import { TextInput } from "../components/InputFields";
import ChatNavCard from "../components/ChatComponents/ChatNavCard";
import {
  establishSocketConnection,
  loadAllUsers,
  fetchThread,
  insertMessage,
  setTyping,
  deleteAllMessages,
  deleteForMe,
  deleteForAll,
  isOnline,
  chatEventTarget,
} from "../actions/chatActions";
import { useSelector, useDispatch } from "react-redux";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router-dom";
import ChatThreadComponent from "../components/ChatComponents/ChatThreadComponent";
import NewChatComponent from "../components/ChatComponents/NewChatComponent";

const ChatContainer = ({ userDetail, match, location }) => {
  const [messagedUsers, setMessagedUsers] = useState(null);
  const [chatThread, setChatThread] = useState([]);
  const [currentSelectedUser, setCurrentSelectedUser] = useState(null);
  const [unreadData, setUnreadData] = useState(null);
  const [filteredMessagedUsers, setFilteredMessagedUsers] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState(null);
  const [newMessage, setNewMessage] = useState(null);
  const [onlineStatus, setOnlineStatus] = useState(null);
  const [typingStatus, setTypingStatus] = useState(null);
  const [composing, setComposing] = useState(false);

  useEffect(() => {
    establishSocketConnection();
    chatEventTarget.addEventListener("users", (e) => {
      setMessagedUsers(e.detail);
      switchCurrentSelectedUserForChat(e.detail[e.detail.length - 1]);
    });
    chatEventTarget.addEventListener("thread", (e) => {
      setChatThread(e.detail);
    });
    chatEventTarget.addEventListener("newMessage", (e) => {
      if (composing) {
        setComposing(false);
      } else updateChatThread(e.detail);
    });
    chatEventTarget.addEventListener("unread", (e) => {
      updateMessagedUsers(e.detail);
    });
    chatEventTarget.addEventListener("isOnline", (e) => {
      updateOnlineStatus(e.detail);
    });
  }, []);

  useEffect(() => {
    if (location.state && location.state.currentUserProfile && messagedUsers) {
      const user =
        messagedUsers &&
        messagedUsers.length > 0 &&
        messagedUsers.filter(
          (user) => user.user_id === location.state.currentUserProfile._id
        );
      if (user && user.length > 0) {
        switchCurrentSelectedUserForChat(user[0]);
      } else {
        let temp = messagedUsers;
        temp.push({
          user_id: location.state.currentUserProfile._id,
          profilepic: location.state.currentUserProfile.profilepic,
          username: location.state.currentUserProfile.username,
          isChatEligible: location.state.currentUserProfile.isChatEligible,
          blockStatus: location.state.currentUserProfile.blockStatus,
          whoCanChat: location.state.currentUserProfile.whoCanChat,
        });
        setMessagedUsers(temp);
        setComposing(true);
        switchCurrentSelectedUserForChat(
          messagedUsers[messagedUsers.length - 1]
        );
      }
    }
  }, [location, messagedUsers && messagedUsers]);

  useEffect(() => {
    chatEventTarget.addEventListener("newMessage", (e) => {
      if (composing) {
        setComposing(false);
      } else updateChatThread(e.detail);
    });
    chatEventTarget.addEventListener("unread", (e) => {
      updateMessagedUsers(e.detail);
    });
    chatEventTarget.addEventListener("isOnline", (e) => {
      updateOnlineStatus(e.detail);
    });
    chatEventTarget.addEventListener("typing", (e) => {
      updateTypingStatus(e.detail);
    });
    chatEventTarget.addEventListener("deleted", (e) => {
      refreshThread(e.detail);
    });
  }, [currentSelectedUser, chatThread]);

  useEffect(() => {
    if (currentSelectedUser) {
      setChatThread([]);
      isOnline({ to: currentSelectedUser.user_id, from: userDetail._id });
      fetchThread({
        to: currentSelectedUser.user_id,
        from: userDetail._id,
        page: 0,
        limit: 10000,
      });
    }
  }, [currentSelectedUser && currentSelectedUser]);

  useEffect(() => {
    if (!composing && currentSelectedUser) {
      isOnline({ to: currentSelectedUser.user_id, from: userDetail._id });
      fetchThread({
        to: currentSelectedUser.user_id,
        from: userDetail._id,
        page: 0,
        limit: 10000,
      });
    }
  }, [composing]);

  const bringUserToTop = (userId) => {
    if (
      messagedUsers &&
      messagedUsers.length > 0 &&
      messagedUsers[messagedUsers.length - 1].user_id !== userId
    ) {
      var elementPos =
        messagedUsers &&
        messagedUsers
          .map((x) => {
            return x.user_id;
          })
          .indexOf(userId);
      if (messagedUsers.length > elementPos > -1) {
        let temp = messagedUsers;
        let e = temp.splice(elementPos, 1);
        temp.push(e[0]);
        setMessagedUsers(temp);
      }
    }
  };

  const switchCurrentSelectedUserForChat = (user) => {
    user && setCurrentSelectedUser(user);
    if (
      user &&
      messagedUsers &&
      messagedUsers.filter((thisUser) => thisUser.user_id === user.user_id)
        .length === 0
    ) {
      let temp = messagedUsers;
      temp.push({
        user_id: user.user_id,
        profilepic: user.profilepic,
        username: user.username,
        isChatEligible: user.isChatEligible,
        blockStatus: user.blockStatus,
        whoCanChat: user.whoCanChat,
        followingUser_CustomName: user.followingUser_CustomName,
      });
      setMessagedUsers(temp);
      // setCurrentSelectedUser(messagedUsers[messagedUsers.length - 1]);
    }
  };

  const refreshThread = (data) => {
    if (
      currentSelectedUser &&
      (data.to === currentSelectedUser.user_id ||
        data.from === currentSelectedUser.user_id)
    ) {
      fetchThread({
        to: currentSelectedUser && currentSelectedUser.user_id,
        from: userDetail._id,
        page: 0,
        limit: 10000,
      });
      isOnline({
        to: currentSelectedUser && currentSelectedUser.user_id,
        from: userDetail._id,
      });
    }
  };

  const updateChatThread = (chat) => {
    if (
      currentSelectedUser &&
      (chat.from === currentSelectedUser.user_id ||
        chat.to === currentSelectedUser.user_id)
    ) {
      bringUserToTop(currentSelectedUser.user_id);
      setNewMessage(chat);
    }
  };

  const updateMessagedUsers = (unread) => {
    if (currentSelectedUser && unread.to === userDetail._id) {
      setUnreadData(unread);
    }
  };

  const updateOnlineStatus = (status) => {
    if (
      status.user_id !== userDetail._id &&
      onlineStatus &&
      onlineStatus.isOnline !== status.isOnline
    ) {
      setOnlineStatus(status);
    }
  };

  const updateTypingStatus = (typingStatus) => {
    if (typingStatus.user_id !== userDetail._id) {
      setTypingStatus(typingStatus);
    }
  };

  const handleSendText = (params) => {
    insertMessage(params);
  };

  const handleSetTyping = (params) => {
    setTyping(params);
  };

  const handleDeleteAll = (params) => {
    deleteAllMessages(params);
  };

  const handleDeleteForAll = (params) => {
    deleteForAll(params);
  };

  const handleDeleteForMe = (params) => {
    deleteForMe(params);
  };

  const handleRefreshUsers = () => {
    loadAllUsers();
  };

  return (
    <Paper style={{ marginTop: "25px", width: "100%" }}>
      <Grid container>
        <Grid item xs={4}>
          <ChatNavCard
            switchCurrentSelectedUserForChat={switchCurrentSelectedUserForChat}
            users={messagedUsers && messagedUsers}
            title={"Previous Messages"}
            id={"messaged-users"}
            currentSelectedUser={currentSelectedUser}
            newMessage={newMessage && newMessage}
            unreadData={unreadData && unreadData}
          />
        </Grid>
        <Grid item xs={8}>
          {currentSelectedUser ? (
            // {currentSelectedUser && chatThread.length > 0 ? (
            <Paper style={{ height: "90vh" }}>
              <ChatThreadComponent
                chatThread={chatThread}
                currentSelectedUser={currentSelectedUser && currentSelectedUser}
                id={userDetail._id}
                handleSendText={(params) => handleSendText(params)}
                newMessage={newMessage && newMessage}
                onlineStatus={onlineStatus && onlineStatus}
                typingStatus={typingStatus && typingStatus}
                handleSetTyping={handleSetTyping}
                handleDeleteAll={handleDeleteAll}
                handleDeleteForAll={handleDeleteForAll}
                handleDeleteForMe={handleDeleteForMe}
                handleRefreshUsers={handleRefreshUsers}
              />
            </Paper>
          ) : composing ? (
            <Paper style={{ height: "90vh" }}>
              <NewChatComponent
                currentSelectedUser={currentSelectedUser && currentSelectedUser}
                id={userDetail._id}
                handleSendText={(params) => handleSendText(params)}
              />
            </Paper>
          ) : null}
        </Grid>
      </Grid>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetail: state.userDetailsReducer.userDetail,
  };
};

export default connect(mapStateToProps)(withRouter(ChatContainer));
