import React from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import { TextInput } from "../../components/InputFields";
import { getPosByUser, getEmpAllPos } from "../../actions/getPosActions";
import bindActionCreators from "redux/es/bindActionCreators";
import connect from "react-redux/es/connect/connect";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import {
  isValidParam,
  getStringParam,
  getObjectParam,
  getArrayParam,
  getIntParam,
  getBooleanParam,
} from "../../helpers/paramVerifier";
import { formatDate } from "../../helpers/globalFunctions";
import ReactPaginate from "react-paginate";

const dataTableStyles = (theme) => ({ ...styles });

class DataTable extends React.Component {
  constructor(props) {
    super(props);
    this.fields = [];
    this.state = {
      isRedirect: false,
      redirectPath: "",
    };
  }

  getTableHeader() {
    let element = null;
    try {
      let fields = getArrayParam(this.props.fields);
      fields = fields.filter((f) => {
        return !f.is_hidden;
      });
      let arrTheads = fields.map((m, i) => {
        let { name, label, width, ...other } = m;

        let style = { paddingLeft: 10, paddingRight: 10 };
        if (i > 0) {
          style.borderLeft = "1px solid #e0e0e0";
        }
        return (
          <TableCell key={name + "-" + i} style={style}>
            <Typography
              noWrap
              variant="caption"
              style={{ width: width, fontSize: 14, fontWeight: 600, ...other }}
              title={label}
            >
              {label}
            </Typography>
          </TableCell>
        );
      });
      element =
        arrTheads.length > 0 ? (
          <TableHead>
            <TableRow style={{ height: 30 }}>{arrTheads}</TableRow>
          </TableHead>
        ) : null;
    } catch (error) {}
    return element;
  }

  getTableBody() {
    let element = null;
    try {
      let rows = new Array();
      let fields = getArrayParam(this.props.fields);
      let values = getArrayParam(this.props.values);

      fields = fields.filter((f) => {
        return !f.is_hidden;
      });
      values.forEach((obj, index) => {
        let rowCells = fields.map((field, i) => {
          let { name, label, width, ...other } = field;
          let value = this.getFieldValue(field, obj);

          let valProps = new Object();
          valProps.noWrap = true;
          valProps.style = { width: width, fontSize: 14, ...other };
          valProps.variant = "caption";
          if (!isValidParam(field.link)) {
            valProps.title = value;
          }
          valProps.children = value;
         
          return (
            <TableCell
              key={name + "-" + i}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Typography {...valProps} />
            </TableCell>
          );
        });
        let style = { height: 30 };
        if (index % 2 != 0) {
          style.backgroundColor = "#00000014";
        }
        rows.push(
          <TableRow
            onClick={() => this.hadleRedirectToViewPositions(obj._id)}
            style={style}
          >
            {rowCells}
          </TableRow>
        );
      });

      element = rows.length > 0 ? <TableBody>{rows}</TableBody> : null;
    } catch (error) {}
    return element;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.location !== this.props.history.location.pathname) {
      this.setState({ location: this.props.history.location.pathname });
    }
  }

  getFieldValue(field, rowObj) {
    let element = null;
    let value = null;
    try {
      field = getObjectParam(field);
      rowObj = getObjectParam(rowObj);
      value = getStringParam(rowObj[field.name]);

      if (field.type == "date") {
        value = formatDate(value);
      }
      if (field.name === "ageMin") {
        value = `${rowObj.ageMin} - ${rowObj.ageMax}`;
      }

      if (field.name === "country") {
        value =
          rowObj.country === "India"
            ? `${rowObj.industry}, ${rowObj.country}`
            : rowObj.country;
      }
      if (field.name === "positionStatus") {
        value = (
          <Typography
            style={{
              cursor: "pointer",
              color:
                rowObj[field.name].toLowerCase() === "open" ? "#337ab7" : "red",
            }}
          >
            {rowObj[field.name]}
          </Typography>
        );
      }

      if (isValidParam(field.link)) {
        let { name, baseUrl, ...others } = field.link;
        let url = baseUrl + rowObj[name];
        if(field.name == "role" && value == "Actor"){
          value = (
            <Typography
              onClick={() => this.hadleRedirectToViewPositions(rowObj._id)}
              {...others}
              title={
                getStringParam(rowObj[field.name]) === "Other"
                  ? `${getStringParam(rowObj[field.name])}: ${getStringParam(
                      rowObj.otherrolename
                    )}`
                  : getStringParam(rowObj[field.name])
              }
              style={{
                // textDecoration: "none",
                cursor: "pointer",
                color: "#337ab7",
              }}
              variant="body2"
            >
              {getStringParam(rowObj[field.name]) === "Other"
                ? `${getStringParam(rowObj[field.name])}: ${getStringParam(
                    rowObj.otherrolename
                  )}`
                : getStringParam(rowObj[field.name])}
                
                {
                  ` : ${rowObj['otherrolename']}`
                }
            </Typography>
          );
        }else{
          value = (
            <Typography
              onClick={() => this.hadleRedirectToViewPositions(rowObj._id)}
              {...others}
              title={
                getStringParam(rowObj[field.name]) === "Other"
                  ? `${getStringParam(rowObj[field.name])}: ${getStringParam(
                      rowObj.otherrolename
                    )}`
                  : getStringParam(rowObj[field.name])
              }
              style={{
                // textDecoration: "none",
                cursor: "pointer",
                color: "#337ab7",
              }}
              variant="body2"
            >
              {getStringParam(rowObj[field.name]) === "Other"
                ? `${getStringParam(rowObj[field.name])}: ${getStringParam(
                    rowObj.otherrolename
                  )}`
                : getStringParam(rowObj[field.name])}
            </Typography>
          );
        }
        
      }

      if (isValidParam(value)) {
        element = value;
      }
    } catch (error) {}
    return element;
  }

  hadleRedirectToViewPositions(id) {
    this.props.history.push(
      `/all-positions/${this.props.match.params.ad_id}/view/${id}`
    );
  }
  redirect(path) {
    this.setState({ isRedirect: true, redirectPath: path });
  }
  getPaginationProps() {
    let props = new Object();
    try {
      let { classes, paging } = this.props;
      paging = getObjectParam(paging);

      props.pageClassName = classes.pageClassName;
      props.breakClassName = classes.breakClassName;
      props.activeClassName = classes.activeClassName;
      props.containerClassName = classes.containerClassName;
      props.previousClassName = classes.previousClassName;
      props.nextClassName = classes.nextClassName;
      props.pageLinkClassName = classes.pageLinkClassName;
      props.previousLinkClassName = classes.previousLinkClassName;
      props.nextLinkClassName = classes.nextLinkClassName;

      props.initialPage = 0;
      props.pageCount = 5;
      props.previousLabel = "<<";
      props.nextLabel = ">>";

      props = { ...props, ...paging };
    } catch (error) {}
    return props;
  }

  render() {
    if (this.state.isRedirect) {
      this.state.isRedirect = false;
      return <Redirect to={this.state.redirectPath} />;
    }
    let { classes, tableStyle, paging } = this.props;
    let header = this.getTableHeader();
    let body = this.getTableBody();
    return (
      <Grid>
        {isValidParam(paging) && (
          <Grid className={classes.pagingContainer}>
            <ReactPaginate {...this.getPaginationProps()} />
          </Grid>
        )}
        <Grid container style={{ overflowX: "auto" }}>
          <Table style={tableStyle}>
            {header}
            {body}
          </Table>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(dataTableStyles)(withRouter(DataTable));

let styles = {
  root: {},
  tableCellChild: {
    fontSize: 14,
    fontWeight: 600,
  },
  addButton: {
    width: 30,
    height: 30,
  },
  pagingContainer: {
    float: "right",
    //marginRight: 10
  },
  pageClassName: {
    display: "inline-block",
    paddingLeft: 5,
    paddingRight: 5,
  },
  activeClassName: {
    backgroundColor: " #25242445",
    color: "#ffffff",
  },
  containerClassName: {
    border: "1px solid #25242445",
    lineHeight: 1.5,
    paddingLeft: 10,
    paddingRight: 10,
    width: "fit-content",
  },
  previousClassName: {
    display: "inline-block",
  },
  nextClassName: {
    display: "inline-block",
  },
  breakClassName: {
    display: "inline-block",
    paddingLeft: 5,
    paddingRight: 5,
  },
  pageLinkClassName: {
    color: "#757575",
  },
  previousLinkClassName: {
    color: "#757575",
  },
  nextLinkClassName: {
    color: "#757575",
  },
};
