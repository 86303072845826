import { createAction } from "redux-actions";
import request from "../helpers/requestEngine";
import request1 from "../helpers/request";
import { sendHTTPRequest } from "../helpers/requestEngine";
import getURL from "../helpers/apis";
import snackbar from "snackbar";
import { openDialog, closeDialog } from "./appActions";
import { authenticateLoginCredentials } from "./loginActions";
import formurlencoded from "form-urlencoded";

// change settings

export const changeSetting = (setting, body) => {
  let url = getURL("CHANGE_SETTING") + setting;
  let promise = Promise.resolve(sendHTTPRequest("post", url, true, body));
  return promise;
};

//  GET USER Settings

export const getUserSettings = () => {
  return (dispatch) => {
    dispatch(fetchingUserSettings());

    return dispatch(fetchUserSettings());
  };
};

function fetchingUserSettings() {
  return {
    type: "GET_USER_SETTINGS",
  };
}

function fetchUserSettings() {
  return (dispatch) => {
    request("get", "GET_USER_SETTINGS", true).then(
      (response) => {
        dispatch(getUserSettingsSuccess(response.data.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(getUserSettingsFailed(err));
      }
    );
  };
}

function getUserSettingsSuccess(data) {
  return {
    type: "GET_USER_SETTINGS_SUCCESS",
    payload: data,
  };
}

function getUserSettingsFailed(err) {
  return {
    type: "GET_USER_SETTINGS_FAILED",
  };
}
//  GET USER ACCESS PAGES

export const getUserAccessPages = () => {
  return (dispatch) => {
    dispatch(fetchingUserAccessPages());

    return dispatch(fetchUserAccessPagesByType());
  };
};

function fetchingUserAccessPages() {
  return {
    type: "GET_USER_ACCESS_PAGES",
  };
}

function fetchUserAccessPagesByType() {
  return (dispatch) => {
    request("get", "GET_USER_ACCESS_PAGES", true).then(
      (response) => {
        dispatch(getUserAccessPagesSuccess(response.data.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(getUserAccessPagesFailed(err));
      }
    );
  };
}

function getUserAccessPagesSuccess(data) {
  return {
    type: "GET_USER_ACCESS_PAGES_SUCCESS",
    payload: data,
  };
}

function getUserAccessPagesFailed(err) {
  return {
    type: "GET_USER_ACCESS_PAGES_FAILED",
  };
}

const getUserDetailsSuccess = createAction("GET_USER_DETAILS_SUCCESS");

export function getUserDetails(token, id) {
  return (dispatch) =>
    request1("get", `${getURL("GET_USER_DETAILS")}${id}`, false, null, null, {
      Authorization: token,
    }).then((response) => {
      dispatch(getUserDetailsSuccess(response));
    });
}

const getJobTypesSuccess = createAction("GET_JOBTYPES_SUCCESS");

export function getJobTypes(token, id) {
  return (dispatch) =>
    request("get", "JOBTYPES").then((response) =>
      dispatch(getJobTypesSuccess(response))
    );
}

const getRolesSuccess = createAction("GET_ROLES_SUCCESS");

export function getRoles(token, id) {
  return (dispatch) =>
    request("get", "ROLES").then((response) =>
      dispatch(getRolesSuccess(response))
    );
}

const getUsersSuccess = createAction("GET_USERS_SUCCESS");

export function getUsers(token, id) {
  return (dispatch) =>
    request("get", "GET_USERS", true).then((response) =>
      dispatch(getUsersSuccess(response))
    );
}

const getUsersByKeywordSuccess = createAction("GET_USERS_SUCCESS");

export function getUsersByKeyword(params) {
  return (dispatch) =>
    request("get", "GET_USERS_BY_KEYWORD", true, null, params).then(
      (response) => {
        dispatch(getUsersByKeywordSuccess(response));
      }
    );
}

const clearUsersListSuccess = createAction("CLEAR_USERS_SUCCESS");

export function clearUsersList() {
  return (dispatch) => dispatch(clearUsersListSuccess());
}

const getUserProfileDetailsSuccess = createAction("GET_USER_PROFILE_DETAILS");

export function getUserProfileDetails(id) {
  return (dispatch) =>
    request1("get", `${getURL("GET_USER_DETAILS")}${id}`, true).then(
      (response) => dispatch(getUserProfileDetailsSuccess(response))
    );
}

export function getUserProfileDetailsByUsername(username) {
  return (dispatch) =>
    request1(
      "get",
      `${getURL("GET_USER_DETAILS_BY_USERNAME")}${username}`,
      true
    ).then((response) => dispatch(getUserProfileDetailsSuccess(response)));
}

//  GET PRODUCT FEATURES BY USER TYPE

export const getProductFeaturesByType = (user_type) => {
  return (dispatch) => {
    dispatch(fetchingProductFeaturesByType());

    return dispatch(fetchProductFeaturesByType(user_type));
  };
};

function fetchingProductFeaturesByType() {
  return {
    type: "GET_PRODUCT_FEATURES_BY_TYPE",
  };
}

function fetchProductFeaturesByType(user_type) {
  return (dispatch) => {
    request1(
      "get",
      `${getURL("GET_PRODUCT_FEATURES_BY_TYPE")}${user_type}`,
      true,
      {},
      {}
    ).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong.");
          dispatch(
            openDialog(
              response.data.data || "Something went wrong. Please try again."
            )
          );

          return dispatch(fetchingProductFeaturesByTypeFailed());
        }
        dispatch(fetchingProductFeaturesByTypeSuccess(response.data.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(fetchingProductFeaturesByTypeFailed(err));
      }
    );
  };
}

function fetchingProductFeaturesByTypeSuccess(data) {
  return {
    type: "GET_PRODUCT_FEATURES_BY_TYPE_SUCCESS",
    payload: data,
  };
}

function fetchingProductFeaturesByTypeFailed(err) {
  return {
    type: "GET_PRODUCT_FEATURES_BY_TYPE_FAILED",
  };
}

//  GET USER ACTIVE SUBSCRIPTION

export const getUserSubscription = (status) => {
  return (dispatch) => {
    dispatch(fetchingUserSubscription());

    return dispatch(fetchUserSubscription(status));
  };
};

function fetchingUserSubscription() {
  return {
    type: "GET_USER_SUBSCRIPTION",
  };
}

function fetchUserSubscription(status) {
  return (dispatch) => {
    request1(
      "get",
      `${getURL("GET_USER_SUBSCRIPTION")}${status}`,
      true,
      {},
      {}
    ).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong.");
          dispatch(
            openDialog(
              response.data.data || "Something went wrong. Please try again."
            )
          );

          return dispatch(fetchingUserSubscriptionFailed());
        }
        dispatch(fetchingUserSubscriptionSuccess(response.data.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(fetchingUserSubscriptionFailed(err));
      }
    );
  };
}

function fetchingUserSubscriptionSuccess(data) {
  return {
    type: "GET_USER_SUBSCRIPTION_SUCCESS",
    payload: data,
  };
}

function fetchingUserSubscriptionFailed(err) {
  return {
    type: "GET_USER_SUBSCRIPTION_FAILED",
  };
}

//  GET UPGRADE INFORMATION

export const getUpgradeDetails = (subscriptionId) => {
  return (dispatch) => {
    dispatch(fetchingUpgradeDetails());

    return dispatch(fetchUpgradeDetails(subscriptionId));
  };
};

function fetchingUpgradeDetails() {
  return {
    type: "GET_UPGRADE_INFORMATION",
  };
}

function fetchUpgradeDetails(subscriptionId) {
  return (dispatch) => {
    request1(
      "get",
      `${getURL("GET_UPGRADE_INFO")}${subscriptionId}`,
      true,
      {},
      {}
    ).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong.");
          dispatch(
            openDialog(
              response.data.data || "Something went wrong. Please try again."
            )
          );

          return dispatch(fetchingUpgradeDetailsFailed());
        }
        dispatch(fetchingUpgradeDetailsSuccess(response.data.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(fetchingUpgradeDetailsFailed(err));
      }
    );
  };
}

function fetchingUpgradeDetailsSuccess(data) {
  return {
    type: "GET_UPGRADE_INFORMATION_SUCCESS",
    payload: data,
  };
}

function fetchingUpgradeDetailsFailed(err) {
  return {
    type: "GET_UPGRADE_INFORMATION_FAILED",
  };
}

//  GET PURCHASE INFORMATION

export const getPurchaseDetails = (subscriptionId) => {
  return (dispatch) => {
    dispatch(fetchingPurchaseDetails());

    return dispatch(fetchPurchaseDetails(subscriptionId));
  };
};

function fetchingPurchaseDetails() {
  return {
    type: "GET_PURCHASE_INFORMATION",
  };
}

function fetchPurchaseDetails(subscriptionId) {
  return (dispatch) => {
    request1(
      "get",
      `${getURL("GET_PURCHASE_INFO")}${subscriptionId}`,
      true,
      {},
      {}
    ).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong.");
          dispatch(
            openDialog(
              response.data.data || "Something went wrong. Please try again."
            )
          );

          return dispatch(fetchingPurchaseDetailsFailed());
        }
        dispatch(fetchingPurchaseDetailsSuccess(response.data.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(fetchingPurchaseDetailsFailed(err));
      }
    );
  };
}

function fetchingPurchaseDetailsSuccess(data) {
  return {
    type: "GET_PURCHASE_INFORMATION_SUCCESS",
    payload: data,
  };
}

function fetchingPurchaseDetailsFailed(err) {
  return {
    type: "GET_PURCHASE_INFORMATION_FAILED",
  };
}

//   USER CHOOSE SUBSCRIPTION PLAN

export const userChooseSubscriptionPlan = (cycle, name, userType) => {
  return (dispatch) => {
    dispatch(choosingSubscriptionPlan);

    return dispatch(chooseSubscriptionPlan(cycle, name, userType));
  };
};

function choosingSubscriptionPlan() {
  return {
    type: "USER_CHOOSE_SUBSCRIPTION_PLAN",
  };
}

function chooseSubscriptionPlan(cycle, name, userType) {
  return (dispatch) => {
    request1(
      "get",
      `${getURL(
        "USER_CHOOSE_SUBSCRIPTION_PLAN"
      )}?cycle=${cycle}&name=${name}&userType=${userType}`,
      true,
      {},
      {}
    ).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong.");
          dispatch(
            openDialog(
              response.data.data || "Something went wrong. Please try again."
            )
          );

          return dispatch(choosingSubscriptionPlanFailed());
        }
        dispatch(choosingSubscriptionPlanSuccess(response.data.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(choosingSubscriptionPlanFailed(err));
      }
    );
  };
}

function choosingSubscriptionPlanSuccess(data) {
  return {
    type: "USER_CHOOSE_SUBSCRIPTION_PLAN_SUCCESS",
    payload: data,
  };
}

function choosingSubscriptionPlanFailed(err) {
  return {
    type: "USER_CHOOSE_SUBSCRIPTION_PLAN_FAILED",
  };
}

// USER ADD SUBSCRIPTION PLAN

export const addSubscriptionPlan = (plan_id, handleCallRazorPayMethod) => {
  return (dispatch) => {
    dispatch(addingSubscriptionPlan());

    return dispatch(
      addSubscriptionPlanToCart(plan_id, handleCallRazorPayMethod)
    );
  };
};

function addingSubscriptionPlan() {
  return {
    type: "USER_ADD_SUBSCRIPTION_TO_CART",
  };
}

function addSubscriptionPlanToCart(plan_id, handleCallRazorPayMethod) {
  return (dispatch) => {
    request1(
      "get",
      `${getURL("USER_ADD_SUBSCRIPTION_TO_CART")}/${plan_id}/${"web"}`,
      true,
      {},
      {}
    ).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong.");
          dispatch(
            openDialog(
              response.data.data || "Something went wrong. Please try again."
            )
          );

          return dispatch(addingSubscriptionPlanFailed());
        }
        dispatch(addingSubscriptionPlanSuccess(response.data.data));
        handleCallRazorPayMethod(response.data.data);
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(addingSubscriptionPlanFailed(err));
      }
    );
  };
}

function addingSubscriptionPlanSuccess(data) {
  return {
    type: "USER_ADD_SUBSCRIPTION_TO_CART_SUCCESS",
    payload: data,
  };
}

function addingSubscriptionPlanFailed(err) {
  return {
    type: "USER_ADD_SUBSCRIPTION_TO_CART_FAILED",
  };
}

//USER GET DONATION

export const addDonationPlan = (amount, handleCallRazorPayMethod) => {
  return (dispatch) => {
    dispatch(addingDonationPlan());

    return dispatch(addDonationPlanToCart(amount, handleCallRazorPayMethod));
  };
};

function addingDonationPlan() {
  return {
    type: "USER_ADD_DONATION_TO_CART",
  };
}

function addDonationPlanToCart(amount, handleCallRazorPayMethod) {
  return (dispatch) => {
    request1(
      "post",
      `${getURL("USER_ADD_DONATION_TO_CART")}`,
      true,
      {
        amount: amount,
        source: "web",
      },
      {},
      {}
    ).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong.");
          dispatch(
            openDialog(
              response.data.data || "Something went wrong. Please try again."
            )
          );

          return dispatch(addingDonationPlanFailed());
        }
        dispatch(addingDonationPlanSuccess(response.data.data));
        handleCallRazorPayMethod(response.data.data);
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(addingDonationPlanFailed(err));
      }
    );
  };
}

function addingDonationPlanSuccess(data) {
  return {
    type: "USER_ADD_DONATION_TO_CART_SUCCESS",
    payload: data,
  };
}

function addingDonationPlanFailed(err) {
  return {
    type: "USER_ADD_DONATION_TO_CART_FAILED",
  };
}

//USER GET RSA KEY

export const getRazorPaySuccessCapture = (
  body,
  handleCallbackRazorPayCapture
) => {
  return (dispatch) => {
    dispatch(fetchingRazorPaySucessCapture());

    return dispatch(
      fetchRazorPaySuccessCapture(body, handleCallbackRazorPayCapture)
    );
  };
};

function fetchingRazorPaySucessCapture() {
  return {
    type: "USER_RAZORPAY_PAYMENT_CAPTURE",
  };
}

function fetchRazorPaySuccessCapture(body, handleCallbackRazorPayCapture) {
  return (dispatch) => {
    request1(
      "post",
      `${getURL("USER_RAZORPAY_PAYMENT_CAPTURE_SUCCESS")}`,
      true,
      body,
      {}
    ).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong.");
          dispatch(
            openDialog(
              response.data.data || "Something went wrong. Please try again."
            )
          );

          return dispatch(fetchingRazorPayCaptureFailed());
        }
        dispatch(fetchingRazorPayCaptureSuccess(response.data.data));
        handleCallbackRazorPayCapture();
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(fetchingRazorPayCaptureFailed(err));
      }
    );
  };
}

function fetchingRazorPayCaptureSuccess(data) {
  return {
    type: "USER_RAZORPAY_PAYMENT_CAPTURE_SUCCESS",
    payload: data,
  };
}

function fetchingRazorPayCaptureFailed(err) {
  return {
    type: "USER_RAZORPAY_PAYMENT_CAPTURE_FAILED",
  };
}

// const fetchingRsaSecuredKeySuccess = createAction(
//   "USER_GET_RSA_SECURED_KEY_SUCCESS"
// );
// const fetchingRsaSecuredKeyFailed = createAction(
//   "USER_GET_RSA_SECURED_KEY_FAILED"
// );

// export function getRsaSecuredKey(body) {
//   return dispatch =>
//     request1(
//       "get",
//       `${getURL("GET_RSA_SECURED_KEY")}`,
//       true,
//       {
//         order_id: "5e71a4450c28152a81341c82"
//       },
//       null
//     ).then(
//       response => dispatch(fetchingRsaSecuredKeySuccess(response)),
//       error => dispatch(fetchingRsaSecuredKeyFailed(error))
//     );
// }
//  GET PRODUCT FEATURES LIST

export const getProductFeaturesList = () => {
  return (dispatch) => {
    dispatch(fetchingProductFeaturesList());

    return dispatch(fetchProductFeaturesList());
  };
};

function fetchingProductFeaturesList() {
  return {
    type: "GET_PRODUCT_FEATURES_LIST",
  };
}

function fetchProductFeaturesList() {
  return (dispatch) => {
    request1(
      "get",
      `${getURL("GET_PRODUCT_FEATURES_LIST")}`,
      true,
      {},
      {}
    ).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong.");
          dispatch(
            openDialog(
              response.data.data || "Something went wrong. Please try again."
            )
          );

          return dispatch(fetchingProductFeaturesListFailed());
        }
        dispatch(fetchingProductFeaturesListSuccess(response.data.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(fetchingProductFeaturesListFailed(err));
      }
    );
  };
}

function fetchingProductFeaturesListSuccess(data) {
  return {
    type: "GET_PRODUCT_FEATURES_LIST_SUCCESS",
    payload: data,
  };
}

function fetchingProductFeaturesListFailed(err) {
  return {
    type: "GET_PRODUCT_FEATURES_LIST_FAILED",
  };
}
