const videosTableReducer = (
  state = {
    videos: [],
    fetchingVideos: false
  },
  action
) => {
  switch (action.type) {
    case "FETCHING_VIDEOS":
      return {
        ...state,
        fetchingVideos: true
      };
    case "VIDEOS_FETCHED_SUCCESS":
      return {
        ...state,
        videos: action.payload,
        fetchingVideos: false
      };
    case "VIDEOS_FETCHED_FAILED":
      return {
        ...state,
        fetchingVideos: false
      };
    default:
      return state;
  }
};

export default videosTableReducer;
