import { combineReducers, applyMiddleware, createStore } from "redux";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";

import loginReducer from "./reducers/loginReducer";
import registerReducer from "./reducers/registerReducer";
import appReducer from "./reducers/appReducer";
import profilePhotoReducer from "./reducers/profilePhotoReducer";
import profileVideoReducer from "./reducers/profileVideoReducer";
import postAnADReducer from "./reducers/postAnADReducer";
import PosTableReducer from "./reducers/PosTableReducer";
import ADTableReducer from "./reducers/ADTableReducer";
import videoReducer from "./reducers/videoReducer";
import videosTableReducer from "./reducers/videosTableReducer";
import applicantsReducer from "./reducers/applicantsReducer";
import calendarReducer from "./reducers/calendarReducer";
import userDepartmentReducer from "./reducers/userDepartmentReducer";
import postAnPosReducer from "./reducers/postAnPosReducer";
import commentReducer from "./reducers/commentReducer";
import userDetailsReducer from "./reducers/userDetailsReducer";
import rolesReducer from "./reducers/rolesReducer";
import usersReducer from "./reducers/usersReducer";
import bidReducer from "./reducers/bidReducer";
import postsReducer from "./reducers/postsReducer";
import pointsReducer from "./reducers/pointsReducer";
import adSpaceReducer from "./reducers/adSpaceReducer";
import shareBidReducer from "./reducers/shareBidReducer";

const middleware = applyMiddleware(promise(), thunk, logger);
// const middleware = applyMiddleware(promise(), thunk);

//creating auth store
const authReducers = combineReducers({
  appReducer,
  profilePhotoReducer,
  profileVideoReducer,
  postAnADReducer,
  postAnPosReducer,
  PosTableReducer,
  ADTableReducer,
  videoReducer,
  videosTableReducer,
  applicantsReducer,
  calendarReducer,
  userDepartmentReducer,
  loginReducer,
  registerReducer,
  commentReducer,
  userDetailsReducer,
  rolesReducer,
  usersReducer,
  bidReducer,
  postsReducer,
  pointsReducer,
  adSpaceReducer,
  shareBidReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGGED_OUT") {
    state = undefined;
  }

  return authReducers(state, action);
};

//export const authStore = createStore(authReducers, middleware);
// export default createStore(authReducers, middleware);
export default createStore(rootReducer, composeWithDevTools(middleware));
// export default createStore(rootReducer, middleware);

// creating unAuth store
// const unAuthReducer = combineReducers({
//     appReducer,
//     loginReducer,
//     registerReducer
// });

// export const unAuthStore = createStore(unAuthReducer, middleware);
