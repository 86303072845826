import React, { useState, useEffect } from "react";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  DialogActions,
  DialogContentText,
  Button,
  Switch,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "100%",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
    width: "inherit",
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
    justifyContent: "space-between",
    width: "95%",
  },
}));

const Settings = ({
  open,
  handleClose,
  settings,
  allowOpenComments,
  allowHiddenComments,
  changeWhoCanOpenComment,
  changeWhoCanHiddenComment,
  changeGalleryQuotation,
  isPrivate,
  userSettings,
}) => {
  const classes = useStyles();
  const [quotation, setQuotation] = useState(
    settings && settings.galleryQuotation
  );
  const [allowOpen, setAllowOpen] = useState(
    settings && settings.allowOpenComments
  );
  const [allowHidden, setAllowHidden] = useState(
    settings && settings.allowHiddenComments
  );
  const [whoOpen, setWhoOpen] = useState(
    settings && settings.whoCanOpenComment
  );
  const [whoHidden, setWhoHidden] = useState(
    settings && settings.whoCanHiddenComment
  );

  const handleDone = () => {
    changeGalleryQuotation(quotation);
    handleClose();
  };

  useEffect(() => {
    changeWhoCanOpenComment(whoOpen);
  }, [whoOpen]);
  useEffect(() => {
    changeWhoCanHiddenComment(whoHidden);
  }, [whoHidden]);

  return (
    <React.Fragment>
      <Dialog
        fullWidth={false}
        maxWidth="sm"
        open={open}
        onClose={() => handleDone()}
      >
        <DialogTitle id="comment-settings">Comment Settings</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can update your gallery quotation and comment view and add
            permissions.
          </DialogContentText>
          <form className={classes.form} noValidate>
            <FormControl className={classes.formControl}>
              <TextField
                id="outlined-multiline-static"
                label="Gallery Quotation"
                multiline
                fullWidth
                rows={3}
                autoFocus
                onChange={(e) =>
                  e.target.value.length <= 50 && setQuotation(e.target.value)
                }
                defaultValue={quotation}
                value={quotation}
                variant="outlined"
              />
            </FormControl>
            {!isPrivate && (
              <FormControlLabel
                className={classes.formControlLabel}
                control={
                  <Switch
                    checked={allowOpen}
                    size="large"
                    onChange={(e) => {
                      setAllowOpen(!allowOpen);
                      allowOpenComments();
                    }}
                  />
                }
                labelPlacement="start"
                label="Open Comments"
              />
            )}

            {!isPrivate && allowOpen && (
              <FormControlLabel
                className={classes.formControlLabel}
                control={
                  <Select
                    value={whoOpen}
                    autoWidth
                    onChange={(e) => setWhoOpen(e.target.value)}
                    inputProps={{
                      name: "who-open",
                      id: "who-open",
                    }}
                  >
                    <MenuItem value={"NoOne"}>No One</MenuItem>
                    <MenuItem value={"AnyOne"}>Any One</MenuItem>
                    <MenuItem value={"OpenFriends"}>Open Friends</MenuItem>
                    {[
                      {
                        value: "HiddenFriends",
                        label: "Hidden Friends",
                      },
                      {
                        label: "Hidden, Open Friends",
                        value: "HiddenOpenFriends",
                      },
                    ].map((opt) => (
                      <MenuItem
                        hidden={
                          !(
                            userSettings && userSettings.showHiddenFriendsOption
                          )
                        }
                        key={opt.value}
                        value={opt.value}
                      >
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                }
                labelPlacement="start"
                label="Who Can Open Comment"
              />
            )}
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Switch
                  checked={allowHidden}
                  size="large"
                  onChange={(e) => {
                    setAllowHidden(!allowHidden);
                    allowHiddenComments();
                  }}
                />
              }
              labelPlacement="start"
              label="Hidden Comments"
            />
            {allowHidden && (
              <FormControlLabel
                className={classes.formControlLabel}
                control={
                  <Select
                    value={whoHidden}
                    autoWidth
                    onChange={(e) => setWhoHidden(e.target.value)}
                    inputProps={{
                      name: "who-hidden",
                      id: "who-hidden",
                    }}
                  >
                    <MenuItem value={"NoOne"}>No One</MenuItem>
                    <MenuItem value={"AnyOne"}>Any One</MenuItem>
                    <MenuItem value={"OpenFriends"}>Open Friends</MenuItem>
                    <MenuItem value={"HiddenFriends"}>Hidden Friends</MenuItem>
                    <MenuItem value={"HiddenOpenFriends"}>
                      Hidden, Open Friends
                    </MenuItem>
                  </Select>
                }
                labelPlacement="start"
                label="Who Can Hidden Comment"
              />
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDone()}
            color="primary"
            style={{ textTransform: "none" }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default Settings;
