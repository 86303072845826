import React from "react";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import classNames from "classnames";
import {
  isValidParam,
  getStringParam,
  getObjectParam,
  getArrayParam,
  getIntParam,
  getBooleanParam,
} from "../helpers/paramVerifier";
import { onFormSubmit } from "../actions/postPosActions";
import connect from "react-redux/es/connect/connect";
import bindActionCreators from "redux/es/bindActionCreators";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { formatDate } from "../helpers/globalFunctions";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";

import CreatePosition from "./CreatePosition";

const styles = (theme) => ({
  card: {
    minWidth: 275,
    borderTopRightRadius: "0px",
    borderTopLeftRadius: "0px",
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: "#00465bbf",
    color: "white",
    height: 40,
    padding: 10,
  },
  root: {
    flexGrow: 1,
    marginLeft: 20,
    marginRight: 20,
  },
  row: {
    fontSize: 14,
    fontWeight: 600,
    margiBottom: 10,
    paddingTop: 16,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
    margiBottom: 10,
  },
  dense: {
    //marginTop: 19,
  },
});

class CreatePositionForm extends React.Component {
  constructor(props) {
    super(props);
    this.fields = [
      { name: "positionName", label: "Position Name" },
      { name: "positionType", label: "Type" },
      {
        name: "positionStatus",
        label: "Status",
        type: "list",
        listValues: [
          { value: "open", label: "Open" },
          { value: "close", label: "Close" },
        ],
      },
      { name: "reqExp", label: "Required Exp", type: "integer" },
      { name: "role", label: "Role" },
      {
        name: "gender",
        label: "Gender",
        type: "list",
        listValues: [
          { value: "Male", label: "Male" },
          { value: "Female", label: "Female" },
        ],
      },
      { name: "ageMin", label: "Min Age", type: "integer" },
      { name: "ageMax", label: "Age Max", type: "integer" },
      { name: "dept", label: "Department" },
      { name: "description", label: "Description" },
      { name: "lastDate", label: "Last Date", type: "date" },
      { name: "compensation", label: "Compensation", type: "text" },
      {
        name: "otherRolesCanApply",
        label: "Others Role Can Apply",
        type: "boolean",
      },
    ];
    this.state = {
      fieldValueMap: {},
    };
  }

  componentWillMount() {
    this.setFieldValueForEdit();
  }

  getHeader() {
    let classes = this.props.classes;
    let positionId = getStringParam(this.props.positionId);
    try {
      return (
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {positionId == "" ? "Create Position" : "Edit Position"}
        </Typography>
      );
    } catch (error) {}
  }

  getFildes(fields) {
    const { classes } = this.props;
    let arrRow = new Array();
    try {
      fields = getArrayParam(fields);
      fields.forEach((field) => {
        arrRow.push(this.getField(field));
      });
      return <CardContent>{arrRow}</CardContent>;
    } catch (error) {}
  }

  getField(field) {
    const { classes } = this.props;
    let textFieldProps = {
      className: classNames(classes.textField, classes.dense),
      margin: "dense",
      variant: "outlined",
    };
    textFieldProps.InputLabelProps = { style: { fontSize: 14 } };
    textFieldProps.InputProps = { style: { fontSize: 14 } };
    let arrRow = new Array();
    let fieldElement = null;
    try {
      field = getObjectParam(field);
      let name = getStringParam(field.name);
      let label = getStringParam(field.label);
      let type = getStringParam(field.type);
      textFieldProps.value = isValidParam(this.state.fieldValueMap[name])
        ? this.state.fieldValueMap[name]
        : null;
      textFieldProps.onChange = this.setFieldValue.bind(this, name, type);
      if (type == "date") {
        textFieldProps.className = classes.textField;
        textFieldProps.defaultValue = textFieldProps.value;
        textFieldProps.InputLabelProps = {
          ...textFieldProps.InputLabelProps,
          shrink: true,
        };
        fieldElement = (
          <TextField id={name} label={label} {...textFieldProps} type="date" />
        );
      } else if (type == "boolean") {
        delete textFieldProps.className;
        textFieldProps.checked = textFieldProps.value;
        fieldElement = [
          <Checkbox
            {...textFieldProps}
            //value="checkedB"
            color="primary"
          />,
          label,
        ];
      } else if (type == "list") {
        fieldElement = (
          <FormControl
            key={"field-" + name}
            variant="outlined"
            className={textFieldProps.className}
          >
            <InputLabel
              ref={(ref) => {
                this.InputLabelRef = ref;
              }}
              htmlFor="outlined-age-native-simple"
              {...textFieldProps.InputLabelProps}
            >
              {label}
            </InputLabel>
            {this.getListValueElement(name, field.listValues, textFieldProps)}
          </FormControl>
        );
      } else {
        fieldElement = (
          <TextField {...textFieldProps} id={name} label={label} />
        );
      }
      return (
        <Grid container xs={12}>
          {/* <Grid item xs={6}><Typography variant="caption" className={classes.row} color="textSecondary" gutterBottom>{label}</Typography></Grid> */}
          <Grid item xs={6}>
            {fieldElement}
          </Grid>
        </Grid>
      );
    } catch (error) {}
  }

  getListValueElement(fieldName, listValues, textFieldProps) {
    let element = null;
    try {
      fieldName = getStringParam(fieldName);
      listValues = getArrayParam(listValues);
      element = (
        <Select
          native
          {...textFieldProps}
          className=""
          input={
            <OutlinedInput
              name={"outline-input-" + fieldName}
              id={"outlined-" + fieldName + "-native-simple"}
              {...getObjectParam(textFieldProps.InputProps)}
            />
          }
        >
          {listValues.map((obj, index) => {
            return (
              <option key={"item-" + fieldName + "-" + index} value={obj.value}>
                {obj.label}
              </option>
            );
          })}
        </Select>
      );
    } catch (error) {}
    return element;
  }
  setFieldValueForEdit() {
    try {
      let positionId = getStringParam(this.props.positionId);
      let posDetails = this.props.posDetails;
      if (isValidParam(posDetails) && posDetails._id == positionId) {
        this.fields.forEach((field) => {
          if (field.type == "date") {
            this.state.fieldValueMap[field.name] = formatDate(
              posDetails[field.name]
            );
          } else if (field.type == "list") {
            this.state.fieldValueMap[field.name] =
              getStringParam(posDetails[field.name]) == ""
                ? null
                : getStringParam(posDetails[field.name]);
          } else if (field.type == "boolean") {
            this.state.fieldValueMap[field.name] = getBooleanParam(
              posDetails[field.name]
            );
          } else {
            this.state.fieldValueMap[field.name] = getStringParam(
              posDetails[field.name]
            );
          }
        });
      }
    } catch (error) {}
  }

  setFieldValue(fieldName, fieldType, event, value) {
    try {
      if (fieldType == "date") {
        this.state.fieldValueMap[fieldName] = event.target.value;
      } else if (fieldType == "list") {
        this.state.fieldValueMap[fieldName] =
          getStringParam(event.target.value) == ""
            ? null
            : getStringParam(event.target.value);
      } else if (fieldType == "boolean") {
        this.state.fieldValueMap[fieldName] = event.target.checked;
      } else {
        this.state.fieldValueMap[fieldName] = event.target.value;
      }
      this.setState({});
    } catch (error) {}
  }

  saveForm(positionId) {
    try {
      let params = getObjectParam(this.state.fieldValueMap);
      for (let key in params) {
        if (isValidParam(params[key])) {
          params[key] = getStringParam(params[key]);
        }
      }
      positionId = getStringParam(positionId);
      if (positionId != "") {
        params._id = positionId;
      }
      this.props.onFormSubmit(params);
    } catch (error) {}
  }

  render() {
    const { classes, pathName } = this.props;
    let positionId = getStringParam(this.props.positionId);

    return (
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <ApplicantBreadcrumbs {...this.props} />
          <CreatePosition />
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    posDetails: state.PosTableReducer.posDetails,
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      onFormSubmit,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(CreatePositionForm));

class ApplicantBreadcrumbs extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let positionName = isValidParam(this.props.posDetails)
      ? getStringParam(this.props.posDetails.positionName)
      : "New";
    return (
      <div style={{ marginTop: "30px" }}>
        <Breadcrumbs aria-label="Breadcrumb" style={{ margin: 10 }}>
          <Typography color="textPrimary" style={{ fontSize: 14 }}>
            <Link to={`/all-positions`}>All Positions</Link>
          </Typography>
          <Typography
            color="textPrimary"
            style={{ fontSize: 14, color: "#8f8f8f" }}
          >
            {positionName}
          </Typography>
        </Breadcrumbs>
      </div>
    );
  }
}
