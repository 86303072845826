import request from "../helpers/requestEngine";
// // import snackbar from "snackbar";
import { openDialog, closeDialog } from "./appActions";

export const flagApplied = () => {
  return {
    type: "APPLIED_FOR_AD",
  };
};

export const applyForAD = (e, { _id }) => {
  e.preventDefault();
  return (dispatch) => {
    dispatch(applyingForAD());
    return dispatch(requestForADApply(_id));
  };
};

function applyingForAD() {
  return {
    type: "APPLYING_FOR_AD",
  };
}

function requestForADApply(_id) {
  return (dispatch) => {
    request("post", "APPLY_FOR_AD", true, {
      ad_id: _id,
    }).then(
      (response) => {
        // snackbar.show(response.data.usrMsg);
        dispatch(openDialog(response.data.usrMsg));

        if (response.data.error) {
          dispatch(errorApplyingAD());
        } else {
          dispatch(flagApplied());
        }
      },
      (err) => {
        // snackbar.show("Something went wrong while applying for AD.");
        dispatch(openDialog("Something went wrong while applying for AD."));

        dispatch(errorApplyingAD());
      }
    );
  };
}

function errorApplyingAD() {
  return {
    type: "ERROR_APPLY_AD",
  };
}

export const changeCurrentAd = (currentAd, applied) => {
  return {
    type: "CURRENT_AD",
    currentAd: currentAd,
    applied: applied,
  };
};

export const getADs = (dept) => {
  return (dispatch) => {
    dispatch(fetchingADs());

    return dispatch(fetchADs(dept));
  };
};

function fetchingADs() {
  return {
    type: "FETCHING_ADS",
  };
}

function fetchADs(dept) {
  return (dispatch) => {
    return request("get", "AD", true, {}, { dept }).then(
      (response) => dispatch(fetchADsSuccess(response.data)),
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));

        dispatch(fetchADsFailed(err));
      }
    );
  };
}

export function fetchFilteredPositions(params) {
  return (dispatch) => {
    return request("get", "FILTER_POSITIONS", true, {}, params).then(
      (response) => dispatch(fetchADsSuccess(response.data)),
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));

        dispatch(fetchADsFailed(err));
      }
    );
  };
}

function fetchADsSuccess(data) {
  return {
    type: "ADS_FETCHED_SUCCESS",
    payload: { data: data.data, totalPages: data.totalPages },
  };
}

function fetchADsFailed(err) {
  return {
    type: "ADS_FETCHED_FAILED",
  };
}

export const getADApplicants = (ad_id) => {
  return (dispatch) => {
    return dispatch(fetchADApplicants(ad_id));
  };
};

function fetchADApplicants(ad_id) {
  return (dispatch) => {
    request("get", "GET_APPLICANT", true, null, {
      ad_id,
    }).then(
      (response) => dispatch(applicantsFetchedSuccess(response.data.data)),
      (err) => {
        // dispatch()
      }
    );
  };
}

function applicantsFetchedSuccess({ applicants, usersDetail }) {
  return {
    applicants,
    usersDetail,
    type: "APPLICANTS_FETCHED_SUCCESS",
  };
}

/************************** START: Get Applied On Positions ***************************/

export const getAppliedADs = (type, params) => {
  return (dispatch) => {
    dispatch(fetchingAppliedADs());

    return dispatch(fetchAppliedADs(type, params));
  };
};

function fetchingAppliedADs() {
  return {
    type: "FETCHING_APPLIED_ADS",
  };
}

function fetchAppliedADs(type, params) {
  return (dispatch) => {
    request("get", "GET_APPLIED_ON_POSITIONS_" + type, true, {}, params).then(
      (response) => dispatch(fetchAppliedADsSuccess(response.data)),
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));

        dispatch(fetchAppliedADsFailed(err));
      }
    );
  };
}

function fetchAppliedADsSuccess(data) {
  return {
    type: "APPLIED_ADS_FETCHED_SUCCESS",
    payload: data.data,
  };
}

function fetchAppliedADsFailed(err) {
  return {
    type: "APPLIED_ADS_FETCHED_FAILED",
  };
}
/************************** END: Get Applied On Positions ***************************/
