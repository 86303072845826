import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import bindActionCreators from "redux/es/bindActionCreators";
import withRouter from "react-router-dom/es/withRouter";
import connect from "react-redux/es/connect/connect";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {
  homeStyles as styles,
  profileMenuStyles,
} from "../helpers/materialStyles";
import { logout } from "../actions/appActions";

const Appbar = ({ classes, history, match, location, logout }) => {
  const getMenuItems = () => {
    let items = new Array();
    if (
      ![
        "/otp-phone",
        "/otp-phone-verify",
        "/email-verify-message",
        "/reset-password/:q",
        "/email-verify/:q",
      ].includes(location.pathname) &&
      !location.pathname.startsWith("/email-verify")
    ) {
      items.push(
        <Button
          className={
            location.pathname === "/login"
              ? [classes.button, classes.selectButton]
              : [classes.button]
          }
          onClick={() => {
            history.push("/login");
          }}
        >
          Login
        </Button>
      );
      items.push(
        <Button
          className={
            location.pathname === "/register"
              ? [classes.button, classes.selectButton]
              : [classes.button]
          }
          onClick={() => {
            history.push("/register");
          }}
        >
          Signup
        </Button>
      );
      items.push(
        <Button
          className={
            location.pathname === "/pricing"
              ? [classes.button, classes.selectButton]
              : [classes.button]
          }
          onClick={() => {
            history.push("/pricing");
          }}
        >
          Pricing
        </Button>
      );
      items.push(
        <Button
          className={
            location.pathname === "/tc"
              ? [classes.button, classes.selectButton]
              : [classes.button]
          }
          onClick={() => {
            history.push("/tc");
          }}
        >
          T&C
        </Button>
      );
      items.push(
        <Button
          className={
            location.pathname === "/privacypolicy"
              ? [classes.button, classes.selectButton]
              : [classes.button]
          }
          onClick={() => {
            history.push("/privacypolicy");
          }}
        >
          Privacy Policy
        </Button>
      );
      items.push(
        <Button
          className={
            location.pathname === "/contactus"
              ? [classes.button, classes.selectButton]
              : [classes.button]
          }
          onClick={() => {
            history.push("/contactus");
          }}
        >
          Contact Us
        </Button>
      );
    } else {
      items.push(
        <Button
          className={[classes.button]}
          onClick={() => {
            logout();
          }}
        >
          Logout
        </Button>
      );
    }

    return items;
  };
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Grid container justify="space-between">
        <Grid item className={classes.appTitleContainer}>
          <Typography variant="h3" className={classes.appTitle}>
            Celebbook
          </Typography>
        </Grid>
        <Grid item className={classes.appMenuContainer}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              height: "100%",
              alignItems: "center",
              margin: "0px 8px",
            }}
          >
            <div>{getMenuItems()}</div>
          </div>
        </Grid>
      </Grid>
    </AppBar>
  );
};
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Appbar))
);
