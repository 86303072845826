import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getShareBids } from "../../actions/shareBidActions";
import Moment from "react-moment";
import {
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  Button,
} from "@material-ui/core";

const BidFeed = ({ shareBids, dispatch }) => {
  const [highestOpen, setHighestOpen] = useState(null);
  useEffect(() => {
    dispatch(getShareBids());
  }, []);

  useEffect(() => {
    if (shareBids && shareBids.length > 0) {
      let a = shareBids.filter((bid) => bid.type === "Open")[0];
      a && setHighestOpen(a.bidamt);
    }
  }, [shareBids]);

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      alignContent="center"
      style={{ margin: 12 }}
    >
      <Grid item xs={12} style={{ margin: 12 }}>
        <Typography variant="h6">Previous Bids</Typography>

        <Typography variant="h5" color="secondary">
          {highestOpen &&
            "Highest Open Bid Amount: ₹ " +
              Number(highestOpen).toLocaleString("en-IN")}
        </Typography>

        <Paper style={{ margin: 12 }} elevation={0}>
          <TableContainer>
            <Table style={{ width: "100%" }} size="small">
              <TableBody>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Bid Username</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Bid Amount</TableCell>
                </TableRow>
                {shareBids &&
                  shareBids.map((bid, key) => (
                    <TableRow key={key}>
                      <TableCell>
                        <Moment format="YYYY-MM-DD HH:mm:ss">
                          {bid.createdAt}
                        </Moment>
                      </TableCell>
                      <TableCell>{bid.company}</TableCell>
                      <TableCell>{bid.type}</TableCell>
                      {key === 0 && bid.type === "Open" ? (
                        <TableCell>
                          <Typography variant="h6" color="secondary">
                            {bid.bidamt}
                          </Typography>
                        </TableCell>
                      ) : (
                        <TableCell>{bid.bidamt}</TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  shareBids: state.shareBidReducer.shareBids,
  fetchingShareBids: state.bidReducer.fetchingShareBids,
});

export default connect(mapStateToProps)(BidFeed);
