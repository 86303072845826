import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import VideoDialog from "./UserProfile/VideoDialog";
import { constants } from "../helpers/constants";

const ProfileInfo = ({ userDetails, classes, auth }) => {
  const [openVideo, setOpenVideo] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [videoSrc, setVideoSrc] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [links, setLinks] = useState([]);
  const [titles, setTitles] = useState([]);

  useEffect(() => {
    if (userDetails) {
      setLinks(
        [
          userDetails.link1_url,
          userDetails.link2_url,
          userDetails.link3_url,
          userDetails.link4_url,
        ].filter((link) => link !== "")
      );
      setTitles([
        userDetails.link1_title,
        userDetails.link2_title,
        userDetails.link3_title,
        userDetails.link4_title,
      ]);
    }
  }, [userDetails]);

  const handleOpenVideo = (src, title, index) => {
    setCurrentIndex(index - 1);
    setVideoSrc(src);
    setVideoTitle(title);
    setOpenVideo(true);
  };

  const handleNext = () => {
    if (currentIndex < links.length - 1) {
      setVideoSrc(links[currentIndex + 1]);
      setVideoTitle(titles[currentIndex + 1]);
      setCurrentIndex(currentIndex + 1);
    } else if (links.length > 0) {
      setVideoSrc(links[0]);
      setVideoTitle(titles[0]);
      setCurrentIndex(0);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setVideoSrc(links[currentIndex - 1]);
      setVideoTitle(titles[currentIndex - 1]);
      setCurrentIndex(currentIndex - 1);
    } else if (links.length > 0) {
      setVideoSrc(links[links.length - 1]);
      setVideoTitle(titles[links.length - 1]);
      setCurrentIndex(links.length - 1);
    }
  };

  const fetchUserStateAndCountry = (showValues) => (
    <>
      <Grid item xs={4} className={classes.fieldGridLabel}>
        <Typography
          variant="span"
          className={classes.fieldLabel}
          style={{ color: constants.PRIMARY_COLOR }}
          noWrap={true}
        >
          State
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridValue}>
        <Typography variant="span" className={classes.fieldValue} noWrap={true}>
          {showValues ?userDetails.state:""}
        </Typography>
      </Grid>

      <Grid item xs={4} className={classes.fieldGridLabel}>
        <Typography
          variant="span"
          className={classes.fieldLabel}
          style={{ color: constants.PRIMARY_COLOR }}
          noWrap={true}
        >
          Country
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridValue}>
        <Typography variant="span" className={classes.fieldValue} noWrap={true}>
          {showValues ? userDetails.country:""}
        </Typography>
      </Grid>
      {showValues && userDetails.country === "India" && (
        <>
          <Grid item xs={4} className={classes.fieldGridLabel}>
            <Typography
              variant="span"
              className={classes.fieldLabel}
              style={{ color: constants.PRIMARY_COLOR }}
              noWrap={true}
            >
              Industry
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.fieldGridValue}>
            <Typography
              variant="span"
              className={classes.fieldValue}
              noWrap={true}
            >
              {userDetails.industry}
            </Typography>
          </Grid>
        </>
      )}
    </>
  );

  const isJobSeeker =
    userDetails &&
    userDetails.baseUserType &&
    userDetails.baseUserType === "JobSeeker";
  const isEmployer =
    userDetails &&
    userDetails.baseUserType &&
    userDetails.baseUserType === "Employer";
  const isSocial =
    userDetails &&
    userDetails.baseUserType &&
    userDetails.baseUserType === "SocialMediaUser";
  const isFemale =
    userDetails && userDetails.gender && userDetails.gender === "Female";
  return (
    <Grid
      container
      xs={12}
      justify="space-around"
      alignItems="flex-start"
      style={{ marginTop: 0 }}
    >
      {openVideo && (
        <VideoDialog
          open={openVideo}
          src={videoSrc}
          handleClose={() => {
            setOpenVideo(false);
            setVideoSrc("");
            setVideoTitle("");
          }}
          handleNextClick={() => handleNext()}
          handlePrevClick={() => handlePrevious()}
          title={videoTitle}
          classes={classes}
          links={links}
        />
      )}
      <Grid
        key={"col-1"}
        container
        justify="space-around"
        xs={6}
        className={classes.columnContainer}
        // justify="center"
      >
        {isEmployer && (
          // <Grid container>
          <>
            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                Production House
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.bnr}
              </Typography>
            </Grid>
          </>
          // </Grid>
        )}

        <Grid item xs={4} className={classes.fieldGridLabel}>
          <Typography
            variant="span"
            className={classes.fieldLabel}
            style={{ color: constants.PRIMARY_COLOR }}
            noWrap={true}
          >
            First Name
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.fieldGridValue}>
          <Typography
            variant="span"
            className={classes.fieldValue}
            noWrap={true}
          >
            {userDetails.firstname}
          </Typography>
        </Grid>

        <Grid item xs={4} className={classes.fieldGridLabel}>
          <Typography
            variant="span"
            className={classes.fieldLabel}
            style={{ color: constants.PRIMARY_COLOR }}
            noWrap={true}
          >
            Last Name
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.fieldGridValue}>
          <Typography
            variant="span"
            className={classes.fieldValue}
            noWrap={true}
          >
            {userDetails.lastname}
          </Typography>
        </Grid>
        {auth && (
          <>
            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                Bid Alias Name
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.bidAlias || " "}
              </Typography>
            </Grid>
          </>
        )}

        <Grid item xs={4} className={classes.fieldGridLabel}>
          <Typography
            variant="span"
            className={classes.fieldLabel}
            style={{ color: constants.PRIMARY_COLOR }}
            noWrap={true}
          >
            User Type
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.fieldGridValue}>
          <Typography
            variant="span"
            className={classes.fieldValue}
            noWrap={true}
          >
            {userDetails.baseUserType}
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.fieldGridLabel}>
          <Typography
            variant="span"
            className={classes.fieldLabel}
            style={{ color: constants.PRIMARY_COLOR }}
            noWrap={true}
          >
            Gender
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.fieldGridValue}>
          <Typography
            variant="span"
            className={classes.fieldValue}
            noWrap={true}
          >
            {userDetails.gender}
          </Typography>
        </Grid>

        <Grid item xs={4} className={classes.fieldGridLabel}>
          <Typography
            variant="span"
            className={classes.fieldLabel}
            style={{ color: constants.PRIMARY_COLOR }}
            noWrap={true}
          >
            DOB
          </Typography>
        </Grid>
        {auth ? (
          <Grid item xs={4} className={classes.fieldGridValue}>
            <Typography
              variant="span"
              className={classes.fieldValue}
              noWrap={true}
            >
              {new Date(userDetails.dob).toLocaleDateString()}
            </Typography>
          </Grid>
        ) : userDetails.showBirthDate_Month ? (
          <Grid item xs={4} className={classes.fieldGridValue}>
            <Typography
              variant="span"
              className={classes.fieldValue}
              noWrap={true}
            >
              {userDetails.showBirthDate_Month && userDetails.showBirthYear
                ? new Date(userDetails.dob).toLocaleDateString()
                : new Date(userDetails.dob).getDate() +
                  "/" +
                  new Date(userDetails.dob).getMonth()}
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={4} className={classes.fieldGridValue}>
            <Typography
              variant="span"
              className={classes.fieldValue}
              noWrap={true}
            >
              {" "}
            </Typography>
          </Grid>
        )}

        <Grid item xs={4} className={classes.fieldGridLabel}>
          <Typography
            variant="span"
            className={classes.fieldLabel}
            style={{ color: constants.PRIMARY_COLOR }}
            noWrap={true}
          >
            Education
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.fieldGridValue}>
          <Typography
            variant="span"
            className={classes.fieldValue}
            noWrap={true}
          >
            {userDetails.education}
          </Typography>
        </Grid>

        {isJobSeeker && (
          // <Grid container>
          <>
            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                Passport
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.passport === "true" ? "yes" : "no"}
              </Typography>
            </Grid>
          </>
        )}

        {isFemale && (isJobSeeker || isEmployer) && (
          <>
            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                Chest
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.chest}
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                Waist
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.waist}
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                Hip
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.hips}
              </Typography>
            </Grid>
          </>
        )}
        {(isJobSeeker || isEmployer) && (
          <>
            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                Height
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.heightfoot &&
                  userDetails.heightfoot +
                    " feet " +
                    userDetails.heightinches +
                    " inches"}
              </Typography>
            </Grid>

            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                Weight
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.weight && userDetails.weight + " Kg"}
              </Typography>
            </Grid>
          </>
        )}
        {/* {(isJobSeeker || isEmployer) && (
          <> */}
        <Grid item xs={4} className={classes.fieldGridLabel}>
          <Typography
            variant="span"
            className={classes.fieldLabel}
            style={{ color: constants.PRIMARY_COLOR }}
            noWrap={true}
          >
            Link 1 Title
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.fieldGridValue}>
          <Typography
            variant="span"
            className={classes.fieldValue}
            noWrap={true}
          >
            <Link
              href="#"
              onClick={() =>
                handleOpenVideo(
                  userDetails.link1_url,
                  userDetails.link1_title,
                  1
                )
              }
              variant="body2"
            >
              {userDetails.link1_title}
            </Link>
          </Typography>
        </Grid>

        <Grid item xs={4} className={classes.fieldGridLabel}>
          <Typography
            variant="span"
            className={classes.fieldLabel}
            style={{ color: constants.PRIMARY_COLOR }}
            noWrap={true}
          >
            Link 2 Title
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.fieldGridValue}>
          <Typography
            variant="span"
            className={classes.fieldValue}
            noWrap={true}
          >
            <Link
              href="#"
              onClick={() =>
                handleOpenVideo(
                  userDetails.link2_url,
                  userDetails.link2_title,
                  2
                )
              }
              variant="body2"
            >
              {userDetails.link2_title}
            </Link>
          </Typography>
        </Grid>

        <Grid item xs={4} className={classes.fieldGridLabel}>
          <Typography
            variant="span"
            className={classes.fieldLabel}
            style={{ color: constants.PRIMARY_COLOR }}
            noWrap={true}
          >
            Link 3 Title
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.fieldGridValue}>
          <Typography
            variant="span"
            className={classes.fieldValue}
            noWrap={true}
          >
            <Link
              href="#"
              onClick={() =>
                handleOpenVideo(
                  userDetails.link3_url,
                  userDetails.link3_title,
                  3
                )
              }
              variant="body2"
            >
              {userDetails.link3_title}
            </Link>
          </Typography>
        </Grid>

        <Grid item xs={4} className={classes.fieldGridLabel}>
          <Typography
            variant="span"
            className={classes.fieldLabel}
            style={{ color: constants.PRIMARY_COLOR }}
            noWrap={true}
          >
            Link 4 Title
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.fieldGridValue}>
          <Typography
            variant="span"
            className={classes.fieldValue}
            noWrap={true}
          >
            <Link
              href="#"
              onClick={() =>
                handleOpenVideo(
                  userDetails.link4_url,
                  userDetails.link4_title,
                  4
                )
              }
              variant="body2"
            >
              {userDetails.link4_title}
            </Link>
          </Typography>
        </Grid>
        {/* </>
        )} */}
      </Grid>
      <Grid
        key={"col-2"}
        container
        justify="space-around"
        xs={6}
        className={classes.columnContainer}
      >
        {isJobSeeker && (
          <>
            {" "}
            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                Mainrole
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.mainrole}
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              className={classes.fieldGridLabel}
              style={{ marginBottom: -2 }}
            >
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                Other roles
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              className={classes.fieldGridValue}
              style={{ marginBottom: -2 }}
            >
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {"• " + userDetails.otherroles[0]}
              </Typography>
            </Grid>
            {userDetails.otherroles[1] && (
              <>
                <Grid
                  item
                  xs={4}
                  style={{ margin: -2 }}
                  className={classes.fieldGridValue}
                >
                  <Typography
                    variant="span"
                    className={classes.fieldValue}
                    noWrap={true}
                  >
                    {" "}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ margin: -2 }}
                  className={classes.fieldGridValue}
                >
                  <Typography
                    variant="span"
                    className={classes.fieldValue}
                    noWrap={true}
                  >
                    {"• " + userDetails.otherroles[1]}
                  </Typography>
                </Grid>
              </>
            )}
            {userDetails.otherroles[2] && (
              <>
                <Grid
                  item
                  xs={4}
                  className={classes.fieldGridValue}
                  style={{ marginTop: -2 }}
                >
                  <Typography
                    variant="span"
                    className={classes.fieldValue}
                    noWrap={true}
                  >
                    {" "}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  className={classes.fieldGridValue}
                  style={{ marginTop: -2 }}
                >
                  <Typography
                    variant="span"
                    className={classes.fieldValue}
                    noWrap={true}
                  >
                    {"• " + userDetails.otherroles[2]}
                  </Typography>
                </Grid>
              </>
            )}
          </>
        )}

        {auth && (
          <>
            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                Email
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.email}
              </Typography>
            </Grid>
          </>
        )}

        <Grid item xs={4} className={classes.fieldGridLabel}>
          <Typography
            variant="span"
            className={classes.fieldLabel}
            style={{ color: constants.PRIMARY_COLOR }}
            noWrap={true}
          >
            Mobile
          </Typography>
        </Grid>
        {auth ? (
          <>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.phone}
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.showMobileNumber ? userDetails.phone : " "}
              </Typography>
            </Grid>
          </>
        )}

        {auth ? (
          <>
            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                House No
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.houseno}
              </Typography>
            </Grid>

            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                Street
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.street}
              </Typography>
            </Grid>

            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                City
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.city}
              </Typography>
            </Grid>
            {userDetails.country === "India" && (
              <>
                <Grid item xs={4} className={classes.fieldGridLabel}>
                  <Typography
                    variant="span"
                    className={classes.fieldLabel}
                    style={{ color: constants.PRIMARY_COLOR }}
                    noWrap={true}
                  >
                    District
                  </Typography>
                </Grid>
                <Grid item xs={4} className={classes.fieldGridValue}>
                  <Typography
                    variant="span"
                    className={classes.fieldValue}
                    noWrap={true}
                  >
                    {userDetails.district}
                  </Typography>
                </Grid>
              </>
            )}

            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                Pincode
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.pincode}
              </Typography>
            </Grid>

            {
              fetchUserStateAndCountry(true)
            }
          </>
        ) : (
          <>
            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                House No
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.showAddress ? userDetails.houseno : " "}
              </Typography>
            </Grid>

            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                Street
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.showAddress ? userDetails.street : " "}
              </Typography>
            </Grid>

            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                City
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.showAddress ? userDetails.city : " "}
              </Typography>
            </Grid>

            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                District
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.showAddress ? userDetails.district : " "}
              </Typography>
            </Grid>

            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                Pincode
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.showAddress ? userDetails.pincode : " "}
              </Typography>
            </Grid>

            {
              fetchUserStateAndCountry(userDetails.showAddress)
            }
          </>
        )}

        {(isJobSeeker || isEmployer) && (
          <>
            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                Manager Name
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.managerName}
              </Typography>
            </Grid>

            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                Manager Mobile
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.managerMobile}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ProfileInfo;
