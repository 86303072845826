import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { chatEventTarget } from "../../actions/chatActions";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Avatar from "@material-ui/core/Avatar";
import getURL from "../../helpers/apis";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { openAvatarModal } from "../../actions/appActions";
import { clearUsersList } from "../../actions/userDetailsAction";
import PersonIcon from "@material-ui/icons/Person";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  inline: {
    display: "inline",
    // maxWidth: "inherit",
  },
}));

const SidePanelUserComponent = ({
  switchCurrentSelectedUserForChat,
  user,
  selected,
  newMessage,
  unreadData,
  clear,
}) => {
  const [unreadCount, setUnreadCount] = useState(
    user.unreadMessages === 0 ? null : user.unreadMessages
  );
  const [recentMessage, setRecentMessage] = useState({
    message: user.recentMessage,
    timestamp: user.timestamp,
  });
  const selectCurrentUser = () => {
    clear();
    switchCurrentSelectedUserForChat(user);
  };
  const classes = useStyles();
  const dispatch = useDispatch();
  const rootUrl = getURL("UPLOADS");
  useEffect(() => {
    if (newMessage) {
      if (user.user_id === newMessage.from || user.user_id === newMessage.to) {
        setRecentMessage(newMessage);
      }
    }
  }, [newMessage && newMessage._id]);

  useEffect(() => {
    if (unreadData) {
      if (user.user_id === unreadData.from || user.user_id === unreadData.to) {
        setUnreadCount(unreadData.count);
      }
    }
  }, [unreadData]);

  let profilePhoto = null;
  if (user.profilepic) {
    profilePhoto = user.profilepic;
  }

  return (
    <Paper elevation={0} className={classes.root}>
      <ListItem
        alignItems="flex-start"
        onClick={selectCurrentUser}
        button
        divider
        // disabled={!user.isChatEligible}
        selected={selected}
      >
        <ListItemAvatar>
          {profilePhoto ? (
            <Avatar
              src={process.env.REACT_APP_BUCKET_NAME + profilePhoto}
              onClick={(e) => dispatch(openAvatarModal(process.env.REACT_APP_BUCKET_NAME + profilePhoto))}
            />
          ) : (
            <Avatar alt="owner" onClick={(e) => dispatch(openAvatarModal())}>
              <PersonIcon />
            </Avatar>
          )}
        </ListItemAvatar>
        <ListItemText
          style={{ overflow: "hidden" }}
          // primary={option.user_follow_customname}
          // secondary={
          //   option.user_follow_customname.startsWith("@")
          //     ? option.username
          //     : option.username.replace("@", "")
          // }
          primary={
            <Link to={`/user_profile/${user.user_id}`}>
              {user.followingUser_CustomName !== ""
                ? user.followingUser_CustomName
                : user.username || ""}
            </Link>
          }
          secondary={
            <Typography
              noWrap
              component="span"
              variant="body2"
              className={classes.inline}
              color={user.isChatEligible ? "textPrimary" : "secondary"}
            >
              {user.isChatEligible
                ? recentMessage.message
                  ? recentMessage.message
                  : user.followingUser_CustomName !== ""
                  ? user.username.replace("@", "")
                  : user.username
                : user.blockStatus}
            </Typography>
          }
        />

        <Typography
          align="right"
          component="span"
          variant="body2"
          color="textSecondary"
          style={{ margin: 4, position: "absolute", right: 4 }}
        >
          {recentMessage.timestamp
            ? new Date(recentMessage.timestamp).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })
            : ""}
        </Typography>
        {unreadCount && (
          <Chip
            label={unreadCount}
            color="secondary"
            size="small"
            style={{ margin: 4, alignSelf: "flex-end" }}
          />
        )}
      </ListItem>
    </Paper>
  );
};

export default SidePanelUserComponent;
