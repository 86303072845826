import React, { Fragment, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Popper from "@material-ui/core/Popper";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import Container from "@material-ui/core/Container";
import { connect } from "react-redux";
import Divider from "@material-ui/core/Divider";
import { Redirect, Link as RouterLink, withRouter } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { getUserDetails, getRoles } from "../../actions/userDetailsAction";
import { openDialog } from "../../actions/appActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import { completeSignUp } from "../../actions/loginActions";
import useStyles from "./styles";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function CompleteSignUp({ dispatch, userDetail, roles }) {
  const classes = useStyles();
  const [managerName, setManagerName] = useState("");
  const [managerMbNo, setManagerMbNo] = useState("");
  const [mainRole, setMainRole] = useState("");
  const [weight, setWeight] = useState("");
  const [feet, setFeet] = useState("");
  const [passport, setPassport] = useState(false);
  const [inches, setInches] = useState("");
  const [education, setEducation] = useState("");
  const [link1Title, setLink1Title] = useState("");
  const [link1Url, setLink1Url] = useState("");
  const [link2Title, setLink2Title] = useState("");
  const [link2Url, setLink2Url] = useState("");
  const [link3Title, setLink3Title] = useState("");
  const [link3Url, setLink3Url] = useState("");
  const [link4Title, setLink4Title] = useState("");
  const [link4Url, setLink4Url] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [otherRoles, setOtherRole] = useState([]);
  const [waist, setWaist] = useState("");
  const [hips, setHips] = useState("");
  const [chest, setChest] = useState("");

  const token = localStorage.getItem("validationJWT");
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    dispatch(getUserDetails(token, userId));
    dispatch(getRoles());
  }, [dispatch, token, userId]);

  const isJobSeeker =
    userDetail &&
    userDetail.baseUserType &&
    userDetail.baseUserType === "JobSeeker";
  const isEmployer =
    userDetail &&
    userDetail.baseUserType &&
    userDetail.baseUserType === "Employer";
  const isSocial =
    userDetail &&
    userDetail.baseUserType &&
    userDetail.baseUserType === "SocialMediaUser";
  const isFemale =
    userDetail && userDetail.gender && userDetail.gender === "Female";
  const onSubmit = (e) => {
    e.preventDefault();
    if (isEmployer && companyName === "") {
      return dispatch(openDialog("Production house can't be empty."));
    }
    if (Number(inches) > 12) {
      return dispatch(openDialog("height in inches cannot be greater than 12"));
    }
    if (Number(feet) > 9) {
      return dispatch(openDialog("height in feet cannot be greater than 12"));
    }
    const params = {
      education,
      mainrole: mainRole,
      link1_title: link1Title,
      link1_url: link1Url,
      link2_title: link2Title,
      link2_url: link2Url,
      link3_title: link3Title,
      link3_url: link3Url,
      link4_title: link4Title,
      link4_url: link4Url,
      heightfoot: feet,
      heightinches: inches,
      weight,
      managerMobile: managerMbNo,
      managerName: managerName,
      bnr: companyName,
      otherroles: otherRoles.filter((or) => or !== mainRole),
      passport,
      waist,
      hips,
      chest,
    };

    return dispatch(completeSignUp(params, token));
  };

  if (!token || !userId) {
    return <Redirect to="/login" />;
  }

  if (userDetail && userDetail.education) {
    return <Redirect to="/position" />;
  }

  const PopperMy = function (props) {
    return (
      <Popper {...props} style={{ width: 212 }} placement="bottom-start" />
    );
  };

  return (
    <Grid
      container
      style={{ marginTop: 10 }}
      justify="center"
      alignItems="center"
    >
      {/* <CssBaseline /> */}
      <Grid xs={8} justify="center" alignItems="center">
        <Grid
          container
          justify="center"
          alignItems="center"
          alignContent="center"
        >
          {/* <Grid xs={10} justify="center" alignItems="center">
            <Typography display="block" style={{ float: "right" }}>
              <Link
                size="small"
                variant="outlined"
                component={RouterLink}
                to={"/login"}
              >
                Go back
              </Link>{" "}
            </Typography>
          </Grid> */}
          <Grid xs={10} justify="center" alignItems="center">
            <Typography
              component="h1"
              variant="h4x"
              display="block"
              style={{
                margin: 10,
                textAlign: "center",
              }}
            >
              Complete Sign Up
            </Typography>
          </Grid>
        </Grid>

        <Divider />
        <form
          // className={classes.form}
          onSubmit={onSubmit}
        >
          <Grid
            container
            spacing={2}
            justify="space-evenly"
            alignItems="center"
            style={{ marginTop: 25 }}
          >
            <Grid item xs={4} justify="center" alignItems="center">
              <Grid container spacing={0} justify="center" alignItems="center">
                <Grid container spacing={0}>
                  {isEmployer && (
                    <>
                      <Grid item xs={6}>
                        <Typography style={{ margin: "12px" }}>
                          Production House*
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          fullWidth
                          // label="Production House"
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                        />
                      </Grid>
                    </>
                  )}

                  {(isJobSeeker || isEmployer) && (
                    <>
                      <Grid item xs={6}>
                        <Typography style={{ margin: "12px" }}>
                          Manager Name
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          // label="Manager Name"
                          name="managerName"
                          value={managerName}
                          onChange={(e) => setManagerName(e.target.value)}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Typography style={{ margin: "12px" }}>
                          Manager Mb No
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          // label="Manager Mb No"
                          name="managerMbNo"
                          value={managerMbNo}
                          onChange={(e) => setManagerMbNo(e.target.value)}
                        />
                      </Grid>

                      {isJobSeeker && (
                        <>
                          <Grid item xs={6}>
                            <Typography style={{ margin: "12px" }}>
                              Main Role
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Autocomplete
                              PopperComponent={PopperMy}
                              options={roles}
                              getOptionLabel={(option) => option}
                              onChange={(e, object) => setMainRole(object)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  // label="Main Role"
                                  fullWidth
                                  required
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography style={{ margin: "12px" }}>
                              Other Roles
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Autocomplete
                              PopperComponent={PopperMy}
                              multiple
                              disabled={mainRole === ""}
                              options={roles.filter(
                                (role) =>
                                  role &&
                                  role.toLowerCase() !== "model" &&
                                  role.toLowerCase() !== "actor"
                              )}
                              disableCloseOnSelect={otherRoles.length < 2}
                              getOptionLabel={(option) => option}
                              onChange={(e, object) =>
                                setOtherRole(object.slice(-3))
                              }
                              value={
                                mainRole
                                  ? otherRoles.filter((or) => or !== mainRole)
                                  : otherRoles
                              }
                              renderOption={(option, { selected }) => (
                                <Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option}
                                </Fragment>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  // label="Other Roles"
                                  // placeholder="Other Roles"
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                  <Grid item xs={6}>
                    <Typography style={{ margin: "12px" }}>
                      Education
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth required>
                      {/* <InputLabel>Education</InputLabel> */}
                      <Select
                        value={education}
                        onChange={(e) => setEducation(e.target.value)}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"Graduation"}>Graduation</MenuItem>
                        <MenuItem value={"Post Graduation"}>
                          Post Graduation
                        </MenuItem>
                        <MenuItem value={"10th Grade"}>10th Grade</MenuItem>
                        <MenuItem value={"Below 10th Grade"}>
                          Below 10th Grade
                        </MenuItem>
                        <MenuItem value={"10+2"}>10+2</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ margin: "12px" }}>Weight</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      fullWidth
                      // label="Weight"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">Kg</InputAdornment>
                        ),
                      }}
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ margin: "12px" }}>Height</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          required
                          fullWidth
                          // label="Height"
                          // InputProps={{
                          //   startAdornment: (
                          //     <InputAdornment position="start">
                          //       Feet
                          //     </InputAdornment>
                          //   ),
                          // }}
                          error={Number(feet) > 9}
                          helperText={
                            Number(feet) > 9
                              ? "feet cannot be greater than 9"
                              : ""
                          }
                          value={feet}
                          onChange={(e) => setFeet(e.target.value)}
                        />
                        <FormHelperText id="standard-weight-helper-text">
                          Feet
                        </FormHelperText>
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          required
                          fullWidth
                          // label="Height"
                          // InputProps={{
                          //   startAdornment: (
                          //     <InputAdornment position="start">
                          //       Inches
                          //     </InputAdornment>
                          //   ),
                          // }}
                          error={Number(inches) > 12}
                          helperText={
                            Number(inches) > 12
                              ? "inches cannot be greater than 12"
                              : ""
                          }
                          value={inches}
                          onChange={(e) => setInches(e.target.value)}
                        />
                        <FormHelperText id="standard-weight-helper-text">
                          Inches
                        </FormHelperText>
                      </Grid>
                    </Grid>
                  </Grid>

                  {isFemale && (
                    <>
                      <Grid item xs={6}>
                        <Typography style={{ margin: "12px" }}>
                          Chest
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          fullWidth
                          // label="Chest"
                          value={chest}
                          onChange={(e) =>
                            Number(e.target.value) < 100 &&
                            setChest(e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography style={{ margin: "12px" }}>
                          Waist
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          fullWidth
                          // label="Waist"
                          value={waist}
                          onChange={(e) =>
                            Number(e.target.value) < 100 &&
                            setWaist(e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography style={{ margin: "12px" }}>Hips</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          fullWidth
                          // label="Hips"
                          value={hips}
                          onChange={(e) =>
                            Number(e.target.value) < 100 &&
                            setHips(e.target.value)
                          }
                        />
                      </Grid>
                    </>
                  )}

                  {isJobSeeker && (
                    <>
                      <Grid item xs={6}>
                        <Typography style={{ margin: "12px" }}>
                          Passport
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={passport}
                              onChange={(e) => setPassport(e.target.value)}
                            />
                          }
                          // label="Passport"
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4} justify="center" alignItems="center">
              <Grid container spacing={0} justify="center" alignItems="center">
                <Grid item xs={6}>
                  <Typography style={{ margin: "12px" }}>
                    Link 1 Title
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="link1Title"
                    // label="Link 1 Title"
                    value={link1Title}
                    onChange={(e) => setLink1Title(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography style={{ margin: "12px" }}>Link 1 URL</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="link1Url"
                    // label="Link 1 URL"
                    value={link1Url}
                    onChange={(e) => setLink1Url(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography style={{ margin: "12px" }}>
                    Link 2 Title
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="link2Title"
                    // label="Link 2 Title"
                    value={link2Title}
                    onChange={(e) => setLink2Title(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography style={{ margin: "12px" }}>Link 2 URL</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="link2Url"
                    // label="Link 2 URL"
                    value={link2Url}
                    onChange={(e) => setLink2Url(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography style={{ margin: "12px" }}>
                    Link 3 Title
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="link3Title"
                    // label="Link 3 Title"
                    value={link3Title}
                    onChange={(e) => setLink3Title(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography style={{ margin: "12px" }}>Link3 URL</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="link3Url"
                    // label="Link3 URL"
                    value={link3Url}
                    onChange={(e) => setLink3Url(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography style={{ margin: "12px" }}>
                    Link 4 Title
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="link4Title"
                    // label="Link 4 Title"
                    value={link4Title}
                    onChange={(e) => setLink4Title(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography style={{ margin: "12px" }}>Link 4 URL</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="link4Url"
                    // label="Link4 URL"
                    value={link4Url}
                    onChange={(e) => setLink4Url(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} align="center">
              <Button
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                style={{ textTransform: "none" }}
              >
                Submit
              </Button>
            </Grid>
            {!(isJobSeeker || isEmployer) && (
              <Grid item xs={6} align="center">
                <Button
                  size="large"
                  variant="contained"
                  color="secondary"
                  className={classes.submit}
                  onClick={() => {
                    window.localStorage.setItem(
                      "jwt",
                      window.localStorage.getItem("validationJWT")
                    );
                    window.location.reload();
                  }}
                  style={{ textTransform: "none" }}
                >
                  Skip
                </Button>
              </Grid>
            )}
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    userDetail: {
      ...state.userDetailsReducer.userDetail,
    },
    // roles: state.rolesReducer.roles,
    roles:
      (state.rolesReducer &&
        state.rolesReducer.roles &&
        state.rolesReducer.roles.map((obj) => obj.role)) ||
      [],
  };
}
export default connect(mapStateToProps)(CompleteSignUp);
