import React, { Fragment, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  Divider,
  AppBar,
  Tab,
  Tabs,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PostForm from "./PostForm";
import PostFeed from "./PostFeed";

const useStyles = makeStyles({
  paper: {
    flexGrow: 1,
  },
  tabIndicator: {
    height: "2.5px",
    backgroundColor: "#3f51b5",
  },
});

const PostsComponent = ({ userDetail, match }) => {
  const classes = useStyles();

  const [userId, setUserId] = useState(null);
  const [value, setValue] = useState(0);

  useEffect(() => {
    userDetail._id && setUserId(userDetail._id);
  }, [match.path, userDetail]);

  return (
    <Grid container style={{ marginTop: 20 }}>
      <Grid container justify="center">
        <PostForm user_id={userId} value={value} />
      </Grid>
      <Grid
        container
        justify="center"
        style={{ height: window.innerHeight - 150 + "px", overflowY: "scroll" }}
      >
        <AppBar position="sticky" color="default" style={{ maxHeight: 32 }}>
          <Tabs
            TabIndicatorProps={{ className: classes.tabIndicator }}
            value={value}
            onChange={(e, n) => setValue(n)}
            centered
            indicatorColor="primary"
            textColor="primary"
            style={{ minHeight: 32 }}
          >
            {/* <Tab
              label="Trending"
              disableRipple
              style={{ textTransform: "none" }}
            /> */}
            <Tab
              label="Following"
              disableRipple
              style={{ textTransform: "none", padding: 0, minHeight: 32 }}
            />
            <Tab
              label="My Posts"
              disableRipple
              style={{ textTransform: "none", padding: 0, minHeight: 32 }}
            />
          </Tabs>
        </AppBar>

        <PostFeed user_id={userId} value={value} />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  userDetail: state.userDetailsReducer.userDetail,
});

export default connect(mapStateToProps)(withRouter(PostsComponent));
