import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Popper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Popover,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getPositionDetailsById } from "../../../src/actions/getPosActions";
import { getUserAddress } from "../../../src/actions/applicantsDetailsActions";
import { useSelector, useDispatch } from "react-redux";
import { getRoles, getJobTypes } from "../../actions/userDetailsAction";
import {
  requestCountries,
  requestStates,
  requestIndustries,
} from "../../actions/registerActions";
import { onFormSubmit } from "../../actions/postPosActions";
import useStyles from "./styles";
import { constants } from "../../helpers/constants";
import SimplePopover from "../SimplePopover";

import moment from "moment";

const gendersArray = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

// const positionTypeArray = [
//   { value: "FullTime", label: "Full Time" },
//   { value: "Contract", label: "Contract" },
// ];

const CreatePosition = (props) => {
  const {
    dispatch,
    countriesList,
    roles,
    jobtypes,
    history,
    statesList,
    industriesList,
  } = props;
  const classes = useStyles();
  const [role, setRole] = useState(null);
  const [title, setTitle] = useState("");
  const [otherrolename, setOtherrolename] = useState("");
  const [compensation, setCompensation] = useState("");
  const [amount, setAmount] = useState("");
  const [ageMin, setAgeMin] = useState("");
  const [ageMax, setAgeMax] = useState("");
  const [gender, setGender] = useState("");
  const [howMany, setHowMany] = useState("");
  const [lastDate, setLastDate] = useState("");
  const [positionType, setPositionType] = useState("");
  const [description, setDescription] = useState("");
  const [houseno, setHouseno] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [industry, setIndustry] = useState("");
  const [pincode, setPincode] = useState("");
  const [otherRolesCanApply, setOtherRolesCanApply] = useState(false);
  const [sameAddress, setSameAddress] = useState(false);

  ///////////

  const currentPosition = useSelector(
    ({ PosTableReducer }) => PosTableReducer.positionDetailsById
  );

  const getCurrentUrlArray = props.location.pathname.split("/");

  const positionId = getCurrentUrlArray.length > 2 ? getCurrentUrlArray[2] : "";

  ////////////

  useEffect(() => {
    dispatch(getJobTypes());
    dispatch(getRoles());
    dispatch(requestCountries());
  }, [dispatch]);

  /////////////

  useEffect(() => {
    positionId &&
      positionId !== "" &&
      dispatch(getPositionDetailsById(positionId));
  }, [positionId]);

  useEffect(() => {
    if (positionId && positionId !== "") {
      setTitle(currentPosition ? currentPosition.title : "");
      setRole(currentPosition ? currentPosition.role : "");
      setOtherrolename(currentPosition ? currentPosition.otherrolename : "");
      setCompensation(
        currentPosition && currentPosition.compensation
          ? currentPosition.compensation.toLowerCase() === "paid"
            ? "Paid"
            : "Free"
          : ""
      );
      setAmount(
        currentPosition && currentPosition.amount
          ? currentPosition.amount.toString()
          : ""
      );
      setAgeMin(
        currentPosition && currentPosition.ageMin
          ? currentPosition.ageMin.toString()
          : ""
      );
      setAgeMax(
        currentPosition && currentPosition.ageMax
          ? currentPosition.ageMax.toString()
          : ""
      );
      setGender(currentPosition ? currentPosition.gender : "");
      setLastDate(
        currentPosition
          ? moment(currentPosition.lastDate).format("YYYY-MM-DD")
          : ""
      );
      setPositionType(
        currentPosition && currentPosition.positionType
          ? currentPosition.positionType.replace(/\s/g, "")
          : ""
      );
      setDescription(currentPosition ? currentPosition.description : "");
      setCountry(currentPosition ? currentPosition.country : "");
      setState(currentPosition ? currentPosition.state : "");
      setCity(currentPosition ? currentPosition.city : "");
      setIndustry(currentPosition ? currentPosition.industry : "");
      setPincode(
        currentPosition && currentPosition.pincode
          ? currentPosition.pincode.toString()
          : ""
      );
      setHouseno(
        currentPosition && currentPosition.houseno
          ? currentPosition.houseno.toString()
          : ""
      );
      setStreet(currentPosition ? currentPosition.street : "");
      setHowMany(
        currentPosition && currentPosition.noOfPositions
          ? currentPosition.noOfPositions.toString()
          : ""
      );
      setOtherRolesCanApply(
        currentPosition ? currentPosition.otherRolesCanApply : false
      );
    }
  }, [Object.values(currentPosition).join("")]);

  ///////////

  useEffect(() => {
    if (sameAddress) {
      getUserAddress({ user_id: localStorage.getItem("userId") }).then(
        (res) => {
          dispatch(requestStates(res.data[0].country));
          if (res.data[0].country === "India") dispatch(requestIndustries());
          setCountry(res.data[0].country);
          setState(res.data[0].state);
          setCity(res.data[0].city);
          setIndustry(res.data[0].industry);
          setPincode(res.data[0].pincode);
          setHouseno(res.data[0].houseno);
          setStreet(res.data[0].street);
        }
      );
    } else {
      setCountry("");
      setState("");
      setCity("");
      setIndustry("");
      setPincode("");
      setHouseno("");
      setStreet("");
    }
  }, [sameAddress]);

  const onSubmit = (e) => {
    e.preventDefault();
    const params = {
      title,
      role,
      otherrolename,
      compensation,
      // noOfprofiles: howMany,
      noOfPositions: howMany,
      address: "",
      amount,
      ageMin,
      ageMax,
      gender,
      lastDate,
      positionType,
      description,
      country,
      state,
      industry,
      pincode,
      houseno,
      city,
      street,
      otherRolesCanApply,
    };

    dispatch(onFormSubmit(params, history));
  };

  const onCountrySelect = (country) => {
    dispatch(requestStates(country));
    if (country === "India") dispatch(requestIndustries());
    setCountry(country);
    setState("");
  };

  const onStateSelect = (state) => {
    setState(state);
  };

  const PopperMy = function (props) {
    return (
      <Popper {...props} style={{ width: 212 }} placement="bottom-start" />
    );
  };

  return (
    <Grid container justify="center" spacing={0}>
      <Grid container xs={10} className={classes.paper} spacing={0}>
        <Grid
          container
          justify="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid xs={12} justify="center" alignItems="center">
            <Button
              variant="contained"
              color="secondary"
              size="small"
              style={{ float: "right", textTransform: "none" }}
              onClick={() => history.push("/all-positions")}
            >
              Back
            </Button>
          </Grid>
          {/* <Grid xs={10} justify="center" alignItems="center"> */}
          <Typography component="h1" variant="h5" className={classes.title}>
            Create Role
          </Typography>
          {/* </Grid> */}
        </Grid>

        <form
          className={classes.form}
          onSubmit={onSubmit}
          className="inherit-bg-color"
        >
          <Grid container spacing={6} justify="space-between">
            <Grid item xs={6} spacing={1}>
              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Type*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Autocomplete
                    options={jobtypes}
                    getOptionLabel={(option) => option}
                    onChange={(e, object) => setPositionType(object)}
                    value={positionType}
                    PopperComponent={PopperMy}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Role"
                        // variant="outlined"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Title*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    // label="Role Name"
                    // variant="outlined"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Role*
                  </Typography>
                </Grid>
                <Grid item style={{ position: "relative" }} xs={7}>
                  <Autocomplete
                    options={roles.filter((r) => r !== otherrolename)}
                    getOptionLabel={(option) => option}
                    onChange={(e, object) => setRole(object)}
                    value={role === otherrolename ? "" : role}
                    PopperComponent={PopperMy}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Role"
                        // variant="outlined"
                        fullWidth
                        required
                      />
                    )}
                  />

                  <SimplePopover rootClass={classes.infoIcon}>
                    <ul>
                      <li>
                        <Typography>
                          Production houses should choose Actor and Model
                          wisely.
                        </Typography>
                      </li>
                    </ul>
                  </SimplePopover>
                </Grid>
                {role === "Actor" && (
                  <>
                    <Grid item xs={5}>
                      <Typography
                        style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                      >
                        Role Name*
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <TextField
                        // label="Role Name"
                        // variant="outlined"
                        value={otherrolename}
                        onChange={(e) => {
                          e.target.value.length < 20 &&
                            setOtherrolename(e.target.value);
                        }}
                        fullWidth
                        required
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Gender*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    // variant="outlined"
                    required
                    // label="Gender"
                    fullWidth
                    select
                    onChange={(e) => setGender(e.target.value)}
                    value={gender}
                  >
                    <MenuItem
                      style={{ fontSize: "1em" }}
                      key=""
                      value=""
                      disabled
                    >
                      Select Gender
                    </MenuItem>
                    {gendersArray.map((option) => {
                      return (
                        <MenuItem
                          style={{ fontSize: "1em" }}
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>

                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Compensation*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    required
                    fullWidth
                    select
                    onChange={(e) => setCompensation(e.target.value)}
                    value={compensation}
                  >
                    <MenuItem
                      style={{ fontSize: "1em" }}
                      key=""
                      value=""
                      disabled
                    >
                      Select Compensation
                    </MenuItem>
                    <MenuItem
                      style={{ fontSize: "1em" }}
                      key={"Paid"}
                      value={"Paid"}
                    >
                      Paid
                    </MenuItem>
                    <MenuItem
                      style={{ fontSize: "1em" }}
                      key={"Free"}
                      value={"Free"}
                    >
                      Free
                    </MenuItem>
                  </TextField>
                </Grid>
                {compensation === "Paid" && (
                  <>
                    <Grid item xs={5}>
                      <Typography
                        style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                      >
                        Amount*
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <TextField
                        // variant="outlined"
                        required
                        fullWidth
                        // label="Amount"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Age*
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    // variant="outlined"
                    required
                    fullWidth
                    // label="Minimum Age"
                    value={ageMin}
                    onChange={(e) => setAgeMin(e.target.value)}
                  />
                </Grid>
                <Typography style={{ marginLeft: "24px", marginRight: "24px" }}>
                  -
                </Typography>
                <Grid item xs={2}>
                  <TextField
                    // variant="outlined"
                    required
                    fullWidth
                    // label="Maximum Age"
                    value={ageMax}
                    onChange={(e) => setAgeMax(e.target.value)}
                  />
                </Grid>

                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    How many?
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    // variant="outlined"
                    required
                    fullWidth
                    // label="Amount"
                    value={howMany}
                    onChange={(e) => setHowMany(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} spacing={1}>
              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Address
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={sameAddress}
                        checked={sameAddress}
                        onChange={(e) => setSameAddress(!sameAddress)}
                      />
                    }
                    labelPlacement="start"
                    label="Copy from my profile"
                  />
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    House No.*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    // variant="outlined"
                    required
                    id="houseno-input"
                    // label="House No"
                    fullWidth
                    onChange={(e) => setHouseno(e.target.value)}
                    value={houseno}
                  />
                </Grid>

                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Street*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    // variant="outlined"
                    required
                    id="street-input"
                    // label="Street No"
                    fullWidth
                    onChange={(e) => setStreet(e.target.value)}
                    value={street}
                  />
                </Grid>

                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    City*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    // variant="outlined"
                    required
                    id="city-input"
                    // label="City"
                    fullWidth
                    onChange={(e) => setCity(e.target.value)}
                    value={city}
                  />
                </Grid>

                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Country*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Autocomplete
                    options={["Any", ...countriesList]}
                    getOptionLabel={(option) => option}
                    onChange={(e, object) => onCountrySelect(object)}
                    value={country}
                    PopperComponent={PopperMy}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Select Country"
                        // variant="outlined"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    State*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Autocomplete
                    id="state-input"
                    options={["Any", ...statesList]}
                    fullwidth
                    size="small"
                    getOptionLabel={(option) => option}
                    value={state}
                    onChange={(e, object) => onStateSelect(object)}
                    disabled={!Boolean(country !== "")}
                    PopperComponent={PopperMy}
                    renderInput={(params) => (
                      <TextField
                        // variant="outlined"
                        {...params}
                        // label="Select State"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Pincode*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    // variant="outlined"
                    required
                    id="pincode-input"
                    // label="Pin Code"
                    fullWidth
                    onChange={(e) => setPincode(e.target.value)}
                    value={pincode}
                  />
                </Grid>
                {country === "India" && (
                  <>
                    <Grid item xs={5}>
                      <Typography
                        style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                      >
                        Industry*
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Autocomplete
                        id="industry-input"
                        options={["Any", ...industriesList]}
                        getOptionLabel={(option) => option}
                        // className={classes.fields}
                        fullwidth
                        size="small"
                        value={industry}
                        onChange={(e, object) => setIndustry(object)}
                        PopperComponent={PopperMy}
                        renderInput={(params) => (
                          <TextField
                            // variant="outlined"
                            {...params}
                            // label="Select Industry"
                            fullWidth
                            required
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Last Date*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    required
                    // variant="outlined"
                    fullWidth
                    // label="Last Date"
                    type="date"
                    onChange={(e) => setLastDate(e.target.value)}
                    value={moment(lastDate).format("YYYY-MM-DD")}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                {!(
                  !role ||
                  role.toLowerCase() === "" ||
                  role.toLowerCase() === "actor" ||
                  role.toLowerCase() === "model" ||
                  role.toLowerCase() === "audience" ||
                  role.toLowerCase() === "people"
                ) && (
                  <>
                    <Grid item xs={11}>
                      <Typography
                        style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                      >
                        Main role in user other roles can also apply?*
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={otherRolesCanApply}
                            checked={otherRolesCanApply}
                            disabled={
                              !role ||
                              role.toLowerCase() === "" ||
                              role.toLowerCase() === "actor" ||
                              role.toLowerCase() === "model"
                            }
                            onChange={(e) =>
                              setOtherRolesCanApply(!otherRolesCanApply)
                            }
                          />
                        }
                        // label="Other Roles Can Apply?"
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>

            <Grid item xs={2}>
              <Typography
                style={{ margin: 10, color: constants.PRIMARY_COLOR }}
              >
                Description*
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                variant="outlined"
                required
                multiline
                rows={6}
                fullWidth
                // label="Description"
                value={description}
                onChange={(e) =>
                  e.target.value.length < 150 && setDescription(e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} align="center" spacing={1}>
              <Button
                variant="contained"
                type="submit"
                size="large"
                className={classes.submit}
                style={{ textTransform: "none" }}
                color="primary"
              >
                Create
              </Button>
              <Button
                variant="contained"
                size="large"
                color="secondary"
                className={classes.submit}
                style={{ textTransform: "none" }}
                onClick={() => history.push("/all-positions")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    countriesList: state.registerReducer.countries
      ? state.registerReducer.countries.map((country) => country.country)
      : [],
    statesList: state.registerReducer.states,
    industriesList: state.registerReducer.industries
      ? state.registerReducer.industries.map((industry) => industry.industry)
      : [],
    roles: state.rolesReducer.roles
      ? state.rolesReducer.roles.map((role) => role.role)
      : [],
    jobtypes: state.rolesReducer.jobtypes
      ? state.rolesReducer.jobtypes.map((jobtype) => jobtype.name)
      : [],
  };
};

export default connect(mapStateToProps)(withRouter(CreatePosition));
