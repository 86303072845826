import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
    display: "flex",
    alignItems: "center",
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(1),
    alignItems: "center",
  },
  avatar: {
    fontSize: 50,
    margin: "8px",
    width: 60,
    height: 60,
  },
}));

export default useStyles;
