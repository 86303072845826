import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import connect from "react-redux/es/connect/connect";
import withRouter from "react-router-dom/es/withRouter";
import bindActionCreators from "redux/es/bindActionCreators";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import getURL from "../../helpers/apis";
import { openAvatarModal } from "../../actions/appActions";
import { useHistory } from "react-router-dom";
import { Loader } from "../UtilityComponents";
import moment from "moment";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const useStyles = makeStyles((theme) => ({
  // root: {
  //   position: "relative",
  //   overflow: "visible",
  //   display: "flex",
  //   width: "fit-content",
  //   height: "fit-content",
  //   maxWidth: "inherit",
  //   maxHeight: "inherit",
  // },
  navIcons: {
    display: "block",
    marginTop: "-20px",
    overflow: "hidden",
    pointerEvents: "auto",
    position: "absolute",
    textIndent: "-9999em",
    top: "50%",
    cursor: "pointer",
    color: "black",
  },
  video: {
    width: 300,
    height: 300,
    maxWidth: 300,
    maxHeight: "inherit",
  },
  image: {
    width: 300,
    height: 300,
    display: "inline-block",
    maxWidth: 300,
  },
  thumb: {
    width: 25,
    height: 64,
    display: "inline-block",
    maxWidth: 600,
  },
  icons: {
    cursor: "pointer",
    height: "auto",
  },
}));

const MediaCarousel = ({ userAd }) => {
  var history = useHistory();
  const classes = useStyles();
  const rootUrl = getURL("UPLOADS");
  const videoRef = useRef();
  videoRef.current = {};

  const videoItem = (src, thumb) => {
    return (
      <ReactPlayer
        ref={(rp) => (videoRef.current = rp)}
        // light={thumb}
        url={src}
        loop
        playing={false}
        controls
        style={{ width: "100%" }}
        pip
      />
    );
  };

  const customRenderThumb = (children) =>
    children.map((item) => {
      const thumbnail =
        item.props.gallery.galleryType === "photos"
          ? item.props.gallery.name
          : item.props.gallery.thumbnail;
      return <img src={process.env.REACT_APP_BUCKET_NAME + thumbnail} className={classes.thumb} />;
    });

  return (
    <div
      style={{
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <Typography>Videos</Typography>
      {userAd && (
        <Carousel
          // autoPlay
          dynamicHeight
          useKeyboardArrows
          stopOnHover
          thumbWidth={100}
          infiniteLoop
        >
          <div>
            <CardMedia className={classes.video}>
              {videoItem(userAd.linkYouAdvt)}
            </CardMedia>
          </div>
          <div>
            <CardMedia className={classes.video}>
              {videoItem(userAd.linkToBeAdvt)}
            </CardMedia>
          </div>
        </Carousel>
      )}
      <Typography>Thumbnail</Typography>
      <Carousel
        // autoPlay
        dynamicHeight
        useKeyboardArrows
        stopOnHover
        thumbWidth={100}
        infiniteLoop
        // showThumbs={false}
        renderThumbs={customRenderThumb}
      >
        {userAd.media &&
          userAd.media.length &&
          userAd.media.map((gallery) => (
            <div gallery={gallery}>
              {gallery.galleryType === "videos" ? (
                <CardMedia className={classes.video}>
                  {videoItem(gallery.name, gallery.thumbnail)}
                </CardMedia>
              ) : (
                <img
                  className={classes.image}
                  alt="No Image found."
                  src={process.env.REACT_APP_BUCKET_NAME + gallery.name}
                />
              )}
            </div>
          ))}
      </Carousel>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userDetail: state.userDetailsReducer.userDetail,
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(MediaCarousel));
