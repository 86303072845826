// import dependencies
import React from "react";
import { Link } from "react-router-dom";
import { TextInput } from "./InputFields";
import { getPosByStatus } from "../actions/getPosActions";
import bindActionCreators from "redux/es/bindActionCreators";
import connect from "react-redux/es/connect/connect";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Input from "@material-ui/core/Input";
import AddIcon from "@material-ui/icons/Add";
import MenuIcon from "@material-ui/icons/Menu";
import Popover from "@material-ui/core/Popover";
import Divider from "@material-ui/core/Divider";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";

import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import {
  isValidParam,
  getStringParam,
  getObjectParam,
  getArrayParam,
  getIntParam,
  getBooleanParam,
} from "../helpers/paramVerifier";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import DataTable from "../components/DataTable";
import EmpDataTable from "../components/Positions/PositionDataTable";
import Pagination from "@material-ui/lab/Pagination";
import PositionDataTablePagination from "../components/Positions/PositionDataTablePagination";
import { Loader, NoRecords } from "../components/UtilityComponents";
import { constants } from "../helpers/constants";

import { Redirect, withRouter } from "react-router-dom";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  tableCellChild: {
    fontSize: 14,
    fontWeight: 600,
  },
  addButton: {
    width: 30,
    height: 20,
  },
  tableContainer: {
    width: "100%",
    //overflowY: 'hidden',
    overflowX: "auto",
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "8px",
  },
});

class PostPosTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      currentStatus: "All",
      searchHeader: "",
      isRedirect: false,
      redirectPath: null,
      pageNumber: 1,
      location: this.props.history.location.pathname,
    };
  }
  componentDidMount() {
    if (this.props.userDetail.userType == constants.USER_TYPE_EMPLOYER) {
      // this.prop();
      //route changed so called below new route
      this.props.getPosByStatus("All");
    } else {
      // let job_id = this.props.match.params.ad_id;
      this.props.getPosByStatus("All");
    }
    // if (this.props.userDetail._id) {
    //   this.props.getPosByUser(null, this.props.userDetail._id);
    // }
  }

  setLinkInfoOnFields() {
    let link = null;
    if (this.props.userDetail.userType == constants.USER_TYPE_EMPLOYER) {
      link = {
        name: "_id",
        baseUrl: "/position/applicants/",
        event: () => {},
        style: null,
      };
    } else {
      link = {
        name: "_id",
        baseUrl: "/position/apply/",
        event: () => {},
        style: null,
      };
    }
    let fields = Object.assign([], getArrayParam(this.props.fields));
    fields.map((m) => {
      if (m.name == "positionName") {
        m.link = link;
      }
    });
    return fields;
  }

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose(e) {
    this.setState({ anchorEl: null });
  }
  handleChangeStatus(e, status) {
    this.setState({ currentStatus: status });
    this.setState({ anchorEl: null });
  }
  handleSearchHeader(e) {
    this.setState({ searchHeader: e.target.value });
  }
  handleChangePageNumber(page) {
    this.setState({ pageNumber: page });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.location !== this.props.history.location.pathname) {
      this.setState({ location: this.props.history.location.pathname });
    }
  }

  redirect(path) {
    this.setState({ isRedirect: true, redirectPath: path });
  }

  render() {
    if (this.state.isRedirect) {
      this.state.isRedirect = false;
      return <Redirect to={this.state.redirectPath} />;
    }

    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;

    let { classes } = this.props;
    let userType = getStringParam(this.props.userDetail.userType);
    let fields = this.setLinkInfoOnFields();
    let filteredPositionsData;
    filteredPositionsData = this.props.PosTable.Pos.filter((pos) =>
      this.state.currentStatus === "All"
        ? true
        : pos.positionStatus.toLowerCase() ===
          this.state.currentStatus.toLowerCase()
    );
    if (this.state.searchHeader) {
      filteredPositionsData = filteredPositionsData.filter((pos) => {
        return pos.role
          .toLowerCase()
          .startsWith(this.state.searchHeader.trim().toLowerCase());
      });
    }
    let pageCount = Math.ceil(filteredPositionsData.length / 10);

    filteredPositionsData = filteredPositionsData.slice(
      (this.state.pageNumber - 1) * 10,
      (this.state.pageNumber - 1) * 10 + 10
    );

    if (getBooleanParam(this.props.fetchingPos)) {
      return <Loader />;
    } else if (!getBooleanParam(this.props.fetchingPos)) {
      return (
        <Grid className={classes.root}>
          {userType == constants.USER_TYPE_JOBSEEKER && (
            <Grid style={{ float: "left" }}>
              <ApplicantBreadcrumbs {...this.props} />
              {this.props.userDetail.userType ==
                constants.USER_TYPE_EMPLOYER && (
                <Grid style={{ float: "right", margin: 10 }}>
                  <Link to={`/positions-create`}>
                    <Fab
                      color="secondary"
                      className={classes.addButton}
                      title={"Add"}
                    >
                      <AddIcon />
                    </Fab>
                  </Link>
                </Grid>
              )}
              <Grid className={classes.tableContainer}>
                {this.props.PosTable.Pos.length > 0 && (
                  <DataTable
                    fields={fields}
                    values={this.props.PosTable.Pos}
                    tableStyle={{ height: "100%" }}
                  />
                )}
                {this.props.PosTable.Pos.length == 0 && (
                  <NoRecords label={"No Records Found."} />
                )}
              </Grid>
            </Grid>
          )}
          {this.props.userDetail.userType == constants.USER_TYPE_EMPLOYER && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "32px 8px 16px",
                }}
              >
                <MenuIcon onClick={(e) => this.handleClick(e)} />

                <Popover
                  id={id}
                  open={open}
                  anchorEl={this.state.anchorEl}
                  onClose={() => this.handleClose()}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <div style={{ padding: "8px", width: "85px" }}>
                    <Typography
                      className={classes.typography}
                      onClick={(e) => this.handleChangeStatus(e, "All")}
                    >
                      <span
                        style={{
                          visibility:
                            this.state.currentStatus === "All"
                              ? "visible"
                              : "hidden",
                          marginRight: "4px",
                        }}
                      >
                        ✔
                      </span>
                      All
                    </Typography>
                    <Divider />
                    <Typography
                      className={classes.typography}
                      onClick={(e) => this.handleChangeStatus(e, "open")}
                    >
                      <span
                        style={{
                          visibility:
                            this.state.currentStatus === "open"
                              ? "visible"
                              : "hidden",
                          marginRight: "4px",
                        }}
                      >
                        ✔
                      </span>
                      Open
                    </Typography>
                    <Divider />
                    <Typography
                      className={classes.typography}
                      onClick={(e) => this.handleChangeStatus(e, "closed")}
                    >
                      <span
                        style={{
                          visibility:
                            this.state.currentStatus === "closed"
                              ? "visible"
                              : "hidden",
                          marginRight: "4px",
                        }}
                      >
                        ✔
                      </span>
                      Closed
                    </Typography>
                  </div>
                </Popover>

                <Paper
                  style={{
                    display: "flex",
                    padding: "4px",
                    alignItems: "center",
                    width: "100%",
                    maxWidth: "512px",
                    margin: "0px 6px",
                  }}
                  elevation={1}
                >
                  <SearchIcon color="action" />
                  <Input
                    placeholder="Search"
                    disableUnderline
                    fullWidth
                    value={this.state.searchHeader}
                    inputProps={{
                      "aria-label": "Search",
                    }}
                    onChange={(e) => this.handleSearchHeader(e)}
                  />
                </Paper>

                <Fab
                  onClick={() => {
                    this.props.history.push("/positions-create");
                  }}
                  color="secondary"
                  // className={classes.addButton}
                  title={"Add"}
                  size="small"
                >
                  <AddIcon />
                </Fab>
              </div>
              <div className={classes.pagination}>
                {this.props.PosTable.Pos.length > 0 && (
                  <PositionDataTablePagination
                    count={pageCount}
                    page={this.state.pageNumber}
                    handleChangePageNumber={(page) =>
                      this.handleChangePageNumber(page)
                    }
                  />
                )}
              </div>

              <Grid className={classes.tableContainer}>
                {this.props.PosTable.Pos.length > 0 && (
                  <EmpDataTable
                    page={this.state.pageNumber - 1}
                    searchText={this.state.searchHeader}
                    fields={empDatafields}
                    values={filteredPositionsData}
                    tableStyle={{ height: "100%" }}
                  />
                )}
                {this.props.PosTable.Pos.length == 0 && (
                  <NoRecords label={"No Records Found."} />
                )}
              </Grid>
            </>
          )}
        </Grid>
      );
    } else {
      return <NoRecords label={"No Records Found."} />;
    }
  }
}

function mapStateToProps(state) {
  return {
    userDetail: state.userDetailsReducer.userDetail,
    PosTable: state.PosTableReducer,
    fetchingPos: state.PosTableReducer.fetchingPos,
    newADCreated: state.postAnADReducer.newADCreated,
    fields: fields,
    empDatafields: empDatafields,
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      getPosByStatus,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(withRouter(PostPosTable)));

let empDatafields = [
  {
    name: "positionType",
    label: "Type",
    width: 150,
    type: "text",
    is_hidden: false,
    link: null,
  },
  {
    name: "title",
    label: "Title",
    width: 150,
    type: "text",
    is_hidden: false,
    link: null,
  },
  {
    name: "role",
    label: "Role",
    width: 150,
    type: "text",
    is_hidden: false,
    link: true,
  },
 
  {
    name: "noofApplicants",
    label: "Applicants",
    width: 50,
    type: "text",
    is_hidden: false,
    link: null,
  },
  {
    name: "gender",
    label: "Gender",
    width: 50,
    type: "text",
    is_hidden: false,
    link: null,
  },
  {
    name: "ageMin",
    label: "Age",
    width: 50,
    type: "text",
    is_hidden: false,
    link: null,
  },
  {
    name: "positionStatus",
    label: "Status",
    width: 50,
    type: "text",
    is_hidden: false,
    link: null,
  },
  {
    name: "country",
    label: "Country",
    width: 50,
    type: "text",
    is_hidden: false,
    link: null,
  },
  {
    name: "createdAt",
    label: "Posted Date",
    width: 80,
    type: "date",
    is_hidden: false,
    link: null,
  },
  {
    name: "lastDate",
    label: "Last Date",
    width: 80,
    type: "date",
    is_hidden: false,
    link: null,
  },
  
  {
    name: "view",
    label: "View",
    width: 80,
    type: "date",
    is_hidden: false,
    link: null,
  },
  {
    name: "edit",
    label: "Edit",
    width: 80,
    type: "date",
    is_hidden: false,
    link: null,
  },
  {
    name: "close",
    label: "Close",
    width: 80,
    type: "date",
    is_hidden: false,
    link: null,
  },
  {
    name: "clone",
    label: "Clone",
    width: 80,
    type: "date",
    is_hidden: false,
    link: null,
  },
];

let fields = [
  {
    name: "positionName",
    label: "Position Name",
    width: 100,
    type: "text",
    is_hidden: false,
    link: {
      name: "_id",
      baseUrl: "/position/applicants/",
      event: () => {},
      style: null,
    },
  },
  {
    name: "positionType",
    label: "Type",
    width: 100,
    type: "text",
    is_hidden: false,
    link: null,
  },
  {
    name: "reqExp",
    label: "Required Experience",
    width: 100,
    type: "text",
    is_hidden: false,
    link: null,
  },
  {
    name: "description",
    label: "Description",
    width: 100,
    type: "text",
    is_hidden: false,
    link: null,
  },
  {
    name: "lastDate",
    label: "Last Date",
    width: 80,
    type: "date",
    is_hidden: false,
    link: null,
  },
  {
    name: "ageMax",
    label: "Max Age",
    width: 50,
    type: "text",
    is_hidden: false,
    link: null,
  },
  {
    name: "ageMin",
    label: "Min Age",
    width: 50,
    type: "text",
    is_hidden: false,
    link: null,
  },
  {
    name: "gender",
    label: "Gender",
    width: 50,
    type: "text",
    is_hidden: false,
    link: null,
  },
  {
    name: "role",
    label: "Role",
    width: 150,
    type: "text",
    is_hidden: false,
    link: null,
  },
  {
    name: "otherrolename",
    label: "Role name",
    width: 150,
    type: "text",
    is_hidden: false,
    link: null,
  },
  {
    name: "dept",
    label: "Department",
    width: 100,
    type: "text",
    is_hidden: false,
    link: null,
  },
  {
    name: "positionStatus",
    label: "Status",
    width: 50,
    type: "text",
    is_hidden: false,
    link: null,
  },
];

class ApplicantBreadcrumbs extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Breadcrumbs aria-label="Breadcrumb" style={{ margin: 10 }}>
        <Typography color="textPrimary" style={{ fontSize: 14 }}>
          <Link to={`/positions`}>All Jobs</Link>
        </Typography>
        <Typography color="textPrimary" style={{ fontSize: 14 }}>
          Positions
        </Typography>
      </Breadcrumbs>
    );
  }
}
