import React, { useState, useEffect, useRef } from "react";
import {
  Paper,
  Grid,
  Chip,
  IconButton,
  Typography,
  Divider,
  Popover,
  List,
  ListItem,
  ListItemText,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import SendSharpIcon from "@material-ui/icons/SendSharp";
import MessageFromComponent from "./MessageFromComponent";
import MoreVertSharpIcon from "@material-ui/icons/MoreVertSharp";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ClearSharpIcon from "@material-ui/icons/ClearSharp";
import MessageToComponent from "./MessageToComponent";
import TextField from "@material-ui/core/TextField";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import ConfirmationDialog from "../ConfirmationDialog";
import Icon from "@material-ui/core/Icon";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Avatar from "@material-ui/core/Avatar";
import getURL from "../../helpers/apis";
import { openAvatarModal } from "../../actions/appActions";
// import ImageDialog from "../UserProfile/ImageDialog";
import PersonIcon from "@material-ui/icons/Person";
import {
  unfollow,
  follow,
  editCustomName,
  unblock,
  block,
} from "../../actions/applicantsDetailsActions";

import moment from "moment";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    textAlign: "center",
    height: "inherit",
    // overflowY: "scroll",
  },
  thread: {
    width: "100%",
    padding: 12,
    height: "calc(100vh - 200px)",
    overflowY: "scroll",
  },
  date: {
    marginTop: 6,
    marginBottom: 6,
  },
  chat: {
    marginTop: 4,
    marginBottom: 4,
  },
}));

const ChatThreadComponent = ({
  chatThread,
  currentSelectedUser,
  id,
  handleSendText,
  newMessage,
  onlineStatus,
  typingStatus,
  handleSetTyping,
  handleDeleteAll,
  handleDeleteForAll,
  handleDeleteForMe,
  dispatch,
  handleRefreshUsers,
}) => {
  const classes = useStyles();
  const messagesEndRef = useRef(null);
  const [pop, setPop] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [newText, setNewText] = useState("");
  const [currentUser, setCurrentUser] = useState(
    currentSelectedUser && currentSelectedUser
  );
  const [isOnline, setIsOnline] = useState(onlineStatus && onlineStatus);
  const [isTyping, setIsTyping] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [openCon, setOpenCon] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [avatarModal, setAvatarModal] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const rootUrl = getURL("UPLOADS");

  const [readChats, setReadChats] = useState(
    chatThread && chatThread.length
      ? chatThread.length === 2
        ? chatThread[0].chats
        : chatThread[0].isRead === "true"
        ? chatThread[0].chats
        : []
      : []
  );
  const [unreadChats, setUnreadChats] = useState(
    chatThread && chatThread.length
      ? chatThread.length === 2
        ? chatThread[1].chats
        : chatThread[0].isRead === "true"
        ? []
        : chatThread[0].chats
      : []
  );
  const [deleteToArray, setDeleteToArray] = useState([]);
  const [deleteFromArray, setDeleteFromArray] = useState([]);

  useEffect(() => {
    if (chatThread && chatThread.length === 2) {
      setReadChats(chatThread[0] && chatThread[0].chats);
      setUnreadChats(chatThread[1] && chatThread[1].chats);
    } else if (chatThread && chatThread.length === 1) {
      if (chatThread[0].isRead === "true") {
        setReadChats(chatThread[0] && chatThread[0].chats);
        setUnreadChats([]);
      } else {
        setReadChats([]);
        setUnreadChats(chatThread[0] && chatThread[0].chats);
      }
    } else if (chatThread && chatThread.length === 0) {
      setReadChats([]);
      setUnreadChats([]);
    }
  }, [chatThread]);

  useEffect(() => {
    setCurrentUser(currentSelectedUser && currentSelectedUser);
  }, [currentSelectedUser]);

  useEffect(() => {
    handleRefreshUsers();
  }, [refresh]);

  useEffect(() => {
    if (
      newMessage &&
      chatThread.length &&
      (newMessage.from === currentSelectedUser.user_id ||
        newMessage.to === currentSelectedUser.user_id) &&
      !unreadChats.concat(readChats).includes(newMessage)
    ) {
      if (unreadChats.length > 0) {
        setIsTyping(false);
        let newArr = [...unreadChats];
        newArr[newArr.length - 1].chats.push(newMessage);
        setUnreadChats(newArr);
      } else if (readChats.length > 0) {
        setIsTyping(false);
        let newArr = [...readChats];
        newArr[newArr.length - 1].chats.push(newMessage);
        setReadChats(newArr);
      }
    } else if (
      newMessage &&
      chatThread.length === 0 &&
      (newMessage.from === currentSelectedUser.user_id ||
        newMessage.to === currentSelectedUser.user_id)
    ) {
      if (readChats && readChats.length > 0) {
        setIsTyping(false);
        let newArr = [...readChats];
        newArr[0].chats.push(newMessage);
        setReadChats(newArr);
      } else {
        setIsTyping(false);
        let newArr = [];
        newArr.push({ chats: [] });
        newArr[0].chats.push(newMessage);
        setReadChats(newArr);
      }
      // setIsTyping(false);
      // let newArr = [];
      // newArr.push({ chats: [] });
      // newArr[0].chats.push(newMessage);
      // setReadChats(newArr);
    }
  }, [newMessage && newMessage._id]);

  useEffect(() => {
    if (onlineStatus && onlineStatus.user_id === currentSelectedUser.user_id) {
      setIsOnline(onlineStatus);
    }
  }, [
    onlineStatus && onlineStatus,
    currentSelectedUser && currentSelectedUser,
  ]);

  useEffect(() => {
    if (typingStatus && typingStatus.user_id === currentSelectedUser.user_id) {
      setIsTyping(typingStatus.isTyping);
    }
  }, [
    typingStatus && typingStatus,
    currentSelectedUser && currentSelectedUser,
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTyping(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [isTyping]);

  useEffect(() => {
    messagesEndRef.current.scrollIntoView({
      behavior: "auto",
      block: "end",
    });
  }, [readChats, unreadChats]);

  const handlePopover = (e) => {
    setPop(true);
    setAnchorEl(e.currentTarget);
  };

  const handleChange = (e) => {
    handleSetTyping({
      to: currentSelectedUser && currentSelectedUser.user_id,
      from: id,
    });
    e.target.value.length <= 280 && setNewText(e.target.value);
  };

  const handleSendNewText = () => {
    if (newText != "") {
      var date = new Date();
      var timestamp = date.getTime();
      let params = {
        from: id,
        to: currentUser.user_id,
        message: newText,
        timestamp: timestamp,
      };
      handleSendText(params);
      setNewText("");
    }
  };

  const handleDeleteSelect = () => {
    setPop(false);
    setDeleting(true);
  };

  const handleDelete = () => {
    setPop(false);
    setOpenCon(true);
  };

  const handleUnblock = () => {
    setPop(false);
    setTitle("Are you sure?");
    setDescription(`Do you want to unblock ${currentUser.username}?`);
    setCurrentAction("unblock");
    setOpenConfirm(true);
  };

  const handleBlock = () => {
    setPop(false);
    setTitle("Are you sure?");
    setDescription(`Do you want to block ${currentUser.username}?`);
    setCurrentAction("block");
    setOpenConfirm(true);
  };

  const handleConfirm = () => {
    setOpenConfirm(false);
    let user_id = currentUser && currentUser.user_id;
    if (currentAction === "block") {
      block({ user_id }).then((res) => setRefresh(!refresh));
    }
    if (currentAction === "unblock") {
      unblock({ user_id }).then((res) => setRefresh(!refresh));
    }
    setCurrentAction(null);
  };

  const handleDeleteConfirm = (type) => {
    setOpenCon(false);
    let params = {
      to: currentSelectedUser && currentSelectedUser.user_id,
      from: id,
    };
    if (
      type === "forme" &&
      (deleteFromArray.length > 0 || deleteToArray.length > 0)
    ) {
      params.idArray = deleteToArray.concat(deleteFromArray);
      handleDeleteForMe(params);
    } else if (type === "forall" && deleteFromArray.length > 0) {
      params.idArray = deleteFromArray;
      handleDeleteForAll(params);
    } else handleDeleteAll(params);
    setDeleting(false);
    setDeleteToArray([]);
    setDeleteFromArray([]);
  };

  const handleAddOrRemoveMessage = (id, toOrFrom) => {
    if (toOrFrom === "to") {
      if (!deleteToArray.includes(id)) {
        setDeleteToArray([...deleteToArray, id]);
      } else {
        let newArr = deleteToArray.filter((i) => i !== id);
        setDeleteToArray(newArr);
      }
    } else {
      if (!deleteFromArray.includes(id)) {
        setDeleteFromArray([...deleteFromArray, id]);
      } else {
        let newArr = deleteFromArray.filter((i) => i !== id);
        setDeleteFromArray(newArr);
      }
    }
  };

  const renderChats = (status) => {
    let chats = status === "read" ? readChats : unreadChats;
    return (
      chats &&
      chats.length > 0 &&
      chats.map((group) => {
        return (
          <>
            <Grid item className={classes.date}>
              <Chip
                size="small"
                label={moment(group.date).format("DD-MM-YYYY")}
              />
            </Grid>
            {group.chats.map((chat) => {
              if (chat.from === id) {
                return (
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="flex-start"
                    className={classes.chat}
                    style={{
                      textAlign: "right",
                      backgroundColor: deleteFromArray.includes(chat._id)
                        ? "rgba(255, 0, 0, 0.1)"
                        : "unset",
                    }}
                  >
                    <Grid
                      item
                      style={{
                        maxWidth: "90%",
                      }}
                    >
                      <MessageFromComponent
                        key={chat._id || chat.timestamp || Math.random()}
                        chat={chat}
                      />
                    </Grid>
                    {deleting && (
                      <Grid item>
                        <Checkbox
                          size="small"
                          checked={deleteFromArray.includes(chat._id)}
                          onChange={() =>
                            handleAddOrRemoveMessage(chat._id, "from")
                          }
                        />
                      </Grid>
                    )}
                  </Grid>
                );
              } else {
                return (
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    className={classes.chat}
                    style={{
                      textAlign: "left",
                      backgroundColor: deleteToArray.includes(chat._id)
                        ? "rgba(255, 0, 0, 0.1)"
                        : "unset",
                    }}
                  >
                    {deleting && (
                      <Grid item>
                        <Checkbox
                          size="small"
                          checked={deleteToArray.includes(chat._id)}
                          onChange={() =>
                            handleAddOrRemoveMessage(chat._id, "to")
                          }
                        />
                      </Grid>
                    )}
                    <Grid item style={{ maxWidth: "90%" }}>
                      <MessageToComponent
                        key={chat._id || chat.timestamp || Math.random()}
                        chat={chat}
                      />
                    </Grid>
                  </Grid>
                );
              }
            })}
          </>
        );
      })
    );
  };

  return (
    <div className={classes.root}>
      {openCon && (
        <DeleteConfirmationDialog
          open={openCon}
          handleConfirm={handleDeleteConfirm}
          handleCancel={() => {
            setOpenCon(false);
            setDeleting(false);
            setDeleteToArray([]);
            setDeleteFromArray([]);
          }}
          title={"Are you sure?"}
          description={"This cannot be undone."}
          id={id}
          deleteType={
            deleteToArray.length === 0 && deleteFromArray.length === 0
              ? null
              : deleteToArray.length > 0
              ? "forme"
              : "forall"
          }
        />
      )}
      {openConfirm && (
        <ConfirmationDialog
          open={openConfirm}
          handleCancel={() => {
            setOpenConfirm(false);
          }}
          handleConfirm={() => handleConfirm()}
          title={title}
          description={description}
        />
      )}
      {/* {avatarModal && (
        <ImageDialog
          src={ currentUser.profilepic}
          open={avatarModal}
          handleClose={(e) => setAvatarModal(false)}
        />
      )} */}
      <Grid>
        <Grid item style={{ height: 48 }}>
          {/* <Paper style={{ height: 48 }} variant="elevation"> */}
            <List disablePadding>
              <ListItem
                style={{
                  height: 48,
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <ListItemAvatar>
                  {currentUser && currentUser.profilepic ? (
                    <Avatar
                      src={
                        process.env.REACT_APP_BUCKET_NAME +
                        currentUser.profilepic
                      }
                      onClick={() =>
                        dispatch(
                          openAvatarModal(
                            process.env.REACT_APP_BUCKET_NAME +
                              currentUser.profilepic
                          )
                        )
                      }
                      // className={classes.profileDefaultIcon}
                    />
                  ) : (
                    <Avatar
                      alt="owner"
                      className={classes.avatar}
                      onClick={(e) => dispatch(openAvatarModal())}
                    >
                      <PersonIcon />
                    </Avatar>
                  )}
                </ListItemAvatar>
                <ListItemText
                  style={{ overflow: "hidden" }}
                  primary={
                    <Typography variant="h5">
                      {currentUser.followingUser_CustomName !== ""
                        ? currentUser.followingUser_CustomName
                        : currentUser.username}
                    </Typography>
                  }
                  secondary={
                    <Typography color="primary" paragraph variant="caption">
                      {isTyping && isTyping
                        ? "Typing..."
                        : isOnline &&
                          (isOnline.isOnline
                            ? "Online"
                            : "Last Seen : " +
                              new Date(isOnline.lastSeen).toLocaleTimeString(
                                [],
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              ))}
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  {deleting ? (
                    <>
                      <IconButton
                        egde="end"
                        color="secondary"
                        style={{ float: "right" }}
                        onClick={(e) => handleDelete()}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                      <IconButton
                        egde="end"
                        color="secondary"
                        style={{ float: "right" }}
                        onClick={(e) => {
                          setDeleting(false);
                          setDeleteToArray([]);
                          setDeleteFromArray([]);
                        }}
                      >
                        <ClearSharpIcon />
                      </IconButton>
                    </>
                  ) : (
                    <IconButton
                      egde="end"
                      style={{ float: "right" }}
                      onClick={(e) => handlePopover(e)}
                    >
                      <MoreVertSharpIcon />
                    </IconButton>
                  )}
                  <Popover
                    id="simple-popper"
                    open={pop}
                    anchorEl={anchorEl}
                    onClose={(e) => {
                      setPop(false);
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <List component="nav" className={classes.root}>
                      <ListItem
                        button
                        className={classes.listItem}
                        onClick={(e) => {
                          handleDeleteSelect();
                        }}
                      >
                        <ListItemText
                          primary="Delete Messages"
                          classes={{ primary: classes.listItemText }}
                        />
                      </ListItem>
                      <ListItem
                        button
                        className={classes.listItem}
                        onClick={(e) => {
                          handleDelete();
                        }}
                      >
                        <ListItemText
                          primary="Delete All Messages"
                          classes={{ primary: classes.listItemText }}
                        />
                      </ListItem>
                      {currentUser.blockStatus
                        .toLowerCase()
                        .startsWith("you") ||
                      currentUser.blockStatus
                        .toLowerCase()
                        .includes("blocked each other") ? (
                        <ListItem
                          button
                          className={classes.listItem}
                          onClick={() => handleUnblock()}
                        >
                          <ListItemText
                            primary={"Unblock User"}
                            classes={{ primary: classes.listItemText }}
                          />
                        </ListItem>
                      ) : (
                        <ListItem
                          button
                          className={classes.listItem}
                          onClick={() => handleBlock()}
                        >
                          <ListItemText
                            primary={"Block User"}
                            classes={{ primary: classes.listItemText }}
                          />
                        </ListItem>
                      )}
                    </List>
                  </Popover>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          {/* </Paper> */}
        </Grid>
        <Divider />
        <Grid item className={classes.thread}>
          <Grid container direction="column" alignItems="center">
            {renderChats("read")}
            {unreadChats && unreadChats.length > 0 && (
              <>
                {unreadChats[0].chats[0].to === id && (
                  <Grid item style={{ marginTop: 6, marginBottom: 6 }}>
                    <Chip label="Unread Messages" />
                  </Grid>
                )}
                {renderChats("unread")}
              </>
            )}
            <div ref={messagesEndRef}></div>
          </Grid>
        </Grid>
        <Grid item style={{ height: 48, padding: 10 }}>
          <OutlinedInput
            disabled={!currentUser.isChatEligible}
            autoFocus
            id="filled-multiline-flexible"
            endAdornment={
              <InputAdornment position="end">
                <Divider orientation="vertical" style={{ height: 32 }} />
                <IconButton
                  edge="end"
                  onClick={handleSendNewText}
                  disabled={newText === "" || !currentUser.isChatEligible}
                >
                  <SendSharpIcon />
                </IconButton>
              </InputAdornment>
            }
            // multiline
            fullWidth
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSendNewText();
              }
            }}
            margin="none"
            // rowsMax={4}
            style={{ height: 48, padding: 10 }}
            size="small"
            value={
              currentUser.isChatEligible ? newText : currentUser.blockStatus
            }
            onChange={(e) => handleChange(e)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default connect()(ChatThreadComponent);
