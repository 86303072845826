import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import bindActionCreators from "redux/es/bindActionCreators";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import BreadCumLink from "@material-ui/core/Link";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  isValidParam,
  getStringParam,
  getObjectParam,
  getArrayParam,
  getIntParam,
  getBooleanParam,
} from "../helpers/paramVerifier";
import { sendHTTPRequest, send } from "../helpers/requestEngine";
import getUrl from "../helpers/apis";
import { getPosApplicants } from "../actions/getPosActions";
import { Link as ReactDomLink } from "react-router-dom";
import ApplicantDetails from "../components/ApplicantDetails";

const styles = (theme) => ({
  root: {},
  row: {
    marginLeft: 20,
    marginRight: 20,
  },
  card: {
    fontSize: 14,
    minWidth: 275,
    margin: 20,
    maxHeight: 300,
    maxWidth: 1200,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  breadCumLink: {
    fontSize: 14,
  },
  pos: {
    fontSize: 14,
    marginBottom: 12,
  },
  root: {
    flexGrow: 1,
    padding: 10,
  },
  jobBodyRow: {
    fontSize: 14,
    marginTop: 10,
    marginBottom: 10,
  },
  jobBodyCol1: {
    width: "10%",
    fontSize: 14,
    fontWeight: 600,
    color: "#00000078",
  },
  jobBodyCol2: {
    width: "90%",
    fontSize: 14,
    fontWeight: 400,
    color: "#000000bd",
  },
  button: {
    fontSize: 14,
    marginLeft: 5,
    marginRigh: 5,
  },
  filterTabs: {
    minHeight: 40,
    marginLeft: 20,
    marginRigh: 20,
    marginBottom: 20,
  },
  filterTab: {
    minHeight: 40,
    fontSize: 14,
    textTransform: "capitalize",
    //color: '#ffffff'
    margin: "5px 5px 0px 5px",
    borderRadius: 4,
    border: "1px solid #00000042",
  },
  filterTabSelected: {
    borderBottom: "3px solid #4c7c8b",
  },
});

class PositionDetailsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFilterType: "",
      defaultTabSelected: 0,
    };
  }

  componentWillMount() {
    this.state.selectedFilterType = getStringParam(
      this.props.status
    ).toLowerCase();
    if (this.state.selectedFilterType == "rejected") {
      this.state.defaultTabSelected = 2;
    } else if (this.state.selectedFilterType == "selected") {
      this.state.defaultTabSelected = 3;
    } else if (this.state.selectedFilterType == "shortlisted") {
      this.state.defaultTabSelected = 1;
    } else {
      this.state.defaultTabSelected = 0;
    }
    this.setState({});
  }

  setSelectedFilterType(type) {
    this.setState({ selectedFilterType: type });
  }

  getApplicantsCount() {
    let countObj = new Object();
    try {
      let applicants = getArrayParam(this.props.applicants);
      //applicants.pendingDecision = applicants.filter( f => { return f.status != 'ShortListed' && f.status != 'Selected' && f.status != 'Rejected' && f.status != 'Applied'});
      countObj.pendingDecision = applicants.filter((f) => {
        return f.status == "Applied";
      }).length;
      countObj.shortListed = applicants.filter((f) => {
        return f.status == "ShortListed";
      }).length;
      countObj.selected = applicants.filter((f) => {
        return f.status == "Selected";
      }).length;
      countObj.rejected = applicants.filter((f) => {
        return f.status == "Rejected";
      }).length;
    } catch (error) {}
    return countObj;
  }

  getSelectedTabApplicants() {
    let arr = new Array();
    let status = "";
    try {
      let filterType = getStringParam(
        this.state.selectedFilterType
      ).toLocaleLowerCase();
      if (filterType == "rejected") {
        status = "Rejected";
      } else if (filterType == "selected") {
        status = "Selected";
      } else if (filterType == "shortlisted") {
        status = "ShortListed";
      } else {
        status = "Applied";
      }
      let applicants = getArrayParam(this.props.applicants);
      let filteredApplicants = applicants.filter((f) => {
        return f.status == status;
      });
      filteredApplicants.forEach((obj, index) => {
        arr.push(
          <ApplicantDetails
            key={obj._id + index}
            index={index}
            {...this.props}
            applicant={obj}
            status={status}
          />
        );
      });
    } catch (error) {}
    return arr;
  }

  render() {
    const { classes } = this.props;
    let applicantCountObj = this.getApplicantsCount();
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.row}>
          <ApplicantBreadcrumbs {...this.props} />
        </Grid>
        <Grid item xs={12}>
          <ApplicantFilterContainer
            {...this.props}
            applicantCountObj={applicantCountObj}
            setSelectedFilterType={(value) => this.setSelectedFilterType(value)}
            defaultTabSelected={this.state.defaultTabSelected}
          />
        </Grid>
        <Grid item xs={12} className={classes.row}>
          <Typography
            component="div"
            style={{ fontSize: 14, marginLeft: 20, fontWeight: 500 }}
          >
            Showing {applicantCountObj[this.state.selectedFilterType]} of{" "}
            {applicantCountObj[this.state.selectedFilterType]} records according
            to the above filters
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {this.getSelectedTabApplicants()}
        </Grid>
      </Grid>
    );
  }
}
function mapStateToProps(state) {
  return {
    posDetails: state.PosTableReducer.posDetails,
    applicants: state.applicantsReducer.applicants,
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      getPosApplicants,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(PositionDetailsContainer));

class ApplicantBreadcrumbs extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <Breadcrumbs aria-label="Breadcrumb">
        <Typography color="textPrimary" className={classes.breadCumLink}>
          <ReactDomLink to={"/all-positions"}>All Positions</ReactDomLink>
        </Typography>
        <Typography
          color="textPrimary"
          className={classes.breadCumLink}
          style={{ color: "#8f8f8f" }}
        >
          {this.props.posDetails.positionName}
        </Typography>
      </Breadcrumbs>
    );
  }
}

class ApplicantFilterContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: getIntParam(this.props.defaultTabSelected),
    };
  }

  filterTabChange(event, value) {
    this.state.selectedTab = value;
    if (value == 3) {
      this.props.setSelectedFilterType("selected");
    } else if (value == 2) {
      this.props.setSelectedFilterType("rejected");
    } else if (value == 1) {
      this.props.setSelectedFilterType("shortListed");
    } else {
      this.props.setSelectedFilterType("pendingDecision");
    }

    //this.setState({selectedTab: value});
  }

  render() {
    const { classes, applicantCountObj } = this.props;
    let { selectedTab } = this.state;

    return (
      <Card className={classes.card}>
        <Typography
          component="div"
          style={{ fontWeight: 600, fontSize: 16, margin: 20 }}
        >
          Filters
        </Typography>
        <Grid item xs={12}>
          <Tabs
            value={selectedTab}
            classes={{
              root: classes.filterTabs,
              indicator: classes.filterTabSelected,
            }}
            onChange={(e, value) => this.filterTabChange(e, value)}
          >
            <Tab
              label={"Applied(" + applicantCountObj.pendingDecision + ")"}
              className={classes.filterTab}
              labelContainer={{ heigh: 40 }}
            />
            <Tab
              label={"Shortlisted(" + applicantCountObj.shortListed + ")"}
              className={classes.filterTab}
              labelContainer={{ heigh: 40 }}
            />
            <Tab
              label={"Rejected(" + applicantCountObj.rejected + ")"}
              className={classes.filterTab}
              labelContainer={{ heigh: 40 }}
            />
            <Tab
              label={"Hired(" + applicantCountObj.selected + ")"}
              className={classes.filterTab}
              labelContainer={{ heigh: 40 }}
            />
          </Tabs>
        </Grid>
      </Card>
    );
  }
}
