import { sendHTTPRequest } from "../helpers/requestEngine";
import getUrl from "../helpers/apis";
import { getStringParam } from "../helpers/paramVerifier";
import request1 from "../helpers/request";
import getURL from "../helpers/apis";
import request from "../helpers/requestEngine";
import { openDialog, closeDialog } from "./appActions";

export const changeGalleryQuotation = (galleryId, q) => {
  return (dispatch) => {
    request("post", "CHANGE_GALLERY_QUOTATION", true, {
      gallery_id: galleryId,
      galleryQuotation: q,
    }).then(
      (response) => {
        dispatch(changingGalleryQuotation());
      },
      (err) => {}
    );
  };
};

function changingGalleryQuotation() {
  return {
    type: "CHANGE_GALLERY_QUOTATION",
  };
}

export const changeWhoCanHiddenComment = (galleryId, who) => {
  return (dispatch) => {
    request("post", "WHO_CAN_HIDDEN_COMMENT", true, {
      gallery_id: galleryId,
      whoCanHiddenComment: who,
    }).then(
      (response) => {
        dispatch(changingWhoCanHiddenComment());
      },
      (err) => {}
    );
  };
};

function changingWhoCanHiddenComment() {
  return {
    type: "WHO_CAN_HIDDEN_COMMENT",
  };
}

export const changeWhoCanOpenComment = (galleryId, who) => {
  return (dispatch) => {
    request("post", "WHO_CAN_OPEN_COMMENT", true, {
      gallery_id: galleryId,
      whoCanOpenComment: who,
    }).then(
      (response) => {
        dispatch(changingWhoCanOpenComment());
      },
      (err) => {}
    );
  };
};

function changingWhoCanOpenComment() {
  return {
    type: "WHO_CAN_OPEN_COMMENT",
  };
}

export const allowHiddenComments = (galleryId) => {
  return (dispatch) => {
    request("post", "ALLOW_HIDDEN_COMMENTS", true, {
      gallery_id: galleryId,
    }).then(
      (response) => {
        dispatch(allowingHiddenComments());
      },
      (err) => {}
    );
  };
};

function allowingHiddenComments() {
  return {
    type: "ALLOW_HIDDEN_COMMENTS",
  };
}

export const allowOpenComments = (galleryId) => {
  return (dispatch) => {
    request("post", "ALLOW_OPEN_COMMENTS", true, {
      gallery_id: galleryId,
    }).then(
      (response) => {
        dispatch(allowingOpenComments());
      },
      (err) => {}
    );
  };
};

function allowingOpenComments() {
  return {
    type: "ALLOW_OPEN_COMMENTS",
  };
}

export const postComment = (body) => {
  return (dispatch) => {
    return request("post", "POST_COMMENT", true, body).then(
      (response) => {
        dispatch(postingComment());
        return response.data;
      },
      (err) => {
        return err;
      }
    );
  };
};

function postingComment() {
  return {
    type: "POST_COMMENT",
  };
}

export const deleteComment = (id) => {
  return (dispatch) => {
    return request("delete", "DELETE_COMMENT", true, { comment_id: id }).then(
      (response) => {
        dispatch(deletingComment());
        return response.data;
      },
      (err) => {
        return err;
      }
    );
  };
};

function deletingComment() {
  return {
    type: "DELETE_COMMENT",
  };
}

export const getCommentsByGalleryId = (params) => {
  return fetchComments(params);
};

function fetchComments(params) {
  return (dispatch) => {
    request("get", "GET_COMMENTS", true, null, params).then(
      (response) => {
        dispatch(fetchCommentsByGalleryId(response.data));
      },
      (err) => {}
    );
  };
}

function fetchCommentsByGalleryId(data) {
  return {
    payload: data,
    type: "GET_COMMENTS",
  };
}

export const getRepliesByCommentId = (params) => {
  return fetchReplies(params);
};

function fetchReplies(params) {
  return (dispatch) => {
    request("get", "GET_REPLIES_FOR_COMMENT", true, null, params).then(
      (response) => {
        dispatch(fetchRepliesByCommentId(response.data, params.comment_id));
      },
      (err) => {}
    );
  };
}

function fetchRepliesByCommentId(data, commentId) {
  return {
    payload: { data, commentId },
    type: "GET_REPLIES_FOR_COMMENT",
  };
}

export const getGallerySettings = (gallery_id) => {
  return fetchSettings(gallery_id);
};

function fetchSettings(gallery_id) {
  return (dispatch) => {
    request("get", "GET_GALLERY_SETTINGS", true, null, {
      galleryId: gallery_id,
    }).then(
      (response) => {
        dispatch(fetchGallerySettings(response.data.gallery));
      },
      (err) => {}
    );
  };
}

function fetchGallerySettings(settings) {
  return {
    payload: settings,
    type: "GALLERY_SETTINGS",
  };
}

//getads between posts

export const getVideoAds = (params) => {
  return (dispatch) => {
    dispatch(fetchingVideoAds());

    return dispatch(fetchVideoAds(params));
  };
};

function fetchingVideoAds() {
  return {
    type: "FETCH_VIDEO_ADS",
  };
}

function fetchVideoAds(params) {
  return (dispatch) => {
    request1("get", getURL("GET_VIDEO_ADS"), true, {}, params).then(
      (response) => {
        dispatch(fetchingVideoAdsSuccess(response.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));
        dispatch(fetchingVideoAdsFailed(err));
      }
    );
  };
}

function fetchingVideoAdsSuccess(data) {
  return {
    type: "FETCH_VIDEO_ADS_SUCCESS",
    payload: data.data,
  };
}

function fetchingVideoAdsFailed(err) {
  return {
    type: "FETCH_VIDEO_ADS_FAILED",
  };
}

//get side ads

export const getSideAds = (params) => {
  return (dispatch) => {
    dispatch(fetchingSideAds());

    return dispatch(fetchSideAds(params));
  };
};

function fetchingSideAds() {
  return {
    type: "FETCH_SIDE_ADS",
  };
}

function fetchSideAds(params) {
  return (dispatch) => {
    request1("get", getURL("GET_SIDE_ADS"), true, {}, params).then(
      (response) => {
        dispatch(fetchingSideAdsSuccess(response.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));
        dispatch(fetchingSideAdsFailed(err));
      }
    );
  };
}

function fetchingSideAdsSuccess(data) {
  return {
    type: "FETCH_SIDE_ADS_SUCCESS",
    payload: data.data,
  };
}

function fetchingSideAdsFailed(err) {
  return {
    type: "FETCH_SIDE_ADS_FAILED",
  };
}

//get free side ads

export const getFreeSideAds = (params) => {
  return (dispatch) => {
    dispatch(fetchingFreeSideAds());

    return dispatch(fetchFreeSideAds(params));
  };
};

function fetchingFreeSideAds() {
  return {
    type: "FETCH_FREE_SIDE_ADS",
  };
}

function fetchFreeSideAds(params) {
  return (dispatch) => {
    request1("get", getURL("GET_FREE_SIDE_ADS"), true, {}, params).then(
      (response) => {
        dispatch(fetchingFreeSideAdsSuccess(response.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));
        dispatch(fetchingFreeSideAdsFailed(err));
      }
    );
  };
}

function fetchingFreeSideAdsSuccess(data) {
  return {
    type: "FETCH_FREE_SIDE_ADS_SUCCESS",
    payload: data.data,
  };
}

function fetchingFreeSideAdsFailed(err) {
  return {
    type: "FETCH_FREE_SIDE_ADS_FAILED",
  };
}

// function fetchComments(user_id) {
//   return (dispatch) => {
//     request("get", "COMMENT", true, null, { _id: user_id }).then(
//       (response) => {
//         dispatch(insertComments(parseComment(response.data.data)));
//       },
//       (err) => {}
//     );
//   };
// }

// const parseComment = (comments) => {
//   if (comments.length) {
//     const parsedComments = comments.map((comment) => {
//       return comment;
//     });
//     return parsedComments;
//   } else {
//     return comments;
//   }
// };

// function insertComments(comments) {
//   return {
//     comments,
//     type: "ASSIGN_COMMENT_EVENTS",
//   };
// }

// function addComment(comment) {
//   return {
//     type: "ADD_COMMENT",
//     comment,
//   };
// }

// export function onSubmitEvent(e, formData) {
//   e.preventDefault();
//   return postEvent(formData);
// }

// function postEvent(formData) {
//   return (dispatch) => {
//     request("post", "COMMENT", true, formData).then(
//       (response) => {
//         if (formData._id) {
//           snackbar.show("Comment updated.");
//           // dispatch(updateEvent(response.data.data));
//         } else {
//           snackbar.show("Comment added.");
//           dispatch(addComment(response.data.data));
//         }
//       },
//       (err) => {
//         snackbar.show("Something went wrong.");
//       }
//     );
//   };
// }

// export function onCommentChange(e) {
//   return {
//     type: "FORM_COMMENT_CHANGED",
//     comment: e.target.value,
//   };
// }
