import React from "react";
import bindActionCreators from "redux/es/bindActionCreators";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {
  getPosDetails,
  applyForPos,
  getIsUserAppliedToPosition,
} from "../actions/getPosActions";
import connect from "react-redux/es/connect/connect";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import FieldLabelValueContent from "../components/FieldLabelValueContent";
import {
  isValidParam,
  getStringParam,
  getObjectParam,
  getArrayParam,
  getBooleanParam,
} from "../helpers/paramVerifier";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  detailContainer: {
    marginTop: 15,
    width: "100%",
  },
});

class PostPosDetails extends React.Component {
  constructor(props) {
    super(props);
    this.fields = [
      { name: "positionStatus", label: "Status" },
      { name: "positionType", label: "Type" },
      { name: "reqExp", label: "Required Exp" },
      { name: "role", label: "Role" },
      { name: "gender", label: "Gender" },
      { name: "ageMin", label: "Min Age", type: "integer" },
      { name: "ageMax", label: "Age Max", type: "integer" },
      { name: "dept", label: "Department" },
      { name: "description", label: "Description" },
      { name: "lastDate", label: "Last Date", type: "date" },
      {
        name: "otherRolesCanApply",
        label: "Others Role Can Apply",
        type: "boolean",
      },
      { name: "createdAt", label: "Created At", type: "date" },
      { name: "updatedAt", label: "Updated At", type: "date" },
    ];
    this.state = {
      isUserApplied: true,
    };
  }

  componentDidMount() {
    const pos_id = this.props.match.params.pos_id;
    this.props.getPosDetails(pos_id);
    this.getIsUserApplied(pos_id);
  }

  getIsUserApplied(positionId) {
    try {
      let promise = getIsUserAppliedToPosition(positionId);
      promise.then((response) => {
        if (
          isValidParam(response) &&
          !getBooleanParam(response.error) &&
          response.status == 200
        ) {
          let isUserApplied = getBooleanParam(response.data);
          this.setState({ isUserApplied: isUserApplied });
        }
      });
    } catch (error) {}
  }

  getActions() {
    let actions = new Array();
    try {
      let isUserApplied = getBooleanParam(this.state.isUserApplied);
      actions.push(
        <Button
          variant="contained"
          color="primary"
          style={{
            padding: "10px 20px",
            marginRight: "auto",
            marginLeft: "auto",
            textTranform: "none",
          }}
          disabled={isUserApplied}
          onClick={(e) =>
            this.props.applyForPos(e, this.props.match.params.pos_id)
          }
        >
          Apply
        </Button>
      );
    } catch (error) {}
    return actions;
  }

  applyJob() {
    try {
    } catch (error) {}
  }

  render() {
    const { classes } = this.props;
    let posDetails = getObjectParam(this.props.posDetails);
    let actions = this.getActions();
    let breadcrumbs = isValidParam(posDetails) ? (
      <ApplicantBreadcrumbs {...this.props} />
    ) : null;
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Grid>{breadcrumbs}</Grid>
          <Grid className={classes.detailContainer}>
            <FieldLabelValueContent
              fields={this.fields}
              data={posDetails}
              actions={actions}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    posDetails: state.PosTableReducer.posDetails,
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      getPosDetails,
      applyForPos,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(PostPosDetails));

class ApplicantBreadcrumbs extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    let posDetails = getObjectParam(this.props.posDetails);
    return (
      <Breadcrumbs aria-label="Breadcrumb" style={{ margin: 10 }}>
        <Typography color="textPrimary" style={{ fontSize: 14 }}>
          <Link to={`/all-positions/${posDetails.postedBy}`}>Positions</Link>
        </Typography>
        <Typography
          color="textPrimary"
          style={{ color: "#82707dd1", fontSize: 14 }}
        >
          {" "}
          {posDetails.positionName}{" "}
        </Typography>
      </Breadcrumbs>
    );
  }
}
