import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Button,
  MenuItem,
  Menu,
  Popper,
} from "@material-ui/core";
import { lighten, makeStyles, fade } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CreateSearchForm from "./CreateSearchForm";
import DeleteIcon from "@material-ui/icons/Delete";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Typography from "@material-ui/core/Typography";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontSize: "16px",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  DialogTitleHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  formControl: {
    width: "33%",
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },

  tablepagination: {
    display: "flex",
    flexDirection: "column",

    width: "100%",
  },
  closeIconButton: {
    padding: "16px 24px",
  },
  margin: {
    margin: theme.spacing(1),
  },
  flexRow: {
    display: "flex",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "8px",
  },
  search: {
    border: "1px solid #c4c4c4;",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  buttonGroup: {
    marginTop: "16px",
  },
}));

const compensationArray = [
  { value: "Any", label: "Any" },
  { value: "Paid", label: "Paid" },
  { value: "Free", label: "Free" },
];

const postedArray = [
  { value: "Any", label: "Any" },
  { value: "addedRecently", label: "Recently Posted" },
  { value: "expiringSoon", label: "Expiring Soon" },
];

const eligibilityArray = [
  { value: "Any", label: "Any" },
  { value: "eligible", label: "Eligible" },
  { value: "notEligible", label: "Not Eligible" },
];

const statusArray = [
  { value: "Any", label: "Any" },
  { value: "Open", label: "Open" },
  { value: "Closed", label: "Closed" },
];

const gendersArray = [
  { value: "Any", label: "Any" },
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

const Filter = ({
  onFilter,
  searchParams = {},
  countriesList,
  jobtypes,
  roles,
  requestStates,
  statesList,
  industriesList,
  searchNames,
  handleSearchNameSelect,
  onCreateSearch,
  onDeleteSearch,
  handleSearch,
}) => {
  const classes = useToolbarStyles();

  const {
    searchAgeMax,
    searchAgeMin,
    searchIndustry,
    searchGender,
    searchCountry,
    searchName,
    searchRole,
    searchState,
    searchType,
    searchCompensation,
    searchPosted,
    searchEligibility,
    searchStatus,
  } = searchParams;
  const [ageMax, setAgeMax] = useState(searchAgeMax);
  const [ageMin, setAgeMin] = useState(searchAgeMin);
  const [industry, setIndustry] = useState(searchIndustry);
  const [gender, setGender] = useState(searchGender);
  const [country, setCountry] = useState(searchCountry);
  const [mainrole, setRole] = useState(searchRole);
  const [positionType, setType] = useState(searchType);
  const [compensation, setCompensation] = useState(searchCompensation);
  const [posted, setPosted] = useState(searchPosted || "Any");
  const [eligibility, setEligibility] = useState(searchEligibility || "Any");
  const [status, setStatus] = useState(searchStatus || "Any");
  const [state, setState] = useState(searchState);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [formOpen, setFormOpen] = useState(false);
  const [search, setSearch] = useState("");

  const PopperMy = function (props) {
    return (
      <Popper {...props} style={{ minWidth: 212 }} placement="bottom-start" />
    );
  };

  useEffect(() => {
    const {
      searchAgeMax,
      searchAgeMin,
      searchIndustry,
      searchGender,
      searchCountry,
      searchRole,
      searchState,
      searchType,
      searchCompensation,
      searchPosted,
      searchEligibility,
      searchStatus,
    } = searchParams;
    setAgeMax(searchAgeMax);
    setAgeMin(searchAgeMin);
    setIndustry(searchIndustry);
    setGender(searchGender);
    setCountry(searchCountry);
    setRole(searchRole);
    setState(searchState);
    setType(searchType);
    setCompensation(searchCompensation);
    setEligibility(searchEligibility || "Any");
    setStatus(searchStatus || "Any");
    setPosted(searchPosted || "Any");
  }, [searchParams]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleFilter = () => {
    const params = {
      ageMax,
      ageMin,
      industry,
      compensation,
      eligibility,
      status,
      posted,
      gender,
      positionType,
      country,
      mainrole,
      state,
      page: 0,
      limit: 10,
      key: search,
    };

    onFilter(params);
  };

  const handleClear = () => {
    setAgeMax(0);
    setAgeMin(0);
    setIndustry("Any");
    setGender("Any");
    setCountry("Any");
    setRole("Any");
    setState("Any");
    setType("Any");
    setCompensation("Any");
    setEligibility("Any");
    setStatus("Any");
    setPosted("Any");
    setSearch("");
    const params = {
      ageMax: 0,
      ageMin: 0,
      industry: "Any",
      compensation: "Any",
      posted: "Any",
      eligibility: "Any",
      status: "Any",
      gender: "Any",
      positionType: "Any",
      country: "Any",
      mainrole: "Any",
      state: "Any",
      page: 0,
      limit: 10,
      key: "",
    };
    onFilter(params);
  };

  const onCountrySelect = (country) => {
    requestStates(country);
    setCountry(country);
    country !== "India" && setIndustry("Any");
  };

  const handleCreate = (name) => {
    const params = {
      searchAgeMax: ageMax,
      searchAgeMin: ageMin,
      searchIndustry: industry,
      searchGender: gender,
      searchCountry: country,
      searchRole: mainrole,
      searchState: state,
      searchName: name,
      searchType: positionType,
      searchCompensation: compensation,
      searchEligibility: eligibility,
      searchStatus: status,
      searchPosted: posted,
    };

    onCreateSearch(params).then((response) => {
      if (!response.error) {
        setFormOpen(false);
      }
    });
  };

  const handleDelete = (event, searchId) => {
    event.stopPropagation();

    onDeleteSearch({ searchId });
  };

  const handleNameClick = (index) => {
    setOpen(false);
    handleSearchNameSelect(index);
  };

  const onSearchTextChange = (text) => {
    setSearch(text);
    // handleSearch(text);
  };

  return (
    <>
      <form className={classes.container}>
        <Grid container spacing={2} style={{ margin: "4px" }}>
          <div className={classes.flexCol} style={{ width: "69%" }}>
            <div className={classes.flexRow}>
              <Grid item className={classes.formControl}>
                <Autocomplete
                  options={["Any", ...countriesList]}
                  getOptionLabel={(option) => option}
                  onChange={(e, object) => onCountrySelect(object)}
                  value={country}
                  renderInput={(params) => (
                    <TextField
                      inputProps={{
                        autoomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      {...params}
                      label="Select Country"
                      value={country}
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item className={classes.formControl}>
                <Autocomplete
                  options={["Any", ...statesList]}
                  getOptionLabel={(option) => option}
                  onChange={(e, object) => setState(object)}
                  disabled={!Boolean(country)}
                  value={state}
                  renderInput={(params) => (
                    <TextField
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      {...params}
                      label="Select State"
                      value={state}
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                  )}
                  disableClearable
                />
              </Grid>
              <Grid item className={classes.formControl}>
                <Autocomplete
                  options={["Any", ...jobtypes]}
                  getOptionLabel={(option) => option}
                  onChange={(e, object) => setType(object)}
                  value={positionType}
                  PopperComponent={PopperMy}
                  renderInput={(params) => (
                    <TextField
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      {...params}
                      label="Type"
                      variant="outlined"
                      fullWidth
                      size="small"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item className={classes.formControl}>
                <TextField
                  inputProps={{
                    autocomplete: "new-password",
                    form: {
                      autocomplete: "off",
                    },
                  }}
                  fullWidth
                  label="Status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  select
                  variant="outlined"
                  size="small"
                >
                  {statusArray.map((option) => (
                    <MenuItem
                      style={{ fontSize: "1em" }}
                      key={option.value}
                      value={option.value}
                      variant="outlined"
                      size="small"
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </div>
            <div className={classes.flexRow}>
              <Grid item className={classes.formControl}>
                <Autocomplete
                  options={["Any", ...industriesList]}
                  getOptionLabel={(option) => option}
                  onChange={(e, object) => setIndustry(object)}
                  value={industry}
                  disabled={country !== "India"}
                  renderInput={(params) => (
                    <TextField
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      {...params}
                      label="Select Industry"
                      value={industry}
                      fullWidth
                      disabled={country !== "India"}
                      variant="outlined"
                      size="small"
                    />
                  )}
                  disableClearable
                />
              </Grid>
              <Grid item className={classes.formControl}>
                <Autocomplete
                  options={["Any", ...roles]}
                  getOptionLabel={(option) => option}
                  onChange={(e, object) => setRole(object)}
                  value={mainrole}
                  PopperComponent={PopperMy}
                  renderInput={(params) => (
                    <TextField
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      {...params}
                      label="Role"
                      variant="outlined"
                      fullWidth
                      size="small"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item className={classes.formControl}>
                <TextField
                  inputProps={{
                    autocomplete: "new-password",
                    form: {
                      autocomplete: "off",
                    },
                  }}
                  fullWidth
                  label="Compensation"
                  value={compensation}
                  onChange={(e) => setCompensation(e.target.value)}
                  select
                  variant="outlined"
                  size="small"
                >
                  {compensationArray.map((option) => (
                    <MenuItem
                      style={{ fontSize: "1em" }}
                      key={option.value}
                      value={option.value}
                      variant="outlined"
                      size="small"
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item className={classes.formControl}>
                <TextField
                  inputProps={{
                    autocomplete: "new-password",
                    form: {
                      autocomplete: "off",
                    },
                  }}
                  fullWidth
                  label="Eligibility"
                  value={eligibility}
                  onChange={(e) => setEligibility(e.target.value)}
                  select
                  variant="outlined"
                  size="small"
                >
                  {eligibilityArray.map((option) => (
                    <MenuItem
                      style={{ fontSize: "1em" }}
                      key={option.value}
                      value={option.value}
                      variant="outlined"
                      size="small"
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </div>
            <div className={classes.flexRow}>
              <Grid item className={classes.formControl}>
                <TextField
                  inputProps={{
                    autocomplete: "new-password",
                    form: {
                      autocomplete: "off",
                    },
                  }}
                  fullWidth
                  label="Date"
                  value={posted}
                  onChange={(e) => setPosted(e.target.value)}
                  select
                  variant="outlined"
                  size="small"
                >
                  {postedArray.map((option) => (
                    <MenuItem
                      style={{ fontSize: "1em" }}
                      key={option.value}
                      value={option.value}
                      variant="outlined"
                      size="small"
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item className={classes.formControl}>
                <TextField
                  inputProps={{
                    autocomplete: "new-password",
                    form: {
                      autocomplete: "off",
                    },
                  }}
                  fullWidth
                  label="Gender"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  select
                  variant="outlined"
                  size="small"
                >
                  {gendersArray.map((option) => (
                    <MenuItem
                      style={{ fontSize: "1em" }}
                      key={option.value}
                      value={option.value}
                      variant="outlined"
                      size="small"
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item className={classes.formControl}>
                <div>
                  <TextField
                    inputProps={{
                      autoComplete: "off",
                    }}
                    fullWidth
                    label="Min Age"
                    value={ageMin}
                    onChange={(e) => setAgeMin(e.target.value)}
                    variant="outlined"
                    size="small"
                  />
                </div>
              </Grid>

              <Grid item className={classes.formControl}>
                <div>
                  <TextField
                    inputProps={{
                      autoComplete: "off",
                    }}
                    fullWidth
                    label="Max Age"
                    value={ageMax}
                    onChange={(e) => setAgeMax(e.target.value)}
                    variant="outlined"
                    size="small"
                  />
                </div>
              </Grid>
            </div>
          </div>
          <Grid className={classes.flexCol} style={{ width: "31%" }}>
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "8px",
              }}
            >
              <TextField
                inputProps={{
                  autoComplete: "off",
                }}
                fullWidth
                placeholder="Press Enter to Search"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleFilter();
                  }
                }}
                value={search}
                onChange={(e) => onSearchTextChange(e.target.value)}
                variant="outlined"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "10px",
              }}
              item
            >
              <div style={{ width: "50%", marginRight: "4px" }}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={() => setFormOpen(true)}
                  style={{ textTransform: "none" }}
                >
                  Save
                </Button>
              </div>
              <div style={{ width: "50%" }}>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={handleClick}
                  style={{ textTransform: "none" }}
                >
                  List
                </Button>
              </div>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
              >
                {searchNames.map((name, index) => (
                  <MenuItem onClick={() => handleNameClick(index)}>
                    <DeleteIcon
                      fontSize="large"
                      onClick={(e) => handleDelete(e, name._id)}
                    />
                    {name.searchName}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>

            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "10px",
              }}
              item
            >
              <div style={{ width: "50%", marginRight: "4px" }}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={handleFilter}
                  style={{ textTransform: "none" }}
                >
                  Search
                </Button>
              </div>
              <div style={{ width: "50%" }}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={() => handleClear()}
                  style={{ textTransform: "none" }}
                >
                  Clear
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </form>

      {formOpen && (
        <CreateSearchForm
          open={formOpen}
          handleClose={() => setFormOpen(false)}
          handleCreate={handleCreate}
        />
      )}
    </>
  );
};

export default Filter;
