import React, { useState, useEffect } from "react";
import DateTimePicker from "react-datetime-picker";
import connect from "react-redux/es/connect/connect";
import { TextInput, TextAreaInput } from "./InputFields";
import bindActionCreators from "redux/es/bindActionCreators";
import {
  onSubmitEvent,
  onTitleChange,
  onDescChange,
  onStartChange,
  onEndChange,
  onDeleteEvent,
  onCloseModal,
} from "../actions/calendarActions";

const CalendarEventModal = (props) => {
  const user_id = props.user_id;
  const loggedInUser = props.userDetail._id;

  return (
    <div
      className={"modal fade"}
      style={{ marginTop: "50px" }}
      id="eventModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        {loggedInUser && loggedInUser === user_id ? (
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLabel">
                Add Event
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={(e) => props.onCloseModal(e)}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form
              onSubmit={(e) => {
                loggedInUser === user_id &&
                  props.onSubmitEvent(e, props.modalContent, user_id);
              }}
            >
              <div className="modal-body">
                <div className="row">
                  <div className="form-group col-md-12">
                    <label>Title</label>
                    <TextInput
                      className={"form-control"}
                      onChange={props.onTitleChange}
                      value={props.modalContent.title}
                    />
                  </div>
                  <div className="form-group col-sm-6">
                    <label>Start Date</label>
                    <DateTimePicker
                      // className={"form-control"}
                      onChange={(value, e) => props.onStartChange(e, value)}
                      value={
                        props.modalContent.start &&
                        new Date(props.modalContent.start)
                      }
                    />
                  </div>
                  <div className="form-group col-sm-6">
                    <label>End Date</label>
                    <DateTimePicker
                      // className={"form-control"}
                      onChange={(value, e) => props.onEndChange(e, value)}
                      value={
                        props.modalContent.end &&
                        new Date(props.modalContent.end)
                      }
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <label>Description</label>
                    <TextAreaInput
                      className={"form-control"}
                      onChange={props.onDescChange}
                      value={props.modalContent.desc}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {props.modalContent._id ? (
                  <button
                    type="button"
                    onClick={(e) => props.onDeleteEvent(props.modalContent._id)}
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                ) : (
                  ""
                )}
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={(e) => props.onCloseModal(e)}
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLabel">
                Event Details
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={(e) => props.onCloseModal(e)}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="form-group col-md-12">
                  <label>Title</label>
                  <TextInput
                    className={"form-control"}
                    value={props.modalContent.title}
                    readonly
                  />
                </div>
                <div className="form-group col-sm-6">
                  <label>Start Date</label>
                  <TextInput
                    className={"form-control"}
                    value={
                      props.modalContent.start &&
                      new Date(props.modalContent.start).toLocaleString()
                    }
                    readonly
                  />
                </div>
                <div className="form-group col-sm-6">
                  <label>End Date</label>
                  <TextInput
                    className={"form-control"}
                    value={
                      props.modalContent.end &&
                      new Date(props.modalContent.end).toLocaleString()
                    }
                    readonly
                  />
                </div>
                <div className="form-group col-md-12">
                  <label>Description</label>
                  <TextAreaInput
                    className={"form-control"}
                    value={props.modalContent.desc}
                    readonly
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={(e) => props.onCloseModal(e)}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    modalContent: state.calendarReducer.modalContent,
    userDetail: state.userDetailsReducer.userDetail,
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      onSubmitEvent,
      onTitleChange,
      onDescChange,
      onStartChange,
      onEndChange,
      onDeleteEvent,
      onCloseModal,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapActionsToProps)(CalendarEventModal);
