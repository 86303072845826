import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import IconButton from "@material-ui/core/IconButton";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import PinterestIcon from "@material-ui/icons/Pinterest";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import TelegramIcon from "@material-ui/icons/Telegram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import RedditIcon from "@material-ui/icons/Reddit";
import AddBoxSharpIcon from "@material-ui/icons/AddBoxSharp";
import Slide from "@material-ui/core/Slide";
import {
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { shareGallery } from "../../actions/applicantsDetailsActions";
import useClipboard from "react-use-clipboard";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PublicShare = ({ open, handleClose, url, dispatch, gallery_id }) => {
  const [isCopied, setCopied] = useClipboard(url, {
    successDuration: 2000,
  });

  const handleShare = () => {
    shareGallery({ gallery_id });
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">Share</DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-slide-description">
          Select an app to share to.
        </DialogContentText>
        <ButtonGroup fullWidth variant="outlined" onClick={handleShare}>
          <FacebookShareButton url={url}>
            <IconButton size="medium">
              <FacebookIcon fontSize="large" color="primary" />
            </IconButton>
          </FacebookShareButton>
          <InstapaperShareButton url={url}>
            <IconButton size="medium">
              <InstagramIcon fontSize="large" color="secondary" />
            </IconButton>
          </InstapaperShareButton>
          <LinkedinShareButton url={url}>
            <IconButton size="medium">
              <LinkedInIcon fontSize="large" color="primary" />
            </IconButton>
          </LinkedinShareButton>
          <TwitterShareButton url={url}>
            <IconButton size="medium">
              <TwitterIcon fontSize="large" style={{ color: "#00acee" }} />
            </IconButton>
          </TwitterShareButton>
          <PinterestShareButton url={url} media={url}>
            <IconButton size="medium">
              <PinterestIcon fontSize="large" color="secondary" />
            </IconButton>
          </PinterestShareButton>
          <RedditShareButton url={url}>
            <IconButton size="medium">
              <RedditIcon fontSize="large" color="secondary" />
            </IconButton>
          </RedditShareButton>
          <WhatsappShareButton url={url}>
            <IconButton size="medium">
              <WhatsAppIcon fontSize="large" style={{ color: "#4FCE5D" }} />
            </IconButton>
          </WhatsappShareButton>
          <TelegramShareButton url={url}>
            <IconButton size="medium">
              <TelegramIcon fontSize="large" style={{ color: "#0088cc" }} />
            </IconButton>
          </TelegramShareButton>
          {/* <IconButton size="medium">
            <AddBoxSharpIcon fontSize="large" color="primary" />
          </IconButton> */}
        </ButtonGroup>
        <DialogContentText id="alert-dialog-slide-description">
          Or copy link
        </DialogContentText>
        <Grid container spacing={1}>
          <Grid item xs={10}>
            <TextField value={url} fullWidth contentEditable={false} />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              onClick={setCopied}
            >
              {isCopied ? "Copied!" : "Copy"}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          style={{ textTransform: "none" }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect()(withRouter(PublicShare));
