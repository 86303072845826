// import dependencies
import React from "react";
import { connect } from "react-redux";
import Viewer from "react-viewer";
import "react-viewer/dist/index.css";
import getURL from "../helpers/apis";

import {
  getPhotos,
  handleUpload1,
  onDeletePhotos,
} from "../actions/profilePhotoActions";
import { bindActionCreators } from "redux";

class PhotoComponent extends React.Component {
  constructor(props) {
    super(props);
    this.onDelete = this.onDelete.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onDelete(e) {
    e.stopPropagation();
    this.props.onDelete(this.props.name);
  }

  onClick() {
    this.props.onClick(this.props.imageIndex);
  }

  render() {
    return (
      <div onClick={this.onClick} className={"col-md-3 col-sm-4 col-xs-4 pfa"}>
        <div className={"gridalb-wrp"}>
          <img width="100%" height="134px" src={this.props.name} alt="" />
        </div>
        <div className={`overlay`} onClick={this.onDelete}>
          <i className={"fa fa-trash"} />
        </div>
      </div>
    );
  }
}

class UploadPhotoForm extends React.Component {
  static openFilePicker() {
    document.getElementById("upload_media").click();
  }

  static onFileChange() {
    document.getElementById("submit_media").click();
  }

  render() {
    return (
      <form onSubmit={this.props.handleUpload1} encType={"multipart/form-data"}>
        <input
          type={"file"}
          id="upload_media"
          multiple={"true"}
          name={"media"}
          className={"hide"}
          onChange={UploadPhotoForm.onFileChange}
        />
        <div className={"upload-video text-right"}>
          <div onClick={UploadPhotoForm.openFilePicker} className={"add-btn"}>
            <i className={"fa fa-upload fa-1x"} />
            <span>&nbsp;Upload photo</span>
          </div>
        </div>
        <input type={"submit"} className={"hide"} id={"submit_media"} />
      </form>
    );
  }
}

class ProfilePhotosComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      imageIndex: 0,
    };
    this.onClick = this.onClick.bind(this);
  }

  componentWillMount() {
    if (this.props.profilePhoto.photos.length === 0) {
      this.props.getPhotos();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.profilePhoto.dataSetChanged !==
      nextProps.profilePhoto.dataSetChanged
    ) {
      this.props.getPhotos();
    }
  }

  onClick(imageIndex) {
    this.setState((prevState) => ({
      visible: !prevState.visible,
      imageIndex: imageIndex || 0,
    }));
  }

  render() {
    let PhotoList = (
      <h5 className={"text-center"}>
        You haven't uploaded any photos yet. Use{" "}
        <span>
          <i className={"fa fa-upload"} />
        </span>{" "}
        button to upload photos.
      </h5>
    );
    let viewerImagesArray = [];

    if (this.props.profilePhoto.photos && this.props.profilePhoto.photos.length)
      PhotoList = this.props.profilePhoto.photos.map((photo, i) => {
        viewerImagesArray.push({ src: "/uploads/" + photo, alt: "" });

        return (
          <li key={photo}>
            <PhotoComponent
              name={photo}
              imageIndex={i}
              onDelete={this.props.onDeletePhotos}
              onClick={this.onClick}
            />
          </li>
        );
      });

    return (
      <div
        className={"card"}
        style={{ height: "300px", width: "600px", overflow: "auto" }}
      >
        <div className={"col-md-12 media_cont"}>
          <ul className={"media_list"}>
            <UploadPhotoForm handleUpload1={this.props.handleUpload1} />
            {PhotoList}
          </ul>
          <Viewer
            visible={this.state.visible}
            onClose={this.onClick}
            images={viewerImagesArray}
            rotatable={false}
            noImgDetails={true}
            activeIndex={this.state.imageIndex}
            zIndex={10000}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profilePhoto: state.profilePhotoReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getPhotos,
      handleUpload1,
      onDeletePhotos,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePhotosComponent);
