import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getPost } from "../../../../actions/postActions";
import PostItem from "../PostItem";

const Post = ({ dispatch, post, fetchingPost, match, history }) => {
  useEffect(() => {
    dispatch(getPost(match.params.post_id));
  }, [match.params.post_id]);

  return (
    <div style={{ marginTop: 30, marginBottom: 30, width: "100%" }}>
      <Button
        color="secondary"
        variant="contained"
        size="small"
        onClick={(e) => {
          history.goBack();
        }}
        style={{ float: "right", margin: "8px", textTransform: "none" }}
      >
        Back
      </Button>
      <Typography style={{ marginLeft: 12 }} variant="h4">
        Post
      </Typography>
      <Divider variant="fullWidth" />
      {!fetchingPost && <PostItem post={post} exp={true} />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  post: state.postsReducer.post,
  fetchingPost: state.postsReducer.fetchingPost,
});

export default connect(mapStateToProps)(withRouter(Post));
