import React from "react";
import {
  TextInput,
  PasswordInput,
  EmailInput,
  NumberInput
} from "./InputFields";

export class AddOnTextInput extends React.Component {
  render() {
    const { formInputsTheme, ...other } = this.props;
    return (
      <div className="input-group">
        <span
          className={
            formInputsTheme.className + " input-group-addon input-group-cust"
          }
        >
          <img src="/assets/images/icons/user%20icon.png" alt="" />
        </span>
        <TextInput {...other} className={"form-control"} />
      </div>
    );
  }
}

export class AddOnPasswordInput extends React.Component {
  render() {
    const { formInputsTheme, ...other } = this.props;
    return (
      <div className="input-group">
        <span
          className={
            formInputsTheme.className + " input-group-addon input-group-cust"
          }
        >
          <img src="/assets/images/icons/locked%20icon.png" alt="" />
        </span>
        <PasswordInput {...other} className={"form-control"} />
      </div>
    );
  }
}

export class AddOnPhoneInput extends React.Component {
  render() {
    const { formInputsTheme, ...other } = this.props;

    return (
      <div className="input-group">
        <span
          className={
            formInputsTheme.className + " input-group-addon input-group-cust"
          }
        >
          {/*<img src="../assets/images/icons/locked%20icon.png" alt=""/>*/}
          <i className="fa fa-phone register-phone" />
        </span>
        <NumberInput {...other} className={"form-control"} />
      </div>
    );
  }
}

export class AddOnEmailInput extends React.Component {
  render() {
    const { formInputsTheme, ...other } = this.props;
    return (
      <div className="input-group">
        <span
          className={
            formInputsTheme.className + " input-group-addon input-group-cust"
          }
        >
          <img src="/assets/images/icons/e-mail%20icon.png" alt="" />
        </span>
        <EmailInput {...other} className={"form-control"} />
      </div>
    );
  }
}
