import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import {
  Button,
  Grid,
  Select,
  MenuItem,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  getUserFreeAdSpace,
  registerForFreeAd,
} from "../../actions/adSpaceActions";
import { openDialog } from "../../actions/appActions";
import { constants } from "../../helpers/constants";
import UserGalleryComponent from "./UserGalleryComponent";
import UploadDialog from "./UploadDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  paymentButton: {
    margin: "24px",
  },
  paymentDetailsRow: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: "8px",
  },
  divider: {
    marginBottom: "8px",
  },
  paymentDetials: {
    width: "100%",
    textAlign: "center",
  },
  warningMessage: {
    display: "flex",
    alignItems: "center",
  },
  warningTyphography: {
    color: "white",
  },
  warningIcon: {
    marginLeft: "6px",

    fontSize: "20px",
  },
  feature: {
    listStyleType: "squre",
  },
}));

const Index = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openUpload, setOpenUpload] = useState(false);
  const [platform, setPlatform] = useState(null);
  const [yourLink, setYourLink] = useState(null);
  const [ourLink, setOurLink] = useState(null);

  const userDetail = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.userDetail
  );
  const userFreeAdSpaceList = useSelector(
    ({ adSpaceReducer }) => adSpaceReducer.userFreeAdSpaceList
  );
  const userFreeAdSpaceListLoading = useSelector(
    ({ adSpaceReducer }) => adSpaceReducer.userFreeAdSpaceListLoading
  );

  const handleCallback = () => {
    dispatch(getUserFreeAdSpace("active"));
  };

  useEffect(() => {
    dispatch(getUserFreeAdSpace("active"));
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.paymentButton}>
        <Typography
          component="h1"
          variant="h4"
          align="center"
          color="textPrimary"
        >
          Free Adspace
        </Typography>
        {!userFreeAdSpaceListLoading &&
          (!userFreeAdSpaceListLoading &&
          userFreeAdSpaceList &&
          userFreeAdSpaceList.length > 0 ? (
            <div className={classes.paymentDetials}>
              <Divider className={classes.divider} />
              <Typography
                component="h1"
                variant="h6"
                color="textPrimary"
                gutterBottom
              >
                Current Free AdSpace Status :{" "}
                {userFreeAdSpaceList[userFreeAdSpaceList.length - 1].isRejected
                  ? "Rejected"
                  : userFreeAdSpaceList[userFreeAdSpaceList.length - 1]
                      .isApproved
                  ? "Approved"
                  : "In Progress"}
              </Typography>

              {/* <Button
              variant="contained"
              color="primary"
              style={{
                color: "white",
                textTransform: "none",
                margin: 12,
              }}
              onClick={() => setOpenUpload(true)}
            >
              Ad Media
            </Button> */}
              {/* {openUpload && ( */}
              {userFreeAdSpaceList &&
                userFreeAdSpaceList.length > 0 &&
                !userFreeAdSpaceList[userFreeAdSpaceList.length - 1]
                  .isApproved && (
                  <UploadDialog
                    // open={openUpload}
                    companyId={userDetail._id}
                    // handleClose={() => setOpenUpload(false)}
                    // handleClose={() => handleCloseUpload()}
                  />
                )}
              {userFreeAdSpaceList &&
                userFreeAdSpaceList.length > 0 &&
                userFreeAdSpaceList[userFreeAdSpaceList.length - 1]
                  .isApproved && (
                  <UserGalleryComponent
                    // open={openUpload}
                    userAd={userFreeAdSpaceList[userFreeAdSpaceList.length - 1]}
                    // handleClose={() => setOpenUpload(false)}
                    // handleClose={() => handleCloseUpload()}
                  />
                )}
              {/* )} */}
            </div>
          ) : (
            !userFreeAdSpaceListLoading && (
              <Grid container  justify="space-between" style={{ padding: 32 }}>
                   <Grid item className="display-flex" xs={12}>
                  <Typography
                    align="center"
                    style={{ margin: "10px", }}
                  >
                   Celebbook offering free advertisement to social media users, who advertises about Celebbook in their social media platforms. The whole process works as a shoutout for shoutout.
                  </Typography>
                </Grid>
                <Grid item className="display-flex" xs={6}>
                  <Typography
                    align="left"
                    variant="h6"
                    style={{ margin: "10px", color: constants.PRIMARY_COLOR }}
                  >
                    1) Your social media platform. <br/> Ex: Instagram, Youtube, Facebook etc. 
                  </Typography>
                </Grid>
                <Grid item className="display-flex flex-end" xs={6}>
                  <TextField
                  className="w-full"
                    // label="Role Name"
                    // variant="outlined"
                    value={platform}
                    onChange={(e) => {
                      setPlatform(e.target.value);
                    }}
                    error={platform === ""}
                    required
                  />
                </Grid>
                <Grid item className="display-flex" xs={6}>
                  <Typography
                    align="left"
                    variant="h6"
                    style={{ margin: "10px", color: constants.PRIMARY_COLOR }}
                  >
                    2) Enter your link to te advertised
                  </Typography>
                </Grid>
                <Grid item className="display-flex flex-end" xs={6}>
                  <TextField
                  className="w-full"
                    // label="Role Name"
                    // variant="outlined"
                    value={yourLink}
                    onChange={(e) => {
                      setYourLink(e.target.value);
                    }}
                    error={yourLink === ""}
                    required
                  />
                </Grid>
                <Grid className="display-flex" item xs={6}>
                  <Typography
                    align="left"
                    variant="h6"
                    style={{ margin: "10px", color: constants.PRIMARY_COLOR }}
                  >
                    3) Enter link in which you advertised about celebbook
                  </Typography>
                </Grid>
                <Grid item className="display-flex flex-end" xs={6}>
                  <TextField
                  className="w-full"
                    // label="Role Name"
                    // variant="outlined"
                    value={ourLink}
                    onChange={(e) => {
                      setOurLink(e.target.value);
                    }}
                    error={ourLink === ""}
                    required
                  />
                </Grid>

                <Grid item xs={12} align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{
                      color: "white",
                      margin: "32px",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      yourLink &&
                        yourLink !== "" &&
                        ourLink &&
                        ourLink !== "" &&
                        platform &&
                        platform !== "" &&
                        dispatch(
                          registerForFreeAd(
                            {
                              platform,
                              linkToBeAdvt: yourLink,
                              linkYouAdvt: ourLink,
                            },
                            handleCallback
                          )
                        );
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            )
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  // countriesList: state.registerReducer.countries
  //   ? state.registerReducer.countries.map((country) => country.country)
  //   : [],
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Index));
