import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const FavouriteName = ({ open, handleClose, handleSave, actionType }) => {
  const [value, setValue] = useState("");

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Favourite</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          label="Favourite Name"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          style={{ textTransform: "none" }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleSave(value, actionType)}
          color="primary"
          style={{ textTransform: "none" }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FavouriteName;
