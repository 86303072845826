const initialState = {
  username: "",
  password: "",
  loginButtonDisabled: false,
  rememberMeCheckBox: true,
  loggingIn: false,
  jwt: null,
  userDetail: null
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_GOING":
      return { ...state, loggingIn: true, loginButtonDisabled: true };
    case "LOGIN_PASSWORD_CHANGED":
      return {
        ...state,
        password: action.password
      };
    case "LOGIN_USERNAME_CHANGED":
      return {
        ...state,
        username: action.username
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        jwt: action.jwt,
        userDetail: action.userDetail
      };
    case "LOGIN_CHECKBOX_TOGGLED":
      return {
        ...state,
        rememberMeCheckBox: !state.rememberMeCheckBox
      };
    case "SET_INITIAL_LOGIN_STATE":
      return initialState;
    default:
      return state;
  }
};

export default loginReducer;
