// import dependencies
import React from "react";
import { connect } from "react-redux";
import {
  handleUpload,
  getVideos,
  onDeleteVideos,
} from "../actions/profileVideosActions";
import { bindActionCreators } from "redux";
import getURL from "../helpers/apis";

class VideoComponent extends React.Component {
  constructor(props) {
    super(props);
    this.onDelete = this.onDelete.bind(this);
  }

  onDelete() {
    this.props.onDelete(this.props.name);
  }

  render() {
    return (
      <div className={"col-md-3 col-sm-4 col-xs-4 pfa"}>
        <div className={"gridalb-wrp"}>
          <video width="100%" height="134px" controls>
            <source src={this.props.name} />
            No support.
          </video>
          <div className={`overlay`} onClick={this.onDelete}>
            <i className={"fa fa-trash"} />
          </div>
        </div>
      </div>
    );
  }
}

class UploadVideoForm extends React.Component {
  constructor(props) {
    super(props);
    this.openFilePicker = this.openFilePicker.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
  }
  openFilePicker() {
    this.inputImage.click();
  }

  onFileChange() {
    this.submitBtn.click();
  }

  render() {
    return (
      <form onSubmit={this.props.handleUpload} encType={"multipart/form-data"}>
        <input
          type={"file"}
          id="upload_media"
          multiple={"true"}
          name={"media"}
          className={"hide"}
          ref={(input) => {
            this.inputImage = input;
          }}
          onChange={this.onFileChange}
        />
        <div className={"upload-video text-right"}>
          <div onClick={this.openFilePicker} className={"add-btn"}>
            <i className={"fa fa-upload fa-1x"} />
            <span>&nbsp;Upload video</span>
          </div>
        </div>
        <input
          type={"submit"}
          className={"hide"}
          ref={(input) => {
            this.submitBtn = input;
          }}
        />
      </form>
    );
  }
}

class ProfileVideosComponent extends React.Component {
  componentWillMount() {
    if (this.props.profileVideo.videos.length === 0) {
      this.props.getVideos();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.profileVideo.dataSetChanged !==
      nextProps.profileVideo.dataSetChanged
    ) {
      this.props.getVideos();
    }
  }

  render() {
    let VideoList = (
      <h5 className={"text-center"}>
        You haven't uploaded any videos yet. Use{" "}
        <span>
          <i className={"fa fa-upload"} />
        </span>{" "}
        button to upload videos.
      </h5>
    );
    if (
      this.props.profileVideo.videos &&
      this.props.profileVideo.videos.length
    ) {
      VideoList = this.props.profileVideo.videos.map((video, i) => (
        <li key={video}>
          <VideoComponent name={video} onDelete={this.props.onDeleteVideos} />
        </li>
      ));
    }

    return (
      <div
        className={"card"}
        style={{ height: "300px", width: "600px", overflow: "auto" }}
      >
        <div className={"col-md-12 media_cont"}>
          <ul className={"media_list"}>
            <li>
              <UploadVideoForm handleUpload={this.props.handleUpload} />
            </li>
            {VideoList}
          </ul>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profileVideo: state.profileVideoReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getVideos,
      handleUpload,
      onDeleteVideos,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileVideosComponent);
