import axios from "axios";
import { logout } from "../actions/appActions";
import { deleteToken } from "./globalFunctions";
import snackbar from "snackbar";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (
      (err.response.status === 403 || err.response.data.status === 403) &&
      err.response.data.msg === "TOKEN_EXPIRED"
    ) {
      deleteToken();
      snackbar.show("Your session expired. Please login again.");
    } else {
      throw err;
    }
  }
);

//request1('post', getURL('SAVE_POSITION'), true, params, null, {'Content-Type': 'application/x-www-form-urlencoded'})
const request1 = (
  type = "get",
  urlName,
  auth = false,
  payload = null,
  params = {},
  extraConfig
) => {
  const authToken = auth ? localStorage.getItem("jwt") : null;
  let headers = {};
  if (authToken) {
    headers["Authorization"] = authToken;
  }

  if (typeof extraConfig !== "undefined") {
    Object.keys(extraConfig).map((config) => {
      headers[config] = extraConfig[config];
    });
  }

  // return axios({
  //     method: type,
  //     url: urlName,
  //     data: payload,
  //     headers: headers,
  //     params: params
  // });

  return axios({
    method: type,
    url: urlName,
    data: payload,
    headers: headers,
    params: params,
  });
};

export default request1;
