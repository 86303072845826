import React, { useState, useEffect } from "react";
import { Paper, Grid, Chip, Typography } from "@material-ui/core";
import moment from "moment";

const MessageToComponent = (props) => {
  const [chat, setChat] = useState(props.chat);
  useEffect(() => {
    setChat(props.chat);
  }, [props]);
  return (
    <Chip
      label={
        <Typography
          align="right"
          style={{
            whiteSpace: "pre-wrap",
            padding: 0,
            wordBreak: "break-word",
          }}
        >
          <Typography
            style={{
              padding:2,
              paddingRight: 40,
              marginBottom: -12,
              textAlign: "left",
            }}
          >
            {chat.message}
          </Typography>
          <Typography
            variant="caption"
            display="inline"
            style={{ fontSize: "11px",opacity : 0.6 }}
          >
            {new Date(chat.timestamp).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </Typography>
        </Typography>
      }
      style={{ height: "fit-content", borderRadius: 8,borderBottomLeftRadius: 0 }}
      size="small"
    />
  );
};

export default MessageToComponent;
