import React, { useState, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import * as dates from "react-big-calendar/lib/utils/dates";
import { navigate } from "react-big-calendar/lib/utils/constants";
import CalendarEditModal from "./CalendarEditModal";
import {
  getCalendarEvents,
  onSelectSlot,
  onSelectEvent,
} from "../actions/calendarActions";
import bindActionCreators from "redux/es/bindActionCreators";
import "./Year.css";

const createCalendar = (currentDate) => {
  if (!currentDate) {
    currentDate = moment();
  } else {
    currentDate = moment(currentDate);
  }

  const first = currentDate.clone().startOf("month");
  const last = currentDate.clone().endOf("month");
  const weeksCount = Math.ceil((first.day() + last.date()) / 7);
  const calendar = Object.assign([], { currentDate, first, last });

  for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
    const week = [];
    calendar.push(week);
    calendar.year = currentDate.year();
    calendar.month = currentDate.month();

    for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
      const date = currentDate.clone().set("date", day + 1 - first.day());
      date.calendar = calendar;
      week.push(date);
    }
  }

  return calendar;
};

const CalendarDate = (props) => {
  let { events } = props;
  const { dateToRender, dateOfMonth } = props;
  const today =
    dateToRender.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
      ? "today"
      : "";
  var event = "";
  for (var i = 0; i < events.length; i++) {
    var start = moment(events[i].start);
    var end = moment(events[i].end);
    if (dateToRender.isBetween(start, end, "", "[]")) {
      event = "event";
      break;
    }
  }
  if (dateToRender.month() < dateOfMonth.month()) {
    return (
      <button disabled={true} className="date prev-month">
        {dateToRender.date()}
      </button>
    );
  }

  if (dateToRender.month() > dateOfMonth.month()) {
    return (
      <button disabled={true} className="date next-month">
        {dateToRender.date()}
      </button>
    );
  }

  return (
    <button
      className={`date ${event} in-month ${today}`}
      onClick={() => props.onClick(dateToRender)}
    >
      {/* <span>{event && "•"}</span> */}
      {dateToRender.date()}
    </button>
  );
};

const Calendar = (props) => {
  let { date, events, onSelectSlot, user_id, onView } = props;

  const [state, setState] = useState({
    calendar: undefined,
  });

  useEffect(() => {
    setState({ calendar: createCalendar(date) });
  }, [date]);

  if (!state.calendar) {
    return null;
  }
  const userId = localStorage.getItem("userId");

  const onDateClick = (date) => {
    onView("day", date);
    // if (userId === user_id)
    //   return onSelectSlot({
    //     slots: [date._d],
    //     start: date._d,
    //     end: date._d,
    //   });
  };

  return (
    <div className="month">
      <div className="month-name">
        {state.calendar.currentDate.format("MMMM").toUpperCase()}
      </div>
      {["S", "M", "T", "W", "T", "F", "S"].map((day, index) => (
        <span key={index} className="day">
          {day}
        </span>
      ))}
      {state.calendar.map((week, index) => (
        <div key={index}>
          {week.map((date) => (
            <CalendarDate
              key={date.date()}
              dateToRender={date}
              dateOfMonth={state.calendar.currentDate}
              onClick={(date) => onDateClick(date)}
              events={events.filter(
                (slot) =>
                  moment(slot.start).format("MM") === moment(date).format("MM")
              )}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

const Year = (props) => {
  let { date, calendarState } = props;
  Year.range(date);
  const months = [];
  const firstMonth = dates.startOf(date, "year");

  for (let i = 0; i < 12; i++) {
    months.push(
      <Calendar
        events={calendarState.filter(
          (slot) =>
            moment(slot.start).format("YYYY") === moment(date).format("YYYY")
        )}
        key={i + 1}
        date={dates.add(firstMonth, i, "month")}
        onSelectSlot={props.onSelectSlot}
        user_id={props.user_id}
        onView={(view, date) => props.onView(view, date)}
      />
    );
  }

  return <div className="year">{months.map((month) => month)}</div>;
};

Year.range = (date) => {
  return [dates.startOf(date, "year")];
};

Year.navigate = (date, action) => {
  switch (action) {
    case navigate.PREVIOUS:
      return dates.add(date, -1, "year");

    case navigate.NEXT:
      return dates.add(date, 1, "year");

    default:
      return date;
  }
};

Year.title = (date, { localizer }) =>
  localizer.format(date, "yearHeaderFormat");

const mapStateToProps = (state) => {
  return {
    calendarState: state.calendarReducer.events,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCalendarEvents,
      onSelectEvent,
      onSelectSlot,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionsToProps)(Year);
