import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory, Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import PersonIcon from "@material-ui/icons/Person";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PhotoSizeSelectLargeIcon from "@material-ui/icons/PhotoSizeSelectLarge";
import GridList from "@material-ui/core/GridList";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import ListSubheader from "@material-ui/core/ListSubheader";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { Paper, Tabs, Tab } from "@material-ui/core";
import { Loader } from "../UtilityComponents";
import getURL from "../../helpers/apis";
import Carousel from "./Carousel";
import { openAvatarModal } from "../../actions/appActions";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    overflow: "hidden",
    // marginLeft: "25px",
    // marginRight: "25px",
    // marginBottom: "25px",
    marginTop: 1,
    backgroundColor: theme.palette.background.paper,
  },
  actionIcon: {
    color: "rgba(255, 255, 255, 0.54)",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    margin: "auto",
  },
  icons: {
    display: "inline-block",
    cursor: "pointer",
    height: "auto",
  },
  gridList: {
    width: "100%",
  },
  gridTile: {
    height: "100%",
    paddingLeft: "100%",
  },
  empty: {
    marginLeft: "50%",
    marginRight: "50%",
    marginTop: "300px",
    fontSize: "36px",
    fontWeight: "400",
    minWidth: "max-content",
  },
  tilebar: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  titleBar1: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, " +
      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
}));

const GalleryComponent = ({
  gallery,
  galleryLoading,
  galleryType = null,
  isPrivate,
  handleRefresh,
  isFollowingVideos = false,
  galleryId = null,
}) => {
  const [hover, setHover] = useState(null);
  const [openGallery, setOpenGallery] = useState(galleryId);
  const [currentIndex, setCurrentIndex] = useState(null);
  const classes = useStyles();
  const rootUrl = getURL("UPLOADS");
  const dispatch = useDispatch();

  if (galleryType === "videos") {
    gallery = gallery.filter((i) => i.galleryType === "videos");
  }

  const handleClose = () => {
    setOpenGallery(false);
  };

  useEffect(() => {
    if (galleryId && gallery) {
      var index =
        gallery &&
        gallery
          .map(function (e) {
            return e._id;
          })
          .indexOf(galleryId);
      setCurrentIndex(index);
    } else {
      setCurrentIndex(0);
    }
  }, []);

  useEffect(() => {
    if (galleryId && gallery) {
      var index =
        gallery &&
        gallery
          .map(function (e) {
            return e._id;
          })
          .indexOf(galleryId);
      setCurrentIndex(index);
    }
  }, [galleryId]);

  if (!galleryLoading) {
    return (
      <Paper elevation={0} className={classes.root}>
        {openGallery && currentIndex !== null && (
          <Carousel
            open={Boolean(openGallery)}
            handleClose={() => handleClose()}
            galleries={gallery.map((i) => i._id)}
            currentIndex={currentIndex}
            handleChangeIndex={(i) => setCurrentIndex(i)}
            isPrivate={isPrivate}
            handleGalleryRefresh={handleRefresh}
            gallery_id={galleryId}
          />
        )}
        {gallery &&
          gallery.length === 0 &&
          (!isFollowingVideos ? (
            <div className={classes.empty}>No Galleries Found.</div>
          ) : (
            <div className={classes.empty}>Not Following Anyone.</div>
          ))}
        <GridList
          cellHeight="auto"
          cols={4}
          spacing={1}
          className={classes.gridList}
        >
          {/* <GridListTile key="Subheader" cols={2} style={{ height: "auto" }}>
          <ListSubheader component="div">December</ListSubheader>
        </GridListTile> */}
          {gallery &&
            gallery.length > 0 &&
            gallery.map((tile, index) => (
              <GridListTile
                key={tile._id}
                className={classes.gridTile}
                onMouseEnter={(e) => setHover(tile._id)}
                onMouseLeave={(e) => setHover("")}
              >
                <Card raised={true} square>
                  <CardMedia
                    component="img"
                    alt="No Thumbnail found."
                    // height="330"
                    style={{ height: "14vw" }}
                    // style={{ height: "15vw" }}
                    image={tile.thumbnail ? process.env.REACT_APP_BUCKET_NAME + tile.thumbnail : process.env.REACT_APP_BUCKET_NAME + tile.name}
                  />
                </Card>
                <GridListTileBar
                  // title={tile.name}
                  // subtitle={<span>by: {tile.name}</span>}
                  onClick={() => {
                    setCurrentIndex(index);
                    setOpenGallery(tile._id);
                  }}
                  className={classes.tilebar}
                  style={{ display: hover === tile._id ? "block" : "none" }}
                  subtitle={
                    <div
                      style={{ float: "right", display: "flex", margin: "2px" }}
                    >
                      <VisibilityIcon
                        className={classes.icons}
                        fontSize="medium"
                      />
                      <Typography variant="subtitle1">
                        {tile.noofviews}
                      </Typography>
                    </div>
                  }
                  actionIcon={
                    <IconButton
                      disableRipple
                      disableTouchRipple
                      disableFocusRipple
                      aria-label={`info about ${tile.galleryType}`}
                      className={classes.actionIcon}
                    >
                      {tile.galleryType === "videos" && (
                        <PlayArrowIcon
                          className={classes.icons}
                          fontSize="large"
                        />
                      )}
                      {tile.galleryType === "photos" && (
                        <PhotoSizeSelectLargeIcon
                          className={classes.icons}
                          fontSize="large"
                        />
                      )}
                    </IconButton>
                  }
                />
                {galleryType && (
                  <GridListTileBar
                    title={
                      <Link to={`/user_profile/${tile.userId}`}>
                        {tile.user_follow_customname || tile.username}
                      </Link>
                    }
                    titlePosition="bottom"
                    actionIcon={
                      <IconButton className={classes.icons}>
                        {tile.profilepic ? (
                          <Avatar
                            style={{
                              width: 32,
                              height: 32,
                            }}
                            alt="owner"
                            src={process.env.REACT_APP_BUCKET_NAME + tile.profilepic}
                            onClick={(e) =>
                              dispatch(openAvatarModal(process.env.REACT_APP_BUCKET_NAME + tile.profilepic))
                            }
                          />
                        ) : (
                          <Avatar
                            style={{
                              width: 32,
                              height: 32,
                            }}
                            alt="owner"
                            onClick={(e) => dispatch(openAvatarModal())}
                          >
                            <PersonIcon />
                          </Avatar>
                        )}
                      </IconButton>
                    }
                    actionPosition="left"
                    className={classes.titleBar1}
                  />
                )}
              </GridListTile>
            ))}
        </GridList>
      </Paper>
    );
  }
  return <Loader />;
};

export default GalleryComponent;
