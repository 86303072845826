import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import withRouter from "react-router-dom/es/withRouter";
import bindActionCreators from "redux/es/bindActionCreators";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FormGroup from "@material-ui/core/FormControl";
import FormControl from "@material-ui/core/FormControl";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import { Loader } from "../UtilityComponents";
import getURL from "../../helpers/apis";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  getGalleriesUserLiked,
  getGalleriesUserFavourited,
} from "../../actions/applicantsDetailsActions";
import { openAvatarModal } from "../../actions/appActions";

const useStyles = makeStyles((theme) => ({
  filter: {
    flexDirection: "row",
    justifyContent: "center",
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "8px",
  },
}));

const UserList = ({
  open,
  handleClose,
  actionType,
  actionName,
  isOwner,
  galleryId,
  dispatch,
}) => {
  const classes = useStyles();
  const [type, setType] = useState(actionType);
  const [gender, setGender] = useState("All");
  const [list, setList] = useState(null);
  const [allcount, setallcount] = useState(null);
  const [malecount, setmalecount] = useState(null);
  const [femalecount, setfemalecount] = useState(null);
  const [opencount, setopencount] = useState(null);
  const [hiddencount, sethiddencount] = useState(null);
  const [key, setKey] = useState("");
  const [page, setPage] = useState(1);
  const limit = 20;
  const [totalPages, setTotalPages] = useState(0);

  const rootUrl = getURL("UPLOADS");

  useEffect(() => {
    setList(null);
    setPage(1);
    if (actionName === "Like") {
      let params = {
        likeType: type,
        page: page - 1,
        limit: limit,
        gender: gender,
        galleryId: galleryId,
        key: key,
      };
      getGalleriesUserLiked(params).then((Obj) => {
        setList(Obj.data);
        setallcount(Obj.allcount);
        setmalecount(Obj.malecount);
        setfemalecount(Obj.femalecount);
        setopencount(Obj.opencount);
        sethiddencount(Obj.hiddencount);
        setTotalPages(Obj.totalPages);
      });
    } else {
      let params = {
        favouriteType: type,
        page: page - 1,
        limit: limit,
        gender: gender,
        galleryId: galleryId,
        key: key,
      };
      getGalleriesUserFavourited(params).then((Obj) => {
        setList(Obj.data);
        setallcount(Obj.allcount);
        setmalecount(Obj.malecount);
        setfemalecount(Obj.femalecount);
        setopencount(Obj.opencount);
        sethiddencount(Obj.hiddencount);
        setTotalPages(Obj.totalPages);
      });
    }
  }, [type, gender, key]);

  useEffect(() => {
    if (actionName === "Like") {
      let params = {
        likeType: type,
        page: page - 1,
        limit: limit,
        gender: gender,
        galleryId: galleryId,
        key: key,
      };
      getGalleriesUserLiked(params).then((Obj) => {
        list ? setList([...list, ...Obj.data]) : setList(Obj.data);
        setallcount(Obj.allcount);
        setmalecount(Obj.malecount);
        setfemalecount(Obj.femalecount);
        setopencount(Obj.opencount);
        sethiddencount(Obj.hiddencount);
        setTotalPages(Obj.totalPages);
      });
    } else {
      let params = {
        favouriteType: type,
        page: page - 1,
        limit: limit,
        gender: gender,
        galleryId: galleryId,
        key: key,
      };
      getGalleriesUserFavourited(params).then((Obj) => {
        list ? setList([...list, ...Obj.data]) : setList(Obj.data);
        setallcount(Obj.allcount);
        setmalecount(Obj.malecount);
        setfemalecount(Obj.femalecount);
        setopencount(Obj.opencount);
        sethiddencount(Obj.hiddencount);
        setTotalPages(Obj.totalPages);
      });
    }
  }, [page]);

  // useEffect(() => {
  //   switch (gender) {
  //     case "All":
  //       setTotalPages(Math.ceil(allcount / 10));
  //       break;
  //     case "Male":
  //       setTotalPages(Math.ceil(malecount / 10));
  //       break;
  //     case "Female":
  //       setTotalPages(Math.ceil(femalecount / 10));
  //       break;
  //   }
  // }, [allcount, malecount, femalecount, gender]);

  const handleChangePageNumber = (event, value) => {
    setPage(value);
  };

  const fetchMoreData = () => {
    setPage(page + 1);
  };

  const handleChangeType = (type) => {
    setType(type);
    setPage(1);
  };

  const handleChangeGender = (gender) => {
    setGender(gender);
    setPage(1);
  };

  const renderFilterBtns = () => {
    const typeBtns = () => (
      <ButtonGroup
        size="small"
        color="primary"
        aria-label="type"
        name="type"
        style={{
          margin: "6px",
          height: "24px",
        }}
      >
        <Button
          style={{ padding: "4px", textTransform: "none" }}
          onClick={(e) => handleChangeType("Open")}
          variant={type === "Open" ? "contained" : "outlined"}
        >
          Open{" - " + (type !== "Open" ? opencount : allcount)}
        </Button>
        <Button
          style={{ padding: "4px", textTransform: "none" }}
          onClick={(e) => handleChangeType("Hidden")}
          variant={type === "Hidden" ? "contained" : "outlined"}
        >
          Hidden{" - " + (type === "Open" ? hiddencount : allcount)}
        </Button>
      </ButtonGroup>
    );

    const genderBtns = () => (
      <ButtonGroup
        size="small"
        color="primary"
        aria-label="gender"
        name="gender"
        style={{
          margin: "6px",
          height: "24px",
        }}
      >
        <Button
          style={{ padding: "4px", textTransform: "none" }}
          onClick={(e) => handleChangeGender("All")}
          variant={gender === "All" ? "contained" : "outlined"}
        >
          All{" - " + allcount}
        </Button>
        <Button
          style={{ padding: "4px", textTransform: "none" }}
          onClick={(e) => handleChangeGender("Male")}
          variant={gender === "Male" ? "contained" : "outlined"}
        >
          M{" - " + malecount}
        </Button>
        <Button
          style={{ padding: "4px", textTransform: "none" }}
          onClick={(e) => handleChangeGender("Female")}
          variant={gender === "Female" ? "contained" : "outlined"}
        >
          F{" - " + femalecount}
        </Button>
      </ButtonGroup>
    );
    return (
      <FormGroup row={true} size="small" className={classes.filter}>
        {isOwner && typeBtns()}
        {genderBtns()}
      </FormGroup>
    );
  };
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="md"
      // style={{ textAlign: "center" }}
    >
      <DialogTitle>
        <Grid container justify="space-between">
          <Grid xs={2} style={{ whiteSpace: "word-wrap" }} item>
            {actionName}d By
          </Grid>
          <Grid xs={4} item>
            <TextField
              fullWidth
              autoFocus
              label="Search"
              value={key}
              onChange={(e) => setKey(e.target.value)}
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>{renderFilterBtns()}</Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers style={{ height: "600px",padding : 0 }} id="scrollableDiv">
        {list ? (
          <>
            {/* <div className={classes.pagination}>
              <Pagination
                count={totalPages}
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handleChangePageNumber}
              />
            </div> */}

            <InfiniteScroll
              dataLength={list.length}
              next={fetchMoreData}
              scrollableTarget="scrollableDiv"
              hasMore={Math.ceil(list.length / limit) < totalPages}
              loader={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "100px",
                  }}
                >
                  <CircularProgress />
                </div>
              }
            >
              <List>
                {list.length > 0 ? (
                  list.map((item) => {
                    const by = actionName === "Like" ? `likedBy` : "userId";
                    return (
                      <ListItem
                        style={{ padding: 0, paddingLeft: 5 }}
                        button
                        key={item._id}
                        divider
                      >
                        <ListItemAvatar>
                          {item[by].profilepic ? (
                            <Avatar
                              alt="owner"
                              src={
                                process.env.REACT_APP_BUCKET_NAME +
                                item[by].profilepic
                              }
                              onClick={(e) =>
                                dispatch(
                                  openAvatarModal(
                                    process.env.REACT_APP_BUCKET_NAME +
                                      item[by].profilepic
                                  )
                                )
                              }
                            />
                          ) : (
                            <Avatar
                              alt="owner"
                              onClick={(e) => dispatch(openAvatarModal())}
                            >
                              <PersonIcon />
                            </Avatar>
                          )}
                        </ListItemAvatar>
                        <Link to={`/user_profile/${item[by]._id}`}>
                          <ListItemText
                            primary={item.followingUser_CustomName}
                            secondary={item[by].username}
                          />
                        </Link>
                      </ListItem>
                    );
                  })
                ) : (
                  <div>
                    No {type} {actionName}s
                  </div>
                )}
              </List>
            </InfiniteScroll>
          </>
        ) : (
          <Loader />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          style={{ textTransform: "none" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect()(UserList);
