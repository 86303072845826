const profileVideoReducer = (
  state = {
    fetching: false,
    videos: [],
    dataSetChanged: false,
    uploading: false
  },
  action
) => {
  switch (action.type) {
    case "VIDEOS_FETCHING":
      return {
        ...state,
        fetching: true
      };

    case "VIDEOS_FETCH_SUCCESS":
      return {
        ...state,
        fetching: false,
        videos: action.payload,
        dataSetChanged: false
      };

    case "VIDEOS_FETCH_FAILED":
      return {
        ...state,
        fetching: false,
        dataSetChanged: false
      };

    case "VIDEO_UPLOADING":
      return {
        ...state,
        uploading: true
      };

    case "VIDEO_UPLOAD_SUCCESS":
      return {
        ...state,
        dataSetChanged: true,
        uploading: false
      };

    case "VIDEO_UPLOAD_FAILED":
      return {
        ...state,
        uploading: false
      };

    default:
      return state;
  }
};

export default profileVideoReducer;
