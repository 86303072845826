import React, { useState, useEffect } from "react";
import withRouter from "react-router-dom/es/withRouter";
import { connect } from "react-redux";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  DialogActions,
  DialogContentText,
  Button,
  Switch,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getUserSettings } from "../../../actions/userDetailsAction";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "100%",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
    width: "inherit",
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
    justifyContent: "space-between",
    width: "95%",
  },
}));

const Settings = ({
  open,
  handleClose,
  handleSave,
  allowOpenComments,
  allowHiddenComments,
  changeWhoCanOpenComment,
  changeWhoCanHiddenComment,
  userSettings,
  dispatch,
}) => {
  const classes = useStyles();

  const [allowOpen, setAllowOpen] = useState(true);
  const [allowHidden, setAllowHidden] = useState(true);
  const [whoOpen, setWhoOpen] = useState("AnyOne");
  const [whoHidden, setWhoHidden] = useState("AnyOne");
  // useEffect(() => {
  //   dispatch(getUserSettings());
  // }, []);
  useEffect(() => {
    changeWhoCanOpenComment(whoOpen);
  }, [whoOpen]);

  useEffect(() => {
    changeWhoCanHiddenComment(whoHidden);
  }, [whoHidden]);

  useEffect(() => {
    !allowOpen ? setWhoOpen("NoOne") : setWhoOpen("AnyOne");
  }, [allowOpen]);
  useEffect(() => {
    !allowHidden ? setWhoHidden("NoOne") : setWhoHidden("AnyOne");
  }, [allowHidden]);

  return (
    <React.Fragment>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={() => handleClose()}>
        <DialogTitle id="comment-settings">
          Choose Comments Privacy Settings
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Add permissions.</DialogContentText>
          <form className={classes.form} noValidate>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Switch
                  checked={allowOpen}
                  size="large"
                  onChange={(e) => {
                    setAllowOpen(!allowOpen);
                    allowOpenComments();
                  }}
                />
              }
              labelPlacement="start"
              label="Open Comments"
            />

            {allowOpen && (
              <FormControlLabel
                className={classes.formControlLabel}
                control={
                  <Select
                    value={whoOpen}
                    autoWidth
                    onChange={(e) => setWhoOpen(e.target.value)}
                    inputProps={{
                      name: "who-open",
                      id: "who-open",
                    }}
                  >
                    <MenuItem value={"NoOne"}>No One</MenuItem>
                    <MenuItem value={"AnyOne"}>Any One</MenuItem>
                    <MenuItem value={"OpenFriends"}>Open Friends</MenuItem>
                    {[
                                {
                                  value: "HiddenFriends",
                                  label: "Hidden Friends",
                                },
                                {
                                  label: "Hidden, Open Friends",
                                  value: "HiddenOpenFriends",
                                },
                              ].map((opt) => (
                                <MenuItem
                                  hidden={
                                    !(userSettings &&
                                    userSettings.showHiddenFriendsOption)
                                  }
                                  key={opt.value}
                                  value={opt.value}
                                >
                                  {opt.label}
                                </MenuItem>
                              ))}
                  </Select>
                }
                labelPlacement="start"
                label="Who Can Open Comment"
              />
            )}
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Switch
                  checked={allowHidden}
                  size="large"
                  onChange={(e) => {
                    setAllowHidden(!allowHidden);
                    allowHiddenComments();
                  }}
                />
              }
              labelPlacement="start"
              label="Hidden Comments"
            />
            {allowHidden && (
              <FormControlLabel
                className={classes.formControlLabel}
                control={
                  <Select
                    value={whoHidden}
                    autoWidth
                    onChange={(e) => setWhoHidden(e.target.value)}
                    inputProps={{
                      name: "who-hidden",
                      id: "who-hidden",
                    }}
                  >
                    <MenuItem value={"NoOne"}>No One</MenuItem>
                    <MenuItem value={"AnyOne"}>Any One</MenuItem>
                    <MenuItem value={"OpenFriends"}>Open Friends</MenuItem>
                    <MenuItem value={"HiddenFriends"}>Hidden Friends</MenuItem>
                    <MenuItem value={"HiddenOpenFriends"}>
                      Hidden, Open Friends
                    </MenuItem>
                  </Select>
                }
                labelPlacement="start"
                label="Who Can Hidden Comment"
              />
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose()}
            color="secondary"
            style={{ textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleSave()}
            color="primary"
            style={{ textTransform: "none" }}
          >
            Proceed to Upload Gallery
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userSettings: state.userDetailsReducer.userSettings,
  };
};

export default connect(mapStateToProps)(withRouter(Settings));
