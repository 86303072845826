import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import withRouter from "react-router-dom/es/withRouter";
import bindActionCreators from "redux/es/bindActionCreators";
import connect from "react-redux/es/connect/connect";

import routes from "../Routes/page-routes.jsx";

import ProfileContainer from "./ProfileContainer";
import SubscriptionComponent from "./SubscriptionComponent";
import BuyShareComponent from "./BuyShareComponent/Bid";
import RegisterFreeAdComponent from "./RegisterFreeAdComponent/index.js";
import SettingsComponent from "./SettingsComponents/SettingsComponent";
import AdSpaceContainer from "./AdSpaceComponent/AdSpaceContainer";
import Bid from "./Bid/Bid";
import BidCalendar from "./Bid/CalendarComponent/Calendar";

import ChatContainer from "../containers/ChatContainer";
import EditProfileForm from "./EditProfile/EditProfileForm";
import CalendarComponent from "./CalendarComponent";
import UserProfile from "./UserProfile";
import UserGalleryComponent from "./UserGalleryComponent";
import UserLists from "./UserLists";
import ActedOnEntity from "./ActedOnEntity";
import GalleryUpload from "./UserGalleryComponent/GalleryUpload";
import VideosComponent from "./VideosComponent";
// import PointsComponent from "./PointsComponent";
// import PointPurchases from "./PointsComponent/PointPurchases";
// import GiftTransactions from "./PointsComponent/GiftTransactions";
import PostsComponent from "./PostsComponent";
import PostComponent from "./PostsComponent/PostFeed/Post";
import EditPostComponent from "./PostsComponent/EditPostForm";

//material-ui imports
import { Grid } from "@material-ui/core";
import { getStringParam } from "../helpers/paramVerifier";
import { constants } from "../helpers/constants";
import DonationComponent from "./ProfileDonation.js";

const MainContent = ({ userDetail, accessPages }) => {
  const pageLoadRedirect = () => {
    let userType = userDetail && getStringParam(userDetail.userType);
    if (userType == constants.USER_TYPE_EMPLOYER) {
      // return <Redirect to={"/all-positions"} />;
      return <Redirect to={"/posts"} />;
    } else if (userType == constants.USER_TYPE_JOBSEEKER) {
      // return <Redirect to={"/positions"} />;
      return <Redirect to={"/posts"} />;
    } else {
      return <Redirect to={"/posts"} />;
    }
  };

  if (!userDetail || !accessPages) {
    return null;
  }
  return (
    <Grid container>
      <Switch>
        <Route exact path={"/"} render={() => pageLoadRedirect()} />

        <Route
          exact
          path={"/profile-me/"}
          render={(props) => <ProfileContainer {...props} />}
        />
        <Route
          exact
          path={"/profile-me/public"}
          render={(props) => <ProfileContainer {...props} />}
        />
        <Route
          exact
          path={"/profile-me/department"}
          render={(props) => <ProfileContainer {...props} />}
        />
        <Route
          exact
          path={"/profile-me/private"}
          render={(props) => <ProfileContainer {...props} />}
        />
        {/* <Route
          exact
          path={"/profile-me/my-gallery/:type"}
          render={(props) => <ProfileContainer {...props} />}
        /> */}
        <Route
          exact
          path={"/profile-me/my-gallery/upload/:type"}
          render={(props) => <GalleryUpload {...props} />}
        />
        <Route
          exact
          path={`/profile-me/my-calendar`}
          render={(props) => <ProfileContainer {...props} />}
        />
        <Route
          exact
          path={`/profile-me/:action`}
          render={(props) => <ActedOnEntity {...props} />}
        />
        <Route
          exact
          path={`/profile-me/:actionName/:type`}
          render={(props) => <UserLists {...props} />}
        />
        <Route exact path={"/buyShare"} component={BuyShareComponent} />
        <Route exact path={"/freeAds"} component={RegisterFreeAdComponent} />
        <Route exact path={"/subscription"} component={SubscriptionComponent} />
        <Route exact path={"/donation"} component={DonationComponent} />
        <Route exact path={"/settings"} component={SettingsComponent} />
        {accessPages.length > 0 &&
          routes
            .filter((route) => {
              var thisPage = accessPages.filter((page) => {
                if (route.path === page.pageName) {
                  return page;
                }
                return false;
              });
              if (thisPage && thisPage[0]) {
                return true;
              }
              return false;
            })
            .map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  render={route.render}
                />
              );
            })}
        <Route
          exact
          path={"/editprofile"}
          render={(props) => <EditProfileForm {...props} />}
        />
        <Route exact path={"/messages"} component={ChatContainer} />
        <Route path={"/messages/:user_id"} component={ChatContainer} />
        <Route exact path={"/buyAdSpace"} component={AdSpaceContainer} />
        <Route exact path={"/bid-calendar"} component={BidCalendar} />
        <Route exact path={"/bid/:id"} component={Bid} />

        <Route exact path={"/posts"} component={PostsComponent} />
        <Route exact path={"/post/:post_id"} component={PostComponent} />
        <Route
          exact
          path={"/posts/edit/:post_id"}
          component={EditPostComponent}
        />
        <Route exact path={"/videos"} component={VideosComponent} />
        {/* <Route exact path={"/points"} component={PointsComponent} /> */}
        {/* <Route exact path={"/gifts"} component={GiftTransactions} /> */}
        {/* <Route exact path={"/pointPurchases"} component={PointPurchases} /> */}
        <Route
          exact
          path={"/user_profile/:user_id/public/:galleryId"}
          render={(props) => <UserProfile {...props} />}
        />
        <Route
          exact
          path={"/user_profile/:user_id/public"}
          render={(props) => <UserProfile {...props} />}
        />
        <Route
          exact
          path={"/user_profile/:user_id/department/:galleryId"}
          render={(props) => <UserProfile {...props} />}
        />
        <Route
          exact
          path={"/user_profile/:user_id/department"}
          render={(props) => <UserProfile {...props} />}
        />
        <Route
          exact
          path={`/user_profile/:user_id/:action`}
          render={(props) => <ActedOnEntity {...props} />}
        />
        <Route
          exact
          path={`/user_profile/:user_id/:actionName/:type`}
          render={(props) => <UserLists {...props} />}
        />

        <Route
          exact
          path={`/user_profile/my-calendar/:user_id`}
          render={(props) => <CalendarComponent {...props} />}
        />
        <Route
          path={"/user_profile/:user_id"}
          render={(props) => <UserProfile {...props} />}
        />
        <Route
          exact
          path={"/profile/:user_name"}
          render={(props) => <UserProfile {...props} />}
        />
        <Route render={() => <h1>Page not found</h1>} />
      </Switch>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetail: state.userDetailsReducer.userDetail,
    accessPages: state.userDetailsReducer.accessPages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainContent)
);

/*********** */

//  <Route path={'/'} component = {PostAdTableComponent}>
//  <IndexRoute component = {ProfileContainer} />
//  <Route path={'profile'} component={ProfileContainer}/>
//  <Route path = {"positions"} component = {PostAdTableComponent}>
//      <Route path={'/:ad_id?'} component={PostPosTable}/>
//  </Route>
// </Route>this.props.userDetail.userType
