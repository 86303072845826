import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function FormDialog({ open, handleClose, handleCreate }) {
  const [name, setName] = useState("");

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create Search</DialogTitle>

      <DialogContent>
        <DialogContentText>Save this Search Query.</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Custom Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          style={{ textTransform: "none" }}
        >
          Cancel
        </Button>

        <Button
          onClick={() => handleCreate(name)}
          color="primary"
          style={{ textTransform: "none" }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
