import React from "react";
import getURL from "../helpers/apis";
import { Link, withRouter, Redirect } from "react-router-dom";
import { TextInput } from "./InputFields";
import { getPosByUser, getEmpAllPos } from "../actions/getPosActions";
import bindActionCreators from "redux/es/bindActionCreators";
import connect from "react-redux/es/connect/connect";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Avatar from "@material-ui/core/Avatar";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Cancel";
import ViewIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  isValidParam,
  getStringParam,
  getObjectParam,
  getArrayParam,
  getIntParam,
  getBooleanParam,
} from "../helpers/paramVerifier";
import { formatDate } from "../helpers/globalFunctions";
import ReactPaginate from "react-paginate";
// import ImageDialog from "./UserProfile/ImageDialog";
import { constants } from "../helpers/constants";
import { openAvatarModal } from "../actions/appActions";
import { cancelPosApplication } from "../actions/getPosActions";

const dataTableStyles = (theme) => ({ ...styles });

class DataTable extends React.Component {
  constructor(props) {
    super(props);
    this.fields = [];
    this.state = {
      avatarModal: false,
      modalImage: "",
    };
  }

  handleCancelApplication(id) {
    this.props.cancelPosApplication(id, this.props.match.path);
  }

  getTableHeader() {
    let element = null;
    try {
      let fields = getArrayParam(this.props.fields);
      fields = fields.filter((f) => {
        return !f.is_hidden;
      });
      let arrTheads = fields.map((m, i) => {
        let { name, label, width, ...other } = m;

        let style = { paddingLeft: 10, paddingRight: 10 };
        if (i > 0) {
          style.borderLeft = "1px solid #e0e0e0";
        }
        return (
          <TableCell size="small" key={name + "-" + i} style={{...style, }}>
            <Typography
              noWrap
              variant="caption"
              style={{ width: width, fontSize: 14, fontWeight: 600, ...other }}
              title={label}
            >
              {label}
            </Typography>
          </TableCell>
        );
      });
      element =
        arrTheads.length > 0 ? (
          <TableHead>
            <TableRow style={{ height: 30 }}>{arrTheads}</TableRow>
          </TableHead>
        ) : null;
    } catch (error) {}
    return element;
  }

  getTableBody() {
    let element = null;
    try {
      let rows = new Array();
      let fields = getArrayParam(this.props.fields);
      let values = getArrayParam(this.props.values);

      fields = fields.filter((f) => {
        return !f.is_hidden;
      });


      values.forEach((obj, index) => {
        let rowCells = fields.map((field, i) => {
          let { name, label, width, ...other } = field;
          let value = this.getFieldValue(field, obj);

          let valProps = new Object();
          valProps.noWrap = true;
          valProps.style = { width: width, fontSize: 14, ...other };
          valProps.variant = "caption";
            valProps.title = value;
          // if (!isValidParam(field.link)) {
          //   valProps.title = value;
          // } else
          valProps.children = value;
          if (field.name !== "profilepic") {
          
            let url = "";
            if (this.props.match.path === "/positions") {
              url = url + "/all-positions/" + obj.employerInfo._id;
              if(label.toLowerCase() == 'production house'){
                url= `${field.link.baseUrl}${obj.employerInfo._id}`
              }
            } else {
              url =
                url + "/applied-jobs/" + obj.postedById + "/view/" + obj.positionId;
            }
            return (
              <TableCell
                size="small"
                key={name + "-" + i}
                onClick={() => this.props.history.push(url)}
                style={{ paddingLeft: 10, paddingRight: 10 , textAlign:"left"}}
              >
                <Typography {...valProps} />
              </TableCell>
            );
          } else {

            return (
              <TableCell
                size="small"
                key={name + "-" + i}
                style={{ paddingLeft: 10, paddingRight: 10, width: "25px" }}
              >
                <Avatar
                  style={{ float: "right" }}
                  src={ process.env.REACT_APP_BUCKET_NAME + value}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.openAvatarModal(process.env.REACT_APP_BUCKET_NAME + value);
                  }}
                  // onClick={(e) => {
                  //   this.setState({
                  //     avatarModal: true,
                  //     modalImage:  value,
                  //   });
                  // }}
                />
              </TableCell>
            );
          }
        });
        let style = { height: 30 };
        if (index % 2 != 0) {
          style.backgroundColor = "#00000014";
        }
        rows.push(
          <TableRow style={style} >
            {rowCells}
          </TableRow>
        );
      });

      element = rows.length > 0 ? <TableBody>{rows}</TableBody> : null;
    } catch (error) {}
    return element;
  }

  getFieldValue(field, rowObj) {
    let element = null;
    let value = null;
    try {
      field = getObjectParam(field);
      rowObj = getObjectParam(rowObj);
      if (this.props.match.path === "/positions") {
      
       if (
          field.name === "country" &&
          rowObj.employerInfo.country === "India"
        ) {
          value =
            getStringParam(rowObj.employerInfo.industry) +
            ", " +
            getStringParam(rowObj.employerInfo.country);
        } else value = getStringParam(rowObj.employerInfo[field.name]);
        if (field.type == "date") {
          value = formatDate(value);
        }
        if (isValidParam(field.link)) {
          let { name, baseUrl, ...others } = field.link;
          let url = baseUrl + rowObj.employerInfo[name];
          value = (
            <Link
              to={url}
              {...others}
              title={getStringParam(rowObj.employerInfo[field.name])}
            >
              <Typography style={{ color: constants.PRIMARY_COLOR }}>
                {getStringParam(field.name == "noOfPositions" ? rowObj[field.name]:  rowObj.employerInfo[field.name])}
              </Typography>
            </Link>
          );
        }
      } else {
        value = getStringParam(rowObj[field.name]);
        if (field.name === "country" && rowObj.country === "India") {
          value =
            getStringParam(rowObj.industry) +
            ", " +
            getStringParam(rowObj.country);
        }
        if (field.type == "date") {
          value = formatDate(value);
        }
        if (field.name == "bnr" || field.name == "role") {
          value = (
            <Typography style={{ color: constants.PRIMARY_COLOR }}>
              {value}
            </Typography>
          );
        }
        if (field.name === "positionStatus") {
          value = (
            <Typography
              style={{
                cursor: "pointer",
                color:
                  rowObj[field.name].toLowerCase() === "open"
                    ? "#337ab7"
                    : "red",
              }}
            >
              {rowObj[field.name]}
            </Typography>
          );
        }
        if (isValidParam(field.link)) {
          let { name, baseUrl, ...others } = field.link;
          let url = baseUrl + rowObj[name];
        
          value = (
            <Link
              to={url}
              {...others}
              title={getStringParam(rowObj[field.name])}
            >
              <Typography style={{ color: constants.PRIMARY_COLOR }}>
                {getStringParam(rowObj[field.name])}
              </Typography>
            </Link>
          );



        }
        if (field.name == "cancel") {
          if (rowObj.positionStatus.toLowerCase() === "closed") {
            value = "";
          } else if (rowObj.positionStatus.toLowerCase() === "cancelled") {
            value = (
              <DeleteIcon
                onClick={(e) => {
                  e.stopPropagation();
                  this.handleDeletePositions(rowObj._id);
                }}
              />
            );
          } else {
            value = (
              <CloseIcon
                style={{ color: "#a94442" }}
                onClick={(e) => {
                  e.stopPropagation();
                  this.handleCancelApplication(rowObj.positionId);
                }}
              />
            );
          }
        }
      }

      if (isValidParam(value)) {
        element = value;
      }
    } catch (error) {}
    return element;
  }

  getPaginationProps() {
    let props = new Object();
    try {
      let { classes, paging } = this.props;
      paging = getObjectParam(paging);

      props.pageClassName = classes.pageClassName;
      props.breakClassName = classes.breakClassName;
      props.activeClassName = classes.activeClassName;
      props.containerClassName = classes.containerClassName;
      props.previousClassName = classes.previousClassName;
      props.nextClassName = classes.nextClassName;
      props.pageLinkClassName = classes.pageLinkClassName;
      props.previousLinkClassName = classes.previousLinkClassName;
      props.nextLinkClassName = classes.nextLinkClassName;

      props.initialPage = 0;
      props.pageCount = 5;
      props.previousLabel = "<<";
      props.nextLabel = ">>";

      props = { ...props, ...paging };
    } catch (error) {}
    return props;
  }

  render() {
    let { classes, tableStyle, paging } = this.props;
    let header = this.getTableHeader();
    let body = this.getTableBody();
    return (
      <Grid
     style={{
      height: "95vh",
     }}
      >
        {isValidParam(paging) && (
          <Grid className={classes.pagingContainer}>
            <ReactPaginate {...this.getPaginationProps()} />
          </Grid>
        )}
        <Grid
          container
          style={{
            overflowX: "auto",
            overflowY: "scroll",
          }}
        >
          <Table style={tableStyle}>
            {header}
            {body}
          </Table>
          {/* {this.state.avatarModal && (
            <ImageDialog
              src={this.state.modalImage}
              open={this.state.avatarModal}
              handleClose={(e) =>
                this.setState({ avatarModal: false, modalImage: "" })
              }
            />
          )} */}
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      cancelPosApplication,
      openAvatarModal,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(dataTableStyles)(withRouter(DataTable)));

const fields = [
  { name: "bnr", label: "BNR", width: 20, type: "date", link: null },
  {
    name: "prfirstname",
    label: "PRFirstName",
    width: 20,
    type: "text",
    link: null,
  },
  { name: "createdAt", label: "SDate", width: 20, type: "text", link: null },
  { name: "", label: "Status", width: 20, type: "text", link: null },
  { name: "country", label: "Country", width: 20, type: "text", link: null },
  { name: "region", label: "Region", width: 20, type: "text", link: null },
  { name: "", label: "Description", width: 20, type: "text", link: null },
];

let styles = {
  root: {},
  tableCellChild: {
    fontSize: 14,
    fontWeight: 600,
  },
  addButton: {
    width: 30,
    height: 30,
  },
  pagingContainer: {
    float: "right",
    //marginRight: 10
  },
  pageClassName: {
    display: "inline-block",
    paddingLeft: 5,
    paddingRight: 5,
  },
  activeClassName: {
    backgroundColor: " #25242445",
    color: "#ffffff",
  },
  containerClassName: {
    border: "1px solid #25242445",
    lineHeight: 1.5,
    paddingLeft: 10,
    paddingRight: 10,
    width: "fit-content",
  },
  previousClassName: {
    display: "inline-block",
  },
  nextClassName: {
    display: "inline-block",
  },
  breakClassName: {
    display: "inline-block",
    paddingLeft: 5,
    paddingRight: 5,
  },
  pageLinkClassName: {
    color: "#757575",
  },
  previousLinkClassName: {
    color: "#757575",
  },
  nextLinkClassName: {
    color: "#757575",
  },
};
