import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Typography,
  FormLabel,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Loader } from "../UtilityComponents";
import { connect } from "react-redux";
import withRouter from "react-router-dom/es/withRouter";
import {
  getUpgradeDetails,
  getPurchaseDetails,
} from "../../actions/userDetailsAction";
import { constants } from "../../helpers/constants";

const PurchaseDetailsForDonation = ({
  open,
  handleClose,
  handleMakePayment,
  amount,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      className="inherit-bg-color"
    >
      <DialogTitle id="scroll-dialog-title">Donate</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={0}>
          <Typography>
            Are you sure you want to donate <strong>Rs.{amount}</strong>!!
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClose}
          color="primary"
          style={{ textTransform: "none" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleMakePayment}
          color="primary"
          style={{ textTransform: "none" }}
        >
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PurchaseDetailsForDonation;
