import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import FaceIcon from "@material-ui/icons/Face";
import Chip from "@material-ui/core/Chip";
import { useDispatch, useSelector } from "react-redux";
import { addUserToExceptionList } from "../../actions/applicantsDetailsActions";
import getURL from "../../helpers/apis";
import { openAvatarModal } from "../../actions/appActions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const Users = ({ usersList, setting, close, handleRefresh }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState([]);
  const rootUrl = getURL("UPLOADS");

  const handleAddToList = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAddUserToExceptionList = () => {
    const body = {
      allowedUser: checked,
      listType: setting === "whoCanChat" ? "chat" : "privateShare",
    };
    addUserToExceptionList(body).then((res) => {
      if (!res.error) {
        setChecked([]);
        handleRefresh();
      }
    });
  };

  return (
    <>
      <List
        dense
        className={classes.root}
        style={{
          height: "350px",
          overflow: "auto",
        }}
      >
        {usersList.length > 0 ? (
          usersList.map((user) => {
            const labelId = `checkbox-list-secondary-label-${user._id}`;
            return (
              <ListItem key={user._id} button>
                <ListItemAvatar>
                  <Avatar
                    onClick={(e) => dispatch(openAvatarModal(process.env.REACT_APP_BUCKET_NAME + user.profilepic))}
                    alt={user.username}
                    src={process.env.REACT_APP_BUCKET_NAME + user.profilepic}
                  />
                </ListItemAvatar>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <ListItemText
                    id={labelId}
                    primary={user.user_follow_customname}
                    secondary={
                      user.user_follow_customname.startsWith("@")
                        ? user.username
                        : user.username.replace("@", "")
                    }
                  />
                </div>
                <ListItemSecondaryAction>
                  {/* <Button
                    edge="end"
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={checked.includes(user._id)}
                    onClick={() => handleAddUserToExceptionList(user._id)}
                    style={{ textTransform: "none" }}
                  >
                    {checked.includes(user._id) ? "Added" : "Add"}
                  </Button> */}
                  <Checkbox
                    edge="end"
                    onChange={() => handleAddToList(user._id)}
                    checked={checked.indexOf(user._id) !== -1}
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })
        ) : (
          <DialogContentText>Search for a user.</DialogContentText>
        )}
      </List>
      <div style={{ margin: "32px 0px" }}>
        {" "}
        {checked.length > 0 &&
          checked.map((user) => {
            const userDetails = usersList.find((usr) => usr._id == user);
            return (
              <Chip
                style={{ marginTop: "4px", marginLeft: "4px" }}
                icon={<FaceIcon />}
                label={userDetails.username}
                variant="outlined"
              />
            );
          })}
      </div>
      <DialogActions>
        <Button
          onClick={() => close()}
          color="secondary"
          variant="contained"
          margin="normal"
          style={{ textTransform: "none" }}
        >
          Done
        </Button>
        <Button
          onClick={() => handleAddUserToExceptionList()}
          color="primary"
          variant="contained"
          margin="normal"
          style={{ textTransform: "none" }}
        >
          Add
        </Button>
      </DialogActions>
    </>
  );
};

export default Users;
