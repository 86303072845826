import { sendHTTPRequest } from "../helpers/requestEngine";
import getUrl from "../helpers/apis";
import { getStringParam } from "../helpers/paramVerifier";
import request1 from "../helpers/request";
import getURL from "../helpers/apis";
import request from "../helpers/requestEngine";
import { openDialog, closeDialog } from "./appActions";

export const postShareBidAfterPayment = (formData, history) => {
  return (dispatch) => {
    if (
      formData.type === "" ||
      formData.type === "Select Type" ||
      formData.bidamt === ""
    ) {
      dispatch(openDialog("Enter all bid details"));
    } else {
      request("post", "POST_SHARE_BID", true, formData).then(
        (response) => {
          dispatch(postShareBidSuccess(response.data.data));
          // history.goBack();
          dispatch(openDialog("Bid Posted!"));
          dispatch(getShareBids());
        },
        (err) => {
          dispatch(openDialog("Something went wrong. Please try again."));

          dispatch(postShareBidFailed(err));
          return err;
        }
      );
    }
  };
};

function postShareBidSuccess() {
  return {
    type: "POST_SHARE_BID_SUCCESS",
  };
}

function postShareBidFailed(err) {
  return {
    type: "POST_SHARE_BID_FAILED",
  };
}

export const getShareBids = (params) => {
  return (dispatch) => {
    dispatch(fetchingShareBids());

    return dispatch(fetchShareBids(params));
  };
};

function fetchingShareBids() {
  return {
    type: "GET_SHARE_BIDS",
  };
}

function fetchShareBids(params) {
  return (dispatch) => {
    request("get", "GET_SHARE_BIDS", true, null, params).then(
      (response) => {
        dispatch(getShareBidsSuccess(response.data.data));
      },
      (err) => {
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(getShareBidsFailed(err));
      }
    );
  };
}

function getShareBidsSuccess(data) {
  return {
    type: "GET_SHARE_BIDS_SUCCESS",
    payload: data,
  };
}

function getShareBidsFailed(err) {
  return {
    type: "GET_SHARE_BIDS_FAILED",
  };
}

//  GET PURCHASE INFORMATION

export const getShareBidTransactions = (formData) => {
  return (dispatch) => {
    dispatch(fetchingShareBidTransactions());

    return dispatch(fetchShareBidTransactions(formData));
  };
};

function fetchingShareBidTransactions() {
  return {
    type: "GET_SHARE_BID_TRANSACTIONS",
  };
}

function fetchShareBidTransactions(formData) {
  return (dispatch) => {
    request1(
      "get",
      `${getURL("GET_SHARE_BID_TRANSACTIONS")}`,
      true,
      {},
      formData
    ).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong.");
          dispatch(
            openDialog(
              response.data.data || "Something went wrong. Please try again."
            )
          );

          return dispatch(fetchingShareBidTransactionsFailed());
        }
        dispatch(fetchingShareBidTransactionsSuccess(response.data.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(fetchingShareBidTransactionsFailed(err));
      }
    );
  };
}

function fetchingShareBidTransactionsSuccess(data) {
  return {
    type: "GET_SHARE_BID_TRANSACTIONS_SUCCESS",
    payload: data,
  };
}

function fetchingShareBidTransactionsFailed(err) {
  return {
    type: "GET_SHARE_BID_TRANSACTIONS_FAILED",
  };
}

//  GET PURCHASE INFORMATION

export const getPurchaseDetails = (formData) => {
  return (dispatch) => {
    dispatch(fetchingPurchaseDetails());

    return dispatch(fetchPurchaseDetails(formData));
  };
};

function fetchingPurchaseDetails() {
  return {
    type: "GET_SHARE_BID_PURCHASE_INFORMATION",
  };
}

function fetchPurchaseDetails(formData) {
  return (dispatch) => {
    request1(
      "get",
      `${getURL("GET_SHARE_BID_PURCHASE_INFO")}`,
      true,
      {},
      formData
    ).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong.");
          dispatch(
            openDialog(
              response.data.data || "Something went wrong. Please try again."
            )
          );

          return dispatch(fetchingPurchaseDetailsFailed());
        }
        dispatch(fetchingPurchaseDetailsSuccess(response.data.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(fetchingPurchaseDetailsFailed(err));
      }
    );
  };
}

function fetchingPurchaseDetailsSuccess(data) {
  return {
    type: "GET_SHARE_BID_PURCHASE_INFORMATION_SUCCESS",
    payload: data,
  };
}

function fetchingPurchaseDetailsFailed(err) {
  return {
    type: "GET_SHARE_BID_PURCHASE_INFORMATION_FAILED",
  };
}

export const getRazorPaySuccessCapture = (
  body,
  handleCallbackRazorPayCapture
) => {
  return (dispatch) => {
    dispatch(fetchingRazorPaySucessCapture());

    return dispatch(
      fetchRazorPaySuccessCapture(body, handleCallbackRazorPayCapture)
    );
  };
};

function fetchingRazorPaySucessCapture() {
  return {
    type: "USER_SHARE_BID_RAZORPAY_PAYMENT_CAPTURE",
  };
}

function fetchRazorPaySuccessCapture(body, handleCallbackRazorPayCapture) {
  return (dispatch) => {
    request1(
      "post",
      `${getURL("USER_SHARE_BID_RAZORPAY_PAYMENT_CAPTURE_SUCCESS")}`,
      true,
      body,
      {}
    ).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong.");
          dispatch(
            openDialog(
              response.data.data || "Something went wrong. Please try again."
            )
          );

          return dispatch(fetchingRazorPayCaptureFailed());
        }
        dispatch(fetchingRazorPayCaptureSuccess(response.data.data));
        handleCallbackRazorPayCapture();
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(fetchingRazorPayCaptureFailed(err));
      }
    );
  };
}

function fetchingRazorPayCaptureSuccess(data) {
  return {
    type: "USER_SHARE_BID_RAZORPAY_PAYMENT_CAPTURE_SUCCESS",
    payload: data,
  };
}

function fetchingRazorPayCaptureFailed(err) {
  return {
    type: "USER_SHARE_BID_RAZORPAY_PAYMENT_CAPTURE_FAILED",
  };
}

// USER ADD SUBSCRIPTION PLAN

export const postShareBid = (formData, history, handleCallRazorPayMethod) => {
  return (dispatch) => {
    dispatch(userPostingShareBid());

    return dispatch(
      userPostShareBid(formData, history, handleCallRazorPayMethod)
    );
  };
};

function userPostingShareBid() {
  return {
    type: "USER_POST_SHARE_BID",
  };
}

function userPostShareBid(formData, history, handleCallRazorPayMethod) {
  return (dispatch) => {
    if (
      formData.type === "" ||
      formData.type === "Select Type" ||
      formData.bidamt === ""
    ) {
      dispatch(openDialog("Enter all bid details"));
    } else {
      request1("post", getURL("USER_POST_SHARE_BID"), true, formData).then(
        (response) => {
          if (response.data.error) {
            // snackbar.show("Something went wrong.");
            dispatch(
              openDialog(
                response.data.data || "Something went wrong. Please try again."
              )
            );

            return dispatch(userPostShareBidFailed());
          }
          dispatch(userPostShareBidSuccess(response.data.data));
          handleCallRazorPayMethod(response.data.data);
        },
        (err) => {
          // snackbar.show("Something went wrong.");
          dispatch(openDialog("Something went wrong. Please try again."));

          dispatch(userPostShareBidFailed(err));
        }
      );
    }
  };
}

function userPostShareBidSuccess(data) {
  return {
    type: "USER_POST_SHARE_BID_SUCCESS",
    payload: data,
  };
}

function userPostShareBidFailed(err) {
  return {
    type: "USER_POST_SHARE_BID_FAILED",
  };
}
