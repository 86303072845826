import React from "react";
import PropTypes from "prop-types";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircle from "@material-ui/icons/AccountCircle";
import HomeIcon from "@material-ui/icons/Home";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import { Grid, Typography, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Drawer from "@material-ui/core/Drawer";
import { menuStyles } from "../helpers/materialStyles";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import { getObjectParam, getStringParam } from "../helpers/paramVerifier";
import { constants } from "../helpers/constants";
import { Redirect } from "react-router-dom";
import SearchField from "./SearchField";
import MenuItems from './MenuItems'
import connect from "react-redux/es/connect/connect";

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirect: false,
      redirectPath: null,
      location: this.props.history.location.pathname,
      dialogBox: true,
      show:false,
    };
  }

  handleClose() {
    this.setState({dialogBox: !this.state.dialogBox});
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.location !== this.props.history.location.pathname) {
      this.setState({ location: this.props.history.location.pathname });
    }
  }

  redirect(path) {
    this.setState({ isRedirect: true, redirectPath: path });
  }

  // getMenuItems() {
  //   const { classes } = this.props;
  //   const { location } = this.state;

  //   let items = new Array();
  //   try {
  //     let userDetail = getObjectParam(this.props.userDetail);
  //     let userType = getStringParam(userDetail.userType);
  //     if (userType == constants.USER_TYPE_EMPLOYER) {
  //       items.push(
  //         <Button
  //           className={
  //             location === "/videos"
  //               ? [classes.button, classes.selectButton]
  //               : [classes.button]
  //           }
  //           onClick={() => {
  //             this.props.history.push("/videos");
  //           }}
  //         >
  //           Videos
  //         </Button>
  //       );
  //       items.push(
  //         <Button
  //           className={
  //             location === "/posts"
  //               ? [classes.button, classes.selectButton]
  //               : [classes.button]
  //           }
  //           onClick={() => {
  //             this.props.history.push("/posts");
  //           }}
  //         >
  //           Posts
  //         </Button>
  //       );
  //       items.push(
  //         <Button
  //           className={
  //             location === "/all-positions"
  //               ? [classes.button, classes.selectButton]
  //               : [classes.button]
  //           }
  //           onClick={() => {
  //             this.checkUserType("Employer")
  //           }}
  //         >
  //           Casting
  //         </Button>
  //       );
  //       items.push(
  //         <Button
  //           className={
  //             location === "/user-profiles"
  //               ? [classes.button, classes.selectButton]
  //               : [classes.button]
  //           }
  //           onClick={() => {
  //             this.props.history.push("/user-profiles");
  //           }}
  //         >
  //           Profiles
  //         </Button>
  //       );
  //       // items.push(
  //       //   <Button
  //       //     className={
  //       //       location === "/bid-calendar"
  //       //         ? [classes.button, classes.selectButton]
  //       //         : [classes.button]
  //       //     }
  //       //     onClick={() => {
  //       //       this.props.history.push("/bid-calendar");
  //       //     }}
  //       //   >
  //       //     Bid
  //       //   </Button>
  //       // );
  //     } else if (userType == constants.USER_TYPE_JOBSEEKER) {
  //       items.push(
  //         <Button
  //           className={
  //             location === "/videos"
  //               ? [classes.button, classes.selectButton]
  //               : [classes.button]
  //           }
  //           onClick={() => {
  //             this.props.history.push("/videos");
  //           }}
  //         >
  //           Videos
  //         </Button>
  //       );
  //       items.push(
  //         <Button
  //           className={
  //             location === "/posts"
  //               ? [classes.button, classes.selectButton]
  //               : [classes.button]
  //           }
  //           onClick={() => {
  //             this.props.history.push("/posts");
  //           }}
  //         >
  //           Posts
  //         </Button>
  //       );
  //       items.push(
  //         <Button
  //           className={
  //             location === "/positions"
  //               ? [classes.button, classes.selectButton]
  //               : [classes.button]
  //           }
  //           onClick={() => {
  //             this.checkUserType("JobSeeker")
  //           }}
  //         >
  //           Casting
  //         </Button>
  //       );
  //       items.push(
  //         <Button
  //           className={
  //             location === "/applied-jobs"
  //               ? [classes.button, classes.selectButton]
  //               : [classes.button]
  //           }
  //           onClick={() => {
  //             this.props.history.push("/applied-jobs");
  //           }}
  //         >
  //           Applied
  //         </Button>
  //       );
  //       // items.push(
  //       //   <Button
  //       //     className={
  //       //       location === "/bid-calendar"
  //       //         ? [classes.button, classes.selectButton]
  //       //         : [classes.button]
  //       //     }
  //       //     onClick={() => {
  //       //       this.props.history.push("/bid-calendar");
  //       //     }}
  //       //   >
  //       //     Bid
  //       //   </Button>
  //       // );
  //     } else if (userType == constants.USER_TYPE_SOCIALMEDIAUSER) {
  //       items.push(
  //         <Button
  //           className={
  //             location === "/videos"
  //               ? [classes.button, classes.selectButton]
  //               : [classes.button]
  //           }
  //           onClick={() => {
  //             this.props.history.push("/videos");
  //           }}
  //         >
  //           Videos
  //         </Button>
  //       );
  //       items.push(
  //         <Button
  //           className={
  //             location === "/posts"
  //               ? [classes.button, classes.selectButton]
  //               : [classes.button]
  //           }
  //           onClick={() => {
  //             this.props.history.push("/posts");
  //           }}
  //         >
  //           Posts
  //         </Button>
  //       );
  //       // items.push(
  //       //   <Button
  //       //     className={
  //       //       location === "/bid-calendar"
  //       //         ? [classes.button, classes.selectButton]
  //       //         : [classes.button]
  //       //     }
  //       //     onClick={() => {
  //       //       this.props.history.push("/bid-calendar");
  //       //     }}
  //       //   >
  //       //     Bid
  //       //   </Button>
  //       // );
  //     } else {
  //       items.push(
  //         <Button
  //           className={
  //             location === "/videos"
  //               ? [classes.button, classes.selectButton]
  //               : [classes.button]
  //           }
  //           onClick={() => {
  //             this.props.history.push("/videos");
  //           }}
  //         >
  //           Videos
  //         </Button>
  //       );
  //       items.push(
  //         <Button
  //           className={
  //             location === "/posts"
  //               ? [classes.button, classes.selectButton]
  //               : [classes.button]
  //           }
  //           onClick={() => {
  //             this.props.history.push("/posts");
  //           }}
  //         >
  //           Posts
  //         </Button>
  //       );
  //       // items.push(
  //       //   <Button
  //       //     className={
  //       //       location === "/bid-calendar"
  //       //         ? [classes.button, classes.selectButton]
  //       //         : [classes.button]
  //       //     }
  //       //     onClick={() => {
  //       //       this.props.history.push("/bid-calendar");
  //       //     }}
  //       //   >
  //       //     Bid
  //       //   </Button>
  //       // );
  //     }
  //   } catch (error) {}
  //   return items;
  // }

  checkUserType = (tempUserType)=>{
    const {userType, bnr,mainrole, otherroles} = this.props.userDetail;
    console.log("@@@ JobSeeker:", userType)
    if(tempUserType === "Employer"){
        //if(userType == constants.USER_TYPE_EMPLOYER && !bnr  ) {
        if( !bnr  ) {
          this.setState({show:true})
        }else{
          this.props.history.push("/all-positions");
        }
    }
    else if(tempUserType === "JobSeeker"){
      //if(userType == constants.u && !bnr  ) {
      if( !mainrole || otherroles.length === 0 ) {
        this.setState({show:true})
      }else{
        this.props.history.push("/positions");
      }
    }
  }

  render() {
    const { classes } = this.props;
    if (this.state.isRedirect) {
      this.state.isRedirect = false;
      return <Redirect to={this.state.redirectPath} />;
    }
    return (
      // <Grid container style={{ height: "100%" }}>
      //   {this.getMenuItems()}
      //   <SearchField />
      // </Grid>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "100%",
          alignItems: "center",
          margin: "0px 8px",
        }}
      >
        <div>
          <MenuItems checkUserType={this.checkUserType} userDetail={this.props.userDetail} classes={classes} />
          </div>
         
                <Dialog
                open={this.state.show}
                maxWidth="sm"
                onClose={()=>{this.setState({show:false})}}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                className="inherit-bg-color"
              >
                <DialogContent dividers>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>

                      <Typography style={{ color: constants.PRIMARY_COLOR,padding:"20px" }}>
                        Please complete your profile details to access the casting features.
                        Go to user profile page and click on Edit and enter * marked fields"
                      </Typography>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={()=>{this.setState({show:false})}}
                    color="primary"
                    style={{ textTransform: "none" }}
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            
              
       
        <SearchField userId={this.props.userDetail._id} />
      </div>
    );
  }
}

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ userDetailsReducer }) => {
  const { userDetail } = userDetailsReducer;

  return {userDetail};
};

export default connect(mapStateToProps)(withStyles(menuStyles)(Menu));
