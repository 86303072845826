import { deleteToken, parseJWT } from "../helpers/globalFunctions";
import request from "../helpers/requestEngine";
import { sendHTTPRequest } from "../helpers/requestEngine";
// // import snackbar from "snackbar";
import { setToken } from "../helpers/globalFunctions";
import getUrl from "../helpers/apis";
import { getUserDetails as fetchUserDetails } from "./userDetailsAction";
import { disconnectSocket } from "./notificationsActions";

export const openDialog = (text, description) => {
  return (dispatch) =>
    dispatch({ type: "OPEN_DIALOG", payload: { text, description } });
};

export const closeDialog = () => {
  return (dispatch) => dispatch({ type: "CLOSE_DIALOG" });
};

export const openAvatarModal = (imageSrc) => {
  return (dispatch) =>
    dispatch({ type: "OPEN_AVATAR_MODAL", payload: { imageSrc } });
};

export const closeAvatarModal = () => {
  return (dispatch) => dispatch({ type: "CLOSE_AVATAR_MODAL" });
};

export const refillJWT = () => {
  const jwt = localStorage.getItem("jwt");
  const userId = localStorage.getItem("userId");

  if (jwt && userId) {
    return (dispatch) => dispatch(fetchUserDetails(jwt, userId));
  }

  return (dispatch) => dispatch(logout());
};

function saveUserToState(jwt, userDetail) {
  return {
    jwt,
    userDetail,
    type: "LOGIN_SUCCESS",
  };
}

export const onEditProfile = () => {
  return {
    type: "EDIT_PROFILE",
  };
};

export const onChangeProfile = (userDetails) => {
  let formData = new FormData(userDetails);
  if (!formData.get("profilePhoto").size) {
    formData.delete("profilePhoto");
  }
  return updateProfile(formData);
};

export function updateProfile(formData) {
  return (dispatch) => {
    return request("post", "UPDATE_PROFILE", true, formData).then(
      (response) => {
        if (!response.data.error) {
          // snackbar.show("Profile updated successfully!");
          if (response.data.userDetail.userType === "JobSeeker") {
            var desc = `Country Change Chances Left = ${response.data.userDetail.countryChangeTriesLeft}
              Industry Change Chances Left = ${response.data.userDetail.industryChangeTriesLeft}
              Otherroles Change Chances Left = ${response.data.userDetail.otherrolesChangeTriesLeft}
              Mainrole Change Chances Left = ${response.data.userDetail.mainroleChangeTriesLeft}`;
            // dispatch(openDialog("Profile updated successfully!", desc));
          } else if (response.data.userDetail.userType === "Employer") {
            var desc = `Country Change Chances Left = ${response.data.userDetail.countryChangeTriesLeft}
              Industry Change Chances Left = ${response.data.userDetail.industryChangeTriesLeft}`;
            // dispatch(openDialog("Profile updated successfully!", desc));
          } else {
            // dispatch(openDialog("Profile updated successfully!"));
          }

          return dispatch({ type: "UPDATE_USER_DETAILS", response });
        }
        response.data.errors.map((err) => {
          // snackbar.show(err);
          dispatch(openDialog(err));
        });
        return response.data.error;
      },
      (err) => {
        // snackbar.show("Could not update your profile.");
        dispatch(openDialog("Could not update your profile."));

        return err;
      }
    );
  };
}

export const handleUpload = (e) => {
  e.preventDefault();
  let formData = new FormData();

  Object.keys(e.target.media.files).map(function (key) {
    formData.append("media", e.target.media.files[key]);
  });

  return (dispatch) => {
    dispatch(uploading());
    return dispatch(uploadPhoto(formData));
  };
};

function uploadPhoto(formData) {
  return (dispatch) => {
    request(
      "post",
      "UPDATE_PROFILE_PIC",
      true,
      formData,
      {},
      { "Content-Type": "multipart/form-data" }
    ).then(
      (response) => {
        // snackbar.show("Photo uploaded!");
        dispatch(openDialog("Photo uploaded!"));

        // dispatch(uploadSuccess());
      },
      (err) => {
        // dispatch(uploadFailed());
        if (err.response.data.status === 415) {
          // snackbar.show("Photo format not supported.");
          dispatch(openDialog("Photo format not supported."));

          return;
        }
        // snackbar.show("Something went wrong while uploading your photo.");
        dispatch(
          openDialog("Something went wrong while uploading your photo.")
        );
      }
    );
  };
}

function uploadSuccess() {
  return {
    type: "UPDATE_PROFILE_PIC_SUCCESS",
  };
}

function uploading() {
  return {
    type: "UPDATE_PROFILE_PIC",
  };
}

function uploadFailed() {
  return {
    type: "UPDATE_PROFILE_PIC_FAILED",
  };
}

export const getUsersByDepartmentAndRole = (department, role) => {
  return fetchUsersByDepartmentAndRole(department, role);
};

function fetchUsersByDepartmentAndRole(department, role) {
  return (dispatch) => {
    request("get", "GET_DEPARTMENT_AND_ROLE_WISE_USER", true, null, {
      department,
      role,
    }).then((response) => {
      dispatch(setUsersByDepartmentAndRole(response.data.data));
    });
  };
}

function setUsersByDepartmentAndRole(users) {
  return {
    type: "USERS_FETCHED",
    users,
  };
}

export function logout() {
  deleteToken();
  disconnectSocket();
  return {
    type: "LOGGED_OUT",
  };
}

export const getUserDetails = (user_id) => {
  let promise = Promise.resolve(
    sendHTTPRequest("get", getUrl("GET_USER_INFO"), true, {}, { user_id })
  );
  return promise;
  // return request('get', 'GET_USER_INFO', true, {}, {user_id})
  //     .then(response=>{ return response.data.data });
};
