import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button, Grid, Select, MenuItem, Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import PaymentDetails from "../components/PaymentDetails/index";
import PurchaseDetails from "../components/PaymentDetails/PurchaseDetails";
import WarningIcon from "@material-ui/icons/Warning";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import { openCheckout } from "../components/PaymentDetails/Checkout";
import { Loader } from "../components/UtilityComponents";
import {
  getProductFeaturesByType,
  getUserSubscription,
  userChooseSubscriptionPlan,
  addSubscriptionPlan,
  getRazorPaySuccessCapture,
} from "../actions/userDetailsAction";
import { logout, openDialog } from "../actions/appActions";
import getURL from "../helpers/apis";
import { isNull } from "util";
import { constants } from "../helpers/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  paymentButton: {
    margin: "24px 0px",
  },
  paymentDetailsRow: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: "8px",
  },
  divider: {
    marginBottom: "8px",
  },
  paymentDetials: {
    width: "100%",
    maxWidth: "80%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  warningMessage: {
    display: "flex",
    alignItems: "center",
  },
  warningTyphography: {
    color: "white",
  },
  warningIcon: {
    marginLeft: "6px",

    fontSize: "20px",
  },
  feature: {
    listStyleType: "squre",
  },
}));

// const rezorPayData = {
//   error: false,
//   errors: [],
//   data: {
//     status: "unprocessed",
//     taxAmount: 0.18,
//     roundOffAmount: 0.18,
//     totalPrice: 1,
//     refundDetails: null,
//     _id: "5e5ca04c9c40be0bc8391d78",
//     taxCGSTAmount: 0.54,
//     taxSGSTAmount: 0.54,
//     taxCGSTPercent: 18,
//     taxSGSTPercent: 18,
//     totalPriceAfterTax: 4,
//     items: [
//       {
//         _id: "5e8340a0c2f5ed21882ab1d8",
//         name: "SocialMedia",
//         price: 1,
//         subscriptionId: "5de66782f38383417f12cada"
//       }
//     ],
//     userId: "5e2ac8132eafcd049e2a291c",
//     createdAt: "2020-03-02T05:57:32.605Z",
//     updatedAt: "2020-03-31T13:07:44.922Z",
//     __v: 18,
//     razorpay_order_id: "order_EYpvuPu80Na94k"
//   }
// };

function SubscriptionComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [cycle, setCycle] = useState("yearly");
  const [userType, setUserType] = useState("");
  const [upgradeOpen, setUpgradeOpen] = useState(false);
  const [purchaseOpen, setPurchaseOpen] = useState(false);
  const userDetail = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.userDetail
  );
  const currentDate = moment();
  const userCreatedDate = moment(userDetail.createdAt);

  const freeDays = currentDate.diff(userCreatedDate, "days");

  const productFeaturesListLoading = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.productFeaturesListLoading
  );

  const addSubscriptionPlanLoading = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.addSubscriptionPlanLoading
  );

  const productFeatureList = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.productFeatureList
  );

  const userSubscriptionList = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.userSubscriptionList
  );

  const chooseSubscriptionPlanList = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.chooseSubscriptionPlanList
  );

  const addSubscriptionPlanDetails = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.addSubscriptionPlanDetails
  );
  const userSubscriptionListLoading = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.userSubscriptionListLoading
  );

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue == 1) {
      userDetail &&
        userDetail.baseUserType === "JobSeeker" &&
        setUserType("Employer");

      userDetail &&
        userDetail.baseUserType === "SocialMediaUser" &&
        setUserType("JobSeeker");
    } else {
      userDetail && setUserType(userDetail.baseUserType);
    }
  };

  useEffect(() => {
    userSubscriptionList &&
      userSubscriptionList.length > 0 &&
      userDetail &&
      userDetail.baseUserType === "JobSeeker" &&
      dispatch(getProductFeaturesByType("Employer"));
    userSubscriptionList &&
      userSubscriptionList.length > 0 &&
      userDetail &&
      userDetail.baseUserType === "SocialMediaUser" &&
      dispatch(getProductFeaturesByType("JobSeeker"));
    userSubscriptionList &&
      userSubscriptionList.length <= 0 &&
      userDetail &&
      dispatch(getProductFeaturesByType(userDetail.baseUserType));
  }, [
    userDetail && userDetail.baseUserType,
    userSubscriptionList,
    getUserSubscription,
  ]);

  useEffect(() => {
    dispatch(getUserSubscription("active"));
  }, [userSubscriptionList && userSubscriptionList.length]);

  useEffect(() => {
    // userSubscriptionList &&
    //   userSubscriptionList.length > 0 &&
    //   userDetail &&
    //   userDetail.baseUserType === "JobSeeker" &&
    //   setUserType("Employer");
    // userSubscriptionList &&
    //   userSubscriptionList.length > 0 &&
    //   userDetail &&
    //   userDetail.baseUserType === "SocialMediaUser" &&
    //   setUserType("JobSeeker");
    userSubscriptionList &&
      userSubscriptionList.length <= 0 &&
      userDetail &&
      dispatch(
        userChooseSubscriptionPlan(
          cycle,
          "SocialMedia",
          userDetail.baseUserType
          // userType
        )
      );

    // userDetail &&
    // dispatch(userChooseSubscriptionPlan(cycle, "SocialMedia", userType));
  }, [
    chooseSubscriptionPlanList && chooseSubscriptionPlanList.length,
    // userType,
    // cycle,
    // value,
    getUserSubscription,
  ]);

  useEffect(() => {
    userType &&
      dispatch(
        userChooseSubscriptionPlan(
          cycle,
          "SocialMedia",
          // userDetail.baseUserType
          userType
        )
      );
  }, [userType, cycle, value]);

  useEffect(() => {
    if (userDetail && userType !== userDetail.baseUserType) {
      setUserType(userDetail.baseUserType);
    }
  }, [userDetail && userDetail.baseUserType]);

  function handleCallbackRazorPayCapture() {
    dispatch(logout());
    dispatch(openDialog("Please Login again to see new features."));
  }
  function handleCallbackRazorPaySuccess(res) {
    setUpgradeOpen(false);
    setPurchaseOpen(false);
    const body = {
      // razorpay_order_id: res.razorpay_order_id,
      // razorpay_payment_id: res.razorpay_payment_id,
      // razorpay_signature: res.razorpay_signature,
      ...res,
    };
    dispatch(getRazorPaySuccessCapture(body, handleCallbackRazorPayCapture));
  }

  function handleCallRazorPayMethod(data) {
    setUpgradeOpen(false);
    setPurchaseOpen(false);
    var pd = {
      key: process.env.REACT_APP_PAYU_KEY,
      txnid: data._id,
      amount: data.adjustedFinalPayment,
      firstname: data.username,
      email: userDetail.email,
      phone: userDetail.phone,
      productinfo: data.items[0].subscriptionId,
      surl: getURL("BASE_URL") + "subscription",
      furl: getURL("BASE_URL") + "subscription",
      hash: data.hash,
      service_provider: "payu_paisa",
    };
    redirectToPayU(pd);
    // openCheckout(
    //   userDetail,
    //   rezorPayData.totalPriceAfterTax,
    //   rezorPayData.razorpay_order_id,
    //   rezorPayData._id,
    //   handleCallbackRazorPaySuccess
    // );
  }

  //   PG_TYPE: "HDFCPG"
  // addedon: "2021-02-10 19:48:12"
  // address1: ""
  // address2: ""
  // amount: "4.00"
  // amount_split: "{"PAYU":"4.00"}"
  // bank_ref_num: "183846680576630"
  // bankcode: "MAST"
  // cardhash: "This field is no longer supported in postback params."
  // cardnum: "512345XXXXXX2346"
  // city: ""
  // country: ""
  // discount: "0.00"
  // email: "gandalf34@vmail.com"
  // encryptedPaymentId: "A4A1A2D6616C7CE33F3BAC2FAC34FF46"
  // error: "E000"
  // error_Message: "No Error"
  // field1: "253497899502"
  // field2: "048457"
  // field3: "183846680576630"
  // field4: "QnNLZm51amJ5OUlwVlVhbGd3M2o="
  // field5: "02"
  // field6: ""
  // field7: "AUTHPOSITIVE"
  // field8: ""
  // field9: ""
  // firstname: "gandalf34"
  // furl: "http://localhost:5000/subscription"
  // giftCardIssued: "true"
  // hash: "df7851b63f1edf5c23facd754ab5917eea12a1f3b6ca9be2f9df9b826d29899fd4d72ecd0ddd620ee5830f5cc4d149045f809f43a25fc83acdbe806f8e5c230a"
  // isConsentPayment: "0"
  // lastname: ""
  // mihpayid: "9084153168"
  // mode: "CC"
  // name_on_card: "Test"
  // net_amount_debit: "4"
  // payuMoneyId: "250783489"
  // phone: "5473577224"
  // productinfo: "5de66789f38383417f12cadb"
  // state: ""
  // status: "success"
  // surl: "http://localhost:5000/subscription"
  // txnMessage: "Transaction Successful"
  // txnStatus: "SUCCESS"
  // txnid: "6023ea8895012185e1d669d4"
  // udf1: ""
  // udf2: ""
  // udf3: ""
  // udf4: ""
  // udf5: ""
  // udf6: ""
  // udf7: ""
  // udf8: ""
  // udf9: ""
  // udf10: ""
  // unmappedstatus: "captured"
  // zipcode: ""

  function redirectToPayU(pd) {
    debugger;
    //use window.bolt.launch if you face an error in bolt.launch
    window.bolt.launch(pd, {
      responseHandler: function (response) {
        // your payment response Code goes here
        response.response.txnStatus !== "CANCEL" &&
          handleCallbackRazorPaySuccess(response);
        // fetch(base_url + "payment/payumoney/response", {
        //   method: "POST",
        //   headers: {
        //     Accept: "application/json",
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify(response.response),
        // })
        //   .then(function (a) {
        //     return a.json();
        //   })
        //   .then(function (json) {
        //   });
      },
      catchException: function (response) {
        response.response.txnStatus !== "CANCEL" &&
          handleCallbackRazorPaySuccess(response);

        // the code you use to handle the integration errors goes here
        // Make any UI changes to convey the error to the user
      },
    });
  }

  function handleMakePayment() {
    setUpgradeOpen(false);
    setPurchaseOpen(false);
    const choosablePlanId =
      chooseSubscriptionPlanList && chooseSubscriptionPlanList._id;
    dispatch(addSubscriptionPlan(choosablePlanId, handleCallRazorPayMethod));
  }
  return (
    <div className={classes.root}>
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <div>
          {userSubscriptionList.length <= 0 &&
            !userSubscriptionListLoading &&
            (freeDays >= 7 ? (
              <div
                style={{
                  backgroundColor: "#3eb0ff",
                  borderRadius: "4px",
                  width: "100%",
                  padding: "16px",
                }}
              >
                <Typography style={{ color: "white" }}>
                  Your free trial is completed now. Please subscribe plan.
                </Typography>
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: "#3eb0ff",
                  borderRadius: "4px",
                  width: "100%",
                  padding: "16px",
                }}
              >
                <Typography className={classes.warningTyphography}>
                  {`Your free trial of CB-REACT ends in ${7 - freeDays} days.`}
                </Typography>
              </div>
            ))}
        </div>

        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          style={{ marginTop: "16px" }}
        >
          {userDetail && userDetail.baseUserType}
        </Typography>
      </Container>
      {userSubscriptionList.length > 0 ? (
        <div className={classes.paymentDetials}>
          <Typography
            component="h1"
            variant="h6"
            color="textPrimary"
            gutterBottom
          >
            Current Subscription Details
          </Typography>
          <Divider className={classes.divider} />
          <PaymentDetails
            payment={userSubscriptionList[userSubscriptionList.length - 1]}
          />
        </div>
      ) : productFeaturesListLoading ? (
        <div style={{ minHeight: "350px" }}>
          <Loader />
        </div>
      ) : (
        <ul className={classes.feature}>
          {productFeatureList &&
            productFeatureList[0].features.map((feature) => {
              return (
                <div
                  className={classes.feature}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <AcUnitIcon
                    style={{ margin: "0px 8px 0px 0px", fontSize: "18px" }}
                  />
                  <Typography
                    variant="subtitle1"
                    component="p"
                    align="center"
                    // style={{ color: "white" }}
                  >
                    {feature}
                  </Typography>
                </div>

                // </div>
              );
            })}
        </ul>
      )}
      {!userSubscriptionListLoading && userSubscriptionList.length <= 0 ? (
        <div className={classes.paymentButton}>
          {addSubscriptionPlanLoading ? (
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white", opacity: "0.6", textTransform: "none" }}
            >
              Making payment...
            </Button>
          ) : (
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  style={{ margin: "10px", color: constants.PRIMARY_COLOR }}
                >
                  Cycle*
                </Typography>
              </Grid>
              <Grid item xs={6} align="center">
                <Select
                  value={cycle}
                  onChange={(e) => setCycle(e.target.value)}
                >
                  <MenuItem value={"yearly"}>Yearly</MenuItem>
                  <MenuItem value={"halfyearly"}>Halfyearly</MenuItem>
                  <MenuItem value={"quarterly"}>Quarterly</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} align="center">
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    color: "white",
                    margin: "10px",
                    textTransform: "none",
                  }}
                  onClick={() => setPurchaseOpen(true)}
                >
                  Make payment
                </Button>
                {purchaseOpen && (
                  <PurchaseDetails
                    open={purchaseOpen}
                    purchaseType="purchase"
                    handleClose={() => setPurchaseOpen(false)}
                    handleMakePayment={handleMakePayment}
                    subscriptionId={
                      chooseSubscriptionPlanList &&
                      chooseSubscriptionPlanList._id
                    }
                    userType={userType}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </div>
      ) : null}
      {!userSubscriptionListLoading &&
      userSubscriptionList.length > 0 &&
      userDetail ? (
        <div className={classes.paymentButton}>
          {addSubscriptionPlanLoading ? (
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white", opacity: "0.6", textTransform: "none" }}
            >
              Making payment...
            </Button>
          ) : (
            <Grid container justify="space-evenly">
              <Tabs
                value={value}
                centered
                onChange={handleChange}
                indicatorColor="primary"
                aria-label="simple tabs example"
                style={{ marginBottom: 12 }}
              >
                <Tab label="Renew" />
                {userDetail.baseUserType !== "Employer" && (
                  <Tab label="Upgrade" />
                )}
              </Tabs>
              <Grid container xs={12}>
                <Grid item xs={6} align="center">
                  <Typography
                    style={{ margin: "10px", color: constants.PRIMARY_COLOR }}
                  >
                    UserType*
                  </Typography>
                </Grid>
                {value === 0 && (
                  <Grid item xs={6} align="center">
                    <Typography style={{ margin: "10px" }}>
                      {userType}
                    </Typography>
                  </Grid>
                )}
                {value === 1 && (
                  <>
                    <Grid item xs={6} align="center">
                      {userDetail.baseUserType === "JobSeeker" && (
                        <Typography style={{ margin: "10px" }}>
                          {userType}
                        </Typography>
                      )}
                      {userDetail.baseUserType === "SocialMediaUser" && (
                        <Select
                          value={userType}
                          onChange={(e) => setUserType(e.target.value)}
                        >
                          <MenuItem disabled value={""}>
                            Choose Usertype
                          </MenuItem>
                          <MenuItem value={"Employer"}>Employer</MenuItem>
                          <MenuItem value={"JobSeeker"}>JobSeeker</MenuItem>
                        </Select>
                      )}
                    </Grid>
                  </>
                )}
                {/* {value === 0 && (
                  <>
                    <Grid item xs={6} align="center">
                      <Typography
                        style={{
                          margin: "10px",
                          color: constants.PRIMARY_COLOR,
                        }}
                      >
                        Cycle*
                      </Typography>
                    </Grid>
                    <Grid item xs={6} align="center">
                      <Select
                        value={cycle}
                        onChange={(e) => setCycle(e.target.value)}
                      >
                        <MenuItem value={"yearly"}>Yearly</MenuItem>
                        <MenuItem value={"halfyearly"}>Halfyearly</MenuItem>
                        <MenuItem value={"quarterly"}>Quarterly</MenuItem>
                      </Select>
                    </Grid>
                  </>
                )} */}
                <Grid item xs={6} align="center">
                  <Typography
                    style={{
                      margin: "10px",
                      color: constants.PRIMARY_COLOR,
                    }}
                  >
                    Cycle*
                  </Typography>
                </Grid>
                <Grid item xs={6} align="center">
                  <Select
                    value={cycle}
                    onChange={(e) => setCycle(e.target.value)}
                  >
                    <MenuItem
                      value={
                        userSubscriptionList[userSubscriptionList.length - 1]
                          .items.cycle
                      }
                    >
                      <Typography style={{ textTransform: "capitalize" }}>
                        {
                          userSubscriptionList[userSubscriptionList.length - 1]
                            .items.cycle
                        }
                      </Typography>
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ color: "white", textTransform: "none" }}
                    onClick={(e) => setUpgradeOpen(true)}
                  >
                    {value === 0 ? "Renew" : "Upgrade"}
                  </Button>
                  {upgradeOpen && (
                    <PurchaseDetails
                      open={upgradeOpen}
                      purchaseType={
                        userType === userDetail.baseUserType
                          ? "renew"
                          : "upgrade"
                      }
                      handleClose={() => setUpgradeOpen(false)}
                      handleMakePayment={handleMakePayment}
                      subscriptionId={
                        chooseSubscriptionPlanList &&
                        chooseSubscriptionPlanList._id
                      }
                      userType={userType}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </div>
      ) : null}
    </div>
  );
}

export default SubscriptionComponent;
