import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {
  getObjectParam,
  getStringParam,
  isValidParam
} from "../helpers/paramVerifier";

export const Loader = () => {
  return (
    <Grid style={{ position: "fixed", top: "50%", left: "50%" }}>
      <CircularProgress style={{ marginLeft: 10 }} />
      <Typography style={{ fontSize: 16 }}>Loading...</Typography>
    </Grid>
  );
};

export const NoRecords = props => {
  let obj = getObjectParam(props);
  let label = isValidParam(obj.label) ? obj.label : "No Records Found.";
  let style = getObjectParam(obj.style);
  return (
    <Grid style={{ position: "fixed", top: "50%", left: "50%" }}>
      <Typography style={{ fontSize: 16, ...style }}>{label}</Typography>
    </Grid>
  );
};
