import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  Divider,
  FormControl,
  FormControlLabel,
  TextField,
  Switch,
  Select,
  MenuItem,
  Button,
  Checkbox,
  Grid,
} from "@material-ui/core";

const UploadForm = ({
  index,
  currentId,
  classes,
  quotation = "",
  allowOpen = false,
  allowHidden = false,
  whoOpen = "AnyOne",
  whoHidden = "AnyOne",
  setQuotation,
  setAllowOpen,
  setAllowHidden,
  setWhoOpen,
  setWhoHidden,
  type,
  length,
  setSame,
  userSettings,
}) => {
  const [checked, setChecked] = useState(null);

  useEffect(() => {
    checked !== null && setSame(index, checked);
  }, [checked]);
  return (
    <div
      className={classes.form}
      noValidate
      style={{ display: currentId === index ? "block" : "none" }}
    >
      <Grid container>
        <Grid item xs={6}>
          <FormControl fullWidth>
        <TextField
          label="Gallery Quotation"
          multiline
          fullWidth
              rows={7}
          autoFocus
          onChange={(e) =>
            e.target.value.length <= 50 && setQuotation(e.target.value)
          }
          defaultValue={quotation}
          variant="outlined"
        />
      </FormControl>
        </Grid>
        <Grid item xs={6}>
      {type !== "private" && (
        <FormControlLabel
          className={classes.formControlLabel}
          control={
            <Switch
              checked={allowOpen}
              onChange={(e) => setAllowOpen(!allowOpen)}
            />
          }
          labelPlacement="start"
          label="Open Comments"
        />
      )}
      {type !== "private" && allowOpen && (
        <FormControlLabel
          className={classes.formControlLabel}
          control={
            <Select
              value={whoOpen}
              autoWidth
              onChange={(e) => setWhoOpen(e.target.value)}
              inputProps={{
                name: "who-open",
              }}
            >
              <MenuItem value={"NoOne"}>No One</MenuItem>
              <MenuItem value={"AnyOne"}>Any One</MenuItem>
              <MenuItem value={"OpenFriends"}>Open Friends</MenuItem>
              {userSettings &&
                userSettings.showHiddenFriendsOption &&
                [
                  { value: "HiddenFriends", label: "Hidden Friends" },
                      {
                        label: "Hidden, Open Friends",
                        value: "HiddenOpenFriends",
                      },
                ].map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.label}
                      </MenuItem>
                ))}
            </Select>
          }
          labelPlacement="start"
          label="Who Can Open Comment"
        />
      )}
      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Switch
            checked={allowHidden}
            onChange={(e) => setAllowHidden(!allowHidden)}
          />
        }
        labelPlacement="start"
        label="Hidden Comments"
      />
      {allowHidden && (
        <FormControlLabel
          className={classes.formControlLabel}
          control={
            <Select
              value={whoHidden}
              autoWidth
              onChange={(e) => setWhoHidden(e.target.value)}
              inputProps={{
                name: "who-hidden",
              }}
            >
              <MenuItem value={"NoOne"}>No One</MenuItem>
              <MenuItem value={"AnyOne"}>Any One</MenuItem>
              <MenuItem value={"OpenFriends"}>Open Friends</MenuItem>
              <MenuItem value={"HiddenFriends"}>Hidden Friends</MenuItem>
              <MenuItem value={"HiddenOpenFriends"}>
                Hidden, Open Friends
              </MenuItem>
            </Select>
          }
          labelPlacement="start"
          label="Who Can Hidden Comment"
        />
      )}
      {index === 0 && length > 1 && (
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={() => setChecked(!checked)}
              name="checkedA"
            />
          }
          label="Apply these settings to all selected galleries"
        />
      )}
        </Grid>
      </Grid>
    </div>
  );
};

export default UploadForm;
