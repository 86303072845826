import request from "../helpers/requestEngine";
// // import snackbar from "snackbar";
import { openDialog, closeDialog } from "./appActions";
import $ from "jquery";

export const getCalendarEvents = (user_id) => {
  return fetchCalendarEvents(user_id);
};

function fetchCalendarEvents(user_id) {
  return (dispatch) => {
    request("get", "CALENDAR_EVENT", true, null, { _id: user_id }).then(
      (response) => {
        dispatch(insertEvents(parseEventTimeFrame(response.data.data)));
      },
      (err) => {}
    );
  };
}

const parseEventTimeFrame = (events) => {
  if (events.length) {
    const parsedEvents = events.map((event) => {
      event.start = new Date(event.start);
      event.end = new Date(event.end);
      return event;
    });
    return parsedEvents;
  } else {
    return events;
  }
};

function insertEvents(events) {
  return {
    events,
    type: "ASSIGN_CALENDAR_EVENTS",
  };
}

function toggleCalendarModal() {
  $("#eventModal").modal("toggle");
}

export const onSelectSlot = (content) => {
  toggleCalendarModal();
  return setModalContents(content);
};

export const onSelectEvent = (e) => {
  toggleCalendarModal();
  return setModalContents(e);
};

export const onCloseModal = (e) => {
  return clearModal();
};

function setModalContents(content) {
  return {
    content,
    type: "UPDATE_MODAL_CONTENT",
  };
}

function addEvent(event) {
  return {
    type: "ADD_EVENT",
    event,
  };
}

function updateEvent(event) {
  return {
    type: "UPDATE_CALENDAR_EVENT",
    event,
  };
}

function removeEvent(event_id) {
  return {
    type: "DELETE_CALENDER_EVENT",
    event_id,
  };
}

function clearModal() {
  return {
    type: "CLEAR_MODAL",
  };
}

export function onSubmitEvent(e, formData, user_id) {
  e.preventDefault();
  if (e._id) {
    return postEvent({
      ...formData,
      start: new Date(formData.start).valueOf(),
      end: new Date(formData.end).valueOf(),
      user_id: user_id,
      _id: e._id,
    });
  }
  return postEvent({
    ...formData,
    start: new Date(formData.start).valueOf(),
    end: new Date(formData.end).valueOf(),
    user_id: user_id,
  });
}

function postEvent(formData) {
  return (dispatch) => {
    toggleCalendarModal();
    request("post", "CALENDAR_EVENT", true, formData).then(
      (response) => {
        dispatch(clearModal());
        if (formData._id) {
          // snackbar.show("Event updated at your calendar.");
          dispatch(openDialog("Event updated at your calendar."));
          // dispatch(updateEvent(response.data.data));
          dispatch(fetchCalendarEvents(formData.user_id));
        } else {
          // snackbar.show("Event added to your calendar.");
          dispatch(openDialog("Event added to your calendar."));
          // dispatch(addEvent(response.data.data));
          dispatch(fetchCalendarEvents(formData.user_id));
        }
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));
      }
    );
  };
}

export function onDeleteEvent(event_id) {
  return deleteEvent(event_id);
}

function deleteEvent(event_id) {
  return (dispatch) => {
    request("delete", "CALENDAR_EVENT", true, { event_id }).then(
      (response) => {
        if (!response.data.error) {
          toggleCalendarModal();
          dispatch(removeEvent(event_id));
          // snackbar.show("Event deleted from your calendar.");
          dispatch(openDialog("Event deleted from your calendar."));
        } else {
          // snackbar.show("Event not deleted.");
          dispatch(openDialog("Event not deleted."));
        }
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));
      }
    );
  };
}

export function onTitleChange(e) {
  return {
    type: "MODAL_TITLE_CHANGED",
    title: e.target.value,
  };
}

export function onDescChange(e) {
  return {
    type: "MODAL_DESC_CHANGED",
    desc: e.target.value,
  };
}

export function onStartChange(e, value) {
  return {
    type: "MODAL_START_CHANGED",
    start: value,
  };
}

export function onEndChange(e, value) {
  return {
    type: "MODAL_END_CHANGED",
    end: value,
  };
}
