const postAnADReducer = (
  state = {
    submittingForm: false,
    bnr: "",
    pr: "",
    dr: "",
    city: "",
    sDate: "",
    description: "",
    newADCreated: false
  },
  action
) => {
  switch (action.type) {
    case "POST_AD_SUBMITTING":
      return {
        ...state,
        submittingForm: true
      };
    case "POST_AD_SUBMITTING_FAILED":
      return {
        ...state,
        submittingForm: false
      };
    case "POST_AD_SUBMITTING_SUCCESS":
      return {
        ...state,
        submittingForm: false,
        newADCreated: true
      };
    default:
      return state;
  }
};

export default postAnADReducer;
