import React, { useState, useEffect } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import withRouter from "react-router-dom/es/withRouter";
import bindActionCreators from "redux/es/bindActionCreators";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Pagination from "@material-ui/lab/Pagination";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListSubheader from "@material-ui/core/ListSubheader";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import PersonIcon from "@material-ui/icons/Person";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import DeleteSharpIcon from "@material-ui/icons/DeleteSharp";
import Divider from "@material-ui/core/Divider";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FormGroup from "@material-ui/core/FormControl";
import FormControl from "@material-ui/core/FormControl";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import { Loader } from "./UtilityComponents";
import getURL from "../helpers/apis";
import ConfirmationDialog from "./ConfirmationDialog";
import CustomNameDialog from "./CustomNameDialog";
import Carousel from "./GalleryComponent/Carousel";
import {
  notifEventTarget,
  getAllNotifs,
  deleteNotif,
} from "../actions/notificationsActions";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { openAvatarModal } from "../actions/appActions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "475px",
    height: "50vh",
    overflowY: "scroll",
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "8px",
  },
}));

const Notifications = () => {
  const classes = useStyles();
  const [readlist, setReadlist] = useState(null);
  const [todaylist, setTodaylist] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openGallery, setOpenGallery] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [galleries, setGalleries] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [hover, setHover] = useState(false);
  const [notifId, setNotifId] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const rootUrl = getURL("UPLOADS");

  useEffect(() => {
    getAllNotifs(page - 1, 20);
    notifEventTarget.addEventListener("fetched", (e) => {
      setReadlist(e.detail.read);
      setTodaylist(e.detail.today);
    });
    notifEventTarget.addEventListener("deleted", (e) => {
      getAllNotifs(page - 1, 20);
    });
    notifEventTarget.addEventListener("new_notification", (e) => {
      getAllNotifs(page - 1, 20);
    });
  }, []);

  const handleDelete = (id) => {
    setTitle("Are you sure?");
    setDescription(`Do you want to delete this notification?`);
    setNotifId(id);
    setOpenConfirm(true);
  };

  const handleConfirm = () => {
    setOpenConfirm(false);
    deleteNotif(notifId);
  };

  //   useEffect(() => {
  //     if (
  //       todaylist &&
  //       readlist &&
  //       (todaylist.length > 0 || readlist.length > 0)
  //     ) {
  //       let arr =
  //         todaylist.length === 0
  //           ? readlist
  //           : readlist.length === 0
  //           ? todaylist
  //           : todaylist.concat(readlist);
  //       arr = arr.filter((item) => {
  //         if (item.action.entityPic || item.action.entityVid) return true;
  //         else return false;
  //       });
  //       arr = arr.map((item) => item.action.entityId);
  //       setGalleries(arr);
  //     }
  //   }, [readlist, todaylist]);

  const renderNotifList = (type) => {
    let list = type === "Today" ? todaylist : readlist;
    return (
      <>
        <List subheader={<li />}>
          <ListSubheader
            disableGutters
            disableSticky
            inset
            color="primary"
            style={{ fontSize: 20 }}
          >
            {type}
          </ListSubheader>
          {list.length > 0 &&
            list.map((item, index) => {
              let action = item.action;
              let actor = item.actor;
              let str = "";
              switch (action.entity) {
                case "like":
                  str = `liked your ${
                    action.entityType === "photos" ? "image" : "video"
                  } ${action.type === "Open" ? "openly" : "hiddenly"}`;
                  break;
                case "comment":
                  str = `commented on your ${
                    action.entityType === "photos" ? "image" : "video"
                  } ${action.type === "Open" ? "openly" : "hiddenly"}`;
                  break;
                case "follow":
                  str = `started following you ${
                    action.type === "Open" ? "openly" : "hiddenly"
                  }`;
                  break;
                case "favourite":
                  str = `favourited your ${
                    action.entityType === "photos" ? "image" : "video"
                  } ${action.type === "Open" ? "openly" : "hiddenly"}`;
                  break;
                case "viewedProfile":
                  str = `viewed your profile`;
                  break;
                case "privateGalleryShare":
                  str = `shared ${
                    action.entityType === "photos" ? "an image" : "a video"
                  } to you ${action.type === "Open" ? "openly" : "hiddenly"}`;
                  break;
                case "message":
                  str = `messaged you`;
                  break;
                case "position":
                  str = `position`;
                  break;
                default:
                  break;
              }
              let secString = str + " • " + moment(item.createdAt).fromNow();
              return (
                <ListItem
                  button
                  key={item._id}
                  divider
                  ContainerProps={{
                    onMouseEnter: () => setHover(item._id),
                    onMouseLeave: () => setHover(""),
                  }}
                >
                  <ListItemAvatar>
                    {actor.profilepic ? (
                      <Avatar
                        onClick={(e) =>
                          dispatch(openAvatarModal(process.env.REACT_APP_BUCKET_NAME + actor.profilepic))
                        }
                        alt="owner"
                        src={process.env.REACT_APP_BUCKET_NAME + actor.profilepic}
                      />
                    ) : (
                      <Avatar
                        alt="owner"
                        onClick={(e) => dispatch(openAvatarModal())}
                      >
                        <PersonIcon />
                      </Avatar>
                    )}
                  </ListItemAvatar>
                  <Link to={`/user_profile/${actor._id}`}>
                    <ListItemText
                      secondaryTypographyProps={{ style: { color: "black" } }}
                      primary={actor.user_follow_customname}
                      secondary={secString}
                    />
                  </Link>
                  <ListItemSecondaryAction>
                    {action.entityPic && (
                      <IconButton
                        disableFocusRipple
                        disableRipple
                        style={{ padding: "2px" }}
                        onClick={() => {
                          setCurrentIndex(0);
                          setOpenGallery(action.actionId);
                        }}
                      >
                        <Card raised={true} square>
                          <CardMedia
                            style={{ width: 50 }}
                            component="img"
                            alt="No Thumbnail found."
                            height="50"
                            image={process.env.REACT_APP_BUCKET_NAME + action.entityPic}
                          />
                        </Card>
                      </IconButton>
                    )}
                    {hover === item._id && (
                      <IconButton
                        disableFocusRipple
                        disableRipple
                        color="secondary"
                        onClick={() => handleDelete(item._id)}
                        fontSize="small"
                      >
                        <DeleteSharpIcon />
                      </IconButton>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
        </List>
      </>
    );
  };

  return (
    <Paper className={classes.root} elevation={0}>
      {openGallery && (
        <Carousel
          open={Boolean(openGallery)}
          handleClose={() => setOpenGallery(false)}
          galleries={[openGallery]}
          currentIndex={currentIndex}
          handleChangeIndex={(i) => setCurrentIndex(i)}
          // galleryType={type}
        />
      )}
      {openConfirm && (
        <ConfirmationDialog
          open={openConfirm}
          handleCancel={() => setOpenConfirm(false)}
          handleConfirm={() => handleConfirm()}
          title={title}
          description={description}
        />
      )}
      {todaylist && renderNotifList("Today")}
      <Divider variant="middle" light />
      {todaylist && renderNotifList("Previous")}
    </Paper>
  );
};

export default withRouter(Notifications);
