const applicantsReducer = (
  state = {
    applicants: [],
    usersDetail: null,
    shareadUserList: [],
    shareableUserList: [],
    shareableUserListLoading: false,
    sharedUserListLoading: false,
    sharingPrivatePicLoading: false,
    removingPrivatePicLoading: false,
  },
  action
) => {
  switch (action.type) {
    case "APPLICANTS_FETCHED_SUCCESS":
      return {
        ...state,
        applicants: action.applicants,
      };
    case "UPDATE_APPLICANT_STATUS":
      return {
        ...state,
        updatingApplicantStatus: true,
      };
    case "UPDATE_APPLICANT_STATUS_SUCCESS":
      const updatedApplicantList = state.applicants.map((user) => {
        if (user._id === action.payload._id) {
          return Object.assign(user, { status: action.payload.status });
        } else {
          return user;
        }
      });
      return {
        ...state,
        applicants: updatedApplicantList,
        updatingApplicantStatus: false,
      };
    case "UPDATE_APPLICANT_STATUS_FAILED":
      return {
        ...state,
        updatingApplicantStatus: false,
      };
    case "FETCH_SHAREABLE_USER_LIST":
      return {
        ...state,
        shareableUserListLoading: true,
      };
    case "FETCH_SHAREABLE_USER_LIST_SUCCESS":
      return {
        ...state,
        shareableUserListLoading: false,
        shareableUserList: action.payload,
      };
    case "CLEAR_SHAREABLE_USER_LIST_SUCCESS":
      return {
        ...state,
        shareableUserListLoading: false,
        shareableUserList: [],
      };
    case "CLEAR_SHARED_USER_LIST_SUCCESS":
      return {
        ...state,
        sharedUserListLoading: false,
        shareadUserList: [],
      };
    case "FETCH_SHAREABLE_USER_LIST_FAILED":
      return {
        ...state,
        shareableUserListLoading: false,
      };
    case "FETCH_SHARED_USER_LIST":
      return {
        ...state,
        sharedUserListLoading: true,
      };
    case "FETCH_SHARED_USER_LIST_SUCCESS":
      return {
        ...state,
        sharedUserListLoading: false,
        shareadUserList: action.payload,
      };
    case "FETCH_SHARED_USER_LIST_FAILED":
      return {
        ...state,
        sharedUserListLoading: false,
      };
    //sharing

    case "SHARING_PRIVATE_PIC":
      return {
        ...state,
        sharingPrivatePicLoading: true,
      };
    case "SHARING_PRIVATE_PIC_SUCCESS":
      const updatedShareableUserList = state.shareableUserList.filter(function (
        item
      ) {
        return !action.payload.userId.includes(item._id);
      });
      return {
        ...state,
        sharingPrivatePicLoading: false,
        shareableUserList: updatedShareableUserList,
        // shareadUserList: action.payload
      };
    case "SHARING_PRIVATE_PIC_FAILED":
      return {
        ...state,
        sharingPrivatePicLoading: false,
      };

    //removing
    case "REMOVING_PRIVATE_PIC":
      return {
        ...state,
        removingPrivatePicLoading: true,
      };
    case "REMOVING_PRIVATE_PIC_SUCCESS":
      const updatedSharedUserList = state.shareadUserList.filter(function (
        item
      ) {
        return !action.payload.userId.includes(item.sharedWith._id);
      });
      return {
        ...state,
        removingPrivatePicLoading: false,
        shareadUserList: updatedSharedUserList,
      };
    case "REMOVING_PRIVATE_PIC_FAILED":
      return {
        ...state,
        removingPrivatePicLoading: false,
      };
    default:
      return state;
  }
};

export default applicantsReducer;
