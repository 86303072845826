import React from "react";
import Grid from "@material-ui/core/Grid";

import ProfileAboutComponent from "./ProfileAboutComponent";
import ProfilePhotosComponent from "./ProfilePhotosComponent";
import ProfileVideosComponent from "./ProfileVideosComponent";

export default class ProfileDetails extends React.Component {
  render() {
    return <ProfileAboutComponent />;
  }
}
