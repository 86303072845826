import TextField from "@material-ui/core/TextField";

import React, { useEffect, useState } from "react";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { constants } from "../../helpers/constants";
import moment from "moment";

import {
  getPositionDetailsById,
  editPositionDetails,
} from "../../../src/actions/getPosActions";
import {
  requestCountries,
  requestIndustries,
  requestStates,
} from "../../actions/registerActions";
import { getRoles, getJobTypes } from "../../actions/userDetailsAction";
import {
  genderOptionsList,
  positionOptionsList,
} from "../../helpers/constants";

import { Link, withRouter, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    marginTop: "15px",
    backgroundColor: theme.palette.background.paper,
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  positionTabHead: {
    display: "flex",
    textDecoration: "none",
  },
  breadcrumbSeparater: {
    margin: "0px 6px",
  },
  appBar: {
    boxShadow: "none",
  },
  tabs: {
    textColor: "white",
  },
  applicantHead: {
    padding: "16px 16px 0px",
  },
  editTitle: {
    margin: "16px 0px 0px",
  },
  editTitleTypography: {
    fontWeight: "400",
  },
  editForm: {
    display: "flex",
    padding: "16px",
    // justifyContent: "center",
  },
  labelTypography: {
    margin: "7px 6px 6px 6px",
    // fontWeight: "500",
  },
  checkboxTypography: {
    margin: "12px",
  },
  arrowBackButton: {
    marginRight: "8px",
  },
  divider: {
    marginTop: "12px",
  },
  labelParent: {
    display: "flex",
    flexDirection: "column",
  },
  editButton: {
    margin: "8px 0px",
    display: "flex",
    justifyContent: "center",
  },
  allPositionTitle: {
    display: "flex",
    textDecoration: "none",
  },
  nativeSelect: {
    margin: "6px 0px",
  },
  textFieldParent: {
    display: "flex",
    flexDirection: "column",
  },
}));

function EditPositionDetails(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const queryParamArrayLength = props.history.location.pathname.split("/")
    .length;
  const positionId = props.history.location.pathname.split("/")[
    queryParamArrayLength - 1
  ];
  const currentPosition = useSelector(
    ({ PosTableReducer }) => PosTableReducer.positionDetailsById
  );
  const { updatingPositionDetailsLoading, error } = useSelector(
    ({ PosTableReducer }) => PosTableReducer
  );
  const countriesList = useSelector(
    ({ registerReducer }) => registerReducer.countries
  );
  const statesList = useSelector(
    ({ registerReducer }) => registerReducer.states
  );
  const industriesList = useSelector(
    ({ registerReducer }) => registerReducer.industries
  );
  const jobtypes = useSelector(({ rolesReducer }) =>
    rolesReducer.jobtypes
      ? rolesReducer.jobtypes.map((jobtype) => jobtype.name)
      : []
  );
  const rolesList = useSelector(({ rolesReducer }) => rolesReducer.roles);

  useEffect(() => {
    dispatch(getRoles());
    dispatch(getJobTypes());
  }, [rolesList && rolesList.length]);

  const [state, setState] = useState({
    role: "",
    title: "",
    ageMin: 0,
    ageMax: 0,
    gender: "",
    noOfPositions: "",
    lastDate: "",
    otherRolesCanApply: false,
    positionType: "",
    description: "",
    compensation: "",
    amount: "",
    country: "",
    city: "",
    state: "",
    pincode: "",
    houseno: "",
    street: "",
    industry: "",
    otherrolename: "",
  });

  useEffect(() => {
    currentPosition &&
      Object.keys(state).map((key) => {
        setState({
          ...state,
          ...currentPosition,
        });
      });
  }, [Object.values(currentPosition).join(",")]);

  useEffect(() => {
    dispatch(getPositionDetailsById(positionId));
  }, [positionId]);

  useEffect(() => {
    countriesList && dispatch(requestCountries());
  }, [countriesList && countriesList.length]);

  useEffect(() => {
    dispatch(requestIndustries());
  }, [industriesList && industriesList.length]);

  useEffect(() => {
    dispatch(requestStates(state.country));
  }, [state.country, statesList && statesList.length]);

  const handleRedirectToAllPosition = () => {
    props.history.push(`/all-positions`);
  };

  const handleUpdatePositionDetails = () => {
    const body = {
      positionId,
      title: state.title,
      role: state.role,
      ageMin: state.ageMin,
      ageMax: state.ageMax,
      gender: state.gender,
      lastDate: state.lastDate,
      otherRolesCanApply: state.otherRolesCanApply,
      positionType: state.positionType,
      description: state.description,
      compensation: state.compensation,
      amount: state.amount,
      country: state.country,
      state: state.state,
      industry: state.industry,
      houseno: state.houseno,
      street: state.street,
      city: state.city,
      pincode: state.pincode,
      otherrolename: state.otherrolename,
      noOfPositions: state.noOfPositions,
    };

    dispatch(editPositionDetails(body));
  };

  const handleChange = (name) => (event) => {
    if (name === "otherRolesCanApply") {
      setState({
        ...state,
        [name]: event.target.checked,
      });
    } else {
      if (name === "country") {
        dispatch(requestStates(event.target.value));
        setState({
          ...state,
          state: "",
          country: event.target.value,
        });
        if (event.target.value.toLowerCase() !== "india") {
          setState({
            ...state,
            industry: "",
            country: event.target.value,
          });
        }
      } else {
        setState({
          ...state,
          [name]: event.target.value,
        });
      }
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.applicantHead}>
        <div className={classes.flexCenter}>
          <Typography
            component={Link}
            role="button"
            to="/all-positions/"
            className={classes.positionTabHead}
            style={{
              display: "flex",
              textDecoration: "none",
              color: constants.PRIMARY_COLOR,
            }}
          >
            All Positions
          </Typography>
          <Typography
            style={{ color: constants.PRIMARY_COLOR }}
            variant="subtitle1"
            className={classes.breadcrumbSeparater}
          >
            /
          </Typography>

          <Typography
            style={{ color: constants.PRIMARY_COLOR }}
            variant="subtitle1"
          >
            {currentPosition && currentPosition.role}
          </Typography>
        </div>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          style={{ float: "right", textTransform: "none" }}
          onClick={() => history.push("/all-positions")}
        >
          Back
        </Button>

        <div className={classes.editTitle}>
          <Typography variant="h5" className={classes.editTitleTypography}>
            Edit {currentPosition && currentPosition.role}
          </Typography>
        </div>
        <Divider className={classes.divider} />
      </div>
      <div style={{ margin: 24 }}>
        <Grid container spacing={6} justify="space-evenly">
          <Grid item xs={6} spacing={1} style={{ paddingBottom: 0 }}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Type*
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Select
                  className={classes.nativeSelect}
                  fullWidth
                  margin="dense"
                  value={state.positionType}
                  onChange={handleChange("positionType")}
                  inputProps={{
                    name: "positionType",
                    id: "positionType-native-helper",
                  }}
                >
                  {jobtypes.map((option) => {
                    return (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Title*
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  margin="dense"
                  id="ageMin"
                  type="text"
                  onChange={handleChange("title")}
                  value={state.title}
                />
              </Grid>

              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Role*
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Select
                  className={classes.nativeSelect}
                  fullWidth
                  margin="dense"
                  value={state.role}
                  onChange={handleChange("role")}
                  inputProps={{
                    name: "role",
                    id: "role-native-helper",
                  }}
                >
                  {rolesList &&
                    [{ _id: "abc", role: "Any" }, ...rolesList].map(
                      (option) => {
                        return (
                          <MenuItem key={option._id} value={option.role}>
                            {option.role}
                          </MenuItem>
                        );
                      }
                    )}
                </Select>
              </Grid>
              {state.role.toLowerCase() === "actor" && (
                <>
                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                    >
                      Role Name*
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="otherrolename"
                      name="otherrolename"
                      type="text"
                      onChange={handleChange("otherrolename")}
                      value={state.otherrolename}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Gender*
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Select
                  className={classes.nativeSelect}
                  fullWidth
                  margin="dense"
                  value={state.gender}
                  onChange={handleChange("gender")}
                  inputProps={{
                    name: "gender",
                    id: "gender-native-helper",
                  }}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Compensation*
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Select
                  className={classes.nativeSelect}
                  fullWidth
                  margin="dense"
                  value={state.compensation}
                  onChange={handleChange("compensation")}
                  inputProps={{
                    name: "gender",
                    id: "gender-native-helper",
                  }}
                >
                  <MenuItem value="Free">Free</MenuItem>
                  <MenuItem value="Paid">Paid</MenuItem>
                </Select>
              </Grid>
              {state.compensation.toLowerCase() === "paid" && (
                <>
                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                    >
                      Amount*
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="amount"
                      name="amount"
                      type="text"
                      onChange={handleChange("amount")}
                      value={state.amount}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Age*
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  margin="dense"
                  id="ageMin"
                  type="text"
                  onChange={handleChange("ageMin")}
                  value={state.ageMin}
                />
              </Grid>
              <Typography
                style={{ marginTop: "12px", marginLeft: 24, marginRight: 24 }}
              >
                -
              </Typography>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  margin="dense"
                  id="ageMax"
                  type="text"
                  onChange={handleChange("ageMax")}
                  value={state.ageMax}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  How Many?*
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  margin="dense"
                  id="noOfPositions"
                  name="noOfPositions"
                  type="text"
                  onChange={handleChange("noOfPositions")}
                  value={state.noOfPositions}
                />
              </Grid>
       
            </Grid>
          </Grid>
          <Grid item xs={6} spacing={1} style={{ paddingBottom: 0 }}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  House No.*
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  margin="dense"
                  id="houseno"
                  name="houseno"
                  type="text"
                  onChange={handleChange("houseno")}
                  value={state.houseno}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Street*
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  margin="dense"
                  id="street"
                  name="street"
                  type="text"
                  onChange={handleChange("street")}
                  value={state.street}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  City*
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  margin="dense"
                  id="city"
                  name="city"
                  type="text"
                  onChange={handleChange("city")}
                  value={state.city}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Country*
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Select
                  className={classes.nativeSelect}
                  fullWidth
                  margin="dense"
                  value={state.country}
                  onChange={handleChange("country")}
                  inputProps={{
                    name: "country",
                    id: "country-native-helper",
                  }}
                >
                  {countriesList &&
                    [{ _id: "abc", country: "Any" }, ...countriesList].map(
                      (option) => {
                        return (
                          <MenuItem key={option._id} value={option.country}>
                            {option.country}
                          </MenuItem>
                        );
                      }
                    )}
                </Select>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  State*
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Select
                  className={classes.nativeSelect}
                  fullWidth
                  margin="dense"
                  value={state.state}
                  disabled={state.country === ""}
                  onChange={handleChange("state")}
                  inputProps={{
                    name: "state",
                    id: "state-native-helper",
                  }}
                >
                  {statesList &&
                    ["Any", ...statesList].map((option) => {
                      return (
                        <MenuItem key={option._id} value={option}>
                          {option}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Grid>
              {state.country === "India" && (
                <>
                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                    >
                      Industry*
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Select
                      className={classes.nativeSelect}
                      fullWidth
                      margin="dense"
                      value={state.industry}
                      onChange={handleChange("industry")}
                      inputProps={{
                        name: "industry",
                        id: "industry-native-helper",
                      }}
                    >
                      {industriesList &&
                        [
                          { _id: "abc", industry: "Any" },
                          ...industriesList,
                        ].map((option) => {
                          return (
                            <MenuItem key={option._id} value={option.industry}>
                              {option.industry}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </Grid>
                </>
              )}
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Pincode*
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  margin="dense"
                  id="pincode"
                  name="pincode"
                  type="text"
                  onChange={handleChange("pincode")}
                  value={state.pincode}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Last Date*
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  margin="dense"
                  id="lastDate"
                  name="lastDate"
                  type="date"
                  onChange={handleChange("lastDate")}
                  value={moment(state.lastDate).format("YYYY-MM-DD")}
                />
              </Grid>
            </Grid>
          </Grid>
          {!(
            !state.role ||
            state.role.toLowerCase() === "" ||
            state.role.toLowerCase() === "actor" ||
            state.role.toLowerCase() === "model" || 
            state.role.toLowerCase() === "audience" ||
            state.role.toLowerCase() === "people" 
          ) && (
            <>
              <Grid item xs={6}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Main role in user other roles can also apply?*
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  style={{ display: "block" }}
                  control={
                    <Checkbox
                      checked={state.otherRolesCanApply}
                      onChange={handleChange("otherRolesCanApply")}
                      value="otherRolesCanApply"
                      color="secondary"
                      disabled={
                        !state.role ||
                        state.role.toLowerCase() === "" ||
                        state.role.toLowerCase() === "actor" ||
                        state.role.toLowerCase() === "model"
                      }
                    />
                  }
                  // label="Main role in user other roles can also apply?"
                />
              </Grid>
            </>
          )}
          <Grid item xs={2} style={{ paddingTop: 0 }}>
            <Typography style={{ margin: 10, color: constants.PRIMARY_COLOR }}>
              Description*
            </Typography>
          </Grid>
          <Grid item xs={10} style={{ paddingTop: 0 }}>
            <TextField
              id="description"
              name="description"
              fullWidth
              margin="dense"
              type="text"
              multiline
              rows={6}
              variant="outlined"
              onChange={handleChange("description")}
              value={state.description}
            />
          </Grid>
        </Grid>
      </div>

      {error ? (
        <div style={{ color: "red", textAlign: "center" }}> {error}</div>
      ) : (
        ""
      )}
      <div className={classes.editButton}>
        {updatingPositionDetailsLoading ? (
          <Button
            variant="contained"
            style={{
              opacity: "0.6",
              textTransform: "none",
              color: constants.PRIMARY_COLOR,
            }}
          >
            Updating...
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={handleUpdatePositionDetails}
            style={{ textTransform: "none", color: constants.PRIMARY_COLOR }}
          >
            Update
          </Button>
        )}
      </div>
    </div>
  );
}
export default withRouter(EditPositionDetails);
