import request from "../helpers/requestEngine";
import snackbar from "snackbar";

export const getPhotos = () => {
  return dispatch => {
    dispatch(mediaFetching());
    return dispatch(fetchPhotos());
  };
};

function mediaFetching() {
  return {
    type: "PHOTOS_FETCHING"
  };
}

function fetchPhotos() {
  return dispatch => {
    request("get", "PROFILE_PHOTO", true).then(
      response => dispatch(photosFetchSuccess(response.data.data)),
      err => dispatch(photosFetchFailed())
    );
  };
}

function photosFetchSuccess(photos) {
  return {
    type: "PHOTOS_FETCH_SUCCESS",
    payload: photos
  };
}

function photosFetchFailed() {
  return {
    type: "PHOTOS_FETCH_FAILED"
  };
}

export const handleUpload1 = e => {
  e.preventDefault();
  let formData = new FormData();

  Object.keys(e.target.media.files).map(function(key) {
    formData.append("media", e.target.media.files[key]);
  });

  return dispatch => {
    dispatch(uploading());
    return dispatch(uploadPhoto(formData));
  };
};

function uploadPhoto(formData) {
  return dispatch => {
    request(
      "post",
      "PROFILE_PHOTO",
      true,
      formData,
      {},
      { "Content-Type": "multipart/form-data" }
    ).then(
      response => {
        snackbar.show("Photo uploaded!");
        dispatch(uploadSuccess());
      },
      err => {
        dispatch(uploadFailed());
        if (err.response.data.status === 415) {
          snackbar.show("Photo format not supported.");
          return;
        }
        snackbar.show("Something went wrong while uploading your photo.");
      }
    );
  };
}

function uploadSuccess() {
  return {
    type: "PHOTO_UPLOAD_SUCCESS"
  };
}

function uploading() {
  return {
    type: "PHOTO_UPLOADING"
  };
}

function uploadFailed() {
  return {
    type: "PHOTO_UPLOAD_FAILED"
  };
}

export function onDeletePhotos(filename) {
  return deletePhotos(filename);
}

const deletePhotos = filename => {
  return dispatch => {
    request("delete", "PROFILE_PHOTO", true, { filename }).then(
      response => {
        if (!response.data.error) {
          dispatch(uploadSuccess());
          return snackbar.show("Photo deleted.");
        }
        snackbar.show("Couldn't delete the photo.");
      },
      err => {
        snackbar.show("Couldn't delete the photo.");
      }
    );
  };
};
