const initialState = {
  posts: [],
  adsBetweenPosts: [],
  post: null,
  fetchingPosts: true,
  fetchingAdsBetweenPosts: true,
  fetchingPost: true,
  commentsObj: {},
  repliesObj: {},
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "FETCH_ADS_BETWEEN_POSTS":
      return {
        ...state,
        fetchingAdsBetweenPosts: true,
      };
    case "FETCH_ADS_BETWEEN_POSTS_SUCCESS":
      return {
        ...state,
        adsBetweenPosts: payload,
        fetchingAdsBetweenPosts: false,
      };
    case "FETCH_ADS_BETWEEN_POSTS_FAILED":
      return {
        ...state,
        fetchingAdsBetweenPosts: false,
      };
    case "FETCH_POSTS":
      return {
        ...state,
        fetchingPosts: true,
      };
    case "FETCH_POSTS_SUCCESS":
      return {
        ...state,
        posts: payload,
        fetchingPosts: false,
      };
    case "FETCH_POSTS_FAILED":
      return {
        ...state,
        fetchingPosts: false,
      };
    case "FETCH_POST":
      return {
        ...state,
        fetchingPost: true,
      };
    case "FETCH_POST_SUCCESS":
      return {
        ...state,
        post: payload,
        fetchingPost: false,
      };
    case "FETCH_POST_FAILED":
      return {
        ...state,
        fetchingPost: false,
      };
    case "GET_POST_COMMENTS":


      if(state.post){
        state.post.noofcomments = action.payload.allcount +  action.payload.hiddencount ?? 0  + action.payload.opencount ?? 0
      }

      return {
        ...state,
        commentsObj: action.payload,
      };
    case "GET_POST_REPLIES":
      return {
        ...state,
        repliesObj: {
          ...state.repliesObj,
          [action.payload.commentId]: action.payload.data,
        },
      };
    case "CLEAR_POST_COMMENTS":
      return {
        ...state,
        commentsObj: {},
      };
    case "CLEAR_POST_COMMENTS":
      return {
        ...state,
        repliesObj: {},
      };
    default:
      return state;
  }
}
