import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import connect from "react-redux/es/connect/connect";
import withRouter from "react-router-dom/es/withRouter";
import { useHistory, Link } from "react-router-dom";
import bindActionCreators from "redux/es/bindActionCreators";
import Button from "@material-ui/core/Button";
import PersonIcon from "@material-ui/icons/Person";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import { openAvatarModal } from "../actions/appActions";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormControl";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PhotoSizeSelectLargeIcon from "@material-ui/icons/PhotoSizeSelectLarge";
import GridList from "@material-ui/core/GridList";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import { Paper, Tabs, Tab, AppBar } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { Loader } from "./UtilityComponents";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import getURL from "../helpers/apis";
import Carousel from "./GalleryComponent/Carousel";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getUserGalleryLikesByType,
  getUserPostLikesByType,
  getUserGalleryFavouritesByType,
  getGalleryLikesByUserId,
  getPostLikesByUserId,
  getUserWarningMessages,
  getGalleryFavouritesByUserId,
  getSharedToMe,
} from "../actions/applicantsDetailsActions";
import PostItem from "./PostsComponent/PostFeed/PostItem";
import PostMediaCarousel from "./PostsComponent/PostFeed/PostMediaCarousel";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: 10,
    // height: "95vh",
    // overflowY: "scroll",
  },
  actionIcon: {
    color: "rgba(255, 255, 255, 0.54)",
    position: "absolute",
    top: 0,
    right: 0,
    margin: "auto",
  },
  icons: {
    display: "inline-block",
    cursor: "pointer",
    height: "auto",
  },
  gridPaper: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    // justifyContent: "center",
    // overflow: "hidden",
    // backgroundColor: theme.palette.background.paper,
  },
  gridTile: {
    height: "100%",
    paddingLeft: "100%",
  },
  title: {
    marginBottom: theme.spacing(3),
    display: "flex",
    alignItems: "center",
  },
  empty: {
    marginTop: "300px",
    fontSize: "36px",
    fontWeight: "400",
    minWidth: "max-content",
  },
  tilebar: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  filter: {
    flexDirection: "row",
    justifyContent: "center",
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "8px",
  },
  field: {
    display: "flex",
    margin: 6,
    justifyContent: "flex-start",
  },
  card: {
    height: "100%",
    paddingLeft: "100%",
    // marginTop: 3,
    marginBottom: 3,
  },
  warningCard: {
    height: "100%",
    paddingLeft: "100%",
    marginRight: 6,
    marginBottom: 3,
  },
  warningPostCard: {
    width: "100%",
    margin: "10px 0px",
  },
}));

const ActedOnEntity = ({ match, actionName }) => {
  const action =
    match.params.action === "likes"
      ? "Like"
      : match.params.action === "favourites"
      ? "Favourite"
      : match.params.action === "sharedtome" || actionName === "sharedtome"
      ? "Shared To Me"
      : match.params.action === "warnings"
      ? "Warning"
      : "";
  const otherUser = match.params.user_id ?? match.params.applicant_id;
  const [hover, setHover] = useState(null);
  const [openGallery, setOpenGallery] = useState(null);
  const [gallery, setGallery] = useState(null);
  const [posts, setPosts] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [type, setType] = useState("Open");
  const [gender, setGender] = useState("All");
  const [allcount, setallcount] = useState(null);
  const [malecount, setmalecount] = useState(null);
  const [femalecount, setfemalecount] = useState(null);
  const [opencount, setopencount] = useState(null);
  const [hiddencount, sethiddencount] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [key, setKey] = useState("");
  const [value, setValue] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const limit = 10;
  const classes = useStyles();
  const dispatch = useDispatch();
  var history = useHistory();

  const rootUrl = getURL("UPLOADS");

  useEffect(() => {
    if (value === 0) {
      // setGallery(null);
      // setPage(1);
      if (!otherUser) {
        if (action === "Like") {
          let params = {
            likeType: type,
            gender: gender,
            page: page - 1,
            limit: limit,
          };
          getUserGalleryLikesByType(params).then((res) => {
            setGallery(res.data);
            setallcount(res.allcount);
            setmalecount(res.malecount);
            setfemalecount(res.femalecount);
            setopencount(res.opencount);
            sethiddencount(res.hiddencount);
            setTotalPages(res.totalPages);
          });
        } else if (action === "Favourite") {
          let params = {
            favouriteType: type,
            gender: gender,
            page: page - 1,
            limit: limit,
            key: key,
          };
          getUserGalleryFavouritesByType(params).then((res) => {
            setGallery(res.data);
            setallcount(res.allcount);
            setmalecount(res.malecount);
            setfemalecount(res.femalecount);
            setopencount(res.opencount);
            sethiddencount(res.hiddencount);
            setTotalPages(res.totalPages);
          });
        } else if (action === "Shared To Me") {
          let params = {
            gender: gender,
            page: page - 1,
            limit: limit,
            key: key,
          };
          getSharedToMe(params).then((res) => {
            setGallery(res.data);
            setallcount(res.allcount);
            setmalecount(res.malecount);
            setfemalecount(res.femalecount);
            setopencount(null);
            sethiddencount(null);
            setTotalPages(res.totalPages);
          });
        } else if (action === "Warning") {
          getUserWarningMessages().then((res) => {
            console.log(res);
            setGallery(res.data.galleryReports);
            setPosts(res.data.postReports);
            setTotalPages(1);
            setallcount(res.data.postReports.length);
          });
        }
      } else {
        if (action === "Like") {
          let params = {
            userId: otherUser,
            gender: gender,
            page: page - 1,
            limit: limit,
          };
          getGalleryLikesByUserId(params).then((res) => {
            setGallery(res.data);
            setallcount(res.allcount);
            setmalecount(res.malecount);
            setfemalecount(res.femalecount);
            setopencount(null);
            sethiddencount(null);
            setTotalPages(res.totalPages);
          });
        } else if (action === "Favourite") {
          let params = {
            userId: otherUser,
            gender: gender,
            page: page - 1,
            limit: limit,
            key: key,
          };
          getGalleryFavouritesByUserId(params).then((res) => {
            setGallery(res.data);
            setallcount(res.allcount);
            setmalecount(res.malecount);
            setfemalecount(res.femalecount);
            setopencount(null);
            sethiddencount(null);
            setTotalPages(res.totalPages);
          });
        } else if (action === "Shared To Me") {
          let params = {
            userId: otherUser,
            gender: gender,
            page: page - 1,
            limit: limit,
            key: key,
          };
          getSharedToMe(params).then((res) => {
            setGallery(res.data);
            setallcount(res.allcount);
            setmalecount(res.malecount);
            setfemalecount(res.femalecount);
            setopencount(null);
            sethiddencount(null);
            setTotalPages(res.totalPages);
          });
        }
      }
    } else if (action !== "Warning") {
      setPosts(null);
      setPage(1);
      if (!otherUser) {
        let params = {
          likeType: type,
          gender: gender,
          page: page - 1,
          limit: limit,
        };
        getUserPostLikesByType(params).then((res) => {
          setPosts(res.data);
          setallcount(res.allcount);
          setmalecount(res.malecount);
          setfemalecount(res.femalecount);
          setopencount(res.opencount);
          sethiddencount(res.hiddencount);
          setTotalPages(res.totalPages);
        });
      } else {
        let params = {
          userId: otherUser,
          gender: gender,
          page: page - 1,
          limit: limit,
        };
        getPostLikesByUserId(params).then((res) => {
          setPosts(res.data);
          setallcount(res.allcount);
          setmalecount(res.malecount);
          setfemalecount(res.femalecount);
          setopencount(null);
          sethiddencount(null);
          setTotalPages(res.totalPages);
        });
      }
    }
  }, [type, gender, action, key, value]);

  useEffect(() => {
    if (action !== "Warning") {
      if (page !== 1) {
        if (value === 0) {
          if (!otherUser) {
            if (action === "Like") {
              let params = {
                likeType: type,
                gender: gender,
                page: page - 1,
                limit: limit,
              };
              getUserGalleryLikesByType(params).then((res) => {
                gallery
                  ? setGallery([...gallery, ...res.data])
                  : setGallery(res.data);
                setallcount(res.allcount);
                setmalecount(res.malecount);
                setfemalecount(res.femalecount);
                setopencount(res.opencount);
                sethiddencount(res.hiddencount);
                setTotalPages(res.totalPages);
              });
            } else if (action === "Favourite") {
              let params = {
                favouriteType: type,
                gender: gender,
                page: page - 1,
                limit: limit,
                key: key,
              };
              getUserGalleryFavouritesByType(params).then((res) => {
                gallery
                  ? setGallery([...gallery, ...res.data])
                  : setGallery(res.data);
                setallcount(res.allcount);
                setmalecount(res.malecount);
                setfemalecount(res.femalecount);
                setopencount(res.opencount);
                sethiddencount(res.hiddencount);
                setTotalPages(res.totalPages);
              });
            } else if (action === "Shared To Me") {
              let params = {
                gender: gender,
                page: page - 1,
                limit: limit,
                key: key,
              };
              getSharedToMe(params).then((res) => {
                gallery
                  ? setGallery([...gallery, ...res.data])
                  : setGallery(res.data);
                setallcount(res.allcount);
                setmalecount(res.malecount);
                setfemalecount(res.femalecount);
                setopencount(null);
                sethiddencount(null);
                setTotalPages(res.totalPages);
              });
            }
          } else {
            if (action === "Like") {
              let params = {
                userId: otherUser,
                gender: gender,
                page: page - 1,
                limit: limit,
              };
              getGalleryLikesByUserId(params).then((res) => {
                gallery
                  ? setGallery([...gallery, ...res.data])
                  : setGallery(res.data);
                setallcount(res.allcount);
                setmalecount(res.malecount);
                setfemalecount(res.femalecount);
                setopencount(null);
                sethiddencount(null);
                setTotalPages(res.totalPages);
              });
            } else if (action === "Favourite") {
              let params = {
                userId: otherUser,
                gender: gender,
                page: page - 1,
                limit: limit,
                key: key,
              };
              getGalleryFavouritesByUserId(params).then((res) => {
                gallery
                  ? setGallery([...gallery, ...res.data])
                  : setGallery(res.data);
                setallcount(res.allcount);
                setmalecount(res.malecount);
                setfemalecount(res.femalecount);
                setopencount(null);
                sethiddencount(null);
                setTotalPages(res.totalPages);
              });
            } else if (action === "Shared To Me") {
              let params = {
                userId: otherUser,
                gender: gender,
                page: page - 1,
                limit: limit,
                key: key,
              };
              getSharedToMe(params).then((res) => {
                gallery
                  ? setGallery([...gallery, ...res.data])
                  : setGallery(res.data);
                setallcount(res.allcount);
                setmalecount(res.malecount);
                setfemalecount(res.femalecount);
                setopencount(null);
                sethiddencount(null);
                setTotalPages(res.totalPages);
              });
            }
          }
        } else {
          if (!otherUser) {
            let params = {
              likeType: type,
              gender: gender,
              page: page - 1,
              limit: limit,
            };
            getUserPostLikesByType(params).then((res) => {
              posts ? setPosts([...posts, ...res.data]) : setPosts(res.data);
              setallcount(res.allcount);
              setmalecount(res.malecount);
              setfemalecount(res.femalecount);
              setopencount(res.opencount);
              sethiddencount(res.hiddencount);
              setTotalPages(res.totalPages);
            });
          } else {
            let params = {
              userId: otherUser,
              gender: gender,
              page: page - 1,
              limit: limit,
            };
            getPostLikesByUserId(params).then((res) => {
              posts ? setPosts([...posts, ...res.data]) : setPosts(res.data);
              setallcount(res.allcount);
              setmalecount(res.malecount);
              setfemalecount(res.femalecount);
              setopencount(null);
              sethiddencount(null);
              setTotalPages(res.totalPages);
            });
          }
        }
      }
    }
  }, [page, value]);

  useEffect(() => {
    if (action !== "Warning") {
      setGallery(null);
      setPosts(null);
      setOpenGallery(null);
      setGender("All");
      setKey("");
      setPage(1);
      setTotalPages(0);
      setType("Open");
      setCurrentIndex(0);
      setallcount(null);
      setmalecount(null);
      setfemalecount(null);
      setopencount(null);
      sethiddencount(null);
    }
  }, [value]);

  // useEffect(() => {
  //   switch (gender) {
  //     case "All":
  //       setTotalPages(Math.ceil(allcount / limit));
  //       break;
  //     case "Male":
  //       setTotalPages(Math.ceil(malecount / limit));
  //       break;
  //     case "Female":
  //       setTotalPages(Math.ceil(femalecount / limit));
  //       break;
  //   }
  // }, [allcount, malecount, femalecount, gender]);

  const handleChangePageNumber = (event, value) => {
    setPage(value);
  };

  const fetchMoreData = () => {
    setPage(page + 1);
  };

  const handleChangeType = (type) => {
    setType(type);
    setPage(1);
    setKey("");
  };

  const handleChangeGender = (gender) => {
    setGender(gender);
    setPage(1);
    setKey("");
  };

  const renderFilterBtns = () => {
    const typeBtns = () => (
      <ButtonGroup
        size="small"
        color="primary"
        aria-label="type"
        name="type"
        style={{
          margin: "6px",
        }}
      >
        <Button
          style={{ textTransform: "none" }}
          onClick={(e) => handleChangeType("Open")}
          variant={type === "Open" ? "contained" : "outlined"}
        >
          Open{" - " + (type === "Open" ? allcount || 0 : opencount || 0)}
        </Button>

        <Button
          style={{ textTransform: "none" }}
          onClick={(e) => handleChangeType("Hidden")}
          variant={type === "Hidden" ? "contained" : "outlined"}
        >
          Hidden{" - " + (type === "Hidden" ? allcount || 0 : hiddencount || 0)}
        </Button>
      </ButtonGroup>
    );

    const genderBtns = () => (
      <ButtonGroup
        size="small"
        color="primary"
        aria-label="gender"
        name="gender"
        style={{
          margin: "6px",
        }}
      >
        <Button
          style={{ textTransform: "none" }}
          onClick={(e) => handleChangeGender("All")}
          variant={gender === "All" ? "contained" : "outlined"}
        >
          All{" - " + allcount}
        </Button>
        <Button
          style={{ textTransform: "none" }}
          onClick={(e) => handleChangeGender("Male")}
          variant={gender === "Male" ? "contained" : "outlined"}
        >
          M{" - " + malecount}
        </Button>
        <Button
          style={{ textTransform: "none" }}
          onClick={(e) => handleChangeGender("Female")}
          variant={gender === "Female" ? "contained" : "outlined"}
        >
          F{" - " + femalecount}
        </Button>
      </ButtonGroup>
    );
    return (
      <FormGroup size="large" className={classes.filter}>
        {!otherUser && action !== "Shared To Me" && typeBtns()}
        {!actionName && genderBtns()}
      </FormGroup>
    );
  };

  return (
    <Paper elevation={0} className={classes.root}>
      {value === 0 && openGallery && (
        <Carousel
          open={Boolean(openGallery)}
          handleClose={() => setOpenGallery(false)}
          galleries={gallery.map((i) => {
            const by =
              action === "Like"
                ? "likedToGallery"
                : action === "Shared To Me"
                ? "sharedId"
                : action == "Warning"
                ? "gallery_id"
                : "favouriteId";
            return i[by]._id;
          })}
          currentIndex={currentIndex}
          handleChangeIndex={(i) => setCurrentIndex(i)}
          // galleryType={type}
        />
      )}
      {action !== "Like" && action !== "Warning" && (
        <Grid
          container
          style={{ marginTop: 10 }}
          justify="space-evenly"
          alignItems="center"
          alignContent="center"
        >
          <Grid xs={2.5}>
            <Typography
              variant="h5"
              display="inline"
              style={{ margin: 12, textTransform: "capitalize" }}
              className={classes.title}
            >
              {action}
            </Typography>
          </Grid>

          <Grid xs={3}>
            <TextField
              // fullWidth
              autoFocus
              label="Search"
              // onKeyPress={(e) => {
              //   if (e.key === "Enter") {
              //     handleFilter();
              //   }
              // }}
              value={key}
              onChange={(e) => setKey(e.target.value)}
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={5}>
            {(gallery || posts) && action !== "Warning" && renderFilterBtns()}
          </Grid>
          {!actionName && (
            <Grid xs={1}>
              <Button
                color="secondary"
                variant="contained"
                size="small"
                onClick={(e) => {
                  !otherUser
                    ? history.push("/profile-me")
                    : history.push(`/user_profile/${otherUser}`);
                }}
                style={{ float: "right", margin: "8px", textTransform: "none" }}
              >
                Back
              </Button>
            </Grid>
          )}
        </Grid>
      )}

      {/* <div className={classes.pagination}>
          <Pagination
            count={totalPages}
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChangePageNumber}
          />
        </div> */}

      <Divider variant="middle" />

      {action === "Like" || action === "Warning" ? (
        <AppBar position="static" style={{ marginTop: 15 }} color="default">
          <Grid container alignItems="center">
            <Grid item alignItems="center" xs={3}>
              <Typography
                variant="h5"
                display="inline"
                style={{ textTransform: "capitalize", marginLeft: 10 }}
                // className={classes.title}
              >
                {action}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Tabs
                TabIndicatorProps={{ className: classes.tabIndicator }}
                value={value}
                onChange={(e, n) => setValue(n)}
                centered
                indicatorColor="primary"
                textColor="primary"
              >
                {/* <Tab
              label="Trending"
              disableRipple
              style={{ textTransform: "none" }}
            /> */}
                <Tab
                  label="Galleries"
                  disableRipple
                  style={{ textTransform: "none", padding: 0 }}
                />
                <Tab
                  label="Posts"
                  disableRipple
                  style={{ textTransform: "none", padding: 0 }}
                />
              </Tabs>
            </Grid>
            {!actionName && (
              <Grid xs={1}>
                <Button
                  color="secondary"
                  variant="contained"
                  size="small"
                  onClick={(e) => {
                    !otherUser
                      ? history.push("/profile-me")
                      : history.push(`/user_profile/${otherUser}`);
                  }}
                  style={{
                    float: "right",
                    textTransform: "none",
                  }}
                >
                  Back
                </Button>
              </Grid>
            )}
          </Grid>
        </AppBar>
      ) : null}
      <Grid
        container
        justify="center"
        alignItems="center"
        alignContent="center"
      >
        <Grid>
          {(gallery || posts) && action === "Like" && renderFilterBtns()}
        </Grid>
      </Grid>

      {value === 0 && (
        <>
          {gallery ? (
            gallery.length === 0 ? (
              <div className={classes.empty}>No Galleries found.</div>
            ) : (
              <div
                id="scrollableDiv"
                style={{ height: "80vh", overflowY: "scroll", padding: 6 }}
              >
                <InfiniteScroll
                  dataLength={gallery.length}
                  next={fetchMoreData}
                  scrollableTarget="scrollableDiv"
                  hasMore={Math.ceil(gallery.length / limit) < totalPages}
                  loader={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "100px",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  }
                >
                  <GridList
                    cellHeight="auto"
                    cols={4}
                    spacing={0}
                    className={classes.gridPaper}
                  >
                    {/* <GridListTile key="Subheader" cols={2} style={{ height: "auto" }}>
          <ListSubheader component="div">December</ListSubheader>
        </GridListTile> */}
                    {gallery &&
                      gallery.length > 0 &&
                      gallery.map((tile, index) => {
                        if (action === "Warning") {
                          return (
                            <Card
                              key={tile._id}
                              className={classes.warningCard}
                              // style={{ width: "23%" }}
                              // raised={true}
                              // square
                            >
                              <CardActionArea>
                                <CardMedia
                                  component="img"
                                  alt={tile.reason}
                                  // height="140"
                                  style={{ height: "14vw" }}
                                  image={`${process.env.REACT_APP_BUCKET_NAME}${
                                    tile["gallery_id"].galleryType == "photos"
                                      ? tile["gallery_id"].name
                                      : tile["gallery_id"].thumbnail
                                  }`}
                                  // title={
                                  //   tile[by].thumbnail
                                  //     ? tile[by].thumbnail
                                  //     : tile[by].name
                                  // }
                                />

                                <Typography style={{ padding: 2 }}>
                                  {tile.reason}
                                </Typography>
                              </CardActionArea>
                            </Card>
                          );
                        }

                        const by =
                          action === "Like"
                            ? "likedToGallery"
                            : action === "Shared To Me"
                            ? "sharedId"
                            : "favouriteId";
                        return (
                          <Card
                            key={tile[by]._id}
                            className={classes.card}
                            // style={{ width: "23%" }}
                            raised={true}
                            square
                            onMouseEnter={(e) => setHover(tile[by]._id)}
                            onMouseLeave={(e) => setHover("")}
                            onClick={() => {
                              setCurrentIndex(index);
                              setOpenGallery(tile[by]._id);
                            }}
                          >
                            <CardActionArea>
                              <CardMedia
                                component="img"
                                alt={tile.itemName}
                                // height="140"
                                style={{ height: "14vw" }}
                                image={
                                  tile[by].thumbnail
                                    ? process.env.REACT_APP_BUCKET_NAME +
                                      tile[by].thumbnail
                                    : process.env.REACT_APP_BUCKET_NAME +
                                      tile[by].name
                                }
                                // title={
                                //   tile[by].thumbnail
                                //     ? tile[by].thumbnail
                                //     : tile[by].name
                                // }
                              />
                              {/* <div
                          // title={
                          //   <Typography variant="h6">
                          //     {action === "Favourite" ? tile.favouriteName : ""}
                          //   </Typography>
                          // }
                          onClick={() => {
                            setCurrentIndex(index);
                            setOpenGallery(tile[by]._id);
                          }}
                          className={classes.tilebar}
                          style={{
                            display: hover === tile[by]._id ? "block" : "none",
                          }}
                          // subtitle={
                          //   <div>
                          //     <Typography variant="h5">
                          //       {action === "Favourite" ? tile.favouriteName : ""}
                          //     </Typography>
                          //   </div>
                          // }
                          actionIcon={
                            <IconButton
                              disableRipple
                              disableTouchRipple
                              disableFocusRipple
                              aria-label={`info about ${tile[by].galleryType}`}
                              className={classes.actionIcon}
                            >
                              {tile[by].galleryType === "videos" && (
                                <PlayArrowIcon
                                  className={classes.icons}
                                  fontSize="large"
                                />
                              )}
                              {tile[by].galleryType === "photos" && (
                                <PhotoSizeSelectLargeIcon
                                  className={classes.icons}
                                  fontSize="large"
                                />
                              )}
                            </IconButton>
                          }
                        > */}
                              <IconButton
                                disableRipple
                                disableTouchRipple
                                disableFocusRipple
                                aria-label={`info about ${tile[by].galleryType}`}
                                className={classes.actionIcon}
                              >
                                {tile[by].galleryType === "videos" && (
                                  <PlayArrowIcon
                                    className={classes.icons}
                                    fontSize="large"
                                  />
                                )}
                                {tile[by].galleryType === "photos" && (
                                  <PhotoSizeSelectLargeIcon
                                    className={classes.icons}
                                    fontSize="large"
                                  />
                                )}
                              </IconButton>
                              {/* </div> */}

                              <CardContent
                                style={{
                                  padding: 6,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {tile[by].userId[0].profilepic ? (
                                    <Avatar
                                      style={{
                                        width: 32,
                                        height: 32,
                                        marginRight: 4,
                                      }}
                                      alt="owner"
                                      src={
                                        process.env.REACT_APP_BUCKET_NAME +
                                        (tile[by].userId[0].profilepic ||
                                          tile.sharedByProfilePic)
                                      }
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch(
                                          openAvatarModal(
                                            process.env.REACT_APP_BUCKET_NAME +
                                              tile[by].userId[0].profilepic
                                          )
                                        );
                                      }}
                                    />
                                  ) : (
                                    <Avatar
                                      style={{
                                        width: 32,
                                        height: 32,
                                        marginRight: 4,
                                      }}
                                      alt="owner"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch(openAvatarModal());
                                      }}
                                    >
                                      <PersonIcon />
                                    </Avatar>
                                  )}
                                  <Typography
                                    variant="subtitle2"
                                    display="inline"
                                    style={{ marginRight: 4 }}
                                  >
                                    <Link
                                      to={`/user_profile/${tile[by].userId[0]._id}`}
                                    >
                                      {tile.user_follow_customname ||
                                        tile.username ||
                                        tile[by].userId[0]
                                          .user_follow_customname ||
                                        tile[by].userId[0].username}
                                    </Link>
                                  </Typography>
                                  •
                                  <Typography
                                    variant="caption"
                                    display="inline"
                                    style={{ marginLeft: 4 }}
                                  >
                                    {moment(tile.createdAt).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </Typography>
                                </div>

                                <Typography variant="h6">
                                  {action === "Favourite"
                                    ? tile.favouriteName
                                    : ""}
                                </Typography>
                                {/* 
                          <Typography variant="body1">
                            {tile[by].galleryQuotation || " "}
                          </Typography> */}
                              </CardContent>
                            </CardActionArea>
                            {/* <CardActions>
                      <Button size="small" color="primary">
                        Share
                      </Button>
                      <Button size="small" color="primary">
                        Learn More
                      </Button>
                    </CardActions> */}
                          </Card>
                          // <GridListTile
                          //   key={tile[by]._id}
                          //   className={classes.gridTile}
                          //   onMouseEnter={(e) => setHover(tile[by]._id)}
                          //   onMouseLeave={(e) => setHover("")}
                          // >
                          //   <Card raised={true} square>
                          //     <CardMedia
                          //       component="img"
                          //       alt="No Thumbnail found."
                          //       // height="330"
                          //       style={{ height: "14vw" }}
                          //       image={
                          //         tile[by].thumbnail
                          //           ? tile[by].thumbnail
                          //           : tile[by].name
                          //       }
                          //     />
                          //   </Card>
                          //
                          // </GridListTile>
                        );
                      })}
                  </GridList>
                </InfiniteScroll>
              </div>
            )
          ) : (
            <Loader />
          )}
        </>
      )}
      {value === 1 && (
        <>
          {posts ? (
            posts.length === 0 ? (
              <div className={classes.empty}>No Posts found.</div>
            ) : (
              <>
                {action == "Warning" ? (
                  <>
                    {posts.map((post) => (
                      <Card
                        key={post._id}
                        className={classes.warningPostCard}
                        // style={{ width: "23%" }}
                        // raised={true}
                        // square
                      >
                        <Typography style={{ padding: 2, paddingLeft: 10 }}>
                          {post.reason}
                        </Typography>
                        <CardActionArea>
                          <PostMediaCarousel
                            galleries={post["postDetails"].media}
                            postId={post["postDetails"]._id}
                          />
                        </CardActionArea>
                      </Card>
                    ))}
                  </>
                ) : (
                  <div
                    id="scrollableDiv"
                    style={{ height: "80vh", overflowY: "scroll", padding: 6 }}
                  >
                    <InfiniteScroll
                      dataLength={posts.length}
                      next={fetchMoreData}
                      scrollableTarget="scrollableDiv"
                      hasMore={Math.ceil(posts.length / limit) < totalPages}
                      loader={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            minHeight: "100px",
                          }}
                        >
                          <CircularProgress />
                        </div>
                      }
                    >
                      <Paper style={{ width: "100%" }} elevation={0}>
                        {posts &&
                          posts.length > 0 &&
                          posts.map((post) => (
                            <Paper
                              style={{
                                width: "100%",
                              }}
                              variant="outlined"
                              onMouseEnter={() => setHover(post._id)}
                              onMouseLeave={() => setHover(null)}
                            >
                              <PostItem
                                key={post._id}
                                origionalPost={post}
                                post={
                                  action == "Warning"
                                    ? post["postDetails"]
                                    : post.likedPost
                                }
                                hovered={hover === post._id}
                                handlePostsRefresh={() => setRefresh(!refresh)}
                              />
                            </Paper>
                          ))}
                      </Paper>
                    </InfiniteScroll>
                  </div>
                )}
              </>
            )
          ) : (
            <Loader />
          )}
        </>
      )}
    </Paper>
  );
};

export default withRouter(ActedOnEntity);
