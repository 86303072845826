const formEventReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_FORM_CONTENT":
      return action.content;
    case "FORM_COMMENT_CHANGED":
      return {
        ...state,
        comment: action.comment,
      };
    default:
      return state;
  }
};

const commentEventReducer = (comments, action) => {
  switch (action.type) {
    case "ASSIGN_COMMENT_EVENTS":
      return action.comments;

    case "ADD_COMMENT":
      return [...comments, action.comment];

    default:
      return comments;
  }
};

const commentReducer = (
  state = {
    commentsObj: {},
    repliesObj: {},
    formContent: {},
    gallerySettings: {},
  },
  action
) => {
  switch (action.type) {
    case "GET_COMMENTS":
      return {
        ...state,
        commentsObj: action.payload,
      };
    case "GET_REPLIES_FOR_COMMENT":
      return {
        ...state,
        repliesObj: {
          ...state.repliesObj,
          [action.payload.commentId]: action.payload.data,
        },
      };
    case "CLEAR_COMMENTS":
      return {
        ...state,
        commentsObj: {},
      };
    case "CLEAR_REPLIES":
      return {
        ...state,
        repliesObj: {},
      };
    case "GALLERY_SETTINGS":
      return {
        ...state,
        gallerySettings: action.payload,
      };
    default:
      return state;
  }
};

export default commentReducer;
