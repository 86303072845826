import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import ReactPlayer from "react-player";
import Draggable from "react-draggable";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import CardMedia from "@material-ui/core/CardMedia";
import { Avatar } from "@material-ui/core";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
export default function ImageDialog({ open, src, handleClose }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        // fullWidth
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogContent
          style={{
            padding: 0,
          }}
          dividers
        >
          <div
            style={{
              width: "500px",
              maxWidth: "95vw",
              maxHeight: "95vh",
              minWidth: "300px",
              minHeight: "300px",
            }}
          >
            <img
              src={
                src && src.startsWith("https")
                  ? src
                  : require("../assets/images/icons/avatar.png")
              }
              style={{ maxHeight: "100%", maxWidth: "100%", display: "block" }}
            />
          </div>
          {/* <Card elevation={0} style={{ height: 300 }}>
            <CardMedia
              component="img"
              alt="No Image found."
              // height={500}
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                display: "block",
              }}
              image={src}
            />
          </Card> */}
        </DialogContent>
      </Dialog>
    </div>
  );
}
