const initialState = {
  shareBids: [],
  fetchingShareBids: false,
  errors: [],
  purchaseDetailsLoading: true,
  purchaseDetails: null,

  addShareBidLoading: false,

  razorPaySuccessCaptureLoading: false,
  razorPayData: {},

  shareBidTransactions: {},
  shareBidTransactionsLoading: false,
};

const shareBidReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SHARE_BIDS": {
      return { ...state, fetchingShareBids: true };
    }
    case "GET_SHARE_BIDS_SUCCESS": {
      return {
        ...state,
        shareBids: action.payload,
        fetchingShareBids: false,
      };
    }
    case "GET_SHARE_BIDS_FAILED": {
      return { ...state, fetchingShareBids: false };
    }
    case "BID_ERROR":
      return {
        ...state,
        errors: action.payload,
      };
    case "CLEAR_BIDS":
      return {
        ...state,
        shareBids: [],
      };

    //GET PURCHASES INFORMATION
    case "GET_SHARE_BID_TRANSACTIONS":
      return {
        ...state,
        shareBidTransactionsLoading: true,
      };
    case "GET_SHARE_BID_TRANSACTIONS_SUCCESS":
      return {
        ...state,
        shareBidTransactionsLoading: false,
        shareBidTransactions: action.payload,
      };
    case "GET_SHARE_BID_TRANSACTIONS_FAILED":
      return {
        ...state,
        shareBidTransactionsLoading: false,
      };
    //GET PURCHASE INFORMATION
    case "GET_SHARE_BID_PURCHASE_INFORMATION":
      return {
        ...state,
        purchaseDetailsLoading: true,
      };
    case "GET_SHARE_BID_PURCHASE_INFORMATION_SUCCESS":
      return {
        ...state,
        purchaseDetailsLoading: false,
        purchaseDetails: action.payload,
      };
    case "GET_SHARE_BID_PURCHASE_INFORMATION_FAILED":
      return {
        ...state,
        purchaseDetailsLoading: false,
      };

    //USER ADD SUBSCRIPTIO PLAN

    case "USER_POST_SHARE_BID":
      return {
        ...state,
        addShareBidLoading: true,
      };
    case "USER_POST_SHARE_BID_SUCCESS":
      return {
        ...state,
        addShareBidLoading: false,
        addSubscriptionPlanDetails: action.payload,
      };
    case "USER_POST_SHARE_BID_FAILED":
      return {
        ...state,
        addShareBidLoading: false,
      };

    //RAZOR PAY CAPTURE CCAVENUE

    case "USER_SHARE_BID_RAZORPAY_PAYMENT_CAPTURE":
      return {
        ...state,
        razorPaySuccessCaptureLoading: true,
      };
    case "USER_SHARE_BID_RAZORPAY_PAYMENT_CAPTURE_SUCCESS":
      return {
        ...state,
        razorPaySuccessCaptureLoading: false,
        razorPayData: action.payload,
      };
    case "USER_SHARE_BID_RAZORPAY_PAYMENT_CAPTURE_FAILED":
      return {
        ...state,
        razorPaySuccessCaptureLoading: false,
      };

    default:
      return state;
  }
};

export default shareBidReducer;
