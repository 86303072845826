import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { withRouter } from "react-router-dom";

import bindActionCreators from "redux/es/bindActionCreators";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import Year from "./CalendarYearView";

import CalendarEditModal from "./CalendarEditModal";
import connect from "react-redux/es/connect/connect";
import {
  getCalendarEvents,
  onSelectSlot,
  onSelectEvent,
} from "../actions/calendarActions";
import { getUserDetails } from "../actions/appActions";
import { getUserProfileDetails } from "../actions/userDetailsAction";
import Link from "react-router-dom/es/Link";
import { Button } from "@material-ui/core";

const localizer = momentLocalizer(moment);
localizer.formats.yearHeaderFormat = "YYYY";

const Calenderia = (props) => {
  const [userInfo, setUserInfo] = useState(null);
  const [view, setView] = useState("month");
  const [date, setDate] = useState(new Date());

  const loggedInUser = props.userDetail._id;

  const user_id = props.user_id;
  useEffect(() => {
    props.getCalendarEvents(user_id);
    getUserInfo(user_id);
  }, [getCalendarEvents, props.currUser && props.currUser._id]);

  const getUserInfo = (user_id) => {
    props.getUserProfileDetails(user_id);
    if (props.currUser) {
      setUserInfo(props.currUser);
    }
  };
  const onSelectSlot = (e) => {
    if (loggedInUser && loggedInUser === user_id) {
      return props.onSelectSlot(e);
    }
  };
  const onSelectEvent = (e) => {
    return props.onSelectEvent(e);
  };

  return (
    <div style={{ width: "100%" }}>
      {/* {userInfo !== null && (
        <div className={"card postcd"}>
          <div className={"pull-right"}>
            <div onClick={props.history.goBack}>
              <i className={"fa fa-arrow-left circle-bg cross-bg"} />
            </div>
          </div>
          <div className={"col-md-12 heading-user-info"}>
            <span>User Detail:</span>
          </div>
          <div className={"col-md-4 display-flex"}>
            <div className={"label-user-info"}>Name:</div>
            <div className={"user-info"}>{userInfo.username}</div>
          </div>
          <div className={"col-md-4 display-flex"}>
            <div className={"label-user-info"}>Role:</div>
            <div className={"user-info"}>{userInfo && userInfo.mainrole}</div>
          </div>
          <div className={"col-md-4 display-flex"}>
            <div className={"label-user-info"}>DOB:</div>
            <div className={"user-info"}>
              {userInfo && userInfo.dob
                ? new Date(userInfo.dob).toLocaleDateString()
                : ""}
            </div>
          </div>
          <div className={"col-md-4 display-flex"}>
            <div className={"label-user-info"}>Address:</div>
            <div className={"user-info"}>
              {userInfo && userInfo.address ? userInfo.address : "Nill"}
            </div>
          </div>
          <div className={"col-md-4 display-flex"}>
            <div className={"label-user-info"}>Mobile:</div>
            <div className={"user-info"}>{userInfo.phone}</div>
          </div>
          <div className={"col-md-4 display-flex"}>
            <div className={"label-user-info"}>Email:</div>
            <div className={"user-info"}>{userInfo.email}</div>
          </div>
        </div>
      )} */}
      <div style={{ marginRight: 12, float: "right", display: "block" }}>
        {loggedInUser && loggedInUser === user_id && (
          <Button
            egde="end"
            variant="contained"
            color="primary"
            onClick={() =>
              onSelectEvent({
                title: "",
                desc: "",
              })
            }
            style={{ textTransform: "none" }}
          >
            Create Event
          </Button>
        )}
      </div>

      <CalendarEditModal user_id={user_id} />
      {props.calendarState.events && (
        <div
          className={"card postcd calendar"}
          style={{ width: "100%", display: "inline-block" }}
        >
          <Calendar
            localizer={localizer}
            events={props.calendarState.events}
            step={60}
            user_id={user_id}
            showMultiDayTimes
            selectable
            onSelectEvent={(e) => onSelectEvent(e)}
            onSelectSlot={(e) => onSelectSlot(e)}
            popup
            defaultDate={date}
            view={view}
            onView={(view, date) => {
              setDate(date);
              setView(view);
            }}
            views={{
              year: Year,
              month: true,
              week: true,
              day: true,
              agenda: true,
            }}
            messages={{ year: "Year" }}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    calendarState: state.calendarReducer,
    userDetail: state.userDetailsReducer.userDetail,
    currUser: state.usersReducer.currentUserProfile,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCalendarEvents,
      onSelectEvent,
      onSelectSlot,
      getUserProfileDetails,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(Calenderia));
