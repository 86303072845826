import React, { useState, useEffect } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import BidForm from "./BidForm";
import BidFeed from "./BidFeed";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// import Moment from "react-moment";

const Bid = ({ userDetail }) => {
  // if (userDetail && userDetail.userType === "FreeUser") {
  //   return <Redirect to="/" />;
  // }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justify="center">
          <Typography variant="h5" style={{ marginTop: 20 }}>
            Become A Partner
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <BidForm userDetail={userDetail} />
        <BidFeed />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetail: state.userDetailsReducer.userDetail,
  };
};

export default connect(mapStateToProps)(withRouter(Bid));
