const userDepartmentReducer = (
  state = {
    users: []
  },
  action
) => {
  switch (action.type) {
    case "USERS_FETCHED":
      return {
        ...state,
        users: action.users
      };
    default:
      return state;
  }
};

export default userDepartmentReducer;
