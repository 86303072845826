import React, { useState, useEffect } from "react";
import moment from "moment";
// import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as dates from "react-big-calendar/lib/utils/dates";
import { withRouter, Redirect } from "react-router-dom";
import { navigate } from "react-big-calendar/lib/utils/constants";

const createCalendar = (currentDate) => {
  if (!currentDate) {
    currentDate = moment();
  } else {
    currentDate = moment(currentDate);
  }

  const first = currentDate.clone().startOf("month");
  const last = currentDate.clone().endOf("month");
  const weeksCount = Math.ceil((first.day() + last.date()) / 7);
  const calendar = Object.assign([], { currentDate, first, last });

  for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
    const week = [];
    calendar.push(week);
    calendar.year = currentDate.year();
    calendar.month = currentDate.month();

    for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
      const date = currentDate.clone().set("date", day + 1 - first.day());
      date.calendar = calendar;
      week.push(date);
    }
  }

  return calendar;
};

const CalendarDate = (props) => {
  let { events, history } = props;

  const [amt, setAmt] = useState(false);
  const { dateToRender, dateOfMonth } = props;

  const today =
    dateToRender.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
      ? "today"
      : "";

  if (dateToRender.month() < dateOfMonth.month()) {
    return (
      <button disabled={true} className="date prev-month">
        {dateToRender.date()}
      </button>
    );
  }

  if (dateToRender.month() > dateOfMonth.month()) {
    return (
      <button disabled={true} className="date next-month">
        {dateToRender.date()}
      </button>
    );
  }

  return (
    <button
      className={`date in-month ${today}`}
      onClick={() => props.onClick(dateToRender)}
      onMouseEnter={(e) => setAmt(true)}
      onMouseLeave={(e) => setAmt(false)}
      // onClick={() => history.push("/bid/" + moment().format())}
    >
      {/* {!amt
        ? dateToRender.date()
        : events
            .filter(
              slot =>
                moment(slot.slotdate).format("YYYY-MM-DD") ===
                moment(dateToRender).format("YYYY-MM-DD")
            )
            .map(slot => {
              if (100000 > slot.amount > 1000) {
                var str = (slot.amount / 1000).toFixed(1) + "K";
                return str;
              }
              str = (slot.amount / 100000).toFixed(1) + "L";
              return str;
            })} */}
      <div>{dateToRender.date()}</div>
      <div style={{ color: "red" }}>
        {events
          .filter(
            (slot) =>
              moment(slot.slotdate).format("YYYY-MM-DD") ===
              moment(dateToRender).format("YYYY-MM-DD")
          )
          .map((slot) => {
            switch (true) {
              case 1000000 > slot.amount && slot.amount > 999:
                return (slot.amount / 1000).toFixed(1) + "K";
              case 1000000000 > slot.amount && slot.amount > 999999:
                return (slot.amount / 1000000).toFixed(1) + "M";
              case 1000000000000 > slot.amount && slot.amount > 999999999:
                return (slot.amount / 1000000000).toFixed(1) + "B";
              default:
                return slot.amount;
            }
          })}
      </div>
    </button>
  );
};

const Calendar = (props) => {
  let { date, events, history } = props;

  // let history = useHistory();

  const onDateClick = (date) => {
    moment().subtract("1", "d").isSameOrBefore(moment(date)) &&
      history.push("bid/" + moment(date).format());
  };
  const [state, setState] = useState({
    calendar: undefined,
  });

  useEffect(() => {
    setState({ calendar: createCalendar(date) });
  }, [date]);

  if (!state.calendar) {
    return null;
  }

  return (
    <div className="month">
      <div className="month-name">
        {state.calendar.currentDate.format("MMMM").toUpperCase()}
      </div>
      {["S", "M", "T", "W", "T", "F", "S"].map((day, index) => (
        <span key={index} className="day">
          {day}
        </span>
      ))}
      {state.calendar.map((week, index) => (
        <div key={index}>
          {week.map((date) => (
            <CalendarDate
              key={date.date()}
              dateToRender={date}
              dateOfMonth={state.calendar.currentDate}
              onClick={(date) => onDateClick(date)}
              events={events.filter(
                (slot) =>
                  moment(slot.slotdate).format("MM") ===
                  moment(date).format("MM")
              )}
              history={history}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

const Year = (props) => {
  let { date, calendarState, history } = props;
  Year.range(date);
  const months = [];
  const firstMonth = dates.startOf(date, "year");

  for (let i = 0; i < 12; i++) {
    months.push(
      <Calendar
        events={calendarState.filter(
          (slot) =>
            moment(slot.slotdate).format("YYYY") === moment(date).format("YYYY")
        )}
        key={i + 1}
        date={dates.add(firstMonth, i, "month")}
        history={history}
      />
    );
  }

  return <div className="year">{months.map((month) => month)}</div>;
};

Year.range = (date) => {
  return [dates.startOf(date, "year")];
};

Year.navigate = (date, action) => {
  switch (action) {
    case navigate.PREVIOUS:
      return dates.add(date, -1, "year");

    case navigate.NEXT:
      return dates.add(date, 1, "year");

    default:
      return date;
  }
};

Year.title = (date, { localizer }) =>
  localizer.format(date, "yearHeaderFormat");

const mapStateToProps = (state) => {
  return {
    calendarState: state.bidReducer.events,
  };
};

export default connect(mapStateToProps, null)(withRouter(Year));
