// const base_url = "";
// export const base_url = "https://dev.celebbook.in";
 export const base_url = "https://api.celebbook.in";
// const base_url = "https://vemsys.org";
// export const base_url = "http://localhost:3000";
// export const base_url = "http://68.183.246.3:3000";

const urlBank = {
  BASE_URL: "/",
  SIGN_IN: "/api/auth/signin",
  SIGN_UP: "/api/auth/signup",
  GET_USER_SETTINGS: "/api/user/getAll_User_Settings",
  CHANGE_SETTING: "/api/user/",
  PROFILE_PHOTO: "/api/user/gallery/photos",
  PROFILE_VIDEO: "/api/user/gallery/videos",
  GALLERY_UPLOAD: "/api/gallery",
  THUMBNAIL_UPLOAD: "/api/gallery/thumbnail/upload",
  UPDATE_PROFILE_PIC: "/api/user/uploadProfilePic",
  AD: "/api/advet",
  VIDEOS_LIST: "/api/videos",
  GET_APPLICANT: "/api/advet/applicant",
  APPLY_FOR_AD: "/api/advet/apply",
  CALENDAR_EVENT: "/api/calendar/events",
  COMMENT: "/api/user/comment",
  GET_COMMENTS: "/api/combine/onlycomments",
  GET_REPLIES_FOR_COMMENT: "/api/combine/repliesforcomment",
  POST_COMMENT: "/api/combine/commentreply",
  DELETE_COMMENT: "/api/combine/commentreply",
  GET_ALL_MY_COMMENTS: "/api/combine/mycommentsonly",
  GET_REPLIES_TO_MY_COMMENT: "/api/combine/repliesonmycomment",
  GET_GALLERY_SETTINGS: "/api/gallery/allGallerySettings",
  ALLOW_OPEN_COMMENTS: "/api/gallery/allowOpenComments",
  ALLOW_HIDDEN_COMMENTS: "/api/gallery/allowHiddenComments",
  WHO_CAN_OPEN_COMMENT: "/api/gallery/whoCanOpenComment",
  WHO_CAN_HIDDEN_COMMENT: "/api/gallery/whoCanHiddenComment",
  CHANGE_GALLERY_QUOTATION: "/api/gallery/changeGalleryQuotation",
  UPDATE_PROFILE: "/api/user/editProfile",
  GET_DEPARTMENT_AND_ROLE_WISE_USER: "/api/user/department/role",
  GET_ALL_CHAT_USERS: "/api/chat/getAllUser",
  FETCH_MESSAGED_USERS: "/api/chat/messagedUsers",
  FETCH_THREAD: "/api/chat",
  GALLERY_VIDEOS: "/api/videos/gallery",
  GET_GALLERY_DETAILS_BY_ID: "/api/gallery/info/",
  UPDATE_VIEWS: "/api/videos/gallery/view",
  GET_USER_INFO: "/api/user",
  FORGOT_PASSWORD: "/api/auth/forgotPassword",
  RESET_PASSWORD: "/api/resetPassword",
  CHANGE_PASSWORD: "/api/user/changePassword",
  VERIFY_EMAIL: "/api/verifyEmail",
  OTP_PHONE: "/api/auth/signin/otp/phone/send",
  OTP_PHONE_VERIFY: "/api/auth/signin/otp/phone/verify",
  RESEND_OTP: "/api/auth/signin/otp/phone/resend",
  RESEND_EMAIL: "/api/auth/signin/email/resend",
  GET_POSITIONS_BY_USER: "/api/position/user",
  GET_POSITIONS_BY_STATUS: "/api/position/user/posByStatus?",
  GET_POS_DETAILS: "/api/position",
  GET_ALL_EMP_POSITIONS: "/api/position",
  GET_APPLICANT: "/api/position/applicant/",
  APPLY_FOR_POS: "/api/position/apply",
  CONFIRM_ROLE: "/api/position/message",
  CANCEL_POSITION_APPLICATION: "/api/position/cancelApplication",
  SAVE_POSITION: "/api/position",
  GET_APPLIED_ON_POSITIONS_ALL: "/api/position/sortedappliedpositions/All",
  GET_APPLIED_ON_POSITIONS_OPEN: "/api/position/sortedappliedpositions/Open",
  GET_APPLIED_ON_POSITIONS_CLOSE: "/api/position/sortedappliedpositions/Closed",
  CHANGE_APPLICANT_STATUS: "/api/position",
  UPLOADS: "/uploads/",
  EDIT_CUSTOM_NAME: "/api/follow/addcustomname",
  FOLLOW: "/api/follow",
  UNFOLLOW: "/api/follow/unfollow",
  UNBLOCK: "/api/user/unblock",
  BLOCK: "/api/user/block",
  GET_USER_FOLLOWING: "/api/follow/following",
  GET_USER_FOLLOWERS: "/api/follow/followers",
  GET_BLOCKED_USERS: "/api/user/block",
  GET_PUBLIC: "/api/gallery/public",
  GET_PRIVATE: "/api/gallery/private",
  GET_FOLLOWING_GALLERY: "/api/gallery/getFollowingSortedGallery",
  GET_NONFOLLOWING_GALLERY: "/api/gallery/getNonFollowingSortedGallery",
  GET_DEPARTMENT: "/api/gallery/department",
  GET_SHAREABLE_USER: "/api/gallery/shareableuser",
  GET_SHARED_USER: "/api/gallery/sharedgallerytowhom",
  SHARE_PRIVATE_SHARE: "/api/gallery/private/share/",
  REMOVE_PRIVATE_SHARE: "/api/gallery/removeshare/",
  GET_FOLLOWING_BY_USER: "/api/follow/followingbyuserid",
  GET_FOLLOWERS_BY_USER: "/api/follow/followersbyuserid",
  GET_PROFILE_LIKES: "/api/user/profilelikes",
  GET_IS_USER_APPLIED_TO_POSITION: "/api/position/checkposition/",
  GET_ALL_USER_PROFILES: "/api/user/pagination",
  GET_USER_ACCESS_PAGES: "/api/user/getUserAccessPages",

  UPDATE_RECENT_SEARCH: "/api/search/updateRecentSearch",
  GET_RECENT_SEARCH: "/api/search/getRecentSearch",

  EXCEPTION_LIST: "/api/exceptionlist/",
  GET_ADDABLE_USERS: "/api/exceptionlist/addableUsers",

  DELETE_GALLERY: "/api/gallery",
  GET_USER_GALLERY_LIKES_BY_TYPE: "/api/like/getlikesbytype",
  GET_USER_POST_LIKES_BY_TYPE: "/api/posts/getlikesbytype",
  GET_USER_GALLERY_FAVES_BY_TYPE: "/api/favourite/getfavouritebytype",
  GET_ALL_GALLERIES_USER_LIKED: "/api/like/getalluserliked",
  GET_ALL_GALLERIES_USER_FAVED: "/api/favourite/getalluserfavourited",
  GET_GALLERY_LIKES_BY_USER_ID: "/api/like/getlikesbyuserid",
  GET_POST_LIKES_BY_USER_ID: "/api/posts/getlikesbyuserid",
  LIKE_PROFILE: "/api/like/profilelikes",
  UNLIKE_PROFILE: "/api/like/removeprofilelikes",
  GET_GALLERY_FAV_BY_USER_ID: "/api/favourite/getfavouritebyuserid",
  LIKE_GALLERY: "/api/like/gallerylikes",
  UNLIKE_GALLERY: "/api/like/removegallerylikes",
  FAVOURITE_GALLERY: "/api/favourite",
  UNFAVOURITE_GALLERY: "/api/favourite",
  GET_SHARED_TO_ME: "/api/gallery/sharedtome",
  SHARE_PUBLIC_GALLERY: "/api/gallery/sharegallery",
  GET_USERNAMES:"/api/global",
  GET_COUNTRIES: "/api/address/getcountry",
  GET_STATES: "/api/address/getState",
  GET_DISTRICTS: "/api/address/getDistrict",
  GET_INDUSTRIES: "/api/auth/industries",
  GET_USER_ADDRESS: "/api/user/getUserAddress",
  EMAIL_VALIDATE: "/api/user/emailValidated",
  COMPLETE_SIGNUP: "/api/user/completeSignUp",
  SEND_CONTACT: "/api/contactus",
  GET_USER_DETAILS: "/api/user/id?user_id=",
  GET_USER_DETAILS_BY_USERNAME: "/api/user/name?user_name=",
  ROLES: "/api/auth/roles",
  JOBTYPES: "/api/auth/jobtypes",
  GET_REPORT_REASONS: "/api/auth/reportreason",
  REPORT_ENTITY: "/api/user/report",
  REPORT_POST_ENTITY: "/api/postsreports/report",
  GET_USERS: "/api/user/getusers",
  GET_USERS_BY_KEYWORD: "/api/user/searchUser",
  GET_SHARED_TO_ME_BY_USER_ID: "/api/gallery/sharedToMebyUserId",
  GET_USER_WARNING_MESSAGES:"/api/user/getUserWarnedMessages/",
  FILTER_POSITIONS: "/api/position/filter",
  FILTER_VALUES: "/api/search",
  UPDATE_SEARCH: "/api/search/updatesearch",
  SEARCH_NAMES_BY_USER: "/api/search",
  CREATE_FILTER_SEARCH: "/api/search",
  GET_POSITIONS_BY_ID: "/api/position/",
  UPDATE_POS_DETAILS: "/api/position/updateposition",
  DELETE_POS: "/api/position/deletePosition?positionId=",
  CLONE_POSITIONS: "/api/position",
  CHANGE_POS_STATUS: "/api/position/changestatus",
  GET_JOB_SEEKERS_PROFILE: "/api/user/filter",
  GET_PRODUCT_FEATURES_LIST: "/api/product",
  GET_PRODUCT_FEATURES_BY_TYPE: "/api/product/usertype?userType=",
  GET_USER_SUBSCRIPTION: "/api/cart/subscription/list?status=",
  GET_UPGRADE_INFO: "/api/cart/subscription/upgradeinfo/",
  GET_PURCHASE_INFO: "/api/cart/subscription/purchaseInfo/",
  USER_CHOOSE_SUBSCRIPTION_PLAN: "/api/cart/subscription",
  USER_ADD_SUBSCRIPTION_TO_CART: "/api/cart/add",
  USER_ADD_DONATION_TO_CART: "/api/donation/add",
  USER_RAZORPAY_PAYMENT_CAPTURE_SUCCESS: "/api/cart/payment/ccavenue/success",

  POST_BID: "/api/bid/",
  GET_BIDS: "/api/bid/bids/",
  GET_BID_EVENTS: "/api/bid/bid-events",

  GET_FOLLOWING_POSTS: "/api/posts/followingPosts",
  GET_TRENDING_POSTS: "/api/posts/trendingPosts",
  GET_POST: "/api/posts/",
  GET_POSTS_BY_USERID: "/api/posts/userPosts",
  ADD_NEW_POST: "/api/posts/noMedia",
  DELETE_POST: "/api/posts/",
  UPDATE_POST: "/api/posts/",
  UPDATE_POST_WITH_GALLERIES: "/api/posts/",
  GET_POST_COMMENTS: "/api/posts/onlycomments",
  GET_POST_REPLIES: "/api/posts/repliesforcomment",
  POST_COMMENT_FOR_POST: "/api/posts/commentreply",
  DELETE_COMMENT_FOR_POST: "/api/posts/commentreply",
  GALLERY_REMOVE: "/api/posts/removeGallery",
  GET_POST_GALLERIES_FOR_UPDATE: "/api/posts/postMediaUpdate",
  LIKE_POST: "/api/posts/postLike",
  UNLIKE_POST: "/api/posts/removePostLike",
  GET_ALL_POSTS_USER_LIKED: "/api/posts/getalluserliked",
  SHARE_POST: "/api/posts/sharepost",

  // GET_GIFTS_LIST: "/api/gift/getAllGifts",
  // GET_CURRENT_GIFTS_AND_POINTS: "/api/point/getCurrentPointsAndGifts",
  // SEND_GIFT: "/api/point/transferPoints",
  // USER_POINTS_RAZORPAY_PAYMENT_CAPTURE_SUCCESS:
  //   "/api/pointspurchase/payment/success",
  // GET_POINTS_PURCHASE_INFO: "/api/pointspurchase/purchaseInfo/",
  // USER_BUY_POINTS: "/api/pointspurchase/add",
  // GET_GIFT_TRANSACTIONS: "/api/point/transactions",
  // GET_POINT_PURCHASES: "/api/pointspurchase/listPurchases",

  GET_ADSPACE_DETAILS: "/api/space/space",
  GET_ADSPACE_PURCHASE_DETAILS: "/api/space/purchaseInfo/",
  ADD_ADSPACE_TO_CART: "/api/space/add/",
  ADSPACE_RAZORPAY_PAYMENT_CAPTURE_SUCCESS:
    "/api/space/payment/ccavenue/success",
  GET_USER_ADSPACE: "/api/space/space/list?status=",
  GET_UNPROCESSED_ADVT: "/api/space/space/unprocessedAdvt",
  POST_ADGALLERIES: "/api/space/",
  GET_AD_GALLERIES_FOR_UPDATE: "/api/space/mediaUpdate",
  ADGALLERY_REMOVE: "/api/space/removeGallery",
  GET_ADS_BETWEEN_POSTS: "/api/space/active",
  GET_VIDEO_ADS: "/api/space/video/active",
  GET_SIDE_ADS: "/api/space/side/active",
  GET_FREE_SIDE_ADS: "/api/space/side/free/active",
  REGISTER_FOR_FREE_ADSPACE: "/api/space/free/",
  GET_USER_FREE_ADSPACE: "/api/space/free/list?status=",

  POST_SHARE_BID: "/api/buyShare/",
  GET_SHARE_BIDS: "/api/buyShare/bids/",
  USER_SHARE_BID_RAZORPAY_PAYMENT_CAPTURE_SUCCESS:
    "/api/buyShare/payment/success",
  GET_SHARE_BID_PURCHASE_INFO: "/api/buyShare/purchaseInfo/",
  USER_POST_SHARE_BID: "/api/buyShare/add/",
  GET_SHARE_BID_TRANSACTIONS: "/api/buyShare/listPurchases",
};

const getURL = (key) => {
  if (key && typeof urlBank[key] != "undefined") {
    return base_url + urlBank[key];
  } else {
    throw new Error("Url is absent from url bank.");
  }
};

export default getURL;
