import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function ConfirmationDialog({
  open,
  handleConfirm,
  handleCancel,
  title,
  description,
  negative = "No",
  positive = "Yes",
}) {
  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancel}
          style={{ textTransform: "none" }}
          color="secondary"
        >
          {negative}
        </Button>
        <Button
          onClick={() => handleConfirm()}
          autoFocus
          color="primary"
          style={{ textTransform: "none" }}
        >
          {positive}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
