import React from "react";
import ApplicantDetails from "../components/ApplicantDetails";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link as ReactDomLink } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import bindActionCreators from "redux/es/bindActionCreators";
import {
  isValidParam,
  getStringParam,
  getObjectParam,
  getArrayParam,
  getIntParam,
  getBooleanParam,
} from "../helpers/paramVerifier";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Pagination from "@material-ui/lab/Pagination";

import { Loader, NoRecords } from "../components/UtilityComponents";

const styles = (theme) => ({
  root: {},
  row: {
    marginLeft: 20,
    marginRight: 20,
  },
  card: {
    fontSize: 14,
    minWidth: 275,
    margin: 20,
    maxHeight: 300,
    maxWidth: 1200,
  },
  breadCumLink: {
    fontSize: 14,
  },
  pos: {
    fontSize: 14,
    marginBottom: 12,
  },
  root: {
    flexGrow: 1,
    padding: 10,
  },
  jobBodyRow: {
    fontSize: 14,
    marginTop: 10,
    marginBottom: 10,
  },
  jobBodyCol1: {
    width: "10%",
    fontSize: 14,
    fontWeight: 600,
    color: "#00000078",
  },
  jobBodyCol2: {
    width: "90%",
    fontSize: 14,
    fontWeight: 400,
    color: "#000000bd",
  },
  button: {
    fontSize: 14,
    marginLeft: 5,
    marginRigh: 5,
  },
  filterTabs: {
    minHeight: 40,
    marginLeft: 20,
    marginRigh: 20,
    marginBottom: 20,
  },
  filterTab: {
    minHeight: 40,
    fontSize: 14,
    textTransform: "capitalize",
    //color: '#ffffff'
    margin: "5px 5px 0px 5px",
    borderRadius: 4,
    border: "1px solid #00000042",
  },
  filterTabSelected: {
    borderBottom: "3px solid #4c7c8b",
  },
  pagination: {
    margin: "8px 0px",
    display: "flex",
    justifyContent: "center",
  },
});

class ViewFullApplicationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobseekerId: "",
      status: "",
      index: 0,
      applicant: null,
    };
  }

  componentWillMount() {
    let jobseekerId = getStringParam(this.props.match.params.jobseeker_id);
    let status = getStringParam(this.props.match.params.status);
    let index = getStringParam(this.props.match.params.index);
    this.state.jobseekerId = jobseekerId;
    this.state.status = status;
    this.state.index = index;

    let applicants = getArrayParam(this.props.applicants);
    let filteredApplicants = applicants.filter((f) => {
      return f.userInfo._id == jobseekerId;
    });
    this.state.applicant =
      filteredApplicants.length > 0 ? filteredApplicants[0] : null;
    this.setState({});
  }

  componentDidMount() {}

  // pageChange(index) {
  //   let applicants = getArrayParam(this.props.applicants);
  //   let filteredApplicants = applicants.filter((f) => {
  //     return f.status == this.state.status;
  //   });
  //   this.state.applicant =
  //     filteredApplicants.length > 0 ? filteredApplicants[index] : null;
  //   this.state.index = index;
  //   this.setState({});
  // }

  pageChange(index) {
    // setPage(index);
    // const selectedDataId = this.props.applicants[this.props.match.params.index]
    //   ._id;
    let updateUrlArray = this.props.history.location.pathname.split("/");
    updateUrlArray.pop();
    updateUrlArray.push(index);
    updateUrlArray = updateUrlArray.join("/");
    this.props.history.push(`${updateUrlArray}`);
  }

  render() {
    const { classes } = this.props;
    if (!isValidParam(this.state.applicant)) {
      return <Loader />;
    }
    return (
      <Grid container>
        <Grid item xs={12} className={classes.row}>
          <ApplicantBreadcrumbs
            key={this.state.jobseekerId}
            {...this.props}
            jobseekerId={this.state.jobseekerId}
            status={this.state.status}
            positionId={this.state.applicant.positionId}
          />
        </Grid>
        <Grid item xs={12} className={classes.row}>
          <ApplicantDetails
            key={"app-details-" + this.state.index}
            {...this.props}
            applicant={this.state.applicant}
            isDetailView={true}
          />
        </Grid>
        <Grid item xs={12} className={classes.row}>
          {/* <Paging
            status={this.state.status}
            index={this.state.index}
            {...this.props}
            pageChange={this.pageChange.bind(this)}
          /> */}
          <div className={classes.pagination}>
            <Pagination
              count={this.props.applicants.length}
              page={this.state.index + 1}
              onChange={(e, i) => this.pageChange(i)}
              color="secondary"
            />
          </div>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    positionsList: state.PosTableReducer.Pos,
    applicants: state.applicantsReducer.applicants,
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(ViewFullApplicationContainer));

class ApplicantBreadcrumbs extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  getUserName() {
    let userName = "";
    try {
      let applicants = getArrayParam(this.props.applicants);
      applicants = applicants.filter((f) => {
        return f.userInfo._id == this.props.jobseekerId;
      });
      let userInfo =
        applicants.length > 0
          ? getObjectParam(applicants[0].userInfo)
          : new Object();
      userName = getStringParam(userInfo.username);
    } catch (error) {}
    return userName;
  }

  render() {
    const { classes, status } = this.props;
    return (
      <Breadcrumbs
        aria-label="Breadcrumb"
        style={{ marginTop: 50, marginBottom: 20 }}
      >
        <ReactDomLink
          to={"/all-positions"}
          className={classes.breadCumLink}
          style={{ color: "#82707dd1" }}
        >
          All Positions
        </ReactDomLink>
        <Typography color="textPrimary" className={classes.breadCumLink}>
          <ReactDomLink
            to={"/position/applicants/" + this.props.positionId}
            style={{ color: "#82707dd1" }}
          >
            {this.props.positionsList.length &&
              this.props.positionsList[this.props.match.params.index].role}
          </ReactDomLink>
        </Typography>
        <Typography color="textPrimary" className={classes.breadCumLink}>
          {/* <Typography color="textPrimary" className={classes.breadCumLink} style={{color: '#82707dd1'}}>{status}</Typography> */}
          <ReactDomLink
            to={"/position/applicants/" + this.props.positionId + "/" + status}
            style={{ color: "#82707dd1" }}
          >
            {status}
          </ReactDomLink>
        </Typography>
        <Typography color="textPrimary" className={classes.breadCumLink}>
          {this.getUserName()}
        </Typography>
      </Breadcrumbs>
    );
  }
}

// class Paging extends React.Component {
//   constructor(props) {
//     super(props);
//   }

//   render() {
//     const { classes, status } = this.props;
//     let index = getIntParam(this.props.index);
//     let applicants = getArrayParam(this.props.applicants);
//     let filteredApplicants = applicants.filter((f) => {
//       return f.status == status;
//     });

//     let prevElement =
//       index + 1 > 1 ? (
//         <a href="javascript:void(0)">
//           {
//             <i
//               className="material-icons"
//               style={{ fontSize: 30 }}
//               onClick={() => this.props.pageChange(index - 1)}
//             >
//               keyboard_arrow_left
//             </i>
//           }
//         </a>
//       ) : (
//         ""
//       );
//     let nextElement =
//       index + 1 < filteredApplicants.length ? (
//         <a href="javascript:void(0)">
//           {
//             <i
//               className="material-icons"
//               style={{ fontSize: 30 }}
//               onClick={() => this.props.pageChange(index + 1)}
//             >
//               keyboard_arrow_right
//             </i>
//           }
//         </a>
//       ) : (
//         ""
//       );
//     return (
//       <Grid container style={{ position: "fixed", width: "auto", left: "48%" }}>
//         <Typography variant="span" color="textPrimary" style={{ width: 30 }}>
//           {" "}
//           {prevElement}{" "}
//         </Typography>
//         <Typography
//           variant="span"
//           color="textPrimary"
//           style={{ fontSize: 14, fontWeight: 600, paddingTop: 6 }}
//         >
//           {index + 1}&nbsp;/&nbsp;{filteredApplicants.length}
//         </Typography>
//         <Typography variant="span" color="textPrimary" style={{ width: 30 }}>
//           {nextElement}
//         </Typography>
//       </Grid>
//     );
//   }
// }
