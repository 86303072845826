import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Paper,
  Grid,
  TextField,
  Divider,
} from "@material-ui/core";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import PlayCircleOutlineSharpIcon from "@material-ui/icons/PlayCircleOutlineSharp";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useStyles from "./styles";
import { constants } from "../../../helpers/constants";
import getURL from "../../../helpers/apis";
import Settings from "./Settings";
import PostMediaUpload from "./PostMediaUpload";
import {
  addPost,
  getFollowingPosts,
  getTrendingPosts,
  getPostsByUserId,
} from "../../../actions/postActions";

const PostForm = ({ history, dispatch, match, user_id, value, userDetail }) => {
  const classes = useStyles();
  const [text, setText] = useState("");
  const [allowOpen, setAllowOpen] = useState(true);
  const [allowHidden, setAllowHidden] = useState(true);
  const [whoOpen, setWhoOpen] = useState("AnyOne");
  const [whoHidden, setWhoHidden] = useState("AnyOne");
  const [openSettings, setOpenSettings] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [currentPost, setCurrentPost] = useState(null);
  const [freeDays, setFreeDays] = useState(
    moment().diff(moment(userDetail && userDetail.createdAt), "days")
  );
  const rootUrl = getURL("UPLOADS");

  useEffect(() => {
    if (userDetail) {
      setFreeDays(moment().diff(moment(userDetail.createdAt), "days"));
    }
  }, [userDetail]);

  const handleSettingsSave = async (e) => {
    setOpenSettings(false);
    const params = {
      text,
      allowOpenComments: allowOpen,
      allowHiddenComments: allowHidden,
      whoCanOpenComment: whoOpen,
      whoCanHiddenComment: whoHidden,
    };
    await dispatch(addPost(params, history)).then((res) => {
      if (!res.data.error) {
        setCurrentPost(res.data);
        setOpenUpload(true);
      }
    });
  };

  const handleCloseUpload = async () => {
    setOpenUpload(false);
    setText("");
    value === 1
      ? dispatch(getPostsByUserId(user_id))
      : value === 0
      ? dispatch(getFollowingPosts())
      : dispatch(getTrendingPosts());
  };

  return (
    <Paper
      style={{
        padding: 6,
        width: "100%",
        paddingBottom: 0,
        paddingTop: 0,
      }}
      elevation={0}
      // variant="outlined"
    >
      {openSettings && (
        <Settings
          open={openSettings}
          handleClose={() => setOpenSettings(false)}
          handleSave={() => handleSettingsSave()}
          allowOpenComments={() => setAllowOpen()}
          allowHiddenComments={() => setAllowHidden()}
          changeWhoCanOpenComment={(who) => setWhoOpen(who)}
          changeWhoCanHiddenComment={(who) => setWhoHidden(who)}
        />
      )}
      {openUpload && (
        <PostMediaUpload
          open={openUpload}
          post={currentPost}
          handleClose={() => handleCloseUpload()}
          freeUser={
            userDetail.userType == "FreeUser"}
        />
      )}
      <Grid container justify="space-evenly" spacing={0}>
        {match.path === "/myPosts" && (
          <Grid item xs={12} align="center">
            <Typography variant="h6" display="inline">
              My Posts
            </Typography>
            <Divider variant="fullWidth" />
          </Grid>
        )}
        <Grid item xs={12} align="left">
          <Grid item xs={12} align="left">
            <Typography variant="subtitle2" display="inline">
              What would you like to say?
            </Typography>
          </Grid>
          <Grid item xs={12} align="left">
            <form
              className={classes.form}
              style={{ width: "100%" }}
              // onSubmit={onSubmit}
              className="inherit-bg-color"
            >
              <Grid container justify="space-around" spacing={0}>
                {/* <Grid item xs={1}>
              <Avatar
                alt={userDetail.name}
                className={classes.avatar}
                src={ userDetail.profilepic}
              />
            </Grid> */}
                <Grid item xs={12}>
                  <Grid
                    container
                    justify="space-around"
                    spacing={0}
                    alignContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={11}>
                      <TextField
                        variant="outlined"
                        required
                        autoFocus
                        multiline
                        size="small"
                        rows={2}
                        fullWidth
                        placeholder="Create a post"
                        value={text}
                        onChange={(e) =>
                          e.target.value.length <= 280 &&
                          setText(e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={1} align="right">
                      <Button
                        variant="contained"
                        // type="submit"
                        size="small"
                        onClick={() => setOpenSettings(true)}
                        className={classes.submit}
                        disabled={text.length < 1}
                        style={{ textTransform: "none" }}
                        color="primary"
                      >
                        Proceed
                      </Button>
                    </Grid>
                    {/* <Grid item xs={2} align="right">
                      {match.path !== "/myPosts" ? (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => history.push("/myPosts")}
                          className={classes.submit}
                          style={{ textTransform: "none" }}
                          color="primary"
                        >
                          <Typography>My Posts</Typography>
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => history.push("/posts")}
                          className={classes.submit}
                          style={{ textTransform: "none" }}
                          color="secondary"
                        >
                          Back
                        </Button>
                      )}
                    </Grid> */}
                  </Grid>

                  {/* <Grid container justify="space-evenly" spacing={0}>
                <Grid item xs={12} align="right">
                  <Button
                    variant="contained"
                    // type="submit"
                    size="small"
                    onClick={() => setOpenSettings(true)}
                    className={classes.submit}
                    disabled={text.length < 1}
                    style={{ textTransform: "none" }}
                    color="primary"
                  >
                    Proceed
                  </Button>
                </Grid>
              </Grid> */}
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
        {/* <Grid item xs={2} align="right" style={{ alignSelf: "center" }}>
          {match.path !== "/myPosts" && (
            <Button
              variant="contained"
              size="small"
              onClick={() => history.push("/myPosts")}
              className={classes.submit}
              style={{ textTransform: "none" }}
              color="primary"
            >
              <Typography>My Posts</Typography>
            </Button>
          )}
        </Grid> */}
      </Grid>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetail: state.userDetailsReducer.userDetail,
  };
};

export default connect(mapStateToProps)(withRouter(PostForm));
