import request from "../helpers/requestEngine";
import io from "socket.io-client";
import { getToken } from "../helpers/globalFunctions";
import { base_url } from "../helpers/apis";
import snackbar from "snackbar";

let socket;

export let chatEventTarget = new EventTarget();

// const base_url = "https://vemsys.org";
// const base_url = "http://68.183.93.199:5000";
// const base_url = "http://localhost:3000";
// const base_url = "https://cineoffer.com";

export const establishSocketConnection = () => {
  socket = io(base_url, {
    path: "/chat",
    transports: ["websocket"],
    query: {
      token: getToken(),
    },
  });

  initUser();

  socket.on("connect", () => {
    let incomingChatEvent = new CustomEvent("connected");
    chatEventTarget.dispatchEvent(incomingChatEvent);
    socket.emit("load");
  });

  socket.on("messagedUsers", function (data) {
    let incomingChatEvent = new CustomEvent("users", { detail: data });
    chatEventTarget.dispatchEvent(incomingChatEvent);
  });

  socket.on("updateUsersList", function (data) {
    let incomingChatEvent = new CustomEvent("updateUsers", { detail: data });
    chatEventTarget.dispatchEvent(incomingChatEvent);
  });

  socket.on("fetch", function (data) {
    let incomingChatEvent = new CustomEvent("thread", { detail: data });
    chatEventTarget.dispatchEvent(incomingChatEvent);
  });

  socket.on("chat", function (data) {
    let incomingChatEvent = new CustomEvent("newMessage", { detail: data });
    chatEventTarget.dispatchEvent(incomingChatEvent);
  });

  socket.on("unreadChat", function (data) {
    let incomingChatEvent = new CustomEvent("unread", { detail: data });
    chatEventTarget.dispatchEvent(incomingChatEvent);
  });

  socket.on("typing", function (data) {
    let incomingChatEvent = new CustomEvent("typing", { detail: data });
    chatEventTarget.dispatchEvent(incomingChatEvent);
  });

  socket.on("delete", function (data) {
    let incomingChatEvent = new CustomEvent("deleted", { detail: data });
    chatEventTarget.dispatchEvent(incomingChatEvent);
  });

  socket.on("online", function (data) {
    let incomingChatEvent = new CustomEvent("isOnline", { detail: data });
    chatEventTarget.dispatchEvent(incomingChatEvent);
  });

  socket.on("reconnect", (attemptNumber) => {
    snackbar.show("Connected.");
    initUser();
  });

  socket.on("reconnecting", (attemptNumber) => {
    snackbar.duration = 5000;
    snackbar.show("Waiting for connection...");
  });
};

export function disconnectSocket() {
  socket.close();
}

const initUser = () => {
  const userToken = getToken();
  socket.emit("connection", { token: userToken });
};

export const loadAllUsers = () => {
  socket.emit("load");
};

export const fetchThread = (params) => {
  socket.emit("chat", {
    type: "fetch",
    to: params.to,
    from: params.from,
    page: params.page,
    limit: params.limit,
  });
};

export const insertMessage = (params) => {
  socket.emit("chat", {
    type: "insert",
    to: params.to,
    from: params.from,
    message: params.message,
    timestamp: params.timestamp,
  });
};

export const setTyping = (params) => {
  socket.emit("chat", {
    type: "typing",
    to: params.to,
    from: params.from,
  });
};

export const deleteAllMessages = (params) => {
  socket.emit("chat", {
    type: "deleteAllMessages",
    to: params.to,
    from: params.from,
  });
};

export const deleteForMe = (params) => {
  socket.emit("chat", {
    type: "deleteForMe",
    to: params.to,
    from: params.from,
    chatId: params.idArray,
  });
};

export const deleteForAll = (params) => {
  socket.emit("chat", {
    type: "deleteForAll",
    to: params.to,
    from: params.from,
    chatId: params.idArray,
  });
};

export const isOnline = (params) => {
  socket.emit("chat", {
    type: "checkOnlineStatus",
    to: params.to,
    from: params.from,
  });
};

// function sendMessage(data) {
//   if (socket) {
//     socket.emit("chat", data);
//   }
// }

// export function onChatMessageSent(data) {
//   sendMessage(data);
// }

// export function onChatMessageRead(data) {
//   sendMessage(data);
// }

// export const getAllUsers = () => {
//   return request("get", "GET_ALL_CHAT_USERS", true).then(
//     (response) => {
//       return response.data.data;
//     },
//     (err) => {}
//   );
// };

// export const fetchMessagedUser = () => {
//   return request("get", "FETCH_MESSAGED_USERS", true).then(
//     (response) => {
//       return response.data;
//     },
//     (err) => {}
//   );
// };

// export const fetchThread = (_id) => {
//   return request(
//     "get",
//     "FETCH_THREAD",
//     true,
//     {},
//     {
//       with_user: _id,
//     }
//   ).then(
//     (response) => {
//       return response.data.data;
//     },
//     (err) => {
//       return [];
//     }
//   );
// };
