import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Typography,
  FormLabel,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Loader } from "../../components/UtilityComponents";
import { connect } from "react-redux";
import withRouter from "react-router-dom/es/withRouter";
import { getAdSpacePurchaseDetails } from "../../actions/adSpaceActions";
import { constants } from "../../helpers/constants";

const PurchaseDetails = ({
  open,
  handleClose,
  handleMakePayment,
  adSpaceId,
  purchaseDetails,
}) => {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getAdSpacePurchaseDetails(adSpaceId));
  // }, [adSpaceId]);

  // const purchaseDetails = useSelector(
  //   ({ adSpaceReducer }) => adSpaceReducer.purchaseDetails
  // );
  // const purchaseDetailsLoading = useSelector(
  //   ({ adSpaceReducer }) => adSpaceReducer.purchaseDetailsLoading
  // );

  if (purchaseDetails) {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className="inherit-bg-color"
      >
        <DialogTitle id="scroll-dialog-title">Purchase AdSpace</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={0}>
            <Grid item xs={8}>
              <Typography style={{ color: constants.PRIMARY_COLOR }}>
                Chosen Space
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {purchaseDetails && purchaseDetails.items[0].name}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ color: constants.PRIMARY_COLOR }}>
                Chosen Cycle
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {purchaseDetails && purchaseDetails.items[0].cycle}
              </Typography>
            </Grid>
            {/* <Grid item xs={8}>
              <Typography style={{ color: constants.PRIMARY_COLOR }}>
                Chosen Country
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {purchaseDetails && purchaseDetails.country}
              </Typography>
            </Grid> */}
            <Grid item xs={8}>
              <Typography style={{ color: constants.PRIMARY_COLOR }}>
                Price
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                Rs. {purchaseDetails && purchaseDetails.totalPrice}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ color: constants.PRIMARY_COLOR }}>
                SGST Percent
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {purchaseDetails && purchaseDetails.taxSGSTPercent}%
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ color: constants.PRIMARY_COLOR }}>
                CGST Percent
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {purchaseDetails && purchaseDetails.taxCGSTPercent}%
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ color: constants.PRIMARY_COLOR }}>
                SGST Amount
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                Rs. {purchaseDetails && purchaseDetails.taxCGSTAmount}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ color: constants.PRIMARY_COLOR }}>
                CGST Amount
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                Rs. {purchaseDetails && purchaseDetails.taxSGSTAmount}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ color: constants.PRIMARY_COLOR }}>
                Total Price After Tax
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                Rs. {purchaseDetails && purchaseDetails.totalPriceAfterTax}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            color="primary"
            style={{ textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleMakePayment}
            color="primary"
            style={{ textTransform: "none" }}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return (
    <div>
      <Loader />
    </div>
  );
};

export default PurchaseDetails;
