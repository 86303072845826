import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { requestCountries } from "../actions/registerActions";
import { contactDetailsSubmit } from "../actions/loginActions";
import { constants } from "../helpers/constants";

import moment from "moment";

const subjectArray = [
  { value: "Feedback", label: "Feedback" },
  { value: "Complaint", label: "Complaint" },
  { value: "Inquiry", label: "Inquiry" },
  { value: "Customer care", label: "Customer care" },
];

const useStyles = makeStyles((theme) => ({
  head: {
    marginBottom: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(3),
    // alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3),
    alignItems: "center",
  },
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

const ContactUs = (props) => {
  const { dispatch, countriesList, history } = props;
  const classes = useStyles();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [description, setDescription] = useState("");
  const [subject, setSubject] = useState("");
  const [country, setCountry] = useState("");
  useEffect(() => {
    dispatch(requestCountries());
  }, [dispatch]);

  const onSubmit = (e) => {
    e.preventDefault();

    const params = {
      country,
      firstname: firstName,
      lastname: lastName,
      email,
      mobile,
      description,
      subject,
    };

    dispatch(contactDetailsSubmit(params)).then((res) => {
      if (!res.data.error) {
        history.push("/login");
      }
    });
  };

  return (
    <Grid
      container
      style={{
        marginTop: 100,
      }}
      className={classes.paper}
      spacing={2}
      justify="space-evenly"
    >
      <Grid
        container
        xs={8}
        spacing={2}
        justify="space-evenly"
        alignContent="flex-start"
      >
        <Grid item xs={8}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            style={{ float: "right", textTransform: "none" }}
            onClick={() => history.push("/login")}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography component="h1" variant="h5" className={classes.head}>
            Contact Us
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <form
            className={classes.form}
            onSubmit={onSubmit}
            className="inherit-bg-color"
          >
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  First Name*
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  // variant="outlined"
                  required
                  fullWidth
                  // label="Amount"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Last Name*
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  // variant="outlined"
                  required
                  fullWidth
                  // label="Amount"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Email*
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  // variant="outlined"
                  required
                  fullWidth
                  // label="Amount"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Mobile*
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  // variant="outlined"
                  required
                  fullWidth
                  // label="Amount"
                  value={mobile}
                  onChange={(e) =>
                    (Number(e.target.value) || e.target.value === "") &&
                    e.target.value.length <= 10 &&
                    setMobile(e.target.value)
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Country*
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  options={countriesList}
                  getOptionLabel={(option) => option}
                  onChange={(e, object) => setCountry(object)}
                  value={country}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="Select Country"
                      // variant="outlined"
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Subject*
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  // variant="outlined"
                  required
                  // label="Type"
                  fullWidth
                  select
                  onChange={(e) => setSubject(e.target.value)}
                  value={subject}
                >
                  <MenuItem
                    style={{ fontSize: "1em" }}
                    key=""
                    value=""
                    disabled
                  >
                    Select Subject
                  </MenuItem>
                  {subjectArray.map((option) => {
                    return (
                      <MenuItem
                        style={{ fontSize: "1em" }}
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Description*
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  multiline
                  rows={4}
                  fullWidth
                  // label="Description"
                  value={description}
                  onChange={(e) =>
                    e.target.value.length < 150 &&
                    setDescription(e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} align="center">
                <Button
                  variant="contained"
                  type="submit"
                  size="large"
                  className={classes.submit}
                  style={{ textTransform: "none" }}
                  color="primary"
                >
                  Send
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  className={classes.submit}
                  style={{ textTransform: "none" }}
                  onClick={() => history.goBack()}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>

        <Grid item xs={3} style={{ textAlign: "center" }}>
          <Card className={classes.root}>
            <CardContent>
              <Typography variant="h5" component="h2">
                WE’D LOVE TO HEAR FROM YOU.
              </Typography>
              <Typography variant="body1" component="p">
                Email: support@celebbook.com
              </Typography>
              <Typography variant="body1" component="p">
                Hyderabad
              </Typography>
              <Typography variant="body1" component="p">
                Telangana
              </Typography>
              <Typography variant="body1" component="p">
                India
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    countriesList: state.registerReducer.countries
      ? state.registerReducer.countries.map((country) => country.country)
      : [],
  };
};

export default connect(mapStateToProps)(withRouter(ContactUs));
