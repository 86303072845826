import React from "react";
import { Button } from "../components/Buttons";
import { AddOnEmailInput, AddOnPasswordInput } from "../components/AddOnInputs";
import { AuGroup } from "../components/AuFormComponent";
import { resetPassword } from "../actions/loginActions";
import withRouter from "react-router-dom/es/withRouter";
import Link from "react-router-dom/es/Link";
import { connect } from "react-redux";

const buttonDesignClass = {
  className: "btn au-btn theme-resetPassword",
  value: "Change Password",
};

const formInputsTheme = {
  className: "theme-resetPassword",
};

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  onSubmitForm(e) {
    e.preventDefault();
    const q = this.props.match.params.q;
    const formData = {
      password: e.target.password.value,
      rePassword: e.target.rePassword.value,
      q: q,
    };
    this.props.dispatch(resetPassword(formData)).then((response) => {
      if (!response.error) {
        this.props.history.push("/login");
      }
    });
  }

  render() {
    return (
      <div className="au-frame au-text au-reset-frame">
        <div className="text-center">
          <div className="au-banner theme-resetPassword">
            <div className="au-bfg">
              <div>
                <img
                  src="/assets/images/icons/login-icon.png"
                  width="20px"
                  alt=""
                />
              </div>
              <div>
                <span>Create New Password</span>
              </div>
            </div>
          </div>
          <span>
            <i className="fa fa-3x fa-caret-down fa-reset-clr au-bcd" />
          </span>
        </div>
        <div className="au-card au-form">
          <form onSubmit={this.onSubmitForm} className="form-horizontal">
            <AuGroup>
              <AddOnPasswordInput
                formInputsTheme={formInputsTheme}
                placeholder="Password"
                className={"form-control"}
                name={"password"}
                required
              />
            </AuGroup>
            <AuGroup>
              <AddOnPasswordInput
                formInputsTheme={formInputsTheme}
                placeholder="Re-Enter Password"
                className={"form-control"}
                name={"rePassword"}
                required
              />
            </AuGroup>
            <div className="au-re text-center">
              <Button buttonDesign={buttonDesignClass} />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default connect(null)(withRouter(ResetPassword));
