import { sendHTTPRequest } from "../helpers/requestEngine";
import getUrl from "../helpers/apis";
import { getStringParam } from "../helpers/paramVerifier";
import request1 from "../helpers/request";
import getURL from "../helpers/apis";
import request from "../helpers/requestEngine";
import { openDialog, closeDialog } from "./appActions";

export const sharePost = (body) => {
  let url = getUrl("SHARE_POST");
  let promise = Promise.resolve(sendHTTPRequest("post", url, true, body));
  return promise;
};

export const getPostsUserLiked = (params) => {
  let url = getUrl("GET_ALL_POSTS_USER_LIKED");
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}, params));
  return promise;
};

export const likePost = (body) => {
  let url = getUrl("LIKE_POST");
  let promise = Promise.resolve(sendHTTPRequest("post", url, true, body));
  return promise;
};

export const unlikePost = (body) => {
  let url = getUrl("UNLIKE_POST");
  let promise = Promise.resolve(sendHTTPRequest("post", url, true, body));
  return promise;
};

//delete post

export const deletePost = (post_id, noAlert) => {
  return (dispatch) => {
    return request1("delete", getURL("DELETE_POST") + "/" + post_id, true).then(
      (response) => {
        dispatch(deletePostSuccess(response.data));
        !noAlert && dispatch(openDialog("Post Deleted!"));
        return response.data;
      },
      (err) => {
        !noAlert && dispatch(openDialog("Something went wrong."));
        dispatch(deletePostFailed(err));
        return err;
      }
    );
  };
};

function deletePostSuccess(data) {
  return {
    type: "DELETE_POST_SUCCESS",
  };
}

function deletePostFailed(err) {
  return {
    type: "DELETE_POST_FAILED",
  };
}

//addNew post

export const updatePost = (params, history) => {
  return (dispatch) => {
    return request1(
      "post",
      getURL("UPDATE_POST") + "/" + params.postId + "/noMedia",
      true,
      params
    ).then(
      (response) => {
        dispatch(updatePostSuccess(response.data));
        dispatch(openDialog("Post Updated!"));
        history.push("/posts");
        return response.data;
      },
      (err) => {
        dispatch(openDialog("Something went wrong."));
        dispatch(updatePostFailed(err));
        return err;
      }
    );
  };
};

function updatePostSuccess(data) {
  return {
    type: "UPDATE_POST_SUCCESS",
  };
}

function updatePostFailed(err) {
  return {
    type: "UPDATE_POST_FAILED",
  };
}

//addNew post

export const addPost = (params) => {
  return (dispatch) => {
    return request1("post", getURL("ADD_NEW_POST"), true, params).then(
      (response) => {
        dispatch(addPostSuccess(response.data));
        // dispatch(openDialog("Posted!"));
        return response.data;
      },
      (err) => {
        dispatch(openDialog(err.response.data.data || "Something went wrong."));
        dispatch(addPostFailed(err));
        return err.response;
      }
    );
  };
};

function addPostSuccess(data) {
  return {
    type: "ADD_NEW_POST_SUCCESS",
  };
}

function addPostFailed(err) {
  return {
    type: "ADD_NEW_POST_FAILED",
  };
}

//getPostsByUserId

export const getPostsByUserId = (user_id) => {
  return (dispatch) => {
    dispatch(fetchingPostsByUserId());

    return dispatch(fetchPostsByUserId(user_id));
  };
};

function fetchingPostsByUserId() {
  return {
    type: "FETCH_POSTS",
  };
}

function fetchPostsByUserId(user_id) {
  return (dispatch) => {
    request1("get", getURL("GET_POSTS_BY_USERID"), true, {}, { user_id }).then(
      (response) => {
        dispatch(fetchingPostsByUserIdSuccess(response.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));
        dispatch(fetchingPostsByUserIdFailed(err));
      }
    );
  };
}

function fetchingPostsByUserIdSuccess(data) {
  return {
    type: "FETCH_POSTS_SUCCESS",
    payload: data.data,
  };
}

function fetchingPostsByUserIdFailed(err) {
  return {
    type: "FETCH_POSTS_FAILED",
  };
}

export const getTrendingPosts = (params) => {
   return (dispatch) => {
    dispatch(fetchingPosts());

    return dispatch(fetchTrendingPosts(params));
  };
};

//getPosts

export const getFollowingPosts = (params) => {
  return (dispatch) => {
    dispatch(fetchingPosts());

    return dispatch(fetchFollowingPosts(params));
  };
};

function fetchingPosts() {
  return {
    type: "FETCH_POSTS",
  };
}

function fetchTrendingPosts(params) {
  return (dispatch) => {
    request1("get", getURL("GET_TRENDING_POSTS"), true, {}, params).then(
      (response) => {
        dispatch(fetchingPostsSuccess(response.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));
        dispatch(fetchingPostsFailed(err));
      }
    );
  };
}

function fetchFollowingPosts(params) {
  return (dispatch) => {
    request1("get", getURL("GET_FOLLOWING_POSTS"), true, {}, params).then(
      (response) => {
        dispatch(fetchingPostsSuccess(response.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));
        dispatch(fetchingPostsFailed(err));
      }
    );
  };
}

function fetchingPostsSuccess(data) {
  return {
    type: "FETCH_POSTS_SUCCESS",
    payload: data.data,
  };
}

function fetchingPostsFailed(err) {
  return {
    type: "FETCH_POSTS_FAILED",
  };
}

//getads between posts

export const getAdsBetweenPosts = (params) => {
  return (dispatch) => {
    dispatch(fetchingAdsBetweenPosts());

    return dispatch(fetchAdsBetweenPosts(params));
  };
};

function fetchingAdsBetweenPosts() {
  return {
    type: "FETCH_ADS_BETWEEN_POSTS",
  };
}

function fetchAdsBetweenPosts(params) {
  return (dispatch) => {
    request1("get", getURL("GET_ADS_BETWEEN_POSTS"), true, {}, params).then(
      (response) => {
        dispatch(fetchingAdsBetweenPostsSuccess(response.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));
        dispatch(fetchingAdsBetweenPostsFailed(err));
      }
    );
  };
}

function fetchingAdsBetweenPostsSuccess(data) {
  return {
    type: "FETCH_ADS_BETWEEN_POSTS_SUCCESS",
    payload: data.data,
  };
}

function fetchingAdsBetweenPostsFailed(err) {
  return {
    type: "FETCH_ADS_BETWEEN_POSTS_FAILED",
  };
}

//getPost

export const getPost = (id) => {
  return (dispatch) => {
    dispatch(fetchingPost());

    return dispatch(fetchPost(id));
  };
};

function fetchingPost() {
  return {
    type: "FETCH_POST",
  };
}

function fetchPost(id) {
  return (dispatch) => {
    request1("get", getURL("GET_POST") + "/" + id, true, {}).then(
      (response) => {
        dispatch(fetchingPostSuccess(response.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong."));
        dispatch(fetchingPostFailed(err));
      }
    );
  };
}

function fetchingPostSuccess(data) {
  return {
    type: "FETCH_POST_SUCCESS",
    payload: data.data,
  };
}

function fetchingPostFailed(err) {
  return {
    type: "FETCH_POST_FAILED",
  };
}

//comment actions

export const postComment = (body) => {
  return (dispatch) => {
    return request("post", "POST_COMMENT_FOR_POST", true, body).then(
      (response) => {
        dispatch(postingComment());
        return response.data;
      },
      (err) => {
        return err;
      }
    );
  };
};

function postingComment() {
  return {
    type: "POST_COMMENT_FOR_POST",
  };
}

export const deleteComment = (id) => {
  return (dispatch) => {
    return request("delete", "DELETE_COMMENT_FOR_POST", true, {
      comment_id: id,
    }).then(
      (response) => {
        dispatch(deletingComment());
      },
      (err) => {}
    );
  };
};

function deletingComment() {
  return {
    type: "DELETE_COMMENT_FOR_POST",
  };
}

export const getCommentsByPostId = (params) => {
  return fetchComments(params);
};

function fetchComments(params) {
  return (dispatch) => {
    request("get", "GET_POST_COMMENTS", true, null, params).then(
      (response) => {
        dispatch(fetchCommentsByPostId(response.data));
      },
      (err) => {}
    );
  };
}

function fetchCommentsByPostId(data) {
  return {
    payload: data,
    type: "GET_POST_COMMENTS",
  };
}

export const getRepliesByCommentId = (params) => {
  return fetchReplies(params);
};

function fetchReplies(params) {
  return (dispatch) => {
    request("get", "GET_POST_REPLIES", true, null, params).then(
      (response) => {
        dispatch(fetchRepliesByCommentId(response.data, params.comment_id));
      },
      (err) => {}
    );
  };
}

function fetchRepliesByCommentId(data, commentId) {
  return {
    payload: { data, commentId },
    type: "GET_POST_REPLIES",
  };
}

export const clearComments = () => {
  return (dispatch) => {
    dispatch(clearingComments());
  };
};

function clearingComments() {
  return {
    type: "CLEAR_POST_COMMENTS",
  };
}

export const clearReplies = () => {
  return (dispatch) => {
    dispatch(clearingReplies());
  };
};

function clearingReplies() {
  return {
    type: "CLEAR_POST_REPLIES",
  };
}
