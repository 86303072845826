import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { TextField, Link, Grid, Typography, Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";

class EmailVerifyMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
    };
  }

  render() {
    if (this.state.login) {
      return <Redirect to={"/login"} />;
    }
    return (
      <Grid container className="mui-center-grid">
        <Grid className="mui-inner-grid">
          <Typography variant="h4" className="heading-center">
            You need to verify your email id to proceed.
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={() => this.setState({ login: true })}
            style={{ textTransform: "none" }}
          >
            Login
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(EmailVerifyMessage);
