import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const CustomNameDialog = ({
  open,
  handleClose,
  handleSave,
  title,
  description,
  type,
  isEdit,
}) => {
  const [value, setValue] = useState(Boolean(isEdit) ? isEdit : "");
  const [followType, setFollowType] = useState("Open");
  const [err, setErr] = useState("");

  const handleTrySave = (value, type) => {
    if (value.length > 20) {
      setErr("Custom name can't be more than 20 characters.");
    } else if (value.startsWith("@")) {
      setErr("Custom name can't start with @.");
    } else handleSave(value, type);
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        {!type && !Boolean(isEdit) && (
          <FormControl component="fieldset">
            <FormLabel component="type">Follow Type</FormLabel>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={followType}
              onChange={(e) => setFollowType(e.target.value)}
            >
              <FormControlLabel value="Open" control={<Radio />} label="Open" />
              <FormControlLabel
                value="Hidden"
                control={<Radio />}
                label="Hidden"
              />
            </RadioGroup>
          </FormControl>
        )}
        <TextField
          autoFocus
          margin="dense"
          id="name"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          label="Custom Name"
          fullWidth
          error={err}
          helperText={Boolean(err) ? err : ""}
        />
      </DialogContent>
      <DialogActions>
        <Button
          style={{ textTransform: "none" }}
          onClick={handleClose}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleTrySave(value, type || followType)}
          color="primary"
          style={{ textTransform: "none" }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomNameDialog;
