import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import LocatinIcon from "@material-ui/icons/LocationOn";
import Pagination from "@material-ui/lab/Pagination";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import {
  getPosByUser,
  getPositionDetailsById,
  getFilteredPositionListByUser,
  applyForPos,
  cancelPosApplication,
} from "../../../src/actions/getPosActions";
import { getAppliedADs } from "../../../src/actions/getADsActions";
import { Link, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "../../helpers/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    marginTop: "15px",
    backgroundColor: theme.palette.background.paper,
  },
  positionTabHead: {
    display: "flex",
    textDecoration: "none",
  },
  appBar: {
    boxShadow: "none",
    margin: "16px 0px 0px",
  },
  tabs: {
    textColor: "white",
  },
  flexJustifyCenter: {
    display: "flex",
    justifyContent: "center",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  roleTitle: {
    margin: "8px 0px 0px",
  },
  roleTitleTypography: {
    fontWeight: "400",
  },
  applicantHead: {
    padding: "16px 16px 0px",
  },
  roleTypography: {
    margin: "8px 4px 0px",
  },
  breadcrumbSeparater: {
    margin: "0px 6px",
  },
  textFieldTypography: {
    margin: "8px 4px 0px",
    fontWeight: 400,
  },
  arrowBackButton: {
    marginRight: "8px",
  },
  separator: {
    margin: "0px 24px",
  },
  descriptionTypography: {
    border: "1px solid #ccc",
    borderRadius: "4px",
    padding: "8px 4px",
  },
  addressParent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  address: {
    border: "1px solid #ccc",
    borderRadius: "4px",
    padding: "8px 0px",
  },
  divider: {
    marginTop: "12px",
  },
  allDetailsFlexCol: {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
  },
  rowPositionDetails: {
    display: "flex",
  },
  labelParent: {
    minWidth: "10%",
  },
  addressMargin: {
    margin: "0px 4px ",
  },
  checkBoxTypography: {
    marginTop: "8px",
  },
  pagination: {
    margin: "8px 0px",
    display: "flex",
    justifyContent: "center",
  },
  editButton: {
    margin: "16px",
  },
}));

function AppliedViewDetails(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  //   const positionList = useSelector(
  //     ({ PosTableReducer }) => PosTableReducer.Pos
  //   );
  //   const userDetail = useSelector(
  //     ({ userDetailsReducer }) => userDetailsReducer.userDetail
  //   );

  const appliedAds = useSelector(
    ({ ADTableReducer }) => ADTableReducer.appliedAds || []
  );
  //   const applying = useSelector(
  //     ({ PosTableReducer }) => PosTableReducer.applying
  //   );

  const queryParamArrayLength = props.history.location.pathname.split("/")
    .length;
  const positionId = props.history.location.pathname.split("/")[
    queryParamArrayLength - 1
  ];
  //   const jobId = props.match.url.split("/applied-jobs/")[1].split("/view/")[0];
  const applying = useSelector(
    ({ PosTableReducer }) => PosTableReducer.applying
  );

  const currentDataIndex =
    appliedAds.length &&
    appliedAds.findIndex((job) => job.positionId._id === positionId);

  const empDetails = useSelector(
    ({ PosTableReducer }) => PosTableReducer.positionDetailsById
  );

  const currentPosition = useSelector(({ PosTableReducer }) =>
    PosTableReducer.positionDetailsById._id
      ? PosTableReducer.positionDetailsById
      : null
  );
  function handleApplyPosition(e, pos_id) {
    dispatch(applyForPos(pos_id));
  }
  //   const currentPosition =
  //     appliedAds.length &&
  //     appliedAds.find((job) => job.positionId._id === positionId);

  //   const positionIdArray = positionList.map((pos) => pos._id);

  //   const currentPositionIndex = positionIdArray.indexOf(positionId);

  //   const [page, setPage] = React.useState(1);

  useEffect(() => {
    dispatch(getAppliedADs("ALL"));
  }, []);
  //   useEffect(() => {
  //     jobId && dispatch(getAppliedADs(jobId));
  //   }, [jobId]);

  //   useEffect(() => {
  //     setPage(currentPositionIndex + 1);
  //   }, [currentPositionIndex]);

  const handleChangePage = (event, value) => {
    // setPage(value);
    const selectedDataId = appliedAds[value - 1].positionId._id;
    let updateUrlArray = props.history.location.pathname.split("/");
    updateUrlArray.pop();
    updateUrlArray.push(selectedDataId);
    updateUrlArray = updateUrlArray.join("/");
    props.history.push(`${updateUrlArray}`);
  };

  //   useEffect(() => {
  //     positionList && dispatch(getPosByUser(null, userDetail._id));
  //   }, [positionList.length]);

  useEffect(() => {
    dispatch(getPositionDetailsById(positionId));
  }, [positionId]);

  return (
    <div className={classes.root}>
      <div className={classes.applicantHead}>
        <div className={classes.flexCenter}>
          <Typography
            component={Link}
            role="button"
            to="/positions/"
            style={{ color: constants.PRIMARY_COLOR }}
            className={classes.positionTabHead}
            style={{ display: "flex", textDecoration: "none" }}
          >
            All Positions
          </Typography>
          <Typography
            color="inherit"
            variant="subtitle1"
            className={classes.breadcrumbSeparater}
          >
            /
          </Typography>
          <Typography
            component={Link}
            role="button"
            to={`/all-positions/${
              empDetails.postedBy && empDetails.postedBy._id
            }`}
            style={{ color: constants.PRIMARY_COLOR }}
            className={classes.positionTabHead}
            style={{ display: "flex", textDecoration: "none" }}
          >
            {empDetails.postedBy && empDetails.postedBy.bnr}
          </Typography>
          <Typography
            color="inherit"
            variant="subtitle1"
            className={classes.breadcrumbSeparater}
          >
            /
          </Typography>

          <Typography color="inherit" variant="subtitle1">
            {currentPosition && currentPosition.role}
          </Typography>
        </div>
        <Button
          color="secondary"
          variant="contained"
          size="small"
          onClick={(e) => {
            props.history.push(
              `/applied-jobs`
              // `/all-positions/${empDetails.postedBy && empDetails.postedBy._id}`
            );
          }}
          style={{ float: "right", margin: "8px", textTransform: "none" }}
        >
          Back
        </Button>
        <div className={classes.roleTitle}>
          <Typography
            color="inherit"
            variant="h5"
            className={classes.roleTitleTypography}
          >
            {currentPosition && currentPosition.role}
          </Typography>
        </div>
        <Divider className={classes.divider} />
      </div>
      <div style={{ margin: 24 }}>
        <Grid container spacing={6} justify="space-evenly">
          <Grid item xs={6} spacing={1} style={{ paddingBottom: 0 }}>
            <Grid container spacing={1}>
            <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Type
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {currentPosition && currentPosition.positionType}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Title
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {(currentPosition && currentPosition.title) || ""}
                </Typography>
              </Grid>       
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Role
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {currentPosition && currentPosition.role}
                </Typography>
              </Grid>
              {currentPosition &&
                currentPosition.role &&
                currentPosition.role.toLowerCase() === "actor" && (
                  <>
                    <Grid item xs={5}>
                      <Typography
                        style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                      >
                        Role Name
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography style={{ margin: 10 }}>
                        {currentPosition && currentPosition.otherrolename}
                      </Typography>
                    </Grid>
                  </>
                )}
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Gender
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {currentPosition && currentPosition.gender}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Compensation
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {currentPosition && currentPosition.compensation}
                </Typography>
              </Grid>
              {currentPosition &&
                currentPosition.compensation &&
                currentPosition.compensation.toLowerCase() === "paid" && (
                  <>
                    <Grid item xs={5}>
                      <Typography
                        style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                      >
                        Amount
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography style={{ margin: 10 }}>
                        {currentPosition && currentPosition.amount}
                      </Typography>
                    </Grid>
                  </>
                )}
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Age
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {currentPosition && currentPosition.ageMin} to{" "}
                  {currentPosition && currentPosition.ageMax}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  How Many?
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {currentPosition && currentPosition.noOfPositions}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Last Date
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {currentPosition &&
                    new Date(currentPosition.lastDate).toDateString()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} spacing={1} style={{ paddingBottom: 0 }}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  House no
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {(currentPosition && currentPosition.houseno) || ""}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Street
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {(currentPosition && currentPosition.street) || ""}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  City
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {(currentPosition && currentPosition.city) || ""}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  State
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {(currentPosition && currentPosition.state) || ""}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Country
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {(currentPosition && currentPosition.country) || ""}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Industry
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {(currentPosition && currentPosition.industry) || ""}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Pincode
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {(currentPosition && currentPosition.pincode) || ""}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Posted Date
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ margin: 10 }}>
                  {currentPosition &&
                    new Date(currentPosition.createdAt).toDateString()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {currentPosition &&
            !(
              !currentPosition.role ||
              currentPosition.role.toLowerCase() === "" ||
              currentPosition.role.toLowerCase() === "actor" ||
              currentPosition.role.toLowerCase() === "model" ||
              currentPosition.role.toLowerCase() === "audience" ||
              currentPosition.role.toLowerCase() === "people" 
            ) && (
              <>
                <Grid item xs={6}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Main role in user other roles can also apply?
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    className={classes.checkBoxTypography}
                    control={
                      <Checkbox
                        checked={
                          currentPosition && currentPosition.otherRolesCanApply
                        }
                        value="otherRolesCanApply"
                        color="secondary"
                        disabled={
                          !currentPosition.role ||
                          currentPosition.role.toLowerCase() === "" ||
                          currentPosition.role.toLowerCase() === "actor" ||
                          currentPosition.role.toLowerCase() === "model"
                        }
                      />
                    }
                    // label="Main role in user other roles can also apply?"
                  />
                </Grid>
              </>
            )}

          <Grid item xs={2} style={{ paddingTop: 0 }}>
            <Typography style={{ margin: 10, color: constants.PRIMARY_COLOR }}>
              Description
            </Typography>
          </Grid>
          <Grid item xs={10} style={{ paddingTop: 0 }}>
            <TextField
              id="description"
              name="description"
              margin="dense"
              type="text"
              multiline
              fullWidth
              rows={6}
              variant="outlined"
              disabled
              value={currentPosition && currentPosition.description}
            />
          </Grid>
        </Grid>
        <div className={classes.flexJustifyCenter}>
          {currentPosition &&
          currentPosition.positionStatus.toLowerCase() === "closed" ? (
            <Button
              variant="contained"
              color="secondary"
              disabled
              className={classes.editButton}
              style={{ textTransform: "none" }}
            >
              Closed
            </Button>
          ) : currentPosition &&
            currentPosition.status.toLowerCase() === "applied" ? (
            <Button
              variant="contained"
              color="secondary"
              className={classes.editButton}
              onClick={() =>
                dispatch(
                  cancelPosApplication(currentPosition._id, props.match.path)
                )
              }
              style={{ textTransform: "none" }}
            >
              Cancel Application
            </Button>
          ) : currentPosition &&
            currentPosition.status.toLowerCase() === "eligible" ? (
            applying ? (
              <Button
                variant="contained"
                color="primary"
                className={classes.editButton}
                style={{ opacity: "0.6" }}
                style={{ textTransform: "none" }}
              >
                Appling...
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                className={classes.editButton}
                onClick={(e) =>
                  handleApplyPosition(e, currentPosition && currentPosition._id)
                }
                style={{ textTransform: "none" }}
              >
                Apply
              </Button>
            )
          ) : (
            currentPosition &&
            currentPosition.status.toLowerCase() === "not eligible" && (
              <Grid
                container
                justify="center"
                alignContent="center"
                alignItems="center"
                direction="column"
              >
                <Grid item>
                  {currentPosition.reason.length > 0 &&
                    currentPosition.reason.map((res) => (
                      <Chip
                        color="secondary"
                        label={res}
                        variant="outlined"
                        size="small"
                        style={{ margin: 4 }}
                        icon={<NotInterestedIcon />}
                      />
                    ))}
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.editButton}
                    style={{ textTransform: "none" }}
                    disabled
                  >
                    Not Eligible
                  </Button>
                </Grid>
              </Grid>
            )
          )}
        </div>

        <div className={classes.pagination}>
          <Pagination
            count={appliedAds.length}
            page={currentDataIndex + 1}
            onChange={handleChangePage}
            color="secondary"
          />
        </div>
      </div>
    </div>
  );
}

export default withRouter(AppliedViewDetails);
