import { sendHTTPRequest } from "../helpers/requestEngine";
import getUrl from "../helpers/apis";
import { getStringParam } from "../helpers/paramVerifier";
import request1 from "../helpers/request";
import getURL from "../helpers/apis";
import request from "../helpers/requestEngine";
import { openDialog, closeDialog } from "./appActions";

//  GET PURCHASE INFORMATION

export const getPointPurchases = () => {
  return (dispatch) => {
    dispatch(fetchingPointPurchases());

    return dispatch(fetchPointPurchases());
  };
};

function fetchingPointPurchases() {
  return {
    type: "GET_POINT_PURCHASES",
  };
}

function fetchPointPurchases() {
  return (dispatch) => {
    request1("get", `${getURL("GET_POINT_PURCHASES")}`, true, {}, {}).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong.");
          dispatch(
            openDialog(
              response.data.data || "Something went wrong. Please try again."
            )
          );

          return dispatch(fetchingPointPurchasesFailed());
        }
        dispatch(fetchingPointPurchasesSuccess(response.data.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(fetchingPointPurchasesFailed(err));
      }
    );
  };
}

function fetchingPointPurchasesSuccess(data) {
  return {
    type: "GET_POINT_PURCHASES_SUCCESS",
    payload: data,
  };
}

function fetchingPointPurchasesFailed(err) {
  return {
    type: "GET_POINT_PURCHASES_FAILED",
  };
}
//  GET gift trans INFORMATION

export const getGiftTransactions = () => {
  return (dispatch) => {
    dispatch(fetchingGiftTransactions());

    return dispatch(fetchGiftTransactions());
  };
};

function fetchingGiftTransactions() {
  return {
    type: "GET_GIFT_TRANSACTIONS",
  };
}

function fetchGiftTransactions() {
  return (dispatch) => {
    request1("get", `${getURL("GET_GIFT_TRANSACTIONS")}`, true, {}, {}).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong.");
          dispatch(
            openDialog(
              response.data.data || "Something went wrong. Please try again."
            )
          );

          return dispatch(fetchingGiftTransactionsFailed());
        }
        dispatch(fetchingGiftTransactionsSuccess(response.data.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(fetchingGiftTransactionsFailed(err));
      }
    );
  };
}

function fetchingGiftTransactionsSuccess(data) {
  return {
    type: "GET_GIFT_TRANSACTIONS_SUCCESS",
    payload: data,
  };
}

function fetchingGiftTransactionsFailed(err) {
  return {
    type: "GET_GIFT_TRANSACTIONS_FAILED",
  };
}

//  GET PURCHASE INFORMATION

export const getPurchaseDetails = (points) => {
  return (dispatch) => {
    dispatch(fetchingPurchaseDetails());

    return dispatch(fetchPurchaseDetails(points));
  };
};

function fetchingPurchaseDetails() {
  return {
    type: "GET_POINTS_PURCHASE_INFORMATION",
  };
}

function fetchPurchaseDetails(points) {
  return (dispatch) => {
    request1(
      "get",
      `${getURL("GET_POINTS_PURCHASE_INFO")}${points}`,
      true,
      {},
      {}
    ).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong.");
          dispatch(
            openDialog(
              response.data.data || "Something went wrong. Please try again."
            )
          );

          return dispatch(fetchingPurchaseDetailsFailed());
        }
        dispatch(fetchingPurchaseDetailsSuccess(response.data.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(fetchingPurchaseDetailsFailed(err));
      }
    );
  };
}

function fetchingPurchaseDetailsSuccess(data) {
  return {
    type: "GET_POINTS_PURCHASE_INFORMATION_SUCCESS",
    payload: data,
  };
}

function fetchingPurchaseDetailsFailed(err) {
  return {
    type: "GET_POINTS_PURCHASE_INFORMATION_FAILED",
  };
}

export const getRazorPaySuccessCapture = (
  body,
  handleCallbackRazorPayCapture
) => {
  return (dispatch) => {
    dispatch(fetchingRazorPaySucessCapture());

    return dispatch(
      fetchRazorPaySuccessCapture(body, handleCallbackRazorPayCapture)
    );
  };
};

function fetchingRazorPaySucessCapture() {
  return {
    type: "USER_POINTS_RAZORPAY_PAYMENT_CAPTURE",
  };
}

function fetchRazorPaySuccessCapture(body, handleCallbackRazorPayCapture) {
  return (dispatch) => {
    request1(
      "post",
      `${getURL("USER_POINTS_RAZORPAY_PAYMENT_CAPTURE_SUCCESS")}`,
      true,
      body,
      {}
    ).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong.");
          dispatch(
            openDialog(
              response.data.data || "Something went wrong. Please try again."
            )
          );

          return dispatch(fetchingRazorPayCaptureFailed());
        }
        dispatch(fetchingRazorPayCaptureSuccess(response.data.data));
        handleCallbackRazorPayCapture();
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(fetchingRazorPayCaptureFailed(err));
      }
    );
  };
}

function fetchingRazorPayCaptureSuccess(data) {
  return {
    type: "USER_POINTS_RAZORPAY_PAYMENT_CAPTURE_SUCCESS",
    payload: data,
  };
}

function fetchingRazorPayCaptureFailed(err) {
  return {
    type: "USER_POINTS_RAZORPAY_PAYMENT_CAPTURE_FAILED",
  };
}

export const sendGift = (params) => {
  return (dispatch) => {
    return request1("post", getURL("SEND_GIFT"), true, params).then(
      (response) => {
        if (response.data.data) {
          dispatch(sendGiftSuccess(response.data));
          dispatch(openDialog("Gift Sent!"));
          return response.data;
        } else {
          dispatch(sendGiftFailed());
          dispatch(openDialog(response.data.msg));
          return response.data;
        }
      },
      (err) => {
        dispatch(openDialog("Something went wrong."));
        dispatch(sendGiftFailed(err));
        return err;
      }
    );
  };
};

function sendGiftSuccess(data) {
  return {
    type: "SEND_GIFT_SUCCESS",
  };
}

function sendGiftFailed(err) {
  return {
    type: "SEND_GIFT_FAILED",
  };
}

export const getGiftsList = (params) => {
  let url = getUrl("GET_GIFTS_LIST");
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}, params));
  return promise;
};

export const getCurrentPointsAndGifts = (params) => {
  let url = getUrl("GET_CURRENT_GIFTS_AND_POINTS");
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}, params));
  return promise;
};

// USER ADD SUBSCRIPTION PLAN

export const addPointsToBuy = (points, handleCallRazorPayMethod) => {
  return (dispatch) => {
    dispatch(addingPointsToBuy());

    return dispatch(addPointsToBuyToCart(points, handleCallRazorPayMethod));
  };
};

function addingPointsToBuy() {
  return {
    type: "USER_BUY_POINTS",
  };
}

function addPointsToBuyToCart(points, handleCallRazorPayMethod) {
  return (dispatch) => {
    request1(
      "get",
      `${getURL("USER_BUY_POINTS")}/${points}`,
      true,
      {},
      {}
    ).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong.");
          dispatch(
            openDialog(
              response.data.data || "Something went wrong. Please try again."
            )
          );

          return dispatch(addingPointsToBuyFailed());
        }
        dispatch(addingPointsToBuySuccess(response.data.data));
        handleCallRazorPayMethod(response.data.data);
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(addingPointsToBuyFailed(err));
      }
    );
  };
}

function addingPointsToBuySuccess(data) {
  return {
    type: "USER_BUY_POINTS_SUCCESS",
    payload: data,
  };
}

function addingPointsToBuyFailed(err) {
  return {
    type: "USER_BUY_POINTS_FAILED",
  };
}
