import { createAction } from "redux-actions";
import request from "../helpers/requestEngine";
import request1 from "../helpers/request";
import { sendHTTPRequest } from "../helpers/requestEngine";
import getURL from "../helpers/apis";
import snackbar from "snackbar";
import { openDialog, closeDialog } from "./appActions";
import { authenticateLoginCredentials } from "./loginActions";
import formurlencoded from "form-urlencoded";

// USER ADD ADSPACE

export const getUserFreeAdSpace = (status) => {
  return (dispatch) => {
    dispatch(fetchingUserFreeAdSpace());

    return dispatch(fetchUserFreeAdSpace(status));
  };
};

function fetchingUserFreeAdSpace() {
  return {
    type: "GET_USER_FREE_ADSPACE",
  };
}

export function incrementPostAdCounter() {
  return {
    type: "CURRENT_POST_VIDEO_AD_COUNTER",
  };
}


export function resetLastAdCounter(){
  return {
    type: "RESET_LAST_AD_COUNTER",
  };
}

export function incrLastVideo() {
  return {
    type: "INCR_LAST_AD_COUNTER",
  };
}

function fetchUserFreeAdSpace(status) {
  return (dispatch) => {
    request1(
      "get",
      `${getURL("GET_USER_FREE_ADSPACE")}${status}`,
      true,
      {},
      {}
    ).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong.");
          dispatch(
            openDialog(
              response.data.data || "Something went wrong. Please try again."
            )
          );

          return dispatch(fetchingUserFreeAdSpaceFailed());
        }
        dispatch(fetchingUserFreeAdSpaceSuccess(response.data.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(fetchingUserFreeAdSpaceFailed(err));
      }
    );
  };
}

function fetchingUserFreeAdSpaceSuccess(data) {
  return {
    type: "GET_USER_FREE_ADSPACE_SUCCESS",
    payload: data,
  };
}

function fetchingUserFreeAdSpaceFailed(err) {
  return {
    type: "GET_USER_FREE_ADSPACE_FAILED",
  };
}

export const registerForFreeAd = (body, handleCallback) => {
  return (dispatch) => {
    dispatch(registeringForFreeAd());

    return dispatch(registerForFreeAdspace(body, handleCallback));
  };
};

function registeringForFreeAd() {
  return {
    type: "REGISTER_FOR_FREE_ADSPACE",
  };
}

function registerForFreeAdspace(body, handleCallback) {
  return (dispatch) => {
    request1(
      "post",
      `${getURL("REGISTER_FOR_FREE_ADSPACE")}`,
      true,
      body,
      {}
    ).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong.");
          dispatch(
            openDialog(
              response.data.data || "Something went wrong. Please try again."
            )
          );

          return dispatch(registeringForFreeAdFailed());
        }
        dispatch(registeringForFreeAdSuccess(response.data.data));
        handleCallback();
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(registeringForFreeAdFailed(err));
      }
    );
  };
}

function registeringForFreeAdSuccess(data) {
  return {
    type: "REGISTER_FOR_FREE_ADSPACE_SUCCESS",
    payload: data,
  };
}

function registeringForFreeAdFailed(err) {
  return {
    type: "REGISTER_FOR_FREE_ADSPACE_FAILED",
  };
}

//  GET USER ACTIVE SUBSCRIPTION

export const setCycleAndAdSpace = (data) => {
  return (dispatch) => {
    return dispatch(settingCycleAndAdSpace(data));
  };
};

function settingCycleAndAdSpace(data) {
  return {
    type: "SET_CYCLE_AND_ADSPACE",
    payload: data,
  };
}
//  GET USER chosen SUBSCRIPTION

export const getUnprocessedAdvt = (status) => {
  return (dispatch) => {
    dispatch(fetchingUnprocessedAdvt());

    return dispatch(fetchUnprocessedAdvt(status));
  };
};

function fetchingUnprocessedAdvt() {
  return {
    type: "GET_UNPROCESSED_ADVT",
  };
}

function fetchUnprocessedAdvt() {
  return (dispatch) => {
    request1("get", `${getURL("GET_UNPROCESSED_ADVT")}`, true, {}, {}).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong.");
          dispatch(
            openDialog(
              response.data.data || "Something went wrong. Please try again."
            )
          );

          return dispatch(fetchingUnprocessedAdvtFailed());
        }
        dispatch(fetchingUnprocessedAdvtSuccess(response.data.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(fetchingUnprocessedAdvtFailed(err));
      }
    );
  };
}

function fetchingUnprocessedAdvtSuccess(data) {
  return {
    type: "GET_UNPROCESSED_ADVT_SUCCESS",
    payload: data,
  };
}

function fetchingUnprocessedAdvtFailed(err) {
  return {
    type: "GET_UNPROCESSED_ADVT_FAILED",
  };
}
//  GET USER ACTIVE SUBSCRIPTION

export const getUserAdSpace = (status) => {
  return (dispatch) => {
    dispatch(fetchingUserAdSpace());

    return dispatch(fetchUserAdSpace(status));
  };
};

function fetchingUserAdSpace() {
  return {
    type: "GET_USER_ADSPACE",
  };
}

function fetchUserAdSpace(status) {
  return (dispatch) => {
    request1(
      "get",
      `${getURL("GET_USER_ADSPACE")}${status}`,
      true,
      {},
      {}
    ).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong.");
          dispatch(
            openDialog(
              response.data.data || "Something went wrong. Please try again."
            )
          );

          return dispatch(fetchingUserAdSpaceFailed());
        }
        dispatch(fetchingUserAdSpaceSuccess(response.data.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(fetchingUserAdSpaceFailed(err));
      }
    );
  };
}

function fetchingUserAdSpaceSuccess(data) {
  return {
    type: "GET_USER_ADSPACE_SUCCESS",
    payload: data,
  };
}

function fetchingUserAdSpaceFailed(err) {
  return {
    type: "GET_USER_ADSPACE_FAILED",
  };
}

//  GET_ADSPACE_DETAILS

export const getAdSpaceDetails = (data) => {
  return (dispatch) => {
    dispatch(fetchingAdSpaceDetails());

    return dispatch(fetchAdSpaceDetails(data));
  };
};

function fetchingAdSpaceDetails() {
  return {
    type: "GET_ADSPACE_DETAILS",
  };
}

function fetchAdSpaceDetails(data) {
  return (dispatch) => {
    request1("get", getURL("GET_ADSPACE_DETAILS"), true, {}, data).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong.");
          dispatch(
            openDialog(
              response.data.data || "Something went wrong. Please try again."
            )
          );

          return dispatch(fetchingAdSpaceDetailsFailed());
        }
        dispatch(fetchingAdSpaceDetailsSuccess(response.data.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(fetchingAdSpaceDetailsFailed(err));
      }
    );
  };
}

function fetchingAdSpaceDetailsSuccess(data) {
  return {
    type: "GET_ADSPACE_DETAILS_SUCCESS",
    payload: data,
  };
}

function fetchingAdSpaceDetailsFailed(err) {
  return {
    type: "GET_ADSPACE_DETAILS_FAILED",
  };
}

//  GET PURCHASE INFORMATION

export const getAdSpacePurchaseDetails = (adSpaceId) => {
  return (dispatch) => {
    dispatch(fetchingAdSpacePurchaseDetails());

    return dispatch(fetchAdSpacePurchaseDetails(adSpaceId));
  };
};

function fetchingAdSpacePurchaseDetails() {
  return {
    type: "GET_ADSPACE_PURCHASE_DETAILS",
  };
}

function fetchAdSpacePurchaseDetails(adSpaceId) {
  return (dispatch) => {
    request1(
      "get",
      `${getURL("GET_ADSPACE_PURCHASE_DETAILS")}${adSpaceId}`,
      true,
      {},
      {}
    ).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong.");
          dispatch(
            openDialog(
              response.data.data || "Something went wrong. Please try again."
            )
          );

          return dispatch(fetchingAdSpacePurchaseDetailsFailed());
        }
        dispatch(fetchingAdSpacePurchaseDetailsSuccess(response.data.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(fetchingAdSpacePurchaseDetailsFailed(err));
      }
    );
  };
}

function fetchingAdSpacePurchaseDetailsSuccess(data) {
  return {
    type: "GET_ADSPACE_PURCHASE_DETAILS_SUCCESS",
    payload: data,
  };
}

function fetchingAdSpacePurchaseDetailsFailed(err) {
  return {
    type: "GET_ADSPACE_PURCHASE_DETAILS_FAILED",
  };
}

// USER ADD ADSPACE

export const addAdSpace = (adSpaceId, handleCallBack) => {
  return (dispatch) => {
    dispatch(addingAdSpace());

    return dispatch(addAdSpaceToCart(adSpaceId, handleCallBack));
  };
};

function addingAdSpace() {
  return {
    type: "ADD_ADSPACE_TO_CART",
  };
}

function addAdSpaceToCart(adSpaceId, handleCallBack) {
  return (dispatch) => {
    request1(
      "get",
      `${getURL("ADD_ADSPACE_TO_CART")}${adSpaceId}`,
      true,
      {},
      {}
    ).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong.");
          dispatch(
            openDialog(
              response.data.data || "Something went wrong. Please try again."
            )
          );

          return dispatch(addingAdSpaceFailed());
        }
        dispatch(addingAdSpaceSuccess(response.data.data));
        handleCallBack && handleCallBack(true);
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(addingAdSpaceFailed(err));
      }
    );
  };
}

function addingAdSpaceSuccess(data) {
  return {
    type: "ADD_ADSPACE_TO_CART_SUCCESS",
    payload: data,
  };
}

function addingAdSpaceFailed(err) {
  return {
    type: "ADD_ADSPACE_TO_CART_FAILED",
  };
}

export const getRazorPaySuccessCapture = (
  body,
  handleCallbackRazorPayCapture
) => {
  return (dispatch) => {
    dispatch(fetchingRazorPaySucessCapture());

    return dispatch(
      fetchRazorPaySuccessCapture(body, handleCallbackRazorPayCapture)
    );
  };
};

function fetchingRazorPaySucessCapture() {
  return {
    type: "ADSPACE_RAZORPAY_PAYMENT_CAPTURE",
  };
}

function fetchRazorPaySuccessCapture(body, handleCallbackRazorPayCapture) {
  return (dispatch) => {
    request1(
      "post",
      `${getURL("ADSPACE_RAZORPAY_PAYMENT_CAPTURE_SUCCESS")}`,
      true,
      body,
      {}
    ).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong.");
          dispatch(
            openDialog(
              response.data.data || "Something went wrong. Please try again."
            )
          );

          return dispatch(fetchingRazorPayCaptureFailed());
        }
        dispatch(fetchingRazorPayCaptureSuccess(response.data.data));
        handleCallbackRazorPayCapture();
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(fetchingRazorPayCaptureFailed(err));
      }
    );
  };
}

function fetchingRazorPayCaptureSuccess(data) {
  return {
    type: "ADSPACE_RAZORPAY_PAYMENT_CAPTURE_SUCCESS",
    payload: data,
  };
}

function fetchingRazorPayCaptureFailed(err) {
  return {
    type: "ADSPACE_RAZORPAY_PAYMENT_CAPTURE_FAILED",
  };
}

// //   USER CHOOSE SUBSCRIPTION PLAN

// export const userChooseAdSpace = (cycle, name, userType) => {
//   return (dispatch) => {
//     dispatch(choosingAdSpace);

//     return dispatch(chooseAdSpace(cycle, name, userType));
//   };
// };

// function choosingAdSpace() {
//   return {
//     type: "USER_CHOOSE_SUBSCRIPTION_PLAN",
//   };
// }

// function chooseAdSpace(cycle, name, userType) {
//   return (dispatch) => {
//     request1(
//       "get",

//       `${getURL(
//         "USER_CHOOSE_SUBSCRIPTION_PLAN"
//       )}?cycle=${cycle}&name=${name}&userType=${userType}`,
//       true,
//       {},
//       {}
//     ).then(
//       (response) => {
//         if (response.data.error) {
//           // snackbar.show("Something went wrong.");
//           dispatch(
//             openDialog(
//               response.data.data || "Something went wrong. Please try again."
//             )
//           );

//           return dispatch(choosingAdSpaceFailed());
//         }
//         dispatch(choosingAdSpaceSuccess(response.data.data));
//       },
//       (err) => {
//         // snackbar.show("Something went wrong.");
//         dispatch(openDialog("Something went wrong. Please try again."));

//         dispatch(choosingAdSpaceFailed(err));
//       }
//     );
//   };
// }

// function choosingAdSpaceSuccess(data) {
//   return {
//     type: "USER_CHOOSE_SUBSCRIPTION_PLAN_SUCCESS",
//     payload: data,
//   };
// }

// function choosingAdSpaceFailed(err) {
//   return {
//     type: "USER_CHOOSE_SUBSCRIPTION_PLAN_FAILED",
//   };
// }
