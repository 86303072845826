import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "react-router-dom/es/withRouter";
import "react-fine-uploader/gallery/gallery.css";
import { deletePost } from "../../../actions/postActions";

import FineUploaderTraditional from "fine-uploader-wrappers";
import Gallery from "react-fine-uploader";

import "react-fine-uploader/gallery/gallery.css";
import { openDialog } from "../../../actions/appActions";
import getURL from "../../../helpers/apis";

const UploadComponent = ({ open, handleClose, post, freeUser }) => {
  const dispatch = useDispatch();
  const [authToken, setAuthToken] = useState(localStorage.getItem("jwt"));
  const [uploader, setUploader] = useState(
    new FineUploaderTraditional({
      options: {
        maxConnections: freeUser ? 2 : 6,
        validation: {
          allowedExtensions: [
            "jpeg",
            "jpg",
            "png",
            "mp4",
            "mpeg",
            "webm",
            "mkv",
            "flv",
            "ogg",
            "ogv",
            "m4v",
            "m4p",
            "3gp",
          ],
          itemLimit: freeUser ? 2 : 6,
          sizeLimit: 10485760,
          stopOnFirstInvalidFile: false,
        },
        // debug: true,
        request: {
          customHeaders: {
            Authorization: authToken,
          },
          inputName: "media",
        },
        // session: {
        // customHeaders: {
        // Authorization: authToken,
        // },
        // endpoint: getURL("GET_AD_GALLERIES_FOR_UPDATE"),
        // params: { ad_id: adId },
        // },
        // deleteFile: {
        //   enabled: true,
        //   customHeaders: {
        //     Authorization: authToken,
        //   },
        //   endpoint: getURL("GALLERY_REMOVE"),
        // },
        // retry: {
        //   enableAuto: true,
        // },
      },
    })
  );

  useEffect(() => {
    setAuthToken(localStorage.getItem("jwt"));
  }, []);

  useEffect(() => {
    if (post) {
      uploader.on("submit", (id, name) => {
        let file = uploader.methods.getFile(id);
        if (file.type.substr(0, 5) === "image") {
          uploader.methods.setEndpoint(
            getURL("UPDATE_POST_WITH_GALLERIES") +
              "/" +
              post._id +
              "/" +
              "photos",
            id
          );
        } else if (file.type.substr(0, 5) === "video") {
          uploader.methods.setEndpoint(
            getURL("UPDATE_POST_WITH_GALLERIES") +
              "/" +
              post._id +
              "/" +
              "videos"
          );
        }
      });
      uploader.on("validate", ({ name, size }) => {
        if (
          ![
            ".jpeg",
            ".jpg",
            ".png",
            ".mp4",
            ".mpeg",
            ".webm",
            ".mkv",
            ".flv",
            ".ogg",
            ".ogv",
            ".m4v",
            ".m4p",
            ".3gp",
          ].includes(name.substr(name.lastIndexOf(".")).toLowerCase()) ||
          size >= 10485760
        ) {
          dispatch(
            openDialog(
              `Some files were not uploaded. Please upload images or videos under 10MB in size. File formats supported are -
        .jpeg, .jpg, .png, .mp4, .mpeg, .webm,  .mkv, .flv, .ogg, .ogv, .m4v, .m4p, .3gp`
            )
          );
        }
      });
      uploader.on("validateBatch", (array) => {
        if (freeUser && array.length > 2) {
          dispatch(openDialog(`Please subscribe to upload more than 2 files.`));
        }
        if (!freeUser && array.length > 6) {
          dispatch(openDialog(`Upload limit is 6.`));
        }
      });
      uploader.on("cancel", (id) => {
        uploader.methods.deleteFile(id);
      });
      uploader.on("complete", (id, name, res) => {
        if (res.error) {
          dispatch(openDialog(res.data || "Something went wrong."));
        }
      });
    }
  }, [post]);

  const handleSubmit = () => {
    let count = uploader.methods.getNetUploads();
    if (count < 1) {
      dispatch(
        openDialog(
          "A post must have atleast one image/video. Please select one or cancel."
        )
      );
    } else {
      handleClose();
    }
  };

  const handleCancel = () => {
    handleClose();
    dispatch(deletePost(post._id, true));
  };

  return (
    <React.Fragment>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={() => handleClose()}>
        <DialogTitle id="comment-settings">Upload Gallery</DialogTitle>
        <DialogContent>
          <Gallery style={{ width: "100%" }} uploader={uploader} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCancel()}
            color="secondary"
            style={{ textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleSubmit()}
            color="primary"
            style={{ textTransform: "none" }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default withRouter(UploadComponent);
