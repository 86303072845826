import request from "../helpers/requestEngine";
import snackbar from "snackbar";

export const getVideos = () => {
  return dispatch => {
    dispatch(mediaFetching());
    return dispatch(fetchVideos());
  };
};

function mediaFetching() {
  return {
    type: "VIDEOS_FETCHING"
  };
}

function fetchVideos() {
  return dispatch => {
    request("get", "PROFILE_VIDEO", true).then(
      response => dispatch(videosFetchSuccess(response.data.data)),
      err => dispatch(videosFetchFailed())
    );
  };
}

function videosFetchSuccess(videos) {
  return {
    type: "VIDEOS_FETCH_SUCCESS",
    payload: videos
  };
}

function videosFetchFailed() {
  return {
    type: "VIDEOS_FETCH_FAILED"
  };
}

export const handleUpload = e => {
  e.preventDefault();
  let formData = new FormData();

  Object.keys(e.target.media.files).map(function(key) {
    formData.append("media", e.target.media.files[key]);
  });

  return dispatch => {
    dispatch(uploading());
    return dispatch(uploadVideo(formData));
  };
};

function uploadVideo(formData) {
  return dispatch => {
    request(
      "post",
      "PROFILE_VIDEO",
      true,
      formData,
      {},
      { "Content-Type": "multipart/form-data" }
    ).then(
      response => {
        snackbar.show("Video uploaded!");
        dispatch(uploadSuccess());
      },
      err => {
        dispatch(uploadFailed());
        if (err.response.data.status === 415) {
          snackbar.show("Video not supported.");
          return;
        }
        snackbar.show("Something went wrong while uploading your video.");
      }
    );
  };
}

function uploadSuccess() {
  return {
    type: "VIDEO_UPLOAD_SUCCESS"
  };
}

function uploading() {
  return {
    type: "VIDEO_UPLOADING"
  };
}

function uploadFailed() {
  return {
    type: "VIDEO_UPLOAD_FAILED"
  };
}

export function onDeleteVideos(filename) {
  return deleteVideo(filename);
}

const deleteVideo = filename => {
  return dispatch => {
    request("delete", "PROFILE_VIDEO", true, { filename }).then(
      response => {
        if (!response.data.error) {
          dispatch(uploadSuccess());
          return snackbar.show("Video deleted.");
        }
        snackbar.show("Couldn't delete the video.");
      },
      err => {
        snackbar.show("Couldn't delete the video.");
      }
    );
  };
};
