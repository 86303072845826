import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import {
  Button,
  Grid,
  Select,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import AdSpacePaymentDetails from "../PaymentDetails/AdSpacePaymentDetails";
import PurchaseDetails from "./PurchaseDetails";
import WarningIcon from "@material-ui/icons/Warning";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import { openCheckout } from "../PaymentDetails/Checkout";
import { Loader } from "../UtilityComponents";
import {
  addAdSpace,
  getUserAdSpace,
  getUnprocessedAdvt,
  getAdSpaceDetails,
  getRazorPaySuccessCapture,
  setCycleAndAdSpace,
} from "../../actions/adSpaceActions";
import { openDialog } from "../../actions/appActions";
import getURL from "../../helpers/apis";
import { constants } from "../../helpers/constants";
import UploadDialog from "./UploadDialog";
import UserGalleryComponent from "./UserGalleryComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  paymentButton: {
    margin: "24px",
  },
  paymentDetailsRow: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: "8px",
  },
  divider: {
    margin: 4,
  },
  paymentDetials: {
    textAlign: "center",
  },
  warningMessage: {
    display: "flex",
    alignItems: "center",
  },
  warningTyphography: {
    color: "white",
  },
  warningIcon: {
    marginLeft: "6px",

    fontSize: "20px",
  },
  feature: {
    listStyleType: "squre",
  },
}));

const AdSpaceContainer = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [submitable, setSubmitable] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [adspace, setAdspace] = useState("Select");
  const [cycle, setCycle] = useState("yearly");
  // const [country, setCountry] = useState("Worldwide");
  const [purchaseOpen, setPurchaseOpen] = useState(false);

  const userDetail = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.userDetail
  );
  const addAdSpacetoCartLoading = useSelector(
    ({ adSpaceReducer }) => adSpaceReducer.addAdSpacetoCartLoading
  );
  const adSpaceDetails = useSelector(
    ({ adSpaceReducer }) => adSpaceReducer.adSpaceDetails
  );
  const userAdSpaceList = useSelector(
    ({ adSpaceReducer }) => adSpaceReducer.userAdSpaceList
  );
  const userAdSpaceListLoading = useSelector(
    ({ adSpaceReducer }) => adSpaceReducer.userAdSpaceListLoading
  );
  const userUnprocessedAdvt = useSelector(
    ({ adSpaceReducer }) => adSpaceReducer.userUnprocessedAdvt
  );
  const userUnprocessedAdvtLoading = useSelector(
    ({ adSpaceReducer }) => adSpaceReducer.userUnprocessedAdvtLoading
  );

  useEffect(() => {
    // dispatch(requestCountries());
    adspace !== "Select" &&
      dispatch(getAdSpaceDetails({ cycle, name: adspace }));
    dispatch(getUserAdSpace("active"));
  }, []);

  useEffect(() => {
    adspace !== "Select" &&
      dispatch(getAdSpaceDetails({ cycle, name: adspace }));
  }, [cycle, adspace]);

  useEffect(() => {
    !userAdSpaceListLoading &&
      !userUnprocessedAdvtLoading &&
      (!userAdSpaceList || (userAdSpaceList && userAdSpaceList.length <= 0)) &&
      dispatch(getUnprocessedAdvt());
  }, [userAdSpaceListLoading]);

  const handleCallback = (submit) => {
    submit &&
      dispatch(
        openDialog(
          "Ad request submitted. Keep checking here for status approval."
        )
      );
    dispatch(getUserAdSpace("active"));
  };

  const handleCallbackRazorPaySuccess = (res) => {
    setPurchaseOpen(false);
    const body = {
      // razorpay_order_id: res.razorpay_order_id,
      // razorpay_payment_id: res.razorpay_payment_id,
      // razorpay_signature: res.razorpay_signature,
      // advtId: advtId,
      ...res,
    };
    dispatch(getRazorPaySuccessCapture(body, handleCallback));
  };

  // const handleCallRazorPayMethod = (razorPayData) => {
  //   setPurchaseOpen(false);
  //   openCheckout(
  //     userDetail,
  //     razorPayData.totalPriceAfterTax,
  //     razorPayData.razorpay_order_id,
  //     razorPayData._id,
  //     handleCallbackRazorPaySuccess
  //   );
  // };

  const handleSubmit = () => {
    // setPurchaseOpen(false);
    dispatch(addAdSpace(adSpaceDetails && adSpaceDetails._id, handleCallback));
  };

  const handleMakePayment = () => {
    setPurchaseOpen(false);
    let data = userUnprocessedAdvt[userUnprocessedAdvt.length - 1];
    var pd = {
      key: process.env.REACT_APP_PAYU_KEY,
      txnid: data._id,
      amount: data.totalPriceAfterTax,
      firstname: userDetail.username,
      email: userDetail.email,
      phone: userDetail.phone,
      productinfo: data.items[0].adSpaceId,
      surl: getURL("BASE_URL") + "buyAdSpace",
      furl: getURL("BASE_URL") + "buyAdSpace",
      hash: data.hash,
      service_provider: "payu_paisa",
    };
    redirectToPayU(pd);
    // openCheckout(
    //   userDetail,
    //   userUnprocessedAdvt[userUnprocessedAdvt.length - 1].totalPriceAfterTax,
    //   userUnprocessedAdvt[userUnprocessedAdvt.length - 1].razorpay_order_id,
    //   userUnprocessedAdvt[userUnprocessedAdvt.length - 1]._id,
    //   handleCallbackRazorPaySuccess
    // );

    // dispatch(
    //   addAdSpace(
    //     userUnprocessedAdvt &&
    //       userUnprocessedAdvt.length > 0 &&
    //       userUnprocessedAdvt[userUnprocessedAdvt.length - 1].items[0] &&
    //       userUnprocessedAdvt &&
    //       userUnprocessedAdvt.length > 0 &&
    //       userUnprocessedAdvt[userUnprocessedAdvt.length - 1].items[0]
    //         .adSpaceId,
    //     handleCallRazorPayMethod
    //   )
    // );
  };
  const redirectToPayU = (pd) => {
    //use window.bolt.launch if you face an error in bolt.launch
    window.bolt.launch(pd, {
      responseHandler: (response) => {
        // your payment response Code goes here
        response.response.txnStatus !== "CANCEL" &&
          handleCallbackRazorPaySuccess(response);
        // fetch(base_url + "payment/payumoney/response", {
        //   method: "POST",
        //   headers: {
        //     Accept: "application/json",
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify(response.response),
        // })
        //   .then(function (a) {
        //     return a.json();
        //   })
        //   .then(function (json) {
        //   });
      },
      catchException: (response) => {
        response.response.txnStatus !== "CANCEL" &&
          handleCallbackRazorPaySuccess(response);

        // the code you use to handle the integration errors goes here
        // Make any UI changes to convey the error to the user
      },
    });
    // openCheckout(
    //   userDetail,
    //   razorPayData.totalPriceAfterTax,
    //   razorPayData.razorpay_order_id,
    //   razorPayData._id,
    //   handleCallbackRazorPaySuccess
    // );
  };
  // useEffect(() => {
  //   if (adspace === "authPages" || adspace === "sidePanels") {
  //     history.push("/bid-calendar");
  //   }
  // }, [adspace]);

  return (
    <div className={classes.root}>
      <div className={classes.paymentButton}>
        <Typography
          component="h1"
          variant="h4"
          align="center"
          color="textPrimary"
        >
          Buy AdSpace!
        </Typography>
        {!userAdSpaceListLoading &&
          !addAdSpacetoCartLoading &&
          !userUnprocessedAdvtLoading &&
          (!userAdSpaceListLoading &&
          !addAdSpacetoCartLoading &&
          !userUnprocessedAdvtLoading &&
          userAdSpaceList &&
          userAdSpaceList.length > 0 ? (
            <div className={classes.paymentDetials}>
              <Typography
                component="h1"
                variant="h6"
                color="textPrimary"
                gutterBottom
              >
                Current AdSpace Details
              </Typography>
              <Divider className={classes.divider} />
              <AdSpacePaymentDetails
                payment={
                  userAdSpaceList &&
                  userAdSpaceList.length > 0 &&
                  userAdSpaceList[userAdSpaceList.length - 1]
                }
              />
              {userAdSpaceList &&
                userAdSpaceList.length > 0 &&
                userAdSpaceList[userAdSpaceList.length - 1].isApproved && (
                  <UserGalleryComponent
                    // open={openUpload}
                    userAd={userAdSpaceList[userAdSpaceList.length - 1]}
                    // handleClose={() => setOpenUpload(false)}
                    // handleClose={() => handleCloseUpload()}
                  />
                )}
            </div>
          ) : addAdSpacetoCartLoading ? (
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white", opacity: "0.6", textTransform: "none" }}
            >
              Processing...
            </Button>
          ) : !userAdSpaceListLoading &&
            !addAdSpacetoCartLoading &&
            !userUnprocessedAdvtLoading &&
            userUnprocessedAdvt &&
            userUnprocessedAdvt.length > 0 ? (
            <div className={classes.paymentDetials}>
              <Typography
                component="h1"
                variant="h6"
                color="textPrimary"
                gutterBottom
              >
                Chosen AdSpace Details
              </Typography>
              <Divider className={classes.divider} />
              <Grid container style={{ margin: 32 }}>
                <Grid item xs={6}>
                  <Typography
                    align="center"
                    variant="h6"
                    style={{ margin: "10px", color: constants.PRIMARY_COLOR }}
                  >
                    Review Status
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    align="center"
                    variant="h6"
                    style={{ margin: "10px", color: constants.PRIMARY_COLOR }}
                  >
                    {userUnprocessedAdvt[userUnprocessedAdvt.length - 1]
                      .isRejected
                      ? "Rejected"
                      : userUnprocessedAdvt[userUnprocessedAdvt.length - 1]
                          .isApproved
                      ? "Approved"
                      : "In Progress"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    align="center"
                    variant="h6"
                    style={{ margin: "10px", color: constants.PRIMARY_COLOR }}
                  >
                    AdSpace
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    align="center"
                    variant="h6"
                    style={{ margin: "10px", color: constants.PRIMARY_COLOR }}
                  >
                    {userUnprocessedAdvt &&
                      userUnprocessedAdvt.length > 0 &&
                      userUnprocessedAdvt[userUnprocessedAdvt.length - 1]
                        .items[0].name}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    align="center"
                    variant="h6"
                    style={{ margin: "10px", color: constants.PRIMARY_COLOR }}
                  >
                    Cycle
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    align="center"
                    variant="h6"
                    style={{ margin: "10px", color: constants.PRIMARY_COLOR }}
                  >
                    {userUnprocessedAdvt &&
                      userUnprocessedAdvt.length > 0 &&
                      userUnprocessedAdvt[userUnprocessedAdvt.length - 1]
                        .items[0].cycle}
                  </Typography>
                </Grid>
                {userUnprocessedAdvt &&
                  userUnprocessedAdvt.length > 0 &&
                  userUnprocessedAdvt[userUnprocessedAdvt.length - 1]
                    .slotdate && (
                    <>
                      <Grid item xs={6}>
                        <Typography
                          align="center"
                          variant="h6"
                          style={{
                            margin: "10px",
                            color: constants.PRIMARY_COLOR,
                          }}
                        >
                          Slot Date
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          align="center"
                          variant="h6"
                          style={{
                            margin: "10px",
                            color: constants.PRIMARY_COLOR,
                          }}
                        >
                          {userUnprocessedAdvt &&
                            userUnprocessedAdvt.length > 0 &&
                            new Date(
                              userUnprocessedAdvt[
                                userUnprocessedAdvt.length - 1
                              ].slotdate
                            ).toDateString()}
                        </Typography>
                      </Grid>
                    </>
                  )}
                (
                <Grid item xs={6}>
                  <Typography
                    align="center"
                    variant="h6"
                    style={{
                      margin: "10px",
                      color: constants.PRIMARY_COLOR,
                    }}
                  >
                    {userUnprocessedAdvt &&
                    userUnprocessedAdvt.length > 0 &&
                    userUnprocessedAdvt[userUnprocessedAdvt.length - 1].bidId
                      ? "Bid Amount"
                      : "Price"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    align="center"
                    variant="h6"
                    style={{
                      margin: "10px",
                      color: constants.PRIMARY_COLOR,
                    }}
                  >
                    {userUnprocessedAdvt &&
                      userUnprocessedAdvt.length > 0 &&
                      userUnprocessedAdvt[userUnprocessedAdvt.length - 1]
                        .totalPrice}
                  </Typography>
                </Grid>
              </Grid>

              {userUnprocessedAdvt &&
                userUnprocessedAdvt.length > 0 &&
                userUnprocessedAdvt[userUnprocessedAdvt.length - 1]
                  .isApproved && (
                  <>
                    <Divider className={classes.divider} />

                    <Grid item xs={12} align="center">
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{
                          color: "white",
                          textTransform: "none",
                        }}
                        onClick={() => setPurchaseOpen(true)}
                      >
                        Make payment
                      </Button>
                      {purchaseOpen && (
                        <PurchaseDetails
                          open={purchaseOpen}
                          handleClose={() => setPurchaseOpen(false)}
                          handleMakePayment={handleMakePayment}
                          adSpaceId={
                            userUnprocessedAdvt &&
                            userUnprocessedAdvt.length > 0 &&
                            userUnprocessedAdvt[userUnprocessedAdvt.length - 1]
                              .items[0] &&
                            userUnprocessedAdvt &&
                            userUnprocessedAdvt.length > 0 &&
                            userUnprocessedAdvt[userUnprocessedAdvt.length - 1]
                              .items[0].adSpaceId
                          }
                          purchaseDetails={
                            userUnprocessedAdvt &&
                            userUnprocessedAdvt.length > 0 &&
                            userUnprocessedAdvt[userUnprocessedAdvt.length - 1]
                          }
                        />
                      )}
                    </Grid>
                    <Divider className={classes.divider} />
                  </>
                )}

              {/* <Button
                variant="contained"
                color="primary"
                style={{
                  color: "white",
                  textTransform: "none",
                  margin: 12,
                }}
                onClick={() => setOpenUpload(true)}
              >
                Ad Media
            </Button> */}
              {/* {openUpload && ( */}
              <UploadDialog
                // open={openUpload}
                setSubmitable={(c) => setSubmitable(c)}
                companyId={userDetail._id}
                // handleClose={() => setOpenUpload(false)}
                // handleClose={() => handleCloseUpload()}
              />
              {/* )} */}
            </div>
          ) : (
            !userAdSpaceListLoading &&
            !addAdSpacetoCartLoading &&
            !userUnprocessedAdvtLoading && (
              <>
                <Grid container style={{ marginTop: 32 }}>
                  <Grid item xs={6}>
                    <Typography
                      align="center"
                      variant="h6"
                      style={{ margin: "10px", color: constants.PRIMARY_COLOR }}
                    >
                      Choose AdSpace
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      value={adspace}
                      onChange={(e) => setAdspace(e.target.value)}
                    >
                      <MenuItem value={"Select"} disabled>
                        Select
                      </MenuItem>
                      <MenuItem value={"authPages"}>
                        Login/Register Page
                      </MenuItem>
                      <MenuItem value={"sidePanels"}>
                        Single Side Panel
                      </MenuItem>
                      <MenuItem value={"inStream"}>In-Stream</MenuItem>
                      <MenuItem value={"betweenPosts"}>Between Posts</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      align="center"
                      variant="h6"
                      style={{ margin: "10px", color: constants.PRIMARY_COLOR }}
                    >
                      Cycle
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      value={cycle}
                      onChange={(e) => setCycle(e.target.value)}
                    >
                      <MenuItem value={"yearly"}>Yearly</MenuItem>
                      <MenuItem value={"halfyearly"}>Halfyearly</MenuItem>
                      <MenuItem value={"quarterly"}>Quarterly</MenuItem>
                      {/* <MenuItem value={"monthly"}>Monthly</MenuItem> */}
                      {/* <MenuItem value={"daily"}>Daily</MenuItem> */}
                    </Select>
                  </Grid>
                  {adspace !== "authPages" &&
                    adspace !== "sidePanels" &&
                    adspace !== "Select" && (
                      <>
                        <Grid item xs={6}>
                          <Typography
                            align="center"
                            variant="h6"
                            style={{
                              margin: "10px",
                              color: constants.PRIMARY_COLOR,
                            }}
                          >
                            Price
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            variant="h6"
                            style={{
                              margin: "10px",
                              color: constants.PRIMARY_COLOR,
                            }}
                          >
                            {adSpaceDetails
                              ? "Rs. " + adSpaceDetails.price
                              : "Unavailable. Please try different cycles and/or countries."}
                          </Typography>
                        </Grid>
                      </>
                    )}
                  {(adspace === "authPages" || adspace === "sidePanels") && (
                    <Grid item xs={12} align="center">
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{
                          color: "white",
                          margin: "32px",
                          textTransform: "none",
                        }}
                        onClick={() => {
                          dispatch(
                            setCycleAndAdSpace({
                              cycle: cycle,
                              adspace: adspace,
                            })
                          );
                          history.push("/bid-calendar");
                        }}
                      >
                        Select Dates
                      </Button>
                    </Grid>
                  )}

                  {adSpaceDetails &&
                    submitable &&
                    !(adspace === "authPages" || adspace === "sidePanels") && (
                      <Grid item xs={12} align="center">
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          style={{
                            color: "white",
                            margin: "32px",
                            textTransform: "none",
                          }}
                          onClick={() => handleSubmit()}
                        >
                          Submit for Review
                        </Button>
                        {/* {purchaseOpen && (
                    <PurchaseDetails
                      open={purchaseOpen}
                      handleClose={() => setPurchaseOpen(false)}
                      handleMakePayment={handleMakePayment}
                      adSpaceId={adSpaceDetails && adSpaceDetails._id}
                    />
                  )} */}
                      </Grid>
                    )}
                </Grid>
                {adspace !== "authPages" &&
                  adspace !== "sidePanels" &&
                  adspace !== "Select" && (
                    <div className={classes.paymentDetials}>
                      <Divider className={classes.divider} />

                      <UploadDialog
                        // open={openUpload}
                        companyId={userDetail._id}
                        setSubmitable={(c) => setSubmitable(c)}
                        // handleClose={() => setOpenUpload(false)}
                        // handleClose={() => handleCloseUpload()}
                      />
                    </div>
                  )}
              </>
            )
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  // countriesList: state.registerReducer.countries
  //   ? state.registerReducer.countries.map((country) => country.country)
  //   : [],
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdSpaceContainer));
