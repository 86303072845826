const PosTableReducer = (
  state = {
    Pos: [],
    fetchingPos: false,
    currentPos: null,
    currentPos_id: null,
    applied: false,
    cancelled: false,
    applying: false,
    cancelling: false,
    posDetails: null,
    fetchingPosDetails: false,
    fetchingJobSeekersDetails: true,
    jobSeekersDetailsList: [],
    fetchingJobSeekersDetailsError: "",
    positionDetailsById: {},
    clonePositionLoading: false,

    fetchingPositionListByUser: false,
    positionListFilteredByUser: [],
    positionListTotalPages: null,

    updatingPositionDetailsLoading: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case "CONFIRM_ROLE":
      const updatedPositionListByUser2 = state.Pos.map((pos) => {
        if (pos._id === action.payload._id) {
          return Object.assign(pos, action.payload);
        }
        return pos;
      });
      return {
        ...state,
        cancelled: true,
        cancelling: false,
        Pos: updatedPositionListByUser2,
      };
    case "CANCELLED_APPLICATION":
      const updatedPositionListByUser1 = state.positionListFilteredByUser.map(
        (pos) => {
          if (pos._id === action.payload.positionId) {
            return Object.assign(pos, { status: "Eligible" });
          }
          return pos;
        }
      );
      return {
        ...state,
        cancelled: true,
        cancelling: false,
        positionListFilteredByUser: updatedPositionListByUser1,
      };
    case "CANCELLING_POSITION_APPLICATION":
      return {
        ...state,
        cancelling: true,
      };
    case "ERROR_CANCEL_POSITION_APPLICATION":
      return {
        ...state,
        cancelling: false,
      };
    case "FETCHING_POS":
      return {
        ...state,
        fetchingPos: true,
      };
    case "POS_FETCHED_SUCCESS":
      return {
        ...state,
        Pos: action.payload,
        fetchingPos: false,
      };
    case "POS_FETCHED_FAILED":
      return {
        ...state,
        fetchingPos: false,
      };
    case "CURRENT_POS":
      return {
        ...state,
        currentPos: action.currentPos,
        currentPos_id: action.currentPos._id,
        applied: action.applied,
      };
    case "APPLIED_FOR_POS":
      const updatedPositionListByUser = state.positionListFilteredByUser.map(
        (pos) => {
          if (pos._id === action.payload.positionId) {
            return Object.assign(pos, { status: action.payload.status });
          }
          return pos;
        }
      );
      return {
        ...state,
        applied: true,
        applying: false,

        positionListFilteredByUser: updatedPositionListByUser,
      };
    case "APPLYING_FOR_POS":
      return {
        ...state,
        applying: true,
      };
    case "ERROR_APPLY_POS":
      return {
        ...state,
        applying: false,
      };
    case "FETCHING_POS_DETAILS":
      return {
        ...state,
        posDetails: null,
        fetchingPosDetails: true,
      };
    case "POS_FETCHED_DETAILS_SUCCESS":
      return {
        ...state,
        posDetails: action.payload,
        fetchingPosDetails: false,
      };
    case "POS_FETCHED_DETAILS_FAILED":
      return {
        ...state,
        fetchingPosDetails: false,
      };

    case "FETCH_JOB_SEEKERS_PROFILE":
      return {
        ...state,
        fetchingJobSeekersDetails: true,
      };
    case "FETCH_JOB_SEEKERS_PROFILE_SUCCESS":
      return {
        ...state,
        jobSeekersDetailsList: action.payload,
        fetchingJobSeekersDetails: false,
      };
    case "FETCH_JOB_SEEKERS_PROFILE_FAILED":
      return {
        ...state,
        fetchingJobSeekersDetails: false,
      };

    //view position

    case "FETCHING_POSITION_BY_ID":
      return {
        ...state,
        error: "",
        fetchingPositionById: true,
      };
    case "FETCHING_POSITION_BY_ID_SUCCESS":
      return {
        ...state,
        positionDetailsById: action.payload,
        fetchingPositionById: false,
      };
    case "FETCHING_POSITION_BY_ID_FAILED":
      return {
        ...state,
        fetchingPositionById: false,
      };

    case "UPDATE_POSITION_DETAILS":
      return {
        ...state,
        error: "",
        updatingPositionDetailsLoading: true,
      };
    case "UPDATE_POSITION_DETAILS_SUCCESS":
      const updatedPostion = state.Pos.map((pos) => {
        if (pos._id === action.payload._id)
          return Object.assign(pos, action.payload.response);
        else {
          return pos;
        }
      });
      return {
        ...state,
        Pos: updatedPostion,
        updatingPositionDetailsLoading: false,
      };
    case "UPDATE_POSITION_DETAILS_FAILED":
      return {
        ...state,
        error: action.payload.msg,
        updatingPositionDetailsLoading: false,
      };

    case "UPDATE_POSITION_STATUS":
      return {
        ...state,
        updatingPositionStatus: true,
      };
    case "UPDATE_POSITION_STATUS_SUCCESS":
      const updatedPostionByUser = state.Pos.map((pos) => {
        if (pos._id === action.payload._id)
          return Object.assign(pos, { positionStatus: "Closed" });
        else {
          return pos;
        }
      });
      return {
        ...state,
        Pos: updatedPostionByUser,
        updatingPositionStatus: false,
      };
    case "UPDATE_POSITION_STATUS_FAILED":
      return {
        ...state,
        updatingPositionStatus: false,
      };

    /////////////////////////////

    case "DELETE_POSITION":
      return {
        ...state,
        deletingPosition: true,
      };
    case "DELETE_POSITION_SUCCESS":
      return {
        ...state,
        deletingPosition: false,
      };
    case "DELETE_POSITION_FAILED":
      return {
        ...state,
        deletingPosition: false,
      };

    /////////////////////////////

    /////////////////////////////

    case "UPDATE_RECENT_SEARCH":
      return {
        ...state,
        updatingRecentSearch: true,
      };
    case "UPDATE_RECENT_SEARCH_SUCCESS":
      return {
        ...state,
        updatingRecentSearch: false,
      };
    case "UPDATE_RECENT_SEARCH_FAILED":
      return {
        ...state,
        updatingRecentSearch: false,
      };

    /////////////////////////////

    case "CLONE_POSITION_BY_ID":
      return {
        ...state,
        clonePositionLoading: true,
      };
    case "CLONE_POSITION_SUCCESS_BY_ID":
      state.Pos.unshift(action.payload);
      return {
        ...state,
        // Pos :[...state.Pos],
        // jobSeekersDetailsList: action.payload,
        clonePositionLoading: false,
      };
    case "CLONE_POSITION_FAILED_BY_ID":
      return {
        ...state,
        clonePositionLoading: false,
      };

    case "FETCH_FILTERED_POSITION_LIST_BY_USER":
      return {
        ...state,
        fetchingPositionListByUser: true,
      };
    case "FETCH_FILTERED_POSITION_LIST_BY_USER_SUCCESS":
      return {
        ...state,
        positionListFilteredByUser: action.payload.data,
        positionListTotalPages: action.payload.totalPages,
        fetchingPositionListByUser: false,
      };
    case "FETCH_FILTERED_POSITION_LIST_BY_USER_FAILED":
      return {
        ...state,
        fetchingPositionListByUser: false,
      };

    default:
      return state;
  }
};

export default PosTableReducer;
