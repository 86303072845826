const initialState = {
  bids: [],
  errors: [],
  events: [],
};

export default function (state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case "GET_BIDS_SUCCESS":
      return {
        ...state,
        bids: payload,
      };
    case "GET_BIDEVENTS":
      return {
        ...state,
        events: payload,
      };
    case "BID_ERROR":
      return {
        ...state,
        errors: payload,
      };
    case "CLEAR_BIDS":
      return {
        ...state,
        bids: [],
      };
    case "CLEAR_EVENTS":
      return {
        ...state,
        events: [],
      };
    default:
      return { ...state };
  }
}
