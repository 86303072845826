import React, { useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Gallery from "react-fine-uploader";
import FineUploaderTraditional from "fine-uploader-wrappers";
import getURL from "../../helpers/apis";
import snackbar from "snackbar";

import "react-fine-uploader/gallery/gallery.css";

const UploadDialog = ({ open, handleClose }) => {
  const [authToken, setAuthToken] = useState(localStorage.getItem("jwt"));

  const profilePicUploader = new FineUploaderTraditional({
    options: {
      multiple: false,
      validation: {
        itemLimit: 1,
        allowedExtensions: ["jpeg", "jpg", "png"],
        sizeLimit: 10485760,
        stopOnFirstInvalidFile: false,
      },
      chunking: {
        enabled: true,
      },
      request: {
        endpoint: getURL("UPDATE_PROFILE_PIC"),
        customHeaders: {
          Authorization: authToken,
        },
        inputName: "Photo",
      },
      callbacks: {
        onComplete: function (id, name, res) {
          !res.error && snackbar.show("Profile pic updated.");
        },
      },
    },
  });

  profilePicUploader.on("complete", (id, name, response) => {
    if (!response.error) {
      handleClose();
    }
  });

  return (
    <Dialog
      disableBackdropClick
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Upload Image</DialogTitle>
      <DialogContent dividers>
        <Gallery uploader={profilePicUploader} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          style={{ textTransform: "none" }}
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadDialog;
