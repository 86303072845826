import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {
  getReportReasons,
  reportEntity,
  reportPostEntity,
} from "../../actions/applicantsDetailsActions";
import { Loader } from "../UtilityComponents";
import AlertDialog from "../AlertDialog";

const ReportDialog = ({ handleClose, open, galleryId, postId }) => {
  const [reasonList, setReasonList] = useState(null);
  const [selectedReason, setSelectedReason] = useState(null);
  const [alert, setAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [desc, setDesc] = useState('')

  useEffect(() => {
    getReportReasons().then((res) => setReasonList(res.data));
  }, []);

  const handleSelect = (reason) => {
    setSelectedReason(reason);
  };

  const handleSend = () => {
    if (galleryId) {
      reportEntity({
        gallery_id: galleryId,
        reason: selectedReason,
        type: "gallery",
        desc,
      }).then((res) => {
        if (res.error) {
          setAlertText("Something Went Wrong.");
          setAlert(true);
        } else {
          setAlertText("Success.");
          setAlert(true);
        }
        handleClose();
      });
    } else if (postId) {
      reportPostEntity({
        post_id: postId,
        reason: selectedReason,
        type: "gallery",
        desc
      }).then((res) => {
        if (res.error) {
          setAlertText("Something Went Wrong.");
          setAlert(true);
        } else {
          setAlertText("Success.");
          setAlert(true);
        }
        handleClose();
      });
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth={"sm"}
      fullWidth
    >
      {alert && (
        <AlertDialog
          open={alert}
          handleClose={() => {
            setAlert(false);
            handleClose();
          }}
          text={alertText}
        />
      )}
      <DialogTitle id="simple-dialog-title">Report</DialogTitle>
      {selectedReason ? (
        <>
          <DialogContent dividers>
            <Typography variant="h6" display="inline" gutterBottom>
              Selected Reason :
            </Typography>
            <Typography variant="h6" display="inline" gutterBottom>
              {" " + selectedReason}
            </Typography>
            <DialogContentText>Please state full reason.</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="desc"
              multiline
              rowsMax={4}
              rows={4}
              label="Description"
              fullWidth
              variant="outlined"
              value={desc}
              onChange={e =>{
                setDesc(e.target.value)
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              style={{ textTransform: "none" }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSend}
              color="primary"
              style={{ textTransform: "none" }}
            >
              Send
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent dividers>
            <List>
              {reasonList ? (
                reasonList.map((item) => (
                  <ListItem
                    divider
                    button
                    onClick={() => handleSelect(item.reportReason)}
                    key={item._id}
                  >
                    <ListItemText primary={item.reportReason} />
                  </ListItem>
                ))
              ) : (
                <div>
                  <Loader />
                </div>
              )}
            </List>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              style={{ textTransform: "none" }}
            >
              Cancel
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ReportDialog;
