import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { setRef, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { withRouter } from "react-router-dom";
import {
  Paper,
  Grid,
  Avatar,
  Typography,
  Divider,
  IconButton,
  Icon,
  Badge,
  Chip,
  Popover,
  List,
  ListItem,
  ListItemText,
  Button,
  Tabs,
  Tab,
} from "@material-ui/core";
import PhotoSharpIcon from "@material-ui/icons/PhotoSharp";
import PersonSharpIcon from "@material-ui/icons/PersonSharp";
import DynamicFeedSharpIcon from "@material-ui/icons/DynamicFeedSharp";
import EventNoteSharpIcon from "@material-ui/icons/EventNoteSharp";
import BlockedUserIcon from "../../assets/svg/Blocked Users Icon.svg";
import LikesIcon from "../../assets/svg/Likes icon (OUTLINE).svg";
import FavouritesIcon from "../../assets/svg/Favourites icon (OUTLINE).svg";
import ORIcon from "../../assets/svg/O R ICON.svg";
import HRIcon from "../../assets/svg/H R ICON.svg";
import HiddenFriendsIcon from "../../assets/svg/Hidden Friends icon.svg";
import ProfileLikeIcon from "../../assets/svg/Profile Like icon.svg";
import ProfileLikeIconBlue from "../../assets/svg/Profile Like icon (blue).svg";
import ShareToMeIcon from "../../assets/svg/Sharedtome icon.svg";
import MyCommentsIcon from "../../assets/svg/My Comments Icon 2.svg";
import CalendarIcon from "../../assets/svg/Calendar icon.svg";
import CalendarIconBlue from "../../assets/svg/Calendar icon (blue).svg";
import GalleryIcon from "../../assets/svg/Gallery icon.svg";
import PersonIcon from "../../assets/svg/User profile icon.svg";
import PersonIconBlue from "../../assets/svg/User profile icon (blue) copy.svg";
import GalleryIconBlue from "../../assets/svg/Gallery icon (blue).svg";
import PostsIconBlue from "../../assets/svg/Posts Icon (blue).svg";
import PostsIcon from "../../assets/svg/Posts Icon (black).svg";
import HFIcon from "../../assets/svg/HFICON.svg";
import OFIcon from "../../assets/svg/OFICON.svg";
import FollowingIcon from "../../assets/svg/Following (black).svg";
import FollowersIcon from "../../assets/svg/Followers (black).svg";
import ChatIcon from "../../assets/svg/Chat Icon (black).svg";
// import FollowingIcon from "@material-ui/icons/ArrowUpward";
// import FollowersIcon from "@material-ui/icons/ArrowDownward";
import ProfileLikedIcon from "@material-ui/icons/ThumbUpAltSharp";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import RadioButtonUncheckedSharpIcon from "@material-ui/icons/RadioButtonUncheckedSharp";
import LikeOpenIcon from "@material-ui/icons/FavoriteBorderSharp";
import FavouriteIcon from "@material-ui/icons/BookmarkBorderSharp";
import MenuIcon from "@material-ui/icons/Menu";
import AddSharpIcon from "@material-ui/icons/AddSharp";
// import ChatSharpIcon from "@material-ui/icons/ChatSharp";
import DoneSharpIcon from "@material-ui/icons/DoneSharp";
import ConfirmationDialog from "../ConfirmationDialog";
import CustomNameDialog from "../CustomNameDialog";
import PostFeed from "../PostsComponent/PostFeed";
import { openAvatarModal, openDialog } from "../../actions/appActions";
import {
  getUserProfileDetails,
  getUserProfileDetailsByUsername,
} from "../../actions/userDetailsAction";
import {
  unfollow,
  follow,
  editCustomName,
  unblock,
  block,
  likeProfile,
  unlikeProfile,
} from "../../actions/applicantsDetailsActions";
import getURL from "../../helpers/apis";
import UserGalleryComponent from "../UserGalleryComponent";
import ProfileInfo from "../ProfileInfo";
import CalendarComponent from "../CalendarComponent";
import ReportDialog from "./ReportDialog";
import PublicShare from "./PublicShare";
import { CheckCircle } from "@material-ui/icons";

const styles = {
  mainContent: {
    marginTop: 10,
  },
  paper: {
    fontSize: 14,
    width: "100%",
    minWidth: 275,
    // margin: 10,
    maxWidth: 1200,
  },
  avatar: {
    fontSize: 100,
    margin: "auto",
    width: 120,
    height: 120,
  },
  name: {
    margin: "20px auto 10px",
    fontSize: 20,
    fontWeight: 600,
    textAlign: "center",
  },
  userName: {
    fontSize: 15,
    textAlign: "center",
    margin: 6,
  },
  gender: {
    margin: "10px auto",
    fontSize: 15,
    textAlign: "center",
  },
  detailFields: {
    marginTop: 20,
    // height: "500px",
  },
  profileTitle: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: 600,
    padding: 10,
  },
  firstName: {
    fontSize: 14,
    marginBottom: 10,
  },
  icons: {
    cursor: "pointer",
    // fontSize: 24,
    height: "auto",
    // marginLeft: 15,
    // marginRight: 15,
  },
  columnContainer: {
    padding: 5,
  },
  fieldGridLabel: {
    // width: "30%",
    fontSize: 18,
    margin: 5,
  },
  fieldGridValue: {
    // width: "68%",
    fontSize: 18,
    margin: 5,
  },
  fieldLabel: {
    fontSize: 14,
    // fontWeight: 600,
  },
  fieldValue: {
    fontSize: 14,
  },
  tabPaper: {
    flexGrow: 1,
    borderTop: 0,
    width: "100%",
    // overflowY: "scroll",
    // height: "100vh",
  },
  tabIndicator: {
    top: "0px",
    height: "2px",
    backgroundColor: "#3f51b5",
    // backgroundColor: "black",
  },
  navIcons: {
    display: "block",
    marginTop: "-20px",
    overflow: "hidden",
    pointerEvents: "auto",
    position: "fixed",
    textIndent: "-9999em",
    top: "50%",
    cursor: "pointer",
    color: "white",
  },
};

// const avatarLetters = (firstString, secondString) =>
//   `${firstString[0]}${secondString[0]}`.toUpperCase();

const rootUrl = getURL("UPLOADS");

const UserProfile = ({
  dispatch,
  classes,
  match,
  currentUserProfile,
  userDetail,
  history,
  isUsedInCasting,
}) => {
  const [value, setValue] = useState(0);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openCustom, setOpenCustom] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [followType, setFollowType] = useState(null);
  const [isEdit, setIsEdit] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openReport, setOpenReport] = useState(false);
  const [openPublicShare, setOpenPublicShare] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [type, setType] = useState("public");
  const [galleryId, setGalleryId] = useState(null);

  useEffect(() => {
    match.params.user_id
      ? dispatch(getUserProfileDetails(match.params.user_id))
      : dispatch(getUserProfileDetailsByUsername(match.params.user_name));
    if (
      match.path === "/user_profile/:user_id/public" ||
      match.path === "/user_profile/:user_id/public/:galleryId"
    ) {
      setType("public");
      setGalleryId(match.params.galleryId);
      setValue(1);
    } else if (
      match.path === "/user_profile/:user_id/department" ||
      match.path === "/user_profile/:user_id/department/:galleryId"
    ) {
      setType("department");
      setGalleryId(match.params.galleryId);
      setValue(1);
    }
  }, []);

  useEffect(() => {
    match.params.user_id
      ? dispatch(getUserProfileDetails(match.params.user_id))
      : dispatch(getUserProfileDetailsByUsername(match.params.user_name));
    if (
      match.path === "/user_profile/:user_id/public" ||
      match.path === "/user_profile/:user_id/public/:galleryId"
    ) {
      setType("public");
      setGalleryId(match.params.galleryId);
      setValue(1);
    } else if (
      match.path === "/user_profile/:user_id/department" ||
      match.path === "/user_profile/:user_id/department/:galleryId"
    ) {
      setType("department");
      setGalleryId(match.params.galleryId);
      setValue(1);
    }
  }, [match, refresh]);

  if (isEmpty(currentUserProfile)) {
    return null;
  }

  const profilePhotoUrl = currentUserProfile.profilepic;
  //const profileName = `${currentUserProfile.firstname} ${currentUserProfile.lastname}`;
  const profileName = currentUserProfile.username;

  const handleUnblock = () => {
    setTitle("Are you sure?");
    setDescription(`Do you want to unblock ${profileName}?`);
    setCurrentAction("unblock");
    setOpenConfirm(true);
  };

  const handleBlock = () => {
    setTitle("Are you sure?");
    setDescription(`Do you want to block ${profileName}?`);
    setCurrentAction("block");
    setOpenConfirm(true);
  };

  const handleReport = () => {
    setOpenReport(true);
  };

  const handleProfileUnLike = () => {
    unlikeProfile({
      user_id: currentUserProfile && currentUserProfile._id,
      type: "user",
    }).then((res) => !res.error && setRefresh(!refresh));
  };

  const handleProfileLike = () => {
    likeProfile({
      user_id: currentUserProfile && currentUserProfile._id,
      type: "user",
    }).then((res) => !res.error && setRefresh(!refresh));
  };

  const handleShareGallery = () => {
    setOpenPublicShare(true);
  };

  const handlePopover = (e) => {
    setIsOpen(!isOpen);
    setAnchorEl(e.currentTarget);
  };

  const handleCustomName = () => {
    setIsEdit(currentUserProfile.user_follow_customname);
    setTitle("Edit Custom name");
    setDescription(
      `Set a customname under 20 characters for ${
        currentUserProfile && currentUserProfile.username
      }`
    );
    setOpenCustom(true);
  };

  const handleFollow = (type) => {
    setTitle(`${type ? type + " " : ""}Follow`);
    setFollowType(type);
    !type &&
      setDescription(
        `Select a follow type and optionally set a customname under 20 characters for ${
          currentUserProfile && currentUserProfile.username
        }`
      );
    type &&
      setDescription(
        `Optionally set ${type} follow customname under 20 characters for ${
          currentUserProfile && currentUserProfile.username
        }`
      );
    setOpenCustom(true);
  };

  const handleUnfollow = (type) => {
    setTitle("Are you sure?");
    setFollowType(type);
    setDescription(
      `Do you want to unfollow ${
        currentUserProfile && currentUserProfile.username
      }?`
    );
    setCurrentAction("unfollow");
    setOpenConfirm(true);
  };

  const handleConfirm = () => {
    setOpenConfirm(false);
    let user_id = currentUserProfile && currentUserProfile._id;
    if (currentAction === "block") {
      block({ user_id }).then((res) => setRefresh(!refresh));
    }
    if (currentAction === "unblock") {
      unblock({ user_id }).then((res) => setRefresh(!refresh));
    }
    if (currentAction === "unfollow") {
      unfollow({ type: followType, user_id }).then((res) => {
        setFollowType(null);
        setRefresh(!refresh);
      });
    }
    setCurrentAction(null);
  };

  const handleSave = (value, type) => {
    let user_id = currentUserProfile && currentUserProfile._id;
    setOpenCustom(false);
    !Boolean(isEdit)
      ? follow({ type, user_id, customname: value }).then((res) => {
          setFollowType(null);
          setRefresh(!refresh);
          typeof res.data === "string" && dispatch(openDialog(res.data));
        })
      : editCustomName({ user_id, customname: value }).then((res) => {
          setFollowType(null);
          setRefresh(!refresh);
          setIsEdit(null);
        });
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };



  return (
    <Paper className={classes.paper} elevation={0}>
      {openConfirm && (
        <ConfirmationDialog
          open={openConfirm}
          handleCancel={() => {
            setFollowType(null);
            setOpenConfirm(false);
          }}
          handleConfirm={() => handleConfirm()}
          title={title}
          description={description}
        />
      )}
      {openCustom && (
        <CustomNameDialog
          open={openCustom}
          handleClose={() => {
            setFollowType(null);
            setOpenCustom(false);
          }}
          handleSave={(value, type) => handleSave(value, type)}
          title={title}
          description={description}
          type={followType}
          isEdit={isEdit}
        />
      )}
      {openReport && (
        <ReportDialog
          open={openReport}
          handleClose={() => setOpenReport(false)}
          userId={currentUserProfile && currentUserProfile._id}
        />
      )}
      {openPublicShare && (
        <PublicShare
          open={openPublicShare}
          handleClose={() => setOpenPublicShare(false)}
          profileUrl={
            "https://celebbook.in/user_profile/" + currentUserProfile._id
          }
        />
      )}
      <Grid container spacing={0} className={classes.mainContent}>
        {!isUsedInCasting ? (
          <Grid xs={12} justify="center" alignItems="center">
            <Button
              variant="contained"
              color="secondary"
              size="small"
              style={{
                float: "right",
                textTransform: "none",
                marginRight: 12,
                marginTop: 12,
              }}
              onClick={() => history.goBack()}
            >
              Back
            </Button>
          </Grid>
        ) : null}

        <Grid item xs={2}>
          {!currentUserProfile.profilepic && (
            <Avatar className={classes.avatar}>
              {/* {avatarLetters(
                currentUserProfile.firstname,
                currentUserProfile.lastname
              )} */}

              {currentUserProfile.username}
            </Avatar>
          )}
          {currentUserProfile.profilepic && (
            <Avatar
              className={classes.avatar}
              alt={currentUserProfile.username}
              src={process.env.REACT_APP_BUCKET_NAME + profilePhotoUrl}
              onClick={(e) =>
                dispatch(
                  openAvatarModal(
                    process.env.REACT_APP_BUCKET_NAME + profilePhotoUrl
                  )
                )
              }
            />
          )}
          {currentUserProfile.friendStatus &&
            currentUserProfile.friendStatus.areYouFollowing && (
              <Typography className={classes.userName}>
                <Chip
                  label={currentUserProfile.user_follow_customname}
                  onClick={handleCustomName}
                  clickable
                  size="small"
                />
              </Typography>
            )}
        </Grid>
        <Grid key={3} item xs={3}>
        <Typography style={{padding:10}}>
        {currentUserProfile.userBio}
      </Typography>
        </Grid>
        <Grid item xs={7}>
          <Grid container>
          <Grid item xs={9}>
          <Typography component="div" className={classes.profileTitle} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            {profileName}
            {currentUserProfile.verifiedProfile ? <Icon color="primary" style={{marginLeft : 5}}><CheckCircle/></Icon> :<></>}
          </Typography>
        </Grid>
            <Grid xs={1}>
              <IconButton
                onClick={(e) => {
                  handlePopover(e);
                }}
              >
                <MenuIcon style={{ color: "black" }} />
              </IconButton>
              <Popover
                id="simple-popper"
                open={isOpen}
                anchorEl={anchorEl}
                onClose={(e) => {
                  handlePopover(e);
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <List component="nav" className={classes.root}>
                  <ListItem
                    button
                    className={classes.listItem}
                    onClick={(e) => {
                      handleShareGallery();
                      handlePopover(e);
                    }}
                  >
                    <ListItemText
                      primary="Share Profile"
                      classes={{ primary: classes.listItemText }}
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.listItem}
                    onClick={(e) => {
                      handleReport();
                      handlePopover(e);
                    }}
                  >
                    <ListItemText
                      primary="Report User"
                      classes={{ primary: classes.listItemText }}
                    />
                  </ListItem>

                  {currentUserProfile.blockStatus
                    .toLowerCase()
                    .startsWith("you") ? (
                    <ListItem
                      button
                      className={classes.listItem}
                      onClick={(e) => {
                        handleUnblock();
                        handlePopover(e);
                      }}
                    >
                      <ListItemText
                        primary={"Unblock User"}
                        classes={{ primary: classes.listItemText }}
                      />
                    </ListItem>
                  ) : (
                    <ListItem
                      button
                      className={classes.listItem}
                      onClick={(e) => {
                        handleBlock();
                        handlePopover(e);
                      }}
                    >
                      <ListItemText
                        primary={"Block User"}
                        classes={{ primary: classes.listItemText }}
                      />
                    </ListItem>
                  )}
                </List>
              </Popover>
            </Grid>
          </Grid>

          {/* <Divider variant="middle" /> */}

          <Grid container justify="center">
            <Grid item>
              <Typography component="div" className={classes.actionContainer}>
                <IconButton fontSize="small">
                  <Link style={{ color: "black" }}>
                    <Typography>
                      {currentUserProfile.noofprofilelikes}
                    </Typography>
                    {currentUserProfile.isProfileLiked ? (
                      <Icon
                        className={classes.icons}
                        onClick={() =>
                          userDetail &&
                          userDetail.userType.toLowerCase() !==
                            "FreeUser".toLowerCase() &&
                          handleProfileUnLike()
                        }
                      >
                        <img src={ProfileLikeIconBlue} />
                      </Icon>
                    ) : (
                      // <ProfileLikedIcon
                      //   className={classes.icons}
                      //   color={"primary"}
                      //   onClick={() => handleProfileUnLike()}
                      // />
                      <Icon
                        className={classes.icons}
                        onClick={() =>
                          userDetail &&
                          userDetail.userType.toLowerCase() !==
                            "FreeUser".toLowerCase() &&
                          handleProfileLike()
                        }
                      >
                        <img src={ProfileLikeIcon} />
                      </Icon>
                      // <ThumbUpAltOutlinedIcon
                      //   className={classes.icons}
                      //   color={"inherit"}
                      // />
                    )}
                  </Link>
                </IconButton>
                {userDetail && (
                  <>
                    <IconButton fontSize="small">
                      <Link
                        to={`/user_profile/${currentUserProfile._id}/following/open`}
                        style={{ color: "black" }}
                      >
                        <Typography>
                          {currentUserProfile.nooffollowing}
                        </Typography>
                        <Icon className={classes.icons}>
                          <img src={FollowingIcon} />
                        </Icon>
                        {/* <FollowingIcon
                            className={classes.icons}
                            color="inherit"
                          /> */}
                      </Link>
                    </IconButton>
                    <IconButton fontSize="small">
                      <Link
                        to={`/user_profile/${currentUserProfile._id}/followers/open`}
                        style={{ color: "black" }}
                      >
                        <Typography>
                          {currentUserProfile.nooffollowers}
                        </Typography>
                        <Icon className={classes.icons}>
                          <img src={FollowersIcon} />
                        </Icon>
                        {/* <FollowersIcon
                            className={classes.icons}
                            color="inherit"
                          /> */}
                      </Link>
                    </IconButton>
                  </>
                )}
              </Typography>
            </Grid>
            <Grid item style={{ display: "flex", marginTop: 24 }}>
              {/* <Divider orientation="vertical" variant="fullWidth" /> */}
              <Typography component="div" className={classes.actionContainer}>
                <IconButton fontSize="small">
                  <Link
                    to={`/user_profile/${currentUserProfile._id}/likes`}
                    style={{ color: "black" }}
                  >
                    <Icon className={classes.icons}>
                      <img src={LikesIcon} />
                    </Icon>
                    {/* <LikeOpenIcon className={classes.icons} color="inherit" /> */}
                  </Link>
                </IconButton>
                <IconButton fontSize="small">
                  <Link
                    to={`/user_profile/${currentUserProfile._id}/favourites`}
                    style={{ color: "black" }}
                  >
                    <Icon className={classes.icons}>
                      <img src={FavouritesIcon} />
                    </Icon>
                    {/* <FavouriteIcon className={classes.icons} color="inherit" /> */}
                  </Link>
                </IconButton>
                {userDetail &&
                  userDetail.userType.toLowerCase() !==
                    "FreeUser".toLowerCase() &&
                  currentUserProfile.userType.toLowerCase() !==
                    "FreeUser".toLowerCase() &&
                  currentUserProfile.isChatEligible && (
                    <IconButton fontSize="small">
                      <Link
                        to={{
                          pathname: `/messages`,
                          state: { currentUserProfile },
                        }}
                        style={{ color: "black" }}
                      >
                        <Icon className={classes.icons}>
                          <img src={ChatIcon} />
                        </Icon>
                      </Link>
                    </IconButton>
                  )}
                {userDetail &&
                  userDetail.userType === "FreeUser" &&
                  currentUserProfile.userType === "FreeUser" &&
                  currentUserProfile.isChatEligible && (
                    <IconButton fontSize="small">
                      <Link
                        to={{
                          pathname: `/messages`,
                          state: { currentUserProfile },
                        }}
                        style={{ color: "black" }}
                      >
                        <Icon className={classes.icons}>
                          <img src={ChatIcon} />
                        </Icon>
                      </Link>
                    </IconButton>
                  )}
                {currentUserProfile.friendStatus &&
                  currentUserProfile.friendStatus.areYouFollowing &&
                  currentUserProfile.friendStatus.isHeFollowing &&
                  currentUserProfile.friendStatus.areYouFollowingType ===
                    currentUserProfile.friendStatus.isHeFollowingType &&
                  (currentUserProfile.friendStatus.areYouFollowingType ===
                  "Open" ? (
                    <IconButton
                      fontSize="small"
                      color="inherit"
                      onClick={(e) => handleUnfollow("Open")}
                    >
                      {/* <Badge
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={"O"}
                        style={{ display: "unset" }}
                      >
                        <DoneSharpIcon className={classes.icons} />
                      </Badge> */}
                      <Icon className={classes.icons}>
                        <img src={OFIcon} />
                      </Icon>
                    </IconButton>
                  ) : (
                    <IconButton
                      fontSize="small"
                      color="inherit"
                      onClick={(e) => handleUnfollow("Hidden")}
                    >
                      {/* <Badge
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={"H"}
                        style={{ display: "unset" }}
                      >
                        <DoneSharpIcon className={classes.icons} />
                      </Badge> */}
                      <Icon className={classes.icons}>
                        <img src={HFIcon} />
                      </Icon>
                    </IconButton>
                  ))}
                {currentUserProfile.friendStatus &&
                  currentUserProfile.friendStatus.areYouFollowing &&
                  currentUserProfile.friendStatus.isHeFollowing &&
                  currentUserProfile.friendStatus.areYouFollowingType !==
                    currentUserProfile.friendStatus.isHeFollowingType &&
                  (currentUserProfile.friendStatus.areYouFollowingType ===
                  "Open" ? (
                    <IconButton
                      fontSize="small"
                      color="inherit"
                      onClick={(e) => handleUnfollow("Open")}
                    >
                      {/* <Badge
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={"F"}
                        style={{ display: "unset" }}
                      > */}
                      {/* <RadioButtonUncheckedSharpIcon
                          className={classes.icons}
                        /> */}
                      <Icon className={classes.icons}>
                        <img src={ORIcon} />
                      </Icon>
                      {/* </Badge> */}
                    </IconButton>
                  ) : (
                    <IconButton
                      fontSize="small"
                      color="inherit"
                      onClick={(e) => handleUnfollow("Hidden")}
                    >
                      {/* <Badge
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={"F"}
                        style={{ display: "unset" }}
                      >
                        <Icon className={classes.icons}>H</Icon>
                      </Badge> */}
                      <Icon className={classes.icons}>
                        <img src={HRIcon} />
                      </Icon>
                    </IconButton>
                  ))}
                {currentUserProfile.friendStatus &&
                  !currentUserProfile.friendStatus.areYouFollowing &&
                  currentUserProfile.friendStatus.isHeFollowing &&
                  (currentUserProfile.friendStatus.isHeFollowingType ===
                  "Open" ? (
                    <>
                      <IconButton
                        fontSize="small"
                        color="inherit"
                        onClick={(e) => handleFollow()}
                      >
                        <Link style={{ color: "black" }}>
                          <AddSharpIcon
                            className={classes.icons}
                            color="inherit"
                          />
                        </Link>
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton
                        fontSize="small"
                        color="inherit"
                        onClick={(e) => handleFollow()}
                      >
                        <Link style={{ color: "black" }}>
                          <AddSharpIcon
                            className={classes.icons}
                            color="inherit"
                          />
                        </Link>
                      </IconButton>
                    </>
                  ))}
                {currentUserProfile.friendStatus &&
                  currentUserProfile.friendStatus.areYouFollowing &&
                  !currentUserProfile.friendStatus.isHeFollowing &&
                  (currentUserProfile.friendStatus.areYouFollowingType ===
                  "Open" ? (
                    <IconButton
                      fontSize="small"
                      color="inherit"
                      onClick={(e) => handleUnfollow("Open")}
                    >
                      {/* <Badge
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={"F"}
                        style={{ display: "unset" }}
                      >
                        <RadioButtonUncheckedSharpIcon
                          className={classes.icons}
                        />
                      </Badge> */}
                      <Icon className={classes.icons}>
                        <img src={ORIcon} />
                      </Icon>
                    </IconButton>
                  ) : (
                    <IconButton
                      fontSize="small"
                      color="inherit"
                      onClick={(e) => handleUnfollow("Hidden")}
                    >
                      {/* <Badge
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={"F"}
                        style={{ display: "unset" }}
                      >
                        <Icon className={classes.icons}>H</Icon>
                      </Badge> */}
                      <Icon className={classes.icons}>
                        <img src={HRIcon} />
                      </Icon>
                    </IconButton>
                  ))}
                {!currentUserProfile.friendStatus && (
                  <IconButton
                    fontSize="small"
                    color="inherit"
                    onClick={(e) => handleFollow()}
                  >
                    <Link style={{ color: "black" }}>
                      <AddSharpIcon className={classes.icons} color="inherit" />
                    </Link>
                  </IconButton>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Paper elevation={0} className={classes.tabPaper}>
          <Tabs
            TabIndicatorProps={{ className: classes.tabIndicator }}
            value={value}
            onChange={(e, newValue) => handleChange(newValue)}
            textColor="inherit"
            color="primary"
            variant="fullWidth"
            style={{ minHeight: 32 }}
          >
            <Tab
              style={{
                border:
                  value !== 0
                    ? "1px solid rgba(var(--b38,219,219,219),1)"
                    : "none",
                textTransform: "none",
                padding: 0,
                minHeight: 32,
              }}
              disableRipple
              icon={
                value === 0 ? (
                  <Icon className={classes.icons}>
                    <img src={PersonIconBlue} />
                  </Icon>
                ) : (
                  <Icon className={classes.icons}>
                    <img src={PersonIcon} />
                  </Icon>
                )
                // <PersonSharpIcon color={value === 0 ? "primary" : "inherit"} />
              }
              aria-label="Profile"
            />
            <Tab
              style={{
                border:
                  value !== 1
                    ? "1px solid rgba(var(--b38,219,219,219),1)"
                    : "none",
                textTransform: "none",
                padding: 0,
                minHeight: 32,
              }}
              disableRipple
              icon={
                value === 1 ? (
                  <Icon className={classes.icons}>
                    <img src={GalleryIconBlue} />
                  </Icon>
                ) : (
                  <Icon className={classes.icons}>
                    <img src={GalleryIcon} />
                  </Icon>
                )
                // <PhotoSharpIcon color={value === 1 ? "primary" : "inherit"} />
              }
              aria-label="Gallery"
            />
            {userDetail &&
              userDetail.userType.toLowerCase() !== "FreeUser".toLowerCase() &&
              currentUserProfile.userType.toLowerCase() !==
                "FreeUser".toLowerCase() && (
                <Tab
                  style={{
                    border:
                      value !== 2
                        ? "1px solid rgba(var(--b38,219,219,219),1)"
                        : "none",
                    textTransform: "none",
                    padding: 0,
                    minHeight: 32,
                  }}
                  disableRipple
                  icon={
                    // <DynamicFeedSharpIcon
                    //   color={value === 2 ? "primary" : "inherit"}
                    // />
                    value === 2 ? (
                      <Icon className={classes.icons}>
                        <img src={PostsIconBlue} />
                      </Icon>
                    ) : (
                      <Icon className={classes.icons}>
                        <img src={PostsIcon} />
                      </Icon>
                    )
                  }
                  aria-label="Posts"
                />
              )}

            {userDetail &&
              // userDetail.userType !== "SocialMediaUser" &&
              userDetail.userType.toLowerCase() !== "FreeUser".toLowerCase() &&
              currentUserProfile.userType !== "SocialMediaUser" &&
              currentUserProfile.userType.toLowerCase() !==
                "FreeUser".toLowerCase() && (
                <Tab
                  style={{
                    border:
                      value !== 3
                        ? "1px solid rgba(var(--b38,219,219,219),1)"
                        : "none",
                    textTransform: "none",
                    padding: 0,
                    minHeight: 32,
                  }}
                  disableRipple
                  icon={
                    // <EventNoteSharpIcon
                    //   color={value === 3 ? "primary" : "inherit"}
                    // />
                    value === 3 ? (
                      <Icon className={classes.icons}>
                        <img src={CalendarIconBlue} />
                      </Icon>
                    ) : (
                      <Icon className={classes.icons}>
                        <img src={CalendarIcon} />
                      </Icon>
                    )
                  }
                  aria-label="Calendar"
                />
              )}
          </Tabs>
          {value === 0 && (
            <ProfileInfo userDetails={currentUserProfile} classes={classes} />
          )}
          {value === 1 && (
            <UserGalleryComponent
              isLoggedIn={false}
              currentUserProfile={currentUserProfile}
              type={type}
              galleryId={galleryId}
            />
          )}
          {value === 2 &&
            userDetail.userType.toLowerCase() !== "FreeUser".toLowerCase() &&
            currentUserProfile.userType.toLowerCase() !==
              "FreeUser".toLowerCase() && (
              <PostFeed user_id={currentUserProfile._id} value={1} />
            )}
          {value === 3 &&
            userDetail &&
            // userDetail.userType !== "SocialMediaUser" &&
            userDetail.userType.toLowerCase() !== "FreeUser".toLowerCase() &&
            currentUserProfile.userType !== "SocialMediaUser" &&
            currentUserProfile.userType.toLowerCase() !==
              "FreeUser".toLowerCase() && (
              <CalendarComponent user_id={currentUserProfile._id} />
            )}
        </Paper>
      </Grid>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUserProfile: state.usersReducer.currentUserProfile,
    userDetail: state.userDetailsReducer.userDetail,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(withRouter(UserProfile))
);
