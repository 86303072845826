// import dependencies
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { TextInput } from "../../components/InputFields";
import {
  getPosByUser,
  // getEmpAllPos,
  getFilteredPositionListByUser,
} from "../../actions/getPosActions";
import { getUserProfileDetails } from "../../actions/userDetailsAction";

import bindActionCreators from "redux/es/bindActionCreators";
import connect from "react-redux/es/connect/connect";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Input from "@material-ui/core/Input";
import AddIcon from "@material-ui/icons/Add";
import MenuIcon from "@material-ui/icons/Menu";
import Popover from "@material-ui/core/Popover";
import Divider from "@material-ui/core/Divider";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";

import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import {
  isValidParam,
  getStringParam,
  getObjectParam,
  getArrayParam,
  getIntParam,
  getBooleanParam,
} from "../../helpers/paramVerifier";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import DataTable from "./DataTable";
import EmpDataTable from "../../components/Positions/PositionDataTable";
import Pagination from "@material-ui/lab/Pagination";
import PositionDataTablePagination from "../../components/Positions/PositionDataTablePagination";
import { Loader, NoRecords } from "../../components/UtilityComponents";
import { constants } from "../../helpers/constants";

import { Redirect, withRouter } from "react-router-dom";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  tableCellChild: {
    fontSize: 14,
    fontWeight: 600,
  },
  addButton: {
    width: 30,
    height: 20,
  },
  tableContainer: {
    width: "100%",
    //overflowY: 'hidden',
    overflowX: "auto",
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "8px",
  },
});

class PostPosTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      currentStatus: "All",
      searchHeader: "",
      isRedirect: false,
      redirectPath: null,
      pageNumber: 1,
      location: this.props.history.location.pathname,
    };
  }
  componentDidMount() {
    // if (this.props.userDetail.userType == constants.USER_TYPE_EMPLOYER) {
    //   // this.props.getEmpAllPos();
    //   //route changed so called below new route
    //   this.props.getPosByUser(null, this.props.userDetail._id);
    // } else {
    let job_id = this.props.match.params.ad_id;

    // this.props.getPosByUser(null, job_id);
    this.props.getFilteredPositionListByUser(
      job_id,
      this.state.pageNumber - 1,
      10
    );
    // }
    // if (this.props.userDetail._id) {
    //   this.props.getPosByUser(null, this.props.userDetail._id);
    // }
  }

  setLinkInfoOnFields() {
    let link = null;
    // if (this.props.userDetail.userType == constants.USER_TYPE_EMPLOYER) {
    //   link = {
    //     name: "_id",
    //     baseUrl: "/position/applicants/",
    //     event: () => {},
    //     style: null,
    //   };
    // } else {
    link = {
      name: "_id",
      baseUrl: "/position/apply/",
      event: () => {},
      style: null,
    };
    // }
    let fields = Object.assign([], getArrayParam(this.props.fields));
    fields.map((m) => {
      if (m.name == "positionName") {
        m.link = link;
      }
    });
    return fields;
  }

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose(e) {
    this.setState({ anchorEl: null });
  }
  handleChangeStatus(e, status) {
    this.setState({ currentStatus: status });
    this.setState({ anchorEl: null });
  }
  handleSearchHeader(e) {
    this.setState({ searchHeader: e.target.value });
  }
  handleChangePageNumber(page) {
    this.setState({ pageNumber: page });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.location !== this.props.history.location.pathname) {
      this.setState({ location: this.props.history.location.pathname });
    }
    if (prevState.pageNumber !== this.state.pageNumber) {
      let job_id = this.props.match.params.ad_id;

      this.props.getFilteredPositionListByUser(
        job_id,
        this.state.pageNumber - 1,
        10
      );
    }
  }

  redirect(path) {
    this.setState({ isRedirect: true, redirectPath: path });
  }

  render() {
    if (this.state.isRedirect) {
      this.state.isRedirect = false;
      return <Redirect to={this.state.redirectPath} />;
    }

    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;

    let { classes } = this.props;
    let userType = getStringParam(this.props.userDetail.userType);
    let fields = this.setLinkInfoOnFields();

    if (getBooleanParam(this.props.fetchingPos)) {
      return <Loader />;
    } else if (!getBooleanParam(this.props.fetchingPos)) {
      return (
        <Grid className={classes.root}>
          {userType == constants.USER_TYPE_JOBSEEKER && (
            <Grid>
              <Button
                color="secondary"
                variant="contained"
                size="small"
                onClick={(e) => {
                  this.props.history.push(`/positions`);
                }}
                style={{ float: "right", margin: "8px", textTransform: "none" }}
              >
                Back
              </Button>
              <ApplicantBreadcrumbs {...this.props} />

              <div className={classes.pagination}>
                {this.props.positionListFilteredByUser.length > 0 && (
                  <PositionDataTablePagination
                    count={this.props.pageCount && this.props.pageCount}
                    page={this.state.pageNumber}
                    handleChangePageNumber={(page) =>
                      this.handleChangePageNumber(page)
                    }
                  />
                )}
              </div>

              {/* {this.props.userDetail.userType ==
                constants.USER_TYPE_EMPLOYER && (
                <Grid style={{ float: "right", margin: 10 }}>
                  <Link to={`/positions-create`}>
                    <Fab
                      color="secondary"
                      className={classes.addButton}
                      title={"Add"}
                    >
                      <AddIcon />
                    </Fab>
                  </Link>
                </Grid>
              )} */}
              <Grid className={classes.tableContainer}>
                {this.props.positionListFilteredByUser.length > 0 && (
                  <DataTable
                    fields={fields}
                    values={this.props.positionListFilteredByUser}
                    tableStyle={{ height: "100%" }}
                  />
                )}
                {this.props.positionListFilteredByUser.length == 0 && (
                  <NoRecords label={"No Records Found."} />
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      );
    } else {
      return <NoRecords label={"No Records Found."} />;
    }
  }
}

function mapStateToProps(state) {
  return {
    userDetail: state.userDetailsReducer.userDetail,
    // PosTable: state.PosTableReducer,
    fetchingPos: state.PosTableReducer.fetchingPos,
    // newADCreated: state.postAnADReducer.newADCreated,
    fields: fields,
    positionListFilteredByUser:
      state.PosTableReducer.positionListFilteredByUser,
    pageCount: state.PosTableReducer.positionListTotalPages,
    currUser: state.usersReducer.currentUserProfile,
    // users: state.usersReducer.users
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      getPosByUser,
      // getEmpAllPos,
      getFilteredPositionListByUser,
      getUserProfileDetails,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(withRouter(PostPosTable)));

let fields = [
  {
    name: "positionType",
    label: "Type",
    width: 100,
    type: "text",
    is_hidden: false,
    link: null,
  },
  {
    name: "title",
    label: "Title",
    width: 100,
    type: "text",
    is_hidden: false,
    link: null,
  },
  {
    name: "role",
    label: "Role",
    width: 100,
    type: "text",
    is_hidden: false,
    link: true,
  },
  // {
  //   name: "otherrolename",
  //   label: "Role name",
  //   width: 100,
  //   type: "text",
  //   is_hidden: false,
  //   link: true,
  // },
  {
    name: "gender",
    label: "Gender",
    width: 50,
    type: "text",
    is_hidden: false,
    link: null,
  },
  {
    name: "ageMin",
    label: "Age",
    width: 50,
    type: "text",
    is_hidden: false,
    link: null,
  },
  {
    name: "positionStatus",
    label: "Status",
    width: 100,
    type: "text",
    is_hidden: false,
    link: null,
  },
  {
    name: "lastDate",
    label: "Last Date",
    width: 80,
    type: "date",
    is_hidden: false,
    link: null,
  },
  {
    name: "createdAt",
    label: "Posted Date",
    width: 80,
    type: "date",
    is_hidden: false,
    link: null,
  },
  {
    name: "country",
    label: "Country",
    width: 50,
    type: "text",
    is_hidden: false,
    link: null,
  },
  {
    name: "status",
    label: "Application Status",
    width: 50,
    type: "text",
    is_hidden: false,
    link: null,
  },
];

const ApplicantBreadcrumbs = ({ match, currUser, getUserProfileDetails }) => {
  const [state, setState] = useState(null);
  useEffect(() => {
    getUserProfileDetails(match.params.ad_id);
  }, [match.params.ad_id]);
  useEffect(() => {
    if (currUser) {
      setState(currUser);
    }
  }, [currUser]);

  return (
    <Breadcrumbs
      aria-label="Breadcrumb"
      style={{ margin: "30px", marginLeft: "10px" }}
    >
      <Typography color="textPrimary" style={{ fontSize: 14 }}>
        <Link to={`/positions`}>All Positions</Link>
      </Typography>
      <Typography color="textPrimary" style={{ fontSize: 14 }}>
        {!state || !state.bnr ? (
          <span>
            <i>Loading...</i>
          </span>
        ) : (
          <Link to={`/all-positions/${match.params.ad_id}`}>
            {state && state.bnr}
          </Link>
        )}
      </Typography>
      <Typography color="textPrimary" style={{ fontSize: 14 }}>
        Positions
      </Typography>
    </Breadcrumbs>
  );
};
