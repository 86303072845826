import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import PersonIcon from "@material-ui/icons/Person";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Avatar from "@material-ui/core/Avatar";
import NativeSelect from "@material-ui/core/NativeSelect";
import { Icon } from "@material-ui/core";
import { editApplicantStatus } from "../../../src/actions/getPosActions";
import getURL from "../../helpers/apis";
import { openAvatarModal } from "../../actions/appActions";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    overflowY: "scroll",
    maxHeight: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  col: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid rgba(162, 69, 69, 0.12)",
    padding: "12px",
    cursor: "pointer",
    borderRadius: "8px",
    marginBottom: "5px",
    "&:hover": {
      backgroundColor: "rgb(248, 247, 248)",
    },
  },
  row: {
    marginTop: "4px",
    display: "flex",
    justifyContent: "space-between",
  },
  like: {
    display: "flex",
  },
  noOfLikesTypography: {
    marginLeft: "8px",
  },
  noRecordsAvialable: {
    display: "flex",
    justifyContent: "center",
    marginTop: "200px",
    marginLeft: "90px",
  },
}));

function ApplicantDetails(props) {
  const { applicantList, tabValue } = props;
  const classes = useStyles();
  const rootUrl = getURL("UPLOADS");
  const dispatch = useDispatch();

  const [applicantStatus, setApplicantStatus] = useState("");
  const [currentApplicantId, setCurrentApplicantId] = useState("");

  const handleChangeStatus = (event, id) => {
    setCurrentApplicantId(id);
    setApplicantStatus(event.target.value);

    const body = {
      status: event.target.value,
    };
    dispatch(editApplicantStatus(id, body));
  };
  const optionsList = {
    Applied: ["Applied", "ShortListed", "Rejected", "Selected"],
    ShortListed: ["ShortListed", "Rejected", "Selected"],
    Rejected: ["Rejected", "ShortListed", "Selected"],
    Selected: ["Selected", "ShortListed", "Rejected"],
  };
  return (
    <div className={classes.root}>
      {applicantList.length > 0 ? (
        applicantList.map((applicant, index) => {
          return (
            <>
              <div
                className={classes.col}
                key={applicant._id}
                onClick={(e) =>
                  props.history.push(
                    `/applicants/${applicant.positionId}/${applicant.userInfo._id}`
                  )
                }
              >
                <div className={classes.row}>
                  <div style={{ display: "flex" }}>
                    {applicant.userInfo.profilepic ? (
                      <Avatar
                        style={{
                          width: 32,
                          height: 32,
                        }}
                        alt="owner"
                        src={process.env.REACT_APP_BUCKET_NAME + applicant.userInfo.profilepic}
                        onClick={(e) =>
                          dispatch(
                            openAvatarModal(process.env.REACT_APP_BUCKET_NAME + applicant.userInfo.profilepic)
                          )
                        }
                      />
                    ) : (
                      <Avatar
                        style={{
                          width: 32,
                          height: 32,
                        }}
                        alt="owner"
                        onClick={(e) => dispatch(openAvatarModal())}
                      >
                        <PersonIcon />
                      </Avatar>
                    )}
                    <Link
                      to={`/applicants/${applicant.positionId}/${applicant.status}/${applicant.userInfo._id}`}
                      style={{ marginLeft: 6 }}
                    >
                      <Typography variant="h6" gutterBottom>
                        {applicant.userInfo.username}
                      </Typography>
                    </Link>
                  </div>

                  <div className={classes.like}>
                    <Icon color="primary">thumb_up_alt</Icon>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      className={classes.noOfLikesTypography}
                    >
                      {applicant.userInfo.noofprofilelikes}
                    </Typography>
                  </div>
                </div>
                <div className={classes.row}>
                  <div style={{ display: "flex" }}>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      style={{ marginRight: 6 }}
                    >
                      {applicant.userInfo.mainrole}
                    </Typography>{" "}
                    •{" "}
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      style={{ marginLeft: 6 }}
                    >
                      {moment(applicant.createdAt).format("DD-MM-YYYY")}
                    </Typography>
                  </div>
                  <FormControl
                    className={classes.formControl}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Select
                      native
                      disabled={
                        props.currentPosition &&
                        props.currentPosition.positionStatus === "Closed"
                      }
                      value={
                        applicant._id === currentApplicantId
                          ? applicantStatus
                          : applicant.status
                      }
                      onChange={(e) => handleChangeStatus(e, applicant._id)}
                      inputProps={{
                        name: "applicantStatus",
                        id: "applicantStatus-native-simple",
                      }}
                    >
                      {optionsList[tabValue].map((status) => {
                        return (
                          <option
                            value={status}
                          >
                            {status}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </>
          );
        })
      ) : (
        <div className={classes.noRecordsAvialable}>
          {" "}
          <Typography variant="h6" gutterBottom>
            There aren't any records.
          </Typography>
        </div>
      )}
    </div>
  );
}

export default withRouter(ApplicantDetails);
