// import dependencies
import React from "react";
import { Link } from "react-router-dom";

export default class ProfileSideNavCard extends React.Component {
  render() {
    const match = this.props.match;
    return (
      <div className={"card"}>
        <div className={"col-lg-12 col-md-4 col-sm-4 nav-cont"}>
          <Link to={`${match.url}`} className={"side-nav"}>
            <img
              width="24px"
              src="/assets/images/icons/profile_icon.png"
              alt=""
            />
            <div className={"item-name"}>My Profile</div>
          </Link>
        </div>
        <div className={"col-lg-12 col-md-4 col-sm-4 nav-cont"}>
          <Link to={`${match.url}/photos`} className={"side-nav"}>
            <img
              width="24px"
              src="/assets/images/icons/my_photo_icon.png"
              alt=""
            />
            <div className={"item-name"}>My Photo</div>
          </Link>
        </div>
        <div className={"col-lg-12 col-md-4 col-sm-4 nav-cont"}>
          <Link to={`${match.url}/videos`} className={"side-nav"}>
            <img
              width="24px"
              src="/assets/images/icons/video_icon.png"
              alt=""
            />
            <div className={"item-name"}>Video</div>
          </Link>
        </div>
      </div>
    );
  }
}
