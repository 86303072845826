import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CameraAltSharpIcon from "@material-ui/icons/CameraAltSharp";
import LockSharpIcon from "@material-ui/icons/LockSharp";
// import ShareToMeIcon from "@material-ui/icons/ReplySharp";
import LockOpenSharpIcon from "@material-ui/icons/LockOpenSharp";
import { Paper, Tabs, Tab, IconButton, ButtonGroup } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import GalleryComponent from "../GalleryComponent";
import {
  getPublicGallery,
  getPrivateGallery,
  getDeptGallery,
} from "../../actions/applicantsDetailsActions";
import ShareToMeIcon from "../../assets/svg/Sharedtome icon.svg";
import PublicIconBlue from "../../assets/svg/Public Icon (blue).svg";
import DepartmentIconBlue from "../../assets/svg/Department Icon (blue).svg";
import PrivateIconBlue from "../../assets/svg/Private Icon (blue).svg";
import PublicIcon from "../../assets/svg/Public Icon (black).svg";
import DepartmentIcon from "../../assets/svg/Department Icon.svg";
import PrivateIcon from "../../assets/svg/Private Icon (black).svg";
import { withStyles } from "@material-ui/core";
import { Icon } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ActedOnEntity from "../ActedOnEntity";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: "100%",
    borderBottom: "1px solid rgba(var(--b38,219,219,219),1)",
    overflowY: "scroll",
    height: "75vh",
  },
  tabIndicator: {
    height: "2.5px",
    backgroundColor: "#3f51b5",
  },
  icons: {
    cursor: "pointer",
    height: "auto",
  },
});

const UserGalleryComponent = ({
  match,
  isLoggedIn,
  userDetail,
  currentUserProfile,
  type = "public",
  galleryId = null,
}) => {
  const classes = useStyles();
  var history = useHistory();
  const [value, setValue] = useState(0);
  const [publicPhotos, setPublicPhotos] = useState([]);
  const [privatePhotos, setPrivatePhotos] = useState([]);
  const [departmentPhotos, setDepartmentPhotos] = useState([]);
  const [refresh, setRefresh] = useState(false);
  var id;
  if (isLoggedIn) id = userDetail._id;
  else {
    id = match.params.user_id ?? match.params.applicant_id;
  }

  const handleUploadBtnClick = () => {
    let type = value === 0 ? "public" : value === 1 ? "department" : "private";
    history.push(`/profile-me/my-gallery/upload/${type}`);
  };

  useEffect(() => {
    type === "public"
      ? setValue(0)
      : type === "department"
      ? setValue(1)
      : setValue(2);
  }, [type]);

  useEffect(() => {
    value === 0 &&
      getPublicGallery(id).then((response) => {
        if (!response.error) {
          setPublicPhotos(response.data);
        }
      });

    value === 1 &&
      getDeptGallery(id).then((response) => {
        if (!response.error) {
          setDepartmentPhotos(response.data);
        }
      });
    isLoggedIn &&
      value === 2 &&
      getPrivateGallery(id).then((response) => {
        if (!response.error) {
          setPrivatePhotos(response.data);
        }
      });
  }, [value, isLoggedIn, refresh]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  return (
    <Paper elevation={0} className={classes.root}>
      <Tabs
        TabIndicatorProps={{ className: classes.tabIndicator }}
        value={value}
        onChange={handleChange}
        textColor="inherit"
        centered
        style={{ minHeight: 32 }}
      >
        <Tab
          disableRipple
          icon={
            // <LockOpenSharpIcon color={value === 0 ? "primary" : "inherit"} />
            value === 0 ? (
              <Icon className={classes.icons}>
                <img src={PublicIconBlue} />
              </Icon>
            ) : (
              <Icon className={classes.icons}>
                <img src={PublicIcon} />
              </Icon>
            )
          }
          aria-label="Public"
          style={{ textTransform: "none", padding: 0, minHeight: 32 }}
        />
        <Tab
          disableRipple
          icon={
            // <CameraAltSharpIcon color={value === 1 ? "primary" : "inherit"} />
            value === 1 ? (
              <Icon className={classes.icons}>
                <img src={DepartmentIconBlue} />
              </Icon>
            ) : (
              <Icon className={classes.icons}>
                <img src={DepartmentIcon} />
              </Icon>
            )
          }
          aria-label="Department"
          style={{ textTransform: "none", padding: 10, minHeight: 32 }}
        />
        {!isLoggedIn &&
          userDetail &&
          userDetail.userType !== "FreeUser" &&
          currentUserProfile &&
          currentUserProfile.userType !== "FreeUser" && (
            <Tab
              disableRipple
              style={{ textTransform: "none", padding: 10, minHeight: 32 }}
              icon={
                isLoggedIn ? (
                  // <LockSharpIcon color={value === 2 ? "primary" : "inherit"} />
                  value === 2 ? (
                    <Icon className={classes.icons}>
                      <img src={PrivateIconBlue} />
                    </Icon>
                  ) : (
                    <Icon className={classes.icons}>
                      <img src={PrivateIcon} />
                    </Icon>
                  )
                ) : (
                  // <ShareToMeIcon
                  //   className={classes.icons}
                  //   color={value === 2 ? "primary" : "inherit"}
                  //   style={{
                  //     transform: "rotate(270.5deg)",
                  //   }}
                  // />
                  <Icon
                    color={value === 2 ? "primary" : "inherit"}
                    className={classes.icons}
                  >
                    <img src={ShareToMeIcon} />
                  </Icon>
                )
              }
              aria-label="Private"
            />
          )}
        {isLoggedIn && (
          <Tab
            disableRipple
            style={{ textTransform: "none", padding: 10, minHeight: 32 }}
            icon={
              value === 2 ? (
                <Icon className={classes.icons}>
                  <img src={PrivateIconBlue} />
                </Icon>
              ) : (
                <Icon className={classes.icons}>
                  <img src={PrivateIcon} />
                </Icon>
              )
            }
            aria-label="Private"
          />
        )}

        {isLoggedIn && (
          <ButtonGroup
            variant="outlined"
            style={{
              position: "absolute",
              right: "35px",
              top: 2,
              height: 32,
            }}
            color="primary"
          >
            <IconButton onClick={() => handleUploadBtnClick()}>
              <AddIcon fontSize="large" />
            </IconButton>
          </ButtonGroup>
        )}
      </Tabs>

      {value === 0 && publicPhotos.length > 0 && (
        <GalleryComponent
          gallery={publicPhotos}
          galleryLoading={false}
          handleRefresh={handleRefresh}
          galleryId={type === "public" ? galleryId : null}
        />
      )}
      {value === 1 && departmentPhotos.length > 0 && (
        <GalleryComponent
          gallery={departmentPhotos}
          galleryLoading={false}
          handleRefresh={handleRefresh}
          galleryId={type === "department" ? galleryId : null}
        />
      )}
      {isLoggedIn && value === 2 && privatePhotos.length > 0 && (
        <GalleryComponent
          gallery={privatePhotos}
          galleryLoading={false}
          isPrivate={true}
          handleRefresh={handleRefresh}
        />
      )}
      {!isLoggedIn &&
        value === 2 &&
        userDetail &&
        userDetail.userType !== "FreeUser" &&
        currentUserProfile &&
        currentUserProfile.userType !== "FreeUser" && (
          <ActedOnEntity actionName="sharedtome" value={value} />
        )}
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetail: state.userDetailsReducer.userDetail,
  };
};

export default connect(mapStateToProps)(withRouter(UserGalleryComponent));
