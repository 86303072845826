export const constants = {
  USER_TYPE_EMPLOYER: "Employer",
  USER_TYPE_JOBSEEKER: "JobSeeker",
  USER_TYPE_SOCIALMEDIAUSER: "SocialMediaUser",

  PUBLIC: "public",
  DEPARTMENT: "department",
  PRIVATE: "private",
  FOLLWERS: "followers",
  FOLLWING: "follwing",
  SHARED_TO_ME: "sharedToMe",
  LIKES: "likes",
  OPEN: "Open",
  HIDDEN: "Hidden",

  GALLERY_TYPE_VIDEOS: "videos",
  GALLERY_TYPE_PHOTOS: "photos",
  PRIMARY_COLOR: "#3f51b5",
};

export const genderOptionsList = ["Any", "Male", "Female"];

export const positionOptionsList = ["Full Time", "Contract"];
