import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import withRouter from "react-router-dom/es/withRouter";
import {
  Tabs,
  Tab,
  Typography,
  Chip,
  IconButton,
  Icon,
  Badge,
  FormControl,
  Select,
  MenuItem,
  AppBar,
} from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import Avatar from "@material-ui/core/Avatar";
import Pagination from "@material-ui/lab/Pagination";
import UserProfile from "../UserProfile";
import ProfileDetails from '../ProfileDetails'
import { withStyles } from "@material-ui/core/styles";
import ProfileLikeIcon from "../../assets/svg/Profile Like icon.svg";
import ProfileLikeIconBlue from "../../assets/svg/Profile Like icon (blue).svg";
// import ProfileLikeIcon from "@material-ui/icons/ThumbUpAltSharp";
import LikeOpenIcon from "@material-ui/icons/FavoriteBorderSharp";
import FavouriteIcon from "@material-ui/icons/BookmarkBorderSharp";
import { useDispatch, useSelector } from "react-redux";

import { constants } from "../../helpers/constants";

import { getUserProfileDetails } from "../../actions/userDetailsAction";
import {
  unfollow,
  follow,
  editCustomName,
  unblock,
  block,
  likeProfile,
  unlikeProfile,
} from "../../actions/applicantsDetailsActions";
import {
  getPosApplicants,
  editApplicantStatus,
} from "../../../src/actions/getPosActions";

import getURL from "../../helpers/apis";
import { openAvatarModal } from "../../actions/appActions";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    marginTop: "30px",
    backgroundColor: theme.palette.background.paper,
  },
  mainContent: {
    minHeight: 600,
  },
  paper: {
    fontSize: 14,
    width: "100%",
    minWidth: 275,
    margin: 10,
    maxWidth: 1200,
  },
  avatar: {
    fontSize: 100,
    margin: "auto",
    width: 120,
    height: 120,
  },
  columnContainer: {
    padding: 5,
  },
  fieldGridLabel: {
    // width: "30%",
    fontSize: 18,
    margin: 5,
  },
  profileTitle: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: 600,
    padding: 10,
  },
  fieldGridValue: {
    // width: "68%",
    fontSize: 18,
    margin: 5,
  },
  fieldLabel: {
    fontSize: 14,
    // fontWeight: 600,
  },
  fieldValue: {
    fontSize: 14,
  },
  pagination: {
    margin: "8px 0px",
    display: "flex",
    justifyContent: "center",
  },
  userName: {
    margin: "20px auto 10px",
    textAlign: "center",
  },
  navIcons: {
    display: "block",
    marginTop: "-20px",
    overflow: "hidden",
    pointerEvents: "auto",
    position: "fixed",
    textIndent: "-9999em",
    top: "50%",
    cursor: "pointer",
    color: "white",
  },
});

// const avatarLetters = (firstString, secondString) =>
//   `${firstString[0]}${secondString[0]}`.toUpperCase();

const ApplicantProfileViewer = ({
  dispatch,
  classes,
  match,
  currentUserProfile,
  applicants,
  history,
  positionList,
}) => {
  const [position_id, setPosition_id] = useState(match.params.position_id);
  const [userId, setUserId] = useState(match.params.applicant_id);
  const [status, setStatus] = useState(null);
  const [currentApplicant, setCurrentApplicant] = useState(null);
  const [page, setPage] = useState(1);
  const [value, setValue] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [filteredApplicants, setFilteredApplicants] = useState({});
  const currentPosition = positionList.find((pos) => pos._id === position_id);

  const array = ["Applied", "ShortListed", "Rejected", "Selected"];
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const app = applicants;
    var filter =
      app && app.filter((applicant) => applicant.status === "Applied");
    var filter1 =
      app && app.filter((applicant) => applicant.status === "ShortListed");
    var filter2 =
      app && app.filter((applicant) => applicant.status === "Rejected");
    var filter3 =
      app && app.filter((applicant) => applicant.status === "Selected");
    setFilteredApplicants({
      ["Applied"]: filter,
      ["ShortListed"]: filter1,
      ["Rejected"]: filter2,
      ["Selected"]: filter3,
    });
  }, [applicants]);

  useEffect(() => {
    setPosition_id(match.params.position_id);
    setUserId(match.params.applicant_id);
  }, [match]);

  useEffect(() => {
    if (currentApplicant) {
      dispatch(getUserProfileDetails(currentApplicant.userInfo._id));
    }
  }, [currentApplicant, refresh]);

  useEffect(() => {
    position_id && dispatch(getPosApplicants(position_id));
  }, [position_id]);

  const handleProfileUnLike = () => {
    unlikeProfile({
      user_id: currentUserProfile && currentUserProfile._id,
      type: "user",
    }).then((res) => !res.error && setRefresh(!refresh));
  };
  const handleProfileLike = () => {
    likeProfile({
      user_id: currentUserProfile && currentUserProfile._id,
      type: "user",
    }).then((res) => !res.error && setRefresh(!refresh));
  };

  useEffect(() => {
    if (filteredApplicants[array[value]]) {
      if (filteredApplicants[array[value]].length === 0) {
        history.push(`/positions/${position_id}`);
      } else if (
        filteredApplicants[array[value]].filter(
          (user) => user.userInfo._id === userId
        ).length === 0
      ) {
        if (page <= filteredApplicants[array[value]].length) {
          history.push(
            `/applicants/${position_id}/${
              filteredApplicants[array[value]][page - 1].userInfo._id
            }`
          );
        } else {
          setPage(filteredApplicants[array[value]].length);
          // history.push(
          //   `/applicants/${position_id}/${
          //     filteredApplicants[array[value]][
          //       filteredApplicants[array[value]].length - 1
          //     ].userInfo._id
          //   }`
          // );
        }
      } else {
        setCurrentApplicant(
          filteredApplicants[array[value]].filter(
            (user) => user.userInfo._id === userId
          )[0]
        );
      }
    }
  }, [filteredApplicants, page, userId, value]);

  const handleChangePage = (e, newValue) => {
    setPage(newValue);
    const usersList = filteredApplicants[array[value]];
    if(usersList && usersList.length >= (newValue -1))
    setUserId(usersList[newValue - 1].userInfo._id);
  };

  const handleChangeStatus = (event, id) => {
    const body = {
      status: event.target.value,
    };
    dispatch(editApplicantStatus(id, body));
  };

  const optionsList = {
    Applied: ["Applied", "ShortListed", "Rejected", "Selected"],
    ShortListed: ["ShortListed", "Rejected", "Selected"],
    Rejected: ["Rejected", "ShortListed", "Selected"],
    Selected: ["Selected", "ShortListed", "Rejected"],
  };

  useEffect(() => {
    const userStatus =
      applicants &&
      applicants.length &&
      userId &&
      applicants.filter((app) => app.userInfo._id === userId)[0].status;
    setStatus(userStatus);
  }, [applicants.length]);

  useEffect(() => {
    setValue(array.indexOf(status));
  }, [status]);

  return (
    <Paper className={classes.root}>
      <div className={classes.applicantHead}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            component={Link}
            role="button"
            to="/all-positions/"
            className={classes.positionTabHead}
            style={{
              display: "flex",
              textDecoration: "none",
              color: constants.PRIMARY_COLOR,
            }}
          >
            All Positions
          </Typography>
          <Typography
            color="inherit"
            variant="subtitle1"
            style={{ margin: "0px 6px" }}
          >
            /
          </Typography>

          <Typography color="inherit" variant="subtitle1">
            {currentPosition && currentPosition.role}
          </Typography>
        </div>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          style={{ float: "right", marginRight: 12, textTransform: "none" }}
          onClick={() => history.push(`/positions/${position_id}`)}
        >
          Back
        </Button>
      </div>

      <AppBar position="static" color="primary" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          className={classes.tabs}
          // variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto simple tabs example"
          centered
        >
          <Tab
            label={`Applied (${
              filteredApplicants[array[0]] &&
              filteredApplicants[array[0]].length
            })`}
            {...a11yProps(0)}
            disabled={
              filteredApplicants[array[0]] &&
              filteredApplicants[array[0]].length < 1
            }
          />
          <Tab
            label={`ShortListed (${
              filteredApplicants[array[1]] &&
              filteredApplicants[array[1]].length
            })`}
            {...a11yProps(1)}
            disabled={
              filteredApplicants[array[1]] &&
              filteredApplicants[array[1]].length < 1
            }
          />
          <Tab
            label={`Rejected (${
              filteredApplicants[array[2]] &&
              filteredApplicants[array[2]].length
            })`}
            {...a11yProps(2)}
            disabled={
              filteredApplicants[array[2]] &&
              filteredApplicants[array[2]].length < 1
            }
          />
          <Tab
            label={`Selected (${
              filteredApplicants[array[3]] &&
              filteredApplicants[array[3]].length
            })`}
            {...a11yProps(3)}
            disabled={
              filteredApplicants[array[3]] &&
              filteredApplicants[array[3]].length < 1
            }
          />
        </Tabs>
      </AppBar>

      {currentUserProfile && currentUserProfile._id == userId && (
        <Grid container spacing={0} className={classes.mainContent}>
          
          <Grid item xs={12}>
            <UserProfile isUsedInCasting={true} currentUserProfile={currentUserProfile} classes={classes} />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <FormControl>
              <Select
                value={array[value]}
                disabled={
                  currentPosition && currentPosition.positionStatus === "Closed"
                }
                onChange={(e) => handleChangeStatus(e, currentApplicant._id)}
                inputProps={{
                  name: "applicantStatus",
                  id: "applicantStatus-native-simple",
                }}
              >
                {array[value] &&
                  optionsList[array[value]].map((item) => {
                    return (
                      <MenuItem value={item} disabled={item === array[value]}>
                        {item}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
      {value >= 0 && filteredApplicants && filteredApplicants[array[value]] && (
        <div className={classes.pagination}>
          <Pagination
            count={filteredApplicants[array[value]].length}
            page={page}
            onChange={handleChangePage}
            color="secondary"
          />
        </div>
      )}
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUserProfile: state.usersReducer.currentUserProfile,
    applicants: state.applicantsReducer.applicants,
    positionList: state.PosTableReducer.Pos,
  };
};
export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(ApplicantProfileViewer))
);
