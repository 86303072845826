import React, { useState, useEffect } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import withRouter from "react-router-dom/es/withRouter";
import bindActionCreators from "redux/es/bindActionCreators";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Pagination from "@material-ui/lab/Pagination";
import ListItem from "@material-ui/core/ListItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListSubheader from "@material-ui/core/ListSubheader";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import PersonIcon from "@material-ui/icons/Person";
// import FollowingIcon from "@material-ui/icons/ArrowUpward";
// import FollowersIcon from "@material-ui/icons/ArrowDownward";
import FollowingIcon from "../assets/svg/Following (black).svg";
import FollowersIcon from "../assets/svg/Followers (black).svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FormGroup from "@material-ui/core/FormControl";
import FormControl from "@material-ui/core/FormControl";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import { Loader } from "./UtilityComponents";
import getURL from "../helpers/apis";
import ConfirmationDialog from "./ConfirmationDialog";
import CustomNameDialog from "./CustomNameDialog";
import Carousel from "./GalleryComponent/Carousel";
import InfiniteScroll from "react-infinite-scroller";
// import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { decryptText, encryptText } from "../helpers/globalFunctions";
import { useDispatch, useSelector } from "react-redux";
import { openAvatarModal, openDialog } from "../actions/appActions";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Menu from "@material-ui/icons/Menu";

import {
  getFollowing,
  getFollowers,
  follow,
  unfollow,
  getBlockedUsers,
  unblock,
  getSharedToMe,
  getAllMyComments,
  getAllRepliesToMyComment,
} from "../actions/applicantsDetailsActions";

const filterStamps = (value) => {
  return value.replaceAll(/&\d\d:\d\d&/g, (item) => item.replaceAll("&", ""));
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "20px",
    // height: "75vh",
    // overflowY: "scroll",
  },
  title: {
    // marginBottom: theme.spacing(3),
    display: "flex",
    alignItems: "center",
  },
  filter: {
    flexDirection: "row",
    justifyContent: "center",
    marginRight: "12.5%",
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "8px",
  },
  collapse: {
    display: "flex",
    flexGrow: 1,
    // transition: theme.transitions.create(["margin"], transition),
    margin: 12,
    "&$expanded": {
      margin: 0,
    },
  },
  commentBody: {
    maxWidth: "100%",
  },
  expanded: {
    margin: 0,
    padding: 0,
  },
  before: {
    "&:before": { opacity: 0.69 },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    alignSelf: "center",
    textAlign: "left",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.primary,
    alignSelf: "left",
    justifySelf: "left",
  },
  icons: {
    cursor: "pointer",
    // fontSize: 24,
    // height: "auto",
    // marginLeft: 15,
    // marginRight: 15,
  },
}));

const UserList = ({ match, action }) => {
  const [otherUser, setOtherUser] = useState(null);
  const [actionName, setActionName] = useState(
    action || match.params.actionName
  );
  const [type, setType] = useState(null);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [gender, setGender] = useState("All");
  const [list, setList] = useState(null);
  const [allcount, setallcount] = useState(null);
  const [malecount, setmalecount] = useState(null);
  const [femalecount, setfemalecount] = useState(null);
  const [followingcount, setfollowingcount] = useState(null);
  const [followerscount, setfollowerscount] = useState(null);
  const [opencount, setopencount] = useState(null);
  const [hiddencount, sethiddencount] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openCustom, setOpenCustom] = useState(false);
  const [openGallery, setOpenGallery] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [replyPage, setReplyPage] = useState(1);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [user_id, setUser_id] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [key, setKey] = useState("");
  const [replyKey, setReplyKey] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [expanded, setExpanded] = useState(null);
  const [repliesList, setRepliesList] = useState(null);
  const userDetail = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.userDetail
  );
  const replyLimit = 3;
  const limit = 20;
  const history = useHistory();

  const rootUrl = getURL("UPLOADS");

  useEffect(() => {
    setOtherUser(match.params.user_id || "");
    setActionName(action || match.params.actionName);
  }, [match.params]);

  useEffect(() => {
    if (otherUser !== null) {
      setType(
        (!otherUser || otherUser === "") && match.params.type === "open"
          ? "Open"
          : (!otherUser || otherUser === "") && match.params.type === "hidden"
          ? "Hidden"
          : null
      );
    }
  }, [otherUser]);

  useEffect(() => {
    if (otherUser !== null) {
      // setList(null);
      setPage(1);
      setReplyPage(1);
      // setfollowerscount(null);
      // setfollowingcount(null);

      if (
        (!otherUser || otherUser === "") &&
        actionName === "following" &&
        type !== null
      ) {
        let params = {
          type: type,
          page: 0,
          limit: limit,
          gender: gender,
          key: key,
        };
        getFollowing(params).then((Obj) => {
          setList(Obj.data);
          setallcount(Obj.allcount);
          setmalecount(Obj.malecount);
          setfemalecount(Obj.femalecount);
          setfollowerscount(Obj.followerscount);
          setTotalPages(Obj.totalPages);
        });
      } else if (
        (!otherUser || otherUser === "") &&
        actionName === "followers" &&
        type !== null
      ) {
        let params = {
          type: type,
          page: 0,
          limit: limit,
          gender: gender,
          key: key,
        };
        getFollowers(params).then((Obj) => {
          setList(Obj.data);
          setallcount(Obj.allcount);
          setmalecount(Obj.malecount);
          setfemalecount(Obj.femalecount);
          setfollowingcount(Obj.followingcount);
          setTotalPages(Obj.totalPages);
        });
      } else if (
        (!otherUser || otherUser === "") &&
        actionName === "mycomments" &&
        type !== null
      ) {
        let params = {
          commenttype: type,
          page: 0,
          limit: limit,
          gender: gender,
          key: encryptText(key),
        };
        getAllMyComments(params).then((Obj) => {
          if (type === "Open") {
            setopencount(Obj.allcount);
            sethiddencount(Obj.hiddencount);
            setallcount(Obj.allcount);
          } else if (type === "Hidden") {
            setallcount(Obj.opencount);
            setopencount(Obj.opencount);
            sethiddencount(Obj.allcount);
          }
          setList(Obj.data);
          setmalecount(Obj.malecount);
          setfemalecount(Obj.femalecount);
          setfollowingcount(null);
          setfollowerscount(null);
          setTotalPages(Obj.totalPages);
        });
      } else if ((!otherUser || otherUser === "") && actionName === "blocked") {
        let params = {
          page: 0,
          limit: limit,
          gender: gender,
          key: key,
        };
        getBlockedUsers(params).then((Obj) => {
          setList(Obj.data);
          setallcount(Obj.allcount);
          setmalecount(Obj.malecount);
          setfemalecount(Obj.femalecount);
          setfollowingcount(null);
          setfollowerscount(null);
          setTotalPages(Obj.totalPages);
        });
      } else if (
        (!otherUser || otherUser === "") &&
        actionName === "sharedtome"
      ) {
        let params = {
          page: 0,
          limit: limit,
          gender: gender,
          key: key,
        };
        getSharedToMe(params).then((Obj) => {
          setList(Obj.data);
          setallcount(Obj.allcount);
          setmalecount(Obj.malecount);
          setfemalecount(Obj.femalecount);
          setfollowingcount(null);
          setfollowerscount(null);
          setTotalPages(Obj.totalPages);
        });
      } else if (otherUser && otherUser !== "" && actionName === "following") {
        let params = {
          userId: otherUser,
          type: "Open",
          page: 0,
          limit: limit,
          gender: gender,
          key: key,
        };
        getFollowing(params).then((Obj) => {
          setList(Obj.data);
          setallcount(Obj.allcount);
          setmalecount(Obj.malecount);
          setfemalecount(Obj.femalecount);
          setfollowerscount(Obj.followerscount);
          setTotalPages(Obj.totalPages);
        });
      } else if (otherUser && otherUser !== "" && actionName === "followers") {
        let params = {
          userId: otherUser,
          type: "Open",
          page: 0,
          limit: limit,
          gender: gender,
          key: key,
        };
        getFollowers(params).then((Obj) => {
          setList(Obj.data);
          setallcount(Obj.allcount);
          setmalecount(Obj.malecount);
          setfemalecount(Obj.femalecount);
          setfollowingcount(Obj.followingcount);
          setTotalPages(Obj.totalPages);
        });
      } else if (otherUser && otherUser !== "" && actionName === "sharedtome") {
        let params = {
          userId: otherUser,
          page: 0,
          limit: limit,
          gender: gender,
          key: key,
        };
        getSharedToMe(params).then((Obj) => {
          setList(Obj.data);
          setallcount(Obj.allcount);
          setmalecount(Obj.malecount);
          setfemalecount(Obj.femalecount);
          setfollowingcount(null);
          setfollowerscount(null);
          setTotalPages(Obj.totalPages);
        });
      }
    }
  }, [type, gender, actionName, refresh, key, otherUser]);

  useEffect(() => {
    if (otherUser && otherUser !== null && otherUser !== "" && page !== 1) {
      setfollowerscount(null);
      setfollowingcount(null);
      if (
        (!otherUser || otherUser === "") &&
        actionName === "following" &&
        type !== null
      ) {
        let params = {
          type: type,
          page: page - 1,
          limit: limit,
          gender: gender,
          key: key,
        };
        getFollowing(params).then((Obj) => {
          list && list.length > 0
            ? setList([...list, ...Obj.data])
            : setList(Obj.data);
          setallcount(Obj.allcount);
          setmalecount(Obj.malecount);
          setfemalecount(Obj.femalecount);
          setfollowerscount(Obj.followerscount);
          setTotalPages(Obj.totalPages);
        });
      } else if (
        (!otherUser || otherUser === "") &&
        actionName === "followers" &&
        type !== null
      ) {
        let params = {
          type: type,
          page: page - 1,
          limit: limit,
          gender: gender,
          key: key,
        };
        getFollowers(params).then((Obj) => {
          list && list.length > 0
            ? setList([...list, ...Obj.data])
            : setList(Obj.data);
          setallcount(Obj.allcount);
          setmalecount(Obj.malecount);
          setfemalecount(Obj.femalecount);
          setfollowingcount(Obj.followingcount);
          setTotalPages(Obj.totalPages);
        });
      } else if (
        (!otherUser || otherUser === "") &&
        actionName === "mycomments" &&
        type !== null
      ) {
        let params = {
          commenttype: type,
          page: page - 1,
          limit: limit,
          gender: gender,
          key: encryptText(key),
        };
        getAllMyComments(params).then((Obj) => {
          list && list.length > 0
            ? setList([...list, ...Obj.data])
            : setList(Obj.data);
          setallcount(Obj.allcount);
          setmalecount(Obj.malecount);
          setfemalecount(Obj.femalecount);
          setfollowingcount(null);
          setfollowerscount(null);
          setopencount(Obj.opencount);
          sethiddencount(Obj.hiddencount);
          setTotalPages(Obj.totalPages);
        });
      } else if ((!otherUser || otherUser === "") && actionName === "blocked") {
        let params = {
          page: page - 1,
          limit: limit,
          gender: gender,
          key: key,
        };
        getBlockedUsers(params).then((Obj) => {
          list && list.length > 0
            ? setList([...list, ...Obj.data])
            : setList(Obj.data);
          setallcount(Obj.allcount);
          setmalecount(Obj.malecount);
          setfemalecount(Obj.femalecount);
          setfollowingcount(null);
          setfollowerscount(null);
          setTotalPages(Obj.totalPages);
        });
      } else if (
        (!otherUser || otherUser === "") &&
        actionName === "sharedtome"
      ) {
        let params = {
          page: page - 1,
          limit: limit,
          gender: gender,
          key: key,
        };
        getSharedToMe(params).then((Obj) => {
          list && list.length > 0
            ? setList([...list, ...Obj.data])
            : setList(Obj.data);
          setallcount(Obj.allcount);
          setmalecount(Obj.malecount);
          setfemalecount(Obj.femalecount);
          setfollowingcount(null);
          setfollowerscount(null);
          setTotalPages(Obj.totalPages);
        });
      } else if (otherUser && otherUser !== "" && actionName === "following") {
        let params = {
          userId: otherUser,
          type: "Open",
          page: page - 1,
          limit: limit,
          gender: gender,
          key: key,
        };
        getFollowing(params).then((Obj) => {
          list && list.length > 0
            ? setList([...list, ...Obj.data])
            : setList(Obj.data);
          setallcount(Obj.allcount);
          setmalecount(Obj.malecount);
          setfemalecount(Obj.femalecount);
          setfollowerscount(Obj.followerscount);
          setTotalPages(Obj.totalPages);
        });
      } else if (otherUser && otherUser !== "" && actionName === "followers") {
        let params = {
          userId: otherUser,
          type: "Open",
          page: page - 1,
          limit: limit,
          gender: gender,
          key: key,
        };
        getFollowers(params).then((Obj) => {
          list && list.length > 0
            ? setList([...list, ...Obj.data])
            : setList(Obj.data);
          setallcount(Obj.allcount);
          setmalecount(Obj.malecount);
          setfemalecount(Obj.femalecount);
          setfollowingcount(Obj.followingcount);
          setTotalPages(Obj.totalPages);
        });
      } else if (otherUser && otherUser !== "" && actionName === "sharedtome") {
        let params = {
          userId: otherUser,
          page: page - 1,
          limit: limit,
          gender: gender,
          key: key,
        };
        getSharedToMe(params).then((Obj) => {
          list && list.length > 0
            ? setList([...list, ...Obj.data])
            : setList(Obj.data);
          setallcount(Obj.allcount);
          setmalecount(Obj.malecount);
          setfemalecount(Obj.femalecount);
          setfollowingcount(null);
          setfollowerscount(null);
          setTotalPages(Obj.totalPages);
        });
      }
    }
  }, [page, otherUser]);

  useEffect(() => {
    if (
      !expanded ||
      (repliesList && repliesList[0] && repliesList[0].parent_id !== expanded)
    ) {
      setRepliesList(null);
      setReplyPage(1);
    } else {
      let params = {
        commenttype: type,
        page: replyPage - 1,
        limit: replyLimit,
        gender: gender,
        key: encryptText(replyKey),
        comment_id: expanded,
      };
      getAllRepliesToMyComment(params).then((repliesObj) => {
        if (
          repliesObj &&
          repliesObj.data.length > 0 &&
          repliesObj.data[0].parent_id === expanded
        ) {
          if (
            repliesList &&
            repliesObj.data &&
            !(repliesObj.data === repliesList) &&
            replyPage &&
            replyPage !== 1
          ) {
            var arr = repliesObj.data.filter(
              (obj) => !repliesList.includes(obj)
            );
            setRepliesList([...repliesList, ...arr]);
          } else {
            setRepliesList(repliesObj.data);
          }
        } else {
          setRepliesList([]);
        }
      });
    }
  }, [expanded, replyPage, replyKey]);

  const handleChangePageNumber = (event, value) => {
    setPage(value);
  };

  const fetchMoreData = () => {
    setPage(page + 1);
  };

  const handleChangeAction = (action) => {
    if (action === "following") {
      otherUser
        ? history.push(
            `/user_profile/${otherUser}/following/${match.params.type}`
          )
        : history.push(`/profile-me/following/${match.params.type}`);
    } else {
      otherUser
        ? history.push(
            `/user_profile/${otherUser}/followers/${match.params.type}`
          )
        : history.push(`/profile-me/followers/${match.params.type}`);
    }
  };

  const handleChangeType = (type) => {
    setType(type);
    setPage(1);
    setReplyPage(1);
    setKey("");
    setReplyKey("");
  };

  const handleChangeGender = (gender) => {
    setGender(gender);
    setPage(1);
    setReplyPage(1);
    setKey("");
    setReplyKey("");
  };

  const renderFilterBtns = () => {
    const actionBtns = () => (
      <ButtonGroup
        size="small"
        color="primary"
        aria-label="type"
        name="type"
        // style={{
        //   margin: "12px",
        // }}
      >
        <Button
          style={{ textTransform: "none", whiteSpace: "nowrap" }}
          onClick={(e) => handleChangeAction("following")}
          variant={actionName === "following" ? "contained" : "outlined"}
          startIcon={
            <Icon
              style={{
                display: "inherit",
                height: "100%",
                verticalAlign: "middle",
              }}
            >
              <img src={FollowingIcon} />
            </Icon>
          }
        >
          {" - "} {actionName === "following" ? allcount : followingcount}
        </Button>
        <Button
          style={{ textTransform: "none", whiteSpace: "nowrap" }}
          onClick={(e) => handleChangeAction("followers")}
          variant={actionName === "followers" ? "contained" : "outlined"}
          startIcon={
            <Icon
              style={{
                display: "inherit",
                height: "100%",
                verticalAlign: "middle",
              }}
            >
              <img src={FollowersIcon} />
            </Icon>
          }
        >
          {" - "} {actionName === "followers" ? allcount : followerscount}
        </Button>
      </ButtonGroup>
    );

    const typeBtns = () => (
      <ButtonGroup
        size="small"
        color="primary"
        aria-label="type"
        name="type"
        // style={{
        //   margin: "12px",
        // }}
      >
        <Button
          style={{ padding: "4px", textTransform: "none" }}
          onClick={(e) => handleChangeType("Open")}
          variant={type === "Open" ? "contained" : "outlined"}
        >
          Open{" - " + (opencount || allcount)}
        </Button>
        <Button
          style={{ padding: "4px", textTransform: "none" }}
          onClick={(e) => handleChangeType("Hidden")}
          variant={type === "Hidden" ? "contained" : "outlined"}
        >
          Hidden{" - " + (hiddencount || allcount)}
        </Button>
      </ButtonGroup>
    );

    const genderBtns = () => (
      <ButtonGroup
        size="small"
        color="primary"
        aria-label="gender"
        name="gender"
        // style={{
        //   margin: "12px",
        // }}
      >
        <Button
          style={{ textTransform: "none" }}
          onClick={(e) => handleChangeGender("All")}
          variant={gender === "All" ? "contained" : "outlined"}
        >
          All{" - " + allcount}
        </Button>
        <Button
          style={{ textTransform: "none" }}
          onClick={(e) => handleChangeGender("Male")}
          variant={gender === "Male" ? "contained" : "outlined"}
        >
          M{" - " + malecount}
        </Button>
        <Button
          style={{ textTransform: "none" }}
          onClick={(e) => handleChangeGender("Female")}
          variant={gender === "Female" ? "contained" : "outlined"}
        >
          F{" - " + femalecount}
        </Button>
      </ButtonGroup>
    );
    return (
      <FormGroup size="large" className={classes.filter}>
        <Grid container spacing={4}>
          {actionName === "following" || actionName === "followers" ? (
            <Grid item>{actionBtns()}</Grid>
          ) : null}
          {actionName === "mycomments" ? (
            <Grid item>{typeBtns()}</Grid>
          ) : (
            <Grid item>{genderBtns()}</Grid>
          )}
        </Grid>
      </FormGroup>
    );
  };

  const handleUnBlock = (user_id, username) => {
    setTitle("Are you sure?");
    setDescription(`Do you want to unblock ${username}?`);
    setUser_id(user_id);
    setOpenConfirm(true);
  };

  const handleFollow = (user_id, username) => {
    setTitle(`${type && type} Follow`);
    setDescription(`You can set a customname for ${username}`);
    setUser_id(user_id);
    setOpenCustom(true);
  };

  const handleRemoveRequest = (user_id, username) => {
    setTitle("Are you sure?");
    setDescription(
      `Do you want to unfollow ${username} and remove friend request?`
    );
    setUser_id(user_id);
    setOpenConfirm(true);
  };

  const handleRemoveFollower = (user_id, username) => {
    setTitle("Are you sure?");
    setDescription(`Do you want to remove ${username} from your followers?`);
    setUser_id(user_id);
    setOpenConfirm(true);
  };

  const handleUnfollow = (user_id, username) => {
    setTitle("Are you sure?");
    setDescription(`Do you want to unfollow ${username}?`);
    setUser_id(user_id);
    setOpenConfirm(true);
  };

  const handleConfirm = () => {
    setOpenConfirm(false);
    actionName !== "blocked" &&
      unfollow({ type: type || "Open", user_id }).then((res) =>
        setRefresh(!refresh)
      );
    actionName === "blocked" &&
      unblock({ user_id }).then((res) => setRefresh(!refresh));
  };

  const handleSave = (value) => {
    setOpenCustom(false);
    follow({ type: type || "Open", user_id, customname: value }).then((res) => {
      setRefresh(!refresh);
      typeof res.data === "string" && dispatch(openDialog(res.data));
    });
  };

  const renderFollowBtn = (status, user_id, username) => {
    if (actionName === "following") {
      switch (true) {
        case status === "requested":
          return (
            <ButtonGroup>
              <Button
                edge="end"
                variant="contained"
                // onClick={() => handleUnfollow(user_id, username)}
                color="primary"
                size="small"
                disabled
                style={{ textTransform: "none" }}
              >
                Requested
              </Button>
              <Button
                edge="end"
                onClick={() => handleRemoveRequest(user_id, username)}
                variant="outlined"
                color="secondary"
                size="small"
                style={{ textTransform: "none" }}
              >
                Remove
              </Button>
            </ButtonGroup>
          );
        case status === "following":
          return (
            <ButtonGroup>
              <Button
                edge="end"
                variant="contained"
                // onClick={() => handleUnfollow(user_id, username)}
                color="primary"
                size="small"
                disabled
                style={{ textTransform: "none" }}
              >
                Requested
              </Button>
              <Button
                edge="end"
                onClick={() => handleRemoveRequest(user_id, username)}
                variant="outlined"
                color="secondary"
                size="small"
                style={{ textTransform: "none" }}
              >
                Remove
              </Button>
            </ButtonGroup>
          );
        case status === "follower":
          return (
            <ButtonGroup>
              <Button
                edge="end"
                onClick={() => handleFollow(user_id, username)}
                variant="contained"
                color="primary"
                size="small"
                style={{ textTransform: "none" }}
              >
                Follow Back
              </Button>
              <Button
                edge="end"
                onClick={() => handleRemoveFollower(user_id, username)}
                variant="outlined"
                color="secondary"
                size="small"
                style={{ textTransform: "none" }}
              >
                Remove
              </Button>
            </ButtonGroup>
          );
        case status === "friends":
          return (
            <ButtonGroup>
              <Button
                edge="end"
                variant="contained"
                onClick={() => handleUnfollow(user_id, username)}
                color="primary"
                size="small"
                style={{ textTransform: "none" }}
              >
                {type === "Hidden" && "Hidden "}Friends
              </Button>
              {/* <Button
                  edge="end"
                  onClick={() => handleUnfollow(user_id, username)}
                  variant="outlined"
                  color="secondary"
                  size="small"
                  style={{ textTransform: "none" }}
                >
                  Remove
                </Button> */}
            </ButtonGroup>
          );
        case status === "not connected" && user_id !== userDetail._id:
          return (
            <ButtonGroup>
              <Button
                edge="end"
                onClick={() => handleFollow(user_id, username)}
                variant="contained"
                color="primary"
                size="small"
                style={{ textTransform: "none" }}
              >
                Follow
              </Button>
            </ButtonGroup>
          );
        default:
          break;
      }
      // switch (true) {
      //   case type === "Open" && status === "requested":
      //     return (
      //       <ButtonGroup>
      //         <Button
      //           edge="end"
      //           variant="contained"
      //           // onClick={() => handleUnfollow(user_id, username)}
      //           color="primary"
      //           size="small"
      //           style={{ textTransform: "none" }}
      //         >
      //           Requested
      //         </Button>
      //         <Button
      //           edge="end"
      //           onClick={() => handleUnfollow(user_id, username)}
      //           variant="outlined"
      //           color="secondary"
      //           size="small"
      //           style={{ textTransform: "none" }}
      //         >
      //           Remove
      //         </Button>
      //       </ButtonGroup>
      //     );
      //   case type === "Open" && status === "friends":
      //     return (
      //       <ButtonGroup>
      //         <Button
      //           edge="end"
      //           variant="contained"
      //           // onClick={() => handleUnfollow(user_id, username)}
      //           color="primary"
      //           size="small"
      //           style={{ textTransform: "none" }}
      //         >
      //           Friends
      //         </Button>
      //         <Button
      //           edge="end"
      //           onClick={() => handleUnfollow(user_id, username)}
      //           variant="outlined"
      //           color="secondary"
      //           size="small"
      //           style={{ textTransform: "none" }}
      //         >
      //           Remove
      //         </Button>
      //       </ButtonGroup>
      //     );
      //   case type === "Hidden" && status === "requested":
      //     return (
      //       <ButtonGroup>
      //         <Button
      //           edge="end"
      //           variant="contained"
      //           // onClick={() => handleUnfollow(user_id, username)}
      //           color="primary"
      //           size="small"
      //           style={{ textTransform: "none" }}
      //         >
      //           Requested
      //         </Button>
      //         <Button
      //           edge="end"
      //           onClick={() => handleUnfollow(user_id, username)}
      //           variant="outlined"
      //           color="secondary"
      //           size="small"
      //           style={{ textTransform: "none" }}
      //         >
      //           Remove
      //         </Button>
      //       </ButtonGroup>
      //     );
      //   case type === "Hidden" && status === "friends":
      //     return (
      //       <ButtonGroup>
      //         <Button
      //           edge="end"
      //           variant="contained"
      //           // onClick={() => handleUnfollow(user_id, username)}
      //           color="primary"
      //           size="small"
      //           style={{ textTransform: "none" }}
      //         >
      //           Hidden Friends
      //         </Button>
      //         <Button
      //           edge="end"
      //           onClick={() => handleUnfollow(user_id, username)}
      //           variant="outlined"
      //           color="secondary"
      //           size="small"
      //           style={{ textTransform: "none" }}
      //         >
      //           Remove
      //         </Button>
      //       </ButtonGroup>
      //     );
      // }
    } else if (actionName === "followers") {
      switch (true) {
        case status === "requested":
          return (
            <ButtonGroup>
              <Button
                edge="end"
                onClick={() => handleFollow(user_id, username)}
                variant="contained"
                color="primary"
                size="small"
                style={{ textTransform: "none" }}
              >
                Follow Back
              </Button>
              <Button
                edge="end"
                onClick={() => handleRemoveFollower(user_id, username)}
                variant="outlined"
                color="secondary"
                size="small"
                style={{ textTransform: "none" }}
              >
                Remove
              </Button>
            </ButtonGroup>
          );
        case status === "follower":
          return (
            <ButtonGroup>
              <Button
                edge="end"
                onClick={() => handleFollow(user_id, username)}
                variant="contained"
                color="primary"
                size="small"
                style={{ textTransform: "none" }}
              >
                Follow Back
              </Button>
              <Button
                edge="end"
                onClick={() => handleRemoveFollower(user_id, username)}
                variant="outlined"
                color="secondary"
                size="small"
                style={{ textTransform: "none" }}
              >
                Remove
              </Button>
            </ButtonGroup>
          );
        case status === "following":
          return (
            <ButtonGroup>
              <Button
                edge="end"
                variant="contained"
                // onClick={() => handleUnfollow(user_id, username)}
                color="primary"
                size="small"
                disabled
                style={{ textTransform: "none" }}
              >
                Requested
              </Button>
              <Button
                edge="end"
                onClick={() => handleRemoveRequest(user_id, username)}
                variant="outlined"
                color="secondary"
                size="small"
                style={{ textTransform: "none" }}
              >
                Remove
              </Button>
            </ButtonGroup>
          );
        case status === "friends":
          return (
            <ButtonGroup>
              <Button
                edge="end"
                variant="contained"
                onClick={() => handleUnfollow(user_id, username)}
                color="primary"
                size="small"
                style={{ textTransform: "none" }}
              >
                {type === "Hidden" && "Hidden "}Friends
              </Button>
              {/* <Button
                  edge="end"
                  onClick={() => handleUnfollow(user_id, username)}
                  variant="outlined"
                  color="secondary"
                  size="small"
                  style={{ textTransform: "none" }}
                >
                  Remove
                </Button> */}
            </ButtonGroup>
          );
        case status === "not connected" && user_id !== userDetail._id:
          return (
            <ButtonGroup>
              <Button
                edge="end"
                onClick={() => handleFollow(user_id, username)}
                variant="contained"
                color="primary"
                size="small"
                style={{ textTransform: "none" }}
              >
                Follow
              </Button>
            </ButtonGroup>
          );
        default:
          break;
      }
      // switch (true) {
      //   case type === "Open" && status === "requested":
      //     return (
      //       <ButtonGroup>
      //         <Button
      //           edge="end"
      //           onClick={() => handleFollow(user_id, username)}
      //           variant="contained"
      //           color="primary"
      //           size="small"
      //           style={{ textTransform: "none" }}
      //         >
      //           Follow Back
      //         </Button>
      //         <Button
      //           edge="end"
      //           onClick={() => handleUnfollow(user_id, username)}
      //           variant="outlined"
      //           color="secondary"
      //           size="small"
      //           style={{ textTransform: "none" }}
      //         >
      //           Remove
      //         </Button>
      //       </ButtonGroup>
      //     );
      //   case type === "Open" && status === "friends":
      //     return (
      //       <ButtonGroup>
      //         <Button
      //           edge="end"
      //           variant="contained"
      //           // onClick={() => handleUnfollow(user_id, username)}
      //           color="primary"
      //           size="small"
      //           style={{ textTransform: "none" }}
      //         >
      //           Friends
      //         </Button>
      //         <Button
      //           edge="end"
      //           onClick={() => handleUnfollow(user_id, username)}
      //           variant="outlined"
      //           color="secondary"
      //           size="small"
      //           style={{ textTransform: "none" }}
      //         >
      //           Remove
      //         </Button>
      //       </ButtonGroup>
      //     );
      //   case type === "Hidden" && status === "requested":
      //     return (
      //       <ButtonGroup>
      //         <Button
      //           edge="end"
      //           onClick={() => handleFollow(user_id, username)}
      //           variant="contained"
      //           color="primary"
      //           size="small"
      //           style={{ textTransform: "none" }}
      //         >
      //           Follow Back
      //         </Button>
      //         <Button
      //           edge="end"
      //           onClick={() => handleUnfollow(user_id, username)}
      //           variant="outlined"
      //           color="secondary"
      //           size="small"
      //           style={{ textTransform: "none" }}
      //         >
      //           Remove
      //         </Button>
      //       </ButtonGroup>
      //     );
      //   case type === "Hidden" && status === "friends":
      //     return (
      //       <ButtonGroup>
      //         <Button
      //           edge="end"
      //           variant="contained"
      //           // onClick={() => handleUnfollow(user_id, username)}
      //           color="primary"
      //           size="small"
      //           style={{ textTransform: "none" }}
      //         >
      //           Hidden Friends
      //         </Button>
      //         <Button
      //           edge="end"
      //           onClick={() => handleUnfollow(user_id, username)}
      //           variant="outlined"
      //           color="secondary"
      //           size="small"
      //           style={{ textTransform: "none" }}
      //         >
      //           Remove
      //         </Button>
      //       </ButtonGroup>
      //     );
      // }
    } else {
      return (
        <Button
          edge="end"
          onClick={() => handleUnBlock(user_id, username)}
          variant="contained"
          color="primary"
          size="small"
          style={{ textTransform: "none" }}
        >
          Unblock
        </Button>
      );
    }
  };

  const renderReplies = (commentId, length) => (
    <AccordionDetails
      style={{
        display: "block",
        padding: 0,
        margin: 0,
        marginBottom: 12,
      }}
    >
      {replyPage < length / replyLimit && (
        <div
          style={{
            marginLeft: 72,
            marginBottom: 3,
            marginTop: 0,
            textAlign: "left",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="caption"
            align="left"
            className={classes.secondaryHeading}
            style={{
              margin: 0,
              padding: 0,
              fontWeight: 500,
            }}
            onClick={() => setReplyPage(replyPage + 1)}
          >
            —— View replies ({length - replyLimit * replyPage})
          </Typography>
          {/* <Button
            variant="text"
            className={classes.secondaryHeading}
            style={{ textTransform: "none" }}
            onClick={() => setReplyPage(replyPage + 1)}
          >
            —— View replies ({length - replyLimit * replyPage})
          </Button> */}
        </div>
      )}
      <TextField
        // fullWidth
        placeholder="Search replies"
        value={replyKey}
        onFocus={() => setReplyPage(1)}
        onChange={(e) => {
          // e.target.value.length > 0 &&
          // setReplyPage(1);
          setReplyKey(e.target.value);
        }}
        variant="outlined"
        size="small"
        style={{
          marginLeft: 64,
          marginBottom: 3,
          width: 150,
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{ marginRight: 0 }}>
              <SearchIcon fontSize="small" style={{ fontSize: 18 }} />
            </InputAdornment>
          ),
          style: { paddingLeft: 3 },
        }}
        inputProps={{
          style: { padding: 4 },
        }}
      />
      {repliesList &&
        repliesList.length > 0 &&
        repliesList.map((reply) => {
          return (
            <Grid>
              {/* <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={reply._id === commentMenu}
                  onClose={handleMenuClose}
                >
                  {(owner || loggedInUser === reply.user_id._id) && (
                    <MenuItem onClick={() => handleDeleteComment(reply._id)}>
                      Delete
                    </MenuItem>
                  )}
                </Menu> */}
              <Grid
                container
                wrap="nowrap"
                spacing={2}
                style={{
                  marginLeft: 64,
                  // marginLeft: `${margin}px`,
                }}
                // onMouseEnter={() => setHover(reply._id)}
                // onMouseLeave={() => setHover(null)}
              >
                <Grid item>
                  {reply.user_id.profilepic ? (
                    <Avatar
                      style={{
                        width: 32,
                        height: 32,
                      }}
                      alt="owner"
                      src={
                        process.env.REACT_APP_BUCKET_NAME +
                        reply.user_id.profilepic
                      }
                      onClick={(e) =>
                        dispatch(
                          openAvatarModal(
                            process.env.REACT_APP_BUCKET_NAME +
                              reply.user_id.profilepic
                          )
                        )
                      }
                    />
                  ) : (
                    <Avatar
                      style={{
                        width: 32,
                        height: 32,
                      }}
                      alt="owner"
                      onClick={(e) => dispatch(openAvatarModal())}
                    >
                      <PersonIcon />
                    </Avatar>
                  )}
                </Grid>
                <Grid
                  justifyContent="left"
                  item
                  spacing={2}
                  className={classes.commentBody}
                >
                  <Typography
                    variant="subtitle2"
                    style={{
                      marginRight: "6px",
                      display: "inline-block",
                    }}
                  >
                    <Link to={`/user_profile/${reply.user_id._id}`}>
                      {reply.user_id.username}
                    </Link>
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{
                      display: "inline-block",
                      float: "right",
                      marginRight: "0px",
                    }}
                  >
                    {moment(reply.createdAt).format("DD-MM-YYYY")}
                  </Typography>
                  <Typography variant="body2" align="left">
                    {decryptText(reply.comment)}
                  </Typography>
                </Grid>
                {/* <Grid
                    item
                    justifyContent="right"
                    style={{ alignSelf: "center", minWidth:24 }}
                    onClick={(e) => handleMenuClick(e, reply._id)}
                  >
                    {hover === reply._id &&
                      (owner || loggedInUser === reply.user_id._id) && (
                        <IconButton
                          variant="text"
                          color="inherit"
                          disableFocusRipple
                          disableRipple
                          style={{
                            padding: 0,
                            marginRight: "72px",
                          }}
                        >
                          <MoreVertIcon fontSize="small" />
                        </IconButton>
                      )}
                  </Grid> */}
              </Grid>
            </Grid>
          );
        })}
    </AccordionDetails>
  );

  if (!otherUser || otherUser === "") {
    return (
      <Paper className={classes.root} elevation={0} dividers>
        {openGallery && (
          <Carousel
            open={Boolean(openGallery)}
            handleClose={() => setOpenGallery(false)}
            galleries={
              list &&
              list.length > 0 &&
              list.map((i) =>
                actionName === "sharedtome"
                  ? i.sharedId._id
                  : i.commentToGallery._id
              )
            }
            currentIndex={currentIndex}
            handleChangeIndex={(i) => setCurrentIndex(i)}
            // galleryType={type}
          />
        )}
        {openConfirm && (
          <ConfirmationDialog
            open={openConfirm}
            handleCancel={() => setOpenConfirm(false)}
            handleConfirm={() => handleConfirm()}
            title={title}
            description={description}
          />
        )}
        {openCustom && (
          <CustomNameDialog
            open={openCustom}
            handleClose={() => setOpenCustom(false)}
            handleSave={(value) => handleSave(value)}
            title={title}
            description={description}
            type={type}
          />
        )}

        <Grid
          container
          justify="space-between"
          spacing={4}
          style={{ alignItems: "center" }}
        >
          <Grid item xs={2}>
            <Typography
              variant="h6"
              // display="inline"
              style={{ textTransform: "capitalize", paddingLeft: 5 }}
              className={classes.title}
            >
              {actionName === "sharedtome"
                ? "Shared To Me"
                : actionName === "mycomments"
                ? "My " + type + " Comments"
                : actionName === "blocked"
                ? "Blocked Users"
                : (type || "") + " " + actionName}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              autoFocus
              label="Search"
              value={key}
              onChange={(e) => setKey(e.target.value)}
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            {renderFilterBtns()}
          </Grid>
          <Grid item xs={1}>
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={(e) => history.push("/profile-me")}
              style={{ float: "right", margin: "16px", textTransform: "none" }}
            >
              Back
            </Button>
          </Grid>
        </Grid>

        <DialogContent
          dividers
          style={{ height: "inherit", padding: 0, margin: 0 }}
        >
          {list ? (
            <>
              {/* <Grid
                container
                alignItems="center"
                style={{
                  height: "fit-content",
                  paddingLeft: 6,
                }}
              ></Grid>

              <Divider variant="middle" /> */}
              <List
                id="scrollableDiv"
                style={{ height: "75vh", padding: 0, overflowY: "scroll" }}
              >
                <InfiniteScroll
                  useWindow={false}
                  loadMore={fetchMoreData}
                  pageStart={0}
                  hasMore={page < totalPages}
                  loader={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "100px",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  }
                  // height={284}
                  threshold={5}
                  style={{
                    overflow: "hidden",
                  }}
                  // dataLength={list.length}
                  // next={fetchMoreData}
                  // scrollableTarget="scrollableDiv"
                  // hasMore={Math.ceil(list.length / limit) < totalPages}
                  // loader={
                  //   <div
                  //     style={{
                  //       display: "flex",
                  //       justifyContent: "center",
                  //       alignItems: "center",
                  //       minHeight: "100px",
                  //     }}
                  //   >
                  //     <CircularProgress />
                  //   </div>
                  // }
                >
                  {list.length > 0 ? (
                    list.map((item, index) => {
                      const by =
                        actionName === "followers"
                          ? "followedBy"
                          : actionName === "following"
                          ? "followedTo"
                          : actionName === "blocked"
                          ? "blockedPerson"
                          : actionName === "sharedtome"
                          ? "sharedId"
                          : "user_id";
                      const on =
                        actionName === "sharedtome"
                          ? "sharedId"
                          : "commentToGallery";
                      return (
                        item[by] &&
                        (actionName !== "mycomments" ? (
                          <ListItem
                            button
                            key={item._id}
                            divider
                            style={{
                              height:
                                actionName === "sharedtome" ? "81px" : "unset",
                              padding: 0,
                              paddingLeft: 5,
                            }}
                          >
                            <ListItemAvatar>
                              {actionName !== "sharedtome" ? (
                                item[by].profilepic ? (
                                  <Avatar
                                    alt="owner"
                                    onClick={(e) =>
                                      dispatch(
                                        openAvatarModal(
                                          process.env.REACT_APP_BUCKET_NAME +
                                            item[by].profilepic
                                        )
                                      )
                                    }
                                    src={
                                      process.env.REACT_APP_BUCKET_NAME +
                                      item[by].profilepic
                                    }
                                  />
                                ) : (
                                  <Avatar
                                    alt="owner"
                                    onClick={(e) => dispatch(openAvatarModal())}
                                  >
                                    <PersonIcon />
                                  </Avatar>
                                )
                              ) : item.sharedByProfilePic ? (
                                <Avatar
                                  alt="owner"
                                  onClick={(e) =>
                                    dispatch(
                                      openAvatarModal(
                                        process.env.REACT_APP_BUCKET_NAME +
                                          item.sharedByProfilePic
                                      )
                                    )
                                  }
                                  src={
                                    process.env.REACT_APP_BUCKET_NAME +
                                    item.sharedByProfilePic
                                  }
                                />
                              ) : (
                                <Avatar
                                  alt="owner"
                                  onClick={(e) => dispatch(openAvatarModal())}
                                >
                                  <PersonIcon />
                                </Avatar>
                              )}
                            </ListItemAvatar>
                            <Link
                              to={
                                actionName !== "sharedtome"
                                  ? `/user_profile/${item[by]._id}`
                                  : `/user_profile/${item.sharedBy}`
                              }
                            >
                              <ListItemText
                                primary={
                                  actionName !== "sharedtome"
                                    ? item.follwerUser_CustomName ||
                                      item[by].username
                                    : item.user_follow_customname.replace(
                                        "@",
                                        ""
                                      ) || item.sharedByUsername
                                }
                                secondary={
                                  actionName !== ("blocked" && "sharedtome")
                                    ? item[by].username
                                    : item.sharedByUsername.replace("@", "")
                                }
                              />
                            </Link>

                            <ListItemSecondaryAction>
                              {actionName !== "sharedtome" ? (
                                (!otherUser || otherUser === "") &&
                                renderFollowBtn(
                                  item.status || "blocked",
                                  item[by]._id,
                                  item[by].username
                                )
                              ) : (
                                <IconButton
                                  onClick={() => {
                                    setCurrentIndex(index);
                                    setOpenGallery(item[on]._id);
                                  }}
                                  style={{ padding: 0 }}
                                >
                                  <Card square>
                                    <CardMedia
                                      style={{ width: 75 }}
                                      component="img"
                                      alt="No Thumbnail found."
                                      height="75"
                                      image={
                                        process.env.REACT_APP_BUCKET_NAME +
                                        (item[on].thumbnail
                                          ? item[on].thumbnail
                                          : item[on].name)
                                      }
                                    />
                                  </Card>
                                </IconButton>
                              )}
                            </ListItemSecondaryAction>
                          </ListItem>
                        ) : (
                          <Accordion
                            square
                            TransitionProps={{ collapsedHeight: 0 }}
                            classes={{ root: classes.before }}
                            expanded={expanded === item._id}
                            onChange={(e, expanded) =>
                              item.noOfReplies > 0 &&
                              setExpanded(expanded ? item._id : null)
                            }
                          >
                            <AccordionSummary
                              expandIcon={<></>}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              classes={{
                                content: classes.collapse,
                                expanded: classes.expanded,
                              }}
                            >
                              <ListItemAvatar
                                style={{ marginLeft: 12, alignSelf: "center" }}
                              >
                                {item.commentToGallery.userId.profilepic ? (
                                  <Avatar
                                    alt="owner"
                                    onClick={(e) =>
                                      dispatch(
                                        openAvatarModal(
                                          process.env.REACT_APP_BUCKET_NAME +
                                            item.commentToGallery.userId
                                              .profilepic
                                        )
                                      )
                                    }
                                    src={
                                      process.env.REACT_APP_BUCKET_NAME +
                                      item.commentToGallery.userId.profilepic
                                    }
                                  />
                                ) : (
                                  <Avatar
                                    alt="owner"
                                    onClick={(e) => dispatch(openAvatarModal())}
                                  >
                                    <PersonIcon />
                                  </Avatar>
                                )}
                              </ListItemAvatar>
                              <Typography className={classes.heading}>
                                <ListItemText
                                  primary={filterStamps(
                                    decryptText(item.comment)
                                  )}
                                  secondary={
                                    item.commentToGallery.userId.username +
                                    " • " +
                                    moment(item.createdAt).format("DD-MM-YYYY")
                                  }
                                />
                                {item.noOfReplies > 0 && (
                                  <ListItemText
                                    primary={
                                      <IconButton
                                        disabled
                                        style={{ margin: 0, padding: 0 }}
                                      >
                                        {expanded !== item._id ? (
                                          <Typography
                                            variant="caption"
                                            align="right"
                                            className={classes.secondaryHeading}
                                            style={{
                                              margin: 0,
                                              padding: 0,
                                              fontWeight: 500,
                                            }}
                                          >
                                            —— View{" "}
                                            {item.noOfReplies > 1
                                              ? "replies"
                                              : "reply"}{" "}
                                            {"("}
                                            {item.noOfReplies}
                                            {") "}
                                          </Typography>
                                        ) : replyPage <
                                          item.noOfReplies / replyLimit ? (
                                          <div
                                            onClick={(e) => {
                                              e.stopPropagation();
                                            }}
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            {/* <Typography
                                              variant="caption"
                                              align="right"
                                              style={{
                                                marginLeft: 6,
                                                textAlign: "right",
                                              }}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setReplyPage(replyPage + 1);
                                              }}
                                            >
                                              —— View replies (
                                              {item.noOfReplies -
                                                replyLimit * replyPage}
                                              )
                                            </Typography> */}
                                            {/* <TextField
                                              // fullWidth
                                              placeholder="Search replies"
                                              value={replyKey}
                                              onFocus={() => setReplyPage(1)}
                                              onChange={(e) => {
                                                // e.target.value.length > 0 &&
                                                // setReplyPage(1);
                                                setReplyKey(e.target.value);
                                              }}
                                              variant="outlined"
                                              size="small"
                                              style={{
                                                marginLeft: 12,
                                                width: 150,
                                              }}
                                              InputProps={{
                                                startAdornment: (
                                                  <InputAdornment
                                                    position="start"
                                                    style={{ marginRight: 0 }}
                                                  >
                                                    <SearchIcon
                                                      fontSize="small"
                                                      style={{ fontSize: 18 }}
                                                    />
                                                  </InputAdornment>
                                                ),
                                                style: { paddingLeft: 3 },
                                              }}
                                              inputProps={{
                                                style: { padding: 4 },
                                              }}
                                            /> */}
                                          </div>
                                        ) : (
                                          <>
                                            <Typography
                                              variant="caption"
                                              align="right"
                                              className={
                                                classes.secondaryHeading
                                              }
                                              style={{
                                                margin: 0,
                                                padding: 0,
                                                fontWeight: 500,
                                              }}
                                            >
                                              —— Hide{" "}
                                              {item.noOfReplies > 1
                                                ? "replies"
                                                : "reply"}
                                            </Typography>
                                          </>
                                        )}
                                      </IconButton>
                                    }
                                  />
                                )}
                              </Typography>

                              <ListItemSecondaryAction>
                                <IconButton
                                  onClick={() => {
                                    setCurrentIndex(index);
                                    setOpenGallery(item[on]._id);
                                  }}
                                >
                                  <Card square>
                                    <CardMedia
                                      style={{ width: 75 }}
                                      component="img"
                                      alt="No Thumbnail found."
                                      height="75"
                                      image={
                                        process.env.REACT_APP_BUCKET_NAME +
                                        (item[on].thumbnail
                                          ? item[on].thumbnail
                                          : item[on].name)
                                      }
                                    />
                                  </Card>
                                </IconButton>
                              </ListItemSecondaryAction>
                            </AccordionSummary>

                            {item.noOfReplies > 0 &&
                              renderReplies(item._id, item.noOfReplies)}
                          </Accordion>
                        ))
                      );
                    })
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <Typography variant="h4">
                        Nothing to show here.
                      </Typography>
                    </div>
                  )}
                </InfiniteScroll>
              </List>
            </>
          ) : (
            <Loader />
          )}
        </DialogContent>
      </Paper>
    );
  }
  return (
    <Paper className={classes.root} elevation={0} dividers>
      {openGallery && (
        <Carousel
          open={Boolean(openGallery)}
          handleClose={() => setOpenGallery(false)}
          galleries={list && list.length > 0 && list.map((i) => i.sharedId._id)}
          currentIndex={currentIndex}
          handleChangeIndex={(i) => setCurrentIndex(i)}
          // galleryType={type}
        />
      )}
      {openConfirm && (
        <ConfirmationDialog
          open={openConfirm}
          handleCancel={() => setOpenConfirm(false)}
          handleConfirm={() => handleConfirm()}
          title={title}
          description={description}
        />
      )}
      {openCustom && (
        <CustomNameDialog
          open={openCustom}
          handleClose={() => setOpenCustom(false)}
          handleSave={(value) => handleSave(value)}
          title={title}
          description={description}
          type={type}
        />
      )}

      <Grid
        container
        justify="space-between"
        spacing={4}
        style={{ alignItems: "center" }}
      >
        <Grid item xs={2}>
          <Typography
            variant="h6"
            // display="inline"
            style={{ textTransform: "capitalize", paddingLeft: 5 }}
            className={classes.title}
          >
            {actionName === "sharedtome" ? "Shared To Me" : actionName}
          </Typography>
        </Grid>
        {(!otherUser || otherUser === "") && (
          <Grid item xs={3}>
            <TextField
              autoFocus
              label="Search"
              value={key}
              onChange={(e) => setKey(e.target.value)}
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
        {actionName !== "sharedtome" && (
          <Grid item xs={6}>
            {renderFilterBtns()}
          </Grid>
        )}
        <Grid item xs={1}>
          {!action && (
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={(e) => history.push(`/user_profile/${otherUser}`)}
              style={{ float: "right", margin: "16px", textTransform: "none" }}
            >
              Back
            </Button>
          )}
        </Grid>
      </Grid>

      <DialogContent dividers style={{ height: "inherit", padding: 0 }}>
        {list ? (
          <>
            {/* {actionName !== "sharedtome" && (
              <div style={{ marginLeft: "12.5%" }}>{renderFilterBtns()}</div>
            )} */}
            {/* <Grid
              container
              alignItems="center"
              justify="center"
              style={{ height: "fit-content" }}
            >
              
            </Grid> */}

            {/* <div className={classes.pagination}>
              <Pagination
                count={totalPages}
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handleChangePageNumber}
              />
            </div> */}
            {/* {(!otherUser ||otherUser==="") && (
              <div>
                <TextField
                  // fullWidth
                  autoFocus
                  label="Search"
                  // onKeyPress={(e) => {
                  //   if (e.key === "Enter") {
                  //     handleFilter();
                  //   }
                  // }}
                  value={key}
                  onChange={(e) => setKey(e.target.value)}
                  variant="outlined"
                  size="small"
                />
              </div>
            )} */}

            <Divider variant="middle" />
            <List
              id="scrollableDiv"
              style={{ height: "75vh", padding: 0, overflowY: "scroll" }}
            >
              <InfiniteScroll
                useWindow={false}
                loadMore={fetchMoreData}
                pageStart={0}
                hasMore={page < totalPages}
                loader={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "100px",
                    }}
                  >
                    <CircularProgress />
                  </div>
                }
                // height={284}
                threshold={5}
                style={{
                  overflow: "hidden",
                }}
              >
                {list.length > 0 ? (
                  list.map((item, index) => {
                    return (
                      item && (
                        <ListItem
                          button
                          key={item._id}
                          divider
                          style={{
                            height:
                              actionName === "sharedtome" ? "81px" : "unset",
                            padding: 0,
                            paddingLeft: 5,
                          }}
                        >
                          <ListItemAvatar>
                            {actionName !== "sharedtome" ? (
                              item.profilepic ? (
                                <Avatar
                                  alt="owner"
                                  onClick={(e) =>
                                    dispatch(
                                      openAvatarModal(
                                        process.env.REACT_APP_BUCKET_NAME +
                                          item.profilepic
                                      )
                                    )
                                  }
                                  src={
                                    process.env.REACT_APP_BUCKET_NAME +
                                    item.profilepic
                                  }
                                />
                              ) : (
                                <Avatar
                                  alt="owner"
                                  onClick={(e) => dispatch(openAvatarModal())}
                                >
                                  <PersonIcon />
                                </Avatar>
                              )
                            ) : item.sharedByProfilePic ? (
                              <Avatar
                                alt="owner"
                                onClick={(e) =>
                                  dispatch(
                                    openAvatarModal(
                                      process.env.REACT_APP_BUCKET_NAME +
                                        item.sharedByProfilePic
                                    )
                                  )
                                }
                                src={
                                  process.env.REACT_APP_BUCKET_NAME +
                                  item.sharedByProfilePic
                                }
                              />
                            ) : (
                              <Avatar
                                alt="owner"
                                onClick={(e) => dispatch(openAvatarModal())}
                              >
                                <PersonIcon />
                              </Avatar>
                            )}
                          </ListItemAvatar>

                          <Link
                            to={
                              actionName !== "sharedtome"
                                ? `/user_profile/${item._id}`
                                : `/user_profile/${item.sharedBy}`
                            }
                          >
                            <ListItemText
                              // primary={
                              //   actionName !== "sharedtome" &&
                              //   item.user_follow_customname
                              // }
                              primary={
                                actionName !== "sharedtome"
                                  ? item.username
                                  : item.sharedByUsername
                              }
                              secondary={
                                actionName !== "sharedtome" &&
                                item.user_follow_customname
                              }
                            />
                          </Link>
                          <ListItemSecondaryAction>
                            {actionName !== "sharedtome" ? (
                              renderFollowBtn(
                                item.status || "blocked",
                                item._id,
                                item.username
                              )
                            ) : (
                              <IconButton
                                onClick={() => {
                                  setCurrentIndex(index);
                                  setOpenGallery(item.sharedId._id);
                                }}
                                style={{ padding: 0 }}
                              >
                                <Card square>
                                  <CardMedia
                                    style={{ width: 75 }}
                                    component="img"
                                    alt="No Thumbnail found."
                                    height="75"
                                    image={
                                      process.env.REACT_APP_BUCKET_NAME +
                                      (item.sharedId.thumbnail
                                        ? item.sharedId.thumbnail
                                        : item.sharedId.name)
                                    }
                                  />
                                </Card>
                              </IconButton>
                            )}
                          </ListItemSecondaryAction>
                        </ListItem>
                      )
                    );
                  })
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <Typography variant="h4">Nothing to show here.</Typography>
                  </div>
                )}
              </InfiniteScroll>
            </List>
          </>
        ) : (
          <Loader />
        )}
      </DialogContent>
    </Paper>
  );
};

export default withRouter(UserList);
