import React, { useState, useEffect, useRef } from "react";
import getURL from "../helpers/apis";
import bindActionCreators from "redux/es/bindActionCreators";
import connect from "react-redux/es/connect/connect";
import { withStyles } from "@material-ui/core/styles";
import Menu from "../components/Menu";
import { homeStyles as pureStyle } from "../helpers/styles";
import {
  homeStyles as styles,
  profileMenuStyles,
} from "../helpers/materialStyles";
import AppBar from "@material-ui/core/AppBar";
import ReactPlayer from "react-player";
import Badge from "@material-ui/core/Badge";
import Toolbar from "@material-ui/core/Toolbar";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Divider from "@material-ui/core/Divider";
import NotificationsRoundedIcon from "@material-ui/icons/NotificationsRounded";
import ChatRoundedIcon from "@material-ui/icons/ChatRounded";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import classNames from "classnames";
import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { logout } from "../actions/appActions";
import withRouter from "react-router-dom/es/withRouter";
import MainContent from "../components/MainContent";
import Notifications from "../components/Notifications";
import Options from "./Options";
import { getSideAds, getFreeSideAds } from "../actions/commentActions";
import {
  establishSocketConnection,
  getUnreadCount,
  notifEventTarget,
  markRead,
} from "../actions/notificationsActions";
import { incomingChatEvent, chatEventTarget } from "../actions/chatActions";
import {
  getUserDetails,
  getUserAccessPages,
  getUserSettings,
} from "../actions/userDetailsAction";
import { openAvatarModal } from "../actions/appActions";
import Popover from "@material-ui/core/Popover";
import { Redirect } from "react-router-dom";
import PersonIcon from "@material-ui/icons/Person";
const Home = ({
  getUserDetails,
  getUserAccessPages,
  getUserSettings,
  getSideAds,
  getFreeSideAds,
  classes,
  userDetail,
  sideAds,
  fetchingSideAds,
  freeSideAds,
  fetchingFreeSideAds,
  history,
  openAvatarModal,
}) => {
  const [state, setState] = useState({
    isNotifOpen: false,
    notifAnchorEl: null,
    unreadCount: "",
    isChatUnread: false,
    isRedirect: false,
    redirectPath: null,
  });
  const [reg, setReg] = useState(true);

  useEffect(() => {
    const jwt = localStorage.getItem("jwt");
    const userId = localStorage.getItem("userId");
    const redirectionUrl = localStorage.getItem("redirectionUrl");

    getUserDetails(jwt, userId);
    getUserAccessPages();
    getUserSettings();
    getSideAds();
    getFreeSideAds();
    establishSocketConnection();
    notifEventTarget.addEventListener("connected", (e) => {
      getUnreadCount();
    });
    notifEventTarget.addEventListener("unreadCount", (e) => {
      handleUnreadCount(e.detail);
    });
    notifEventTarget.addEventListener("markRead", (e) => {
      getUnreadCount();
    });
    notifEventTarget.addEventListener("new_notification", (e) => {
      getUnreadCount();
    });
    chatEventTarget.addEventListener("unread", (e) => {
      if (e.detail.count > 0) setState({ ...state, isChatUnread: true });
    });
    if (redirectionUrl) {
      redirect(redirectionUrl);
      localStorage.removeItem("redirectionUrl");
    }
  }, []);

  const redirect = (path) => {
    setState({ ...state, isRedirect: true, redirectPath: path });
  };

  const handleUnreadCount = (count) => {
    setState({ ...state, unreadCount: count });
  };

  const handleNotifPopover = (e) => {
    if (state.isNotifOpen === true) {
      markRead();
    }
    setState({
      ...state,
      isNotifOpen: !state.isNotifOpen,
      notifAnchorEl: e.currentTarget,
    });
  };

  if (state.isRedirect) {
    state.isRedirect = false;
    return <Redirect to={state.redirectPath} />;
  }
  return (
    <Grid container>
      <AppBar position="fixed" className={classes.appBar}>
        <Grid container justify="space-evenly">
          <Grid item className={classes.appTitleContainer}>
            <Typography variant="h3" className={classes.appTitle}>
              Celebbook
            </Typography>
          </Grid>
          <Grid item className={classes.appMenuContainer}>
            {userDetail && <Menu userDetail={userDetail} history={history} />}
          </Grid>
          {/* <Grid item justify="flex-end" alignContent="space-around"> */}
          <Grid item className={classes.profileContainer}>
            <Grid
              item
              xs={12}
              className={classes.container}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  cursor: "pointer",
                }}
              >
                {userDetail && userDetail.profilepic ? (
                  <Avatar
                    src={
                      process.env.REACT_APP_BUCKET_NAME + userDetail.profilepic
                    }
                    onClick={(e) =>
                      openAvatarModal(
                        process.env.REACT_APP_BUCKET_NAME +
                          userDetail.profilepic
                      )
                    }
                    // className={classes.profileDefaultIcon}
                  />
                ) : (
                  <Avatar
                    alt="owner"
                    className={classes.avatar}
                    onClick={(e) => openAvatarModal()}
                  >
                    <PersonIcon />
                  </Avatar>
                )}

                <Typography
                  // className={classes.title}
                  style={{ margin: "6px" }}
                  variant="h6"
                  className="urlLink"
                  onClick={(e) => history.push("/profile-me")}
                >
                  {userDetail && userDetail.username}
                </Typography>
              </Grid>
            </Grid>
            <Grid item className={classes.notification}>
              <IconButton onClick={(e) => history.push("/messages")}>
                <Badge badgeContent=" " variant="dot" color="secondary">
                  <ChatRoundedIcon style={{ color: "white" }} />
                </Badge>
              </IconButton>
            </Grid>
            <Grid item className={classes.notification}>
              <IconButton
                onClick={(e) => {
                  handleNotifPopover(e);
                }}
              >
                <Badge
                  badgeContent={state.unreadCount.toString()}
                  color="secondary"
                >
                  <NotificationsRoundedIcon style={{ color: "white" }} />
                </Badge>
              </IconButton>
              <Popover
                open={state.isNotifOpen}
                anchorEl={state.notifAnchorEl}
                onClose={(e) => {
                  handleNotifPopover(e);
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div>{userDetail && <Notifications />}</div>
              </Popover>
            </Grid>
            {reg && (
              <Chip
                size="small"
                label="GET FREE ADSPACE!"
                aria-label="add"
                className={classes.fabButton}
                onClick={() => {
                  history.push("/freeAds");
                }}
                onDelete={() => setReg(false)}
              />
            )}

            <Grid item className={classes.notification}>
              <Options />
            </Grid>
          </Grid>

          {/* </Grid> */}
        </Grid>
      </AppBar>

      <Grid container className={classes.body}>
        <Grid key="cust-add-left" style={{ width: "17%", marginLeft: 5 }}>
          <CustomLeftAdContainer
            classes={classes}
            sideAds={sideAds}
            fetchingSideAds={fetchingSideAds}
          />
        </Grid>
        <Grid style={{ width: "64%", marginLeft: 10, marginRight: 10 }}>
          <AppContainer />
        </Grid>
        <Grid key="cust-add-right" style={{ width: "17%" }}>
          <CustomRightAdContainer
            classes={classes}
            freeSideAds={freeSideAds}
            fetchingFreeSideAds={fetchingFreeSideAds}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetail: state.userDetailsReducer.userDetail,
    sideAds: state.adSpaceReducer.sideAds,
    fetchingSideAds: state.adSpaceReducer.fetchingSideAds,
    freeSideAds: state.adSpaceReducer.freeSideAds,
    fetchingFreeSideAds: state.adSpaceReducer.fetchingFreeSideAds,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logout,
      getUserDetails,
      getUserAccessPages,
      getUserSettings,
      openAvatarModal,
      getSideAds,
      getFreeSideAds,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Home))
);

const CustomLeftAdContainer = ({ classes, sideAds, fetchingSideAds }) => {
  const [isOpen, setIsOpen] = useState(true);
  const rootUrl = getURL("UPLOADS");
  const adRef = useRef();
  const [companyIndex, setCompanyIndex] = useState(0);
  adRef.current = {};
  const openInNewTab = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  useEffect(() => {
    !fetchingSideAds &&
      sideAds &&
      sideAds.length > 1 &&
      setTimeout(
        () => (companyIndex === 0 ? setCompanyIndex(1) : setCompanyIndex(0)),
        // companyIndex + 1 < sideAds.length
        //   ? setCompanyIndex(companyIndex + 1)
        //   : setCompanyIndex(0),
        10000
      );
  }, [fetchingSideAds, companyIndex]);

  const AdItem = (src) => {
    return (
      <>
        <div className={classes.playerOverlay}></div>

        <ReactPlayer
          ref={(rp) => (adRef.current = rp)}
          url={src}
          // onEnded={() => {
          //   setAdPlaying(false);
          //   setResumed(true);
          // }}
          playing={false}
          muted
          playIcon={<></>}
          light
          width="100%"
          controls={false}
          height="100%"
          // style={{
          //   minWidth: "335px",
          //   minHeight: "auto",
          //   maxHeight: "inherit",
          // }}
        />
      </>
    );
  };

  return (
    <>
      {sideAds &&
        sideAds[0 + companyIndex] &&
        sideAds[0 + companyIndex].media[0] && (
          <Card variant="outlined">
            {sideAds[0 + companyIndex].media[0].galleryType === "videos" ? (
              <CardMedia
                className={classes.ad}
                style={{
                  width: "100%",
                  height: (window.innerHeight - 64) / 5 + "px",
                }}
                // onClick={(e) =>
                //   openInNewTab(e, sideAds[0 + companyIndex].linkToBeAdvt)
                // }
              >
                {AdItem(sideAds[0 + companyIndex].media[0].name)}
              </CardMedia>
            ) : (
              <CardMedia
                className={classes.image}
                style={{
                  width: "100%",
                  height: (window.innerHeight - 64) / 5 + "px",
                }}
                // onClick={(e) =>
                //   openInNewTab(e, sideAds[0 + companyIndex].linkToBeAdvt)
                // }
                component="img"
                alt="No Image found."
                image={sideAds[0 + companyIndex].media[0].name}
              />
            )}
          </Card>
        )}
      {sideAds &&
        sideAds[2 + companyIndex] &&
        sideAds[2 + companyIndex].media[0] && (
          <Card variant="outlined">
            {sideAds[2 + companyIndex].media[0].galleryType === "videos" ? (
              <CardMedia
                className={classes.ad}
                style={{
                  width: "100%",
                  height: (window.innerHeight - 64) / 5 + "px",
                }}
                // onClick={(e) =>
                //   openInNewTab(e, sideAds[2 + companyIndex].linkToBeAdvt)
                // }
              >
                {AdItem(sideAds[2 + companyIndex].media[0].name)}
              </CardMedia>
            ) : (
              <CardMedia
                className={classes.image}
                style={{
                  width: "100%",
                  height: (window.innerHeight - 64) / 5 + "px",
                }}
                // onClick={(e) =>
                //   openInNewTab(e, sideAds[2 + companyIndex].linkToBeAdvt)
                // }
                component="img"
                alt="No Image found."
                image={sideAds[2 + companyIndex].media[0].name}
              />
            )}
          </Card>
        )}

      {sideAds &&
        sideAds[4 + companyIndex] &&
        sideAds[4 + companyIndex].media[0] && (
          <Card variant="outlined">
            {sideAds[4 + companyIndex].media[0].galleryType === "videos" ? (
              <CardMedia
                className={classes.ad}
                style={{
                  width: "100%",
                  height: (window.innerHeight - 64) / 5 + "px",
                }}
                // onClick={(e) =>
                //   openInNewTab(e, sideAds[4 + companyIndex].linkToBeAdvt)
                // }
              >
                {AdItem(sideAds[4 + companyIndex].media[0].name)}
              </CardMedia>
            ) : (
              <CardMedia
                className={classes.image}
                style={{
                  width: "100%",
                  height: (window.innerHeight - 64) / 5 + "px",
                }}
                // onClick={(e) =>
                //   openInNewTab(e, sideAds[4 + companyIndex].linkToBeAdvt)
                // }
                component="img"
                alt="No Image found."
                image={sideAds[4 + companyIndex].media[0].name}
              />
            )}
          </Card>
        )}

      {sideAds &&
        sideAds[6 + companyIndex] &&
        sideAds[6 + companyIndex].media[0] && (
          <Card variant="outlined">
            {sideAds[6 + companyIndex].media[0].galleryType === "videos" ? (
              <CardMedia
                className={classes.ad}
                style={{
                  width: "100%",
                  height: (window.innerHeight - 64) / 5 + "px",
                }}
                // onClick={(e) =>
                //   openInNewTab(e, sideAds[6 + companyIndex].linkToBeAdvt)
                // }
              >
                {AdItem(sideAds[6 + companyIndex].media[0].name)}
              </CardMedia>
            ) : (
              <CardMedia
                className={classes.image}
                style={{
                  width: "100%",
                  height: (window.innerHeight - 64) / 5 + "px",
                }}
                // onClick={(e) =>
                //   openInNewTab(e, sideAds[6 + companyIndex].linkToBeAdvt)
                // }
                component="img"
                alt="No Image found."
                image={sideAds[6 + companyIndex].media[0].name}
              />
            )}
          </Card>
        )}

      {sideAds &&
        sideAds[8 + companyIndex] &&
        sideAds[8 + companyIndex].media[0] && (
          <Card variant="outlined">
            {sideAds[8 + companyIndex].media[0].galleryType === "videos" ? (
              <CardMedia
                className={classes.ad}
                style={{
                  width: "100%",
                  height: (window.innerHeight - 64) / 5 + "px",
                }}
                // onClick={(e) =>
                //   openInNewTab(e, sideAds[8 + companyIndex].linkToBeAdvt)
                // }
              >
                {AdItem(sideAds[8 + companyIndex].media[0].name)}
              </CardMedia>
            ) : (
              <CardMedia
                className={classes.image}
                style={{
                  width: "100%",
                  height: (window.innerHeight - 64) / 5 + "px",
                }}
                // onClick={(e) =>
                //   openInNewTab(e, sideAds[8 + companyIndex].linkToBeAdvt)
                // }
                component="img"
                alt="No Image found."
                image={sideAds[8 + companyIndex].media[0].name}
              />
            )}
          </Card>
        )}
    </>
  );
};

const CustomRightAdContainer = ({
  classes,
  freeSideAds,
  fetchingFreeSideAds,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const rootUrl = getURL("UPLOADS");
  const adRef = useRef();
  const [companyIndex, setCompanyIndex] = useState(0);
  adRef.current = {};

  const openInNewTab = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  useEffect(() => {
    !fetchingFreeSideAds &&
      freeSideAds &&
      freeSideAds.length > 1 &&
      setTimeout(
        () => (companyIndex === 0 ? setCompanyIndex(1) : setCompanyIndex(0)),
        10000
      );
  }, [fetchingFreeSideAds, companyIndex]);

  const AdItem = (index, ad) => {
    const src = freeSideAds[index].linkToBeAdvt;
    let thumbnail = false;
    if (!ad.platform.toLowerCase().includes("youtube")) {
      const thubmnailName = ad.media[0] && ad.media[0].name;
      thumbnail = `${process.env.REACT_APP_BUCKET_NAME}${thubmnailName}`;
    }
    return (
      <>
        <div className={classes.playerOverlay}></div>
        {thumbnail ? (
          <img width="100%" height="100%" src={thumbnail} />
        ) : (
          <ReactPlayer
            ref={(rp) => (adRef.current = rp)}
            url={src}
            // onEnded={() => {
            //   setAdPlaying(false);
            //   setResumed(true);
            // }}
            light={thumbnail}
            playing={false}
            width="100%"
            height="100%"
            muted
            controls={false}
            playIcon={<></>}
            // style={{
            //   minWidth: "335px",
            //   minHeight: "auto",
            //   maxHeight: "inherit",
            // }}
          />
        )}
      </>
    );
  };

  return (
    <>
      {freeSideAds && freeSideAds[0 + companyIndex] && (
        <Card variant="outlined">
          <CardMedia
            className={classes.ad}
            style={{
              width: "100%",
              height: (window.innerHeight - 64) / 5 + "px",
            }}
            onClick={(e) =>
              openInNewTab(e, freeSideAds[0 + companyIndex].linkToBeAdvt)
            }
          >
            {AdItem(0 + companyIndex, freeSideAds[0 + companyIndex])}
          </CardMedia>
        </Card>
      )}
      {freeSideAds && freeSideAds[2 + companyIndex] && (
        <Card variant="outlined">
          <CardMedia
            className={classes.ad}
            style={{
              width: "100%",
              height: (window.innerHeight - 64) / 5 + "px",
            }}
            onClick={(e) =>
              openInNewTab(e, freeSideAds[2 + companyIndex].linkToBeAdvt)
            }
          >
            {AdItem(2 + companyIndex, freeSideAds[2 + companyIndex])}
          </CardMedia>
        </Card>
      )}

      {freeSideAds && freeSideAds[4 + companyIndex] && (
        <Card variant="outlined">
          <CardMedia
            className={classes.ad}
            style={{
              width: "100%",
              height: (window.innerHeight - 64) / 5 + "px",
            }}
            onClick={(e) =>
              openInNewTab(e, freeSideAds[4 + companyIndex].linkToBeAdvt)
            }
          >
            {AdItem(4 + companyIndex, freeSideAds[4 + companyIndex])}
          </CardMedia>
        </Card>
      )}

      {freeSideAds && freeSideAds[6 + companyIndex] && (
        <Card variant="outlined">
          <CardMedia
            className={classes.ad}
            style={{
              width: "100%",
              height: (window.innerHeight - 64) / 5 + "px",
            }}
            onClick={(e) =>
              openInNewTab(e, freeSideAds[6 + companyIndex].linkToBeAdvt)
            }
          >
            {AdItem(6 + companyIndex, freeSideAds[6 + companyIndex])}
          </CardMedia>
        </Card>
      )}

      {freeSideAds && freeSideAds[8 + companyIndex] && (
        <Card variant="outlined">
          <CardMedia
            className={classes.ad}
            style={{
              width: "100%",
              height: (window.innerHeight - 64) / 5 + "px",
            }}
            onClick={(e) =>
              openInNewTab(e, freeSideAds[8 + companyIndex].linkToBeAdvt)
            }
          >
            {AdItem(8 + companyIndex, freeSideAds[8 + companyIndex])}
          </CardMedia>
        </Card>
      )}
    </>
  );
};

// const CustomAddContainerRight = ({ classes, sideAds, fetchingSideAds }) => {
//   const [isOpen, setIsOpen] = useState(true);
//   const rootUrl = getURL("UPLOADS");
//   const adRef = useRef();
//   adRef.current = {};
//   const AdItem = (src) => {
//     return (
//       <ReactPlayer
//         ref={(rp) => (adRef.current = rp)}
//         url={src}
//         // onEnded={() => {
//         //   setAdPlaying(false);
//         //   setResumed(true);
//         // }}
//         playing={true}
//         loop={true}
//         width="100%"
//         height="100%"
//         style={{
//           minWidth: "335px",
//           minHeight: "auto",
//           maxHeight: "inherit",
//         }}
//       />
//     );
//   };

//   return (
//     <>
//       {sideAds &&
//         sideAds.length > 0 &&
//         sideAds.map((ad) =>
//           ad.media.map((gallery) => (
//             <Card variant="outlined">
//               {gallery.galleryType === "videos" ? (
//                 <CardMedia
//                   className={classes.ad}
//                   style={{
//                     width: "100%",
//                     height: (window.innerHeight - 64) / 5 + "px",
//                   }}
//                 >
//                   {AdItem( gallery.name)}
//                 </CardMedia>
//               ) : (
//                 <CardMedia
//                   className={classes.image}
//                   component="img"
//                   alt="No Image found."
//                   image={gallery.name}
//                 />
//               )}
//             </Card>
//           ))
//         )}
//     </>
//   );
// };

const AppContainer = () => {
  return <Paper style={{ height: "100%" }}>{<MainContent />}</Paper>;
};
