const initialState = {
  adSpaceDetailsLoading: false,
  userAdSpaceListLoading: false,
  userAdSpaceList: null,
  purchaseDetailsLoading: true,
  purchaseDetails: null,
  adSpaceDetails: null,
  //   chooseSubscriptionPlanLoading: false,
  //   chooseSubscriptionPlanList: [],
  addAdSpacetoCartLoading: false,
  razorPaySuccessCaptureLoading: false,
  razorPayData: {},
  fetchingVideoAds: false,
  fetchingSideAds: false,
  fetchingFreeSideAds: false,
  videoAds: null,
  sideAds: null,
  freeSideAds: null,
  cycle: null,
  adSpace: null,

  userFreeAdSpaceListLoading: false,
  userFreeAdSpaceList: null,

  userUnprocessedAdvtLoading: false,
  userUnprocessedAdvt: null,
  videoAdCounter: 0,
  lastAdIndex: 0,
};

const adSpaceReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET PRODUCT FEATURES BY USER TYPE
    case "SET_CYCLE_AND_ADSPACE":
      return {
        ...state,
        cycle: action.payload.cycle,
        adspace: action.payload.adspace,
      };
    case "CLEAR_CYCLE_AND_ADSPACE":
      return {
        ...state,
        cycle: null,
        adSpace: null,
      };
    case "GET_ADSPACE_DETAILS":
      return {
        ...state,
        adSpaceDetailsLoading: true,
      };
    case "GET_ADSPACE_DETAILS_SUCCESS":
      return {
        ...state,
        adSpaceDetailsLoading: false,
        adSpaceDetails: action.payload,
      };
    case "GET_ADSPACE_DETAILS_FAILED":
      return {
        ...state,
        adSpaceDetailsLoading: false,
      };
    //GET PURCHASE INFORMATION
    case "GET_ADSPACE_PURCHASE_DETAILS":
      return {
        ...state,
        purchaseDetailsLoading: true,
      };
    case "GET_ADSPACE_PURCHASE_DETAILS_SUCCESS":
      return {
        ...state,
        purchaseDetailsLoading: false,
        purchaseDetails: action.payload,
      };
    case "GET_ADSPACE_PURCHASE_DETAILS_FAILED":
      return {
        ...state,
        purchaseDetailsLoading: false,
      };

    //GET USERT SUBSCRIPTION LIST
    case "GET_USER_ADSPACE":
      return {
        ...state,
        userAdSpaceListLoading: true,
      };
    case "GET_USER_ADSPACE_SUCCESS":
      return {
        ...state,
        userAdSpaceListLoading: false,
        userAdSpaceList: action.payload,
      };
    case "GET_USER_ADSPACE_FAILED":
      return {
        ...state,
        userAdSpaceListLoading: false,
      };
    case "GET_UNPROCESSED_ADVT":
      return {
        ...state,
        userUnprocessedAdvtLoading: true,
      };
    case "GET_UNPROCESSED_ADVT_SUCCESS":
      return {
        ...state,
        userUnprocessedAdvtLoading: false,
        userUnprocessedAdvt: action.payload,
      };
    case "GET_UNPROCESSED_ADVT_SUCCESS_FAILED":
      return {
        ...state,
        userUnprocessedAdvtLoading: false,
      };
    case "GET_USER_FREE_ADSPACE":
      return {
        ...state,
        userFreeAdSpaceListLoading: true,
      };
    case "GET_USER_FREE_ADSPACE_SUCCESS":
      return {
        ...state,
        userFreeAdSpaceListLoading: false,
        userFreeAdSpaceList: action.payload,
      };
    case "GET_USER_FREE_ADSPACE_FAILED":
      return {
        ...state,
        userFreeAdSpaceListLoading: false,
      };
    case "FETCH_VIDEO_ADS":
      return {
        ...state,
        fetchingVideoAds: true,
      };
    case "FETCH_VIDEO_ADS_SUCCESS":
      return {
        ...state,
        videoAds: action.payload,
        fetchingVideoAds: false,
      };
    case "RESET_LAST_AD_COUNTER":
      return {
        ...state,
        lastAdIndex: 0,
      };
    case "INCR_LAST_AD_COUNTER":
      return {
        ...state,
        lastAdIndex: state.lastAdIndex + 1,
      };
    case "CURRENT_POST_VIDEO_AD_COUNTER":
      return {
        ...state,
        videoAdCounter: state.videoAdCounter + 1,
      };
    case "FETCH_VIDEO_ADS_FAILED":
      return {
        ...state,
        fetchingVideoAds: false,
      };
    case "FETCH_SIDE_ADS":
      return {
        ...state,
        fetchingSideAds: true,
      };
    case "FETCH_SIDE_ADS_SUCCESS":
      return {
        ...state,
        sideAds: action.payload,
        fetchingSideAds: false,
      };
    case "FETCH_SIDE_ADS_FAILED":
      return {
        ...state,
        fetchingSideAds: false,
      };
    case "FETCH_FREE_SIDE_ADS":
      return {
        ...state,
        fetchingFreeSideAds: true,
      };
    case "FETCH_FREE_SIDE_ADS_SUCCESS":
      return {
        ...state,
        freeSideAds: action.payload,
        fetchingFreeSideAds: false,
      };
    case "FETCH_FREE_SIDE_ADS_FAILED":
      return {
        ...state,
        fetchingFreeSideAds: false,
      };
    // //USER CHOOSE SUBSCRIPTIN PLAN
    // case "USER_CHOOSE_SUBSCRIPTION_PLAN":
    //   return {
    //     ...state,
    //     chooseSubscriptionPlanLoading: true,
    //   };
    // case "USER_CHOOSE_SUBSCRIPTION_PLAN_SUCCESS":
    //   return {
    //     ...state,
    //     chooseSubscriptionPlanLoading: false,
    //     chooseSubscriptionPlanList: action.payload,
    //   };
    // case "USER_CHOOSE_SUBSCRIPTION_PLAN_FAILED":
    //   return {
    //     ...state,
    //     chooseSubscriptionPlanLoading: false,
    //   };

    //USER ADD SUBSCRIPTIO PLAN

    case "ADD_ADSPACE_TO_CART":
      return {
        ...state,
        addAdSpacetoCartLoading: true,
      };
    case "ADD_ADSPACE_TO_CART_SUCCESS":
      return {
        ...state,
        addAdSpacetoCartLoading: false,
        // addAdSpacetoCartDetails: action.payload,
      };
    case "ADD_ADSPACE_TO_CART_FAILED":
      return {
        ...state,
        addAdSpacetoCartLoading: false,
      };

    //RAZOR PAY CAPTURE CCAVENUE

    case "ADSPACE_RAZORPAY_PAYMENT_CAPTURE":
      return {
        ...state,
        razorPaySuccessCaptureLoading: true,
      };
    case "ADSPACE_RAZORPAY_PAYMENT_CAPTURE_SUCCESS":
      return {
        ...state,
        razorPaySuccessCaptureLoading: false,
        razorPayData: action.payload,
      };
    case "ADSPACE_RAZORPAY_PAYMENT_CAPTURE_FAILED":
      return {
        ...state,
        razorPaySuccessCaptureLoading: false,
      };
    default:
      return state;
  }
};

export default adSpaceReducer;
