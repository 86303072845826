import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { withStyles } from "@material-ui/core/styles";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import {
  isValidParam,
  getStringParam,
  getObjectParam,
  getArrayParam,
  getIntParam,
  getBooleanParam,
} from "../helpers/paramVerifier";

const styles = (theme) => ({
  card: {
    minWidth: 275,
    borderTopRightRadius: "0px",
    borderTopLeftRadius: "0px",
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: "#00465bbf",
    color: "white",
    height: 40,
    padding: 10,
  },
  label: {
    fontSize: 14,
    marginBottom: 5,
    //fontWeight: 600
  },
  value: {
    fontSize: 14,
    marginBottom: 5,
  },
});

class FieldLabelValueContent extends React.Component {
  constructor(props) {
    super(props);
  }

  getLabelValue(field, data) {
    const { classes } = this.props;
    let arrRow = new Array();
    try {
      data = getObjectParam(data);
      field = getObjectParam(field);
      let name = getStringParam(field.name);
      let label = getStringParam(field.label);
      let value = data[name];
      let type = getStringParam(field.type);
      if (type == "date") {
        value = getStringParam(value);
        value = new Date(value).toLocaleDateString();
      } else if (type == "integer") {
        value = getIntParam(value);
      } else if (type == "boolean") {
        value = getBooleanParam(value);
      } else {
        value = getStringParam(value);
      }
      return (
        <Grid container xs={12} style={{ paddingLeft: 10, paddingRight: 10 }}>
          <Grid item xs={6}>
            <Typography
              variant="caption"
              className={classes.label}
              color="textSecondary"
              gutterBottom
            >
              {label}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              className={classes.value}
              color="textSecondary"
              gutterBottom
              title={value}
            >
              {value}
            </Typography>
          </Grid>
        </Grid>
      );
    } catch (error) {}
  }

  getFildesLabelValue() {
    const { classes } = this.props;
    let arrRow = new Array();
    try {
      let actions = getArrayParam(this.props.actions);
      let data = getObjectParam(this.props.data);
      let fields = getArrayParam(this.props.fields);
      fields.forEach((field) => {
        arrRow.push(this.getLabelValue(field, data));
      });
      if (!getBooleanParam(this.props.isNotUseContainer)) {
        return arrRow;
      } else {
        return actions.length > 0 ? (
          <CardActionArea>
            <CardContent>{arrRow}</CardContent>
          </CardActionArea>
        ) : (
          <CardContent>{arrRow}</CardContent>
        );
      }
    } catch (error) {}
  }

  getHeader() {
    const { classes } = this.props;
    try {
      if (isValidParam(this.props.header)) {
        return (
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            {this.props.header}
          </Typography>
        );
      }
    } catch (error) {}
  }

  getCardActions() {
    try {
      let actions = getArrayParam(this.props.actions);
      return actions.length > 0 ? <CardActions>{actions}</CardActions> : [];
    } catch (error) {}
  }

  render() {
    const { classes, style, breadcrumbs } = this.props;
    if (getBooleanParam(this.props.isNotUseContainer)) {
      return (
        <Grid className={classes.card} style={style}>
          {this.getHeader()}
          {breadcrumbs}
          {this.getFildesLabelValue()}
          {this.getCardActions()}
        </Grid>
      );
    } else {
      return (
        <Card className={classes.card} style={style}>
          {this.getHeader()}
          {breadcrumbs}
          {this.getFildesLabelValue()}
          {this.getCardActions()}
        </Card>
      );
    }
  }
}

export default withStyles(styles)(FieldLabelValueContent);
