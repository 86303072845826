import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ApplicantDetials from "./ApplicantDetails";
import {
  getPosApplicants,
  confirmRole,
  getPosByUser,
} from "../../../src/actions/getPosActions";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "../../helpers/constants";
import ConfirmationDialog from "../ConfirmationDialog";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1} style={{ height: "75vh" }}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    marginTop: "35px",
    backgroundColor: theme.palette.background.paper,
  },
  positionTabHead: {
    display: "flex",
    textDecoration: "none",
  },
  appBar: {
    boxShadow: "none",
  },
  tabs: {
    textColor: "white",
  },
  applicantHead: {
    padding: "16px",
  },
  roleTypography: {
    margin: "16px 4px 0px",
  },
  arrowBackButton: {
    marginRight: "8px",
  },
}));

export default function PositionsTab(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const positionList = useSelector(
    ({ PosTableReducer }) => PosTableReducer.Pos
  );
  const userDetail = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.userDetail
  );

  const queryParamArrayLength = props.history.location.pathname.split("/")
    .length;
  const applicantId = props.history.location.pathname.split("/")[
    queryParamArrayLength - 1
  ];
  const applicants = useSelector(
    ({ applicantsReducer }) => applicantsReducer.applicants
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    positionList && dispatch(getPosByUser(null, userDetail._id));
  }, [positionList.length]);

  useEffect(() => {
    applicantId && dispatch(getPosApplicants(applicantId));
  }, [applicantId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleConfirm = () => {
    setConfirmOpen(false);
    applicantId && dispatch(confirmRole(applicantId));
  };

  const appliedApplicantList =
    applicants &&
    applicants.filter((applicant) => applicant.status === "Applied");
  const shortListedApplicantList =
    applicants &&
    applicants.filter((applicant) => applicant.status === "ShortListed");
  const rejectedApplicantList =
    applicants &&
    applicants.filter((applicant) => applicant.status === "Rejected");
  const selectedApplicantList =
    applicants &&
    applicants.filter((applicant) => applicant.status === "Selected");

  const currentPosition = positionList.find((pos) => pos._id === applicantId);

  return (
    <div className={classes.root}>
      {confirmOpen && (
        <ConfirmationDialog
          open={confirmOpen}
          handleConfirm={() => handleConfirm()}
          handleCancel={() => setConfirmOpen(false)}
          title={"Are you sure?"}
          description={
            "A confirmation message will be sent to all the selected candidates and this role will be closed. Are you sure you want to confirm and close the role?"
          }
        />
      )}
      <div className={classes.applicantHead}>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          style={{
            float: "right",
            marginBottom: 12,
            textTransform: "none",
          }}
          onClick={() => history.push("/all-positions")}
        >
          Back
        </Button>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            component={Link}
            role="button"
            to="/all-positions/"
            className={classes.positionTabHead}
            style={{
              display: "flex",
              textDecoration: "none",
              color: constants.PRIMARY_COLOR,
            }}
          >
            All Positions
          </Typography>
          <Typography
            color="inherit"
            variant="subtitle1"
            style={{ margin: "0px 6px" }}
          >
            /
          </Typography>

          <Typography color="inherit" variant="subtitle1">
            {currentPosition && currentPosition.role}
          </Typography>
        </div>
      </div>
      <div>
        {currentPosition && currentPosition.positionStatus !== "Closed" && (
          <Button
            variant="contained"
            color="primary"
            style={{ marginBottom: 6, marginRight: "8%", float: "right" }}
            disabled={selectedApplicantList.length < 1}
            onClick={() => setConfirmOpen(true)}
          >
            Confirm
          </Button>
        )}
      </div>

      <AppBar position="static" color="primary" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          className={classes.tabs}
          // variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto simple tabs example"
          variant="fullWidth"
        >
          <Tab
            label={`Applied (${appliedApplicantList.length})`}
            {...a11yProps(0)}
          />
          <Tab
            label={`ShortListed (${shortListedApplicantList.length})`}
            {...a11yProps(1)}
          />
          <Tab
            label={`Rejected (${rejectedApplicantList.length})`}
            {...a11yProps(2)}
          />
          <Tab
            label={`Selected (${selectedApplicantList.length})`}
            {...a11yProps(3)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <ApplicantDetials
          applicantList={appliedApplicantList}
          tabValue="Applied"
          currentPosition={currentPosition && currentPosition}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ApplicantDetials
          applicantList={shortListedApplicantList}
          tabValue="ShortListed"
          currentPosition={currentPosition && currentPosition}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ApplicantDetials
          applicantList={rejectedApplicantList}
          tabValue="Rejected"
          currentPosition={currentPosition && currentPosition}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ApplicantDetials
          applicantList={selectedApplicantList}
          tabValue="Selected"
          currentPosition={currentPosition && currentPosition}
        />
      </TabPanel>
    </div>
  );
}
