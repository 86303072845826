const videoReducer = (
  state = {
    followingVideos: [],
    followingVideosLoading: true,
    nonFollowingVideos: [],
    nonFollowingVideosLoading: true,
    videosPayload: [],
    isDataChanged: false,
    bnr: "",
    pr: "",
    dr: "",
    youtubeLink: "",
  },
  action
) => {
  switch (action.type) {
    case "VIDEOS_POST_SUCCESS":
      return {
        ...state,
        isDataChanged: true,
        videosPayload: [...state.videosPayload, action.payload],
      };
    case "VIDEOS_LIST_FETCH_SUCCESS":
      return {
        ...state,
        videosPayload: action.payload,
      };
    case "VIDEOS_LIST_FETCH_FAILED":
      return {
        ...state,
      };

    case "GET_FOLLOWING_VIDEOS_SUCCESS":
      return {
        ...state,
        followingVideos: action.payload,
        followingVideosLoading: false,
      };
    case "GET_FOLLOWING_VIDEOS_FAILED":
      return {
        ...state,
        followingVideosLoading: false,
      };

    case "GET_NONFOLLOWING_VIDEOS_SUCCESS":
      return {
        ...state,
        nonFollowingVideos: action.payload,
        nonFollowingVideosLoading: false,
      };
    case "GET_NONFOLLOWING_VIDEOS_FAILED":
      return {
        ...state,
        nonFollowingVideosLoading: false,
      };
    default:
      return state;
  }
};

export default videoReducer;
