export const homeStyles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  appTitleContainer: {
    width: "12.5%",
    margin: 18,
  },
  appMenuContainer: {
    width: "56%",
  },
  notification: {
    float: "right",
    display: "flex",
    justifyContent: "flex-end",
  },
  profileContainer: {
    width: "25%",
    float: "right",
    display: "flex",
    justifyContent: "flex-end",
  },
  appTitle: {
    color: "#ffffff",
    fontSize: 20,
    marginLeft: 15,
  },
  body: {
    marginTop: 45,
  },
  video: {
    width: "fit-content",
    height: "fit-content",
    maxWidth: "600px",
    maxHeight: "inherit",
    minWidth: "335px",
    minHeight: "auto",
  },
  ad: {
    maxHeight: "inherit",
    minHeight: "auto",
    position: "relative",
  },
  playerOverlay: {
    position: "absolute",
    width: "100%",
    height: (window.innerHeight - 64) / 5 + "px",
    left: 0,
    top: 0,
    zIndex: 1,
  },
  image: {
    height: "inherit",
    width: "inherit",
    maxWidth: "inherit",
    maxHeight: "inherit",
  },
  button: {
    height: 30,
    paddingLeft: 20,
    paddingRight: 20,
    marginRight: "8px",
    paddingTop: 3,
    color: "#ffffff",
    fontSize: 14,
    textTransform: "capitalize",
    "&:focus": {
      // fontSize: 30,
      // '&$selectButton': {
      //     backgroundColor: 'red'
      // }
    },
  },
  selectButton: {
    borderRadius: "0px",
    borderBottom: "2px solid white",
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    bottom: -30,
    // left: 0,
    right: 30,
    margin: "0 auto",
  },
});

export const profileMenuStyles = (theme) => ({
  container: {
    float: "right",
    paddingRight: 10,
  },
  profileDefaultIcon: {
    fontSize: 35,
    cursor: "pointer",
    marginTop: 4,
  },
  title: {
    marginTop: 12,
    fontSize: 14,
    color: "#ffffff",
    marginRight: 3,
  },
  listItem: {
    height: 30,
  },
  listItemText: {
    fontSize: 14,
  },
});

export const menuStyles = (theme) => ({
  button: {
    height: 30,
    paddingLeft: 20,
    paddingRight: 20,
    marginRight: "8px",
    paddingTop: 3,
    color: "#ffffff",
    fontSize: 14,
    textTransform: "capitalize",
    "&:focus": {
      // fontSize: 30,
      // '&$selectButton': {
      //     backgroundColor: 'red'
      // }
    },
  },
  selectButton: {
    borderRadius: "0px",
    borderBottom: "2px solid white",
  },
  hover: {},
  // root: {
  //     display: 'flex',
  // },
  // appTitle: {
  //     //height: 64,
  //     color: '#ffffff',
  //     fontSize: 16,

  // },
  // menuButton: {
  //     marginLeft: 12,
  //     marginRight: 36,
  // },
  // hide: {
  //     display: 'none',
  // },
  // drawer: {
  //     width: drawerWidth,
  //     flexShrink: 0,
  //     whiteSpace: 'nowrap',
  // },
  // drawerOpen: {
  //     width: drawerWidth,
  //     transition: theme.transitions.create('width', {
  //         easing: theme.transitions.easing.sharp,
  //         duration: theme.transitions.duration.enteringScreen,
  //     }),
  // },
  // drawerClose: {
  //     transition: theme.transitions.create('width', {
  //         easing: theme.transitions.easing.sharp,
  //         duration: theme.transitions.duration.leavingScreen,
  //     }),
  //     overflowX: 'hidden',
  //     width: theme.spacing.unit * 7 + 1,
  //     [theme.breakpoints.up('sm')]: {
  //         width: theme.spacing.unit * 9 + 1,
  //     },
  // },
  // toolbar: {
  //     display: 'flex',
  //     alignItems: 'center',
  //     justifyContent: 'flex-end',
  //     padding: '0 8px',
  //     ...theme.mixins.toolbar,
  // },
  // content: {
  //     flexGrow: 1,
  //     padding: theme.spacing.unit * 3,
  // },
  // default: {
  //     fontSize: 16,
  //     color: '#687384'
  // },
  // menuItem: {
  //     '&:focus': {
  //         //backgroundColor: theme.palette.primary.main,
  //         '& $primary, & $icon': {
  //             color: '#ffffff',
  //             fontSize: 16,
  //         },
  //     },
  //     '&:onClick': {
  //       '& $primary, & $icon': {
  //           // backgroundColor: '#242a33',
  //           // color: theme.palette.common.white,
  //       },
  //   },
  // },
  // primary: {
  //   fontSize: 16,
  //   color: '#687384'
  // },
  // icons: {cursor: "pointer",
  // height: "auto",
  //   color: '#687384'

  // },
});

export const userProfilesListViewStyles = (theme) => ({
  root: {
    // width:  '100%'
  },
  tableContainer: {
    marginTop: 10,
    //width:  '100%',
    //overflowX: 'auto',
  },
});
