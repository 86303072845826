// dependencies import
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

//components import
import Home from "../containers/Home";
// import UserComponent from "../components/UserComponent";
import RegisterForm from "../containers/RegisterForm";
import LoginForm from "../containers/LoginForm";
import ForgetPasswordComponent from "../containers/ForgetPasswordComponent";
import ResetPassword from "../containers/ResetPassword";

import VerifyEmail from "../containers/verifyEmail";
import OtpPhone from "../containers/otp";
import OtpVerify from "../containers/OtpPhoneVerify";
import EmailVerifyMessage from "../containers/EmailVerifyMessage";
import CompleteSignUp from "../containers/CompleteSignUp";
import ContactUs from "../containers/ContactUs";
import TermsAndConditions from "../containers/TermsAndConditions";
import PrivacyPolicy from "../containers/PrivacyPolicy";
import Pricing from "../containers/Pricing";

export default class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/contactus" exact render={() => <ContactUs />} />
        <Route
          exact
          path={"/pricing"}
          render={(props) => <Pricing {...props} />}
        />
        <Route
          exact
          path={"/tc"}
          render={(props) => <TermsAndConditions {...props} />}
        />
        <Route
          exact
          path={"/privacypolicy"}
          render={(props) => <PrivacyPolicy {...props} />}
        />
        <Route
          path="/complete-signup"
          exact
          render={() =>
            localStorage.getItem("jwt") ? (
              <Redirect to={"/"} />
            ) : (
              <CompleteSignUp />
            )
          }
        />
        <Route
          path="/register"
          exact
          render={() =>
            localStorage.getItem("jwt") ? (
              <Redirect to={"/"} />
            ) : (
              <RegisterForm />
            )
          }
        />
        <Route
          path="/login"
          exact
          render={() =>
            localStorage.getItem("jwt") ? <Redirect to={"/"} /> : <LoginForm />
          }
        />
        <Route
          path="/forgot-password"
          exact
          render={() =>
            localStorage.getItem("jwt") ? (
              <Redirect to={"/"} />
            ) : (
              <ForgetPasswordComponent />
            )
          }
        />
        <Route
          path="/reset-password/:q"
          exact
          render={() =>
            localStorage.getItem("jwt") ? (
              <Redirect to={"/"} />
            ) : (
              <ResetPassword />
            )
          }
        />
        <Route
          path="/email-verify/:q"
          exact
          render={() =>
            localStorage.getItem("jwt") ? (
              <Redirect to={"/"} />
            ) : !localStorage.getItem("validationJWT") ? (
              <Redirect to={"/login"} />
            ) : (
              <VerifyEmail />
            )
          }
        />
        <Route
          path="/email-verify-message"
          exact
          render={() =>
            localStorage.getItem("jwt") ? (
              <Redirect to={"/"} />
            ) : !localStorage.getItem("validationJWT") ? (
              <Redirect to={"/login"} />
            ) : (
              <EmailVerifyMessage />
            )
          }
        />
        <Route
          path="/otp-phone-verify"
          exact
          render={() =>
            localStorage.getItem("jwt") ? (
              <Redirect to={"/"} />
            ) : !localStorage.getItem("validationJWT") ? (
              <Redirect to={"/login"} />
            ) : (
              <OtpVerify />
            )
          }
        />
        <Route
          path="/otp-phone"
          exact
          render={() =>
            localStorage.getItem("jwt") ? (
              <Redirect to={"/"} />
            ) : !localStorage.getItem("validationJWT") ? (
              <Redirect to={"/login"} />
            ) : (
              <OtpPhone />
            )
          }
        />
        {/* <Route path='/' render={(props)=> */}
        <Route
          render={(props) =>
            localStorage.getItem("jwt") ? (
              <Home {...props} />
            ) : (
              <Redirect to={"/login"} />
            )
          }
        />
        <Route
          render={() => (
            <div>
              <center>404: Page not found</center>
            </div>
          )}
        />
      </Switch>
    );
  }
}
