import React, { useState, useEffect, useRef } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import bindActionCreators from "redux/es/bindActionCreators";
import withRouter from "react-router-dom/es/withRouter";
import connect from "react-redux/es/connect/connect";
import MenuList from "@material-ui/core/MenuList";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import { logout } from "../actions/appActions";
import ArrowDropDownCircleRoundedIcon from "@material-ui/icons/ArrowDropDownCircleRounded";
import IconButton from "@material-ui/core/IconButton";
import {
  homeStyles as styles,
  profileMenuStyles,
} from "../helpers/materialStyles";

const Options = ({ logout, classes, userDetail, history }) => {
  const [state, setState] = useState({
    isOpen: false,
    anchorEl: null,
    isRedirect: false,
    redirectPath: null,
  });

  const handlePopover = (e) => {
    setState((state) => ({
      ...state,
      isOpen: !state.isOpen,
      anchorEl: e.currentTarget,
    }));
  };

  const redirect = (path) => {
    setState((state) => ({ ...state, isRedirect: true, redirectPath: path }));
  };

  if (state.isRedirect) {
    state.isRedirect = false;
    return <Redirect to={state.redirectPath} />;
  }

  return (
    <Grid container>
      <IconButton
        onClick={(e) => {
          handlePopover(e);
        }}
      >
        <ArrowDropDownCircleRoundedIcon
          fontSize="large"
          style={{ color: "white" }}
        />
      </IconButton>
      <Popover
        id="simple-popper"
        open={state.isOpen}
        anchorEl={state.anchorEl}
        onClose={(e) => {
          handlePopover(e);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List component="nav" className={classes.root}>
          <ListItem
            button
            className={classes.listItem}
            onClick={(e) => {
              handlePopover(e);
              history.push("/settings");
            }}
          >
            <ListItemText
              primary="Settings"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          {/* <ListItem
              button
              className={classes.listItem}
              onClick={(e) => {
                handlePopover(e);
                history.push("/points");
              }}
            >
              <ListItemText
                primary="My Points and Gifts"
                classes={{ primary: classes.listItemText }}
              />
            </ListItem> */}
          <ListItem
            button
            className={classes.listItem}
            onClick={(e) => {
              handlePopover(e);
              history.push("/subscription");
            }}
          >
            <ListItemText
              primary="Subscription"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <ListItem
            button
            className={classes.listItem}
            onClick={(e) => {
              handlePopover(e);
              history.push("/donation");
            }}
          >
            <ListItemText
              primary="Donation"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <ListItem
            button
            className={classes.listItem}
            onClick={(e) => {
              handlePopover(e);
              history.push("/buyAdSpace");
            }}
          >
            <ListItemText
              primary="Buy AdSpace"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          {userDetail && (
            <ListItem
              button
              className={classes.listItem}
              onClick={(e) => {
                handlePopover(e);
                history.push("/buyShare");
              }}
            >
              <ListItemText
                primary="Become a Partner?"
                classes={{ primary: classes.listItemText }}
              />
            </ListItem>
          )}

          <ListItem
            button
            className={classes.listItem}
            onClick={(e) => {
              handlePopover(e);
              logout();
            }}
          >
            <ListItemText
              primary="Logout"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
        </List>
      </Popover>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetail: state.userDetailsReducer.userDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(profileMenuStyles)(Options))
);
