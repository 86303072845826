// import dependencies
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import SidePanelUserComponent from "./SidePanelUserComponent";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import withRouter from "react-router-dom/es/withRouter";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import {
  getUsersByKeyword,
  clearUsersList,
} from "../../actions/userDetailsAction";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    textAlign: "center",
    height: "90vh",
    overflowY: "scroll",
  },
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const ChatNavCard = ({
  users,
  switchCurrentSelectedUserForChat,
  title,
  currentSelectedUser,
  newMessage,
  unreadData,
  dispatch,
  unmessagedUsers,
}) => {
  const [userList, setUserList] = useState(users && users);
  const [keyword, setKeyword] = useState("");
  const [refresh, setRefresh] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    dispatch(clearUsersList());
  }, []);

  useEffect(() => {
    setUserList(users);
  }, [users]);



  console.log(users)

  useEffect(() => {
    if (keyword !== "") {
      const params = { key: keyword, page: 0, limit: 10 };
      dispatch(getUsersByKeyword(params));
    }
  }, [keyword]);

  useEffect(() => {
    if (unmessagedUsers && unmessagedUsers.length > 0 && keyword !== "") {
      unmessagedUsers = unmessagedUsers.map((user) => {
        user.user_id = user._id;
        user.followingUser_CustomName = user.user_follow_customname.startsWith(
          "@"
        )
          ? ""
          : user.user_follow_customname.replace("@", "");
        return user;
      });
      // unmessagedUsers = unmessagedUsers.filter(
      //   (user) => user.userType !== "FreeUser"
      // );

      setUserList(unmessagedUsers);
    } else if (keyword === "" || unmessagedUsers.length === 0) {
      setUserList(users && users);
    }
  }, [unmessagedUsers]);

  // const clearList = () => {
  // dispatch(clearUsersList());
  // setKeyword("");
  // };

  const renderUserList = () => {
    return userList
      .slice(0)
      .reverse()
      .map((user) => {
        return (
          <SidePanelUserComponent
            key={user.user_id}
            switchCurrentSelectedUserForChat={switchCurrentSelectedUserForChat}
            user={user}
            selected={
              currentSelectedUser &&
              currentSelectedUser.user_id === user.user_id
            }
            newMessage={newMessage}
            unreadData={unreadData}
            clear={() => setKeyword("")}
          />
        );
      });
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h5" style={{ margin: 6 }}>
        {title}
      </Typography>
      <Divider variant="fullWidth" />
      <TextField
        variant="outlined"
        fullWidth
        inputValue={keyword}
        onChange={(e) => setKeyword(e.target.value)}
        placeholder="Search Users"
        InputProps={{
          type: "search",
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Divider variant="fullWidth" />
      <List className={classes.list}>
        {userList && userList.length > 0 ? (
          renderUserList()
        ) : (
          <div className={"text-center"}>No Messages.</div>
        )}
      </List>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    unmessagedUsers: state.usersReducer.users || [],
  };
};

export default connect(mapStateToProps)(withRouter(ChatNavCard));
