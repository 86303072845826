import { useHistory, useLocation } from "react-router";
import React from "react";

import { getObjectParam, getStringParam } from "../helpers/paramVerifier";
import { constants } from "../helpers/constants";
import Button from "@material-ui/core/Button";
import { useState } from "react";
import { useEffect } from "react";


 const MenuItems = function (props){
const {classes} = props;
const location = useLocation()
const history = useHistory()
const [items, setItems] = useState([])

const buildMenuItems = () =>{
    items.length = 0
try {
    let userDetail = getObjectParam(props.userDetail);
    let userType = getStringParam(userDetail.userType);
    if (userType == constants.USER_TYPE_EMPLOYER) {
      items.push(
        <Button
          className={
            location.pathname === "/videos"
              ? [classes.button, classes.selectButton]
              : [classes.button]
          }
          onClick={() => {
            history.push("/videos");
          }}
        >
          Videos
        </Button>
      );
      items.push(
        <Button
          className={
            location.pathname === "/posts"
              ? [classes.button, classes.selectButton]
              : [classes.button]
          }
          onClick={() => {
            history.push("/posts");
          }}
        >
          Posts
        </Button>
      );
      items.push(
        <Button
          className={
            location.pathname === "/all-positions"
              ? [classes.button, classes.selectButton]
              : [classes.button]
          }
          onClick={() => {
            props.checkUserType("Employer")
          }}
        >
          Casting/Employer
        </Button>
      );
      items.push(
        <Button
          className={
            location.pathname === "/user-profiles"
              ? [classes.button, classes.selectButton]
              : [classes.button]
          }
          onClick={() => {
            history.push("/user-profiles");
          }}
        >
          Profiles
        </Button>
      );
      // items.push(
      //   <Button
      //     className={
      //       location.pathname === "/bid-calendar"
      //         ? [classes.button, classes.selectButton]
      //         : [classes.button]
      //     }
      //     onClick={() => {
      //       history.push("/bid-calendar");
      //     }}
      //   >
      //     Bid
      //   </Button>
      // );
    } else if (userType == constants.USER_TYPE_JOBSEEKER) {
      items.push(
        <Button
          className={
            location.pathname === "/videos"
              ? [classes.button, classes.selectButton]
              : [classes.button]
          }
          onClick={() => {
            history.push("/videos");
          }}
        >
          Videos
        </Button>
      );
      items.push(
        <Button
          className={
            location.pathname === "/posts"
              ? [classes.button, classes.selectButton]
              : [classes.button]
          }
          onClick={() => {
            history.push("/posts");
          }}
        >
          Posts
        </Button>
      );
      items.push(
        <Button
          className={
            location.pathname === "/positions"
              ? [classes.button, classes.selectButton]
              : [classes.button]
          }
          onClick={() => {
            props.checkUserType("JobSeeker")
          }}
        >
          Casting/Employer
        </Button>
      );
      items.push(
        <Button
          className={
            location.pathname === "/applied-jobs"
              ? [classes.button, classes.selectButton]
              : [classes.button]
          }
          onClick={() => {
            history.push("/applied-jobs");
          }}
        >
          Applied
        </Button>
      );
      // items.push(
      //   <Button
      //     className={
      //       location.pathname === "/bid-calendar"
      //         ? [classes.button, classes.selectButton]
      //         : [classes.button]
      //     }
      //     onClick={() => {
      //       history.push("/bid-calendar");
      //     }}
      //   >
      //     Bid
      //   </Button>
      // );
    } else if (userType == constants.USER_TYPE_SOCIALMEDIAUSER) {
      items.push(
        <Button
          className={
            location.pathname === "/videos"
              ? [classes.button, classes.selectButton]
              : [classes.button]
          }
          onClick={() => {
            history.push("/videos");
          }}
        >
          Videos
        </Button>
      );
      items.push(
        <Button
          className={
            location.pathname === "/posts"
              ? [classes.button, classes.selectButton]
              : [classes.button]
          }
          onClick={() => {
            history.push("/posts");
          }}
        >
          Posts
        </Button>
      );
      // items.push(
      //   <Button
      //     className={
      //       location.pathname === "/bid-calendar"
      //         ? [classes.button, classes.selectButton]
      //         : [classes.button]
      //     }
      //     onClick={() => {
      //       history.push("/bid-calendar");
      //     }}
      //   >
      //     Bid
      //   </Button>
      // );
    } else {
      items.push(
        <Button
          className={
            location.pathname === "/videos"
              ? [classes.button, classes.selectButton]
              : [classes.button]
          }
          onClick={() => {
            history.push("/videos");
          }}
        >
          Videos
        </Button>
      );
      items.push(
        <Button
          className={
            location.pathname === "/posts"
              ? [classes.button, classes.selectButton]
              : [classes.button]
          }
          onClick={() => {
            history.push("/posts");
          }}
        >
          Posts
        </Button>
      );
      // items.push(
      //   <Button
      //     className={
      //       location.pathname === "/bid-calendar"
      //         ? [classes.button, classes.selectButton]
      //         : [classes.button]
      //     }
      //     onClick={() => {
      //       history.push("/bid-calendar");
      //     }}
      //   >
      //     Bid
      //   </Button>
      // );
    }
    setItems([...items])
  } catch (error) {
      console.warn(error)
  }
}



useEffect(buildMenuItems, [location])


return items;




}


export default MenuItems