import React from "react";
import { Redirect } from "react-router";

import PostAdTableComponent from "../components/PostAdTableComponent";
import PostPosTable from "../components/PostPosTable";
import PositionDataTable from "../containers/Positions/PositionsDataTable";
import PositionViewDetails from "../containers/Positions/PositionViewDetails";
import AppliedViewDetails from "../containers/Positions/AppliedViewDetails";

import PositionTab from "../components/Positions/PositionTab";
import ApplicantProfileViewer from "../components/Positions/ApplicantProfileViewer";
import EditPositionDetails from "../components/Positions/EditPositionDetails";
import ViewPositionDetails from "../components/Positions/ViewPositionDetails";

import PostPosDetails from "../components/PostPosDetails";
import CreatePositionForm from "../components/CreatePositionForm";
import ViewFullApplicationContainer from "../containers/ViewFullApplicationContainer";
import UserProfilesListViewContainer from "../containers/UserProfileListView/UserProfileListViewContainer";
import PositionApplicantsContainer from "../containers/PositionApplicantsContainer";
import Bid from "../components/Bid/Bid";
import BidCalendar from "../components/Bid/CalendarComponent/Calendar";

import PostsComponent from "../components/PostsComponent";
import PostComponent from "../components/PostsComponent/PostFeed/Post";
import EditPostComponent from "../components/PostsComponent/EditPostForm";

const getApplicantsContainer = (props) => {
  let pathname = props.location.pathname.replace("/position/applicants/", "");
  let arr = pathname.split("/");
  let match = { ...props.match };
  match.params.status = arr.length > 1 ? arr[1] : "";
  props = { ...props, match: match };
  return <PositionApplicantsContainer {...props} />;
};

const routes = [
  // {
  //   path: "/posts",
  //   exact: true,
  //   title: "Posts",
  //   render: (props) => <PostsComponent {...props} />,
  // },
  // {
  //   path: "/posts/:post_id",
  //   exact: true,
  //   title: "View Post",
  //   render: (props) => <PostComponent {...props} />,
  // },
  // {
  //   path: "/posts/edit/:post_id",
  //   exact: true,
  //   title: "Edit Post",
  //   render: (props) => <EditPostComponent {...props} />,
  // },
  {
    path: "/applied-jobs",
    exact: true,
    title: "APPLIED JOBS",
    render: (props) => <PostAdTableComponent {...props} />,
  },
  {
    path: "/positions",
    exact: true,
    title: "POSITION LIST",
    render: (props) => <PostAdTableComponent {...props} />,
  },
  {
    path: "/positions/:ad_id",
    exact: true,
    title: "POSITION BY POSITION ID",
    render: (props) => <PositionTab {...props} />,
  },
  {
    path: "/positions/view/:id",
    exact: true,
    title: "VIEW POSITION BY POSITION ID",
    render: (props) => <ViewPositionDetails {...props} />,
  },
  {
    path: "/positions/edit/:id",
    exact: true,
    title: "EDIT POSITION BY POSITION ID",
    render: (props) => <EditPositionDetails {...props} />,
  },
  {
    path: "/all-positions/:ad_id",
    exact: true,
    title: "ALL POSITIONS BY POSITION ID",
    render: (props) => <PositionDataTable {...props} />,
  },
  {
    path: "/all-positions/:id/view/:id",
    exact: true,
    title: "ALL POSITIONS BY POSITION ID",
    render: (props) => <PositionViewDetails {...props} />,
  },
  {
    path: "/applied-jobs/:id/view/:id",
    exact: true,
    title: "APPLIED JOBS BY POSITION ID",
    render: (props) => <AppliedViewDetails {...props} />,
  },
  {
    path: "/all-positions",
    exact: true,
    title: "ALL POSITIONS",
    render: (props) => <PostPosTable {...props} />,
  },
  {
    path: "/position/applicants/:position_id",
    exact: true,
    title: "ALL POSITIONS",
    render: (props) => getApplicantsContainer(props),
  },
  {
    path: "/positions-create/:positionId",
    exact: true,
    title: "CREATE POSITION",
    render: (props) => <CreatePositionForm {...props} />,
  },
  {
    path: "/positions-create",
    exact: true,
    title: "CREATE POSITION",
    render: (props) => <CreatePositionForm {...props} />,
  },
  {
    path: "/position/apply/:pos_id",
    exact: true,
    title: "APPLY TO POSITION",
    render: (props) => <PostPosDetails {...props} />,
  },
  {
    path: "/applicants/:position_id/:applicant_id",
    exact: false,
    title: "ApplicantProfileViewer",
    render: (props) => <ApplicantProfileViewer {...props} />,
  },
  {
    path: "/user-profiles",
    exact: true,
    title: "VIEW USER PROFILES",
    render: (props) => <UserProfilesListViewContainer {...props} />,
  },
  // {
  //   path: "/bid/:id",
  //   exact: true,
  //   title: "Bid Calendar",
  //   render: (props) => <Bid {...props} />,
  // },
  // {
  //   path: "/bid-calendar",
  //   exact: true,
  //   title: "Bid Calendar",
  //   render: (props) => <BidCalendar {...props} />,
  // },
];

export default routes;
