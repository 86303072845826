import request1 from "../helpers/request";
import request from "../helpers/requestEngine";
import snackbar from "snackbar";
import { openDialog, closeDialog } from "./appActions";

import getURL from "../helpers/apis";

export const onFormSubmit = (params, history) => {
  return (dispatch) => {
    dispatch(formSubmitting());
    return dispatch(submitForm(params, history));
  };
};
//request('post', 'AD', true, formData)
function formSubmitting() {
  return {
    type: "POST_POS_SUBMITTING",
  };
}
function submitForm(params, history) {
  return (dispatch) => {
    request1("post", getURL("SAVE_POSITION"), true, params).then(
      (response) => {
        //eventTarget.reset();
        // snackbar.show("Your POS is created!");
        dispatch(openDialog("Role Created."));

        dispatch(onSubmitSuccess());
        history.push("/all-positions");
      },
      (err) => {
        // snackbar.show("Something went wrong. Please try again.");
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(onSubmitFailed());
      }
    );
  };
}

function onSubmitSuccess() {
  return {
    type: "POST_POS_SUBMITTING_SUCCESS",
  };
}

function onSubmitFailed() {
  return {
    type: "POST_POS_SUBMITTING_FAILED",
  };
}
