import React from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import bindActionCreators from "redux/es/bindActionCreators";

import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CloneIcon from "@material-ui/icons/FileCopy";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Cancel";
import ViewIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  isValidParam,
  getStringParam,
  getObjectParam,
  getArrayParam,
  getIntParam,
  getBooleanParam,
} from "../../helpers/paramVerifier";
import {
  closePositionStatus,
  clonePosition,
  deletePositionById,
} from "../../actions/getPosActions";
import { formatDate } from "../../helpers/globalFunctions";
import ReactPaginate from "react-paginate";
import { constants } from "../../helpers/constants";

const dataTableStyles = (theme) => ({ ...styles });

let styles = {
  root: {},
  tableCellChild: {
    fontSize: 14,
    fontWeight: 600,
  },
  addButton: {
    width: 30,
    height: 30,
  },
  pagingContainer: {
    float: "right",
    //marginRight: 10
  },
  pageClassName: {
    display: "inline-block",
    paddingLeft: 5,
    paddingRight: 5,
  },
  activeClassName: {
    backgroundColor: " #25242445",
    color: "#ffffff",
  },
  containerClassName: {
    border: "1px solid #25242445",
    lineHeight: 1.5,
    paddingLeft: 10,
    paddingRight: 10,
    width: "fit-content",
  },
  previousClassName: {
    display: "inline-block",
  },
  nextClassName: {
    display: "inline-block",
  },
  breakClassName: {
    display: "inline-block",
    paddingLeft: 5,
    paddingRight: 5,
  },
  pageLinkClassName: {
    color: "#757575",
  },
  previousLinkClassName: {
    color: "#757575",
  },
  nextLinkClassName: {
    color: "#757575",
  },
};
class PositionDataTable extends React.Component {
  constructor(props) {
    super(props);
    this.fields = [];
    this.state = {
      isShowEditPositionDetailsDailog: false,
      isRedirect: false,
      redirectPath: "",
      location: this.props.history.location.pathname,
    };
  }

  getTableHeader() {
    let element = null;
    try {
      let fields = getArrayParam(this.props.fields);
      fields = fields.filter((f) => {
        return !f.is_hidden;
      });
      let arrTheads = fields.map((m, i) => {
        let { name, label, width, ...other } = m;

        let style = { paddingLeft: 10, paddingRight: 10 };
        //border commented in header
        // if (i > 0) {
        //   style.borderLeft = "1px solid #e0e0e0";
        // }
        return (
          <TableCell key={name + "-" + i} style={style}>
            <Typography
              noWrap
              variant="caption"
              style={{ width: width, fontSize: 14, fontWeight: 600, ...other }}
              title={label}
            >
              {label}
            </Typography>
          </TableCell>
        );
      });
      element =
        arrTheads.length > 0 ? (
          <TableHead>
            <TableRow style={{ height: 30 }}>{arrTheads}</TableRow>
          </TableHead>
        ) : null;
    } catch (error) {}
    return element;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.location !== this.props.history.location.pathname) {
      this.setState({ location: this.props.history.location.pathname });
    }
  }

  getTableBody() {
    let element = null;
    try {
      let rows = new Array();
      let fields = getArrayParam(this.props.fields);
      let values = getArrayParam(this.props.values);
      // values= values.filter(pos=>{

      //   return !!this.props.searchText ?
      //           pos.positionType
      //           .toLowerCase()
      //           .includes(this.props.searchText.trim().toLowerCase())
      //           :true
      // })
      fields = fields.filter((f) => {
        return !f.is_hidden;
      });

      values.forEach((obj, index) => {
        let rowCells = fields.map((field, i) => {
          let { name, label, width, ...other } = field;
          let value = this.getFieldValue(field, obj);

          let valProps = new Object();
          valProps.noWrap = true;
          valProps.style = { width: width, fontSize: 14, ...other };
          valProps.variant = "caption";
          if (!isValidParam(field.link)) {
            valProps.title = value;
          }
          if (field.name === "clone") {
            valProps.title = "Clone Position";
          }
          if (field.name === "close") {
            if (obj.positionStatus.toLowerCase() === "closed") {
              valProps.title = "Delete Position";
            } else {
              valProps.title = "Close Position";
            }
          }
          if (field.name === "edit") {
            valProps.title = "Edit Position";
          }
          if (field.name === "view") {
            valProps.title = "View Position";
          }
          if (field.name === "positionStatus") {
            if (obj.positionStatus.toLowerCase() === "closed") {
              valProps.style = {
                width: width,
                fontSize: 14,
                ...other,
                color: "#a94442",
              };
            } else {
              valProps.style = {
                width: width,
                fontSize: 14,
                ...other,
                color: "#3f51b5",
              };
            }
          }
       

          valProps.children = value;
          return (
            <TableCell
              key={name + "-" + i}
              style={
                field.name === "noofApplicants"
                  ? { padding: "8px", textAlign: "center" }
                  : { padding: "8px" }
              }
              className="MuiTableBody-root"
            >
              <Typography {...valProps} />
            </TableCell>
          );
        });

        let style = { height: 30 };
        // if (index % 2 != 0) {
        //   style.backgroundColor = "#00000014";
        // }

        rows.push(
          <TableRow
            hover
            style={style}
            onClick={() => this.hadleRedirectToViewApplicants(obj._id)}
          >
            {rowCells}
          </TableRow>
        );
      });

      element = rows.length > 0 ? <TableBody>{rows}</TableBody> : null;
    } catch (error) {}
    return element;
  }

  handleRedirectToPositions(row_id) {
    this.props.history.push(`/positions/${row_id}`);
  }

  redirect(path) {
    this.setState({ isRedirect: true, redirectPath: path });
  }

  handleRedirectToViewPositions(id) {
    this.props.history.push(`/positions/view/${id}`);
  }

  handleClosePositions(id) {
    const body = {
      status: "Closed",
    };
    this.props.closePositionStatus(id, body);
  }

  handleDeletePositions(id) {
    this.props.deletePositionById(id);
  }

  handleRedirectToEditPositions(id) {
    this.props.history.push(`/positions/edit/${id}`);
  }
  handleClonePositions(id) {
    this.props.history.push(`/positions-create/${id}`);
  }

  hadleRedirectToViewApplicants(id) {
    this.props.history.push(`/positions/${id}`);
  }
  // handleClonePositions(rowObj) {
  //   const body = {
  //     industry: rowObj.industry,
  //     role: rowObj.role,
  //     otherrolename: rowObj.otherrolename,
  //     gender: rowObj.gender,
  //     ageMin: rowObj.ageMin,
  //     ageMax: rowObj.ageMax,
  //     lastDate: moment(rowObj.lastDate).format("MM/DD/YYYY"),
  //     description: rowObj.description,
  //     otherRolesCanApply: rowObj.otherRolesCanApply,
  //     positionType: rowObj.positionType,
  //     compensation: rowObj.compensation,
  //     houseno: rowObj.houseno,
  //     street: rowObj.street,
  //     city: rowObj.city,
  //     state: rowObj.state,
  //     country: rowObj.country,
  //     amount: rowObj.amount
  //   };
  //   this.props.clonePosition(body);
  // }

  getFieldValue(field, rowObj) {
    let element = null;
    let value = null;
    try {
      field = getObjectParam(field);
      rowObj = getObjectParam(rowObj);
      value = getStringParam(rowObj[field.name]);
      if (field.type == "date") {
        value = formatDate(value);
      }
     
      if (isValidParam(field.link)) {
        let { name, baseUrl, ...others } = field.link;
        let url = baseUrl + rowObj[name];
        if(field.name === "role" && rowObj.role == "Actor"){
         
      
          value = (
            <Typography
              onClick={() => this.hadleRedirectToViewApplicants(rowObj._id)}
              {...others}
              title={getStringParam(rowObj[field.name])}
              style={{
                textDecoration: "none",
                cursor: "pointer",
                color: constants.PRIMARY_COLOR,
              }}
              variant="body2"
            >
              {getStringParam(rowObj[field.name]) +" : "+ rowObj['otherrolename']}
            </Typography>
          );
        }else {
            value = (
          <Typography
            onClick={() => this.hadleRedirectToViewApplicants(rowObj._id)}
            {...others}
            title={getStringParam(rowObj[field.name])}
            style={{
              textDecoration: "none",
              cursor: "pointer",
              color: constants.PRIMARY_COLOR,
            }}
            variant="body2"
          >
            {getStringParam(rowObj[field.name])}
          </Typography>
        );
        }
      
      }

     
      
      if (field.name == "view") {
        value = (
          <ViewIcon
            style={{ color: "#1f1f1f" }}
            onClick={(e) => {
              e.stopPropagation();
              this.handleRedirectToViewPositions(rowObj._id);
            }}
          />
        );
      }
      if (field.name == "edit") {
        value = (
          <EditIcon
            style={{ color: "#1f1f1f" }}
            onClick={(e) => {
              e.stopPropagation();
              this.handleRedirectToEditPositions(rowObj._id);
            }}
          />
        );
      }
      if (field.name == "close") {
        if (rowObj.positionStatus.toLowerCase() === "closed") {
          value = (
            <DeleteIcon
              onClick={(e) => {
                e.stopPropagation();
                this.handleDeletePositions(rowObj._id);
              }}
            />
          );
        } else {
          value = (
            <CloseIcon
              style={{ color: "#a94442" }}
              onClick={(e) => {
                e.stopPropagation();
                this.handleClosePositions(rowObj._id);
              }}
            />
          );
        }
      }
      if (
        field.name == "clone"
        // && rowObj.positionStatus.toLowerCase() === "closed"
      ) {
        value = (
          // <Link to={`/positions-create/${rowObj._id}`}>
          <CloneIcon
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              this.handleClonePositions(rowObj._id);
            }}
          />
          // </Link>
        );
      }
      if (field.name === "ageMin") {
        value = `${rowObj.ageMin} - ${rowObj.ageMax}`;
      }

      if (field.name === "country") {
        value =
          rowObj.country === "India"
            ? `${rowObj.industry}, ${rowObj.country}`
            : rowObj.country;
      }

     

      if (isValidParam(value)) {
        element = value;
      }
    } catch (error) {}
    return element;
  }

  getPaginationProps() {
    let props = new Object();
    try {
      let { classes, paging } = this.props;
      paging = getObjectParam(paging);

      props.pageClassName = classes.pageClassName;
      props.breakClassName = classes.breakClassName;
      props.activeClassName = classes.activeClassName;
      props.containerClassName = classes.containerClassName;
      props.previousClassName = classes.previousClassName;
      props.nextClassName = classes.nextClassName;
      props.pageLinkClassName = classes.pageLinkClassName;
      props.previousLinkClassName = classes.previousLinkClassName;
      props.nextLinkClassName = classes.nextLinkClassName;

      props.initialPage = 0;
      props.pageCount = 5;
      props.previousLabel = "<<";
      props.nextLabel = ">>";

      props = { ...props, ...paging };
    } catch (error) {}
    return props;
  }
  handleClosePositionDetailsDailog() {
    this.setState({ isShowEditPositionDetailsDailog: false });
  }

  render() {
    if (this.state.isRedirect) {
      this.state.isRedirect = false;
      return <Redirect to={this.state.redirectPath} />;
    }
    const { isShowEditPositionDetailsDailog } = this.state;
    let { classes, tableStyle, paging } = this.props;
    let header = this.getTableHeader();
    let body = this.getTableBody();
    return (
      <>
        <Grid>
          {isValidParam(paging) && (
            <Grid className={classes.pagingContainer}>
              <ReactPaginate {...this.getPaginationProps()} />
            </Grid>
          )}
          <Grid container style={{ overflowX: "auto", paddingBottom: 10 }}>
            <Table style={tableStyle}>
              {header}
              {body}
            </Table>
          </Grid>
        </Grid>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    posDetails: state.PosTableReducer.posDetails,
    applicants: state.applicantsReducer.applicants,
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      closePositionStatus,
      clonePosition,
      deletePositionById,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(dataTableStyles)(withRouter(PositionDataTable)));
