import request from "../helpers/requestEngine";
import request1 from "../helpers/request";
import getURL from "../helpers/apis";
import { openDialog, closeDialog } from "./appActions";

export const addBid = (formData, history, baseamt) => {
  return (dispatch) => {
    if (
      formData.type === "" ||
      formData.type === "Select Type" ||
      formData.bidamt === ""
    ) {
      dispatch(openDialog("Enter all bid details"));
    } else if (Number(formData.bidamt) <= Number(baseamt)) {
      dispatch(openDialog("Bid Amount has to be higher than Base Amount"));
    } else {
      request("post", "POST_BID", true, formData).then(
        (response) => {
          dispatch(postBidSuccess(response.data.data));
          // history.goBack();
          dispatch(openDialog("Bid Posted!"));
          const { slotdate, adspace, cycle } = formData;
          dispatch(getBids({ slotdate, space: adspace, cycle }));
          dispatch(clearEvents());
        },
        (err) => {
          dispatch(openDialog("Something went wrong. Please try again."));

          dispatch(postBidFailed(err));
          return err;
        }
      );
    }
    // const response = await request("post", "POST_BID", true, formData);
    // if (response.data) window.alert("Success");
    // const { slotdate } = formData;
    // const bids =  request1(
    //   "get",
    //   `${getURL("GET_BIDS")}${slotdate}`,
    //   false
    // );
  };
};

function postBidSuccess() {
  return {
    type: "POST_BID_SUCCESS",
  };
}

function postBidFailed(err) {
  return {
    type: "POST_BID_FAILED",
  };
}

export const getBids = (params) => {
  return (dispatch) => {
    dispatch(fetchingBids());

    return dispatch(fetchBids(params));
  };
};

function fetchingBids() {
  return {
    type: "GET_BIDS",
  };
}

function fetchBids(params) {
  return (dispatch) => {
    request1("get", getURL("GET_BIDS"), true, null, params).then(
      (response) => {
        dispatch(getBidsSuccess(response.data.data));
        dispatch(clearEvents());
      },
      (err) => {
        dispatch(openDialog("Something went wrong. Please try again."));

        dispatch(getBidsFailed(err));
      }
    );
  };
}

function getBidsSuccess(data) {
  return {
    type: "GET_BIDS_SUCCESS",
    payload: data,
  };
}

function getBidsFailed(err) {
  return {
    type: "GET_BIDS_FAILED",
  };
}

export const getBidEvents = (data) => async (dispatch) => {
  try {
    const res = await request("get", "GET_BID_EVENTS", true, null, data);
    dispatch(getBidEventsData(res.data.data));
    dispatch(clearBids());
  } catch (err) {
    dispatch(bidError(err));
  }
};

const getBidEventsData = (res) => {
  return {
    type: "GET_BIDEVENTS",
    payload: res,
  };
};

const bidError = (err) => {
  return {
    type: "BID_ERROR",
    payload: { msg: err.message },
  };
};

const clearEvents = () => {
  return {
    type: "CLEAR_EVENTS",
  };
};

const clearBids = () => {
  return {
    type: "CLEAR_BIDS",
  };
};
