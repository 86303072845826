import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Typography,
  FormLabel,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Loader } from "../../components/UtilityComponents";
import { connect } from "react-redux";
import withRouter from "react-router-dom/es/withRouter";
import {
  getUpgradeDetails,
  getPurchaseDetails,
} from "../../actions/userDetailsAction";
import { constants } from "../../helpers/constants";

const PurchaseDetails = ({
  open,
  purchaseType,
  handleClose,
  handleMakePayment,
  subscriptionId,
  userType,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (purchaseType === "upgrade") {
      dispatch(getUpgradeDetails(subscriptionId));
    } else {
      dispatch(getPurchaseDetails(subscriptionId));
    }
  }, [subscriptionId]);
  const upgradeDetails = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.upgradeDetails
  );
  const upgradeDetailsLoading = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.upgradeDetailsLoading
  );
  const purchaseDetails = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.purchaseDetails
  );
  const purchaseDetailsLoading = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.purchaseDetailsLoading
  );
  if (purchaseType === "upgrade") {
    if (!upgradeDetailsLoading) {
      return (
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="sm"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="inherit-bg-color"
        >
          <DialogTitle id="scroll-dialog-title">
            Upgrade Subscription
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={0}>
              <Grid item xs={8}>
                <Typography style={{ color: constants.PRIMARY_COLOR }}>
                  Previous Plan User Type
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  {upgradeDetails && upgradeDetails.previousUserType}
                </Typography>
              </Grid>

              <Grid item xs={8}>
                <Typography style={{ color: constants.PRIMARY_COLOR }}>
                  Previous Payment Id
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  {upgradeDetails && upgradeDetails.previousPaymentId}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: constants.PRIMARY_COLOR }}>
                  Previous Plan Price
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  Rs. {upgradeDetails && upgradeDetails.oldPlanPrice}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: constants.PRIMARY_COLOR }}>
                  Previous Plan CGST
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  Rs. {upgradeDetails && upgradeDetails.oldPlanCGST}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: constants.PRIMARY_COLOR }}>
                  Previous Plan SGST
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  Rs. {upgradeDetails && upgradeDetails.oldPlanSGST}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: constants.PRIMARY_COLOR }}>
                  Previous Plan Total
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  Rs. {upgradeDetails && upgradeDetails.oldTotalPaid}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: constants.PRIMARY_COLOR }}>
                  Previous Plan Days Used
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  {upgradeDetails && upgradeDetails.previousPlanDays}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: constants.PRIMARY_COLOR }}>
                  Previous Plan Payment Refund
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  Rs. {upgradeDetails && upgradeDetails.previousPaymentRefund}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: constants.PRIMARY_COLOR }}>
                  Chosen User Type
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>{userType}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: constants.PRIMARY_COLOR }}>
                  Chosen Plan Price
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  Rs. {upgradeDetails && upgradeDetails.newPlanPrice}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: constants.PRIMARY_COLOR }}>
                  Chosen Plan Cycle
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  {upgradeDetails && upgradeDetails.newPlanCycle}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: constants.PRIMARY_COLOR }}>
                  Chosen Plan SGST Percent
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  {upgradeDetails && upgradeDetails.taxSGSTPercent}%
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: constants.PRIMARY_COLOR }}>
                  Chosen Plan CGST Percent
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  {upgradeDetails && upgradeDetails.taxCGSTPercent}%
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: constants.PRIMARY_COLOR }}>
                  Chosen Plan SGST Amount
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  Rs. {upgradeDetails && upgradeDetails.taxCGSTAmount}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: constants.PRIMARY_COLOR }}>
                  Chosen Plan CGST Amountount
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  Rs. {upgradeDetails && upgradeDetails.taxSGSTAmount}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: constants.PRIMARY_COLOR }}>
                  Total Price After Tax
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  Rs. {upgradeDetails && upgradeDetails.totalPriceAfterTax}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: constants.PRIMARY_COLOR }}>
                  Adjusted Final Payment
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  Rs. {upgradeDetails && upgradeDetails.adjustedFinalPayment}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleClose}
              color="primary"
              style={{ textTransform: "none" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleMakePayment}
              color="primary"
              style={{ textTransform: "none" }}
            >
              Proceed
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return (
      <div>
        <Loader />
      </div>
    );
  }
  if (!purchaseDetailsLoading) {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className="inherit-bg-color"
      >
        <DialogTitle id="scroll-dialog-title">
          Purchase Subscription
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={0}>
            <Grid item xs={8}>
              <Typography style={{ color: constants.PRIMARY_COLOR }}>
                Plan User Type
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {purchaseDetails && purchaseDetails.userType}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ color: constants.PRIMARY_COLOR }}>
                Plan Price
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                Rs. {purchaseDetails && purchaseDetails.totalPrice}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ color: constants.PRIMARY_COLOR }}>
                SGST Percent
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {purchaseDetails && purchaseDetails.taxSGSTPercent}%
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ color: constants.PRIMARY_COLOR }}>
                CGST Percent
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {purchaseDetails && purchaseDetails.taxCGSTPercent}%
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ color: constants.PRIMARY_COLOR }}>
                SGST Amount
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                Rs. {purchaseDetails && purchaseDetails.taxCGSTAmount}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ color: constants.PRIMARY_COLOR }}>
                CGST Amount
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                Rs. {purchaseDetails && purchaseDetails.taxSGSTAmount}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ color: constants.PRIMARY_COLOR }}>
                Total Price After Tax
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                Rs. {purchaseDetails && purchaseDetails.totalPriceAfterTax}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            color="primary"
            style={{ textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleMakePayment}
            color="primary"
            style={{ textTransform: "none" }}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return (
    <div>
      <Loader />
    </div>
  );
};

export default PurchaseDetails;
