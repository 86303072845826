import React from "react";

export class Button extends React.Component {
  render() {
    return (
      <button
        className={this.props.buttonDesign.className}
        type={this.props.type || ""}
      >
        {this.props.buttonDesign.value}
      </button>
    );
  }
}
