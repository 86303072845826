import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function ConfirmationDialog({
  open,
  handleConfirm,
  handleCancel,
  title,
  description,
  id,
  deleteType,
}) {
  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancel}
          style={{ textTransform: "none" }}
          color="primary"
        >
          Cancel
        </Button>
        {deleteType === "forme" ? (
          <Button
            onClick={() => handleConfirm("forme")}
            color="secondary"
            autoFocus
            style={{ textTransform: "none" }}
          >
            Delete For Me
          </Button>
        ) : deleteType === "forall" ? (
          <>
            <Button
              onClick={() => handleConfirm("forme")}
              color="secondary"
              autoFocus
              style={{ textTransform: "none" }}
            >
              Delete For Me
            </Button>
            <Button
              onClick={() => handleConfirm("forall")}
              color="secondary"
              autoFocus
              style={{ textTransform: "none" }}
            >
              Delete For All
            </Button>
          </>
        ) : (
          <Button
            onClick={() => handleConfirm()}
            color="secondary"
            autoFocus
            style={{ textTransform: "none" }}
          >
            Delete All Messages
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
