const modalEventReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_MODAL_CONTENT":
      return action.content;
    case "CLEAR_MODAL":
      return {
        ...state,
        desc: "",
        title: "",
      };
    case "MODAL_DESC_CHANGED":
      return {
        ...state,
        desc: action.desc,
      };
    case "MODAL_TITLE_CHANGED":
      return {
        ...state,
        title: action.title,
      };
    case "MODAL_START_CHANGED":
      return {
        ...state,
        start: action.start,
      };
    case "MODAL_END_CHANGED":
      return {
        ...state,
        end: action.end,
      };
    default:
      return state;
  }
};

const calendarEventReducer = (events, action) => {
  switch (action.type) {
    case "ASSIGN_CALENDAR_EVENTS":
      return action.events;

    case "ADD_EVENT":
      return [...events, action.event];

    case "UPDATE_CALENDAR_EVENT":
      return updateEventInArray(events, action.event);

    case "DELETE_CALENDER_EVENT":
      return deleteEvent(events, action.event_id);

    default:
      return events;
  }
};

const updateEventInArray = (events, newEvent) => {
  const updatedEvents = events.map((event) => {
    if (event._id !== newEvent._id) {
      return event;
    }
    return newEvent;
  });
  return updatedEvents;
};

const deleteEvent = (events, event_id) => {
  return events.filter((event) => {
    return event._id !== event_id;
  });
};

const calendarReducer = (
  state = {
    events: [],
    modalContent: {},
  },
  action
) => {
  return {
    events: calendarEventReducer(state.events, action),
    modalContent: modalEventReducer(state.modalContent, action),
  };
};

export default calendarReducer;
