// import dependencies
import React from "react";
import { Link } from "react-router-dom";
import { TextInput } from "./InputFields";
import {
  getADs,
  getAppliedADs,
  fetchFilteredPositions,
} from "../actions/getADsActions";
import { updateRecentSearchById } from "../actions/getPosActions";
import bindActionCreators from "redux/es/bindActionCreators";
import connect from "react-redux/es/connect/connect";
// import snackbar from "snackbar";
import { openDialog, closeDialog } from "../actions/appActions";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
//import TextButtons from './Mbutton'
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import DataTable from "../components/DataTable";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import { MenuItem, TextField } from "@material-ui/core";
import PositionDataTablePagination from "../components/Positions/PositionDataTablePagination";

import {
  isValidParam,
  getStringParam,
  getObjectParam,
  getArrayParam,
  getIntParam,
  getBooleanParam,
} from "../helpers/paramVerifier";
import { Loader, NoRecords } from "../components/UtilityComponents";
import Filter from "./Filter";
import {
  filterParams,
  updateSearchParams,
  searchNamesByUserId,
  getRecentSearch,
  createFilterSearch,
  deleteFilterSearch,
} from "../actions/filterAction";
import { isEmpty } from "lodash";
import {
  requestCountries,
  requestStates,
  requestIndustries,
} from "../actions/registerActions";
import { getRoles, getJobTypes } from "../actions/userDetailsAction";

const styles = (theme) => ({
  root: {
    marginTop: 30,
    width: "100%",
  },
  appBar: {
    height: 40,
    marginLeft: 25,
    borderBottom: "1px solid #00465b",
  },
  tabs: {
    minHeight: 40,
  },
  tab: {
    minHeight: 40,
    fontSize: 14,
    textTransform: "capitalize",
    color: "black",
    //backgroundColor: '#00465bb0',
  },
  tabSelected: {
    borderBottom: "3px solid #00465B",
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "8px",
  },
});

class PostAdTableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      getADsResponse: [],
      searchString: "",
      searchParams: {
        searchAgeMax: 0,
        searchAgeMin: 0,
        searchIndustry: "Any",
        searchGender: "Any",
        searchCountry: "Any",
        searchRole: "Any",
        searchState: "Any",
        searchCompensation: "Any",
        searchEligibility: "Any",
        searchStatus: "Any",
        searchPosted: "Any",
        searchType: "Any",
      },
      currentSearchNameIndex: 0,
      searchNames: [],
      anchorEl: null,
      currentStatus: "ALL",
      pageNumber1: 1,
      pageNumber2: 1,
      totalPages1: 0,
      totalPages2: 0,
      searchStringAppliedJobs: "",
    };

    this.onFilter = this.onFilter.bind(this);
    this.handleSearchNameSelect = this.handleSearchNameSelect.bind(this);
    this.onCreateSearch = this.onCreateSearch.bind(this);
    this.onDeleteSearch = this.onDeleteSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleChangePageNumber = this.handleChangePageNumber.bind(this);
  }

  handleChangePageNumber(page) {
    if (this.props.match.path == "/positions") {
      this.setState({ pageNumber1: page });
      getRecentSearch().then((response) => {
        const record = response.data.data[0];

        if (record) {
          const {
            searchAgeMax,
            searchAgeMin,
            searchIndustry,
            searchGender,
            searchCountry,
            searchName,
            searchRole,
            searchState,
            searchCompensation,
            searchEligibility,
            searchStatus,
            searchPosted,
            searchType,
          } = record;
          const params = {
            ageMax: searchAgeMax,
            ageMin: searchAgeMin,
            industry: searchIndustry,
            gender: searchGender,
            country: searchCountry,
            role: searchRole,
            state: searchState,
            compensation: searchCompensation,
            eligibility: searchEligibility,
            status: searchStatus,
            posted: searchPosted,
            positionType: searchType,
            page: this.state.pageNumber1 - 1,
            limit: 20,
            key: this.state.searchString,
          };
          this.setState({
            ...this.state,
            searchParams: record,
          });
          const { page, limit, key } = params;
          return this.props
            .fetchFilteredPositions({ page: page, limit: limit, key: key })
            .then((res) => {
              const result = res.payload.data.map((f) => f);
              this.setState({
                getADsResponse: result,
                totalPages1: res.payload.totalPages,
              });
              return res;
            });
        }
      });
      const params = { userId: this.props.userDetail._id };

      searchNamesByUserId(params).then((response) => {
        if (response.data.data) {
          this.setState({
            ...this.state,
            searchNames: response.data.data,
          });
        }
      });
    } else this.setState({ pageNumber2: page });
  }

  onFilter(params) {
    this.props.updateRecentSearchById(params);
    const { page, limit, key } = params;
    return this.props
      .fetchFilteredPositions({ page: page, limit: limit, key: key })
      .then((res) => {
        const result = res.payload.data;
        this.setState({
          getADsResponse: result,
          totalPages1: res.payload.totalPages,
        });

        setTimeout(() => {
          this.setState({
            getADsResponse: result,
            totalPages1: res.payload.totalPages,
          });
        }, 100);
        return res;
      });
  }

  onCreateSearch(params) {
    return createFilterSearch(params).then((response) => {
      if (response.data.data) {
        this.setState((state) => ({
          searchNames: [...state.searchNames, response.data.data],
        }));
        return response;
      }

      // snackbar.show(response.data.data);
      this.props.openDialog("Successfully created");

      return response;
    });
  }

  onDeleteSearch(params) {
    return deleteFilterSearch(params).then((response) => {
      if (!response.data.error) {
        this.setState((state) => ({
          searchNames: state.searchNames.filter(
            (f) => f._id !== params.searchId
          ),
        }));
        // snackbar.show(response.data.data);
        this.props.openDialog(response.data.data);
      }

      return response;
    });
  }

  componentDidMount() {
    const dept = this.props.match.params.dept;
    this.props.requestCountries();
    this.props.requestIndustries();
    this.props.getJobTypes();
    this.props.getRoles();
    if (this.props.match.path == "/applied-jobs") {
      this.props.getAppliedADs("ALL", {
        page: this.state.pageNumber2 - 1,
        limit: 20,
      });
    }
    if (this.props.match.path == "/positions") {
      getRecentSearch().then((response) => {
        const record = response.data.data[0];

        if (record) {
          const {
            searchAgeMax,
            searchAgeMin,
            searchIndustry,
            searchGender,
            searchCountry,
            searchName,
            searchRole,
            searchState,
            searchCompensation,
            searchEligibility,
            searchStatus,
            searchPosted,
            searchType,
          } = record;
          const params = {
            ageMax: searchAgeMax,
            ageMin: searchAgeMin,
            industry: searchIndustry,
            gender: searchGender,
            country: searchCountry,
            role: searchRole,
            state: searchState,
            compensation: searchCompensation,
            eligibility: searchEligibility,
            status: searchStatus,
            posted: searchPosted,
            positionType: searchType,
            page: this.state.pageNumber1 - 1,
            limit: 20,
            key: this.state.searchString,
          };
          this.setState({
            ...this.state,
            searchParams: record,
          });
          const { page, limit, key } = params;
          return this.props
            .fetchFilteredPositions({ page: page, limit: limit, key: key })
            .then((res) => {
              const result = res.payload.data.map((f) => f);
              this.setState({
                getADsResponse: result,
                totalPages1: res.payload.totalPages,
              });
              return res;
            });
        }
      });
      const params = { userId: this.props.userDetail._id };

      searchNamesByUserId(params).then((response) => {
        if (response.data.data) {
          this.setState({
            ...this.state,
            searchNames: response.data.data,
          });
        }
      });
    }
  }

  handleSearchNameSelect(index) {
    const { searchNames } = this.state;
    const {
      searchAgeMax,
      searchAgeMin,
      searchIndustry,
      searchGender,
      searchCountry,
      searchName,
      searchRole,
      searchState,
      searchCompensation,
      searchEligibility,
      searchStatus,
      searchPosted,
      searchType,
    } = searchNames[index];
    const params = {
      ageMax: searchAgeMax,
      ageMin: searchAgeMin,
      industry: searchIndustry,
      gender: searchGender,
      country: searchCountry,
      mainrole: searchRole,
      state: searchState,
      compensation: searchCompensation,
      eligibility: searchEligibility,
      status: searchStatus,
      posted: searchPosted,
      positionType: searchType,
      page: this.state.pageNumber1 - 1,
      limit: 20,
      key: this.state.searchString,
    };
    this.setState({
      ...this.state,
      searchParams: searchNames[index],
      currentSearchNameIndex: index,
    });
    this.props.updateRecentSearchById(params);
    const { page, limit, key } = params;
    return this.props
      .fetchFilteredPositions({ page: page, limit: limit, key: key })
      .then((res) => {
        const result = res.payload.data.map((f) => f);
        this.setState({
          getADsResponse: result,
          totalPages1: res.payload.totalPages,
        });
        return res;
      });
  }

  handleSearch(searchString) {
    this.setState({ searchString });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.path !== this.props.match.path ||
      prevProps.userDetail !== this.props.userDetail
    ) {
      if (prevProps.match.path == "/positions") {
        getRecentSearch().then((response) => {
          const record = response.data.data[0];

          if (record) {
            const {
              searchAgeMax,
              searchAgeMin,
              searchIndustry,
              searchGender,
              searchCountry,
              searchName,
              searchRole,
              searchState,
              searchCompensation,
              searchEligibility,
              searchStatus,
              searchPosted,
              searchType,
            } = record;
            const params = {
              ageMax: searchAgeMax,
              ageMin: searchAgeMin,
              industry: searchIndustry,
              gender: searchGender,
              country: searchCountry,
              role: searchRole,
              state: searchState,
              compensation: searchCompensation,
              eligibility: searchEligibility,
              status: searchStatus,
              posted: searchPosted,
              positionType: searchType,
              page: this.state.pageNumber1 - 1,
              limit: 20,
              key: this.state.searchString,
            };
            this.setState({
              ...this.state,
              searchParams: record,
            });
            const { page, limit, key } = params;

            return this.props
              .fetchFilteredPositions({ page: page, limit: limit, key: key })
              .then((res) => {
                const result = res.payload.data.map((f) => f);
                this.setState({
                  getADsResponse: result,
                  totalPages1: res.payload.totalPages,
                });
                return res;
              });
          }
        });
        const params = { userId: this.props.userDetail._id };

        searchNamesByUserId(params).then((response) => {
          if (response.data.data) {
            this.setState({
              ...this.state,
              searchNames: response.data.data,
            });
          }
        });
      } else if (prevProps.match.path == "/applied-jobs") {
        this.props.getAppliedADs("ALL", {
          page: this.state.pageNumber2 - 1,
          limit: 20,
        });
      }
    }
  }

  getAppliedOnJobs() {
    let array = new Array();
    let fields = getArrayParam(this.props.appliedOnFields);
    fields = fields.filter((f) => {
      return !f.is_hidden;
    });
    let appliedAds = getArrayParam(this.props.appliedAds);

    let obj = new Object();
    appliedAds.forEach((ads, index) => {
      if (fields.length > 0) {
        if (
          isValidParam(ads) &&
          isValidParam(ads.positionId) &&
          isValidParam(ads.positionId.postedBy)
        ) {
          obj = {};
          obj._id = ads._id;
          obj.bnr = ads.positionId.postedBy.bnr;
          obj.profilepic = ads.positionId.postedBy.profilepic;
          obj.appliedAt = ads.createdAt;
          obj.role =ads.positionId.role == "Actor" ?`${ads.positionId.role} : ${ ads.positionId.otherrolename}`:ads.positionId.role;
          obj.postedAt = ads.positionId.createdAt;
          obj.country = ads.positionId.country;
          obj.industry = ads.positionId.industry;
          obj.posClosedDate = ads.positionId.posClosedDate;
          obj.positionId = ads.positionId._id;
          obj.positionName = "";
          obj.postedById = ads.positionId.postedBy._id;
          obj.positionStatus = ads.positionId.positionStatus;
          obj.status = ads.status;
          obj.title = ads.positionId.title;
          // obj.otherrolename =
          obj.positionType = ads.positionId.positionType
          array.push(obj);
        }
      }
    });
    return array;
  }

  isShowLoder() {
    let isShowLoder = false;
    let path = this.props.match.path;
    let fetchingADs = getBooleanParam(this.props.ADTable.fetchingADs);
    let fetchingAppliedAds = getBooleanParam(this.props.fetchingAppliedAds);
    if (
      (path == "/positions" && fetchingADs) ||
      (path == "/applied-jobs" && fetchingAppliedAds)
    ) {
      isShowLoder = true;
    }
    return isShowLoder;
  }

  handleTypeChange(type) {
    this.setState({ currentStatus: type });
    this.props.getAppliedADs(type, {
      page: this.state.pageNumber2 - 1,
      limit: 20,
    });
    this.setState({ anchorEl: null });
  }

  render() {
    let { classes } = this.props;
    let path = this.props.match.path;
    let isShowLoder = this.isShowLoder();
    const {
      getADsResponse,
      searchParams,
      searchNames,
      searchString,
      searchStringAppliedJobs,
    } = this.state;

    let searchResult = getADsResponse.filter((pos) =>
      pos.employerInfo.bnr.toLowerCase().includes(searchString.toLowerCase())
    );
    // let pageCount1 = Math.ceil(searchResult.length / 10);
    // searchResult = searchResult.slice(
    //   (this.state.pageNumber1 - 1) * 10,
    //   (this.state.pageNumber1 - 1) * 10 + 10
    // );
    let searchStringAppliedJobsResult = this.getAppliedOnJobs().filter(
      ({ bnr }) =>
        bnr.toLowerCase().includes(searchStringAppliedJobs.toLowerCase())
    );
    let pageCount2 = Math.ceil(searchStringAppliedJobsResult.length / 20);
    searchStringAppliedJobsResult = searchStringAppliedJobsResult.slice(
      (this.state.pageNumber2 - 1) * 20,
      (this.state.pageNumber2 - 1) * 20 + 20
    );

    if (isShowLoder) {
      return <Loader />;
    } else if (!isShowLoder) {
      return (
        <Paper className={classes.root}>
          <Grid item xs={12}>
            {path == "/positions" && (
              <>
                {!isEmpty(searchParams) && (
                  <Filter
                    onFilter={this.onFilter}
                    searchParams={searchParams}
                    countriesList={this.props.countriesList}
                    statesList={this.props.statesList}
                    jobtypes={this.props.jobtypes}
                    roles={this.props.roles}
                    requestStates={this.props.requestStates}
                    industriesList={this.props.industriesList}
                    searchNames={searchNames}
                    handleSearchNameSelect={this.handleSearchNameSelect}
                    onCreateSearch={this.onCreateSearch}
                    onDeleteSearch={this.onDeleteSearch}
                    handleSearch={this.handleSearch}
                  />
                )}
                <div className={classes.pagination}>
                  {searchResult.length > 0 && (
                    <PositionDataTablePagination
                      count={this.state.totalPages1}
                      page={this.state.pageNumber1}
                      handleChangePageNumber={(page) =>
                        this.handleChangePageNumber(page)
                      }
                    />
                  )}
                </div>
                <DataTable
                  key={path}
                  fields={this.props.fields}
                  values={searchResult}
                />
              </>
            )}
            {path == "/applied-jobs" && (
              <>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={(event) =>
                    this.setState({ anchorEl: event.currentTarget })
                  }
                >
                  <MenuIcon />
                </Button>

                <TextField
                  // fullWidth
                  label="Search"
                  onChange={(e) =>
                    this.setState({ searchStringAppliedJobs: e.target.value })
                  }
                  variant="outlined"
                  size="small"
                />
                <Popover
                  // id={id}
                  open={Boolean(this.state.anchorEl)}
                  anchorEl={this.state.anchorEl}
                  onClose={() => this.setState({ anchorEl: null })}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <div style={{ padding: "8px", width: "85px" }}>
                    <Typography
                      className={classes.typography}
                      onClick={(e) => this.handleTypeChange("ALL")}
                    >
                      <span
                        style={{
                          visibility:
                            this.state.currentStatus === "ALL"
                              ? "visible"
                              : "hidden",
                          marginRight: "4px",
                        }}
                      >
                        ✔
                      </span>
                      All
                    </Typography>
                    <Divider />
                    <Typography
                      className={classes.typography}
                      onClick={(e) => this.handleTypeChange("OPEN")}
                    >
                      <span
                        style={{
                          visibility:
                            this.state.currentStatus === "OPEN"
                              ? "visible"
                              : "hidden",
                          marginRight: "4px",
                        }}
                      >
                        ✔
                      </span>
                      Open
                    </Typography>
                    <Divider />
                    <Typography
                      className={classes.typography}
                      onClick={(e) => this.handleTypeChange("CLOSE")}
                    >
                      <span
                        style={{
                          visibility:
                            this.state.currentStatus === "CLOSE"
                              ? "visible"
                              : "hidden",
                          marginRight: "4px",
                        }}
                      >
                        ✔
                      </span>
                      Closed
                    </Typography>
                  </div>
                </Popover>
                <div className={classes.pagination}>
                  {searchStringAppliedJobsResult.length > 0 && (
                    <PositionDataTablePagination
                      count={pageCount2}
                      page={this.state.pageNumber2}
                      handleChangePageNumber={(page) =>
                        this.handleChangePageNumber(page)
                      }
                    />
                  )}
                </div>
                <DataTable
                  key={path}
                  fields={this.props.appliedOnFields}
                  values={searchStringAppliedJobsResult}
                />
              </>
            )}
          </Grid>
        </Paper>
      );
    } else {
      return <NoRecords label={"No Adds Found."} />;
    }
  }
}

function mapStateToProps(state) {
  return {
    countriesList: state.registerReducer.countries
      ? state.registerReducer.countries.map((f) => f.country)
      : [],
    industriesList: state.registerReducer.industries
      ? state.registerReducer.industries.map((f) => f.industry)
      : [],
    jobtypes: state.rolesReducer.jobtypes
      ? state.rolesReducer.jobtypes.map((jobtype) => jobtype.name)
      : [],
    roles: state.rolesReducer.roles
      ? state.rolesReducer.roles.map((role) => role.role)
      : [],
    ADTable: state.ADTableReducer,
    newADCreated: state.postAnADReducer.newADCreated,
    appliedAds: state.ADTableReducer.appliedAds,
    fields: fields,
    appliedOnFields: appliedOnFields,
    fetchingAppliedAds: state.ADTableReducer.fetchingAppliedAds,
    statesList: state.registerReducer.states || [],
    userDetail: state.userDetailsReducer.userDetail,
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      getADs,
      getAppliedADs,
      fetchFilteredPositions,
      updateRecentSearchById,
      requestCountries,
      requestStates,
      getJobTypes,
      getRoles,
      requestIndustries,
      updateSearchParams,
      openDialog,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(PostAdTableComponent));

let fields = [
  {
    name: "profilepic",
    label: "",
    width: 50,
    type: "image",
    is_hidden: false,
    link: null,
  },
  {
    name: "bnr",
    label: "Production House",
    width: 200,
    type: "text",
    is_hidden: false,
    link: {
      name: "_id",
      baseUrl: "/user_profile/",
      event: () => {},
      style: null,
    },
  },
  {
    name: "noOfPositions",
    label: "Number Of Roles",
    width: 100,
    type: "text",
    is_hidden: false,
    link: {
      name: "_id",
      baseUrl: "/all-positions/",
      event: () => {},
      style: null,
    },
  },
  {
    name: "country",
    label: "Country",
    width: 100,
    type: "text",
    is_hidden: false,
    link: null,
  },
  //{ name: '', label: 'Description', width: 150, type: 'text', is_hidden: false, link: null }
];

let appliedOnFields = [
  {
    name: "_id",
    label: "ID",
    width: 160,
    type: "text",
    is_hidden: true,
    link: null,
  },
  {
    name: "profilepic",
    label: "",
    width: 50,
    type: "image",
    is_hidden: false,
    link: null,
  },
  {
    name: "bnr",
    label: "Production House",
    width: 100,
    type: "text",
    is_hidden: false,
    link: {
      name: "postedById",
      baseUrl: "/user_profile/",
      onClick: (e) => {
        e.stopPropagation();
      },
      style: null,
    },
  },
  {
    name: "positionType",
    label: "Type",
    width: 100,
    type: "text",
    is_hidden: false,
    link: null,
  },
  {
    name: "title",
    label: "Title",
    width: 100,
    type: "text",
    is_hidden: false,
    link: null,
  },
  {
    name: "role",
    label: "Role",
    width: 100,
    type: "text",
    is_hidden: false,
    link: null,
  },
  {
    name: "positionId",
    label: "Position Id",
    width: 100,
    type: "text",
    is_hidden: true,
    link: null,
  },
  {
    name: "positionName",
    label: "Position Name",
    width: 100,
    type: "text",
    is_hidden: true,
    link: null,
  },
  {
    name: "postedById",
    label: "Posted By",
    width: 100,
    type: "text",
    is_hidden: true,
    link: null,
  },
  {
    name: "positionStatus",
    label: "Status",
    width: 100,
    type: "text",
    is_hidden: false,
    link: null,
  },
  // {
  //   name: "industry",
  //   label: "Industry",
  //   width: 100,
  //   type: "text",
  //   is_hidden: false,
  //   link: null,
  // },
  {
    name: "country",
    label: "Country",
    width: 100,
    type: "text",
    is_hidden: false,
    link: null,
  },
  {
    name: "postedAt",
    label: "Posted Date",
    width: 100,
    type: "date",
    is_hidden: false,
    link: null,
  },
  // {
  //   name: "status",
  //   label: "Status",
  //   width: 100,
  //   type: "text",
  //   is_hidden: false,
  //   link: null,
  // },
  {
    name: "appliedAt",
    label: "Applied Date",
    width: 100,
    type: "date",
    is_hidden: false,
    link: null,
  },

  {
    name: "posClosedDate",
    label: "Closed Date",
    width: 100,
    type: "date",
    is_hidden: false,
    link: null,
  },
  {
    name: "cancel",
    label: "Cancel",
    width: 80,
    type: "date",
    is_hidden: false,
    link: null,
  },
];

class ApplicantBreadcrumbs extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <Breadcrumbs aria-label="Breadcrumb">
        <Typography
          color="textPrimary"
          style={{ color: "#82707dd1", fontSize: 14 }}
        >
          Position
        </Typography>
        <Typography color="textPrimary" style={{ fontSize: 14 }}>
          sdsds
        </Typography>
        <Typography color="textPrimary" style={{ fontSize: 14 }}>
          Appiled
        </Typography>
      </Breadcrumbs>
    );
  }
}
