import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import FaceIcon from "@material-ui/icons/Face";
import Chip from "@material-ui/core/Chip";
import { useDispatch, useSelector } from "react-redux";
import { openAvatarModal } from "../../actions/appActions";
import {
  sharePrivatePic,
  getSharedUserList,
} from "../../actions/applicantsDetailsActions";
import getURL from "../../helpers/apis";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function CheckboxListSecondary(props) {
  const { galleryId, shareableUserList } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [checked, setChecked] = React.useState([]);
  const rootUrl = getURL("UPLOADS");

  const handleChangeShareToUserList = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const sharingPrivatePicLoading = useSelector(
    ({ applicantsReducer }) => applicantsReducer.sharingPrivatePicLoading
  );

  const handleShareGalleryToUser = () => {
    const body = {
      userId: checked,
    };
    dispatch(sharePrivatePic(galleryId, body, handleSuccessSharePrivatePic));
  };

  const handleSuccessSharePrivatePic = () => {
    setChecked([]);
    dispatch(getSharedUserList(galleryId));
  };

  return (
    <>
      <List
        dense
        className={classes.root}
        style={{
          height: "350px",
          overflow: "auto",
        }}
      >
        {shareableUserList &&
          shareableUserList.map((user) => {
            const labelId = `checkbox-list-secondary-label-${user._id}`;
            return (
              <ListItem key={user._id} button disabled={!user.isAllowing}>
                <ListItemAvatar>
                  <Avatar
                    onClick={(e) => dispatch(openAvatarModal(process.env.REACT_APP_BUCKET_NAME + user.profilepic))}
                    alt={user.username}
                    src={process.env.REACT_APP_BUCKET_NAME + user.profilepic}
                  />
                </ListItemAvatar>
                {user.isAllowing ? (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <ListItemText
                      id={labelId}
                      primary={user.user_follow_customname}
                    />
                    <ListItemText
                      id={labelId}
                      primary={
                        user.user_follow_customname.startsWith("@")
                          ? user.username
                          : user.username.replace("@", "")
                      }
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      color: "red",
                    }}
                  >
                    <ListItemText
                      id={labelId}
                      primary={user.user_follow_customname}
                    />
                    <ListItemText
                      id={labelId}
                      primary={
                        user.user_follow_customname.startsWith("@")
                          ? user.username
                          : user.username.replace("@", "")
                      }
                    />
                    <ListItemText
                      id={labelId}
                      primary={"User has restricted sharing."}
                    />
                  </div>
                )}

                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    disabled={!user.isAllowing}
                    onChange={handleChangeShareToUserList(user._id)}
                    checked={checked.indexOf(user._id) !== -1}
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
      </List>

      <div style={{ margin: "32px 0px" }}>
        {" "}
        {checked.length > 0 &&
          checked.map((user) => {
            const userDetails = shareableUserList.find(
              (usr) => usr._id == user
            );
            return (
              <Chip
                style={{ marginTop: "4px", marginLeft: "4px" }}
                icon={<FaceIcon />}
                label={userDetails.username}
                variant="outlined"
              />
            );
          })}
      </div>

      <DialogActions>
        <Button
          onClick={() => props.close()}
          color="primary"
          margin="normal"
          style={{ textTransform: "none" }}
        >
          Cancel
        </Button>
        {sharingPrivatePicLoading ? (
          <Button
            color="primary"
            variant="contained"
            margin="normal"
            style={{ textTransform: "none" }}
          >
            Sharing
          </Button>
        ) : (
          <Button
            onClick={() => handleShareGalleryToUser()}
            color="primary"
            variant="contained"
            margin="normal"
            style={{ textTransform: "none" }}
          >
            Share
          </Button>
        )}
      </DialogActions>
    </>
  );
}
