import React, { useState, useEffect } from "react";
// import { Button } from "../components/Buttons";
import {
  AddOnEmailInput,
  AddOnPasswordInput,
  AddOnPhoneInput,
} from "../components/AddOnInputs";
import { AuGroup } from "../components/AuFormComponent";
import { otpPhoneVerify, resendOTP } from "../actions/loginActions";
import withRouter from "react-router-dom/es/withRouter";
import Link from "react-router-dom/es/Link";
// // import snackbar from "snackbar";
import { otpPhone } from "../actions/loginActions";
import { connect } from "react-redux";
import { Button, Typography, TextField } from "@material-ui/core";
import AlertDialog from "../components/AlertDialog";

// const buttonDesignClass = {
//   className: "btn au-btn theme-resetPassword",
//   value: "Verify Phone No.",
// };

// const formInputsTheme = {
//   className: "theme-resetPassword",
// };

const OtpVerify = (props) => {
  const [otp, setOtp] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [seconds, setSeconds] = useState(5);
  const [tries, setTries] = useState(null);
  const [alert, setAlert] = useState(false);
  const [alertText, setAlertText] = useState("");

  useEffect(() => {
    const { userDetail } = props;

    let formData = {};

    if (userDetail && userDetail.username) {
      formData = {
        username: userDetail && userDetail.username,
      };

      props.dispatch(otpPhone(formData)).then((res) => {
        if (res.error) {
          setAlertText("Sorry! Failed to resend OTP");
          setAlert(true);
        } else if (
          res.errors.length > 0 &&
          res.errors[0].param === "OTP_LIMIT"
        ) {
          setTries(0);

          setAlertText("Sorry! Failed to resend OTP. Daily Limit Exceeded.");
          setAlert(true);
        } else {
          setTries(res.data.triesLeft);
        }
      });
    } else {
      const username = localStorage.getItem("username");

      formData = {
        username,
      };
      props.dispatch(otpPhone(formData)).then((res) => {
        if (res.error) {
          setAlertText("Sorry! Failed to resend OTP");
          setAlert(true);
        } else if (
          res.errors.length > 0 &&
          res.errors[0].param === "OTP_LIMIT"
        ) {
          setTries(0);

          setAlertText("Sorry! Failed to resend OTP. Daily Limit Exceeded.");
          setAlert(true);
        } else {
          setTries(res.data.triesLeft);
        }
      });
    }
  }, []);

  const onSubmitForm = (e) => {
    // e.preventDefault();
    const { userDetail } = props;
    let formData = {};

    if (userDetail && userDetail.username) {
      formData = {
        username: userDetail && userDetail.username,
        otp: otp,
      };
      props.dispatch(otpPhoneVerify(formData)).then((response) => {
        if (!response.error) {
          props.history.push("/profile-me");
        }
      });
    } else {
      const username = localStorage.getItem("username");
      formData = {
        username,
        otp: otp,
      };
      props.dispatch(otpPhoneVerify(formData)).then((response) => {
        if (!response.error) {
          props.history.push("/profile-me");
        }
      });
    }
  };

  const onResendOTP = () => {
    const { userDetail } = props;

    let formData = {};

    if (userDetail && userDetail.username) {
      formData = {
        username: userDetail && userDetail.username,
      };

      props.dispatch(resendOTP(formData)).then((res) => {
        if (res.error) {
          setAlertText("Sorry! Failed to resend OTP");
          setAlert(true);
        } else if (
          res.errors.length > 0 &&
          res.errors[0].param === "OTP_LIMIT"
        ) {
          setTries(0);

          setAlertText("Sorry! Failed to resend OTP. Daily Limit Exceeded.");
          setAlert(true);
        } else {
          setTries(res.data.triesLeft);
        }
      });
      setIsDisabled(true);
    } else {
      const username = localStorage.getItem("username");

      formData = {
        username,
      };
      props.dispatch(resendOTP(formData)).then((res) => {
        if (res.error) {
          setAlertText("Sorry! Failed to resend OTP");
          setAlert(true);
        } else if (
          res.errors.length > 0 &&
          res.errors[0].param === "OTP_LIMIT"
        ) {
          setTries(0);
          setAlertText("Sorry! Failed to resend OTP. Daily Limit Exceeded.");
          setAlert(true);
        } else {
          setTries(res.data.triesLeft);
        }
      });
      setIsDisabled(true);
    }
  };

  useEffect(() => {
    let interval = null;
    if (tries > 0) {
      if (seconds <= 0) {
        setIsDisabled(false);
        setSeconds(5);
        clearInterval(interval);
      } else if (isDisabled) {
        interval = setInterval(() => {
          setSeconds((seconds) => seconds - 1);
        }, 1000);
      }
      return () => clearInterval(interval);
    }
  }, [isDisabled, seconds, tries]);

  return (
    <div className="au-frame au-text au-reset-frame">
      <div className="text-center">
        <div className="au-banner theme-resetPassword">
          <div className="au-bfg">
            <div>
              <img
                src="/assets/images/icons/login-icon.png"
                width="20px"
                alt=""
              />
            </div>
            <div>
              <span>Phone Number Verification</span>
            </div>
          </div>
        </div>
        <span>
          <i className="fa fa-3x fa-caret-down fa-reset-clr au-bcd"></i>
        </span>
      </div>
      <div className="au-card au-form">
        {alert && (
          <AlertDialog
            open={alert}
            handleClose={() => {
              setAlert(false);
              // handleClose();
            }}
            text={alertText}
          />
        )}
        <form className="form-horizontal">
          <TextField
            required
            id="otp-input"
            label="OTP"
            fullWidth={true}
            type="number"
            margin="dense"
            className="inherit-bg-color cb-tf"
            onChange={(e) => setOtp(e.target.value)}
            value={otp}
          />
          <div className="au-re text-center">
            <Button
              //  buttonDesign={buttonDesignClass}
              variant="contained"
              color="primary"
              onClick={(e) => onSubmitForm(e)}
              style={{ textTransform: "none" }}
            >
              Verify Phone No.
            </Button>
          </div>
        </form>

        <Button
          disabled={isDisabled || tries === 0}
          onClick={(e) => onResendOTP(e)}
          style={{ textTransform: "none" }}
        >
          {isDisabled
            ? tries > 0
              ? `Wait ${seconds} seconds`
              : ""
            : "Resend OTP"}
          {/* {isDisabled ? `Wait ${seconds} seconds` : "Resend OTP"} */}
        </Button>
        <Typography
          display="inline"
          style={{ float: "right", margin: 6 }}
          color="secondary"
        >
          {tries !== null
            ? tries > 0
              ? tries + " tries left"
              : "Daily limit reached"
            : null}
        </Typography>
      </div>
    </div>
  );
};

const mapStateToProps = ({ registerReducer, loginReducer }) => {
  return {
    userDetail: registerReducer.userDetail || loginReducer.userDetail,
  };
};

export default connect(mapStateToProps)(withRouter(OtpVerify));
