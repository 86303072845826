import React from "react";
import BidForm from "./BidForm";
import { connect } from "react-redux";
import BidFeed from "./BidFeed";
import { Typography, Button, Paper } from "@material-ui/core";
import withRouter from "react-router-dom/es/withRouter";
// import Moment from "react-moment";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  paper: {
    width: "100%",
    margin: 50,
  },
  profileTitle: {
    textAlign: "center",
  },
});

const Bid = ({ classes, userDetail, history, match, adspace, cycle }) => {
  if (!adspace || !cycle) {
    history.push("/buyAdspace");
  }
  return (
    <Paper className={classes.paper} elevation={0}>
      <Typography component="div" variant="h4" className={classes.profileTitle}>
        Bid
      </Typography>
      <Button
        size="small"
        variant="contained"
        style={{
          float: "right",
          textTransform: "none",
        }}
        onClick={(e) => history.push("/bid-calendar")}
        color="secondary"
      >
        Back
      </Button>
      <div>
        <div style={{ display: "inline-block", width: "fit-content" }}>
          <Typography variant="h6">Adspace </Typography>
          <Typography variant="h6">Cycle </Typography>
        </div>
        <div style={{ display: "inline-block", width: "fit-content" }}>
          <Typography color="primary" variant="h6">
            :{" "}
            {adspace === "authPages" ? " Login/Register Pages" : " Side Panels"}
          </Typography>
          <Typography color="primary" variant="h6">
            : {cycle}
          </Typography>
        </div>

        <BidForm
          id={match.params.id}
          classes={classes}
          userDetail={userDetail}
          adspace={adspace}
          cycle={cycle}
        />
        <BidFeed
          id={match.params.id}
          classes={classes}
          adspace={adspace}
          cycle={cycle}
        />
      </div>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetail: state.userDetailsReducer.userDetail,
    adspace: state.adSpaceReducer.adspace,
    cycle: state.adSpaceReducer.cycle,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(withRouter(Bid)));
