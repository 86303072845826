import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import connect from "react-redux/es/connect/connect";
import withRouter from "react-router-dom/es/withRouter";
import bindActionCreators from "redux/es/bindActionCreators";
import Badge from "@material-ui/core/Badge";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import CardMedia from "@material-ui/core/CardMedia";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Grid from "@material-ui/core/Grid";
// import ShareIcon from "@material-ui/icons/Share";
import CircularProgress from "@material-ui/core/CircularProgress";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FormGroup from "@material-ui/core/FormControl";
import FormControl from "@material-ui/core/FormControl";
import { FormLabel } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import SettingsIcon from "@material-ui/icons/Settings";
import DeleteIcon from "@material-ui/icons/Delete";
import BlockedUserIcon from "../../assets/svg/Blocked Users Icon.svg";
import LikesIcon from "../../assets/svg/Likes icon (OUTLINE).svg";
import FavouritesIcon from "../../assets/svg/Favourites icon (OUTLINE).svg";
import ORIcon from "../../assets/svg/O R ICON.svg";
import HRIcon from "../../assets/svg/H R ICON.svg";
import HFIcon from "../../assets/svg/HFICON.svg";
import OFIcon from "../../assets/svg/OFICON.svg";
import OpenLikeIcon from "../../assets/svg/Likes icon (OPEN) copy 3.svg";
import HiddenLikeIcon from "../../assets/svg/Likes icon (HIDDEN) copy 3.svg";
import OpenFavoriteIcon from "../../assets/svg/Favourites icon (OPEN).svg";
import HiddenFavoriteIcon from "../../assets/svg/Favourites icon (HIDDEN).svg";
import ShareIcon from "../../assets/svg/SHARE ICON (blue).svg";
import ReportIcon from "../../assets/svg/Report Icon (black).svg";
import HiddenFriendsIcon from "../../assets/svg/Hidden Friends icon.svg";
import ProfileLikeIcon from "../../assets/svg/Profile Like icon.svg";
import ShareToMeIcon from "../../assets/svg/Sharedtome icon.svg";
import MyCommentsIcon from "../../assets/svg/My Comments Icon 2.svg";
import DescriptionSharpIcon from "@material-ui/icons/DescriptionSharp";
import { Icon } from "@material-ui/core";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Pagination from "@material-ui/lab/Pagination";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import CustomDialog from "../CustomDialog";
import UserList from "./UserList";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import ArrowBackSharpIcon from "@material-ui/icons/ArrowBackSharp";
import LockSharpIcon from "@material-ui/icons/LockSharp";
import ReplySharpIcon from "@material-ui/icons/ReplySharp";
import DoneSharpIcon from "@material-ui/icons/DoneSharp";
import getURL from "../../helpers/apis";
import {
  getGalleryDetailsById,
  clearComments,
  clearReplies,
  likeGallery,
  unlikeGallery,
  favGallery,
  unfavGallery,
  deleteGallery,
} from "../../actions/applicantsDetailsActions";
import {
  getUserSettings,
  changeSetting,
} from "../../actions/userDetailsAction";
import { openAvatarModal, openDialog } from "../../actions/appActions";
import { useHistory } from "react-router-dom";
import {
  getCommentsByGalleryId,
  getRepliesByCommentId,
  getGallerySettings,
  getVideoAds,
  postComment,
  deleteComment,
  allowOpenComments,
  allowHiddenComments,
  changeWhoCanOpenComment,
  changeWhoCanHiddenComment,
  changeGalleryQuotation,
} from "../../actions/commentActions";
import { Loader } from "../UtilityComponents";
import moment from "moment";
import {
  incrementPostAdCounter,
  incrLastVideo,
  resetLastAdCounter,
} from "../../actions/adSpaceActions";

import { decryptText, encryptText } from "../../helpers/globalFunctions";
import { unfollow, follow } from "../../actions/applicantsDetailsActions";
import ConfirmationDialog from "../ConfirmationDialog";
import CustomNameDialog from "../CustomNameDialog";
import Settings from "./Settings";
import FavouriteName from "./FavouriteName";
import SharePopup from "./SharePopup";
import InfiniteScroll from "react-infinite-scroller";
// import InfiniteScroll from "react-infinite-scroll-component";
import ReportDialog from "./ReportDialog";
import PublicShare from "./PublicShare";
import GiftDialog from "./GiftDialog";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { useDispatch, useSelector } from "react-redux";

const BlueSwitch = withStyles({
  switchBase: {
    // color: purple[300],
    "&$checked": {
      color: "#0A3C9D",
    },
    "&$checked + $track": {
      backgroundColor: "#0A3C9D",
    },
  },
  checked: {},
  track: {},
})(Switch);

const reactStringReplace = require("react-string-replace");

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    overflow: "visible",
    display: "flex",
    width: "fit-content",
    height: "fit-content",
    maxWidth: "inherit",
    maxHeight: "inherit",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
    height: "auto",
    minWidth: "335px",
    minHeight: "auto",
  },
  content: {
    width: "100%",
    height: "100%",
  },
  navIcons: {
    display: "block",
    marginTop: "-20px",
    overflow: "hidden",
    pointerEvents: "auto",
    position: "fixed",
    textIndent: "-9999em",
    top: "50%",
    cursor: "pointer",
    color: "white",
  },
  video: {
    width: "fit-content",
    height: "fit-content",
    maxWidth: "600px",
    maxHeight: "inherit",
    minWidth: "335px",
    minHeight: "auto",
  },
  ad: {
    width: "fit-content",
    height: "fit-content",
    maxWidth: "600px",
    maxHeight: "inherit",
    minWidth: "335px",
    minHeight: "auto",
  },
  image: {
    height: "600px",
    width: "100%",
    display: "inline-block",
    maxWidth: "600px",
    maxHeight: "inherit",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  commentBody: {
    width: "100%",
    maxWidth: "100%",
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(2),
  },
  section1: {
    width: "-webkit-fill-available",
    margin: theme.spacing(1),
    marginBottom: 0,
  },
  // section2: {
  //   width: "-webkit-fill-available",
  //   margin: theme.spacing(1),
  //   marginTop: 0,
  //   marginBottom: 0,
  //   height: "40px",
  //   overflowY: "scroll",
  //   overflowX: "hidden",
  // },
  section2: {
    width: "-webkit-fill-available",
    margin: theme.spacing(1),
    marginTop: 0,
    height: "calc(100% - 190px)",
    overflow: "hidden",
    marginBottom: 0,
  },
  commentsPaper: {
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    // overflowY: "scroll",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    margin: 0,
    // margin: theme.spacing(1),
    // marginTop: 0,
    // marginBottom: 0,
  },
  section3: {
    width: "-webkit-fill-available",
    margin: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
  },
  filter: {
    flexDirection: "row",
    fontSize: "12px",
  },
  btns: {
    fontSize: "10px",
    display: "inline-block",
    minHeight: 0,
    minWidth: 0,
    textTransform: "none",
  },
  icons: {
    cursor: "pointer",
    height: "auto",
    // fontSize: 16,
    // marginLeft: 15,
    // marginRight: 15,
  },
  imgIcon: {
    verticalAlign: "bottom",
    // fontSize: 16,
    // marginLeft: 15,
    // marginRight: 15,
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "8px",
  },
  collapse: {
    display: "flex",
    flexGrow: 1,
    // transition: theme.transitions.create(["margin"], transition),
    margin: 0,
    "&$expanded": {
      margin: 0,
    },
  },
  expanded: {
    margin: 0,
    padding: 0,
  },
  before: {
    "&:before": { opacity: 0.69 },
  },
  overlay: {
    position: "absolute",
    bottom: 0,
    left: 0,
    // width: "100%",
    // height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "white",
    minHeight: "auto",
    maxHeight: "inherit",
  },
  wrapper: {
    position: "relative",
  },
}));

const Carousel = ({
  open,
  handleClose,
  galleries,
  currentIndex,
  handleChangeIndex,
  isPrivate = false,
  gallerySettings,
  userSettings,
  getGallerySettings,
  getVideoAds,
  postComment,
  deleteComment,
  getCommentsByGalleryId,
  getRepliesByCommentId,
  clearComments,
  clearReplies,
  commentsObj,
  repliesObj,
  userDetail,
  allowOpenComments,
  allowHiddenComments,
  changeWhoCanOpenComment,
  changeWhoCanHiddenComment,
  changeGalleryQuotation,
  handleGalleryRefresh,
  openAvatarModal,
  fetchingVideoAds,
  videoAds,
  getUserSettings,
  openDialog,
  videoAdCounter,
  lastAdIndex,
  gallery_id = null,
}) => {
  var history = useHistory();
  const classes = useStyles();
  const rootUrl = getURL("UPLOADS");
  const [galleryId, setGalleryId] = useState(null);
  const [gallery, setGallery] = useState(null);
  const [settings, setSettings] = useState(null);
  const [type, setType] = useState(null);
  const [gender, setGender] = useState("All");
  const [owner, setOwner] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [replyParent, setReplyParent] = useState({});
  const [openSettings, setOpenSettings] = useState(false);
  const [err, setErr] = useState(false);
  const [like, setLike] = useState(false);
  const [fav, setFav] = useState(false);
  const [openUserList, setOpenUserList] = useState(false);
  const [actionType, setActionType] = useState("Open");
  const [actionName, setActionName] = useState("Like");
  const [openFavourite, setOpenFavourite] = useState(false);
  const [key, setKey] = useState("");
  const [replyKey, setReplyKey] = useState({});
  const [page, setPage] = useState(1);
  const [replyPage, setReplyPage] = useState({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openCustom, setOpenCustom] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [commentsRefresh, setCommentsRefresh] = useState(false);
  const [followType, setFollowType] = useState(null);
  const [whoseCommentsByDefault, setWhoseCommentsByDefault] = useState(null);
  const [auto_Rotation_Of_Videos, setAuto_Rotation_Of_Videos] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(null);
  const [openPrivateShare, setOpenPrivateShare] = useState(false);
  const [openPublicShare, setOpenPublicShare] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [commentsList, setCommentsList] = useState(null);
  const [repliesList, setRepliesList] = useState({});
  const [openReport, setOpenReport] = useState(false);
  const [openGift, setOpenGift] = useState(false);
  const [commentMenu, setCommentMenu] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hover, setHover] = useState(null);
  const [expanded, setExpanded] = useState([]);
  const [currentComment, setCurrentComment] = useState(null);
  const [adPlaying, setAdPlaying] = useState(false);
  const [ad, setAd] = useState(null);
  const [fraction, setFraction] = useState(0.2);
  const [adSecond, setAdSecond] = useState(0);
  const [skipable, setSkipable] = useState(false);
  const [resumed, setResumed] = useState(null);
  const [hiddenReplying, setHiddenReplying] = useState(false);
  const dispatch = useDispatch();
  const scrollRef = useRef(null);
  const replyRef = useRef(null);
  const videoRef = useRef();
  const adRef = useRef();
  scrollRef.current = {};
  videoRef.current = null;
  adRef.current = {};

  const limit = 10;
  const replyLimit = 3;
  const loggedInUser = userDetail._id;

  useEffect(() => {
    if (!fetchingVideoAds && videoAds && videoAds.length > 0) {
      if (videoAdCounter % 3 == 0) {
        const tmpAd = videoAds[lastAdIndex];
        if (tmpAd.media.length == 0) {
          dispatch(incrLastVideo());
        } else {
          setAd(tmpAd);
        }
      } else if (lastAdIndex >= videoAds.length) {
        dispatch(resetLastAdCounter());
      }
      dispatch(incrementPostAdCounter());
    }
  }, [fetchingVideoAds]);

  useEffect(() => {
    userSettings &&
      setAuto_Rotation_Of_Videos(userSettings.auto_Rotation_Of_Videos);
    userSettings &&
      setWhoseCommentsByDefault(userSettings.whoseCommentsByDefault);
  }, [userSettings]);

  useEffect(() => {
    setGalleryId(galleries[currentIndex]);
  }, [currentIndex, galleries]);

  useEffect(() => {
    galleryId &&
      getGalleryDetailsById(galleryId).then((res) => {
        if (res.data === "Gallery is deleted") {
          setDeleted(galleryId);
        } else setGallery(res.data);
      });
  }, [galleryId, refresh]);

  useEffect(() => {
    if (gallery) {
      setOwner(loggedInUser === gallery.userId._id);
      getGallerySettings(gallery._id);
      getVideoAds();
    }
  }, [gallery, loggedInUser]);

  useEffect(() => {
    setSettings(gallerySettings);
    if (settings) {
      setGender(whoseCommentsByDefault || "All");
      settings.allowOpenComments &&
        settings.allowHiddenComments &&
        setType("Open");
      !settings.allowOpenComments &&
        settings.allowHiddenComments &&
        setType("Hidden");
      settings.allowOpenComments &&
        !settings.allowHiddenComments &&
        setType("Open");
      !settings.allowOpenComments &&
        !settings.allowHiddenComments &&
        setType("Open");
    }
  }, [gallerySettings, settings, whoseCommentsByDefault]);

  useEffect(() => {
    if (gender && type && settings && gallery) {
      setPage(1);
      // setReplyPage({});
      let params = {
        gallery_id: galleryId,
        commenttype: type,
        gender: "All",
        page: 0,
        limit: limit,
        key: key === "" ? "" : encryptText(key),
      };
      getCommentsByGalleryId(params);
    }
  }, [settings, gender, type, key, refresh, commentsRefresh]);

  useEffect(() => {
    if (gender && type && settings && page !== 1) {
      let params = {
        gallery_id: galleryId,
        commenttype: type,
        gender: "All",
        page: page - 1,
        limit: limit,
        key: key === "" ? "" : encryptText(key),
      };
      getCommentsByGalleryId(params);
      // if (expanded) {
      //   params.comment_id = expanded;
      //   getRepliesByCommentId(params);
      // }
    }
  }, [page]);

  useEffect(() => {
    if (commentsObj.data) {
      if (
        commentsList &&
        commentsObj.data &&
        !(commentsObj.data === commentsList) &&
        page !== 1
      ) {
        var arr = commentsObj.data.filter((obj) => !commentsList.includes(obj));
        setCommentsList([...commentsList, ...arr]);
        setTotalPages(commentsObj.totalPages);
      } else {
        setCommentsList(commentsObj.data);
        setTotalPages(commentsObj.totalPages);
      }
    }
  }, [commentsObj, commentsObj && commentsObj.data]);

  useEffect(() => {
    if (repliesObj && Object.keys(repliesObj).length > 0) {
      if (
        repliesList &&
        repliesList[currentComment] &&
        repliesObj[currentComment].data &&
        !(repliesObj[currentComment].data === repliesList[currentComment]) &&
        replyPage &&
        replyPage[currentComment] !== 1
      ) {
        var arr = repliesObj[currentComment].data.filter(
          (obj) => !repliesList[currentComment].includes(obj)
        );
        setRepliesList({
          ...repliesList,
          [currentComment]: [...repliesList[currentComment], ...arr],
        });
      } else {
        setRepliesList({
          ...repliesList,
          [currentComment]: repliesObj[currentComment].data,
        });
      }
    }
  }, [repliesObj]);

  useEffect(() => {
    getUserSettings();
    return () => {
      clearComments();
      clearReplies();
      setCommentsList(null);
      setRepliesList({});
    };
  }, []);

  const handleMenuClick = (e, id) => {
    setAnchorEl(e.currentTarget);
    setCommentMenu(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCommentMenu(null);
  };

  const handleReport = () => {
    setOpenReport(true);
  };

  const handleGift = () => {
    setOpenGift(true);
  };

  const handleShareGallery = () => {
    setOpenPublicShare(true);
  };

  const handleReply = (parent, type) => {
    handleMenuClose();
    if (type === "hiddenReply") setHiddenReplying(true);
    setReplyParent(parent);
    replyRef.current.focus();
  };

  const handleDeleteComment = (commentId) => {
    handleMenuClose();
    deleteComment(commentId).then((r) => {
      !r.error && setCommentsRefresh(!commentsRefresh);
    });
    // setPage(1);
    // setKey("");
    // setReplyKey({});
  };

  const handleSettingsClose = async () => {
    setOpenSettings(false);
    setType(null);
    setPage(1);
    setReplyPage({});
    setKey("");
    setReplyKey({});
    getGallerySettings(gallery._id);
  };

  const handleAction = (actionType, actionName) => {
    if (actionName === "Like") {
      if (gallery && gallery.isGalleryLiked) {
        let body = {
          gallery_id: gallery._id,
        };
        unlikeGallery(body).then((res) => {
          setLike(false);
          getGalleryDetailsById(galleryId).then((res) => {
            if (res.data === "Gallery is deleted") {
              setDeleted(galleryId);
            } else setGallery(res.data);
          });
        });
      } else {
        let body = {
          gallery_id: gallery._id,
          likeType: actionType,
          type: gallery.galleryType,
        };
        likeGallery(body).then((res) => {
          setLike(false);
          getGalleryDetailsById(galleryId).then((res) => {
            if (res.data === "Gallery is deleted") {
              setDeleted(galleryId);
            } else setGallery(res.data);
          });
        });
      }
    } else if (actionName === "Favourite") {
      if (gallery && gallery.isGalleryFavourited) {
        let body = {
          galleryId: gallery._id,
        };
        unfavGallery(body).then((res) => {
          setFav(false);
          getGalleryDetailsById(galleryId).then((res) => setGallery(res.data));
        });
      } else setOpenFavourite(actionType);
    }
  };

  const handleFavourite = (value, actionType) => {
    let body = {
      galleryId: gallery._id,
      favouriteType: actionType,
      favouriteName: value,
    };
    favGallery(body).then((res) => {
      setOpenFavourite(false);
      setFav(false);
      getGalleryDetailsById(galleryId).then((res) => setGallery(res.data));
    });
  };

  const handleNextClick = () => {
    if (currentIndex < galleries.length - 1) {
      setGallery(null);
      setPage(1);
      setReplyPage({});
      setKey("");
      setAd(null);
      setReplyKey({});
      setCommentsList(null);
      setRepliesList({});
      setResumed(null);
      setGalleryId(galleries[currentIndex + 1]);
      handleChangeIndex(currentIndex + 1);
    } else if (galleries.length > 0) {
      setGallery(null);
      setPage(1);
      setReplyPage({});
      setKey("");
      setAd(null);
      setReplyKey({});
      setCommentsList(null);
      setRepliesList({});
      setGalleryId(galleries[0]);
      handleChangeIndex(0);
    }
  };

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setGallery(null);
      setPage(1);
      setReplyPage({});
      setKey("");
      setReplyKey({});
      setCommentsList(null);
      setRepliesList({});
      setGalleryId(galleries[currentIndex - 1]);
      handleChangeIndex(currentIndex - 1);
    } else if (galleries.length > 0) {
      setGallery(null);
      setPage(1);
      setReplyPage({});
      setKey("");
      setReplyKey({});
      setCommentsList(null);
      setRepliesList({});
      setGalleryId(galleries[galleries.length - 1]);
      handleChangeIndex(galleries.length - 1);
    }
  };

  const handleOpenUserList = (actionType, actionName) => {
    setActionType(actionType);
    setActionName(actionName);
    setOpenUserList(true);
  };

  const handleDeleteGallery = () => {
    setTitle(`Are you sure you want to delete this gallery?`);
    setDescription(`This cannot be undone.`);
    setDeleting(true);
    setOpenConfirm(true);
  };

  const handleFollow = (type) => {
    setTitle(`${type ? type + " " : ""}Follow`);
    setFollowType(type);
    !type &&
      setDescription(
        `Select a follow type and optionally set a customname for ${
          gallery.userId && gallery.userId.username
        }`
      );
    type &&
      setDescription(
        `Optionally set ${type} follow customname for ${
          gallery.userId && gallery.userId.username
        }`
      );
    setOpenCustom(true);
  };

  const handleUnfollow = (type) => {
    setTitle("Are you sure?");
    setFollowType(type);
    setDescription(
      `Do you want to unfollow ${gallery.userId && gallery.userId.username}?`
    );
    setOpenConfirm(true);
  };

  const handleConfirm = () => {
    if (deleting) {
      setOpenConfirm(false);
      setDeleting(false);
      if (owner && owner) {
        deleteGallery(gallery._id).then((res) => {
          if (!res.error) {
            // // if (gallery.length <= 1) {
            // //   return handleClose();
            // // }
            // handleNextClick();
            handleGalleryRefresh();
            return handleClose();
          }
        });
      }
    } else {
      setOpenConfirm(false);
      let user_id = gallery.userId && gallery.userId._id;
      unfollow({ type: followType, user_id }).then((res) => {
        setFollowType(null);
        setRefresh(!refresh);
      });
    }
  };

  const handleSave = (value, type) => {
    let user_id = gallery.userId && gallery.userId._id;
    setOpenCustom(false);
    follow({ type, user_id, customname: value }).then((res) => {
      setFollowType(null);
      setRefresh(!refresh);
      typeof res.data === "string" && openDialog(res.data);
    });
  };

  const handleGetText = (text) => {
    text = decryptText(text);
    if (gallery && gallery.galleryType === "videos") {
      let expression = /&.*?[^&]&/g;
      var regex = new RegExp(expression, "g");
      var allMatches = text.match(regex);
      var finalText = text;
      if (allMatches && allMatches.length > 0) {
        for (var i = 0; i < allMatches.length; i++) {
          if (typeof finalText == "string") {
            finalText = (
              <Typography
                variant="body2"
                display="inline"
                style={{ overflowWrap: "anywhere" }}
              >
                {reactStringReplace(
                  finalText,
                  allMatches[i],
                  (match, index) => {
                    var time = match.replace(/&/g, "").split(":");
                    time.map((a) => Number(a));
                    if (time[0] && time[1]) {
                      return (
                        <a key={i} onClick={() => handleSeek(time)}>
                          {match.replace(/&/g, "")}
                        </a>
                      );
                    } else return allMatches[i];
                  }
                )}
              </Typography>
            );
          } else {
            finalText.props.children[finalText.props.children.length - 1] =
              // <Typography variant="body2" display="inline"
              // style={{ overflowWrap: "anywhere" }}
              // >
              reactStringReplace(
                finalText.props.children[finalText.props.children.length - 1],
                allMatches[i],
                (match, index) => {
                  var time = match.replace(/&/g, "").split(":");
                  time.map((a) => Number(a));
                  if (time[0] && time[1]) {
                    return (
                      <a key={i} onClick={() => handleSeek(time)}>
                        {match.replace(/&/g, "")}
                      </a>
                    );
                  } else return allMatches[i];
                }
              );
            // </Typography>
          }
        }
      }
      return finalText;
    } else return text;
  };

  const handleSeek = (time) => {
    var seconds = +time[0] * 60 + +time[1];
    videoRef.current.seekTo(parseFloat(seconds));
  };

  const videoItem = (src, thumb) => {
    return (
      <ReactPlayer
        ref={(rp) => (videoRef.current = rp)}
        light={auto_Rotation_Of_Videos || resumed ? false : thumb}
        url={src}
        loop={auto_Rotation_Of_Videos ? false : true}
        onEnded={() => {
          auto_Rotation_Of_Videos && galleries.length > 1 && handleNextClick();
        }}
        playing={auto_Rotation_Of_Videos || resumed ? true : false}
        onProgress={(Obj) =>
          Obj.played.toFixed(1) == fraction &&
          resumed === null &&
          setAdPlaying(true)
        }
        onDuration={() =>
          resumed && videoRef.current.seekTo(fraction, "fraction")
        }
        controls
        width="100%"
        height="600px"
        style={{
          minWidth: "335px",
          minHeight: "auto",
          maxHeight: "inherit",
        }}
      />
    );
  };

  const AdItem = (media) => {
    const src = media[0].name;
    return (
      <ReactPlayer
        ref={(rp) => (adRef.current = rp)}
        url={process.env.REACT_APP_BUCKET_NAME + src}
        onEnded={() => {
          setAdPlaying(false);
          setResumed(true);
          dispatch(incrLastVideo());
        }}
        playing={true}
        // controls
        onProgress={(Obj) => {
          Obj.playedSeconds.toFixed(0) < 5 &&
            setAdSecond(Obj.playedSeconds.toFixed(0));
          Obj.playedSeconds.toFixed(0) >= 5 && setSkipable(true);
        }}
        width="100%"
        height="600px"
        style={{
          minWidth: "335px",
          minHeight: "auto",
          maxHeight: "inherit",
        }}
      />
    );
  };

  const handleToggleSetting = (setting) => {
    changeSetting(setting);
    setAuto_Rotation_Of_Videos(!auto_Rotation_Of_Videos);
  };

  const renderSwitch = () => {
    return (
      <FormGroup row>
        <FormControlLabel
          control={
            <BlueSwitch
              size="small"
              onChange={() => handleToggleSetting("auto_Rotation_Of_Videos")}
              checked={auto_Rotation_Of_Videos && auto_Rotation_Of_Videos}
            />
          }
          label={<Typography variant="caption">Auto Rotation</Typography>}
          labelPlacement="start"
        />
      </FormGroup>
    );
  };

  const isCommentAllowed = () => {
    let friends =
      gallery.friendStatus &&
      gallery.friendStatus.areYouFollowing &&
      gallery.friendStatus.isHeFollowing
        ? gallery.friendStatus.isHeFollowingType === "Open" &&
          gallery.friendStatus.areYouFollowingType === "Open"
          ? "OpenFriends"
          : gallery.friendStatus.isHeFollowingType === "Hidden" &&
            gallery.friendStatus.areYouFollowingType === "Hidden"
          ? "HiddenFriends"
          : null
        : null;
    var isAllowed = false;
    if (settings.allowOpenComments || settings.allowHiddenComments) {
      isAllowed =
        owner ||
        (settings.allowOpenComments &&
          settings.whoCanOpenComment === "AnyOne") ||
        (settings.allowHiddenComments &&
          settings.whoCanHiddenComment === "AnyOne") ||
        ((settings.whoCanOpenComment === "HiddenOpenFriends" ||
          settings.whoCanHiddenComment === "HiddenOpenFriends") &&
          friends !== null) ||
        (settings.allowOpenComments &&
          settings.whoCanOpenComment === "OpenFriends" &&
          friends === "OpenFriends") ||
        (settings.allowHiddenComments &&
          settings.whoCanHiddenComment === "OpenFriends" &&
          friends === "OpenFriends") ||
        (settings.allowOpenComments &&
          settings.whoCanOpenComment === "HiddenFriends" &&
          friends === "HiddenFriends") ||
        (settings.allowHiddenComments &&
          settings.whoCanHiddenComment === "HiddenFriends" &&
          friends === "HiddenFriends");
    }
    return isAllowed;

    // switch (
    //   true &&
    //   (settings.allowOpenComments || settings.allowHiddenComments)
    // ) {
    //   case owner:
    //     isAllowed = true;
    //   case settings.allowOpenComments &&
    //     settings.whoCanOpenComment === "AnyOne":
    //     isAllowed = true;
    //   case settings.allowHiddenComments &&
    //     settings.whoCanHiddenComment === "AnyOne":
    //     isAllowed = true;
    //   case settings.whoCanOpenComment === "HiddenOpenFriends" ||
    //     settings.whoCanHiddenComment === "HiddenOpenFriends":
    //     isAllowed = friends !== null;
    //   case settings.allowOpenComments &&
    //     settings.whoCanOpenComment === "OpenFriends":
    //     isAllowed = friends === "OpenFriends";
    //   case settings.allowHiddenComments &&
    //     settings.whoCanHiddenComment === "OpenFriends":
    //     isAllowed = friends === "OpenFriends";
    //   case settings.allowOpenComments &&
    //     settings.whoCanOpenComment === "HiddenFriends":
    //     isAllowed = friends === "HiddenFriends";
    //   case settings.allowHiddenComments &&
    //     settings.whoCanHiddenComment === "HiddenFriends":
    //     isAllowed = friends === "HiddenFriends";
    //   default:
    //     isAllowed = false;
    // }
  };

  const openCommentAllowed = () => {
    let friends =
      gallery.friendStatus &&
      gallery.friendStatus.areYouFollowing &&
      gallery.friendStatus.isHeFollowing
        ? gallery.friendStatus.isHeFollowingType === "Open" &&
          gallery.friendStatus.areYouFollowingType === "Open"
          ? "OpenFriends"
          : gallery.friendStatus.isHeFollowingType === "Hidden" &&
            gallery.friendStatus.areYouFollowingType === "Hidden"
          ? "HiddenFriends"
          : null
        : null;
    var isAllowed = false;
    if (
      settings.allowOpenComments &&
      !((gallery && gallery.type.toLowerCase() === "private") || isPrivate)
    ) {
      isAllowed =
        owner ||
        settings.whoCanOpenComment === "AnyOne" ||
        (settings.whoCanOpenComment === "HiddenOpenFriends" &&
          friends !== null) ||
        (settings.whoCanOpenComment === "OpenFriends" &&
          friends === "OpenFriends") ||
        (settings.whoCanOpenComment === "HiddenFriends" &&
          friends === "HiddenFriends");
    }
    return isAllowed;

    // switch (true && settings.allowOpenComments && !(gallery && gallery.type.toLowerCase() === "private" ||isPrivate)) {
    //   case owner:
    //     return true;
    //   case settings.whoCanOpenComment === "AnyOne":
    //     return true;
    //   case settings.whoCanOpenComment === "HiddenOpenFriends":
    //     return !friends;
    //   case settings.whoCanOpenComment === "OpenFriends":
    //     return friends === "OpenFriends";
    //   case settings.whoCanOpenComment === "HiddenFriends":
    //     return friends === "HiddenFriends";
    //   default:
    //     return false;
    // }
  };

  const hiddenCommentAllowed = () => {
    let friends =
      gallery.friendStatus &&
      gallery.friendStatus.areYouFollowing &&
      gallery.friendStatus.isHeFollowing
        ? gallery.friendStatus.isHeFollowingType === "Open" &&
          gallery.friendStatus.areYouFollowingType === "Open"
          ? "OpenFriends"
          : gallery.friendStatus.isHeFollowingType === "Hidden" &&
            gallery.friendStatus.areYouFollowingType === "Hidden"
          ? "HiddenFriends"
          : null
        : null;
    var isAllowed = false;
    if (settings.allowHiddenComments) {
      isAllowed =
        owner ||
        settings.whoCanHiddenComment === "AnyOne" ||
        (settings.whoCanHiddenComment === "HiddenOpenFriends" &&
          friends !== null) ||
        (settings.whoCanHiddenComment === "OpenFriends" &&
          friends === "OpenFriends") ||
        (settings.whoCanHiddenComment === "HiddenFriends" &&
          friends === "HiddenFriends");
    }
    return isAllowed;

    // switch (true && settings.allowHiddenComments) {
    //   case owner:
    //     return true;
    //   case settings.whoCanHiddenComment === "AnyOne":
    //     return true;
    //   case settings.whoCanHiddenComment === "HiddenOpenFriends":
    //     return !friends;
    //   case settings.whoCanHiddenComment === "OpenFriends":
    //     return friends === "OpenFriends";
    //   case settings.whoCanHiddenComment === "HiddenFriends":
    //     return friends === "HiddenFriends";
    //   default:
    //     return false;
    // }
  };

  const handlePost = () => {
    if (isCommentAllowed()) {
      if (newComment === "" || newComment.length > 280) {
        setErr(true);
        return;
      }
      setErr(false);
      let body = {
        commentType:
          owner && hiddenReplying
            ? "Hidden"
            : openCommentAllowed() && hiddenCommentAllowed()
            ? type
            : hiddenCommentAllowed()
            ? "Hidden"
            : openCommentAllowed()
            ? "Open"
            : null,
        gallery_id: gallery._id,
        type: gallery.galleryType,
        comment: encryptText(newComment),
        hiddenReplying: owner && hiddenReplying,
      };
      if (replyParent._id) body.parent_id = replyParent._id;
      postComment(body).then((res) => {
        if (!res.error) {
          res.data.parent_id && setCurrentComment(res.data.parent_id);
          res.data.parent_id &&
            setRepliesList({
              ...repliesList,
              [res.data.parent_id]: [],
            });
          res.data.parent_id &&
            setReplyPage({
              ...replyPage,
              [res.data.parent_id]: 1,
            });
          setNewComment("");
          setReplyParent({});
          if (hiddenReplying) {
            !hiddenCommentAllowed() && getGallerySettings(gallery._id);
            setType("Hidden");
          }
          setHiddenReplying(false);
          setKey("");
          // setReplyKey({});
          // setPage(1);
          setCommentsRefresh(!commentsRefresh);
          //  !res.data.parent_id &&  setCommentsRefresh(!commentsRefresh);
        }
      });
    } else {
      openDialog("Not Allowed");
    }
  };

  const renderOtherUserControls = (isLiked, isFavourited) => {
    const followBtn = () => {
      {
        switch (true) {
          case gallery.friendStatus &&
            gallery.friendStatus.areYouFollowing &&
            gallery.friendStatus.isHeFollowing &&
            gallery.friendStatus.areYouFollowingType ===
              gallery.friendStatus.isHeFollowingType:
            return gallery.friendStatus.areYouFollowingType === "Open" ? (
              <IconButton
                fontSize="small"
                color="primary"
                onClick={(e) => handleUnfollow("Open")}
              >
                {/* <Badge
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={"O"}
                  style={{ transform: "none" }}
                >
                  <DoneSharpIcon className={classes.icons} />
                </Badge> */}
                <Icon className={classes.icons}>
                  <img className={classes.imgIcon} src={OFIcon} />
                </Icon>
              </IconButton>
            ) : (
              <IconButton
                fontSize="small"
                color="primary"
                onClick={(e) => handleUnfollow("Hidden")}
              >
                {/* <Badge
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={"H"}
                  style={{ transform: "none" }}
                >
                  <DoneSharpIcon className={classes.icons} />
                </Badge> */}
                <Icon className={classes.icons}>
                  <img className={classes.imgIcon} src={HFIcon} />
                </Icon>
              </IconButton>
            );
          case gallery.friendStatus &&
            gallery.friendStatus.areYouFollowing &&
            gallery.friendStatus.isHeFollowing &&
            gallery.friendStatus.areYouFollowingType !==
              gallery.friendStatus.isHeFollowingType:
            return gallery.friendStatus.areYouFollowingType === "Open" ? (
              <IconButton
                fontSize="small"
                color="primary"
                onClick={(e) => handleUnfollow("Open")}
              >
                {/* <Badge
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={"F"}
                >
                  <RadioButtonUncheckedIcon className={classes.icons} />
                </Badge> */}
                <Icon className={classes.icons}>
                  <img className={classes.imgIcon} src={ORIcon} />
                </Icon>
              </IconButton>
            ) : (
              <IconButton
                fontSize="small"
                color="primary"
                onClick={(e) => handleUnfollow("Hidden")}
              >
                {/* <Badge
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={"F"}
                >
                  <Icon className={classes.icons}>H</Icon>
                </Badge> */}
                <Icon className={classes.icons}>
                  <img className={classes.imgIcon} src={HRIcon} />
                </Icon>
              </IconButton>
            );
          case gallery.friendStatus &&
            !gallery.friendStatus.areYouFollowing &&
            gallery.friendStatus.isHeFollowing:
            return gallery.friendStatus.isHeFollowingType === "Open" ? (
              <>
                <IconButton
                  fontSize="small"
                  color="primary"
                  onClick={(e) => handleFollow()}
                >
                  <AddSharpIcon className={classes.icons} color="inherit" />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  fontSize="small"
                  color="primary"
                  onClick={(e) => handleFollow()}
                >
                  <AddSharpIcon className={classes.icons} color="inherit" />
                </IconButton>
              </>
            );
          case gallery.friendStatus &&
            gallery.friendStatus.areYouFollowing &&
            !gallery.friendStatus.isHeFollowing:
            return gallery.friendStatus.areYouFollowingType === "Open" ? (
              <IconButton
                fontSize="small"
                color="primary"
                onClick={(e) => handleUnfollow("Open")}
              >
                {/* <Badge
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={"F"}
                >
                  <RadioButtonUncheckedIcon className={classes.icons} />
                </Badge> */}
                <Icon className={classes.icons}>
                  <img className={classes.imgIcon} src={ORIcon} />
                </Icon>
              </IconButton>
            ) : (
              <IconButton
                fontSize="small"
                color="primary"
                onClick={(e) => handleUnfollow("Hidden")}
              >
                {/* <Badge
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={"F"}
                >
                  <Icon className={classes.icons}>H</Icon>
                </Badge> */}
                <Icon className={classes.icons}>
                  <img className={classes.imgIcon} src={HRIcon} />
                </Icon>
              </IconButton>
            );
          case !gallery.friendStatus:
            return (
              <IconButton
                fontSize="small"
                color="primary"
                onClick={(e) => handleFollow()}
              >
                <AddSharpIcon className={classes.icons} color="inherit" />
              </IconButton>
            );
          default:
            return null;
        }
      }
    };

    const likeBtn = () =>
      isLiked ? (
        gallery.isGalleryLikedType === "Open" ? (
          <IconButton
            aria-label="open-like"
            color="secondary"
            onClick={() => handleAction("Open", "Like")}
          >
            {/* <Badge
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              badgeContent={"O"}
            >
              <FavoriteIcon className={classes.icons} />
            </Badge> */}
            <Icon className={classes.icons}>
              <img className={classes.imgIcon} src={OpenLikeIcon} />
            </Icon>
          </IconButton>
        ) : (
          <IconButton
            aria-label="hidden-like"
            color="secondary"
            onClick={() => handleAction("Hidden", "Like")}
          >
            {/* <Badge
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              badgeContent={"H"}
            >
              <FavoriteIcon className={classes.icons} />
            </Badge> */}
            <Icon className={classes.icons}>
              <img className={classes.imgIcon} src={HiddenLikeIcon} />
            </Icon>
          </IconButton>
        )
      ) : (
        <IconButton
          size="small"
          disableFocusRipple
          disableRipple
          onMouseLeave={() => setLike(false)}
        >
          {like && (
            <img
              style={{
                width: "25px",
              }}
              className="floatingBtn-left"
              src={OpenLikeIcon}
              alt="hidden-like"
              onClick={() => handleAction("Open", "Like")}
            />
          )}

          <IconButton
            aria-label="like"
            onMouseEnter={() => setLike(true)}
            disabled={like}
          >
            <FavoriteBorderIcon
              className={classes.icons}
              style={{
                fontSize: like ? "12px" : "24px",
              }}
            />
          </IconButton>

          {like && (
            <img
              style={{
                width: "25px",
              }}
              className="floatingBtn-right"
              src={HiddenLikeIcon}
              alt=""
              onClick={() => handleAction("Hidden", "Like")}
            />
          )}
        </IconButton>
      );

    const favBtn = () =>
      isFavourited ? (
        gallery.isGalleryFavouritedType === "Open" ? (
          <IconButton
            aria-label="open-fav"
            color="secondary"
            onClick={() => handleAction("Open", "Favourite")}
          >
            {/* <Badge
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              badgeContent={"O"}
            >
              <BookmarkIcon className={classes.icons} />
            </Badge> */}
            <Icon className={classes.icons}>
              <img className={classes.imgIcon} src={OpenFavoriteIcon} />
            </Icon>
          </IconButton>
        ) : (
          <IconButton
            aria-label="hidden-fav"
            color="secondary"
            onClick={() => handleAction("Hidden", "Favourite")}
          >
            {/* <Badge
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              badgeContent={"H"}
            >
              <BookmarkIcon className={classes.icons} />
            </Badge> */}
            <Icon className={classes.icons}>
              <img className={classes.imgIcon} src={HiddenFavoriteIcon} />
            </Icon>
          </IconButton>
        )
      ) : (
        <IconButton
          size="small"
          disableFocusRipple
          disableRipple
          onMouseLeave={() => setFav(false)}
        >
          {fav && (
            <img
              style={{
                width: "25px",
                position: "relative",
                top: -15,
                left: -10,
              }}
              src={OpenFavoriteIcon}
              alt="open-fav"
              onClick={() => handleAction("Open", "Favourite")}
            />
          )}

          <IconButton
            aria-label="fav"
            onMouseEnter={() => setFav(true)}
            disabled={fav}
          >
            <BookmarkBorderIcon
              className={classes.icons}
              style={{
                fontSize: fav ? "12px" : "24px",
              }}
            />
          </IconButton>
          {fav && (
            <img
              style={{
                width: "25px",
                position: "relative",
                top: -15,
                right: -10,
              }}
              src={HiddenFavoriteIcon}
              alt="hidden-fav"
              onClick={() => handleAction("Hidden", "Favourite")}
            />
          )}
        </IconButton>
      );

    const shareBtn = () => (
      <IconButton
        aria-label="share"
        color="inherit"
        onClick={() => handleShareGallery()}
      >
        <Icon className={classes.icons}>
          <img className={classes.imgIcon} src={ShareIcon} />
        </Icon>
      </IconButton>
    );

    const reportBtn = () => (
      <IconButton
        aria-label="report"
        color="inherit"
        onClick={() => handleReport()}
      >
        {/* <DescriptionSharpIcon className={classes.icons} /> */}
        <Icon className={classes.icons}>
          <img className={classes.imgIcon} src={ReportIcon} />
        </Icon>
      </IconButton>
    );

    // const giftBtn = () => (
    //   <IconButton
    //     aria-label="gift"
    //     color="primary"
    //     onClick={() => handleGift()}
    //   >
    //     <CardGiftcardIcon className={classes.icons} />
    //   </IconButton>
    // );

    return (
      <>
        {followBtn()}
        {!(
          (gallery && gallery.type.toLowerCase() === "private") ||
          isPrivate
        ) && (
          <>
            {likeBtn()}
            <Typography
              style={{
                marginLeft: 4,
                marginRight: 4,
                paddingLeft: 8,
                paddingRight: 8,
                cursor: "pointer",
                textDecoration: "underline",
              }}
              variant="subtitle2"
              color="primary"
              onClick={() => handleOpenUserList("Open", "Like")}
            >
              {gallery.nooflikes}
            </Typography>
            {favBtn()}
            <Typography
              style={{
                marginLeft: 4,
                marginRight: 4,
                paddingLeft: 8,
                paddingRight: 8,
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => handleOpenUserList("Open", "Favourite")}
              variant="subtitle2"
              color="primary"
            >
              {gallery.nooffavourite}
            </Typography>
          </>
        )}
        {shareBtn()}
        <Typography variant="subtitle2" color="textPrimary">
          {gallery.noofshare}
        </Typography>
        {reportBtn()}
        {/* {giftBtn()} */}
      </>
    );
  };

  const fetchMoreData = () => {
    setPage(page + 1);
  };

  const renderOwnerControls = () => (
    <>
      {!(
        (gallery && gallery.type.toLowerCase() === "private") ||
        isPrivate
      ) && (
        <>
          <IconButton
            aria-label="like"
            color="primary"
            onClick={() => handleOpenUserList("Open", "Like")}
          >
            <Icon className={classes.icons}>
              <img className={classes.imgIcon} src={LikesIcon} />
            </Icon>
            {/* <FavoriteBorderIcon className={classes.icons} /> */}
          </IconButton>
          <Typography variant="subtitle2" color="textPrimary">
            {gallery.nooflikes}
          </Typography>
          <IconButton
            aria-label="fav"
            color="primary"
            onClick={() => handleOpenUserList("Open", "Favourite")}
          >
            <Icon className={classes.icons}>
              <img className={classes.imgIcon} src={FavouritesIcon} />
            </Icon>
            {/* <BookmarkBorderIcon className={classes.icons} /> */}
          </IconButton>
          <Typography variant="subtitle2" color="textPrimary">
            {gallery.nooffavourite}
          </Typography>
          <IconButton
            aria-label="share"
            color="primary"
            onClick={() => handleShareGallery()}
          >
            <Icon className={classes.icons}>
              <img className={classes.imgIcon} src={ShareIcon} />
            </Icon>
          </IconButton>
          <Typography variant="subtitle2" color="textPrimary">
            {gallery.noofshare}
          </Typography>
        </>
      )}
      <IconButton onClick={(e) => setOpenSettings(true)} aria-label="Settings">
        <SettingsIcon className={classes.icons} color="primary" />
      </IconButton>
      <IconButton aria-label="delete" onClick={() => handleDeleteGallery()}>
        <DeleteForeverIcon color="primary" className={classes.icons} />
      </IconButton>
      {((gallery && gallery.type.toLowerCase() === "private") || isPrivate) && (
        <IconButton
          aria-label="delete"
          onClick={(e) => setOpenPrivateShare(true)}
        >
          <Badge
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            badgeContent={<ReplySharpIcon fontSize="medium" color="primary" />}
          >
            <LockSharpIcon
              color="primary"
              fontSize="small"
              className={classes.icons}
            />
          </Badge>
        </IconButton>
      )}
    </>
  );

  // const handleChangePageNumber = (event, value) => {
  //   setPage(value);
  // };

  const handleChangeType = (type) => {
    setGender("All");
    setType(type);
    setExpanded([]);
    setCommentsList(null);
    setRepliesList({});
    setCurrentComment(null);
    setPage(1);
    setReplyPage({});
    setKey("");
    setReplyKey({});
  };

  const handleChangeGender = (gender) => {
    setGender("All");
    setExpanded([]);
    setCommentsList(null);
    setRepliesList({});
    setCurrentComment(null);
    setPage(1);
    setReplyPage({});
    setKey("");
    setReplyKey({});
  };

  const renderFilterBtns = (allowed) => {
    const typeLabel = (type) => (
      <Button
        style={{ textTransform: "none" }}
        onClick={(e) => handleChangeType(type)}
      >
        {type}
      </Button>
    );
    const typeBtns = () => (
      <ButtonGroup
        size="small"
        color="primary"
        aria-label="type"
        name="type"
        style={{
          margin: "6px",
          height: "24px",
        }}
      >
        <Button
          style={{
            padding: "4px",
            textTransform: "none",
            whiteSpace: "nowrap",
          }}
          onClick={(e) => handleChangeType("Open")}
          variant={type === "Open" ? "contained" : "outlined"}
        >
          Open
          {type === "Hidden"
            ? ` - ${commentsObj.opencount || 0}`
            : type === "Open" && ` - ${commentsObj.allcount || 0}`}
        </Button>
        <Button
          style={{
            padding: "4px",
            textTransform: "none",
            whiteSpace: "nowrap",
          }}
          onClick={(e) => handleChangeType("Hidden")}
          variant={type === "Hidden" ? "contained" : "outlined"}
        >
          Hidden
          {type === "Open"
            ? ` - ${commentsObj.hiddencount || 0}`
            : type === "Hidden" && ` - ${commentsObj.allcount || 0}`}
        </Button>
      </ButtonGroup>
    );

    const genderBtns = () => handleChangeGender("All");

    // <ButtonGroup
    //   size="small"
    //   color="primary"
    //   aria-label="gender"
    //   name="gender"
    //   style={{
    //     margin: "6px",
    //     height: "24px",
    //   }}
    // >
    //   <Button
    //     style={{ padding: "4px", textTransform: "none" }}
    //     onClick={(e) => handleChangeGender("All")}
    //     variant={gender === "All" ? "contained" : "outlined"}
    //   >
    //     All {` - ${commentsObj.allcount || 0}`}
    //   </Button>
    //   <Button
    //     style={{ padding: "4px", textTransform: "none" }}
    //     onClick={(e) => handleChangeGender("Male")}
    //     variant={gender === "Male" ? "contained" : "outlined"}
    //   >
    //     M{` - ${commentsObj.malecount || 0}`}
    //   </Button>
    //   <Button
    //     style={{ padding: "4px", textTransform: "none" }}
    //     onClick={(e) => handleChangeGender("Female")}
    //     variant={gender === "Female" ? "contained" : "outlined"}
    //   >
    //     F{` - ${commentsObj.femalecount || 0}`}
    //   </Button>
    // </ButtonGroup>
    if (owner) {
      switch (true) {
        case (gallery && gallery.type.toLowerCase() === "private") || isPrivate:
          return (
            <FormGroup row={true} size="small" className={classes.filter}>
              {typeLabel("Hidden")}
              {/* {genderBtns()} */}
            </FormGroup>
          );
        case allowed === "Both":
          return (
            <FormGroup row={true} size="small" className={classes.filter}>
              {typeBtns()}
              {/* {genderBtns()} */}
            </FormGroup>
          );
        case allowed === "Open":
          return (
            <FormGroup row={true} size="small" className={classes.filter}>
              {typeLabel("Open")}
              {/* {typeBtns()} */}
              {/* {genderBtns()} */}
            </FormGroup>
          );
        case allowed === "Hidden":
          return (
            <FormGroup row={true} size="small" className={classes.filter}>
              {typeLabel("Hidden")}
              {/* {typeBtns()} */}
              {/* {genderBtns()} */}
            </FormGroup>
          );
      }
    } else {
      // let openAllowed = settings && openCommentAllowed();
      // let hiddenAllowed = settings && hiddenCommentAllowed();
      switch (true) {
        case (gallery && gallery.type.toLowerCase() === "private") || isPrivate:
          return (
            <FormGroup row={true} size="small" className={classes.filter}>
              {typeLabel("Hidden")}
              {/* {genderBtns()} */}
            </FormGroup>
          );
        // case openAllowed || hiddenAllowed:
        //   return (
        //     <FormGroup row={true} size="small" className={classes.filter}>
        //       {typeBtns()}
        //       {type === "Open" && genderBtns()}
        //     </FormGroup>
        //   );
        case allowed === "Both":
          // case allowed === "Both" && (openAllowed || hiddenAllowed):
          return (
            <FormGroup row={true} size="small" className={classes.filter}>
              {typeBtns()}
              {/* {type === "Open" && genderBtns()} */}
              {type === "Open"}
            </FormGroup>
          );
        case allowed === "Open":
          // case (allowed === "Both" && openAllowed) ||
          //   (allowed === "Open" && openAllowed):
          return (
            <FormGroup row={true} size="small" className={classes.filter}>
              {typeLabel("Open")}
              {/* {genderBtns()} */}
            </FormGroup>
          );
        case allowed === "Hidden":
          // case (allowed === "Both" && hiddenAllowed) ||
          //   (allowed === "Hidden" && hiddenAllowed):
          return (
            <FormGroup row={true} size="small" className={classes.filter}>
              {/* {typeBtns()} */}
              {typeLabel("Hidden")}
            </FormGroup>
          );
      }
    }
  };

  useEffect(() => {
    // if (expanded.length < 1) setReplyPage({});
    if (currentComment && gender && type && settings) {
      let params = {
        gallery_id: galleryId,
        commenttype: type,
        gender: "All",
        page: (replyPage[currentComment] || 1) - 1,
        limit: replyLimit,
        key: replyKey[currentComment]
          ? replyKey[currentComment] === ""
            ? ""
            : encryptText(replyKey[currentComment])
          : "",
        comment_id: currentComment,
      };
      getRepliesByCommentId(params);
    }
  }, [currentComment, replyPage, replyKey]);

  const renderReplies = (commentId, length) => (
    <Accordion
      square
      elevation={0}
      style={{
        marginLeft: 32,
        marginTop: 0,
      }}
      expanded={expanded.includes(commentId)}
      TransitionProps={{ collapsedHeight: 0 }}
      classes={{ root: classes.before }}
      onChange={(e, thisExpanded) => {
        thisExpanded
          ? setExpanded([commentId, ...expanded])
          : setExpanded((expanded) => {
              var arr = expanded.filter((i) => i != commentId);
              return arr;
            });
        thisExpanded
          ? setCurrentComment(commentId)
          : setRepliesList((repliesList) => {
              delete repliesList[commentId];
              return repliesList;
            });
        thisExpanded
          ? setReplyPage({ ...replyPage, [commentId]: 1 })
          : setReplyPage((replyPage) => {
              delete replyPage[commentId];
              return replyPage;
            });
      }}
    >
      <AccordionSummary
        expandIcon={<></>}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{
          minHeight: 0,
          padding: 0,
          margin: 0,
        }}
        IconButtonProps={{ style: { margin: 0, padding: 0 } }}
        classes={{
          content: classes.collapse,
          expanded: classes.expanded,
        }}
      >
        {!expanded.includes(commentId) ? (
          <Typography
            variant="caption"
            align="right"
            style={{ marginLeft: 12, textAlign: "right" }}
          >
            —— View {length > 1 ? "replies" : "reply"} {"("}
            {length}
            {") "}
          </Typography>
        ) : replyPage[commentId] < length / replyLimit ? (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Typography
              variant="caption"
              align="right"
              style={{ marginLeft: 6, textAlign: "right" }}
              onClick={(e) => {
                e.stopPropagation();
                setCurrentComment(commentId);
                setReplyPage({
                  ...replyPage,
                  [commentId]: replyPage[commentId] + 1,
                });
              }}
            >
              —— View replies ({length - replyLimit * replyPage[commentId]})
            </Typography>
            <TextField
              // fullWidth
              placeholder="Search replies"
              value={replyKey[commentId]}
              onFocus={() => setReplyPage({ ...replyPage, [commentId]: 1 })}
              onChange={(e) => {
                // e.target.value.length > 0 &&
                // setReplyPage({ ...replyPage, [commentId]: 1 });
                setReplyKey({ ...replyKey, [commentId]: e.target.value });
              }}
              variant="outlined"
              size="small"
              style={{ marginLeft: 12, width: 150 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{ marginRight: 0 }}>
                    <SearchIcon fontSize="small" style={{ fontSize: 18 }} />
                  </InputAdornment>
                ),
                style: { paddingLeft: 3 },
              }}
              inputProps={{
                style: { padding: 4 },
              }}
            />
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="caption"
              align="right"
              style={{ marginLeft: 6, textAlign: "right" }}
            >
              —— Hide {length > 1 ? "replies" : "reply"}
            </Typography>
            <TextField
              // fullWidth
              onClick={(e) => {
                e.stopPropagation();
              }}
              onFocus={() => setReplyPage({ ...replyPage, [commentId]: 1 })}
              placeholder="Search replies"
              value={replyKey[commentId]}
              onChange={(e) =>
                setReplyKey({ ...replyKey, [commentId]: e.target.value })
              }
              variant="outlined"
              size="small"
              style={{ marginLeft: 12, width: 150 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{ marginRight: 0 }}>
                    <SearchIcon fontSize="small" style={{ fontSize: 18 }} />
                  </InputAdornment>
                ),
                style: { paddingLeft: 4 },
              }}
              inputProps={{
                style: { padding: 4 },
              }}
            />
          </div>
        )}
      </AccordionSummary>
      <AccordionDetails
        style={{
          display: "block",
          padding: 0,
          margin: 0,
          marginBottom: 12,
        }}
      >
        {repliesList &&
          Object.keys(repliesList).length > 0 &&
          repliesList[commentId] &&
          repliesList[commentId].length > 0 &&
          repliesList[commentId].map((reply) => {
            return (
              <Grid>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={reply._id === commentMenu}
                  onClose={handleMenuClose}
                >
                  {owner &&
                    loggedInUser !== reply.user_id._id &&
                    reply.commentType === "Open" && (
                      <MenuItem
                        onClick={() => handleReply(reply, "hiddenReply")}
                      >
                        Reply in Hidden
                      </MenuItem>
                    )}
                  {(owner || loggedInUser === reply.user_id._id) && (
                    <MenuItem onClick={() => handleDeleteComment(reply._id)}>
                      Delete
                    </MenuItem>
                  )}
                </Menu>
                <Grid
                  container
                  wrap="nowrap"
                  spacing={1}
                  style={
                    {
                      // marginLeft: "32px",
                      // marginLeft: `${margin}px`,
                    }
                  }
                  onMouseEnter={() => setHover(reply._id)}
                  onMouseLeave={() => setHover(null)}
                >
                  <Grid item>
                    {reply.user_id.profilepic ? (
                      <Avatar
                        style={{
                          width: 32,
                          height: 32,
                        }}
                        alt="owner"
                        src={
                          process.env.REACT_APP_BUCKET_NAME +
                          reply.user_id.profilepic
                        }
                        onClick={(e) =>
                          openAvatarModal(
                            process.env.REACT_APP_BUCKET_NAME +
                              reply.user_id.profilepic
                          )
                        }
                      />
                    ) : (
                      <Avatar
                        style={{
                          width: 32,
                          height: 32,
                        }}
                        onClick={(e) => openAvatarModal()}
                        alt="owner"
                      >
                        <PersonIcon />
                      </Avatar>
                    )}
                  </Grid>
                  <Grid
                    justifyContent="left"
                    item
                    className={classes.commentBody}
                  >
                    <Typography
                      variant="subtitle2"
                      style={{
                        marginRight: "6px",
                        display: "inline-block",
                      }}
                    >
                      <Link to={`/user_profile/${reply.user_id._id}`}>
                        {reply.user}
                      </Link>
                    </Typography>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      style={{
                        display: "inline-block",
                        float: "right",
                        marginRight:
                          owner || loggedInUser === reply.user_id._id
                            ? "0px"
                            : "48px",
                      }}
                    >
                      {moment(reply.createdAt).format("DD-MM-YYYY")}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ overflowWrap: "anywhere" }}
                    >
                      {handleGetText(reply.comment)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    justifyContent="right"
                    style={{ alignSelf: "center", minWidth: 24 }}
                    onClick={(e) =>
                      hover === reply._id &&
                      (owner || loggedInUser === reply.user_id._id) &&
                      handleMenuClick(e, reply._id)
                    }
                  >
                    {hover === reply._id &&
                      (owner || loggedInUser === reply.user_id._id) && (
                        // ((type === "Open" && openCommentAllowed()) ||
                        // (type === "Hidden" && hiddenCommentAllowed())) &&
                        <IconButton
                          variant="text"
                          color="inherit"
                          disableFocusRipple
                          disableRipple
                          style={{
                            padding: 0,
                            // marginRight: "72px",
                          }}
                        >
                          <MoreVertIcon fontSize="small" />
                        </IconButton>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
      </AccordionDetails>
    </Accordion>
  );

  return (
    <>
      <CustomDialog open={open} onClose={handleClose}>
        {(gallery || galleryId === deleted) && galleries.length > 1 && (
          <IconButton
            aria-label="Previous"
            onClick={() => handlePrevClick()}
            className={classes.navIcons}
            style={{
              // display: currentIndex <= 0 ? "none" : "block",
              left: "calc(50% - 600px)",
            }}
          >
            <NavigateBeforeIcon fontSize="large" />
          </IconButton>
        )}
        {galleryId === deleted ? (
          <div>
            <Typography variant="h3">This Gallery has been deleted.</Typography>
          </div>
        ) : gallery ? (
          <>
            <Card elevation={0} className={classes.root}>
              {openSettings && (
                <Settings
                  open={openSettings}
                  handleClose={() => handleSettingsClose()}
                  settings={settings}
                  allowOpenComments={() => allowOpenComments(gallery._id)}
                  allowHiddenComments={() => allowHiddenComments(gallery._id)}
                  changeWhoCanOpenComment={(who) =>
                    changeWhoCanOpenComment(gallery._id, who)
                  }
                  changeWhoCanHiddenComment={(who) =>
                    changeWhoCanHiddenComment(gallery._id, who)
                  }
                  changeGalleryQuotation={(quotation) =>
                    changeGalleryQuotation(gallery._id, quotation)
                  }
                  isPrivate={
                    (gallery && gallery.type.toLowerCase() === "private") ||
                    isPrivate
                  }
                  userSettings={userSettings}
                />
              )}
              {openFavourite && (
                <FavouriteName
                  open={Boolean(openFavourite)}
                  handleClose={() => setOpenFavourite(false)}
                  handleSave={(value, actionType) =>
                    handleFavourite(value, actionType)
                  }
                  actionType={openFavourite}
                />
              )}
              {openUserList && (
                <UserList
                  open={openUserList}
                  handleClose={() => setOpenUserList(false)}
                  actionType={actionType}
                  isOwner={owner}
                  actionName={actionName}
                  galleryId={gallery && gallery._id}
                />
              )}
              {openConfirm && (
                <ConfirmationDialog
                  open={openConfirm}
                  handleCancel={() => setOpenConfirm(false)}
                  handleConfirm={() => handleConfirm()}
                  title={title}
                  description={description}
                />
              )}
              {openCustom && (
                <CustomNameDialog
                  open={openCustom}
                  handleClose={() => setOpenCustom(false)}
                  handleSave={(value, type) => handleSave(value, type)}
                  title={title}
                  description={description}
                  type={followType}
                />
              )}
              {openPrivateShare && (
                <SharePopup
                  open={openPrivateShare}
                  close={() => setOpenPrivateShare(false)}
                  currentIndex={gallery && gallery._id}
                />
              )}
              {openReport && (
                <ReportDialog
                  open={openReport}
                  handleClose={() => setOpenReport(false)}
                  galleryId={gallery && gallery._id}
                />
              )}
              {/* {openGift && (
                <GiftDialog
                  open={openGift}
                  handleClose={() => setOpenGift(false)}
                  gallery={gallery && gallery}
                />
              )} */}
              {openPublicShare && (
                <PublicShare
                  open={openPublicShare}
                  handleClose={() => setOpenPublicShare(false)}
                  url={
                    "https://celebbook.in/user_profile/" +
                    gallery.userId._id +
                    "/" +
                    gallery.type +
                    "/" +
                    gallery._id
                  }
                  gallery_id={gallery && gallery._id}
                />
              )}
              {gallery.galleryType === "videos" ? (
                adPlaying && ad && ad.media.length > 0 ? (
                  <CardMedia className={classes.ad}>
                    {AdItem(ad.media)}
                    <div className={classes.overlay}>
                      {skipable ? (
                        <Button
                          variant="text"
                          style={{ textTransform: "none" }}
                          onClick={() => {
                            setAdPlaying(false);
                            setResumed(true);
                            dispatch(incrLastVideo());
                          }}
                        >
                          Skip ad
                        </Button>
                      ) : (
                        <Button
                          variant="text"
                          disabled
                          style={{ textTransform: "none" }}
                        >
                          Skip ad in: 00:0{5 - adSecond}
                        </Button>
                      )}
                    </div>
                  </CardMedia>
                ) : (
                  <CardMedia className={classes.video}>
                    {videoItem(
                      process.env.REACT_APP_BUCKET_NAME + gallery.name,
                      process.env.REACT_APP_BUCKET_NAME + gallery.thumbnail
                    )}
                  </CardMedia>
                )
              ) : (
                <CardMedia
                  className={classes.image}
                  component="img"
                  alt="No Image found."
                  image={process.env.REACT_APP_BUCKET_NAME + gallery.name}
                />
              )}
              <div className={classes.details}>
                <CardContent className={classes.content} style={{ padding: 0 }}>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    style={{ height: "100%", width: "100%", display: "flex" }}
                  >
                    <Grid item xs={12} className={classes.section1}>
                      <Grid
                        container
                        wrap="nowrap"
                        justify="space-between"
                        style={{
                          alignItems: "center",
                          paddingInline: 20,
                          paddingBottom: 10,
                        }}
                      >
                        <Grid xs={6} container>
                          <Grid item>
                            {gallery.userId.profilepic ? (
                              <Avatar
                                alt="owner"
                                src={
                                  process.env.REACT_APP_BUCKET_NAME +
                                  gallery.userId.profilepic
                                }
                                onClick={(e) =>
                                  openAvatarModal(
                                    process.env.REACT_APP_BUCKET_NAME +
                                      gallery.userId.profilepic
                                  )
                                }
                              />
                            ) : (
                              <Avatar
                                alt="owner"
                                onClick={(e) => openAvatarModal()}
                              >
                                <PersonIcon />
                              </Avatar>
                            )}
                          </Grid>
                          <Grid
                            item
                            zeroMinWidth
                            style={{
                              marginLeft: 6,
                            }}
                          >
                            <Typography
                              variant="h5"
                              style={{ display: "inline-block" }}
                            >
                              <Link to={`/user_profile/${gallery.userId._id}`}>
                                {gallery.userId.username}
                              </Link>
                            </Typography>
                          </Grid>
                        </Grid>
                        {/* FILTER */}

                        {settings &&
                        settings.allowOpenComments &&
                        settings.allowHiddenComments ? (
                          <Grid item>{renderFilterBtns("Both")}</Grid>
                        ) : settings.allowOpenComments &&
                          !settings.allowHiddenComments ? (
                          <Grid item>{renderFilterBtns("Open")}</Grid>
                        ) : !settings.allowOpenComments &&
                          settings.allowHiddenComments ? (
                          <Grid item>{renderFilterBtns("Hidden")}</Grid>
                        ) : null}
                        {gallery.galleryType === "videos" && (
                          <Grid item>
                            <Grid
                              justifyContent="flex-end"
                              container
                              style={{ alignSelf: "center" }}
                            >
                              {renderSwitch()}
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                      <Divider variant="fullWidth" light />
                    </Grid>
                    {settings && type && gender && commentsObj ? (
                      <>
                        {/* {!isPrivate && ( */}
                        <Grid item xs={12} className={classes.section2}>
                          {/* <Paper
                            elevation={0}
                            className={classes.commentsPaper}
                          > */}
                          {/* GALLERY QUOTATION */}
                          <Grid
                            justify="space-between"
                            container
                            style={{
                              // width: "inherit",
                            }}
                          >
                            <Grid xs={9} item>
                              {settings.galleryQuotation &&
                                settings.galleryQuotation !== "" && (
                                  <Typography style={{overflowWrap:'break-word'}} variant="body1">
                                    {settings.galleryQuotation}
                                  </Typography>
                                )}
                            </Grid>
                            <Grid
                              xs={3}
                              item
                              style={{
                                textAlign: "right",
                                verticalAlign: "middle",
                              }}
                            >
                              {!(
                                (gallery &&
                                  gallery.type.toLowerCase() === "private") ||
                                isPrivate
                              ) && (
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                  // style={{ display: "flex" }}
                                >
                                  •{" "}
                                  {moment(gallery.createdAt).format(
                                    "DD-MM-YYYY"
                                  )}
                                  {/* Posted {moment(gallery.createdAt).fromNow()} */}
                                </Typography>
                              )}
                            </Grid>
                            <Divider variant="fullWidth" light />
                          </Grid>
                          {/* <div className={classes.pagination}>
                            <Pagination
                              count={totalPages}
                              page={page}
                              variant="outlined"
                              shape="rounded"
                              onChange={handleChangePageNumber}
                            />
                          </div> */}
                          {/* <Divider
                            variant="fullWidth"
                            light
                            // style={{ marginBottom: "6px" }}
                          /> */}
                          {/* <Divider
                            variant="fullWidth"
                            light
                            style={{ marginBottom: "6px" }}
                          /> */}
                          {(settings &&
                            !settings.allowOpenComments &&
                            !settings.allowHiddenComments) ||
                          (settings && !isCommentAllowed()) ? (
                            <div>
                              <i>Comments Disabled by owner</i>
                            </div>
                          ) : (
                            commentsObj.data && (
                              <>
                                <TextField
                                  fullWidth
                                  label="Search usernames and comments"
                                  value={key}
                                  onFocus={() =>
                                    setCurrentComment(null) &&
                                    // setRepliesList({}) &&
                                    setReplyPage({})
                                  }
                                  onChange={(e) =>
                                    // setCurrentComment(null) &&
                                    setKey(e.target.value)
                                  }
                                  variant="outlined"
                                  size="small"
                                  style={{ marginBottom: 2, marginTop: 6 }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <SearchIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <div
                                  id="scrollableDiv"
                                  style={{
                                    height: "calc(100% - 50px)",
                                    flexGrow: 1,
                                    overflow: "auto",
                                  }}
                                  ref={(ref) => (scrollRef.current = ref)}
                                >
                                  <InfiniteScroll
                                    // getScrollParent={() => scrollRef.current}
                                    useWindow={false}
                                    // dataLength={
                                    //   commentsList
                                    //     ? repliesList &&
                                    //       repliesList.length > 0
                                    //       ? commentsList.length +
                                    //         repliesList.filter(
                                    //           (obj) =>
                                    //             commentsList.filter(
                                    //               (com) =>
                                    //                 com._id === obj.comment._id
                                    //             ).length > 0
                                    //         )
                                    //       : commentsList.length
                                    //     : 0
                                    // }
                                    loadMore={fetchMoreData}
                                    // next={fetchMoreData}
                                    // scrollableTarget="scrollableDiv"
                                    pageStart={0}
                                    hasMore={page < totalPages}
                                    loader={
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          minHeight: "100px",
                                        }}
                                      >
                                        <CircularProgress />
                                      </div>
                                    }
                                    // height={284}
                                    threshold={5}
                                    style={{
                                      overflow: "hidden",
                                    }}
                                  >
                                    {commentsList &&
                                      commentsList.length > 0 &&
                                      commentsList.map((comment) => {
                                        return (
                                          <>
                                            <Grid>
                                              <Menu
                                                id="simple-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={
                                                  comment._id === commentMenu
                                                }
                                                onClose={handleMenuClose}
                                              >
                                                {loggedInUser !==
                                                  comment.user_id._id && (
                                                  <MenuItem
                                                    onClick={() =>
                                                      handleReply(comment)
                                                    }
                                                  >
                                                    Reply
                                                  </MenuItem>
                                                )}
                                                {owner &&
                                                  loggedInUser !==
                                                    comment.user_id._id &&
                                                  comment.commentType ===
                                                    "Open" && (
                                                    <MenuItem
                                                      onClick={() =>
                                                        handleReply(
                                                          comment,
                                                          "hiddenReply"
                                                        )
                                                      }
                                                    >
                                                      Reply in Hidden
                                                    </MenuItem>
                                                  )}
                                                {(owner ||
                                                  loggedInUser ===
                                                    comment.user_id._id) && (
                                                  <MenuItem
                                                    onClick={() =>
                                                      handleDeleteComment(
                                                        comment._id
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </MenuItem>
                                                )}
                                              </Menu>
                                              <Grid
                                                container
                                                wrap="nowrap"
                                                spacing={1}
                                                onMouseEnter={() =>
                                                  setHover(comment._id)
                                                }
                                                onMouseLeave={() =>
                                                  setHover(null)
                                                }
                                              >
                                                <Grid item>
                                                  {comment.user_id
                                                    .profilepic ? (
                                                    <Avatar
                                                      style={{
                                                        width: 32,
                                                        height: 32,
                                                      }}
                                                      alt="owner"
                                                      src={
                                                        process.env
                                                          .REACT_APP_BUCKET_NAME +
                                                        comment.user_id
                                                          .profilepic
                                                      }
                                                      onClick={(e) =>
                                                        openAvatarModal(
                                                          process.env
                                                            .REACT_APP_BUCKET_NAME +
                                                            comment.user_id
                                                              .profilepic
                                                        )
                                                      }
                                                    />
                                                  ) : (
                                                    <Avatar
                                                      style={{
                                                        width: 32,
                                                        height: 32,
                                                      }}
                                                      alt="owner"
                                                      onClick={(e) =>
                                                        openAvatarModal()
                                                      }
                                                    >
                                                      <PersonIcon />
                                                    </Avatar>
                                                  )}
                                                </Grid>
                                                <Grid
                                                  justifyContent="left"
                                                  item
                                                  className={
                                                    classes.commentBody
                                                  }
                                                >
                                                  <Typography
                                                    variant="subtitle2"
                                                    style={{
                                                      marginRight: "6px",
                                                      display: "inline-block",
                                                    }}
                                                  >
                                                    <Link
                                                      to={`/user_profile/${comment.user_id._id}`}
                                                    >
                                                      {comment.user}
                                                    </Link>
                                                  </Typography>
                                                  <Typography
                                                    variant="caption"
                                                    color="textSecondary"
                                                    style={{
                                                      display: "inline-block",
                                                      float: "right",
                                                    }}
                                                  >
                                                    {moment(
                                                      comment.createdAt
                                                    ).format("DD-MM-YYYY")}
                                                  </Typography>
                                                  <Typography
                                                    variant="body2"
                                                    style={{
                                                      overflowWrap: "anywhere",
                                                    }}
                                                  >
                                                    {handleGetText(
                                                      comment.comment
                                                    )}
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  justifyContent="right"
                                                  style={{
                                                    alignSelf: "center",
                                                    minWidth: 24,
                                                  }}
                                                  onClick={(e) =>
                                                    hover === comment._id &&
                                                    handleMenuClick(
                                                      e,
                                                      comment._id
                                                    )
                                                  }
                                                >
                                                  {hover === comment._id && (
                                                    // ((type === "Open" &&
                                                    //   openCommentAllowed()) ||
                                                    //   (type === "Hidden" &&
                                                    //     hiddenCommentAllowed())) &&
                                                    <IconButton
                                                      variant="text"
                                                      color="inherit"
                                                      disableFocusRipple
                                                      disableRipple
                                                      style={{
                                                        padding: 0,
                                                      }}
                                                    >
                                                      <MoreVertIcon fontSize="small" />
                                                    </IconButton>
                                                  )}
                                                </Grid>
                                              </Grid>
                                              {comment.noOfReplies > 0 &&
                                                renderReplies(
                                                  comment._id,
                                                  comment.noOfReplies
                                                )}
                                            </Grid>
                                          </>
                                        );
                                      })}
                                  </InfiniteScroll>
                                </div>
                              </>
                            )
                          )}
                          {/* </Paper> */}
                        </Grid>
                        {/* )} */}

                        <Grid
                          item
                          xs={12}
                          flexGrow={1}
                          className={classes.section3}
                        >
                          {/* <Divider variant="fullWidth" light /> */}
                          <div className={classes.controls}>
                            {!owner
                              ? renderOtherUserControls(
                                  gallery.isGalleryLiked,
                                  gallery.isGalleryFavourited
                                )
                              : renderOwnerControls()}
                          </div>

                          {(settings &&
                            !settings.allowOpenComments &&
                            !settings.allowHiddenComments) ||
                          (settings && !isCommentAllowed())
                            ? null
                            : ((type === "Open" && openCommentAllowed()) ||
                                (type === "Hidden" &&
                                  hiddenCommentAllowed())) && (
                                <>
                                  {/* <Divider variant="fullWidth" light /> */}
                                  <Grid
                                    component="form"
                                    style={{ display: "flex" }}
                                  >
                                    <TextField
                                      error={err}
                                      size="small"
                                      fullWidth
                                      autoFocus
                                      inputRef={replyRef}
                                      onChange={(e) => {
                                        setNewComment(e.target.value);
                                        if (e.target.value.length > 280)
                                          setErr(true);
                                        else setErr(false);
                                      }}
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                          handlePost();
                                        }
                                      }}
                                      helperText={
                                        err
                                          ? newComment === ""
                                            ? "Can not be empty"
                                            : "Comments can only be between 1 to 280 charachters."
                                          : ""
                                      }
                                      multiline
                                      rows={1}
                                      id="standard-basic"
                                      variant={
                                        err || replyParent._id !== ""
                                          ? "outlined"
                                          : "standard"
                                      }
                                      placeholder={
                                        replyParent._id
                                          ? hiddenReplying
                                            ? `Replying in Hidden to ${replyParent.user_id.username}`
                                            : `Replying to ${replyParent.user_id.username}`
                                          : "Add Comment"
                                      }
                                      inputProps={{ style: { padding: 0 } }}
                                      style={{ flex: 1 }}
                                      defaultValue={newComment}
                                      value={newComment}
                                    />
                                    <Button
                                      color="primary"
                                      variant="outlined"
                                      onClick={handlePost}
                                      style={{ textTransform: "none" }}
                                    >
                                      Post
                                    </Button>
                                    {replyParent._id && (
                                      <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => {
                                          setReplyParent({});
                                          setErr(false);
                                        }}
                                        style={{ textTransform: "none" }}
                                      >
                                        Cancel
                                      </Button>
                                    )}
                                  </Grid>
                                  {replyParent && replyParent._id && (
                                    <Paper
                                      elevation={6}
                                      style={{
                                        backgroundColor:
                                          "rgba(255, 255, 255, 0.75)",
                                        padding: 6,
                                      }}
                                      square
                                    >
                                      <Grid container wrap="nowrap" spacing={1}>
                                        <Grid item>
                                          {replyParent.user_id.profilepic ? (
                                            <Avatar
                                              style={{
                                                width: 32,
                                                height: 32,
                                              }}
                                              alt="owner"
                                              src={
                                                process.env
                                                  .REACT_APP_BUCKET_NAME +
                                                replyParent.user_id.profilepic
                                              }
                                              onClick={(e) =>
                                                openAvatarModal(
                                                  process.env
                                                    .REACT_APP_BUCKET_NAME +
                                                    replyParent.user_id
                                                      .profilepic
                                                )
                                              }
                                            />
                                          ) : (
                                            <Avatar
                                              style={{
                                                width: 32,
                                                height: 32,
                                              }}
                                              alt="owner"
                                              onClick={(e) => openAvatarModal()}
                                            >
                                              <PersonIcon />
                                            </Avatar>
                                          )}
                                        </Grid>
                                        <Grid
                                          justifyContent="left"
                                          item
                                          className={classes.commentBody}
                                        >
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              marginRight: "12px",
                                              display: "inline-block",
                                            }}
                                          >
                                            <Link
                                              to={`/user_profile/${replyParent.user_id._id}`}
                                            >
                                              {replyParent.user_id.username}
                                            </Link>
                                          </Typography>
                                          <Typography
                                            variant="caption"
                                            color="textSecondary"
                                            style={{
                                              display: "inline-block",
                                              float: "right",
                                            }}
                                          >
                                            {moment(
                                              replyParent.createdAt
                                            ).format("DD-MM-YYYY")}
                                          </Typography>
                                          <Typography
                                            variant="body2"
                                            style={{ overflowWrap: "anywhere" }}
                                          >
                                            {handleGetText(replyParent.comment)}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Paper>
                                  )}
                                </>
                              )}
                        </Grid>
                      </>
                    ) : (
                      <div>
                        <Loader />
                      </div>
                    )}
                  </Grid>
                </CardContent>
              </div>
            </Card>
          </>
        ) : (
          <div>
            <Loader />
          </div>
        )}
        {(gallery || galleryId === deleted) && galleries.length > 1 && (
          <IconButton
            aria-label="Next"
            onClick={() => handleNextClick()}
            className={classes.navIcons}
            style={{
              // display: currentIndex >= galleries.length - 1 ? "none" : "block",
              right: "calc(50% - 600px)",
            }}
          >
            <NavigateNextIcon fontSize="large" />
          </IconButton>
        )}
      </CustomDialog>
    </>
  );
};

function mapStateToProps(state) {
  return {
    gallerySettings: state.commentReducer.gallerySettings,
    commentsObj: state.commentReducer.commentsObj,
    repliesObj: state.commentReducer.repliesObj,
    userDetail: state.userDetailsReducer.userDetail,
    userSettings: state.userDetailsReducer.userSettings,
    videoAds: state.adSpaceReducer.videoAds,
    fetchingVideoAds: state.adSpaceReducer.fetchingVideoAds,
    videoAdCounter: state.adSpaceReducer.videoAdCounter,
    lastAdIndex: state.adSpaceReducer.lastAdIndex,
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      getGallerySettings,
      getVideoAds,
      postComment,
      getCommentsByGalleryId,
      getRepliesByCommentId,
      clearComments,
      clearReplies,
      deleteComment,
      allowOpenComments,
      allowHiddenComments,
      changeWhoCanOpenComment,
      changeWhoCanHiddenComment,
      changeGalleryQuotation,
      openAvatarModal,
      getUserSettings,
      openDialog,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(Carousel));
