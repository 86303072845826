import React, { useState, useEffect, useRef } from "react";
import {
  Paper,
  Grid,
  Chip,
  IconButton,
  Typography,
  Divider,
  Popover,
  List,
  ListItem,
  ListItemText,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import SendSharpIcon from "@material-ui/icons/SendSharp";
import MessageFromComponent from "./MessageFromComponent";
import MoreVertSharpIcon from "@material-ui/icons/MoreVertSharp";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ClearSharpIcon from "@material-ui/icons/ClearSharp";
import MessageToComponent from "./MessageToComponent";
import TextField from "@material-ui/core/TextField";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    textAlign: "center",
    height: "inherit",
    // overflowY: "scroll",
  },
  thread: {
    width: "100%",
    padding: 12,
    height: "calc(100% - 96px)",
    overflowY: "scroll",
  },
  date: {
    marginTop: 6,
    marginBottom: 6,
  },
  chat: {
    marginTop: 4,
    marginBottom: 4,
  },
}));

const NewChatComponent = ({ currentSelectedUser, id, handleSendText }) => {
  const classes = useStyles();
  const messagesEndRef = useRef(null);
  const [newText, setNewText] = useState("");
  const [currentUser, setCurrentUser] = useState(
    currentSelectedUser && currentSelectedUser
  );
  // const [chats, setChats] = useState([]);

  useEffect(() => {
    setCurrentUser(currentSelectedUser && currentSelectedUser);
  }, [currentSelectedUser]);

  // useEffect(() => {
  //   if (newMessage) {
  //     setChats([...chats, newMessage]);
  //   }
  // }, [newMessage && newMessage._id]);

  // useEffect(() => {
  //   messagesEndRef.current.scrollIntoView({
  //     behavior: "auto",
  //   });
  // }, [chats]);

  const handleChange = (e) => {
    e.target.value.length <= 280 && setNewText(e.target.value);
  };

  const handleSendNewText = () => {
    if (newText != "") {
      var date = new Date();
      var timestamp = date.getTime();
      let params = {
        from: id,
        to: currentUser.user_id,
        message: newText,
        timestamp: timestamp,
      };
      handleSendText(params);
      setNewText("");
      // handleStopComposing();
    }
  };

  // const renderChats = () =>
  //   chats &&
  //   chats.length > 0 &&
  //   chats.map((chat) => (
  //     <>
  //       <Grid item className={classes.date}>
  //         <Chip size="small" label={group.date} />
  //       </Grid>
  //       {chat.from === id ? (
  //         <Grid
  //           container
  //           direction="row"
  //           justify="flex-end"
  //           alignItems="flex-start"
  //           className={classes.chat}
  //           style={{
  //             textAlign: "right",
  //           }}
  //         >
  //           <Grid
  //             item
  //             style={{
  //               maxWidth: "90%",
  //             }}
  //           >
  //             <MessageFromComponent
  //               key={chat._id || chat.timestamp || Math.random()}
  //               chat={chat}
  //             />
  //           </Grid>
  //         </Grid>
  //       ) : (
  //         <Grid
  //           container
  //           direction="row"
  //           justify="flex-start"
  //           alignItems="flex-start"
  //           className={classes.chat}
  //           style={{
  //             textAlign: "left",
  //           }}
  //         >
  //           <Grid item style={{ maxWidth: "90%" }}>
  //             <MessageToComponent
  //               key={chat._id || chat.timestamp || Math.random()}
  //               chat={chat}
  //             />
  //           </Grid>
  //         </Grid>
  //       )}
  //     </>
  //   ));

  return (
    <Paper className={classes.root}>
      <Grid container direction="column" style={{ height: "inherit" }}>
        <Grid item style={{ height: 48 }}>
          <Paper style={{ width: "100%", height: "100%" }}>
            <Typography variant="h5">
              {currentUser.followingUser_CustomName !== ""
                ? currentUser.followingUser_CustomName
                : currentUser.username}
            </Typography>
          </Paper>
        </Grid>
        <Grid item className={classes.thread}>
          <Grid container direction="column" alignItems="center">
            {/* {renderChats()} */}
            <div ref={messagesEndRef}></div>
          </Grid>
        </Grid>
        <Grid item>
          <OutlinedInput
            autoFocus
            disabled={!currentUser.isChatEligible}
            id="filled-multiline-flexible"
            endAdornment={
              <InputAdornment position="end">
                <Divider orientation="vertical" style={{ height: 32 }} />
                <IconButton
                  edge="end"
                  onClick={handleSendNewText}
                  disabled={newText === "" || !currentUser.isChatEligible}
                >
                  <SendSharpIcon />
                </IconButton>
              </InputAdornment>
            }
            // multiline
            fullWidth
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSendNewText();
              }
            }}
            margin="none"
            // rowsMax={4}
            style={{ height: 48 }}
            size="small"
            // value={newText}
            value={
              currentUser.isChatEligible ? newText : currentUser.blockStatus
            }
            onChange={(e) => handleChange(e)}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default NewChatComponent;
