import React, { useState, useEffect } from "react";
// import { Button } from "../components/Buttons";
import { AddOnEmailInput, AddOnPasswordInput } from "../components/AddOnInputs";
import { AuGroup } from "../components/AuFormComponent";
import { emailValidate, resendEmail } from "../actions/loginActions";
import withRouter from "react-router-dom/es/withRouter";
import Link from "react-router-dom/es/Link";
import { Button, Typography } from "@material-ui/core";
import AlertDialog from "../components/AlertDialog";
import { useDispatch, useSelector } from "react-redux";

// // import snackbar from "snackbar";

// const buttonDesignClass = {
//   className: "btn au-btn theme-resetPassword",
//   value: "Verify Email",
// };

// const formInputsTheme = {
//     className: 'theme-resetPassword',
// };

const VerifyEmail = (props) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [seconds, setSeconds] = useState(5);
  const [tries, setTries] = useState(null);
  const [alert, setAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const dispatch = useDispatch();

  const onResendEmail = () => {
    const token = localStorage.getItem("validationJWT");
    const username = localStorage.getItem("username");

    const formData = {
      username,
    };

    dispatch(resendEmail(formData, token)).then((res) => {
      if (res.error) {
        setAlertText("Sorry! Failed to resend OTP");
        setAlert(true);
      } else if (res.data == "Daily limit reached.") {
        setTries(0);
      } else res.data.triesLeft !== null && setTries(res.data.triesLeft);
    });
    setIsDisabled(true);
  };

  const onSubmitForm = (e) => {
    // e.preventDefault();
    const id = props.match.params.q;
    const token = localStorage.getItem("validationJWT");
    dispatch(emailValidate(id, token)).then((response) => {
      if (!response.error) {
        if (response.data && response.data.emailValidated) {
          props.history.push("/otp-phone-verify");
        }
      }
    });
  };

  useEffect(() => {
    let interval = null;
    if (tries > 0) {
      if (seconds <= 0) {
        setIsDisabled(false);
        setSeconds(5);
        clearInterval(interval);
      } else if (isDisabled) {
        interval = setInterval(() => {
          setSeconds((seconds) => seconds - 1);
        }, 1000);
      }
      return () => clearInterval(interval);
    }
  }, [isDisabled, seconds, tries]);

  return (
    <div className="au-frame au-text au-reset-frame">
      <div className="text-center">
        <div className="au-banner theme-resetPassword">
          <div className="au-bfg">
            <div>
              <img
                src="/assets/images/icons/login-icon.png"
                width="20px"
                alt=""
              />
            </div>
            <div>
              <span>Verify Your Email</span>
            </div>
          </div>
        </div>
        <span>
          <i className="fa fa-3x fa-caret-down fa-reset-clr au-bcd"></i>
        </span>
      </div>
      <div className="au-card au-form">
        {alert && (
          <AlertDialog
            open={alert}
            handleClose={() => {
              setAlert(false);
              // handleClose();
            }}
            text={alertText}
          />
        )}
        <p>
          Email verification link is sent to your registered email, please
          verify using the link provided in the mail and then come back and
          click this verify button.
        </p>
        <form className="form-horizontal">
          <div className="au-re text-center">
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => onSubmitForm(e)}
              style={{ textTransform: "none" }}

              // buttonDesign={buttonDesignClass}
            >
              Verify Email
            </Button>
          </div>
        </form>
        <Button
          disabled={isDisabled || tries === 0}
          onClick={onResendEmail}
          style={{ textTransform: "none" }}
        >
          {isDisabled
            ? tries > 0
              ? `Wait ${seconds} seconds`
              : ""
            : "Resend Email"}
        </Button>
        <Typography
          display="inline"
          style={{ float: "right", margin: 6, textTransform: "none" }}
          color="secondary"
        >
          {tries !== null
            ? tries > 0
              ? tries + " tries left"
              : "Daily limit reached"
            : null}
        </Typography>
      </div>
    </div>
  );
};

export default withRouter(VerifyEmail);
