const ADTableReducer = (
  state = {
    ADs: [],
    ADsTotalPages: 0,
    fetchingADs: false,
    currentAd: null,
    currentAd_id: null,
    applied: false,
    applying: false,
    appliedAds: null,
    fetchingAppliedAds: false,
  },
  action
) => {
  switch (action.type) {
    case "FETCHING_ADS":
      return {
        ...state,
        fetchingADs: true,
      };
    case "ADS_FETCHED_SUCCESS":
      return {
        ...state,
        ADs: action.payload.data,
        ADsTotalPages: action.payload.totalPages,
        fetchingADs: false,
      };
    case "ADS_FETCHED_FAILED":
      return {
        ...state,
        fetchingADs: false,
      };
    case "FETCHING_APPLIED_ADS":
      return {
        ...state,
        fetchingAppliedAds: true,
      };
    case "APPLIED_ADS_FETCHED_SUCCESS":
      return {
        ...state,
        appliedAds: action.payload,
        fetchingAppliedAds: false,
      };
    case "APPLIED_ADS_FETCHED_FAILED":
      return {
        ...state,
        fetchingAppliedAds: false,
      };
    case "CURRENT_AD":
      return {
        ...state,
        currentAd: action.currentAd,
        currentAd_id: action.currentAd._id,
        applied: action.applied,
      };
    case "APPLIED_FOR_AD":
      return {
        ...state,
        applied: true,
        applying: false,
      };
    case "APPLYING_FOR_AD":
      return {
        ...state,
        applying: true,
      };
    case "ERROR_APPLY_AD":
      return {
        ...state,
        applying: false,
      };
    default:
      return state;
  }
};

export default ADTableReducer;
