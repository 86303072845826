import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import blue from "@material-ui/core/colors/blue";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import ListSubheader from "@material-ui/core/ListSubheader";
import InfoIcon from "@material-ui/icons/Info";
import {
  getStringParam,
  isValidParam,
  getBooleanParam,
} from "../helpers/paramVerifier";

const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  gridList: {
    width: 600,
    height: 500,
  },
  icons: {
    color: "rgba(255, 255, 255, 0.54)",
    marginLeft: 75,
    cursor: "pointer",
    height: "auto",
  },
  thumbnail: {
    height: "100%",
  },
  dialogPaper: {
    left: "0px",
    right: "0px",
    overflowY: "visible",
    padding: "0px",
    maxWidth: 1048,
    // width: "fit-content",
    maxHeight: "600px",
  },
};

class CustomDialog extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      classes,
      onClose,
      isFullDialog,
      isShowHeader,
      ...other
    } = this.props;
    let headerTitle = isValidParam(this.props.headerTitle)
      ? this.props.headerTitle
      : "";

    let dialogProps = new Object();
    if (getBooleanParam(isFullDialog)) {
      dialogProps.fullWidth = true;
      // dialogProps.maxWidth = "md";
    }

    dialogProps = { ...other, ...dialogProps };
    return (
      <Dialog
        fullWidth
        onClose={() => this.props.onClose()}
        aria-labelledby="simple-dialog-title"
        {...dialogProps}
        style={{ padding: "0px" }}
        PaperProps={{ className: classes.dialogPaper }}
      >
        {getBooleanParam(isShowHeader) && (
          <DialogTitle
            id="simple-dialog-title"
            onClose={() => this.props.onClose()}
          >
            {headerTitle}
          </DialogTitle>
        )}
        <DialogContent style={{ padding: "0px" }}>
          {this.props.children}
        </DialogContent>
        {/* <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Save changes
            </Button>
          </DialogActions> */}
      </Dialog>
    );
  }
}
export default withStyles(styles)(CustomDialog);

const DialogTitle = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    margin: 0,
    // padding: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 0,
    overflowY: "visible",
    maxHeight: "inherit",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions);
