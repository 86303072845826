import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    textAlign: "center",
  },
});

function createData(name, yearly, half, quarterly) {
  return { name, yearly, half, quarterly };
}

const rows = [
  createData("Production House/Employer", 9999, 7999, 4999),
  createData("Opportunity Seeker/JobSeeker", 7999, 5999, 3999),
  createData("Social Media User", 5999, 3999, 1999),
];

const Pricing = () => {
  const classes = useStyles();
  return (
    <div style={{ marginTop: 100, width: "100%" }}>
      <Grid
        container
        justify="center"
        direction="column"
        alignItems="center"
        style={{ margin: 12, height: "fit-content" }}
      >
        <Typography variant="h4">Pricing</Typography>
        <Typography variant="body1">First 7 days free trial. After free trial ends, user has to subscribe.</Typography>
      </Grid>
      <Grid container justify="center">
        <Grid
          item
          xs={6}
          style={{
            padding: 12,
            border: "solid",
            borderColor: "rgba(0,0,0,0.1)",
            borderWidth: 0.5,
            height: "fit-content",
          }}
        >
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>UserType</TableCell>
                  <TableCell align="center">Yearly(₹)</TableCell>
                  <TableCell align="center">Half-Yearly(₹)</TableCell>
                  <TableCell align="center">Quarterly(₹)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="center">{row.yearly}</TableCell>
                    <TableCell align="center">{row.half}</TableCell>
                    <TableCell align="center">{row.quarterly}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default Pricing;
