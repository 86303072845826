import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import connect from "react-redux/es/connect/connect";
import withRouter from "react-router-dom/es/withRouter";
import bindActionCreators from "redux/es/bindActionCreators";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import getURL from "../../../helpers/apis";
import { openAvatarModal } from "../../../actions/appActions";
import { useHistory } from "react-router-dom";
import { Loader } from "../../UtilityComponents";
import moment from "moment";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    overflow: "visible",
    display: "flex",
    width: "fit-content",
    height: "fit-content",
    maxWidth: "inherit",
    maxHeight: "inherit",
  },
  navIcons: {
    display: "block",
    marginTop: "-20px",
    overflow: "hidden",
    pointerEvents: "auto",
    position: "absolute",
    textIndent: "-9999em",
    top: "50%",
    cursor: "pointer",
    color: "black",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  video: {
    width: "fit-content",
    height: "300px",
    maxWidth: "300px",
    maxHeight: "300px",
    minWidth: "300px",
    minHeight: "auto",
    marginBottom: 25,
  },
  image: {
    height: "300px",
    width: "100%",
    display: "inline-block",
    maxWidth: "300px",
    maxHeight: "300px",
    minHeight: "auto",
    marginBottom: 25,
  },
  icons: {
    cursor: "pointer",
    height: "auto",
  },
}));

const indicatorStyles = {
  // transition: "opacity .25s ease-in",
  // opacity: ".3",
  // filter: "alpha(opacity = 30)",
  boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.9)",
  background: "#fff",
  borderRadius: "50%",
  width: "10px",
  height: "10px",
  cursor: "pointer",
  display: "inline-block",
  margin: "0 8px",
};

const MediaCarousel = ({ galleries, postId, match }) => {
  var history = useHistory();
  const classes = useStyles();
  const rootUrl = getURL("UPLOADS");
  const videoRef = useRef();
  const [playing, setPlaying] = useState(false);
  videoRef.current = {};

  useEffect(() => {
    setPlaying(false);
  }, [match]);

  const customRenderItem = (item, props) => {
    return <item.type {...item.props} {...props} />;
  };

  const customRenderIndicator = (onClickHandler, isSelected, index, label) => {
    if (isSelected) {
      return (
        <li
          style={{ ...indicatorStyles, background: "blue" }}
          aria-label={`Selected: ${label} ${index + 1}`}
          title={`Selected: ${label} ${index + 1}`}
        />
      );
    }
    return (
      <li
        style={indicatorStyles}
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        value={index}
        key={index}
        role="button"
        tabIndex={0}
        title={`${label} ${index + 1}`}
        aria-label={`${label} ${index + 1}`}
      />
    );
  };

  const videoItem = (src, thumb, isSelected) => {
    return (
      <ReactPlayer
        ref={(rp) => (videoRef.current = rp)}
        // light={thumb}
        url={src}
        loop
        onStart={() => setPlaying(true)}
        onPause={() => setPlaying(false)}
        playing={playing && isSelected}
        controls
        width="100%"
        height="300px"
        style={{
          minWidth: "300px",
          minHeight: "auto",
          maxHeight: "inherit",
        }}
        pip
        onClick={(e) => e.stopPropagation()}
      />
    );
  };

  // const customRenderThumb = (children) =>
  //   children.map((item) => {
  //     const thumbnail =
  //       item.props.gallery.galleryType === "photos"
  //         ? item.props.gallery.name
  //         : item.props.gallery.thumbnail;
  //     return <img src={thumbnail} />;
  //   });

  const Slide = ({ gallery, style, onClick, isSelected }) => (
    <div gallery={gallery} style={style} onClick={onClick}>
      {gallery.galleryType === "videos" ? (
        <CardMedia className={classes.video}>
          {videoItem(process.env.REACT_APP_BUCKET_NAME + gallery.name, process.env.REACT_APP_BUCKET_NAME + gallery.thumbnail, isSelected)}
        </CardMedia>
      ) : (
        <CardMedia
          className={classes.image}
          alt="No Image found."
          component="img"
          image={process.env.REACT_APP_BUCKET_NAME + gallery.name}
        />
      )}
    </div>
  );

  return (
    <Carousel
      // autoPlay
      renderItem={customRenderItem}
      dynamicHeight
      // useKeyboardArrows
      stopOnHover
      // thumbWidth={100}
      infiniteLoop
      showThumbs={false}
      // renderThumbs={customRenderThumb}
      renderIndicator={customRenderIndicator}
    >
      {
        galleries &&
          galleries.length &&
          galleries.map((gallery) => (
            <Slide
              gallery={gallery}
              style={{ textAlign: "-webkit-center" }}
              onClick={(e) => {
                history.push(`/post/${postId}`);
              }}
            />
          ))
        // <div
        //   gallery={gallery}
        //   style={{ textAlign: "-webkit-center" }}
        //   onClick={() => history.push(`/post/${postId}`)}
        // >
        //   {gallery.galleryType === "videos" ? (
        //     <CardMedia className={classes.video}>
        //       {videoItem(
        //          gallery.name,
        //          gallery.thumbnail,
        //         gallery._id
        //       )}
        //     </CardMedia>
        //   ) : (
        //     <CardMedia
        //       className={classes.image}
        //       alt="No Image found."
        //       component="img"
        //       image={ gallery.name}
        //     />
        //   )}
        // </div>
      }
    </Carousel>
  );
};

function mapStateToProps(state) {
  return {
    userDetail: state.userDetailsReducer.userDetail,
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(MediaCarousel));
