// import dependencies...
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
// import { HashRouter as Router } from "react-router-dom";

//import components
import App from "./components/App";
import authStore from "./store";
import { isLoggedIn } from "./helpers/globalFunctions";

//require css and js files here instead of importing them in html
require("./assets/css/bootstrap.min.css");
require("./assets/css/font-awesome.min.css");
require("./assets/css/theme.css");
require("./assets/css/snackbar.css");
require("./assets/css/style.css");
require("typeface-roboto");
global.jQuery = window.$ = window.jQuery = require("jquery");
require("bootstrap");

const app = () => {
  return (
    <Provider store={authStore}>
      <Router>
        <Route component={App} />
      </Router>
    </Provider>
  );
};

export default app;

// window.onload = () => {
//   ReactDOM.render(
//     <Provider store={authStore}>
//       <Router>
//         <Route component={App} />
//       </Router>
//     </Provider>,
//     document.getElementById("root")
//   );
// };
