import request from "../helpers/requestEngine";

export const filterParams = () => {
  return request("get", "FILTER_VALUES", true);
};

export const updateSearchParams = params => {
  return request("post", "UPDATE_SEARCH", true, params);
};

export const searchNamesByUserId = params => {
  return request("get", "CREATE_FILTER_SEARCH", true, null, params);
};

export const getRecentSearch = () => {
  return request("get", "GET_RECENT_SEARCH", true, null, {});
};

export const createFilterSearch = params => {
  return request("post", "CREATE_FILTER_SEARCH", true, params);
};

export const deleteFilterSearch = params => {
  return request("delete", "CREATE_FILTER_SEARCH", true, null, params);
};
